import React from 'react';
import { Modal } from '../components/common/modal-service/modal';
import { create } from 'zustand';

export const types = {
  reset: 'RESET',
  showModal: 'SHOW_MODAL',
  closeModal: 'CLOSE_MODAL',
  closeLatestModal: 'CLOSE_LATEST',
  pushAlert: 'PUSH_ALERT',
  closeAlert: 'CLOSE_ALERT',
};

const initialState = { instances: [], active: undefined, alerts: [] };

const modalReducer = (state, action) => {
  switch (action.type) {
    case types.reset:
      return initialState;

    case types.showModal: {
      return {
        ...state,
        active: action.payload.id,
        instances: [...state.instances, action.payload.instance],
      };
    }

    case types.closeModal: {
      return {
        ...state,
        active: undefined,
        instances: state.instances.filter((item) => item.id !== action.payload.id),
      };
    }

    case types.closeLatestModal: {
      if (state.instances.length === 0) return state;

      const id = state.instances[state.instances.length - 1].id;

      return {
        ...state,
        active: undefined,
        instances: state.instances.filter((item) => item.id !== id),
      };
    }

    case types.pushAlert: {
      return {
        ...state,
        alerts: [...state.alerts, { ...action.payload.alert, id: action.payload.id }],
      };
    }

    case types.closeAlert: {
      return {
        ...state,
        alerts: state.alerts.filter((item) => item.id !== action.payload.id),
      };
    }

    default:
      return state;
  }
};

export const useModalStore = create((set) => ({
  ...initialState,
  dispatch: (args) => set((state) => modalReducer(state, args)),
}));

const mapModalProps = (props) => {
  const {
    PaperProps,
    BackdropComponent,
    BackdropProps,
    classes,
    component,
    components,
    componentsProps,
    closeAfterTransition,
    container,
    disableAutoFocus,
    disableEnforceFocus,
    disableEscapeKeyDown,
    disablePortal,
    disableRestoreFocus,
    disableScrollLock,
    hideBackdrop,
    keepMounted,
    onBackdropClick,
    onTransitionEnter,
    onTransitionExited,
    slotProps,
    slots,
    useFullScreen,
    fullWidth,
    maxWidth,

    template,
    ...templateProps
  } = props;

  const modalProps = {
    PaperProps,
    BackdropComponent,
    BackdropProps,
    classes,
    component,
    components,
    componentsProps,
    closeAfterTransition,
    container,
    disableAutoFocus,
    disableEnforceFocus,
    disableEscapeKeyDown,
    disablePortal,
    disableRestoreFocus,
    disableScrollLock,
    hideBackdrop,
    keepMounted,
    onBackdropClick,
    onTransitionEnter,
    onTransitionExited,
    slotProps,
    slots,
    useFullScreen,
    fullWidth,
    maxWidth,
  };

  return {
    template,
    modalProps,
    templateProps,
  };
};

export const ModalProvider = () => {
  const dispatch = useModalStore((state) => state.dispatch);
  const instances = useModalStore((state) => state.instances);

  return (
    <>
      {instances.map((modalInstance) => {
        const { template, modalProps, templateProps } = mapModalProps(modalInstance);

        return (
          <Modal
            key={modalInstance.id}
            // open={modalInstance.id === state.active}
            open={true}
            {...modalProps}
            onClose={(event, reason) => {
              // Prevent closing on backdrop click
              if (modalInstance?.preventCloseOnBackdropClick && reason === 'backdropClick') {
                return;
              }
              modalInstance.onClose?.();
              dispatch({ type: 'CLOSE_MODAL', payload: { id: modalInstance.id } });
            }}
          >
            {React.cloneElement(template, templateProps)}
          </Modal>
        );
      })}
    </>
  );
};
