import React from 'react';
import { keyframes, styled } from '@mui/system';
import { css } from 'styled-components';

// Define the keyframes for the shine animation
const shine = keyframes`
  0% {
    transform: skewX(-45deg) translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

// Create a styled component with the shine effect
const ShineEffect = styled('div')`
  position: relative;
  overflow: hidden;
  pointer-events: none;
  cursor: not-allowed;
  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: calc(100% - 5px);
    transition: transform 0.2s linear;
    background: rgba(0, 0, 0, 0.12);
    transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
    animation: ${shine} 2s infinite cubic-bezier(0.5, 0.05, 0.1, 0.3);
  }
`;

export const ShineAnimation = ({ children, showAnimation }) => {
  return showAnimation ? <ShineEffect> {children} </ShineEffect> : children;
};

export default ShineAnimation;
