import React, { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Chip, Divider, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import UpdateIcon from '@mui/icons-material/Update';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';

import { HtmlTooltip } from '../../../Style';
import { appColors } from '../../../theme/AppThemes';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { ContactManagerContext } from '../../../context/contact-manager.context';
import { useContext } from 'react';
import AgentAvailRequestInfo from './AgentAvailRequestInfo';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Show from '../../common/others/Show';
import { isEmptyObject } from '../../../helpers';

const proMemberIcon = require(`../../../assets/images/ico_proStatus.png`);

const _scale = require(`../../../assets/images/scale.png`);
const _24Time = require(`../../../assets/images/24-time.png`);
const _certified = require(`../../../assets/images/certified.png`);
const _language = require(`../../../assets/images/language.png`);
const _ron = require(`../../../assets/images/video-call.png`);

const _scaleDark = require(`../../../assets/images/scale-dark.png`);
const _24TimeDark = require(`../../../assets/images/24-time-dark.png`);
const _certifiedDark = require(`../../../assets/images/certified-dark.png`);
const _languageDark = require(`../../../assets/images/language-dark.png`);
const _ronDark = require(`../../../assets/images/video-call-dark.png`);

// active CAPs
const _scaleColored = require(`../../../assets/images/scale-color.png`);
const _24TimeColored = require(`../../../assets/images/24-time-color.png`);
const _certifiedColored = require(`../../../assets/images/certified-color.png`);
const _certifiedColoredDark = require(`../../../assets/images/certified-colored-dark.png`);
const _languageColored = require(`../../../assets/images/language-color.png`);
const _ronColored = require(`../../../assets/images/video-call-color.png`);

const SmallAvatar = styled(Avatar)(
  ({ theme }) => css`
    width: 50px;
    height: 22px;
    border-radius: 7px;
    border: 2.5px solid ${theme.palette.background.paper} !important;
    zoom: 90%;

    img {
      object-fit: cover;
      top: 0.5px;
    }
  `
);

const StyledDataItemRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin-bottom: 7px;

    .MuiCard-root {
      padding: 5px;
      display: block;
      place-content: space-around;
    }

    :hover {
      #rowLeft {
        > div:nth-child(2) {
          .MuiSvgIcon-root {
            color: ${theme.palette.success.main};
            animation: left_to_right 1s linear infinite;
          }
          @keyframes left_to_right {
            from {
              margin-left: 30%;
            }
            to {
              margin-left: 70%;
            }
          }
        }
      }
    }
  `
);

const StyledDataItemAssign = styled(Card)(
  ({ theme }) => css`
    width: 70px;
    min-height: 100px !important;
    text-align: center;
    text-align: center;
    margin-right: 1px;
    padding: 0px !important;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    &.agent-selected {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#f1fff03d'};

      > div:nth-child(2) {
        .MuiSvgIcon-root {
          color: green;
        }
      }
    }

    :hover {
      > div:nth-child(2) {
        .MuiSvgIcon-root {
          animation: none !important;
        }
      }
    }

    height: 100%;
    position: relative;

    > div {
      height: 33%;
      position: relative;
      justify-content: center;
      align-content: center;
    }

    > div:first-child {
      place-items: center;
    }

    > div:nth-child(1) {
      .MuiSvgIcon-root {
        font-size: 1.6rem;
        width: 0.76em;
        top: 3px;
        position: relative;
      }
    }

    > div:nth-child(2) {
      button {
        padding: 3px;

        .MuiSvgIcon-root {
          color: #c1a20f;
          font-size: 1.6rem;
        }
      }
    }

    .MuiChip-root {
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.7rem;
      height: 20px;
      color: #03a9f4;
      /* top: 5px; */
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  `
);

const StyledDataItem = styled(Card)(
  ({ theme, $showLeftSection, $priColor }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    /* cursor: pointer; */
    position: relative;
    border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    &.agent-selected {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#f1fff03d'};

      .names {
        h3 {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : '#005e74'};
        }
      }
    }

    .icon-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      max-width: 250px;
      overflow-wrap: anywhere;

      svg {
        width: 0.8em;
        height: 0.8em;
      }
    }

    .outer-div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .mobile-hide {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    .desktop-hide {
      @media screen and (min-width: 700px) {
        display: none !important;
      }
    }

    .mobile-show {
      @media screen and (min-width: 700px) {
        display: flex;
      }
    }

    .soComments {
      position: absolute;
      top: 5px;
      right: -25px;
    }

    :hover {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};
      /* border: 1px solid ${theme.palette.mode === 'dark' ? '#354b58' : '#bfcae3'}; */
      clip-path: none;

      .names {
        h3,
        .MuiSvgIcon-root {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : ''};
        }
      }

      .stats-others {
        .profile-completion {
          .percentage {
            background: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};

            span: {
              font-weight: 600 !important;
            }
          }
        }
      }
    }

    .email-checkBox {
      flex-grow: 0.1;
      span {
        margin-left: -10px;
      }
    }

    > div {
      flex: 1 1 0px;
    }

    .avatar {
      flex-grow: 0;

      .MuiAvatar-root {
        border: 2px solid ${theme.palette.mode == 'dark' ? theme.palette.border.main : theme.palette.primary.main};
        color: ${theme.palette.primary.main};
        background-color: ${theme.palette.mode == 'dark'
          ? theme.palette.background.default
          : appColors[$priColor].palette.border.main};
      }
    }

    .contact-name {
      display: flex;
      align-items: center;

      h3,
      .MuiSvgIcon-root {
        color: ${theme.palette.mode === 'dark' ? '#29c4e9' : '#1e879f'};
      }

      .MuiSvgIcon-root {
        height: 0.8em;
      }
    }

    .contact {
      > div {
        overflow-wrap: break-word;
        display: flex;

        .MuiSvgIcon-root {
          font-size: 1rem;
          align-self: center;
        }

        span {
          max-width: 180px;
          font-size: 0.8rem;
        }
      }

      > div:nth-child(2) {
        span {
          line-height: 100%;
        }
      }
    }

    ${theme.breakpoints.up('md')} {
      .contact {
        > div {
          overflow-wrap: break-word;
          display: inline-flex;
          span {
            min-width: 230px;
          }
        }
      }
    }

    .panel-content {
      font-size: 0.75rem;
    }

    .stats-others {
      flex-wrap: wrap;
      display: flex;
      row-gap: 5px;

      .profile-completion {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: start;

        > div:first-child {
          position: relative;
          width: 100%;
        }

        .percentage {
          position: absolute;
          left: 15%;
          top: -8px;
          font-weight: bolder;
          background: ${theme.palette.background.paper};
          border-radius: 15%;
          padding: 0px 2px 0px;
        }
      }

      .capa {
        display: flex;
        width: 100%;
        align-items: center;

        .capa-icons {
          display: flex;
          gap: 5px;
          width: 135px;
          justify-content: space-evenly;

          img {
            width: 18px;
            height: 19px;
          }
        }
      }

      .stats {
        display: flex;
        gap: 3px;
        align-items: center;
        width: 100%;
      }
    }

    .label-icon {
      display: flex;
      width: 30px;

      .MuiSvgIcon-root:nth-child(2) {
        margin-left: -5px;
      }
    }

    .more-icons {
      position: absolute;
      bottom: 8px;
      right: 15px;
      text-align: right;
      display: grid;

      .miles {
        border-radius: 5px;
        font-weight: 500;
        font-size: 0.7rem;
        height: 20px;
        color: #03a9f4;
        width: fit-content;
        justify-self: end;
        margin-bottom: 5px;
      }
    }

    .favorite {
      position: absolute;
      right: 10px;
    }

    .profile-updated {
      height: auto;
      border-radius: 5px;

      .MuiChip-icon {
        margin-left: 2.5px;
      }

      .MuiChip-label {
        width: 30px;
        padding: 0px;
        padding-right: 4px;
        font-size: 0.7rem;
        text-align: end;
      }
    }
  `,

  ({ withnotes, $showLeftSection }) =>
    withnotes === 'true' &&
    css`
      position: relative;
      border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 102%);

      ::after {
        content: '';
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        background-color: #e1e1f2;
        top: 0;
        right: 0;
        border-bottom-left-radius: 20px;
        border-bottom-style: groove;
        text-align: center;
        z-index: 5;
      }

      :hover {
        .soComments {
          right: 5px;
          z-index: 5;
          color: blueviolet;
        }

        ::after {
          font-family: 'Material Icons';
          font-size: 1.25rem;
          color: blueviolet;
          z-index: 0;
          width: 35px;
          height: 35px;
        }
      }

      .favorite {
        bottom: 70px;
      }
    `
);

const ContactMobileBox = styled(Box)(
  ({ theme, $listMode }) => css`
    flex-grow: 1;
    display: flex;

    .MuiTabs-root {
      font-size: 0.7rem;
      /* flex: ${$listMode == 'email' ? 1.5 : 1}; */
      flex: 1.5;

      .MuiTab-root {
        font-size: 0.7rem;
        padding: 7px;
        min-height: auto;
      }

      .MuiTab-root.Mui-selected {
        background-color: ${theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.clear.main};
      }
    }
  `
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: '0 5px 0 15px', fontSize: '.85rem' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Item = ({ contact, setting, emitSelectedEmail }) => {
  const {
    listMode,
    requestID,
    filterPayLoad,
    assignNotaryState,
    assignedAgentFromRoute,
    assignAgentToRequest,
    agentEmailRecipients,
    fromContactManager,
    profileLookups,
  } = useContext(ContactManagerContext);

  const history = useHistory();
  const theme = useTheme();
  const currentRoute = history.location.pathname.replaceAll('/', '');
  const showLeftSection = currentRoute == 'contact-manager' ? false : true;
  const { customColor: appColor } = useSelector((state) => state.reducer.themeAppearance);

  const checkIfWasSelected = () => {
    if (agentEmailRecipients.length > 0) {
      let obj = agentEmailRecipients.filter((i) => i.publicId == contact.v2PublicID)[0];
      if (obj) return true;
      else return false;
    }
  };

  const [checked, setChecked] = useState(() => checkIfWasSelected());
  const [tabValue, setTabValue] = useState(0);
  const [capabilities, setCapabilities] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);

  const profileImage = `https://notarycafe.com/Content/profile/${contact.v2PublicID}_1.png`;

  useEffect(() => {
    setCapabilities(getNamesFromLookups('CAP', true));
    setLanguages(getNamesFromLookups('LANG', true));
    if (listMode != 'email') setChecked(false);
    return () => {};
  }, [listMode, contact]);

  const handleContactTabChange = (event, newValue) => {
    event.stopPropagation();
    setTabValue(newValue);
  };

  const proMemberIconRender = (event) => {
    return contact.isMember ? <SmallAvatar alt="pro" src={proMemberIcon} /> : '';
  };

  const handleEmailCheckBox = (event, contactEmail) => {
    if (!checked) {
      let obj = { publicId: contact.v2PublicID, email: contactEmail };
      if (emitSelectedEmail(obj)) setChecked(true);
    } else {
      emitSelectedEmail({
        publicId: contact.v2PublicID,
        remove: true,
      });
      setChecked(false);
    }
  };

  const testIconClick = (event) => {
    event.stopPropagation();
  };

  const getNamesFromLookups = (type, withCommas) => {
    const invalidProfiles = !profileLookups || isEmptyObject(profileLookups);
    if (!['BGC', 'CAP', 'LANG'].includes(type) || invalidProfiles) return [];

    const _BGC = (id) => profileLookups['SelectProviderTypes']?.find((item) => item.index === id)?.value || '';
    const _CAP = (id) => profileLookups['SelectCapabilityTypes']?.find((item) => item.index === id)?.value || '';
    const _LANG = (id) => profileLookups['SelectLanguageTypes']?.find((item) => item.index === id)?.value || '';

    if (type == 'BGC')
      return (
        contact.backgroundCheckList?.map((bck) => {
          const lookupName = _BGC(bck.ncLookupId);
          return withCommas ? lookupName : { ...bck, lookupName };
        }) || []
      );
    else if (type == 'CAP') {
      let res = contact.capabilityList?.reduce((acc, { ...attribs }) => {
        const lookupName = _CAP(attribs.ncLookupId);
        attribs.isCapable && acc.push(withCommas ? lookupName : { ...attribs, lookupName });
        return acc;
      }, []);
      if (languages.filter((i) => i !== '').length > 0) res?.push('Multi-Language');
      return res || [];
    } else if (type == 'LANG')
      return (
        contact.languageList?.map((bck) => {
          if (!bck.isSpoken) return '';
          const lookupName = _LANG(bck.ncLookupId);
          return withCommas ? lookupName : { ...bck, lookupName };
        }) || []
      );
    else return [];
  };

  const backgroundCheckTooltip = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Background Checks:</b>
        </div>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        {getNamesFromLookups('BGC').map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <strong>
                #{index + 1}&nbsp;&nbsp;
                {item.lookupName}.&nbsp;&nbsp;
              </strong>
            </div>
            <div style={{ flex: 1, textAlign: 'end' }}>
              <i>
                Expires On: <b>{item.expiresOn}</b>
              </i>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  const CapabilitiesTooltip = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Main Capabilities: </b>
          <Typography component="span" variant="body2">
            {getNamesFromLookups('CAP', true)
              .map((item) => {
                if (['Attorney', '24 Hour Service', 'CSA', 'RON Capable', 'Multi-Language'].includes(item)) return item;
                else return null;
              })
              .filter((item) => item) // Filter out null values
              .join(', ') || <i>None</i>}
          </Typography>
        </div>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <div className={'with-header'}>
          <b>Others: </b>
          <Typography component="span" variant="body2">
            {getNamesFromLookups('CAP', true)
              .map((item) => {
                if (!['Attorney', '24 Hour Service', 'CSA', 'RON Capable', 'Multi-Language'].includes(item) && item)
                  return item;
                else return null;
              })
              .filter((item) => item) // Filter out null values
              .join(', ') || <i>None</i>}
          </Typography>
        </div>
      </Fragment>
    );
  };

  const regDocumentTooltipContent = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Registered Documents:</b>
        </div>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        {contact.regDocumentList?.map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 2 }}>
              <strong>#{index + 1}&nbsp;&nbsp;</strong>
              {item.name} ({item.isCustom ? item.initials : item.code}
              ).&nbsp;&nbsp;
            </div>
            {item.expirationDate && (
              <div style={{ textAlign: 'end' }}>
                <i>
                  Expires On: <b>{item.expirationDate}</b>
                </i>
              </div>
            )}
          </div>
        ))}
      </Fragment>
    );
  };

  const profileUpdateTimeAgo = () => (
    <>
      {contact.updatedStatus == 'Red' && (
        <HtmlTooltip arrow title="Profile updated over 180 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'red !important' }} />}
            label="180"
          />
        </HtmlTooltip>
      )}
      {contact.updatedStatus == 'Yellow' && (
        <HtmlTooltip arrow title="Profile updated within the last 120 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'orange !important' }} />}
            label="120"
          />
        </HtmlTooltip>
      )}
      {contact.updatedStatus == 'Green' && (
        <HtmlTooltip arrow title="Profile updated within the last 60 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'green !important' }} />}
            label="60"
          />
        </HtmlTooltip>
      )}
    </>
  );

  const contactInfo = () => (
    <div className="contact-name">
      <h3
        onClick={(e) => {
          let partialUrl = '';
          if (fromContactManager) {
            partialUrl = `/contact-manager/profile`;
          } else {
            partialUrl = `/manage-request/assign-notary/${requestID}/contact`;
          }
          history.push({
            pathname:
              partialUrl +
              `/${contact.userType}/${contact.userTypeInitial === 'a' ? contact.v2PublicID : contact.userID}`,
            state: {
              fullName: contact.nameAndCompany.fullName,
              company: contact.nameAndCompany.company,
            },
          });
          e.stopPropagation();
        }}
      >
        {contact.nameAndCompany.fullName}
      </h3>
      {<LaunchOutlinedIcon fontSize="small" />}
      {contact.inPersonalList && (
        <div className="favorite">
          <div>
            <FavoriteIcon style={{ color: 'red', width: '0.9rem' }} />
          </div>
        </div>
      )}
    </div>
  );

  const assignAgent = useCallback((agentId) => {
    assignAgentToRequest(agentId);
  }, []);

  const assignedclassName = () => {
    return contact.v2PublicID === assignNotaryState?.publicId || assignedAgentFromRoute ? 'agent-selected' : '';
  };

  return (
    <div style={{ display: 'flex' }}>
      {listMode === 'email' && (
        <div className="email-checkBox" style={{ alignSelf: 'center' }}>
          <Checkbox
            color={'info'}
            checkedIcon={<MailOutlinedIcon />}
            checked={checked}
            onChange={(e) => handleEmailCheckBox(e, contact.mainEmail)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
      <StyledDataItemRow sx={{ boxShadow: assignedclassName() ? 3 : 0, borderRadius: '10px' }}>
        {showLeftSection && (
          <StyledDataItemAssign id="rowLeft" className={assignedclassName()}>
            <AgentAvailRequestInfo availRequest={contact.agentAvailabilityInfo} />
            <div>
              <IconButton onClick={() => assignAgent(contact.v2PublicID)}>
                <DoubleArrowRoundedIcon fontSize="small" />
              </IconButton>
            </div>
            {filterPayLoad.onProximity && (
              <div>
                <Chip
                  onClick={(e) => {
                    if (contact.mapLink) window.open(contact.mapLink, '_blank');
                    else e.stopPropagation();
                  }}
                  color="default"
                  size="small"
                  label={contact.miles ? contact.miles : '0'}
                />
              </div>
            )}
          </StyledDataItemAssign>
        )}
        <StyledDataItem
          id="rowRight"
          className={assignedclassName()}
          $showLeftSection={showLeftSection}
          $priColor={appColor}
          withnotes={contact.sO_Comments ? 'true' : 'false'}
        >
          <ContactMobileBox $listMode={listMode}>
            <Tabs
              sx={{ borderRight: 1, borderColor: 'divider' }}
              aria-label="Vertical tabs"
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleContactTabChange}
              onClick={(e) => {
                e.stopPropagation();
                setTabValue(e.target.tabIndex != -1 ? 0 : tabValue);
              }}
            >
              <Tab
                sx={{ backgroundColor: 'transparent !important' }}
                icon={
                  <div className="avatar">
                    <Show when={contact?.notSynced}>
                      <HtmlTooltip
                        style={{ display: 'flex' }}
                        arrow
                        followCursor
                        title={'Profile is out of sync with Notary Cafe.'}
                      >
                        <SyncProblemIcon
                          sx={{
                            width: 24,
                            height: 24,
                            top: 0,
                            left: 'calc(50% - 24px)',
                            position: 'absolute',
                            zIndex: 1,
                            borderRadius: '50%',
                            color: theme.palette.warning.main,
                            background: theme.palette.nsTableHeaderBG.main,
                          }}
                        />
                      </HtmlTooltip>
                    </Show>
                    <Stack direction="row" spacing={2}>
                      {proMemberIconRender() ? (
                        <Badge
                          sx={{ '.MuiBadge-badge': { p: 0 } }}
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={proMemberIconRender()}
                        >
                          <Avatar sx={{ width: 45, height: 45 }} src={profileImage} />
                        </Badge>
                      ) : (
                        <Avatar sx={{ width: 45, height: 45 }} src={profileImage} />
                      )}
                    </Stack>
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab label="Stats" {...a11yProps(1)} />
              <Tab label="Agent Docs" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={tabValue} index={0} style={{ flex: 3 }}>
              {contactInfo()}
              <div className="panel-content">
                <p>
                  <strong>{contact.nameAndCompany.company.replace('<br>', '')}</strong>
                </p>
                <Divider sx={{ mt: 0.5, mb: 1 }} />
                <div className="icon-item">
                  <LocationOnOutlinedIcon fontSize="small" />{' '}
                  <span>
                    {contact.billingAddress?.city} {contact.billingAddress?.state} {contact.billingAddress?.zipCode}
                  </span>
                </div>
                <div className="icon-item">
                  <AlternateEmailOutlinedIcon fontSize="small" />{' '}
                  <span onClick={(e) => e.stopPropagation()}>{contact.mainEmail.toLowerCase()}</span>
                </div>
                <div className="icon-item">
                  <PhoneIphoneOutlinedIcon fontSize="small" />{' '}
                  <HtmlTooltip disableFocusListener arrow maxwidth="350px" title={contact.allPhoneInfo}>
                    <span>{contact.preferredPhone}</span>
                  </HtmlTooltip>
                  <StarBorderOutlinedIcon sx={{ fontSize: '1rem' }} fontSize="small" color="default" />
                </div>
                {!showLeftSection && setting.showProximityEnable && (
                  <div className="more-icons">
                    <Chip
                      className="miles"
                      onClick={(e) => testIconClick(e)}
                      color="default"
                      size="small"
                      label={contact.miles ? contact.miles : '0'}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1} style={{ flex: 3 }}>
              {contactInfo()}
              <Divider sx={{ mt: 0.5, mb: 1 }} />
              <div className="panel-content stats-others">
                <div className="profile-completion">
                  <HtmlTooltip style={{ display: 'flex' }} arrow followCursor title={'Profile Completion'}>
                    <span className="label-icon">
                      <FactCheckOutlinedIcon color="default" />
                      <ArrowRightOutlinedIcon color="disabled" />
                    </span>
                    <span style={{ width: '32px' }}>{`${contact.profileScore}%`}&nbsp;</span>
                  </HtmlTooltip>
                  {profileUpdateTimeAgo()}
                  <Divider sx={{ ml: 1, mr: 1 }} orientation="vertical" flexItem />
                  <HtmlTooltip style={{ display: 'flex' }} arrow followCursor title={'Rating'}>
                    <span className="label-icon">
                      <StarOutlineOutlinedIcon color="default" />
                      <ArrowRightOutlinedIcon color="disabled" />
                    </span>
                    {contact.sO_Rating < 0 ? 0 : contact.sO_Rating}
                  </HtmlTooltip>
                </div>
                <div className="capa">
                  <span className="label-icon">
                    <CopyrightOutlinedIcon color="default" />
                    <ArrowRightOutlinedIcon color="disabled" />
                  </span>
                  <HtmlTooltip arrow followCursor maxwidth="250px" title={CapabilitiesTooltip()}>
                    <div className="capa-icons">
                      {capabilities?.includes('Attorney') ? (
                        <img alt="scale" src={_scaleColored} />
                      ) : (
                        <img
                          alt="scale"
                          src={theme.palette.mode == 'dark' ? _scaleDark : _scale}
                          style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                        />
                      )}
                      {capabilities?.includes('24 Hour Service') ? (
                        <img alt="24Time" src={_24TimeColored} />
                      ) : (
                        <img
                          alt="24Time"
                          src={theme.palette.mode == 'dark' ? _24TimeDark : _24Time}
                          style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                        />
                      )}
                      {capabilities?.includes('CSA') ? (
                        <div style={{ position: 'relative', display: 'inline-block', zIndex: 0 }}>
                          <img
                            style={{
                              width: '18px',
                              height: '19px',
                              display: 'block',
                            }}
                            alt="certified"
                            src={theme.palette.mode == 'dark' ? _certifiedColoredDark : _certifiedColored}
                          />
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '3px',
                              width: '70%',
                              height: '70%',
                              backgroundColor: 'rgb(255 255 255)',
                              transform: 'translateY(-50%)',
                              borderRadius: '50px',
                              zIndex: -1,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <img
                          alt="certified"
                          src={theme.palette.mode == 'dark' ? _certifiedDark : _certified}
                          style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                        />
                      )}
                      {languages.filter((i) => i !== '').length > 0 ? (
                        <img alt="language" src={_languageColored} />
                      ) : (
                        <img
                          alt="language"
                          src={theme.palette.mode == 'dark' ? _languageDark : _language}
                          style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                        />
                      )}
                      {capabilities?.includes('RON Capable') ? (
                        <img
                          style={{
                            width: '18px',
                            height: '20px',
                            display: 'block',
                          }}
                          alt="ron"
                          src={_ronColored}
                        />
                      ) : (
                        <img
                          style={{
                            width: '18px',
                            height: '20px',
                            display: 'block',
                            opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25,
                          }}
                          alt="language"
                          src={theme.palette.mode == 'dark' ? _ronDark : _ron}
                        />
                      )}
                    </div>
                  </HtmlTooltip>
                </div>
                <div className="stats">
                  <span className="label-icon">
                    <BarChartOutlinedIcon color="default" />
                    <ArrowRightOutlinedIcon color="disabled" />
                  </span>
                  <span>
                    <strong>12m:</strong>
                    {contact.signings_12mths} {` | `} <strong>My: </strong>
                    {contact.signings_MyPortal} {` | `} <FavoriteBorderOutlinedIcon style={{ fontSize: '1.2em' }} />
                    {contact.preferredCount}
                  </span>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={2} style={{ flex: 3 }}>
              {contactInfo()}
              <Divider sx={{ mt: 0.5, mb: 1 }} />
              <div className="panel-content">
                <div className="background-check">
                  <strong>{`BGC: `}</strong>
                  {getNamesFromLookups('BGC').length ? (
                    <HtmlTooltip arrow title={backgroundCheckTooltip()} minwidth="350px">
                      <span>{getNamesFromLookups('BGC', true).join(', ')}</span>
                    </HtmlTooltip>
                  ) : (
                    <i
                      style={{
                        fontSize: '0.72rem',
                        color: 'darkgrey',
                      }}
                    >
                      No Background Checks.
                    </i>
                  )}
                </div>
                <div className="registered-documents">
                  <strong>{`Reg. Documents: `}</strong>
                  {contact.regDocumentList?.length ? (
                    <HtmlTooltip disableFocusListener arrow title={regDocumentTooltipContent()} minwidth="350px">
                      <span>{contact.regDocumentList.map((d) => (d.isCustom ? d.initials : d.code)).join(', ')}</span>
                    </HtmlTooltip>
                  ) : (
                    <i
                      style={{
                        fontSize: '0.72rem',
                        color: 'darkgrey',
                      }}
                    >
                      No Documents
                    </i>
                  )}
                </div>
              </div>
            </TabPanel>
          </ContactMobileBox>
          {contact.sO_Comments && (
            <div className="soComments">
              <HtmlTooltip arrow disableFocusListener title={contact.sO_Comments}>
                <StickyNote2OutlinedIcon fontSize="small" />
              </HtmlTooltip>
            </div>
          )}
        </StyledDataItem>
      </StyledDataItemRow>
    </div>
  );
};

export const DataItemMobile = memo(Item);
