import React, { Fragment, useState, useEffect } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

import Profile from '../components/profile/index';
import UserProfileProvider from '../context/user-profile.context';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const StyledView = styled('div')(
  ({ theme, $withView }) => css`
    position: relative;
    margin: ${$withView ? 'auto' : '0 auto'};
    margin-bottom: 15px;

    /* ${theme.breakpoints.up('sm')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('md')} {
      width: ${$withView ? '100%' : '90%'};
    }
    ${theme.breakpoints.up('lg')} {
      width: ${$withView ? '100%' : '80%'};
    }
    ${theme.breakpoints.up('xl')} {
      width: ${$withView ? '100%' : '80%'};
    }
    ${theme.breakpoints.down('1400')} {
      width: ${$withView ? '100%' : '98%'};
    } */
    ${theme.breakpoints.down('xl')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('xl')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('1800')} {
      width: ${$withView ? '100%' : '1400px'};
    }
  `
);

const UserProfile = (props) => {
  const profileType = useParams()?.profileType;
  useDocumentTitle(
    profileType == 'customer' ? 'Customer Profile' : 'Agent Profile'
  );

  const history = useHistory();
  const withView = history.location.pathname.includes('contact-manager');

  return (
    <StyledView $withView={withView}>
      <UserProfileProvider
        history={props.history}
        fromManager={props.fromManager}
      >
        <Profile
          {...props}
          fullName={history.location.state?.fullName}
          company={history.location.state?.company}
        />
      </UserProfileProvider>
    </StyledView>
  );
};

export default UserProfile;
