import React from 'react';

import Weather from './Weather';
import Forecast from './Forecast';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const WeatherAndForecastStyle = styled('div')(
  ({ theme }) => css`
    .WeatherAndForecast {
      display: flex;
      flex-direction: column;
      color: #f2f2f2;
      padding: 1rem 1rem;

      @media screen and (max-width: 1000px) {
        border-radius: 0px !important;
        padding: 1rem !important;
        margin-top: 0px;
      }

      @media only screen and (max-width: 1100px) and (min-width: 900px) {
        zoom: 80%;
      }

      @media screen and (min-width: 1300px) {
        padding: 2rem 5rem;
      }
    }

    .WeatherAndForecast__container {
      display: flex;
      justify-content: space-between;
      padding-top: 1.5rem;
    }

    @media (max-width: 600px) {
      .WeatherAndForecast {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  `
);

const dateBuilder = (d) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const date = [];

  for (let count = 0; count < 5; count++) {
    if (d.getDay() + count < 7) date[count] = d.getDay() + count;
    else if (d.getDay() + count === 7) date[count] = 0;
    else if (d.getDay() + count === 8) date[count] = 1;
    else if (d.getDay() + count === 9) date[count] = 2;
    else if (d.getDay() + count === 10) date[count] = 3;
  }

  return [
    days[date[0]],
    days[date[1]],
    days[date[2]],
    days[date[3]],
    days[date[4]],
  ];
};

const WeatherAndForecast = ({ weatherInfo, location }) => {
  const date = dateBuilder(new Date());

  return (
    <WeatherAndForecastStyle>
      <div className="WeatherAndForecast">
        <Weather weatherInfo={weatherInfo} location={location} date={date[0]} />
        <div className="WeatherAndForecast__container">
          <Forecast weatherInfo={weatherInfo.daily[0]} date={date[0]} />
          <Forecast weatherInfo={weatherInfo.daily[1]} date={date[1]} />
          <Forecast weatherInfo={weatherInfo.daily[2]} date={date[2]} />
          <Forecast weatherInfo={weatherInfo.daily[3]} date={date[3]} />
          <Forecast weatherInfo={weatherInfo.daily[4]} date={date[4]} />
        </div>
      </div>
    </WeatherAndForecastStyle>
  );
};

export default WeatherAndForecast;
