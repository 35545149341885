import {
  Button,
  FormControl,
  Input,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { profileService } from '../services';

const rows = [
  {
    id: 1,
    amount: 75.0,
    quantity: 1,
    subTotal: 0,
  },
  {
    id: 2,
    amount: 75.0,
    quantity: 2,
    subTotal: 0,
  },
  {
    id: 3,
    amount: 75.0,
    quantity: 2,
    subTotal: 0,
  },
];

const email = [
  {
    id: 1,
    field: 'amount',
    headerName: 'amount',
    type: 'number',
    editable: true,
    flex: 2,
  },
  {
    id: 2,
    field: 'quantity',
    headerName: 'Quantity',
    type: 'number',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'subtotal',
    headerName: 'Subtotal',
    type: 'calculated',
    editable: true,
    calcColumns: ['amount', 'quantity'], // only put two column you want to perform operations on
    operator: '+', // default is '*' add any other math operator to do that operation
    flex: 2,
  },
];

const AppReactDemos = ({ prop1, prop2, prop3 }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    // You should see email and password in console.
    // ..code to submit form to backend here...
  }

  return (
    // <form onSubmit={handleSubmit}>
    //   <TextField value={email} onChange={(e) => setEmail(e.target.value)} />
    //   <TextField
    //     value={password}
    //     onChange={(e) => setPassword(e.target.value)}
    //   />
    //   <Typography className={''} />
    //   <Button type="submit">Login</Button>
    // </form>

    //  <form onSubmit={handleSubmit} autoComplete="off" noValidate>
    //   <FormControl>
    //     <InputLabel htmlFor="my-input">Email address</InputLabel>
    //     <Input id="my-input" aria-describedby="my-helper-text" />
    //   </FormControl>
    //   <Button type="submit">Login</Button>
    // </form>

    <form onSubmit={handleSubmit}>
      <OutlinedInput
        components={<input />}
        inputProps={{
          autoComplete: 'on',
          form: {
            autoComplete: 'on',
          },
        }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type="submit">Test</Button>
    </form>

    //   <form onSubmit={handleSubmit} autoComplete="off" noValidate>
    //   <FormControl>
    //     <InputLabel htmlFor="my-input">Email address</InputLabel>
    //     <Input
    //       id="my-input"
    //       name="isGoing"
    //       aria-describedby="my-helper-text"
    //       value={email}
    //       onChange={(e) => setEmail(e.target.value)}
    //     />
    //   </FormControl>
    //   <input type="submit" value="Submit" onClick={handleSubmit} />
    // </form>
  );
};

export default AppReactDemos;
