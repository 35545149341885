import React from 'react';
import { TextField, Grid, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import StateAutocomplete from '../inputs/StateAutocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import PhoneNumberField from '../inputs/PhoneNumberField';

const PersonalInfo = ({ control, errors }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              required
              {...field}
              autoComplete="off"
              label="First Name"
              error={!!errors.firstName}
              helperText={errors.firstName ? errors.firstName.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              autoComplete="off"
              label="Last Name"
              error={!!errors.lastName}
              helperText={errors.lastName ? errors.lastName.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="workPhone"
          control={control}
          render={({ field }) => (
            <PhoneNumberField
              {...field}
              required
              autoComplete="off"
              label="Work Phone"
              error={!!errors.workPhone}
              helperText={errors.workPhone ? errors.workPhone.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="userName"
          control={control}
          render={({ field, formState, ...others }) => {
            return (
              <TextField
                {...field}
                required
                autoComplete="off"
                label="Username (Email address)"
                error={!!errors.userName}
                helperText={errors.userName ? errors.userName.message : 'The username must be an email address'}
                fullWidth
                InputProps={{
                  endAdornment: formState?.validatingFields?.userName && field?.value?.length > 0 && (
                    <InputAdornment position="end">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="workEmail"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              autoComplete="off"
              label="Work Email"
              error={!!errors.workEmail}
              helperText={errors.workEmail ? errors.workEmail.message : 'Password Reset will be sent to this address'}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              autoComplete="off"
              label="Address"
              error={!!errors.address}
              helperText={errors.address ? errors.address.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              label="City"
              autoComplete="off"
              error={!!errors.city}
              helperText={errors.city ? errors.city.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <StateAutocomplete
              {...field}
              required
              label="State"
              autoComplete="off"
              error={!!errors.state}
              helperText={errors.state ? errors.state.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="zip"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              label="Zip"
              autoComplete="off"
              autoComplete="--off--"
              inputProps={{ maxLength: 5 }}
              error={!!errors.zip}
              helperText={errors.zip ? errors.zip.message : ''}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
