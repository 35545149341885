import React, { useEffect, useRef, useState } from 'react';
import { store } from '../store';
import { Button, TextField, Card, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

import { userService } from '../services';
import { CountryStates } from '../components/common/ddls/CountryStates';
import Loading from '../components/common/others/Loading';
import { isValidEmail } from '../helpers';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const StyledRegistration = styled('div')(
  ({ theme }) => css`
    text-align: center;
    justify-content: center;

    .root {
      max-width: 800px;
      display: inline-block;
    }

    .MuiCard-root:nth-child(n + 1) {
      padding: 30px;
    }

    .portalLogo {
      padding: 20px 20px;
      width: 100%;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .form-inputs {
      font-size: 15px;
    }

    .main-info {
      font-size: 15px;
    }

    label {
      font-size: inherit;
    }

    .error {
      color: #e53935;
      text-align: left;
      display: block;
      font-size: 0.75rem;
    }

    ${theme.breakpoints.down('sm')} {
      .form-inputs {
        display: 'block';
      }
    }
    .link {
      color: ${theme.palette.primary.main};
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
      h3 {
        margin-left: 12px;
      }
    }
  `
);

const initialState = () => {
  return {
    names: '',
    companyName: '',
    email: '',
    phone: '',
    notes: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    verified: false,
    error: false,

    portalID: -1,
    isSubmitting: false,
    namesError: '',
    companyNameError: '',
    emailError: '',
    phoneError: '',
    addressError: '',
    cityError: '',
    stateError: '',
    zipCodeError: '',
    verifiedError: '',
    notesError: '',
  };
};

const Registration = (props) => {
  useDocumentTitle('Customer Registration'); // Set page title
  const dispatch = useDispatch();
  const { global } = store.getState().reducer;
  const captchaRef = useRef();

  const [state, setState] = useState(initialState());

  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  useEffect(() => {
    return () => {};
  }, []);

  const onChange = (value) => {
    setState((values) => ({
      ...values,
      verified: true,
      verifiedError: '',
    }));
  };

  const isFormValid = () => {
    let isValid = true;

    if (BUILD_ENV !== 'development') {
      if (!state.verified) {
        isValid = false;
        setState((values) => ({
          ...values,
          verifiedError: 'ReCAPTCHA is required.',
        }));
      }
    }

    if (state.names == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        namesError: 'Name field is required.',
      }));
    } else {
      if (state.names.length < 2) {
        isValid = false;
        setState((values) => ({
          ...values,
          namesError: 'Name field should be a valid Name.',
        }));
      }
    }

    if (state.companyName == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        companyNameError: 'Company Name field is required.',
      }));
    }

    if (state.email == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        emailError: 'Email field is required.',
      }));
    } else {
      if (!isValidEmail(state.email)) {
        isValid = false;
        setState((values) => ({
          ...values,
          emailError: 'Email field is not a valid email address.',
        }));
      }
    }

    if (state.phone == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        phoneError: 'Phone field is required.',
      }));
    }

    if (state.address == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        addressError: 'Address field is required.',
      }));
    }

    if (state.city == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        cityError: 'City field is required.',
      }));
    } else {
      if (state.city.length < 4) {
        isValid = false;
        setState((values) => ({
          ...values,
          cityError: 'City field should be a valid City.',
        }));
      }
    }

    if (state.state == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        stateError: 'State field is required.',
      }));
    }

    if (state.zipCode == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        zipCodeError: 'Zip code field is required.',
      }));
    } else {
      if (state.zipCode.length < 4) {
        isValid = false;
        setState((values) => ({
          ...values,
          zipCodeError: 'Zip Code field should be a valid Zip code.',
        }));
      }
    }

    return isValid;
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;

    setState((values) => ({
      ...values,
      [fieldName]: event.target.value,
      [fieldName + 'Error']: '',
    }));
  };

  const verifyCaptcha = async () => {
    if (process.env.NODE_ENV !== 'development') {
      try {
        const captchaValue = captchaRef.current.getValue();
        if (!captchaValue) return false;
        else {
          const res = await fetch(`${SERVICE_URL}/portal/verifyCaptcha`, {
            method: 'POST',
            body: JSON.stringify(captchaValue),
            headers: { 'content-type': 'application/json' },
          });
          const data = await res.json();
          if (data.success) return true;
          else return false;
        }
      } catch (error) {
        return false;
      }
    } else return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setState((values) => ({ ...values, isSubmitting: true }));

    if (isFormValid()) {
      if (!(await verifyCaptcha())) {
        dispatchAlert('Sorry. Unable to verify re-captcha.', true);
        setIsSubmit(false);
        captchaRef.current?.reset();
        return;
      }

      userService.customerRegistration(state).then(
        (res) => {
          dispatch({
            type: 'PUSH_ALERT',
            payload: {
              open: true,
              color: 'success',
              message: `[Replace]Thank you for registering. We will be in touch with you to complete your account setup shortly.`,
              withRedirect: {
                to: '/login',
                text: 'Click here to login',
                isButton: false,
              },
            },
          });

          setState(initialState());
          if (BUILD_ENV !== 'development') {
            window.grecaptcha.reset();
          }
        },
        (error) => {
          dispatch({
            type: 'PUSH_ALERT',
            payload: {
              open: true,
              color: 'error',
              message: '[Replace]There was an error while submiting your information.',
            },
          });
          setState((values) => ({ ...values, isSubmitting: false }));
        }
      );
    } else {
      setState((values) => ({ ...values, isSubmitting: false }));
    }
  };

  return (
    <StyledRegistration>
      <div className="root">
        <div className="portalLogo">
          {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
        </div>
        <Card>
          <div className="view-title">New Customer Registration</div>
          <div className="form-inputs">
            <div className="main-info">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <TextField
                  label="Name *"
                  name="names"
                  value={state.names}
                  helperText={state.namesError}
                  onChange={handleChange}
                  error={state.namesError != ''}
                />
                <TextField
                  label="Company name *"
                  name="companyName"
                  value={state.companyName}
                  helperText={state.companyNameError}
                  onChange={handleChange}
                  error={state.companyNameError != ''}
                />
                <TextField
                  label="Email *"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  helperText={state.emailError}
                  error={state.emailError != ''}
                />
                <NumberFormat
                  customInput={TextField}
                  format="(###) ###-####"
                  mask="_"
                  label="Phone *"
                  name="phone"
                  value={state.phone}
                  onChange={handleChange}
                  helperText={state.phoneError}
                  error={state.phoneError != ''}
                />
                <TextField
                  label="Address *"
                  name="address"
                  value={state.address}
                  onChange={handleChange}
                  helperText={state.addressError}
                  error={state.addressError != ''}
                />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      className="input-address"
                      label="City *"
                      name="city"
                      value={state.city}
                      onChange={handleChange}
                      helperText={state.cityError}
                      error={state.cityError != ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CountryStates
                      required={true}
                      withHelper={true}
                      showError={state.stateError != ''}
                      helperText={state.stateError}
                      value={state.state}
                      handleState={(val) => {
                        setState((values) => ({
                          ...values,
                          state: val ? val : '',
                          stateError: '',
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      customInput={TextField}
                      className="input-zip"
                      format="#####"
                      mask="_"
                      label="Zip code *"
                      name="zipCode"
                      value={state.zipCode}
                      onChange={handleChange}
                      helperText={state.zipCodeError}
                      error={state.zipCodeError != ''}
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Additional Notes"
                  name="notes"
                  multiline
                  rows={3}
                  value={state.notes}
                  onChange={handleChange}
                  helperText={state.notesError}
                  error={state.notesError != ''}
                />
                {BUILD_ENV !== 'development' && (
                  <ReCAPTCHA
                    ref={captchaRef}
                    name="verified"
                    sitekey={RECAPTCHA_KEY}
                    style={{ marginTop: '10px' }}
                    value={state.verified}
                    onChange={onChange}
                  />
                )}
                <span className="error">{state.verifiedError}</span>
                <br />
                <div style={{ textAlign: 'end' }}>
                  <div style={{ float: 'left', paddingTop: '7px' }}>
                    <label
                      style={{
                        marginRight: '10px',
                        verticalAlign: 'bottom',
                      }}
                    >
                      {'Already have an account?'}
                    </label>

                    <Link
                      className="link"
                      to={{
                        pathname: '/login',
                      }}
                      style={{
                        marginRight: '20px',
                        verticalAlign: 'bottom',
                      }}
                    >
                      {'Click here to login'}
                    </Link>
                  </div>
                  <Button type="submit" style={{ float: 'right' }} variant="contained" disabled={state.isSubmitting}>
                    Register {state.isSubmitting && <Loading forButton lpad />}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </div>
    </StyledRegistration>
  );
};

export default Registration;
