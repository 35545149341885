import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import TabControls from './TabControls';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#eee2e214',
    },
  },
  nested: {
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
  },
  selected: {},
}));

const TabItem = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ListItem
        selected={props.selected}
        className={`${
          props.isNested ? classes.nested : { selected: classes.selected }
        }`}
      >
        <ListItemText primary={props.tabName} />
        <TabControls {...props} />
      </ListItem>
    </div>
  );
};

export default TabItem;
