import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import TabItem from './TabItem';
import TabControls from './TabControls';

const useStyles = makeStyles((theme, context) => ({
  root: {},
  controls: { textAlign: 'end' },
}));

const TabCollapse = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <ListItem button onClick={() => setOpen(!open)} selected={open}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <span style={{ fontSize: '1rem' }}>{props.tabName}</span>
        <ListItemText className={classes.controls}>
          <TabControls
            {...props}
            onMove={props.onMove}
            onSucceedAction={props.onSucceedAction}
          />
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.tabChildren.map((item) => (
            <TabItem
              key={item.index}
              {...item}
              customCSS
              isNested
              selected={open}
              onMove={props.onMove}
              onSucceedAction={props.onSucceedAction}
            />
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default TabCollapse;
