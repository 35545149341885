import { browserName } from 'react-device-detect';

let portalContext = localStorage.getItem('NSPortalContext') ? JSON.parse(localStorage.getItem('NSPortalContext')) : '';

let nsActiveTabID = localStorage.getItem('NSActiveTabID')
  ? parseInt(JSON.parse(localStorage.getItem('NSActiveTabID')))
  : 0;

let nsUserInfo = localStorage.getItem('NSUserInfo') ? JSON.parse(localStorage.getItem('NSUserInfo')) : '';
let nsPortalTabs = localStorage.getItem('portalTabs') ? JSON.parse(localStorage.getItem('portalTabs')) : [];
let currentTheme = localStorage.getItem('NSThemeMode') ? localStorage.getItem('NSThemeMode') : null;

let mgrReqStorage = localStorage.getItem('NSMgrReqStorage')
  ? JSON.parse(localStorage.getItem('NSMgrReqStorage'))
  : localStorage.setItem(
      'NSMgrReqStorage',
      JSON.stringify({
        compactViewOn: 'off',
        altCardColor: false,
        lockViewOnScroll: false,
      })
    );

let contactMgrStorage = localStorage.getItem('NSContactMgrStorage')
  ? JSON.parse(localStorage.getItem('NSContactMgrStorage'))
  : localStorage.setItem(
      'NSContactMgrStorage',
      JSON.stringify({
        compactViewOn: 'off',
        altCardColor: false,
        lockViewOnScroll: false,
      })
    );

const hiddenMenuWidth = '0px';
const iconsMenuWidth = '65px';
const expandedMenuWidth = '300px';

export const initialState = {
  browser: browserName,
  tokenExpired: false,
  accessToken: {
    token: '',
    lastRefreshTokenDate: '',
    onLogin: false,
    isRefreshing: false,
  },
  global: {
    isAuthenticated: nsUserInfo.isAuthenticated,
    portalID: portalContext.portalID,
    superUserId: portalContext.superUserId,
    logoFile: portalContext.logoFile,
    portalName: portalContext.portalName,
    email: portalContext.email,
    portalAliasHashed: portalContext.portalAliasHashed,
  },
  currentUser: {
    detail: nsUserInfo,
    userRoles: nsUserInfo?.roles || [],
    userHasRoles: nsUserInfo.roles ? true : false,
  },
  mixedRoute: false,
  showLoginBar: false,
  portalTabs: nsPortalTabs,
  currentPortalID: portalContext.portalID || -1,
  activeTabID: nsActiveTabID || 0,
  snackbar: {
    open: false,
    color: 'inherit',
    message: '',
  },
  appAlerts: [],
  isPageInEditMode: false,
  showEditAlertModal: false,
  appViewport: 'xs',
  menuState: {
    current: 'hidden',
    width: hiddenMenuWidth,
  },
  themeAppearance: {
    customTheme: currentTheme ? currentTheme.split('|')[0] : 'lightTheme',
    customColor: currentTheme ? currentTheme.split('|')[1] : 'green',
  },
  portalStorage: {
    mgrReqStorage: {
      compactViewOn: mgrReqStorage?.compactViewOn || 'off',
      altCardColor: mgrReqStorage?.altCardColor || false,
      lockViewOnScroll: mgrReqStorage?.lockViewOnScroll || false,
    },
    contactMgrStorage: {
      compactViewOn: contactMgrStorage?.compactViewOn || 'off',
      altCardColor: contactMgrStorage?.altCardColor || false,
      lockViewOnScroll: contactMgrStorage?.lockViewOnScroll || false,
    },
  },
  promptState: '',
  needServiceAgreementSigned: false,
};

// state represent the previous state
// acction reprenset the current action begin proceessed
// reducer always return a new state or the old stat. It never modify the state
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GLOBALS':
      return { ...state, global: action.payload };
    case 'SET_MIXED_ROUTE':
      return { ...state, mixedRoute: action.payload };
    case 'SET_SHOW_LOGIN':
      return { ...state, showLoginBar: action.payload };
    case 'SET_PORTAL_TABS':
      return { ...state, portalTabs: action.payload };
    case 'SET_CURRENT_PORTAL_ID':
      return { ...state, currentPortalID: action.payload };
    case 'SET_ACTIVE_TAB_ID':
      return { ...state, appAlerts: [], activeTabID: action.payload };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        currentUser: {
          detail: action.payload,
          userRoles: action.payload?.roles || [],
          userHasRoles: action.payload?.roles.length > 0 ? true : false,
        },
        needServiceAgreementSigned: !!action?.payload?.needServiceAgreementSigned,
        accessToken: {
          onLogin: action.onLogin,
        },
      };

    case 'SET_SIGNED_AGREEMENT':
      return {
        ...state,
        needServiceAgreementSigned: !!action?.payload?.needServiceAgreementSigned,
      };

    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: {
          token: action.payload.token || state.accessToken.token,
          lastRefreshTokenDate: action.payload.lastRefreshTokenDate || '',
          onLogin: action.payload.onLogin,
          isRefreshing: action.payload.isRefreshing || false,
        },
      };
    case 'SET_PAGE_IN_EDIT_MODE':
      return { ...state, isPageInEditMode: action.payload };
    case 'SET_SHOW_EDIT_ALERT_MODAL':
      return { ...state, showEditAlertModal: action.payload };
    case 'LOGOUT':
      localStorage.removeItem('NSUserInfo');
      localStorage.removeItem('NSActiveTabID');
      localStorage.removeItem('NSCurrentPortalID');
      localStorage.removeItem('portalTabs');
      localStorage.removeItem('NSPortalContext');
      localStorage.removeItem('NSRefreshToken');
      localStorage.removeItem('NSUserID');

      return {
        ...state,
        global: { isAuthenticated: false },
        activeTabID: 0,
        portalTabs: [],
        currentUser: {
          detail: '',
          userRoles: [],
          userHasRoles: false,
        },
        accessToken: { token: '' },
        menuState: { current: 'hidden', width: hiddenMenuWidth },
      };
    case 'snackBar':
      return { ...state, snackbar: action.payload };
    case 'CLEAR_ALERTS':
      return { ...state, appAlerts: [] };
    case 'PUSH_ALERT':
      let alerts = [...state.appAlerts];
      alerts = alerts.filter((i) => i.message.indexOf('[Replace]') === -1);
      return { ...state, appAlerts: [...alerts, action.payload] };
    case 'REMOVE_ALERT':
      let alertsClone = _.cloneDeep([...state.appAlerts]);
      return {
        ...state,
        appAlerts: alertsClone.filter((el) => {
          return el.id !== action.payload.id;
        }),
      };
    case 'SET_MENU_STATE':
      switch (action.payload) {
        case 'hidden':
          return {
            ...state,
            menuState: { current: action.payload, width: hiddenMenuWidth },
          };
        case 'icons':
          return {
            ...state,
            menuState: {
              current: action.payload,
              width: ['xs', 'sm'].includes(state.appViewport) ? expandedMenuWidth : iconsMenuWidth,
            },
          };
        case 'expanded':
          return {
            ...state,
            menuState: { current: action.payload, width: expandedMenuWidth },
          };
        default:
          break;
      }
      return true;
    case 'SET_APP_VIEWPORT':
      return {
        ...state,
        appViewport: action.payload,
      };
    case 'SET_CURRENT_THEME':
      let customTheme = action.payload.customTheme || state.themeAppearance.customTheme;
      let customColor = action.payload.customColor || state.themeAppearance.customColor;
      localStorage.setItem('NSThemeMode', customTheme + '|' + customColor);
      return {
        ...state,
        themeAppearance: {
          customTheme: customTheme,
          customColor: customColor,
        },
      };
    case 'SET_PORTAL_STORAGE':
      let compactViewOnST = state.portalStorage.mgrReqStorage.compactViewOn;
      let altCardColor = state.portalStorage.mgrReqStorage.altCardColor;
      let lockViewOnScroll = state.portalStorage.mgrReqStorage.lockViewOnScroll;

      let newState = {
        compactViewOn: action.payload.compactViewOn === undefined ? compactViewOnST : action.payload.compactViewOn,
        altCardColor: action.payload.altCardColor === undefined ? altCardColor : action.payload.altCardColor,
        lockViewOnScroll:
          action.payload.lockViewOnScroll === undefined ? lockViewOnScroll : action.payload.lockViewOnScroll,
      };

      localStorage.setItem('NSMgrReqStorage', JSON.stringify(newState));

      return { ...state, portalStorage: { ...state.portalStorage, mgrReqStorage: newState } };
    case 'SET_PORTAL_STORAGE_CONTACT_MGR':
      let compactViewOnST_ContMgr = state.portalStorage.contactMgrStorage.compactViewOn;
      let altCardColor_ContMgr = state.portalStorage.contactMgrStorage.altCardColor;
      let lockViewOnScroll_ContMgr = state.portalStorage.contactMgrStorage.lockViewOnScroll;

      let newState_ContMgr = {
        compactViewOn:
          action.payload.compactViewOn === undefined ? compactViewOnST_ContMgr : action.payload.compactViewOn,
        altCardColor: action.payload.altCardColor === undefined ? altCardColor_ContMgr : action.payload.altCardColor,
        lockViewOnScroll:
          action.payload.lockViewOnScroll === undefined ? lockViewOnScroll_ContMgr : action.payload.lockViewOnScroll,
      };

      localStorage.setItem('NSContactMgrStorage', JSON.stringify(newState_ContMgr));

      return { ...state, portalStorage: { ...state.portalStorage, contactMgrStorage: newState_ContMgr } };
    case 'SET_ROUTE_PROMPT_STATE':
      return { ...state, promptState: action.payload };
    default:
      return state;
  }
};
