import React, { lazy, useContext, useState, useEffect, Fragment } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const merge = require('lodash.merge');

import { SigningRequestContext } from '../../../context/signing-request.context';
import RequestActions from './RequestActions';
import { format, parseISO } from 'date-fns';
import { store } from '../../../store';
import UploadFilesDialog from '../../common/file-uploader/upload-files-dialog';
import FileUploadProvider from '../../common/file-uploader/FileUploadContext';
import FormProvider, { FormContext } from './FormContext';

const RequestInfo = lazy(() => import('./RequestInfo'));
const SigningInfo = lazy(() => import('./SigningInfo'));
const BorrowerInfo = lazy(() => import('./BorrowerInfo'));
const SigningAltLocation = lazy(() => import('./SigningAltLocation'));
const InvoiceContactInfo = lazy(() => import('./InvoiceContactInfo'));
const CompletionInfo = lazy(() => import('./CompletionInfo'));
const BillingSection = lazy(() => import('./BillingSection'));

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      marginBottom: '10px',
    },

    '& > div .fixed': {
      boxShadow: 'none',
    },
  },
}));

const SigningRequestForm = (props) => {
  if (props.gettingData === undefined || props.gettingData) {
    throw new Promise(function () {
      setTimeout(function () {
        return true;
      }, 0);
    });
  }

  const classes = useStyles();
  const scrollHeigh = 150;
  const { appViewport: viewport } = store.getState().reducer;
  const userRoles = store.getState().reducer.currentUser.userRoles;

  const {
    settings,
    signingRequest,
    borrowerSection,
    altLocationSection,
    appointmentSection,
    invoiceContactSection,
    completionInfoSection,
    billingSection,
    setSigningRequest,
    submitSigningRequest,
    saveSigningRequest,
  } = useContext(SigningRequestContext);

  const [documentData, setDocumentData] = useState(null);
  const [openDocUploadDialog, setOpenDocUploadDialog] = useState(false);
  const [showFixed, setShowFixed] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      const newShowFixed = window.scrollY > scrollHeigh;
      showFixed !== newShowFixed && setShowFixed(newShowFixed);
    };

    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [showFixed]);

  const callOpenDocumentUploadDialog = () => {
    setDocumentData({
      title:
        'Request #' +
        signingRequest.requestSeqID +
        ' - ' +
        [signingRequest.borrower.borrowerFirstName, signingRequest.borrower.borrowerLastName].join(' ') +
        ' ' +
        format(parseISO(signingRequest.signingDate), 'MM/dd/yyyy'),
      requestID: signingRequest.requestID,
      requestSeqID: signingRequest.requestSeqID,
    });

    setOpenDocUploadDialog(true);
  };

  const submitSigningForm = async (fromData, action) => {
    let _borrowerFormData = fromData.borrowerSection ? fromData.borrowerSection : borrowerSection;

    const dataToSave = merge(signingRequest, {
      borrower: { ..._borrowerFormData },
      ...(fromData.altLocationSection ?? altLocationSection),
      ...fromData.appointmentSection,
      ...(fromData.invoiceContactSection ?? invoiceContactSection),
      ...(fromData.completionInfoSection ?? completionInfoSection),
      customerPricing: fromData.billingSection?.customerPricing
        ? { ...fromData.billingSection.customerPricing }
        : { ...billingSection.customerPricing },
      agentPricing: fromData.billingSection?.agentPricing
        ? { ...fromData.billingSection.agentPricing }
        : { ...billingSection.agentPricing },
      // ...{ ...undefinedSection, signingZipForProximity: _borrowerFormData.signingZipCode },
      signingZipForProximity: _borrowerFormData.signingZipCode,
    });

    setSigningRequest(dataToSave);

    if (action == 'Submit') return submitSigningRequest(dataToSave);
    else if (action == 'Save') return saveSigningRequest(dataToSave);
  };

  const isServiceOwner = () => {
    return userRoles.indexOf('SuperUser') >= 0 || userRoles.indexOf('ServiceOwner') >= 0;
  };

  return (
    <div className={`${classes.root} big-form`}>
      <Fragment>
        {isServiceOwner() && signingRequest.requestID > 0 && !['xs'].includes(viewport) && <RequestActions />}
        {settings.hyp_UploadDocs_Visible && signingRequest.requestSeqID > 0 && (
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={(e) => callOpenDocumentUploadDialog()} startIcon={<CloudUploadIcon />}>
              Click Here to Upload Documents for this Signing
            </Button>
          </div>
        )}
        <FormProvider editOn={props.editOn} onSubmit={submitSigningForm}>
          {!showFixed && <RequestInfo showTitle={props.showTitle} />}
          {showFixed && <RequestInfo className="fixed" showTitle={props.showTitle} />}
          <BorrowerInfo data={borrowerSection} />
          <SigningAltLocation settings={settings} data={altLocationSection} />
          <SigningInfo data={appointmentSection} />
          <InvoiceContactInfo settings={settings} data={invoiceContactSection} />
          <CompletionInfo settings={settings} data={completionInfoSection} />
          <BillingSection
            data={billingSection}
            settings={{
              pnl_Prices_Visible: settings.pnl_Prices_Visible,
              pnl_CustomerFees_Visible: settings.pnl_CustomerFees_Visible,
              pnl_AgentFees_Visible: settings.pnl_AgentFees_Visible,
            }}
          />
        </FormProvider>
        {openDocUploadDialog && (
          <FileUploadProvider context={{ uploadDocType: 'SigningFiles', requestID: signingRequest.requestID }}>
            <UploadFilesDialog
              open={openDocUploadDialog}
              title={'Documents Upload'}
              buttonTrueText={'Save'}
              buttonFalseText={'Close'}
              documentData={documentData}
              onClose={(e) => setOpenDocUploadDialog(false)}
            />
          </FileUploadProvider>
        )}
      </Fragment>
    </div>
  );
};

export default SigningRequestForm;
