import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Select,
  ButtonGroup,
  Popper,
  Paper,
  Grow,
  FormGroup,
  Chip,
  Tooltip,
  Link,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { EditableTable } from '../common/others/EditableTable';
import { agreedUponColumns } from './ProfileContent/profileInfo';
import { useParams } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SendIcon from '@mui/icons-material/Send';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import DownloadIcon from '@mui/icons-material/Download';

import { UserProfileContext } from '../../context/user-profile.context';
import ProfileTextField from './ProfileTextField';
import { AppTextarea } from '../common/others/NSTextarea';
import { store } from '../../store';
import { AntSwitch, FileChip } from '../../Style';
import FileUploadProvider from '../common/file-uploader/FileUploadContext';
import UploadFilesDialog from '../common/file-uploader/upload-files-dialog';
import moment from 'moment';
import { dispatchAlert, isValidEmail, isValidEmailOr } from '../../helpers';
import AppAlertDialog from '../common/others/AppAlertDialog';
import AppConfirmationDialog from '../common/others/AppConfirmationDialog';
import { UploadFilesService, userService } from '../../services';
import InfoTooltip from '../common/others/InfoTooltip';

const StyledRoot = styled(Card)(
  ({ theme, $editMode, $withMenu }) => css`
    position: relative;
    overflow: inherit;
    display: flow-root;

    .MuiCardHeader-root {
      font-size: 14px;
      padding: 10px;

      & .MuiTypography-root {
        color: #266b7b;
        font-weight: 500;
      }
    }

    /* .MuiCardContent-root {
      padding: 15px 15px 0px;
    }

    .MuiCardContent-root:last-of-type {
      padding-bottom: 0px;
    } */

    & .service-row {
      align-items: center;
      flex-flow: wrap;
      margin-bottom: 10px;

      .MuiTypography-root {
        font-weight: 500;
      }

      .reg-info-status {
        display: flex;
        color: ${!$editMode ? (theme.palette.mode == 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0, 0, 0, 0.38)') : ''};
      }
    }

    & .service-col {
      width: 100%;

      @media screen and (max-width: 500px) {
        align-items: left;
        width: 100%;

        & .table {
          align-items: left;
          width: 95%;
        }
      }

      & .table {
        align-items: center;
        padding-right: 4px;
      }
    }

    .content-section.flex {
      padding: 0px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      ${theme.breakpoints.between('md', 'lg')} {
        & {
          display: ${$withMenu ? 'block' : 'flex'};
        }
      }

      ${theme.breakpoints.down('md')} {
        & {
          display: block;
        }
      }
    }
  `
);

const DocItem = ({ item, editMode, emitNewValue }) => {
  if (item.code === 'Reg_OC') return <React.Fragment></React.Fragment>;

  const origExpirationDate = useRef(null);

  const [state, setState] = useState({
    isShared: false,
    expirationDate: null,
  });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    origExpirationDate.current = item.expirationDate || null;
    let dateIsValid = moment(item.expirationDate).isValid();
    setState({
      isShared: item.isShared,
      expirationDate: dateIsValid ? moment(item.expirationDate) : null,
    });

    return () => {};
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup aria-label="split button" size="small" color="default" disabled={!editMode} ref={anchorRef}>
        <Button>
          <FormGroup>
            <FormControlLabel
              sx={{
                marginRight: 0,
                '& .MuiTypography-root': {
                  fontSize: '0.75rem !important',
                  paddingLeft: '5px',
                },
              }}
              label={`${item.name} ${
                item.isCustom ? (state.expirationDate ? `| ${state.expirationDate?.format('L')}` : '') : ''
              }`}
              control={
                <Checkbox
                  sx={{ p: 0, pl: '5px' }}
                  disabled={!editMode}
                  checked={state.isShared}
                  onChange={(e) => {
                    setState({ ...state, isShared: e.target.checked });
                    emitNewValue({ ...state, isShared: e.target.checked });
                  }}
                />
              }
            />
          </FormGroup>
        </Button>
        {item.isCustom && (
          <Button
            size="small"
            aria-controls={open ? 'calendar-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select expiration date"
            aria-haspopup="menu"
            sx={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
            onClick={handleToggle}
          >
            <CalendarMonthOutlinedIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card sx={{ p: '10px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <h3>Expiration Date</h3>
                    </div>
                    <div>
                      <Button
                        variant="text"
                        onClick={() => {
                          let dateRef = origExpirationDate.current;
                          let dateIsValid = moment(dateRef).isValid();
                          let _dateRef = dateIsValid ? moment(dateRef) : null;
                          setState({ ...state, expirationDate: _dateRef });
                          emitNewValue({ ...state, expirationDate: _dateRef });
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setState({ ...state, expirationDate: null });
                          emitNewValue({ ...state, expirationDate: null });
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                      sx={{
                        maxHeight: '320px',
                        '& .MuiPickersSlideTransition-root': {
                          minHeight: '200px',
                          overflowX: 'visible',
                        },
                      }}
                      actions={['clear']}
                      id="calendar-button-menu"
                      value={state.expirationDate}
                      onChange={(newValue) => {
                        setState({ ...state, expirationDate: newValue });
                        emitNewValue({ ...state, expirationDate: newValue });
                      }}
                    />
                  </LocalizationProvider>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export const ServiceOwnerData = (props) => {
  const {
    fake,
    fake1,
    fake2,
    fake3,
    profileId,
    ownerData,
    setOwnerData,
    profileInfo,
    updatedProfile,
    setUpdatedProfile,
  } = useContext(UserProfileContext);
  const { canEdit, editMode } = props;
  const { profileType } = useParams();
  const { current: menuState } = store.getState().reducer.menuState;
  const [customerDefaultFiles, setCustomerDefaultFiles] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openFileUploader, setOpenFileUploader] = useState(false);
  const [fileError, setFileError] = useState('');
  const [userNameCopied, setUserNameCopied] = useState(false);
  const [confirmPassReset, setConfirmPassReset] = useState(false);

  const profileUpdate = (value, profileObj) => {
    setUpdatedProfile({ ...updatedProfile, [profileObj]: value });
    setOwnerData({ ...ownerData, [profileObj]: value });
  };

  useEffect(() => {
    setCustomerDefaultFiles([...new Array(), ownerData.CustomizedData?.attachment || []]);
    return () => {};
  }, []);

  const customerFiles = () => {
    return (
      <Autocomplete
        className={''}
        multiple
        readOnly
        size="small"
        margin="dense"
        freeSolo
        disableClearable
        forcePopupIcon={false}
        open={false}
        onOpen={() => {}}
        value={customerDefaultFiles}
        onChange={(event, newValue) => {}}
        options={customerDefaultFiles}
        getOptionLabel={(option) => option.name}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <FileChip
              size="small"
              label={option.name || 'No document on file.'}
              key={index}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            margin={props.margin}
            // label={'Attachment for Agent (300KB per file, Max-Total=1MB)'}
            error={fileError ? true : false}
            helperText={fileError ? fileError : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Button
                  color="default"
                  component="span"
                  onClick={() => {
                    if (profileId == 'new')
                      return setOpenAlert({
                        open: true,
                        msg: 'Customer profile must be saved before attachment can be added.',
                      });
                    setOpenFileUploader(true);
                  }}
                >
                  <AttachFileIcon /> Upload
                </Button>
              ),
            }}
          />
        )}
      />
    );
  };

  const [inputValue, setInputValue] = React.useState({
    ccEmailDefault: '',
    scanbackRecipients: '',
  });

  const handleInputChange = (event, newInputValue, prop) => {
    if (prop === 'scanbackRecipients') {
      if (ownerData?.CustomizedData?.scanbackRecipients?.split(';').length >= 3) {
        setInputValue({ ...inputValue, [prop]: '' });
        event.preventDefault();
        return;
      }
    } else if (prop === 'ccEmailDefault') {
      if (ownerData?.CustomizedData?.ccEmailDefault?.split(';').length >= 5) {
        setInputValue({ ...inputValue, [prop]: '' });
        event.preventDefault();
        return;
      }
    }

    const options = newInputValue.split(/[ ,;]+/);
    if (options.length > 1) event.persist();
    else setInputValue({ ...inputValue, [prop]: newInputValue });
  };

  const handleKeyDown = (event, prop) => {
    let val = event.target.value;

    if (['Tab', 'Enter', ',', ';'].includes(event.key) || event.type == 'blur') {
      if (isValidEmail(val)) {
        let tempData = _.cloneDeep(ownerData?.CustomizedData);
        if (tempData[prop]?.split(';')?.length >= 5) event.preventDefault();
        tempData[prop] += ';' + val;
        tempData[prop] = tempData[prop].replace(/^;/, '');

        profileUpdate(tempData, 'CustomizedData');
        setInputValue({ ...inputValue, [prop]: '' });
      } else setInputValue({ ...inputValue, [prop]: '' });
    }
  };

  const handleUserNameCopyChange = async (event) => {
    setUserNameCopied(event.target.checked);
    try {
      await navigator.clipboard.writeText(ownerData?.CustomizedData?.agentUserName);
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    setTimeout(() => {
      setUserNameCopied(false);
    }, 500);
  };

  const handlePassResetConfirmation = (event) => {
    if (event) {
      let agentUserName = ownerData?.CustomizedData?.agentUserName;
      if (agentUserName) {
        userService.forgotAgentPassword({ userName: agentUserName }).then(
          (res) => {
            dispatchAlert('A password reset email was sent to the email on file for this account.');
            setConfirmPassReset(false);
          },
          (error) => {
            dispatchAlert('An account with that username was not found.', true);
            setConfirmPassReset(false);
          }
        );
      }
    } else setConfirmPassReset(false);
  };

  const workEmail = profileInfo.Emails ? profileInfo.Emails.find((email) => email.lookupName === 'Work')?.address : '';

  const checkIfOCMustBeShown = () => {
    let agentDocs = ownerData.RegistrationInfo?.AgentDocuments.find((doc) => doc.code === 'Reg_OC' && doc.isShared);
    if (agentDocs && ownerData.ServiceAgreement === null) return agentDocs;
    else return null;
  };

  const handleDownload = React.useCallback((docID) => {
    UploadFilesService.downloadFile({ uploadDocType: 'ServiceAgreementForAgent' }, docID);
  }, []);

  return (
    <StyledRoot $editMode={editMode} $canEdit={canEdit} $withMenu={menuState == 'expanded' ? true : false}>
      <CardHeader
        subheader={'Customized Data for Service Owners'}
        action={
          <>
            {profileType === 'agent' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ verticalAlign: 'middle', fontWeight: 500 }}>Agent Username:</span>
                <Tooltip title={ownerData?.CustomizedData?.agentUserName}>
                  <FormControlLabel
                    sx={{
                      marginRight: '0px',
                      marginLeft: '5px',
                      '.MuiFormControlLabel-label': { fontSize: '0.8rem', color: userNameCopied ? 'green' : '' },
                      '.MuiButtonBase-root': { padding: '7px', marginLeft: '-3px' },
                      '.MuiTypography-root': { maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' },
                    }}
                    labelPlacement="start"
                    label={`${userNameCopied ? 'Copied!' : ownerData?.CustomizedData?.agentUserName}`}
                    control={
                      <Checkbox
                        checked={userNameCopied}
                        onChange={handleUserNameCopyChange}
                        icon={<ContentCopyOutlinedIcon color="default" />}
                        checkedIcon={<LibraryAddCheckOutlinedIcon color="success" />}
                      />
                    }
                  />
                </Tooltip>
                <Divider sx={{ margin: '8px  10px 8px' }} orientation="vertical" flexItem></Divider>
                <Link
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    mr: '10px',
                    ml: '5px',
                    textAlign: 'end',
                  }}
                  component="button"
                  variant="body2"
                  underline="hover"
                  onClick={() =>
                    setConfirmPassReset('Are you sure you want to send a password reset email to the email below?')
                  }
                >
                  Send Password Reset <SendIcon />
                </Link>
              </div>
            )}
          </>
        }
      />
      <CardContent>
        <div className="content-section flex">
          <div className="service-col">
            <div className="service-row">
              <Typography variant="body2">
                Default Invoice Notes:
                <Typography variant="caption"> 500 characters</Typography>
              </Typography>
              <AppTextarea
                showBorder
                maxRows={3}
                edit={canEdit}
                disabled={!editMode}
                value={ownerData?.CustomizedData?.invoiceNotes || ''}
                onChange={(val) => {
                  let tempData = _.cloneDeep(ownerData?.CustomizedData);
                  if (tempData !== null) tempData.invoiceNotes = val;
                  else tempData = { invoiceNotes: val };
                  profileUpdate(tempData, 'CustomizedData');
                }}
              />
            </div>
            <div className="service-row">
              <Typography variant="body2">Agreed Upon Fees:</Typography>
              <EditableTable
                addNew
                maxRows={5}
                edit={canEdit}
                columns={agreedUponColumns}
                rows={ownerData.CustomizedData?.agreedUponFees}
                emitNewRows={(e) => {
                  let tempData = _.cloneDeep(ownerData?.CustomizedData);
                  tempData.agreedUponFees = e.state;
                  profileUpdate(tempData, 'CustomizedData');
                }}
              />
            </div>
          </div>
          <div className="service-col">
            <div className="service-row">
              <Typography variant="body2">CC Email Default:</Typography>
              <Autocomplete
                freeSolo
                multiple
                limitTags={3}
                clearIcon={false}
                disabled={!editMode}
                options={[]}
                inputValue={inputValue.ccEmailDefault}
                onInputChange={(event, val) => handleInputChange(event, val, 'ccEmailDefault')}
                value={ownerData?.CustomizedData?.ccEmailDefault?.split(';')}
                onChange={(e, val) => {
                  e.stopPropagation();
                  if (!isValidEmailOr(e.target.value)) return;
                  let tempData = _.cloneDeep(ownerData?.CustomizedData);
                  tempData.ccEmailDefault = val.join(';').replace(/^;/, '');
                  profileUpdate(tempData, 'CustomizedData');
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    if (option) {
                      return (
                        <Chip
                          sx={{ opacity: 'initial !important' }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          color={`${isValidEmailOr(option) ? 'primary' : 'error'}`}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      );
                    }
                  })
                }
                renderInput={(params) => {
                  params.inputProps.onKeyDown = (event) => handleKeyDown(event, 'ccEmailDefault');
                  params.inputProps.onBlur = (event) => handleKeyDown(event, 'ccEmailDefault');
                  return (
                    <TextField
                      {...params}
                      helperText={'Enter a max of 5 CC emails'}
                      error={!isValidEmailOr(params.inputProps.value) ? true : false}
                    />
                  );
                }}
              />
            </div>
            {profileType === 'customer' && (
              <div className="service-row">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">Scanback Notice</Typography>
                  <Typography variant="caption">Disable Email</Typography>
                  <AntSwitch
                    sx={{
                      '.Mui-disabled + .MuiSwitch-track': {
                        opacity: '0.5 !important',
                      },
                    }}
                    disabled={!editMode}
                    checked={ownerData?.CustomizedData?.disableScanBackNotice || false}
                    onChange={(event) => {
                      let tempData = _.cloneDeep(ownerData?.CustomizedData);
                      tempData.disableScanBackNotice = event.target.checked;
                      profileUpdate(tempData, 'CustomizedData');
                    }}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                </Stack>
                <Autocomplete
                  freeSolo
                  multiple
                  limitTags={3}
                  clearIcon={false}
                  disabled={!editMode}
                  options={[]}
                  inputValue={inputValue.scanbackRecipients}
                  onInputChange={(event, val) => handleInputChange(event, val, 'scanbackRecipients')}
                  value={ownerData?.CustomizedData?.scanbackRecipients?.split(';')}
                  onChange={(e, val) => {
                    e.stopPropagation();
                    if (!isValidEmailOr(e.target.value)) return;
                    let tempData = _.cloneDeep(ownerData?.CustomizedData);
                    tempData.scanbackRecipients = val.join(';').replace(/^;/, '');
                    profileUpdate(tempData, 'CustomizedData');
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      if (option) {
                        return (
                          <Chip
                            sx={{ opacity: 'initial !important' }}
                            deleteIcon={<CloseIcon />}
                            variant="outlined"
                            color={`${isValidEmailOr(option) ? 'primary' : 'error'}`}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      }
                    })
                  }
                  renderInput={(params) => {
                    params.inputProps.onKeyDown = (event) => handleKeyDown(event, 'scanbackRecipients');
                    params.inputProps.onBlur = (event) => handleKeyDown(event, 'scanbackRecipients');
                    return (
                      <TextField
                        {...params}
                        helperText={'Enter a max of 3 emails'}
                        error={!isValidEmailOr(params.inputProps.value) ? true : false}
                      />
                    );
                  }}
                />
              </div>
            )}
            <div className="service-row">
              <Typography variant="body2">Hide In List:</Typography>
              {editMode && canEdit ? (
                <TextField
                  select
                  value={ownerData?.CustomizedData?.hideInList || false}
                  onChange={(e) => {
                    let tempData = _.cloneDeep(ownerData?.CustomizedData);
                    tempData.hideInList = e.target.value;
                    profileUpdate(tempData, 'CustomizedData');
                  }}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              ) : (
                <span>{ownerData.CustomizedData?.hideInList ? 'Yes' : 'No'}</span>
              )}
            </div>
            <div className="service-row">
              <Typography variant="body2">
                Comments:
                <Typography variant="caption"> 1000 characters</Typography>
              </Typography>
              <AppTextarea
                showBorder
                minRows={5}
                maxLength={1000}
                edit={canEdit}
                disabled={!editMode}
                value={ownerData.CustomizedData?.comments || ''}
                onChange={(val) => {
                  let tempData = _.cloneDeep(ownerData?.CustomizedData);
                  tempData.comments = val;
                  profileUpdate(tempData, 'CustomizedData');
                }}
              />
            </div>
          </div>
        </div>
        <div className="content-section">
          {profileType === 'customer' && (
            <>
              <div className="service-row">
                <Typography variant="body2">
                  Custom Signing Instructions:
                  <Typography variant="caption"> 7700 characters</Typography>
                </Typography>
                <AppTextarea
                  showBorder
                  minRows={3}
                  maxLength={7700}
                  placeholder="Custom Signing Instructions..."
                  edit={canEdit}
                  disabled={!editMode}
                  value={ownerData.CustomizedData?.customInstructions}
                  onChange={(val) => {
                    let tempData = _.cloneDeep(ownerData?.CustomizedData);
                    tempData['customInstructions'] = val;
                    profileUpdate(tempData, 'CustomizedData');
                  }}
                />
              </div>
              {editMode && canEdit ? (
                <div className="service-row">
                  <Typography variant="body2">Confirmation Attachment For Notary:</Typography>
                  {customerFiles()}
                </div>
              ) : (
                <div className="service-row">
                  <Typography variant="body2">Confirmation Attachment For Notary:</Typography>
                  {customerFiles()}
                </div>
              )}
              {openAlert.open && (
                <AppAlertDialog
                  severity="info"
                  open={openAlert.open}
                  title={'Documents Upload'}
                  content={openAlert.msg}
                  onClose={() => setOpenAlert(false)}
                />
              )}
              {openFileUploader && (
                <FileUploadProvider context={{ uploadDocType: 'CustomerDefault', customerID: profileId }}>
                  <UploadFilesDialog
                    open={openFileUploader}
                    title={'Documents Upload'}
                    buttonTrueText={'Upload'}
                    buttonFalseText={'Close'}
                    documentData={{
                      title: '',
                      notes: 'All new uploaded files will override the existing file. Max file size limit is 1MB.',
                      // 300KB per file, Max-Total=1MB
                      maxSize: 1000000,
                    }}
                    onClose={(e) => {
                      if (e?.length) setCustomerDefaultFiles(e);
                      setOpenFileUploader(false);
                    }}
                  />
                </FileUploadProvider>
              )}
            </>
          )}
        </div>
        <Typography variant="subtitle2">Agent Information</Typography>
        <Divider sx={{ mb: 1 }} />
        {profileType === 'agent' && (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Card sx={{ flex: 1 }}>
              <CardHeader subheader={'Agreements'} />
              {checkIfOCMustBeShown() || checkIfOCMustBeShown() === undefined ? (
                <CardContent sx={{ p: '10px' }}>
                  {checkIfOCMustBeShown()?.isShared ? 'Online Contract is on file. (Legacy)' : 'No Agreemwents found.'}
                </CardContent>
              ) : (
                <CardContent sx={{ p: '10px' }}>
                  {ownerData.ServiceAgreement !== null ? (
                    <>
                      {ownerData.ServiceAgreement[0].isAgreed && (
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1 }}>
                            <p style={{ display: 'flex', alignItems: 'center', gap: '5px', lineHeight: '100%' }}>
                              <VerifiedIcon color="success" /> Agent has accepted your contractor agreement.
                            </p>
                            <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                              <Typography component="span" variant="subtitle2">
                                Agreed On:{' '}
                              </Typography>
                              <Typography variant="body2">
                                <i>{moment(ownerData.ServiceAgreement[0].agreementStatusDate).format('MM/DD/YYYY')}</i>
                              </Typography>
                            </p>
                          </div>
                          <div>
                            <IconButton
                              aria-label="donwload"
                              onClick={() => handleDownload(ownerData.ServiceAgreement[0].docId)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                      {!ownerData.ServiceAgreement[0].isAgreed && (
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1 }}>
                            <p style={{ display: 'flex', alignItems: 'center', gap: '5px', lineHeight: '100%' }}>
                              <NewReleasesIcon color="warning" /> Agent has declined your contractor agreement.
                              <InfoTooltip
                                title={ownerData.ServiceAgreement[0].comments}
                                placement="top"
                                arrow
                                sx={{ pl: 1 }}
                              />
                            </p>
                            <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                              <Typography component="span" variant="subtitle2">
                                Declined On:{' '}
                              </Typography>
                              <Typography variant="body2">
                                <i>{moment(ownerData.ServiceAgreement[0].agreementStatusDate).format('MM/DD/YYYY')}</i>
                              </Typography>
                            </p>
                          </div>
                          <div>
                            <IconButton
                              aria-label="donwload"
                              onClick={() => handleDownload(ownerData.ServiceAgreement[0].docId)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                      {ownerData.ServiceAgreement.slice(1).length > 0 && (
                        <>
                          <Divider sx={{ m: 1 }} />
                          <p style={{ marginBottom: '5px' }}>
                            <b>Older Versions:</b>
                          </p>
                          {ownerData.ServiceAgreement.slice(1).map((item, index) => (
                            <>
                              <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }} key={index}>
                                <Link
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    textAlign: 'left',
                                    lineHeight: '100%',
                                    fontSize: '0.85em',
                                  }}
                                  className="link"
                                  color="default"
                                  underline="hover"
                                  component="button"
                                  onClick={() => handleDownload(item.docId)}
                                >
                                  <DownloadIcon /> ({moment(item.agreementStatusDate).format('MM/DD/YYYY')}) -{' '}
                                  {item.displayName} |
                                </Link>
                                <Typography
                                  sx={{ fontSize: '0.85em' }}
                                  color={item.isAgreed ? 'text.success' : 'text.warning'}
                                  variant="subtitle2"
                                >
                                  {item.isAgreed ? ' Accepted' : ' Declined'}
                                </Typography>
                              </p>
                              <Divider sx={{ m: '5px' }} />
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <Typography variant="body2">No agreements found.</Typography>
                  )}
                </CardContent>
              )}
            </Card>
            <Card sx={{ flex: 2 }}>
              <CardHeader subheader={'Registration Documents'} />
              <div style={{ padding: '10px' }}>
                <div style={{ display: 'flex', gap: '10px' }} className="service-row">
                  {ownerData.RegistrationInfo?.AgentDocuments.map((item, index) => (
                    <Fragment key={item.code}>
                      <DocItem
                        item={item}
                        editMode={editMode}
                        emitNewValue={(val) => {
                          let tempData = _.cloneDeep(ownerData.RegistrationInfo);
                          tempData.AgentDocuments[index].isShared = val.isShared;
                          tempData.AgentDocuments[index].expirationDate = val.expirationDate?.format('L');
                          profileUpdate(tempData, 'RegistrationInfo');
                        }}
                      />
                    </Fragment>
                  ))}
                  <div className="reg-info-status">
                    <div style={{ minWidth: '90px', alignSelf: 'center' }}>
                      <Typography variant="body1">Reg. Status:</Typography>
                    </div>
                    {editMode && canEdit ? (
                      <FormControl sx={{ minWidth: '120px' }}>
                        <Select
                          sx={{ '& .MuiSelect-select': { padding: '5px' } }}
                          size="small"
                          inputProps={{ 'aria-label': 'Status' }}
                          value={ownerData.RegistrationInfo?.Status || 0}
                          onChange={(e) => {
                            let tempData = _.cloneDeep(ownerData?.RegistrationInfo);
                            tempData.Status = e.target.value;
                            profileUpdate(tempData, 'RegistrationInfo');
                          }}
                          displayEmpty
                        >
                          <MenuItem value={0}>Select One</MenuItem>
                          <MenuItem value={1}>Pending</MenuItem>
                          <MenuItem value={2}>Approved</MenuItem>
                          <MenuItem value={3}>Declined</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="body1">
                        {ownerData.RegistrationInfo?.Status == 0 && 'Not Selected'}
                        {ownerData.RegistrationInfo?.Status == 1 && 'Pending'}
                        {ownerData.RegistrationInfo?.Status == 2 && 'Approved'}
                        {ownerData.RegistrationInfo?.Status == 3 && 'Declined'}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="service-row">
                  <Typography variant="body2">Notes:</Typography>
                  <ProfileTextField
                    edit={canEdit}
                    disabled={!editMode}
                    value={ownerData.RegistrationInfo?.RegNotes || ''}
                    onChange={(e) => {
                      let tempData = _.cloneDeep(ownerData?.RegistrationInfo);
                      tempData.RegNotes = e.target.value;
                      profileUpdate(tempData, 'RegistrationInfo');
                    }}
                  />
                </div>
              </div>
            </Card>
          </div>
        )}
      </CardContent>

      {confirmPassReset && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={confirmPassReset ? true : false}
          title={'Password Reset Confirmation'}
          contentHtml={
            <React.Fragment>
              <div>{confirmPassReset}</div>
              <ListItem>
                <ListItemText
                  primary={workEmail}
                  secondary={
                    <React.Fragment>
                      Username: <i>{ownerData?.CustomizedData?.agentUserName}</i>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </React.Fragment>
          }
          buttonTrueText={'Send'}
          buttonFalseText={'Cancel'}
          onClose={() => {}}
          confirmCallback={handlePassResetConfirmation}
        />
      )}
    </StyledRoot>
  );
};
