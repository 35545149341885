import React from 'react';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const WarningStyle = styled('div')(
  ({ theme }) => css`
    color: white;
    margin: 30px;

    .Warning {
      display: flex;
      flex-direction: column;
      padding: 2rem 3rem;
      text-align: center;
    }

    .Warning > * {
      padding: 0.15rem 0;
    }
  `
);

const Warning = () => {
  return (
    <WarningStyle>
      <h2> No location found </h2>
      <p>Try informing city/town and state/country</p>
      <p> Ex: São Carlos, São Paulo</p>
      <p> Ex: Tokyo, Japan </p>
    </WarningStyle>
  );
};

export default Warning;
