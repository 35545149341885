import React from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { TextField, IconButton, Autocomplete } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileChip } from '../../../Style';

const StyledAutoComplete = styled(Autocomplete)(
  ({ theme }) => css`
    .MuiFilledInput-root {
      border-radius: 0;
      padding: 3px !important;
    }
  `
);

const FileUploadV2 = (props) => {
  return (
    <StyledAutoComplete
      className={''}
      multiple
      readOnly
      size="small"
      margin="dense"
      freeSolo
      disableClearable
      forcePopupIcon={false}
      open={false}
      onOpen={() => {}}
      value={props.files}
      onChange={(event, newValue) => {}}
      options={[]}
      getOptionLabel={(option) => option?.FileName}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <FileChip
            size="small"
            sx={{ flexGrow: '0 !important' }}
            label={option?.FileName || 'No document on file.'}
            key={index}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          size="small"
          error={false}
          helperText={''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <IconButton
                color="default"
                component="span"
                onClick={() => props.openUploader()}
              >
                <AttachFileIcon />
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
};

FileUploadV2.propTypes = {};

export default FileUploadV2;
