import React, { Fragment, useContext } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Card, Typography } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { store } from '../../../store';
import { ManageRequestContext } from '../../../context/manage-request.context';
import { parseStringTime } from '../../../helpers';
import { HtmlTooltip, RequestStatus } from '../../../Style';
import AssignAgentCard from './AssignAgentCard';
import MoreOptions from './MoreOptions';
import AgentConfirmation from '../signing-request/AgentConfirmation';

const StyledItemDesktop = styled('div')(
  ({ theme }) => css`
    display: flex;
    border-radius: 10px;
    width: 100%;
    display: flex;
    margin-bottom: 7px;

    #left {
      width: 120px;
      margin-right: 1px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;

      .inner-wrapper {
        top: 5px;
        position: relative;
        display: grid;
        gap: 10px;
        justify-items: center;
      }

      .icon-item {
        color: #76a6d5;
        cursor: pointer;
      }

      .left-item {
        line-height: normal;
        cursor: pointer;
        display: flex;
        justify-content: center;
        cursor: pointer;
        white-space: pre-wrap;

        .item-link {
          text-decoration: none;
          color: ${theme.palette.primary.main};

          span {
            font-weight: bold;
          }
        }
      }

      .left-item:nth-child(n + 2) {
        margin-top: 5px;
      }
    }

    #right {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;

      & .icon-color {
        color: ${theme.palette.mode === 'dark' && '#c7c4c4'};
      }

      & .chip {
        display: flex;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 0.75rem;
        height: 21px;
        border-radius: 16px;
        white-space: nowrap;
        margin-left: 5px;
        border: 1px solid #c0c0c0;
        width: 75px;

        & .chip-label {
          padding-right: 5px;
          font-weight: 500;
          display: none;
        }

        & .avatar {
          border-radius: 20px !important;
          width: 100%;
          font-weight: 500;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          height: 19px;
          font-size: 0.7rem;
          padding-top: 1px;
          color: #545458;
        }
      }

      & .item-columns {
        display: flex;
        /* flex-direction: row no-wrap; */
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;
        font-size: small !important;
        align-items: flex-start;
        margin-left: 10px;
        margin-right: 10px;
        padding: 5px;
        padding: 7px 0px 7px;
        gap: 10px;
      }

      & .item-column {
        display: inline-block;
        text-align: left;
        min-height: 100px;
        position: relative;
        overflow-wrap: anywhere;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .item-columns .item-column:nth-of-type(1) {
        flex: 1.5;
      }

      & .item-columns .item-column:nth-of-type(2) {
        flex: 1.5;
        margin-top: -4px;
      }

      & .item-columns .item-column:nth-child(3) {
        flex: 2;
        display: flex;
        justify-content: space-evenly;
      }

      & .item-columns .item-column:nth-child(4) {
        flex: 2;

        > div:nth-child(n + 2) {
          justify-content: end;
          margin-right: 15px;
        }
      }

      & .item-column:not(.when) {
        padding-top: 5px;
      }

      & .item-column-without-agent {
        display: none;
      }

      & .chip:hover {
        width: 115px;
        padding-left: 8px;

        & .chip-label {
          display: flex;
        }

        & .avatar {
          flex-shrink: 1;
          border-left: solid 1px rgba(0, 0, 0, 0.07);
        }
      }

      & .staff {
        display: block;
        width: 24px;
        height: 24px;
        text-align: center;
        cursor: pointer;
      }

      & .status {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }

      & .email {
        width: calc(100%);
        float: left;
        color: ${theme.palette.primary.default};
      }

      & .email:hover {
        overflow: visible;
      }

      & p {
        padding: 0px 0px 0px 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 40px;
        align-items: center;
        font-size: small;
        justify-content: space-around;
      }

      & .bold {
        font-weight: 500;
      }

      > div {
        flex: 1 1 0px;
      }
    }
  `,
);

const ItemTablet = ({ request, appointmentDialog, signingUploader, scanBacksUploader, assignStaffDialog }) => {
  const { controlSetting, quickSigningUpdate } = useContext(ManageRequestContext);

  const userRoles = store.getState().reducer.currentUser.userRoles;

  return (
    <StyledItemDesktop>
      <Card id="left">
        <div className="inner-wrapper">
          <div className="left-item item-1">
            <div>Req: </div>
            <Link className="item-link" to={`/manage-request/detail/${request.requestID}`}>
              <b>{request.requestSeqID}</b>
            </Link>
          </div>
          <RequestStatus $colorBase={request.statusColor}>{request.statusNameShort}</RequestStatus>
          <MoreOptions
            horizontal
            request={request}
            settings={controlSetting}
            requestLog
            appointmentDialog={(e) => appointmentDialog(e)}
            signingUploader={(e) => signingUploader(request)}
            scanBacksUploader={(e) => scanBacksUploader(request)}
            assignStaffDialog={(e) => assignStaffDialog(request)}
          />
        </div>
      </Card>
      <Card id="right">
        <div className="item-columns">
          <div className="item-column">
            <p className="bold">Signer(s):</p>
            <p>
              <strong style={{ fontSize: '0.85rem' }}>{request.borrowerLastName}</strong>
              {request.borrowerFirstName && <span>{`, ${request.borrowerFirstName}`}</span>}
            </p>
            {(request.coBorrowerLastName || request.coBorrowerFirstName) && (
              <>
                <strong>{request.coBorrowerLastName}</strong>
                {request.coBorrowerFirstName && <span>{`, ${request.coBorrowerFirstName}`}</span>}
              </>
            )}
            <p>{request.cityState}</p>
          </div>
          <div className="item-column">
            <p className="bold">
              When:{'  '}
              <AgentConfirmation
                confirmed={request.agentAppointmentConfirmed}
                onUpdate={(value) => {
                  let data = {
                    updateType: 'AgentApptConfirm',
                    requestID: request.requestID,
                    requestSeqID: request.requestSeqID,
                    agentAppointmentConfirmed: value,
                  };
                  quickSigningUpdate(request.requestID, data).then((res) => {
                    if (res) request.agentAppointmentConfirmed = value;
                    else request.agentAppointmentConfirmed = false;
                  });
                }}
              />
            </p>
            <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              <>
                <CalendarMonthOutlinedIcon sx={{ fontSize: '1rem' }} color="default" />
                {format(parseISO(request.signingDate), 'MM/dd/yy E')}
              </>
            </p>
            {(parseStringTime(request.signingTime) || request.showUndefinedSigningTime) && (
              <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                <AccessTimeOutlinedIcon sx={{ fontSize: '1rem' }} color="default" />
                {parseStringTime(request.signingTime) ? (
                  request.signingTime
                ) : (
                  <b style={{ fontSize: '0.95em' }}>{request.schedulingNotes}</b>
                )}
              </p>
            )}
            {!request.showUndefinedSigningTime &&
              (request.schedulingNotes || !parseStringTime(request.signingTime)) && (
                <p style={{ display: 'flex' }}>
                  <HtmlTooltip
                    arrow
                    placement="bottom-start"
                    title={
                      <>
                        {request.schedulingNotes ? request.schedulingNotes : ''}
                        {!parseStringTime(request.signingTime) && (
                          <>
                            <br />
                            {request.signingTime}
                          </>
                        )}
                      </>
                    }
                  >
                    <Typography variant="body2" component="span" color="primary">
                      <u>See Notes</u>
                    </Typography>
                  </HtmlTooltip>
                </p>
              )}
            {request.agentAppointmentConfirmed && (
              <p style={{ display: 'flex', marginTop: '2px' }}>
                <CheckBoxOutlinedIcon sx={{ fontSize: '1rem' }} color="success" />
                {'  '}
                <Typography component="span" color="text.success" sx={{ fontSize: '0.95em', fontWeight: 600 }}>
                  Appt. Confirmed
                </Typography>
              </p>
            )}
          </div>
          <div className="item-column" style={{ display: 'block' }}>
            <p className="bold">Customer:</p>
            <p>{request.contactInfo}</p>
            <p>{request.loanOfficerCompany}</p>
            <p>{request.contactPhone}</p>
            <p>
              <a className=" email" href={`mailto:${request.contactEmail}`}>
                {request.contactEmail}
              </a>
            </p>
          </div>
          <div className="item-column">
            <div
              className={
                userRoles.includes('Customer') && controlSetting.chkShowAgentInfoToCustomer === false
                  ? 'agent item-column-without-agent'
                  : 'agent'
              }
            >
              <AssignAgentCard request={request} setting={controlSetting} />
            </div>
          </div>
        </div>
      </Card>
    </StyledItemDesktop>
  );
};

export default ItemTablet;
