import { createStore, compose, applyMiddleware } from 'redux';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

// import { middlewareFunction } from './apiMiddleware';
import createRootReducer from './reducers';
import { initialState } from './reducers/reducer';

const reduxStateSyncConfig = {
  // whitelist: ['LOGIN_SUCCESS', 'LOGOUT', 'SET_ACCESS_TOKEN'],
  whitelist: ['LOGOUT'],
};

const env = BUILD_ENV;
const composeEnhancer =
  (env === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  createRootReducer(),
  { reducer: initialState },
  composeEnhancer(
    applyMiddleware(
      // middlewareFunction,
      createStateSyncMiddleware(reduxStateSyncConfig)
    )
  )
);

initMessageListener(store);
