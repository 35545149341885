import React from 'react';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const CurrentStyle = styled('div')(
  ({ theme }) => css`
    .Weather {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
    }

    .Weather__info {
      text-align: left;
      display: flex;
    }

    .Weather__icon {
      width: 100px;
      height: 100px;
      animation-name: sun;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }

    @keyframes sun {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
    }

    .Weather__list {
      font-size: 0.85rem;
      list-style: none;
      margin-left: 0.5rem;
      @media screen and (max-width: 1000px) {
        margin-right: 0.5rem;
      }
    }

    .list__temperature {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
      transition: scale 0.3s, padding-left 0.3s;
    }

    .list__temperature:hover {
      scale: 1.5;
      padding-left: 0.75rem;
    }

    .list__temperature-symbol {
      font-weight: 500;
      font-size: 1rem;
    }

    .Weather__other-info {
      text-align: right;
    }

    .other-info__city {
      font-size: 1.15rem;
      margin-bottom: 1rem;
    }

    .other-info__clouds {
      font-weight: normal;
      font-size: 0.9rem;
    }

    .Weather {
      padding: 0 0 1.5rem 0;
    }
  `
);

const Current = ({ weatherInfo, location, date }) => {
  return (
    <CurrentStyle>
      <div className="Weather">
        <div className="Weather__info">
          <img
            className="Weather__icon"
            src={
              'https://openweathermap.org/img/wn/' +
              weatherInfo.current.weather[0].icon +
              '.png'
            }
            alt={weatherInfo.current.weather[0].main}
          />
          <ul className="Weather__list">
            <li className="list__temperature">
              {Math.round(weatherInfo.current.temp)}
              <sup className="list__temperature-symbol">°F</sup>
            </li>
            <li>Humidity: {weatherInfo.current.humidity}% </li>
            <li>Wind: {Math.round(weatherInfo.current.wind_speed)} mph</li>
          </ul>
        </div>
        <div className="Weather__other-info">
          <h2 className="other-info__city">
            {location.city ||
              location.town ||
              location.village ||
              location.county}
            , {location.state || location.country.toUpperCase()}
          </h2>
          <h3 className="other-info__clouds">{date}</h3>
          <h3 className="other-info__clouds">
            {weatherInfo.current.weather[0].description}
          </h3>
        </div>
      </div>
    </CurrentStyle>
  );
};
export default Current;
