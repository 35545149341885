export const initialFilterPayload = {
  requestStatus: '123',
  requestNumber: -1,
  agentID: -1,
  staffSearchID: -1,
  borrowerLastName: '',
  loanCompany: '',
  dateFrom: '',
  dateTo: '',
  contactLastName: '',
  loanNumber: '',
  invoiceNumber: '',
  pageSize: 0,
  pageNumber: 1,
  sortField: 'Status',
  requestDocs: [],
};

const filterOptions = [
  {
    id: 'staffSearchID',
    label: 'Staff',
    type: 'dropdown',
    permanent: true,
    useCustomForm: true,
    dataType: 'number',
    backendValProp: 'staffID',
    defaultValue: 'Show All',
    value: {
      staffID: -1,
      staffName: 'Show All',
      staffOrder: -5,
      label: 'Show All',
    },
    disableClearable: true,
  },
  {
    id: 'dateFrom',
    label: 'Start Date',
    type: 'datepicker',
    useCustomForm: true,
    mdBreakpointDown: true,
  },
  {
    id: 'dateTo',
    label: 'End Date',
    type: 'datepicker',
    useCustomForm: true,
    mdBreakpointDown: true,
  },
  {
    id: 'borrowerLastName',
    label: 'Signer Last Name',
    type: 'input',
    mdBreakpointDown: true,
  },
  {
    id: 'invoiceNumber',
    label: 'Invoice #',
    type: 'input',
    inputProps: {
      maxLength: 30,
    },
  },
  {
    id: 'requestNumber',
    label: 'Request #',
    type: 'input',
    dataType: 'number',
  },
  {
    id: 'contactLastName',
    label: 'Contact Name',
    type: 'input',
  },
  {
    id: 'loanCompany',
    label: 'Loan Company',
    type: 'input',
  },
  {
    id: 'loanNumber',
    label: 'Loan #',
    type: 'input',
    mdBreakpointDown: true,
    maxLength: 30,
    inputProps: {
      maxLength: 30,
    },
  },
  {
    id: 'agentID',
    label: 'Assigned Agent',
    type: 'dropdown',
    dataType: 'number',
    useCustomForm: true,
    backendValProp: 'agentReferralID',
    disableClearable: true,
  },
  {
    id: 'requestDocs',
    label: 'Documents',
    type: 'dropdown',
    dataType: 'array',
    options: [],
  },
];

export const scanBackFilterObject = {
  index: '1',
  label: 'Has Scan Backs',
  value: 'ScanBacksFilesIn',
};

class FilterOption {
  constructor(filter, index) {
    this.id = filter.id;
    this.label = filter.label;
    this.type = filter.type;
    this.multiple = false;
    this.permanent = filter.permanent;
    this.dataType = filter.dataType;
    this.backendValProp = filter.backendValProp;
    this.inputProps = filter.inputProps;
    this.disableClearable = filter.disableClearable;

    // this below will help to SORT the applied filter by the moment it gets added
    if (filter.permanent) this['lastEnteredTimestamp'] = index;
    if (filter.mdBreakpointDown) this['mdBreakpointDown'] = filter.mdBreakpointDown;

    switch (filter.id) {
      case 'dateFrom':
      case 'dateTo':
        this.value = filter.value;
        this.useCustomForm = filter.useCustomForm;
        break;
      case 'staffSearchID':
      case 'agentID':
        this.value = filter.value;
        this.valueLabel = filter.valueLabel;
        this.defaultValue = filter.defaultValue;
        this.useCustomForm = filter.useCustomForm;
        this.options = [];
        break;
      case 'requestDocs':
        this.multiple = true;
        this.value = filter.value || [];
        this.useCustomForm = filter.useCustomForm;
        this.options = [
          {
            index: '0',
            label: 'Has Docs',
            value: 'SigningFilesIn',
          },
        ];
        break;
      default:
        this.value = filter.value;
        break;
    }
  }
}

export const GetInitialFilterOptions = (settings = {}) => {
  return filterOptions.map((item, index) => {
    item = new FilterOption(item, index);
    if (item.id == 'requestDocs' && settings.enableScanBacks) {
      item.options.push(scanBackFilterObject);
    }
    return item;
  });
};
