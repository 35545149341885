import * as yup from 'yup';
import { contactManagerService } from '../../services';

const nameRegex = /^[a-zA-Z'-_]*$/;
const alphaNumRegex = /^[1-9a-zA-Z'-_]*((\s)([1-9a-zA-Z'-_])+)*$/;
const alphaRegex = /^[a-zA-Z'-_]*((\s)([a-zA-Z'-_])+)*$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const firstNameValidationSchema = yup
  .string()
  .matches(nameRegex, 'First name should not include invalid characters')
  .min(2, 'First name must be at least 2 characters long')
  .max(50, 'First name must not exceed 50 characters')
  .required('First name is required');

const lastNameValidationSchema = yup
  .string()
  .matches(
    /^[a-zA-Z'-]+(?:\s[a-zA-Z'-]+)?$/,
    'Last name must consist of 1 or 2 names, separated by a space and containing only letters'
  )
  .required('Last name is required');

const addressValidationSchema = yup
  .string()
  .required('Address is required')
  .matches(/^\d*\w*(\s(\w)+)*/, 'Invalid combination for Street address ');

const cityValidationSchema = yup
  .string()
  .required('City is required')
  .matches(alphaRegex, 'City must contain only letters and spaces');

const zipValidationSchema = yup
  .string()
  .matches(/^\d{5}$/, 'ZIP code must be 5 digits')
  .required('ZIP code is required');

// U.S. phone number validation schema (10 digits only)
const phoneValidationWithoutFormatSchema = yup
  .string()
  .required('Phone number is required')
  .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits');

// U.S. phone number validation schema
const phoneValidationWithFormatSchema = yup
  .string()
  .required('Phone number is required')
  .matches(/^(?:\+1\s)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, 'Phone number must be in a valid format');

const userNameValidationSchema = yup
  .string()
  .matches(emailRegex, 'username should be a valid email')
  .required('Username is required')
  .test('username-exists', 'Username already exists', async (value) => {
    const isValid = await contactManagerService.checkUserNameAvailability(value);
    return !isValid;
  });
// Validation schema
export const agentProfileSchema = yup.object().shape({
  firstName: firstNameValidationSchema,
  lastName: lastNameValidationSchema,
  address: addressValidationSchema,
  city: cityValidationSchema,
  state: yup.string().required('State is required'),
  zip: zipValidationSchema,
  workPhone: phoneValidationWithFormatSchema,
  userName: userNameValidationSchema,

  workEmail: yup.string().matches(emailRegex, 'work email should be a valid email').required('Email is required'),
});

export const schemaDefaults = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  workPhone: '',
  userName: '',
  workEmail: '',
};

// export const schemaDefaults = {
//   firstName: 'Daniel',
//   lastName: 'Barrios',
//   address: '49 street',
//   city: 'some',
//   state: 'HI',
//   zip: '75500',
//   workPhone: '2345678910',
//   userName: 'joeAgent@gmail.com',
//   workEmail: 'dbarrios1907@gmail.com',
// };
