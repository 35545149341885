import { Button } from '@mui/material';
import React from 'react';
import { NeutralButton } from '../../../Style';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchButton = (props) => {
  const { text, onClick = () => {} } = props;

  return (
    // <NeutralButton
    //   color="btnNeutral"
    //   variant="contained"
    //   startIcon={<SearchOutlinedIcon />}
    //   onClick={(e) => onClick(e)}
    // >
    //   {text}
    // </NeutralButton>
    <Button
      sx={{ minHeight: '36px' }}
      size={props.size}
      startIcon={<SearchOutlinedIcon />}
      onClick={(e) => onClick(e)}
    >
      {text}
    </Button>
  );
};

SearchButton.propTypes = {};

export default SearchButton;
