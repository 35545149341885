import React from 'react';
import { Button, LinearProgress, TextField } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledAccordion } from '../../Style';
import { useTranslation } from 'react-i18next';
import { useBusinessSettings } from '../../context/business-settings.context';

const BillingDefaults = ({ callBack = () => {} }) => {
  const { t, i18n } = useTranslation();
  const panelKey = 'billingDefaults';
  const panelName = 'Billing Defaults';

  const {
    dataIsLoading,
    expanded,
    setExpanded,
    currentSetting,
    setCurrentSetting,
    updateBusSettings,
    hasDirtyChanges,
    progressType,
  } = useBusinessSettings();

  const handleNavigationCallback = (e) => {
    if (hasDirtyChanges) {
      callBack({ confirmation: false, accordion: panelKey });
      e.stopPropagation();
    } else {
      if (expanded !== panelKey) {
        callBack({ confirmation: true, accordion: panelKey });
      } else {
        setExpanded(false);
      }
    }
  };

  return (
    <>
      <StyledAccordion
        id={`${panelKey}-Accordion`}
        expanded={expanded === panelKey}
        onChange={(e) => handleNavigationCallback(e)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="billingDefaultsPanel-content"
          id="billingDefaultsPanel-header"
          className={expanded ? 'activePanel' : ''}
        >
          <Typography className="heading">Billing Defaults</Typography>
          <Typography className={`secondaryHeading ${expanded === panelKey ? 'activePanel' : ''}`}>
            Billing Default Information used on the Invoice
          </Typography>
        </AccordionSummary>
        {currentSetting && progressType == panelKey && (
          <>
            <AccordionDetails>
              <div>
                <div className="rowsNoBorder">
                  <div className="comp-title">Billing Informations</div>
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bCompanyName')}`}
                    value={currentSetting?.bCompanyName || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bCompanyName: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bAddress1')}`}
                    value={currentSetting.bAddress1 || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bAddress1: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bAddress2')}`}
                    value={currentSetting.bAddress2 || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bAddress2: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bCity')}`}
                    value={currentSetting.bCity || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bCity: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bState')}`}
                    value={currentSetting.bState || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bState: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bZip')}`}
                    value={currentSetting.bZip || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bZip: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bPhone')}`}
                    value={currentSetting.bPhone || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bPhone: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bFax')}`}
                    value={currentSetting.bFax || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bFax: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    fullWidth
                    label={`${t('businessSettings.ns__bTerms')}`}
                    value={currentSetting.bTerms || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        bTerms: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
            <div className="action-section">
              <Button
                disabled={dataIsLoading}
                variant="contained"
                onClick={() => updateBusSettings(panelKey, panelName)}
              >
                Update
              </Button>
            </div>
          </>
        )}
      </StyledAccordion>
      {dataIsLoading && progressType == panelKey && <LinearProgress sx={{ top: '-10px' }} color="inherit" />}
    </>
  );
};

export default BillingDefaults;
