import React from 'react';
import { StyledRelativeAlert, AlertItem } from '../others/ComponentAlertRelative.js';
import { F } from '../../../helpers/functions';

export const ModalAlert = ({ alerts, handleClose = F }) => {
  return (
    <StyledRelativeAlert>
      {alerts.map((alert, index) => (
        <AlertItem key={index} alert={alert} handleClose={() => handleClose(alert?.id)} />
      ))}
    </StyledRelativeAlert>
  );
};

export default ModalAlert;
