import { authHeader, getJsonOr, handleResponse, IsValidJSON } from '../helpers';
import Fetch from '../helpers/Fetch';
import { store } from '../store';

import { fetchEventSource } from '@microsoft/fetch-event-source';
class RetriableError extends Error {}
class FatalError extends Error {}

const UploadFilesService = {
  getFiles: async function (params) {
    const options = { headers: await authHeader() };
    const url = `/FileUploader/getFiles`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  uploadFiles: async function (signal, params, files) {
    const formData = new FormData();
    formData.append('uploadedFilesMeta', JSON.stringify(files.uploadedFilesMeta));

    for (var i = 0; i < files.uploadedFiles.length; i++) {
      formData.append('uploadedFiles', files.uploadedFiles[i]);
    }

    formData.append('externalFiles', JSON.stringify(files.externalFiles));

    const options = { headers: await authHeader(true), body: formData };
    const url = `/FileUploader/uploadFile`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  uploadFilesWithProgress: async function (signal, params, files, onProgress) {
    const formData = new FormData();
    formData.append('uploadedFilesMeta', JSON.stringify(files.uploadedFilesMeta));
    formData.append('externalFiles', JSON.stringify(files.externalFiles));

    for (var i = 0; i < files.uploadedFiles.length; i++) {
      formData.append('uploadedFiles', files.uploadedFiles[i]);
    }

    const options = {
      method: 'POST',
      headers: {
        ...(await authHeader(true)),
        withCredentials: true,
        Accept: 'text/event-stream',
      },
      body: formData,
      signal: signal,
    };

    const url = `${SERVICE_URL}/FileUploader/uploadFile${params}`;

    return new Promise((resolve, reject) => {
      try {
        fetchEventSource(url, {
          ...options,
          onopen(res) {
            // console.log('onopen', res);
            if (res.ok && res.status === 200) {
              // console.log('Connection made ', res);
            } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
              // console.log('Client side error ', res);
            }
          },
          onmessage(res) {
            switch (res?.event) {
              case 'onmessage':
                onProgress(res.data);
                if (res.data == 100) return resolve(true);
                break;
              case 'onerror':
                onProgress(-1);
                return reject(res.data); // Reject with original error
              case 'onclose':
                onProgress(-1);
                throw new FatalError(res.data);
              default:
                break;
            }
          },
          onclose(event) {
            console.log('Connection closed by the server.');
          },
          // onerror(err) {
          //   console.log('onerror', err);
          //   if (err instanceof FatalError) {
          //     throw err; // rethrow to stop the operation
          //   } else {
          //     // do nothing to automatically retry. You can also
          //     // return a specific retry interval here.
          //   }
          // },
        });
      } catch (error) {
        reject(error); // Reject with original error
      }
    });
  },

  deleteFile: async function (params, docId) {
    const options = { headers: await authHeader() };
    const _params = { ...params, docID: docId };
    const url = `/FileUploader/deleteFile`;
    return Fetch.remove(url, _params, options).then(handleResponse);
  },

  // previewFile: async function (params, docId) {
  //   const url = `/FileUploader/downloadFile`;
  //   const options = { headers: await authHeader() };
  //   const _params = { ...params, docID: docId };

  //   return Fetch.get(url, _params, options).then(async (response) => {
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const fileName = response?.headers
  //       ?.get('content-disposition')
  //       ?.split(';')
  //       .find((n) => n.includes('filename='))
  //       .replace('filename=', '')
  //       .trim()
  //       .replace(/^"(.*)"$/, '$1');
  //     const blob = await response.blob();
  //     return { fileName, blob };
  //   });
  // },

  previewFile: async function (params, docId) {
    const url = `/FileUploader/getPresignedURL`;
    const options = { headers: await authHeader() };
    const _params = { ...params, docID: docId };
    return Fetch.get(url, _params, options).then(handleResponse);
  },

  downloadFile: async function (params, docId) {
    const url = `/FileUploader/downloadFile`;
    const options = { headers: await authHeader() };
    const _params = { ...params, docID: docId };

    return Fetch.get(url, _params, options)
      .then(async (response) => {
        const fileName = response?.headers
          ?.get('content-disposition')
          ?.split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace(/^"(.*)"$/, '$1');
        const blob = await response.blob();
        return { fileName, blob };
      })
      .then((res) => {
        let url = window.URL.createObjectURL(res.blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = res.fileName;
        a.click();
      });
  },

  UpdateMetaFile: async function (params, requestID, metaFile) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(metaFile),
    };

    const _params = { ...params, requestID, docID: metaFile.docId };
    const url = `/fileUploader/UpdateFile`;
    return Fetch.update(url, _params, options).then(handleResponse);
  },

  logUploadedFiles: async function (params, files, skipEmailNotice) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(files),
    };
    params = { ...params, skipEmailNotice: skipEmailNotice };
    const url = `/FileUploader/logUploadedFiles`;
    return Fetch.post(url, params, options).then(handleResponse);
  },
};

export { UploadFilesService };
