import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { EditableTable } from '../../common/others/EditableTable';
import { invoiceService as service } from '../../../services';
import { InvoicePaymentsCss } from './style';
import { useParams } from 'react-router-dom';

import NumberFormat from 'react-number-format';
import { dispatchAlert, parseFeeTypesForArrayLookup } from '../../../helpers';
import Loading from '../../common/others/Loading';
import { green } from '@mui/material/colors';

import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { currencyFormat } from '../../../helpers/currency-format';
import useDocumentTitle from '../../../helpers/hooks/useDocumentTitle';

const initialState = () => {
  return {
    invoiceID: 0,
    borrowerLastName: '',
    status: '',
    agentName: '',
    reg_W9: false,
    reg_OC: false,
    reg_HC: false,
    reg_C1: false,
    regCustomInitials1: '',
    reg_C2: false,
    regCustomInitials2: '',
    reg_Status: 0,
    feeComments: '',
    invoiceTotal: 0,
    paymentTotal: 0,
    balance: 0,
    oldBalance: 0,
    fees: [],
    payments: [],
    paymentTypes: [],
  };
};

const controlSettingInitialState = () => {
  return {
    check: true,
  };
};

const submittingInitialState = () => {
  return {
    save: false,
    savePayments: false,
  };
};

const invoiceCols = [
  {
    id: 1,
    field: 'priceDescAgent',
    headerName: 'Description',
    type: 'text',
    editable: true,
    flex: 3,
  },
  {
    id: 2,
    field: 'qtyAgent',
    headerName: 'Qty',
    type: 'number',
    editable: true,
    allowNegative: false,
    flex: 1,
  },
  {
    id: 3,
    field: 'priceAmtAgent',
    headerName: 'Unit $',
    type: 'decimal',
    editable: true,
    flex: 1,
  },
  {
    id: 4,
    field: 'total',
    headerName: 'Total $',
    type: 'decimal-calculated',
    editable: false,
    flex: 1,
    calcColumns: ['qtyAgent', 'priceAmtAgent'],
  },
];

const _paymentCols = (amountDefault = 0) => {
  return [
    {
      id: 1,
      field: 'paymentDate',
      headerName: 'Date',
      type: 'date',
      editable: true,
      defaultValue: new Date().toISOString(),
      flex: 2,
    },
    {
      id: 2,
      type: 'lookup',
      field: 'pmtTypeDesc',
      headerName: 'Trans Type',
      editable: true,
      defaultValue: 'Notary Payment',
      lookups: [],
      flex: 2,
    },
    {
      id: 3,
      field: 'amount',
      headerName: 'Amount',
      type: 'currency',
      editable: true,
      defaultValue: amountDefault,
      flex: 1,
    },
    {
      id: 4,
      field: 'notes',
      headerName: 'Notes',
      type: 'text',
      editable: true,
      flex: 1,
    },
  ];
};

export const AgentInvoicePayment = () => {
  useDocumentTitle('Agent Payments'); // Set page title

  const [state, setState] = useState(initialState());
  const [controlSetting, setControlSetting] = useState(
    controlSettingInitialState()
  );
  const [isSubmitting, setIsSubmitting] = useState(submittingInitialState());
  const [paymentCols, setPaymentCols] = useState(_paymentCols());
  const [activeSaveFees, setActiveSaveFees] = useState(false);
  const [oldFees, setOldFees] = useState({});
  const requestID = useParams().requestID;

  useEffect(() => {
    service.getAgentInvoiceDetails(requestID).then(
      (res) => {
        setControlSetting(res.controlSetting);
        if (res.controlSetting.check) {
          setAgentInvoiceDetails(res.agentInvoice);
          setPaymentCols(
            parseFeeTypesForArrayLookup(
              res.agentInvoice.paymentTypes,
              _paymentCols(res.agentInvoice.balance),
              'pmtTypeDesc',
              'pmtTypeDesc'
            )
          );
        }
      },
      (error) => {
        setControlSetting(controlSettingInitialState());
        dispatchAlert(error, true);
      }
    );
    return () => {};
  }, []);

  const setAgentInvoiceDetails = (agentInvoice) => {
    setState((values) => ({
      ...values,
      ...agentInvoice,
    }));
  };

  const save = () => {
    const asyncSave = async () => {
      setIsSubmitting((v) => ({ ...v, save: true }));
      setActiveSaveFees(false);

      await service.saveAgentfees(state).then(
        (res) => {
          dispatchAlert(`Notary Agent Fees was saved.`);
          const balance = state.invoiceTotal - state.paymentTotal;
          setState((values) => ({
            ...values,
            balance: balance,
          }));
          setPaymentCols(
            parseFeeTypesForArrayLookup(
              state.paymentTypes,
              _paymentCols(balance),
              'pmtTypeDesc',
              'pmtTypeDesc'
            )
          );
        },
        (error) => dispatchAlert(error, true)
      );
      setIsSubmitting((v) => ({ ...v, save: false }));
    };

    asyncSave();
  };

  const cancel = () => {
    setState((values) => ({
      ...values,
      fees: [...oldFees.fees],
      feeComments: oldFees.feeComments,
      invoiceTotal: oldFees.invoiceTotal,
    }));
    setActiveSaveFees(false);
  };

  const edit = () => {
    setOldFees({
      fees: [...state.fees],
      feeComments: state.feeComments,
      invoiceTotal: state.invoiceTotal,
    });
    setActiveSaveFees(true);
  };

  const savePayments = () => {
    setIsSubmitting((v) => ({ ...v, savePayments: true }));
    const savePaymentsAsync = async () => {
      await service.saveAgentPayments(state).then(
        (res) => {
          dispatchAlert(`Notary Agent Payments was saved.`);
          setAgentInvoiceDetails(res);
          setPaymentCols(
            parseFeeTypesForArrayLookup(
              state.paymentTypes,
              _paymentCols(state.balance),
              'pmtTypeDesc',
              'pmtTypeDesc'
            )
          );
        },
        (error) => dispatchAlert(error, true)
      );

      setIsSubmitting((v) => ({ ...v, savePayments: false }));
    };

    savePaymentsAsync();
  };

  const handleEditTableFees = (items) => {
    let details = [...items.state];

    const subtotal = details
      .map((item, index) => {
        item.priceAmtAgent = Number(item.priceAmtAgent);
        item.qtyAgent = Number(item.qtyAgent);
        return Number(item.qtyAgent) * Number(item.priceAmtAgent);
      })
      .reduce((prev, curr) => prev + curr, 0);

    setState((values) => ({
      ...values,
      fees: [...details],
      invoiceTotal: Number(subtotal || 0),
    }));
  };

  const handleEditTablePayments = (items) => {
    let details = [...items.state];

    const subtotal = details
      .map((item, index) => {
        let payment = state.paymentTypes.find(
          (x) => x.pmtTypeDesc === item.pmtTypeDesc
        );
        item.paymentTypeID = payment?.id;
        item.amount = Number(item.amount);
        return Number(item.amount);
      })
      .reduce((prev, curr) => prev + curr, 0);

    const balance = Number(state.invoiceTotal - Number(subtotal || 0) || 0);

    setState((values) => ({
      ...values,
      payments: [...details],
      balance: balance,
      paymentTotal: Number(subtotal || 0),
    }));
    setPaymentCols(
      parseFeeTypesForArrayLookup(
        state.paymentTypes,
        _paymentCols(balance),
        'pmtTypeDesc',
        'pmtTypeDesc'
      )
    );
  };

  const returnCheck = (status, name) => {
    switch (status) {
      case true:
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                name={name}
                size="small"
                icon={<PriceCheckOutlinedIcon />}
                sx={{
                  color: green[800],
                  '&.Mui-checked': {
                    color: green[600],
                  },
                }}
              />
            }
            label={name}
          />
        );
      case false:
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={false}
                name={name}
                size="small"
                icon={<CheckBoxOutlineBlankOutlinedIcon />}
              />
            }
            label={name}
          />
        );
    }
  };

  return (
    <InvoicePaymentsCss>
      <Card className="card card-agent-title">
        <div className="agent-invoice-title">
          Signing Request# {state.requestSeqID} - Borrower:{' '}
          {state.borrowerLastName} - Status: '{state.status}'
        </div>
        <div className="agent-invoice-sub-title">
          Agent Name:{' '}
          <span className="agent-invoice-title">{state.agentName}</span>
        </div>
        <div className="agent-invoice-sub-title">
          Agent Registration Info: {'    '}
          {returnCheck(state.reg_W9, 'W9')} {returnCheck(state.reg_OC, 'OC')}{' '}
          {returnCheck(state.reg_HC, 'HC')}{' '}
          {returnCheck(state.reg_C1, state.regCustomInitials1)}{' '}
          {returnCheck(state.reg_C2, state.regCustomInitials2)}
        </div>
      </Card>
      <Card className="card card-title">
        <div className="invoice-title">Notary Agent Fees to be Paid</div>
        <div className="buttons">
          {activeSaveFees && (
            <Fragment>
              <Button
                className="button"
                variant="contained"
                onClick={save}
                disabled={!controlSetting.check}
              >
                Save {isSubmitting.save && <Loading forButton lpad />}
              </Button>

              <Button
                className="button"
                onClick={cancel}
                disabled={!controlSetting.check}
              >
                Cancel
              </Button>
            </Fragment>
          )}
          {!activeSaveFees && (
            <Button
              className="button"
              onClick={edit}
              disabled={!controlSetting.check}
            >
              Edit
            </Button>
          )}
        </div>
      </Card>
      <div className="flex-container">
        <Card className="card card-table">
          <CardContent>
            <div style={{ flexGrow: 1 }}>
              <EditableTable
                addNew
                edit={controlSetting.check && activeSaveFees}
                columns={invoiceCols}
                rows={state.fees}
                emitNewRows={(val) => handleEditTableFees(val)}
              />
            </div>
            <div className="totals-container">
              <NumberFormat
                size="small"
                className="totals"
                InputLabelProps={{ shrink: true }}
                label="Total Payment to Notary"
                name="notaryInvoiceTotal"
                disabled={true}
                value={state.invoiceTotal}
                prefix="$"
                decimalSeparator="."
                customInput={TextField}
                type="text"
                thousandSeparator={true}
                allowNegative={true}
                decimalScale={2}
                fixedDecimalScale
              />
            </div>
          </CardContent>
        </Card>
        <Card className="card card-comments">
          <CardContent>
            <div>
              <span className="agent-invoice-title">Payments Comments </span>
            </div>
            <TextField
              InputLabelProps={{ shrink: true }}
              multiline
              rows={4}
              name="feeComments"
              disabled={!controlSetting.check || !activeSaveFees}
              value={state.feeComments}
              onChange={(e) => {
                setState((values) => ({
                  ...values,
                  feeComments: e.target.value,
                }));
              }}
            />
          </CardContent>
        </Card>
      </div>
      <Card className="card card-title">
        <div className="invoice-title">
          Payments and Adjustments to Above Amount Owed
        </div>
        <div className="buttons">
          <Button
            className="button"
            variant="contained"
            onClick={savePayments}
            disabled={!controlSetting.check}
          >
            Save Payments{' '}
            {isSubmitting.savePayments && <Loading forButton lpad />}
          </Button>
        </div>
      </Card>
      <Card>
        <CardContent>
          <div className="totals">
            Total Owed To Notary: {currencyFormat(state.invoiceTotal)} Payments:{' '}
            {currencyFormat(state.paymentTotal)} Balance:{' '}
            {currencyFormat(state.balance)}
          </div>
          <div style={{ flexGrow: 1 }}>
            <EditableTable
              addNew
              needRender
              edit={controlSetting.check}
              columns={paymentCols}
              rows={state.payments}
              emitNewRows={(val) => handleEditTablePayments(val)}
            />
          </div>
        </CardContent>
      </Card>
    </InvoicePaymentsCss>
  );
};
