import React, { memo, useEffect, useRef, useState, useTransition } from 'react';
import { css, useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Alert, Autocomplete, Button, Checkbox, InputBase, Radio, TextareaAutosize } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Loading from './Loading';
import { Delete } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { ErrorBoundary } from '../../../ErrorBoundery';
import { triggerWhenCallback } from '../../../helpers';
import InfoTooltip from '../../common/others/InfoTooltip';
import Typography from '@mui/material/Typography';

const StyledEditableTable = styled('div')(
  ({ theme, style, $required }) => css`
    width: 100%;
    border-radius: 4px;
    border: 1px solid;
    border-color: ${$required ? 'red' : theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};

    .col-border {
      align-self: center;
      border-right: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};

      :last-of-type {
        border-right: none !important;
      }
    }

    .row-border {
      border-right: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};
    }

    .header .col-1,
    .row .col-1 {
      flex: 1 !important;
    }
    .header .col-2,
    .row .col-2 {
      flex: 2 !important;
    }
    .header .col-3,
    .row .col-3 {
      flex: 3 !important;
    }
    .header .col-4,
    .row .col-4 {
      flex: 4 !important;
    }
    .header .col-5,
    .row .col-5 {
      flex: 5 !important;
    }
    .header .col-6,
    .row .col-6 {
      flex: 6 !important;
    }
    .header .col-7,
    .row .col-7 {
      flex: 7 !important;
    }
    .header .col-8,
    .row .col-8 {
      flex: 8 !important;
    }
    .header .col-9,
    .row .col-9 {
      flex: 9 !important;
    }
    .header .col-10,
    .row .col-10 {
      flex: 10 !important;
    }
    .header .col-11,
    .row .col-11 {
      flex: 11 !important;
    }
    .header .col-12,
    .row .col-12 {
      flex: 12 !important;
    }

    [class*='col-'] {
      /* padding: 2px; */
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-size: 0.75rem;

      .MuiInputBase-root,
      textarea,
      a {
        font-family: inherit;
        font-size: inherit;
      }

      .MuiInputBase-root,
      textarea {
        width: 100%;
        height: ${style?.bodyRowHeight || '25px'};
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .header,
    .row {
      flex-grow: 1;
      display: flex;
      border-bottom: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};

      input,
      textarea {
        border: 0;
        outline: 0;
        background-color: inherit;
        -webkit-text-fill-color: ${theme.palette.mode === 'dark' ? 'white' : 'black'} !important;
      }

      input {
        padding: 0px;
      }

      textarea {
        padding: 3px;
      }

      input:focus,
      textarea:focus {
        outline: none !important;
      }
    }

    .header {
      background-color: ${theme.palette.nsTableHeaderBG.main};
      height: ${style?.headerRowHeight || '40px'};
      line-height: ${style?.headerRowHeight || '30px'};
      margin-bottom: 0px !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .MuiInputBase-root {
        color: ${theme.palette.mode === 'dark' ? 'white' : 'black'};
      }
    }

    .row {
      background-color: ${theme.palette.mode === 'light' ? 'white' : theme.palette.nsTableBodyBG.main};

      &:last-of-type {
        border-bottom: 0px solid;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};
      }

      :hover {
        background-color: rgb(92 92 92 / 1%);
      }

      [class*='col-']:focus-within {
        border: rgba(25, 118, 210, 0.5) solid 1px;
        outline-offset: -1px;
      }
    }

    .row.selected {
      background-color: rgba(25, 118, 210, 0.08);
    }

    .row.showError:first-child {
      border: 1px solid;
      border-color: red;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .no-rows-found {
      padding: 5px;

      .MuiAlert-root {
        padding: 0px 16px;
      }
    }

    .footer {
      text-align: end;
      border-top: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};
    }

    .remove {
      width: 24px;
      height: 30px;
      text-align: center;
      align-items: center;

      .MuiSvgIcon-root {
        font-size: 1.1rem;
        vertical-align: -webkit-baseline-middle;
      }
    }

    .Mui-disabled {
      color: inherit;
    }
  `
);

const Rows = (props) => {
  const renderFunc = props.children || props.render;

  return (
    <div className={` ${props.rows?.length ? 'rows-wrapper' : 'no-rows-found'} `}>
      {props.loading && props.onLoading()}
      {!props.loading && !props.rows?.length && props.onEmptyFees()}
      {!props.loading && props.rows?.map(renderFunc)}
    </div>
  );
};

const Table = ({
  style,
  loading = false,
  editable = false,
  addNew = false,
  hideUrl = false,
  required = false,
  edit,
  lookups,
  columns = [],
  rows = [],
  maxRows,
  minRows,
  needRender = false,
  showErrorOnRow = false,
  emitNewRows = () => {},
}) => {
  const theme = useTheme();
  const mountRef = useRef(true);
  const [, startTransition] = useTransition();

  const [selectedRow, setSelectedRow] = useState();
  const [tableState, setTableState] = useState(rows);

  useEffect(() => {
    if (!mountRef.current) setTableState(rows);
    return () => {};
  }, [emitNewRows]);

  useEffect(() => {
    handleMinRows();
    return () => {
      mountRef.current = false;
    };
  }, [rows?.length]);

  const handleMinRows = () => {
    if (minRows && minRows > rows.length) {
      let newObj = {};
      columns.map((col) => {
        switch (col.type) {
          case 'number':
            newObj[col.field] = 0;
            break;
          case 'boolean':
            newObj[col.field] = false;
            break;
          default:
            newObj[col.field] = '';
            break;
        }
      });
      let tempRows = rows;
      let tempMinRows = Array(minRows - rows.length).fill(newObj);
      setTableState(tempRows.concat(tempMinRows) || []);
    } else {
      setTableState(rows || []);
    }
  };

  const onRowClick = (id) => {
    setSelectedRow(id);
  };

  const calcColumns = (row, col) => {
    let calcValue = 0;
    switch (col.operator) {
      case '/':
        calcValue = Number(row[col.calcColumns[0]] / row[col.calcColumns[1]]);
        break;
      case '-':
        calcValue = Number(row[col.calcColumns[0]] - row[col.calcColumns[1]]);
        break;
      case '+':
        calcValue = Number(row[col.calcColumns[0]] + row[col.calcColumns[1]]);
        break;
      default:
        calcValue = Number(row[col.calcColumns[0]] * row[col.calcColumns[1]]);
        break;
    }
    row[col.field] = isNaN(calcValue) ? 0 : calcValue;
    // calcRef.current = isNaN(calcValue) ? 0 : calcValue;
    // setCalcRef(isNaN(calcValue) ? 0 : calcValue);
    return isNaN(calcValue) ? 0 : calcValue;
  };

  const handlePlaceholder = (index, col, row) => {
    //function left for later implementation of the placeholder
    if (index === 0) {
      return col.placeHolder;
    } else {
      return '';
    }
  };

  const handleRadio = (index, column, val) => {
    const newList = tableState.map((item, iindex) => {
      let updatedItem = item;
      if (iindex === index) {
        updatedItem = { ...item, [column.field]: val };
        return updatedItem;
      } else {
        updatedItem = { ...item, [column.field]: false };
        return updatedItem;
      }
    });
    setTableState(newList);
    return newList;
  };

  const handleTableUpdate = (index, column, val) => {
    let isValObject = typeof val === 'object' ? true : false;

    const getItemFromLookup = (field) => {
      if (column.lookups?.length > 0) {
        let item = column.lookups.find((i) => (isValObject ? i.label === val?.label : i.label === val));
        if (item && parseInt(item.id) > 0) {
          if (!(field in item)) {
            item[field] = item.id;
          }
        }
        return item;
      }
      return undefined;
    };

    const newList = tableState.map((item, iindex) => {
      if (iindex === index) {
        let updatedItem = {
          ...item,
          [column.field]: isValObject ? val?.label : val,
        };

        column.updateFielsOnChange?.map((field) => {
          let lkItem = getItemFromLookup(field);
          updatedItem = {
            ...item,
            id: lkItem?.id || -1,
            [column.field]: isValObject ? val?.label : val,
            [field]: lkItem ? lkItem[field] : undefined,
          };
        });
        return updatedItem;
      }
      return item;
    });

    setTableState(newList);
  };

  const getDefaultValue = (col) => {
    if (col.defaultValue) {
      if (col.type == 'date') {
        return col.defaultValue.split('T')[0];
      } else {
        return col.defaultValue;
      }
    } else {
      switch (col.type) {
        case 'currency':
        case 'decimal':
        case 'number':
        case 'increment':
          if (col.type == 'increment') {
            return parseInt(tableState?.length || 0) + 1;
          }
          return 0;
        case 'boolean':
          return false;
        default:
          return '';
      }
    }
  };

  return (
    <ErrorBoundary>
      {/* <StyledEditableTable $required={required}> */}
      <StyledEditableTable style={style}>
        <div className="header">
          {columns?.map((col) => {
            if ((col.type === 'linkUrl' && hideUrl && !edit) || col.hidden) return;
            return (
              <div key={col.id} className={`col-${col.flex}`}>
                {col.info ? (
                  <Typography variant="body2" sx={{ fontSize: '12px', paddingTop: '4px', paddingLeft: '5px' }}>
                    <span>{col.headerName}</span>
                    <InfoTooltip title={col.info} placement="top" arrow sx={{ pl: 1 }} />
                  </Typography>
                ) : (
                  <InputBase margin="dense" size="small" value={col.headerName} disabled />
                )}
              </div>
            );
          })}
          {addNew && edit && <div className="remove"></div>}
        </div>
        <Rows
          loading={loading}
          rows={tableState}
          onLoading={() => (
            <span style={{ zoom: '75%' }}>
              <Loading forList msg="" />
            </span>
          )}
          onEmptyFees={() => (
            <Alert variant="outlined" severity="warning" sx={{ marginTop: '10px', padding: 0 }}>
              No records were found.
            </Alert>
          )}
        >
          {(row, index) => {
            if (row.name && row.name === 'Social Link') return; //keeping the social media links out of table
            return (
              <div
                key={index}
                className={`row ${selectedRow == row && edit && editable ? 'selected' : ''} ${
                  showErrorOnRow ? 'showError' : ''
                }`}
              >
                {columns.map((col) => {
                  if ((col.type === 'linkUrl' && hideUrl && !edit) || col.hidden) return; //don't render the url column for links outside of edit mode

                  /** The code below is needed just to re-map the array to contain only id and label
                   * It seems like Material UI is less problematic when the object has only these 2 properties.
                   * this is just a work aound but in the future if we can normalized the the lookup array from the backend then this code won't be needed
                   */

                  if (col.type == 'lookup' && lookups?.length > 0) {
                    col.lookups = lookups?.map((obj) => {
                      return { id: obj.index, label: obj.value };
                    });
                  }
                  /*********************** End*/

                  return (
                    <div key={index + col.id} className={`col-${col.flex} col-border`} onClick={() => onRowClick(row)}>
                      {/* {col.lookups?.length > 0 && ( */}
                      {col.type == 'lookup' && (
                        <Autocomplete
                          freeSolo={row[`lock_${col.field}`] ? true : false}
                          disabled={!(edit && col.editable && !row[`lock_${col.field}`])}
                          isOptionEqualToValue={(option, value) => option.label === value || ''}
                          options={col.lookups || lookups}
                          value={row[col.field] || ''}
                          {...col.props}
                          onChange={(e, val) => {
                            handleTableUpdate(index, col, val);
                          }}
                          onBlur={(event) => {
                            emitNewRows({ args: row, state: tableState });
                          }}
                          renderInput={(params) => {
                            const { InputLabelProps, InputProps, ...rest } = params;
                            return (
                              <InputBase
                                margin="dense"
                                size="small"
                                placeholder={handlePlaceholder(index, col, row)}
                                disabled={!(edit && col.editable)}
                                type={col.type}
                                {...params.InputProps}
                                {...rest}
                                onChange={(e) => {
                                  handleTableUpdate(index, col, e.target.value);
                                }}
                              />
                            );
                          }}
                        />
                      )}
                      {col.switch && col.type === 'boolean' && (
                        <Checkbox
                          sx={{ padding: '0px' }}
                          disabled={!(edit && col.editable) || triggerWhenCallback(col?.disabledWhen, row, col)}
                          readOnly={row.readOnly}
                          size="small"
                          checked={row[col.field] || false}
                          onChange={(e) => {
                            startTransition(() => {
                              handleTableUpdate(index, col, Boolean(e.target.checked));
                            });
                            emitNewRows({ args: row, state: tableState });
                          }}
                        />
                      )}
                      {col.radio && col.type === 'boolean' && (
                        <Radio
                          sx={{ padding: '0px' }}
                          size="small"
                          disabled={!(edit && col.editable)}
                          checked={row[col.field] || false}
                          onChange={(e) => {
                            handleRadio(index, col, Boolean(e.target.checked));
                            emitNewRows({
                              args: row,
                              state: handleRadio(index, col, Boolean(e.target.checked)),
                            });
                          }}
                        />
                      )}
                      {['text', 'number', 'increment'].includes(col.type) && (
                        <InputBase
                          margin="dense"
                          type={col.type}
                          disabled={
                            !(edit && col.editable && !row[`lock_${col.field}`]) ||
                            triggerWhenCallback(col?.disabledWhen, row, col)
                          }
                          placeholder={handlePlaceholder(index, col, row)}
                          readOnly={row.readOnly}
                          size="small"
                          value={row[col.field] || ''}
                          onChange={(e) => {
                            switch (col.type) {
                              case 'number':
                              case 'increment':
                                if (col.allowNegative == false)
                                  e.target.value < 0 ? (e.target.value = 0) : e.target.val;
                                handleTableUpdate(index, col, parseInt(e.target.value));
                                break;
                              default:
                                handleTableUpdate(index, col, e.target.value);
                                break;
                            }
                          }}
                          onBlur={(event) => {
                            emitNewRows({ args: row, state: tableState });
                          }}
                        />
                      )}
                      {['decimal', 'currency'].includes(col.type) && (
                        <NumberFormat
                          style={{ width: '100%' }}
                          decimalSeparator="."
                          type="text"
                          decimalScale={2}
                          fixedDecimalScale
                          margin="dense"
                          disabled={!(edit && col.editable)}
                          placeholder={handlePlaceholder(index, col, row)}
                          value={row[col.field]}
                          prefix={col.type == 'currency' ? '$' : ''}
                          onValueChange={(values) => {
                            if (col.allowNegative == false)
                              values.floatValue < 0 ? (values.floatValue = 0) : values.floatValue;
                            handleTableUpdate(index, col, values.floatValue);
                          }}
                          onBlur={(event) => {
                            emitNewRows({ args: row, state: tableState });
                          }}
                        />
                      )}
                      {['date'].includes(col.type) && (
                        <InputBase
                          type="date"
                          required
                          margin="dense"
                          value={row[col.field].split('T')[0]}
                          disabled={!(edit && col.editable) || row.readOnly}
                          readOnly={row.readOnly}
                          size="small"
                          onChange={(e) => {
                            handleTableUpdate(index, col, e.target.value);
                          }}
                          onBlur={(event) => {
                            emitNewRows({ args: row, state: tableState });
                          }}
                        />
                      )}
                      {['link', 'linkUrl'].includes(col.type) &&
                        (!edit ? (
                          <a
                            style={{ color: `${theme.palette.mode == 'dark' ? 'white' : 'black'}` }}
                            target={'_blank'}
                            href={row['url']}
                          >
                            {row[col.field]}
                          </a>
                        ) : (
                          <InputBase
                            margin="dense"
                            disabled={!(edit && col.editable)}
                            placeholder={handlePlaceholder(index, col, row)}
                            size="small"
                            type={col.type}
                            value={row[col.field] || ''}
                            onChange={(e) => {
                              switch (col.type) {
                                case 'number':
                                  handleTableUpdate(index, col, e.target.value);
                                  break;
                                case 'boolean':
                                  handleTableUpdate(index, col, Boolean(e.target.value));
                                  break;
                                default:
                                  handleTableUpdate(index, col, e.target.value);
                                  break;
                              }
                            }}
                            onBlur={(event) => {
                              emitNewRows({ args: row, state: tableState });
                            }}
                          />
                        ))}
                      {col.type === 'calculated' && (
                        <InputBase
                          id={'calcInput'}
                          readOnly
                          margin="dense"
                          disabled={!(edit && col.editable)}
                          size="small"
                          type={col.type}
                          value={calcColumns(row, col)}
                          onChange={(e) => {
                            handleTableUpdate(index, col, e.target.value);
                          }}
                        />
                      )}
                      {col.type === 'decimal-calculated' && (
                        <NumberFormat
                          style={{ width: '100%' }}
                          decimalSeparator="."
                          type="text"
                          decimalScale={2}
                          fixedDecimalScale
                          id={'calcInput'}
                          readOnly
                          margin="dense"
                          disabled={!(edit && col.editable)}
                          value={calcColumns(row, col)}
                          onChange={(e) => {
                            handleTableUpdate(index, col, e.target.value);
                          }}
                        />
                      )}
                      {col.type === 'textarea' && (
                        <TextareaAutosize
                          margin="dense"
                          disabled={
                            !(edit && col.editable && !row[`lock_${col.field}`]) ||
                            triggerWhenCallback(col?.disabledWhen, row, col)
                          }
                          placeholder={handlePlaceholder(index, col, row)}
                          readOnly={row.readOnly}
                          size="small"
                          value={row[col.field] || ''}
                          onChange={(e) => {
                            handleTableUpdate(index, col, e.target.value);
                          }}
                          onBlur={(event) => {
                            emitNewRows({ args: row, state: tableState });
                          }}
                          {...(col?.props || {})}
                        />
                      )}
                      {col.type === 'callback' && <span>{triggerWhenCallback(col?.callback, row, index, col)}</span>}

                      {col.type === 'callbackLink' && (
                        <a
                          style={{
                            color: `${theme.palette.mode == 'dark' ? 'white' : 'black'}`,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => triggerWhenCallback(col?.callback, row)}
                        >
                          {row[col.field]}
                        </a>
                      )}
                    </div>
                  );
                })}
                {addNew && edit && (
                  <div className="remove">
                    <Delete
                      size="small"
                      onClick={() => {
                        if (minRows && tableState.length === minRows) {
                          alert(`There needs to be at least ${minRows} rows in this table`);
                        } else {
                          const rows = [...tableState];
                          rows.splice(index, 1);

                          let newObj = columns.find((col) => col.type == 'increment');
                          if (newObj) {
                            rows.forEach((el, index) => {
                              el[newObj.field] = index + 1;
                            });
                          }

                          setTableState(rows);
                          emitNewRows({ args: row, state: rows });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Rows>
        {addNew && edit && (
          <div className="footer">
            <Button
              color="default"
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => {
                if (maxRows && tableState?.length === maxRows) {
                  alert(`The row limit for this table is ${maxRows}`);
                } else {
                  let newObj = {};
                  columns.map((col) => (newObj[col.field] = getDefaultValue(col)));
                  setTableState([...(tableState || []), newObj]);
                  needRender &&
                    emitNewRows({
                      args: newObj,
                      state: [...(tableState || []), newObj],
                    });
                }
              }}
            >
              Add New
            </Button>
          </div>
        )}
      </StyledEditableTable>
    </ErrorBoundary>
  );
};

// In Columns definition, type must be exluded when using lookups
export const EditableTable = memo(Table);
