import { useLayoutEffect, useState } from 'react';
import { UploadFilesService } from '../../../services';

const fetchPdf = async (docID, bodyParams, callback) => {
  try {
    const response = await UploadFilesService.previewFile(
      {
        ...bodyParams,
      },
      docID
    );

    // console.log(response);
    callback(response + '#toolbar=0');
  } catch (error) {
    console.error('Error fetching PDF:', error);
  }
};

const PdfPreviewProvider = ({ docID, bodyParams, children }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useLayoutEffect(() => {
    docID && fetchPdf(docID, bodyParams, setPdfUrl);

    // Cleanup function to revoke the object URL
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [docID]);

  return children(pdfUrl);
};

export default PdfPreviewProvider;
