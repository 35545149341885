import React, { memo, useRef } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { RequestStatus, requestStatusColors } from '../../../../Style';

import { manageRequestService as service } from '../../../../services/manage-request.service';
import { getJsonOr } from '../../../../helpers';

const StyledCalendar = styled(DateCalendar)(
  ({ theme, outside, selected }) => css`
    zoom: 85%;
    height: auto;
    max-height: max-content;
    align-self: center;
    width: 910px;

    ${theme.breakpoints.down('1200')} {
      max-width: 765px;
      width: 100% !important;
    }

    .muiskeleton-pulse {
      margin: 10px 25px;
    }

    .MuiPickersCalendarHeader-root {
      margin-top: auto;
      padding: 0;

      .MuiPickersCalendarHeader-labelContainer {
        font-size: 1.25rem;
      }
    }

    .MuiDayCalendar-header {
      display: none;

      span {
        width: 100px;
      }
    }

    .MuiDayCalendar-slideTransition {
      /* min-height: 360px; */
      min-height: 235px;

      .MuiDayCalendar-monthContainer {
        border-top: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
        border-right: 1px solid lightgrey;
        position: relative;
      }

      .MuiDayCalendar-weekContainer {
        margin: 0;
      }
    }
  `
);

const StyledServerDay = styled('div')(
  ({ theme, outside, selected }) => css`
    position: relative;
    border-bottom: 1px solid lightgrey;
    width: 130px;
    /* min-height: 70px; */
    cursor: ${outside ? 'inherit' : 'pointer'};
    font-weight: ${selected ? 'bold' : 'inherit'};
    background: ${selected
      ? `linear-gradient(
          ${theme.palette.mode == 'light' ? theme.palette.clear.main : theme.palette.nsAppBG?.main},
          ${theme.palette.clearx2.main}
        )`
      : 'inherit'};

    .day {
      color: ${outside ? 'lightgray' : 'inherit'};
      text-align: right;
      padding: 0 5px 5px;
    }

    .counts {
      display: flex;
      justify-content: center;
      padding: 10px;
      flex-flow: wrap;

      .day-count {
        display: inline-block;

        span {
          background-color: ${theme.palette.mode == 'dark' ? '#373737' : 'white'};
          border: 1px solid ${theme.palette.mode == 'dark' ? '#575757' : 'lightgray'};
          color: ${theme.palette.mode == 'dark' ? 'white' : 'black'};
        }
      }
    }

    :not(:last-child) {
      border-right: 1px solid lightgrey;
    }
  `
);

function ServerDay(props) {
  const { countsPerDay = [], openColor, billingColor, closedColor, day, outsideCurrentMonth, ...other } = props;

  function getDayData() {
    let foundObj = countsPerDay.find((item) => moment(item.SigningDate).format('D') == props.day.date());
    return foundObj?.Counts;
  }

  function getCountContent() {
    if (props.outsideCurrentMonth || getDayData() == undefined) return undefined;
    else {
      return getDayData().map((count, index) => (
        <React.Fragment key={index}>
          {index == 0 && count.OpenCount > 0 && (
            <Badge size="small" className="day-count" badgeContent={count.OpenCount}>
              <RequestStatus $fullradius={true} $colorBase={openColor}>
                {'OP'}
              </RequestStatus>
            </Badge>
          )}
          {index == 1 && count.BillingCount > 0 && (
            <Badge size="small" className="day-count" badgeContent={count.BillingCount}>
              <RequestStatus $fullradius={true} $colorBase={billingColor}>
                {'BI'}
              </RequestStatus>
            </Badge>
          )}
          {index == 2 && count.ClosedCount > 0 && (
            <Badge size="small" className="day-count" badgeContent={count.ClosedCount}>
              <RequestStatus $fullradius={true} $colorBase={closedColor}>
                {'CL'}
              </RequestStatus>
            </Badge>
          )}
        </React.Fragment>
      ));
    }
  }

  function getBadgeContent() {
    if (props.outsideCurrentMonth || getDayData() == undefined) return undefined;
    else {
      return getDayData().map((count, index) => (
        <React.Fragment key={index}>
          {index == 0 && count.OpenCount > 0 && (
            <FiberManualRecordIcon sx={{ width: '0.5em', color: requestStatusColors[openColor].dot }} />
          )}
          {index == 1 && count.BillingCount > 0 && (
            <FiberManualRecordIcon sx={{ width: '0.5em', color: requestStatusColors[billingColor].dot }} />
          )}
          {index == 2 && count.ClosedCount > 0 && (
            <FiberManualRecordIcon sx={{ width: '0.5em', color: requestStatusColors[closedColor].dot }} />
          )}
        </React.Fragment>
      ));
    }
  }

  return (
    <StyledServerDay
      outside={outsideCurrentMonth}
      selected={other.selected}
      onClick={(e) => {
        if (outsideCurrentMonth) {
          e.stopPropagation();
          return;
        }
        other.onDaySelect(moment(other['data-timestamp']));
      }}
    >
      <div className="day">{moment(props.day).format('ddd D')}</div>
      <div className="counts">{getCountContent()}</div>
    </StyledServerDay>
  );
}

const DateCalendarServerRequest = ({ filterPayload, defaultCalendar, onDateChange }) => {
  const mountRef = useRef(true);
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [countsPerDay, setCountsPerDay] = React.useState(defaultCalendar.calendar);
  const [currentDate, setCurrentDate] = React.useState(moment());

  React.useEffect(() => {
    if (!mountRef.current) return;
    if (filterPayload.dateFrom) {
      fetchRequestCountDays(moment(filterPayload.dateFrom).format('yyyy-MM-DD'));
      setCurrentDate(moment(filterPayload.dateFrom));
    } else {
      fetchRequestCountDays(moment().format('yyyy-MM-DD'));
      setCurrentDate(moment());
    }
    mountRef.current = false;

    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, [filterPayload.dateFrom]);

  const fetchRequestCountDays = async (date) => {
    const controller = new AbortController();
    setIsLoading(true);
    await service
      .getGeneralRequests({ ...filterPayload, getOnlySettings: true, dateFrom: date, dateTo: date }, controller.signal)
      .then(
        (res) => {
          setCountsPerDay(getJsonOr(res.controlSetting.calendarViewDefaults) || []);
        },
        (error) => {
          if (typeof error === 'string') {
            if (!error?.toString().includes('aborted')) {
              dispatchAlert('[Replace]' + error, true);
            }
          }
        }
      );

    setIsLoading(false);
    requestAbortController.current = controller;
  };

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    // setIsLoading(true);
    setCountsPerDay([]);
    fetchRequestCountDays(moment(date).format('yyyy-MM-DD'));
  };

  const handleDayChange = (day) => {
    onDateChange(moment(day).format('yyyy-MM-DD'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledCalendar
        value={currentDate}
        onChange={(newValue) => {
          setCurrentDate(newValue);
          handleDayChange(newValue);
        }}
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton sx={{ '.MuiSkeleton-pulse': { margin: '10px 20px' } }} />}
        onMonthChange={handleMonthChange}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            countsPerDay,
            openColor: defaultCalendar.openColor,
            billingColor: defaultCalendar.billingColor,
            closedColor: defaultCalendar.closedColor,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default memo(DateCalendarServerRequest, (prevProps, nextProps) => {
  // Only re-render if the value prop's contents change
  // console.log(prevProps.filterPayload?.dateFrom);
  // console.log(nextProps.filterPayload?.dateFrom);

  return prevProps.filterPayload?.dateFrom === nextProps.filterPayload?.dateFrom;
});
