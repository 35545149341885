import React, { useRef, useState, useEffect, Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import EmailSection from '../../common/fancy-email/EmailSection';
import Loading from '../../common/others/Loading';
import { signingRequestService as srService } from '../../../services';
import AppDialog from '../../common/others/AppDialog';
import { dispatchAlert, dispatchRelativeAlert } from '../../../helpers';

function ContactEmail({
  title,
  onOpen,
  onClose,
  requestID,
  emailTemplate,
  confirmCallback,
}) {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [mailData, setMailData] = useState();
  const [apiResult, setApiResult] = useState();

  useEffect(() => {
    if (emailTemplate) {
      let recipients = emailTemplate.recipients || [];
      setMailData({
        toEmails: recipients.map(({ email }) => email),
        ccEmails: [],
        bccEmails: [],
        recipients: recipients,
        replyTo: emailTemplate.replyTo,
        fromEmail: emailTemplate.from,
        subject: emailTemplate.subject,
        body: emailTemplate.body,
      });
    }
    return () => {};
  }, [emailTemplate]);

  const handleSend = () => {
    if (mailData.toEmails.length > 10) {
      dispatchRelativeAlert('Only 10 emails are allowed at the time.', true);
      return;
    }

    const sendEmail = async () => {
      setSendingEmail(true);
      let data = { ...mailData };
      let newRecipients = [];
      data.toEmails.forEach((email) => {
        let foundEmailObj = emailTemplate?.recipients.find(
          (rec) => rec.email == email
        );
        if (foundEmailObj) newRecipients.push(foundEmailObj);
      });
      data.recipients = newRecipients;

      await srService.sendAvailabilityRequest(requestID, data).then(
        (response) => {
          dispatchAlert('Availability request was sent to selected agents.');
          confirmCallback(true);
          setSendingEmail(false);
        },
        (error) => {
          setSendingEmail(false);
          dispatchRelativeAlert(error + '.', true);
        }
      );
    };
    sendEmail();
  };

  const parseResponse = () => {
    let jsonRes = apiResult ? apiResult : '';
    if (jsonRes) {
      let responseBySection = {
        toEmails: jsonRes.Errors?.toEmails,
        bccEmails: jsonRes.Errors?.bccEmails,
        fromEmail: jsonRes.Errors?.fromEmail,
        subject: jsonRes.Errors?.subject,
        body: jsonRes.Errors?.body,
      };
      return responseBySection;
    } else {
      return { Message: apiResult };
    }
  };

  return (
    <AppDialog open={onOpen} mainTitle={title} maxWidth="md">
      <DialogContent dividers style={{ paddingTop: '0px' }}>
        <div className={'emailContainer'}>
          <div className={'emailSection'}>
            {mailData && (
              <EmailSection
                showReplyTo
                toolbarId={'toolbar'}
                showSingleToInput="Recipients"
                validations={apiResult ? parseResponse() : ''}
                dataSection={mailData}
                setDataSection={(e) => setMailData(e)}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="text"
          color="default"
          onClick={() => onClose(true)}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          variant="contained"
          disabled={mailData?.toEmails.length == 0 || sendingEmail}
          onClick={() => handleSend(true)}
        >
          {sendingEmail ? (
            <>
              Sending <Loading forButton lpad />
            </>
          ) : (
            'Send'
          )}
        </Button>
      </DialogActions>
    </AppDialog>
  );
}

ContactEmail.propTypes = {
  onOpen: PropTypes.bool.isRequired,
};

export const ContactEmailDialog = memo(ContactEmail);
