import { Button, Grow, Paper, Popper } from '@mui/material';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import StaffDDL from '../../StaffDDL';
import AgentDDL from '../../AgentDDL';
import { NSDatePicker } from '../../../common/pickers/NSDatePicker';

const StyledCustomForm = styled('div')(
  ({ theme }) => css`
    padding: 10px;

    .actions {
      margin-top: 10px;
      justify-content: start;
      display: flex;

      .cancel {
        min-height: 30px;
        width: 50%;
      }

      .apply {
        min-height: 30px;
        margin-left: 5px;
        width: 100%;
      }
    }
  `
);

const CustomForm = (props) => {
  const { requestStatus, menuRef, form, applyFilter, editFilter } = props;

  // const [state, setState] = useState(() => {
  //   if (form.id == 'staffSearchID') {
  //     return {
  //       id: 'staffSearchID',
  //       value: form.value,
  //     };
  //   } else if (form.id == 'agentID') {
  //     return {
  //       id: 'agentID',
  //       value: form.value,
  //     };
  //   }
  // });

  const [state, setState] = useState(() => {
    return {
      id: form.id,
      value: form.value,
    };
  });

  const onSubmit = (e) => {
    e.preventDefault();
    applyFilter(state);
  };

  return (
    <Popper
      sx={{ zIndex: 1 }}
      transition
      open={true}
      anchorEl={menuRef?.current || menuRef}
      // placement={editFilter ? 'bottom' : 'left-start'}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={{ width: '300px' }}>
            <StyledCustomForm>
              <form onSubmit={onSubmit}>
                {(form.id == 'dateFrom' || form.id == 'dateTo') && (
                  <NSDatePicker
                    label={form.label}
                    date={state.value}
                    emitDate={(date) => setState({ ...state, value: date })}
                  />
                )}
                {form.id == 'staffSearchID' && (
                  <StaffDDL
                    ddlType="Search"
                    disableClearable={form.disableClearable}
                    value={state.value}
                    onChange={(val) => {
                      setState({
                        ...state,
                        value: { ...val, label: val.staffName },
                        defaultValue: val.staffName,
                      });
                    }}
                  />
                )}
                {form.id == 'agentID' && (
                  <AgentDDL
                    disableClearable={form.disableClearable}
                    requestStatus={requestStatus}
                    value={state.value}
                    onChange={(val) =>
                      setState({
                        ...state,
                        value: { ...val, label: val.agentReferralName },
                        defaultValue: val.agentReferralName,
                      })
                    }
                  />
                )}
                <div className="actions">
                  <Button className="cancel" color="default" onClick={() => props.onClose()}>
                    Cancel
                  </Button>
                  <Button className="apply" type="submit" onClick={() => props.applyFilter(state)}>
                    Apply Filter
                  </Button>
                </div>
              </form>
            </StyledCustomForm>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default CustomForm;
