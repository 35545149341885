import React from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { HtmlTooltip } from '../../../Style';
import { Divider, Typography, Badge } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

const StyledTooltip = styled('div')(
  ({ theme }) => css`
    display: flex;
  `
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 9,
    padding: '0 4px',
  },
}));

const AgentAvailRequestInfo = ({ availRequest }) => {
  const parsedDateTime = (date) => {
    return moment.utc(date).local().format('MM/DD/YYYY h:mm A');
  };

  const getItemRowBackgroundColor = (item) => {
    if (item.responseOption == 'Available') return 'green';
    else if (item.responseOption == 'UnAvailable') return 'crimson';
    else if (item.responseOption == 'LowFee') return 'darkorange';
    else if (item.responseOption == 'SchedulingConflict') return 'darkorange';
    else return 'inherit';
  };

  const getRequestLastResponse = () => {
    if (availRequest.requestLastResponse) {
      if (availRequest.requestLastResponse == 'Available') return <MarkEmailReadIcon color="success" />;
      else if (availRequest.requestLastResponse == 'UnAvailable') return <MarkEmailReadIcon color="error" />;
      else if (availRequest.requestLastResponse == 'LowFee') return <MarkEmailReadIcon color="warning" />;
      else if (availRequest.requestLastResponse == 'SchedulingConflict') return <MarkEmailReadIcon color="warning" />;
    } else {
      let _lastResponse = _.orderBy(availRequest.requestHistory, [(obj) => new Date(obj.respondedOn)], ['desc'])[0];

      let badgeColor;
      if (_lastResponse.responseOption == 'Available') badgeColor = 'success';
      else if (_lastResponse.responseOption == 'UnAvailable') badgeColor = 'error';
      else if (_lastResponse.responseOption == 'LowFee') badgeColor = 'warning';
      else if (_lastResponse.responseOption == 'SchedulingConflict') badgeColor = 'warning';

      return (
        <StyledBadge color={badgeColor} overlap="circular" variant={'dot'} invisible={!badgeColor ? true : false}>
          <MailOutlinedIcon color="info" />
        </StyledBadge>
      );
    }
  };

  return (
    <StyledTooltip>
      {availRequest.requestWasSent ? (
        <HtmlTooltip
          minwidth="400px"
          arrow
          title={
            <div>
              <div style={{ display: 'flex', gap: 2 }} className={'with-header'}>
                <div style={{ width: '25px', textAlign: 'center' }}>
                  <Typography variant="body2">{`#`}</Typography>
                </div>
                <div style={{ flex: 2.5 }}>
                  <Typography variant="body2">Sent On</Typography>
                </div>
                <div style={{ flex: 3 }}>
                  <Typography variant="body2">Response</Typography>
                </div>
                <div style={{ flex: 1 }}>
                  <Typography variant="body2">Total Fee</Typography>
                </div>
              </div>
              <Divider sx={{ mt: 0.5, mb: 1 }} />
              {availRequest.requestHistory?.map((item, index) => (
                <div key={index} style={{ display: 'flex', gap: 2, backgroundColor: getItemRowBackgroundColor(item) }}>
                  <div style={{ width: '25px', textAlign: 'right' }}>
                    <Typography variant="body2">{`${availRequest.requestHistory.length - index}. `}</Typography>
                  </div>
                  <div style={{ flex: 2.5 }}>
                    <Typography variant="body2">{parsedDateTime(item.sentOn)}</Typography>
                  </div>
                  <div style={{ flex: 3 }}>
                    {item.responseOption ? (
                      <Typography variant="body2">
                        {item.responseOption == 'Available' && 'Yes, I am available.'}
                        {item.responseOption == 'UnAvailable' && 'No, I am unavailable.'}
                        {item.responseOption == 'LowFee' && 'No, Fee too low.'}
                        {item.responseOption == 'SchedulingConflict' && 'No, Scheduling Conflict.'}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        No Response.{' '}
                        {item.hasExpired && (
                          <Typography variant="caption">
                            <i>Expired</i>
                          </Typography>
                        )}
                      </Typography>
                    )}
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography variant="body2">${item.proposedTotalFee}</Typography>
                  </div>
                </div>
              ))}
            </div>
          }
        >
          {availRequest.requestWasReplied ? <div>{getRequestLastResponse()}</div> : <MailOutlinedIcon color="info" />}
        </HtmlTooltip>
      ) : (
        <MailOutlinedIcon disabled color={'disabled'} onClick={() => testIconClick('email')} />
      )}
    </StyledTooltip>
  );
};

export default AgentAvailRequestInfo;
