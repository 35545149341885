import { DashboardCard } from '../dashboard/style';
import { Button, CardContent, CardHeader, LinearProgress, Link, ListItemIcon, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import Show from '../common/others/Show';
import { F } from '../../helpers/functions';
import Chip from '@mui/material/Chip';
import InfoTooltip from '../common/others/InfoTooltip';

const agreementDataMapper = ({ status, statusUpdatedDate, ...props }) => ({
  ...props,
  date: statusUpdatedDate,
  status: status?.toUpperCase(),
});

export const useAgreementStatusCard = ({ moduleTitle, currentAgreement = {}, agreements = [], ...props }) => {
  const fileCountInfo = agreements.length > 0 ? `${agreements.length} prior agreements` : 'No prior agreements found.';
  const showAgreementSign = currentAgreement?.status === 'Declined';
  const agreementStatus = showAgreementSign ? 'DECLINED' : currentAgreement?.status ? 'ACCEPTED' : '';
  const agreementStatusColor = showAgreementSign ? 'warning.main' : 'success.main';

  return {
    moduleTitle: moduleTitle || 'Agreements',
    title: `Contract Agreement`,
    fileCountInfo,
    showAgreementSign,
    agreementStatusColor,
    agreementStatus,
    agreements: agreements.map(agreementDataMapper),
    currentAgreement: agreementDataMapper(currentAgreement || {}),
    hasCurrentAgreement: Object.values(currentAgreement || {}).length > 0,
    ...props,
  };
};

export const AgreementStatusCard = ({ onShowAgreement = F, onDownload = F, ...props }) => {
  const {
    moduleTitle,
    title,
    fileCountInfo,
    agreements,
    currentAgreement,
    agreementStatus,
    agreementStatusColor,
    showAgreementSign,
    hasCurrentAgreement,
    isMobile,
  } = useAgreementStatusCard(props);

  const renderAgreementStatusIcon = showAgreementSign ? (
    <NewReleasesIcon color="warning" />
  ) : (
    <VerifiedIcon color="success" />
  );

  return (
    <DashboardCard {...props}>
      {props.children} {/* this is for adding ModuleOptions */}
      <CardHeader className="card-header" title={<div className="title">{moduleTitle}</div>}></CardHeader>
      <CardContent>
        <Stack
          direction={{ xs: 'col', sm: 'col', xsm: 'row', md: 'col', lg: 'row' }}
          useFlexGap
          spacing={2}
          alignItems={{ xs: 'flex-start', sm: 'flex-start', xsm: 'center', md: 'flex-start', lg: 'center' }}
          justifyContent="space-between"
        >
          <Show when={hasCurrentAgreement}>
            <Box>
              <Typography variant="h6" component="div" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                {title}{' '}
                <Show when={agreementStatus}>
                  <Typography component="span" color={agreementStatusColor} fontWeight="bold">
                    {agreementStatus}
                  </Typography>
                </Show>
                <Show when={showAgreementSign}>
                  <InfoTooltip title={currentAgreement?.declineComment} placement="top" arrow sx={{ pl: 1 }} />
                </Show>
              </Typography>

              <ListItem sx={{ padding: '4px 16px 4px 0' }}>
                <Show when={currentAgreement?.date}>
                  <ListItemIcon sx={{ minWidth: '25px' }}>{renderAgreementStatusIcon}</ListItemIcon>
                  <Link
                    onClick={() => onDownload(currentAgreement?.contractId)}
                    underline="hover"
                    variant="body"
                    color="text.primary"
                  >
                    {`(${currentAgreement?.date}) ${currentAgreement.displayName}`}
                  </Link>
                </Show>
              </ListItem>
            </Box>
          </Show>

          <Show when={showAgreementSign}>
            <Box sx={{ width: isMobile ? '100%' : 'auto' }}>
              <Button color="primary" size="medium" onClick={onShowAgreement} fullWidth={isMobile}>
                Agree to Terms
              </Button>
            </Box>
          </Show>
        </Stack>

        <LinearProgress variant="determinate" value={100} sx={{ marginTop: 1, marginBottom: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" color="text.secondary">
            {fileCountInfo}
          </Typography>
        </Box>

        <List dense alignItems="flex-start">
          {agreements.map(({ contractId, date, url, status, name, displayName }, index) => (
            <ListItem key={index} sx={{ pl: 0 }}>
              <ListItemIcon sx={{ minWidth: '25px' }}>
                <DownloadIcon />
              </ListItemIcon>
              <Link onClick={() => onDownload(contractId)} underline="hover" variant="body" color="text.primary">
                ({date}) {displayName || 'Contract Agreement'}
              </Link>
              <Chip
                sx={{
                  height: '16px',
                  fontSize: '10px',
                  marginLeft: '5px',
                  borderRadius: '20px',
                }}
                label={status}
                size="small"
                color={status === 'DECLINED' ? 'warning' : 'success'}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </DashboardCard>
  );
};
