import React, { useEffect } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

import UnderConstruction from '../../../assets/images/under-construction.png';
import { Alert, Typography } from '@mui/material';

const Container = styled('div')(
  ({ theme }) => css`
    margin: 0 auto;
    padding: 20px;

    & p {
      font-size: 14px;
      text-align: center;
    }

    & img {
      width: 250px;
      height: 250px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  `
);

const ModuleNotFound = (props) => {
  useEffect(() => {
    return () => {
      //console.log('Does it need cleanup?');
    };
  }, []);

  return (
    <Container>
      <Alert severity="info">
        <Typography>
          Current page does not have any module assigned yet or you are not
          authorized to view it.
        </Typography>
      </Alert>
      <img src={UnderConstruction} alt="coming soon..." />
    </Container>
  );
};

export default ModuleNotFound;
