import { create } from 'zustand/index';
import { devtools } from 'zustand/middleware';

export const useAgentProfileFormStore = create(
  devtools((set) => ({
    blockButtonActions: false,
    ignoreDupWarning: false,
    duplicatedProfiles: [],
    addedProfiles: [],
    addNewProfile: (profile) => set((state) => ({ addedProfiles: [profile, ...state.addedProfiles] })),
    replaceProfile: (profile) =>
      set((state) => {
        const index = state.addedProfiles.findIndex((p) => p.identityId === profile.identityId);
        if (index === -1) {
          return state;
        }
        const addedProfiles = [...state.addedProfiles];
        addedProfiles[index] = profile;
        return { ...state, addedProfiles };
      }),
    setDuplicatedProfiles: (profiles) => set(() => ({ duplicatedProfiles: profiles })),
    disableSubmit: (ignoreDupWarning) =>
      set(() => ({ ...(typeof ignoreDupWarning !== undefined ? { ignoreDupWarning } : {}) })),
    restoreSubmit: (ignoreDupWarning) =>
      set(() => ({
        ...(typeof ignoreDupWarning !== undefined ? { ignoreDupWarning } : {}),
      })),

    cleanDupState: () =>
      set(() => ({
        blockButtonActions: false,
        ignoreDupWarning: false,
        duplicatedProfiles: [],
      })),
  }))
);
