import React from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import QuillEditor from './QuillEditor';

const StyledDiv = styled('div')(
  ({ theme, $counter, editorheight }) => css`
    width: 100%;

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid ${theme.palette.mode == 'dark' ? 'rgb(255 255 255 / 46%)' : 'rgb(0 0 0 / 3%)'} !important;
    }

    .ql-editor {
      overflow: auto;
      max-height: ${editorheight == 'auto' ? 'auto' : editorheight || '300px'};
      height: ${editorheight == 'auto' ? 'auto' : editorheight || '200px'};
      margin-bottom: ${$counter ? '22px' : '0px'};
      margin-top: 3px;
      margin-right: 3px;
      margin-bottom: 3px;
    }

    .ql-snow.ql-toolbar {
      background: ${theme.palette.mode == 'dark' ? 'rgb(255 255 255 / 46%)' : 'rgb(0 0 0 / 3%)'};
      border-radius: 5px 5px 0px 0px;
      padding: 5px;
    }

    .ql-editor.ql-blank::before {
      color: inherit;
    }

    .chard-count-wrapper {
      position: relative;
      top: -20px;
      right: 20px;
      font-size: 0.73rem;
      color: #b5b5b5;

      .ql-count {
        float: right;
      }
    }

    .ql-editor::-webkit-scrollbar {
      width: 5px;
    }

    .ql-editor::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1.5px solid lightgrey;
      border-radius: 5px;
    }

    .ql-editor::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.primary.main};
      /* border: 2px solid #616161; */
      border-radius: 5px;
    }
  `
);

const RichTextEditor = ({ readOnly, characterMax = null, editorHeight, value, onChange }) => {
  // Use a ref to access the quill instance directly
  const quillRef = React.useRef();

  const [characterCount, setCharacterCount] = React.useState(0);

  React.useEffect(() => {
    setCharacterCount(quillRef.current?.getLength());
    return () => {};
  }, [quillRef]);

  return (
    <StyledDiv $counter={characterMax ? true : false} editorheight={editorHeight}>
      <QuillEditor
        ref={quillRef}
        readOnly={readOnly}
        characterMax={characterMax ? characterMax : 100000}
        defaultValue={value}
        onTextChange={() => {
          // console.log(quillRef.current?.root.innerHTML);
          // onChange(quillRef.current?.getSemanticHTML()); // this remove <br />
          onChange(quillRef.current?.root.innerHTML);
          setCharacterCount(quillRef.current?.getLength());
        }}
      />
      {characterMax != null && (
        <div className="chard-count-wrapper">
          <span className="ql-count">{`Character Count: ${characterCount} / ${characterMax}`}</span>
        </div>
      )}
    </StyledDiv>
  );
};

export default RichTextEditor;
