import React, { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import { store } from '../../../store';

const Container = styled('div')(
  ({ theme }) => css`
    & p {
      font-size: 14px;
      text-align: center;
    }
  `
);

const NotAuthorized = (props) => {
  let history = useHistory();
  const { currentUser } = store.getState().reducer;
  const [locationToRedirect, setLocationToRedirect] = useState(null);

  useEffect(() => {
    if (currentUser.detail === '') {
      setLocationToRedirect('Login');
      setTimeout(() => {
        history.push('/login');
      }, 1500);
    } else {
      setLocationToRedirect('Dashboard');
      setTimeout(() => {
        history.push('/dashboard');
      }, 1500);
    }
    return () => {
      //console.log('Does it need cleanup?');
    };
  }, []);

  return (
    <Container>
      <p>
        Being redirected to <strong>{locationToRedirect}...</strong>
      </p>
    </Container>
  );
};

export default NotAuthorized;
