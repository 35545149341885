import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { create } from 'zustand/index';
import { devtools } from 'zustand/middleware';
import { store } from '../store';
import { portalService } from '../services';
import { useDirtyChangesStore } from './dirty-changes.store';
import { useShallow } from 'zustand/react/shallow';

const initialState = {
  dataIsLoading: false,
  attemptPanelAccess: false,
  expanded: false,
  currentSetting: '',
  shouldBlockAccordion: false,
  progressType: false,
  originalSettings: null,
  reset: () => ({ ...initialState }),
};

const devToolsConfig = {
  name: 'BusinessSettingsStore',
  enabled: true,
  store: 'BusinessSettingsStore',
};

export const useBusinessSettingsStore = create(
  devtools(
    (set, get) => ({
      ...initialState,
      setDataIsLoading: (v) => set((s) => ({ ...s, dataIsLoading: v })),
      setAttemptPanelAccess: (v) => set((s) => ({ ...s, attemptPanelAccess: v })),
      setExpanded: (v) => set((s) => ({ ...s, expanded: v })),
      setCurrentSetting: (v) => set((s) => ({ ...s, currentSetting: v })),
      setShouldBlockAccordion: (v) => set((s) => ({ ...s, shouldBlockAccordion: v })),
      setProgressType: (v) => set((s) => ({ ...s, progressType: v })),
      setOriginalSettings: (v) => set((s) => ({ ...s, originalSettings: v })),
      reset: () => set((state) => ({ ...initialState })),
    }),
    devToolsConfig
  )
);

export const useBusinessSettings = () => {
  const dispatch = useDispatch();
  const { portalID } = store.getState().reducer.global;

  const {
    originalSettings,
    setOriginalSettings,
    dataIsLoading,
    setDataIsLoading,
    currentSetting,
    setCurrentSetting: setCurrentSettingRaw,
    expanded,
    setExpanded,
    attemptPanelAccess,
    setAttemptPanelAccess,
    shouldBlockAccordion,
    setShouldBlockAccordion,
    progressType,
    setProgressType,
    reset: resetState,
  } = useBusinessSettingsStore(useShallow((state) => state));

  const hasDirtyChanges = useDirtyChangesStore((s) => s.isDirty);
  const cleanDirtyChanges = useDirtyChangesStore((s) => s.reset);
  const disableDirtyChangesSync = useDirtyChangesStore((s) => s.disableDirtyChangesSync);
  const enableDirtyChangesSync = useDirtyChangesStore((s) => s.enableDirtyChangesSync);
  const setDirtyChanges = useDirtyChangesStore((s) => s.setDirtyState);
  const currentSettingRef = useRef(null);

  useEffect(() => {
    dispatch({ type: 'CLEAR_ALERTS' });
    currentSettingRef.current = '';
    return () => {
      resetState();
    };
  }, []);

  const syncCurrentSettings = (settings) => {
    cleanDirtyChanges();
    setCurrentSettingRaw(settings);
    setOriginalSettings(typeof settings === 'object' ? JSON.stringify(settings) : '');
  };

  const getBusSettings = async (settingName) => {
    setProgressType(settingName);
    setDataIsLoading(true);
    setCurrentSettingRaw(null); // this is need to remove all object properties
    await portalService.getBusSettings(settingName).then((res) => {
      setExpanded(settingName);
      syncCurrentSettings(res);
    });
    setDataIsLoading(false);
  };

  const updateBusSettings = (settingName, settingText) => {
    return portalService
      .updateBusSettings(settingName, JSON.stringify(currentSetting))
      .then((res) => {
        syncCurrentSettings(res);
        store.dispatch({
          type: 'PUSH_ALERT',
          payload: {
            open: true,
            color: 'success',
            message: settingText + ' settings were updated successfully!',
          },
        });
      })
      .catch((error) => {
        store.dispatch({
          type: 'PUSH_ALERT',
          payload: {
            open: true,
            color: 'error',
            message: '[Replace]' + error,
            keepOpen: true,
          },
        });
      });
  };

  const handleCurrentSettingChange = (settings, customVerification) => {
    const valid = !!settings;
    if (!valid) return;

    if (typeof customVerification === 'function') {
      setDirtyChanges(customVerification());
    } else {
      const current = typeof settings === 'object' && JSON.stringify(settings);
      setDirtyChanges(originalSettings != current);
    }

    setCurrentSettingRaw(settings);
  };

  return {
    portalID,
    currentSetting,
    originalSettings,
    shouldBlockAccordion,
    dataIsLoading,
    expanded,
    attemptPanelAccess,
    progressType,
    hasDirtyChanges,
    setAttemptPanelAccess,
    setDataIsLoading,
    setExpanded,
    setShouldBlockAccordion,
    setCurrentSetting: handleCurrentSettingChange,
    setOriginalSettings,
    disableDirtyChangesSync,
    enableDirtyChangesSync,
    getBusSettings,
    updateBusSettings,
    setDirtyChanges,
  };
};

export default useBusinessSettings;
