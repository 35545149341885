import React from 'react';
import Search from './Search';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const HeaderStyle = styled('div')(
  ({ theme }) => css`
    .Header {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Header__title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 375px) {
      .Header {
        padding: 2rem;
      }
    }
  `
);

const Header = ({ searchCity }) => {
  return (
    <HeaderStyle>
      <header className="Header">
        <Search searchCity={searchCity} />{' '}
      </header>
    </HeaderStyle>
  );
};

export default Header;
