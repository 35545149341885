import React, { Fragment, useEffect, useState, useContext, createRef } from 'react';
import {
  Button,
  Alert,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Typography,
  Divider,
  CardActions,
  CardContent,
  Card,
  Box,
  Tabs,
  Tab,
  AlertTitle,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AddIcon from '@mui/icons-material/Add';
import Dropzone from 'react-dropzone';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { deviceType, isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { StyledDropzoneContainer, UploadFilesCss } from '../style';
import AppConfirmationDialog from '../../others/AppConfirmationDialog';
import { UploadFilesDialogSaved } from '../upload-files-dialog-saved';
import { UploadFilesDialogSelected } from '../upload-files-dialog-selected';
import Loading from '../../others/Loading';
import { dispatchRelativeAlert } from '../../../../helpers';
import { ErrorBoundary } from '../../../../ErrorBoundery';
import { useTheme } from 'styled-components';
import { store } from '../../../../store';
import { FileUploadContext } from '../FileUploadContext';
import NSDragDropIcon from '../../../../assets/images/drag-and-drop.png';
import { CustomTabPanel } from '../upload-files-dialog';

const ScanBackUploader = ({ open, onClose, buttonTrueText, buttonFalseText, data, dialog }) => {
  const dispatch = useDispatch();
  const {
    uploadSingleRequest,
    setStartUploadFromChild,
    isSubmitting,
    setIsSubmitting,
    state,
    setState,
    getFiles,
    counterDispatch,
    secondaryButtonText,
    setSecondaryButtonText,
    canEdit,
    isGettingFiles,
    showNavigateAwayAlert,
    setShowNavigateAwayAlert,
  } = useContext(FileUploadContext);

  const { innerWidth: width, innerHeight: height } = window;

  const [showAlertNote, setShowAlertNote] = useState(true);
  const [dragStyle, setDragStyle] = useState('');
  const [sameFiles, setSameFiles] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({
    msg: '',
    open: false,
  });
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState({
    open: false,
  });

  let isValidDocuments = true;
  const { savedFiles, selectedFiles } = state;
  const userRoles = store.getState().reducer.currentUser.userRoles;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('0', '375'));
  const dialogFullScreen = useMediaQuery(theme.breakpoints.between('0', '700'));

  useEffect(() => {
    configureScreen();
    return () => {};
  }, [width]);

  useEffect(() => {
    configureScreen();
    getFiles();
    return () => {};
  }, []);

  useEffect(() => {
    counterDispatch({
      type: 'setUploadTypeTotal',
      payload: {
        newFileUploadTotal: state.selectedFiles.length,
        externalUploadTotal: state.externalFiles.length,
      },
    });
    return () => {};
  }, [state]);

  const configureScreen = () => {
    setSecondaryButtonText(buttonFalseText);
  };

  const handleConfirmation = (value) => {
    if (value) if (isValidDocuments) setOpenSaveConfirmation({ open: true });
    setOpenConfirmation({ msg: '', open: false });
  };

  const handleSaveConfirmation = async (value) => {
    setIsSubmitting(true);
    if (value && isValidDocuments && !uploadSingleRequest) {
      setShowNavigateAwayAlert(true);
      counterDispatch({ type: 'setProcessState' });
      /***************************************
       ***  BEGIN: Activate child api call ***
       **************************************/
      setStartUploadFromChild(true);
      /***************************************
       ****  END: Activate child api call ****
       **************************************/
    } else setIsSubmitting(false);

    setOpenSaveConfirmation({ open: false });
  };

  const validateSelectedFilesForUpload = () => {
    sameFiles.splice(0, sameFiles.length);
    setOpenConfirmation((values) => ({
      msg: '',
      open: false,
    }));

    for (var i = 0; i < selectedFiles.length; i++) {
      for (var j = 0; j < savedFiles.length; j++) {
        if (selectedFiles[i].name == savedFiles[j].displayName) {
          sameFiles.push(selectedFiles[i].name);
          setSameFiles(sameFiles);
        }
      }
    }

    if (sameFiles.length > 0) {
      isValidDocuments = false;
      setOpenConfirmation((values) => ({
        msg: 'A document(s) with the same name already exists. Would you like to replace them?',
        open: true,
      }));
    }
  };

  const deleteSelectedFile = (file) => {
    const newselectedFiles = selectedFiles.filter(function (value, index, arr) {
      return value.docId !== file.docId;
    });

    setState((values) => ({
      ...values,
      selectedFiles: newselectedFiles,
    }));
  };

  const onDragLeave = () => {
    setDragStyle('');
  };

  const onDragOver = () => {
    setDragStyle('#949794');
  };

  const getValidExtensionsAsString = (string) => {
    // Define a regex to match and capture the groups inside the original regex pattern
    const groupExtractor = /\(([^)]+)\)/g;

    // Extract the groups
    let match;
    const groups = [];
    while ((match = groupExtractor.exec(string)) !== null) {
      groups.push(match[1]);
    }

    return groups[1];
  };

  const validateOnDrop = (files) => {
    for (var i = 0; i < files.length; i++) {
      const regexName = new RegExp(state.settings.regexCharacters, 'i');
      if (!regexName.test(files[i].name)) {
        dispatchRelativeAlert(
          `${files[i].name} document contains an invalid special character. Allowed special characters include "period, underscore, dash and space"`,
          true
        );
        return;
      }

      const regexExtension = new RegExp(state.settings.regexExtensions, 'i');
      if (!regexExtension.test(files[i].name)) {
        dispatchRelativeAlert(
          `${
            files[i].name
          } document contains an invalid extension. Allowed extensions include "${getValidExtensionsAsString(
            state.settings.regexExtensions
          )}"`,
          true
        );
        return;
      }

      if (data?.maxSize && files[i].size > data?.maxSize) {
        dispatchRelativeAlert(`File size exceeds the permitted size limit.`, true);
        return;
      }
    }

    onDrop(files);
  };

  const onDrop = (files) => {
    dispatch({ type: 'SET_ROUTE_PROMPT_STATE', payload: '' });
    setDragStyle('');

    for (var i = 0; i < files.length; i++) {
      let doc = files[i];
      selectedFiles.push({
        docId: new Date().getTime() + i,
        name: doc.name,
        path: doc.path,
        size: doc.size,
        lastModified: doc.lastModified,
        description: '',
        file: doc,
      });
    }

    setState((values) => ({
      ...values,
      selectedFiles: selectedFiles,
    }));
  };

  const handleCancel = () => {
    setShowNavigateAwayAlert(false);
    onClose(state.savedFiles);
  };

  const editSelectedFile = (index, value) => {
    selectedFiles[index] = value;
    setState((values) => ({
      ...values,
      selectedFiles: selectedFiles,
    }));
  };

  const UploadAllDocuments = () => {
    isValidDocuments = true;
    validateSelectedFilesForUpload();
    if (isValidDocuments) setOpenSaveConfirmation({ open: true });
    else setIsSubmitting(false);
  };

  const dropzoneRef = createRef();

  const dropzoneDisableHandler = () => {
    return selectedFiles.length == 1 || isMobile;
  };

  return (
    <ErrorBoundary>
      {openConfirmation.open && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={openConfirmation.open}
          title={'Confirmation'}
          content={openConfirmation.msg}
          onClose={() => {}}
          confirmCallback={(val) => handleConfirmation(val)}
          contentHtml={
            <Fragment>
              <div>{openConfirmation.msg}</div>
              {sameFiles && (
                <ul style={{ marginTop: '10px' }}>
                  {sameFiles &&
                    sameFiles.map((data, index) => (
                      <li key={index} style={{ fontWeight: '600' }}>
                        {data}
                      </li>
                    ))}
                </ul>
              )}
            </Fragment>
          }
        />
      )}
      {openSaveConfirmation.open && (
        <AppConfirmationDialog
          id="appSaveConfirmationDialog"
          open={openSaveConfirmation.open !== false ? true : false}
          title={'Save uploaded documents'}
          contentHtml={
            <div>
              {userRoles.includes('NotaryAgent') && data?.signingStatusID == 4 && (
                <>
                  <Alert severity="warning">
                    <AlertTitle>Important Warning!</AlertTitle>
                    Have you selected all your files? Once you select OK, you will no longer have access to this
                    request.
                  </Alert>
                  <br />
                </>
              )}
              {selectedFiles.length > 0 && (
                <p style={{ paddingBottom: '10px' }}>
                  <UploadFileOutlinedIcon style={{ margin: '0px 5px -5px 0px' }} />
                  <span>
                    <span style={{ fontWeight: '600' }}>{selectedFiles.length}</span> new{' '}
                    {selectedFiles.length > 1 ? 'files' : 'file'} to save.
                  </span>
                </p>
              )}
            </div>
          }
          onClose={() => {}}
          confirmCallback={(val) => handleSaveConfirmation(val)}
        />
      )}
      <Card variant="outlined" sx={{ border: 'none' }}>
        <CardContent
          sx={{
            p: dialogFullScreen ? '5px 10px' : '',
            height: dialog && dialogFullScreen ? 'calc(100vh - 130px)' : '',
          }}
        >
          <UploadFilesCss $noGragDrop={['mobile', 'tablet'].includes(deviceType)}>
            <UploadFilesDialogSaved scanbacks savedFiles={savedFiles} />
            {data?.notes && showAlertNote && !isGettingFiles && (
              <Alert severity="warning" onClose={() => setShowAlertNote(false)}>
                {data?.notes}
              </Alert>
            )}
            <br />
            {showNavigateAwayAlert && (
              <Alert sx={{ mb: 1 }} severity="warning" variant="filled" onClose={() => setShowNavigateAwayAlert(false)}>
                <Typography variant="subtitle2">
                  Do not refresh your browser or close this window while your documents are being uploaded
                </Typography>
              </Alert>
            )}
            {canEdit && state.settings.showUploadPanel && (
              <Card variant={!isMobile ? 'outlined' : ''}>
                <Dropzone
                  ref={dropzoneRef}
                  noClick
                  multiple
                  onDrop={validateOnDrop}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                >
                  {({ getRootProps, getInputProps, open, isFocused, isDragReject, isDragAccept }) => {
                    return (
                      <Fragment>
                        <Box
                          className="tab-container"
                          sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                          }}
                        >
                          <Tabs sx={{ paddingTop: '5px' }} value={0} aria-label="basic tabs example">
                            <Tab
                              label={
                                <div className="new-files-tab">
                                  <img src={NSDragDropIcon} alt="Drag & drop" />
                                  <span>Upload New Files</span>
                                </div>
                              }
                            />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={0} index={0}>
                          <StyledDropzoneContainer
                            style={{ backgroundColor: dragStyle }}
                            className="desktop"
                            {...getRootProps({
                              className: `dropzone ${dropzoneDisableHandler() ? 'no-border' : ''}`,
                              isFocused,
                              isDragAccept,
                              isDragReject,
                            })}
                          >
                            <input {...getInputProps()} />
                            <UploadFilesDialogSelected
                              scanbacks
                              selectedFiles={selectedFiles}
                              editSelectedFile={editSelectedFile}
                              deleteSelectedFile={deleteSelectedFile}
                            />
                            {['mobile', 'tablet'].includes(deviceType) ? (
                              <Button
                                className={`btn-browse ${deviceType}`}
                                type="submit"
                                startIcon={<AddIcon />}
                                color="default"
                                onClick={open}
                              >
                                browse files
                              </Button>
                            ) : (
                              <>
                                {selectedFiles.length == 0 && (
                                  <div className="no-files">
                                    <CloudUploadOutlinedIcon />
                                    <p>Drag files to upload</p>
                                    <Divider>or</Divider>
                                    <Button
                                      className="btn-browse"
                                      color="default"
                                      onClick={() => dropzoneRef.current?.open()}
                                    >
                                      browse files
                                    </Button>
                                    <div>
                                      {parseInt(data?.maxSize || 0) > 1048576
                                        ? `Max ${data?.maxSize / 1048576}mb per file`
                                        : 'Max 60mb per file'}
                                    </div>
                                  </div>
                                )}
                                {selectedFiles.length > 0 && (
                                  <Button
                                    className="btn-browse with-files"
                                    startIcon={<AddIcon />}
                                    color="default"
                                    onClick={() => dropzoneRef.current?.open()}
                                  >
                                    browse files
                                  </Button>
                                )}
                              </>
                            )}
                          </StyledDropzoneContainer>
                        </CustomTabPanel>
                      </Fragment>
                    );
                  }}
                </Dropzone>
              </Card>
            )}
          </UploadFilesCss>
        </CardContent>
        <Divider />
        <CardActions
          sx={{
            justifyContent: 'space-between',
            width: '100%',
            position: dialog && dialogFullScreen ? 'absolute' : '',
            bottom: dialog && dialogFullScreen ? '0px' : '',
          }}
        >
          <div>
            {state.settings.showUploadNotification && (
              <FormControlLabel
                sx={{ m: 0 }}
                label={
                  <Typography variant="body2">
                    {isSmallScreen ? 'Email Notification' : 'Send Upload Notification'}
                  </Typography>
                }
                labelPlacement="start"
                control={
                  <Checkbox
                    disabled={userRoles.includes('Customer') || userRoles.includes('NotaryAgent')}
                    checked={state.settings.overrideSendDocsNotice || false}
                    style={{ marginRight: '20px' }}
                    onChange={(e) =>
                      setState((values) => ({
                        ...values,
                        settings: {
                          ...values.settings,
                          overrideSendDocsNotice: e.target.checked,
                        },
                      }))
                    }
                  />
                }
              />
            )}
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {open && (
              <Button autoFocus color="default" onClick={handleCancel} disabled={isSubmitting}>
                {secondaryButtonText || 'Cancel'}
              </Button>
            )}
            <Button
              variant="contained"
              className="upload-button"
              disabled={selectedFiles.length <= 0 || !canEdit || isSubmitting}
              onClick={UploadAllDocuments}
            >
              {buttonTrueText || 'Upload'} {isSubmitting && <Loading forButton lpad />}
            </Button>
          </div>
        </CardActions>
      </Card>
    </ErrorBoundary>
  );
};

export default ScanBackUploader;
