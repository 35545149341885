import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PersonalInfo from './form/PersonalInfoForm';
import { agentProfileSchema, schemaDefaults } from './AgentProfileSchema';
import Grid from '@mui/material/Grid';
import AgentProfileTable from './AgentProfileTable';
import { contactManagerService } from '../../services';
import { useLoadingStore } from '../../context/loading.store';
import { useCloseModal, useModalAlert } from '../../helpers/hooks/useModals';
import Slide from '@mui/material/Slide';
import { useAgentProfileFormStore } from './hooks/useAgentProfileFormStore';
import { Alert } from '@mui/material';
import { agentProfileDataMapper } from './AgentProfileMappers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from 'styled-components';
import { deepmerge } from '@mui/utils';
import { plainErrorMsgHandler } from '../../helpers/handle-fetch-errors';

const inputCustomTheme = {
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
        inputProps: {
          autoComplete: '--off--',
          form: {
            autoComplete: 'off',
          },
        },
      },
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },

          marginBottom: 0,

          '.MuiOutlinedInput-input': {
            height: '15px',
            lineHeight: '15px',
          },
        },
      },
    },
  },
};

const MESSAGES = {
  UNHANDLED_EXCEPTION_TYPE: 'Unhandled Exception. Please contact support.',
};

const AgentProfileForm = ({ isMobile }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(agentProfileSchema),
    defaultValues: schemaDefaults,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const setLoadingState = useLoadingStore((state) => state.setLoadingState);
  const setDuplicateProfiles = useAgentProfileFormStore((state) => state.setDuplicatedProfiles);
  const addNewProfile = useAgentProfileFormStore((state) => state.addNewProfile);
  const duplicateProfiles = useAgentProfileFormStore((state) => state.duplicatedProfiles);
  const ignoreDupWarning = useAgentProfileFormStore((state) => state.ignoreDupWarning);
  const resetStore = useAgentProfileFormStore((s) => s.cleanDupState);
  const { closeLatestModal } = useCloseModal();
  const { pushAlert } = useModalAlert();

  const mainTheme = useTheme();
  const theme = createTheme(deepmerge(mainTheme, inputCustomTheme));

  const notify = (type, message) => {
    pushAlert({
      open: true,
      color: type,
      message,
      ...(type === 'error' ? { timeOut: 8000 } : {}),
    });
  };

  const handleDuplicatesProfiles = (response) => {
    const profiles = JSON.parse(response.detail);
    return profiles?.length > 0 && setDuplicateProfiles(profiles);
  };

  const handleProfileErrors = (error) => {
    if (error?.type === 'ValidationFailure' && error?.title === 'DuplicateProfiles') {
      // why we check for "error?.title" here? Because DuplicateProfiles is a special/custom validation
      return handleDuplicatesProfiles(error);
    } else if (error?.type === 'ValidationFailure') {
      notify('error', plainErrorMsgHandler(error));
    } else {
      notify('error', error || MESSAGES.UNHANDLED_EXCEPTION_TYPE);
    }

    resetStore();
    // closeLatestModal();
  };

  const handleSuccessResponse = (resp) => {
    addNewProfile(agentProfileDataMapper(resp));
    closeLatestModal();
  };

  const onSubmit = async (data) => {
    setLoadingState(true);

    //set a new AbortController instance to the reducer
    let newController = new AbortController();
    let signal = newController.signal;

    try {
      //set the abort controller to the reducer
      const response = await contactManagerService.submitAgentProfileForm(
        { ignoreDupWarning },
        data,
        signal,
        () => {} // this could be an anonymous function or handleProfileErrors. We just need the signature
      );
      handleSuccessResponse(response);
    } catch (error) {
      handleProfileErrors(error);
    }

    setLoadingState(false);
  };

  const handleChange = (event) => {
    if (!!event.target.name) {
      resetStore();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 2 }}
        id="AgentProfileForm"
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleChange}
        sx={{ margin: 'auto', p: isMobile && 2 }}
      >
        <Slide direction="down" in={duplicateProfiles.length > 0} timeout={400}>
          <Grid item xs={12}>
            <AgentProfileTable agents={duplicateProfiles} />
          </Grid>
        </Slide>

        <Grid item xs={12}>
          <Alert severity="warning">
            May take up to one minute from the time the profile is created for the data to sync and be visible in the
            profile.
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <PersonalInfo control={control} errors={errors} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default AgentProfileForm;
