import React, { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ModuleSettingsDialog from './ModuleSettingsDialog';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import { moduleTabService as service } from '../../../services';
import { dispatchAlert } from '../../../helpers';

const StyledModuleOptions = styled('div')(
  ({ theme }) => css`
    text-align: right;
  `
);

const ModuleOptions = (props) => {
  const { module, reloadView } = props;
  const optionsRef = useRef(null);
  const [openModuleOptions, setOpenModuleOptions] = useState(false);
  const [openModuleSetting, setOpenModuleSetting] = useState(false);
  const [confirmationHandler, setConfirmationHandler] = useState(false);

  const handleOptionsClose = (event) => {
    setOpenModuleOptions(false);
    optionsRef.current = null;
  };

  const dialogActionResolver = (event) => {
    setOpenModuleSetting(false);
    if (event) reloadView();
  };

  const handleConfirmation = (event, value) => {
    if (value) {
      service.deleteTabModule(JSON.stringify(module)).then(
        (res) => {
          dispatchAlert('Module was deleted successfully.');
          reloadView();
        },
        (error) => {
          dispatchAlert(error + '.', true);
        }
      );
    }
    setConfirmationHandler(false);
  };

  return (
    <StyledModuleOptions>
      <IconButton
        aria-label="delete"
        size="small"
        ref={optionsRef}
        onClick={(e) => {
          setOpenModuleOptions(true);
          optionsRef.current = e.currentTarget;
        }}
      >
        <MoreHorizIcon fontSize="medium" />
      </IconButton>
      {openModuleOptions && (
        <Popper
          sx={{ zIndex: 1 }}
          id={'moduleOptions'}
          open={openModuleOptions}
          anchorEl={optionsRef.current}
          placement={'bottom-end'}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleOptionsClose}>
                  <MenuList dense>
                    <MenuItem
                      onClick={(e) => {
                        setOpenModuleSetting(true);
                        setOpenModuleOptions(false);
                      }}
                    >
                      Module Setting
                    </MenuItem>
                    <MenuItem
                      onClick={(e) =>
                        setConfirmationHandler({
                          event: e,
                          title: 'Confirm Deletion',
                          msg: 'Are you sure you wish to delete this module?',
                        })
                      }
                    >
                      Delete Module
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {openModuleSetting && (
        <ModuleSettingsDialog
          open={openModuleSetting}
          module={module}
          title={`${module.moduleTitle} - Settings`}
          buttonTrueText={'Update'}
          buttonFalseText={'Close'}
          onClose={dialogActionResolver}
        />
      )}
      <AppConfirmationDialog
        open={confirmationHandler !== false ? true : false}
        title={confirmationHandler.title}
        content={confirmationHandler.msg}
        buttonTrueText="Delete"
        color="error"
        onClose={() => {}}
        confirmCallback={(val) =>
          handleConfirmation(confirmationHandler.event, val)
        }
      />
    </StyledModuleOptions>
  );
};

export default ModuleOptions;
