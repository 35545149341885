import React, { useState, useEffect, createContext } from 'react';
import { compareVersions, sortBy } from '../helpers';
import { appReleaseNotes } from '..';

export const NotificationCenterContext = createContext();

const NotificationCenterProvider = (props) => {
  const [onNewRelease, setOnNewRelease] = useState({ whoCanSee: 'ServiceOwner', seen: false, release: null });

  const checkForNewRelease = () => {
    let latestVersion = sortBy(appReleaseNotes, { prop: 'ReleaseVersion', desc: true })[0];
    if (!latestVersion) return setOnNewRelease((prev) => ({ ...prev, seen: false, release: null }));
    else {
      let lastSeenVersion = localStorage.getItem('NS_LastSeen_ReleaseVersion') || '0.0.0';
      let comparedVersion = compareVersions(latestVersion.ReleaseVersion, lastSeenVersion);
      if (comparedVersion == 1) {
        return setOnNewRelease((prev) => ({ ...prev, seen: false, release: latestVersion }));
      } else return setOnNewRelease((prev) => ({ ...prev, seen: true, release: null }));
    }
  };

  useEffect(() => {
    checkForNewRelease();
    return () => {};
  }, []);

  return (
    <NotificationCenterContext.Provider value={{ onNewRelease, setOnNewRelease }}>
      {props.children}
    </NotificationCenterContext.Provider>
  );
};

export default NotificationCenterProvider;
