import { isValid, isThisHour } from 'date-fns';
import moment from 'moment';

export function IsValidJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getJsonOr(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

export function parseStringTime(time) {
  var matches = null;
  if (typeof time === 'string') {
    matches = time.match(
      '^(1[0-2]|0?[1-9])(\\.|:)([0-5]?[0-9])(.s?)([AaPp][Mm])$'
    );
  }

  if (matches && isValid(new Date(time))) {
    return time;
  } else if (matches) {
    let theTime = matches && matches[0];
    const parsedTime = moment(theTime, ['h:mm A']);
    const mergedTime = moment(new Date()).set({
      hours: parsedTime.get('hours'),
      minutes: parsedTime.get('minutes'),
    });
    return mergedTime;
  } else {
    return null;
  }
}

export const sanitizeStringDate = (date) => {
  if (
    date === '1900-01-01T00:00:00' ||
    date === '1/1/1900 12:00:00 AM' ||
    date === '0001-01-01T00:00:00' ||
    date === '1/1/0001 12:00:00 AM'
  ) {
    return null;
  }
  return date;
};

export const isNumeric = (str) => {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
