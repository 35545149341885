import { useCallback } from 'react';
import { types, useModalStore } from '../../context/modal.store';
import { uuid } from '../generateId';

export const useCloseModal = () => {
  const dispatch = useModalStore((s) => s.dispatch);

  const closeModal = useCallback((id) => {
    dispatch({ type: types.closeModal, payload: { id } });
  }, []);

  const closeLatestModal = useCallback(() => {
    dispatch({ type: types.closeLatestModal });
  }, []);

  const resetModals = () => dispatch({ type: types.reset });

  return {
    resetModals,
    closeModal,
    closeLatestModal,
  };
};

export const useModal = (instance) => {
  const dispatch = useModalStore((state) => state.dispatch);

  const showModal = useCallback((options = {}) => {
    const id = uuid();

    dispatch({
      type: types.showModal,
      payload: {
        id,
        instance: { ...instance, ...options, id },
      },
    });
  }, []);

  return {
    showModal,
  };
};

export const useModalAlert = () => {
  const dispatch = useModalStore((s) => s.dispatch);

  const pushAlert = useCallback((alert) => {
    const id = uuid();

    dispatch({
      type: types.pushAlert,
      payload: {
        id,
        alert: { ...alert, id },
      },
    });
  }, []);

  const removeAlert = useCallback((id) => {
    dispatch({ type: types.closeAlert, payload: { id } });
  }, []);

  return {
    pushAlert,
    removeAlert,
  };
};
