import React, { useState } from 'react';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const SearchStyle = styled('div')(
  ({ theme }) => css`
    .Search {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .Search__label,
    .Search__button,
    .Search__input {
      border: none;
      outline: none;
      font-size: 0.9rem;
      background-color: transparent;
      font-weight: 500;
    }

    .Search__label {
      border: 1px solid #f2f2f2;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 75%;
      position: relative;
    }

    .Search__input {
      width: 100%;
      padding: 0.75rem 0rem 0.75rem 1rem;
      color: white;
    }

    .Search__input::placeholder {
      opacity: 0.85;
    }

    .Search__button {
      padding: 0.75rem;
      font-weight: 500;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      color: #fff;
      cursor: pointer;
    }
  `
);

const Search = ({ searchCity }) => {
  const [currentCity, setCurrentCity] = useState('');

  const handleInputChange = (event) => {
    setCurrentCity(event.target.value);
  };

  const handleButtonClick = () => {
    searchCity(currentCity.trim());
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleButtonClick();
  };

  return (
    <SearchStyle>
      <div className="Search">
        <label className="Search__label">
          <input
            className="Search__input"
            value={currentCity}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
        </label>
        <button className="Search__button" onClick={handleButtonClick}>
          Search
        </button>
      </div>
    </SearchStyle>
  );
};
export default Search;
