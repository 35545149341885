import { DataItemMobile } from './DataItemMobile';
import { DataItemTablet } from './DataItemTablet';
import { DataItemDesktopCompact } from './DataItemDesktopCompact';
import { DataItemDesktop } from './DataItemDesktop';
import React from 'react';

export const DataListItem = (props) => {
  const {
    controlSetting,
    contact,
    handleSelectedEmails,
    handleAgentAssignation,
    filterPayLoad,
    fromContactManager,
    profileLookups,
    requestID,
    listMode,
    agentEmailRecipients,
    renderListView,
    setClassNameForAssignedAgent,
    store,
  } = props;

  return (
    <>
      {['xs'].includes(store.appViewport) && (
        <DataItemMobile
          setting={{
            ...controlSetting,
            ddlSelection: filterPayLoad.listSelection,
          }}
          contact={contact}
          emitSelectedEmail={handleSelectedEmails}
        />
      )}
      {['sm', 'xsm', 'md'].includes(store.appViewport) && (
        <DataItemTablet
          setting={{
            ...controlSetting,
            ddlSelection: filterPayLoad.listSelection,
          }}
          contact={contact}
          emitSelectedEmail={handleSelectedEmails}
        />
      )}
      {['lg', 'xl'].includes(store.appViewport) && (
        <>
          {renderListView == 2 ? (
            <DataItemDesktopCompact
              setting={{
                ...controlSetting,
                ddlSelection: filterPayLoad.listSelection,
              }}
              fromContactManager={fromContactManager}
              profileLookups={profileLookups}
              requestID={requestID}
              assignedclassName={setClassNameForAssignedAgent(contact)}
              contact={contact}
              listMode={listMode}
              onProximitySearch={filterPayLoad.onProximity ? true : false}
              agentEmailRecipients={agentEmailRecipients}
              emitSelectedEmail={handleSelectedEmails}
              assignAgent={handleAgentAssignation}
            />
          ) : (
            <DataItemDesktop
              setting={{
                ...controlSetting,
                ddlSelection: filterPayLoad.listSelection,
              }}
              fromContactManager={fromContactManager}
              profileLookups={profileLookups}
              requestID={requestID}
              assignedclassName={setClassNameForAssignedAgent(contact)}
              contact={contact}
              listMode={listMode}
              onProximitySearch={filterPayLoad.onProximity ? true : false}
              agentEmailRecipients={agentEmailRecipients}
              emitSelectedEmail={handleSelectedEmails}
              assignAgent={handleAgentAssignation}
            />
          )}
        </>
      )}
    </>
  );
};

export default DataListItem;
