import React, { useEffect } from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton, Stack, Typography } from '@mui/material';

const StyledStack = styled(Stack)({
  alignItems: 'center',

  '.MuiTypography-root': {
    // fontSize: '0.8rem',
    top: '1px',
    position: 'relative',
    fontWeight: '400',
  },

  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
});

export default function PaginationMobile(props) {
  const { items } = usePagination({
    count: props.count,
    page: props.pageNumber,
    onChange: handleClick,
  });

  function handleClick(event, item) {
    props.onChange(item);
  }

  let calc = props.pageNumber * props.rowsPerPage - props.rowsPerPage + 1;
  let accumulated = props.pageNumber == 1 ? props.pageNumber : calc;

  return (
    <StyledStack direction="row" spacing={1}>
      <Typography variant="subtitle2" gutterBottom={false}>
        {`${accumulated}-${
          props.pageNumber * props.rowsPerPage > props.totalRows
            ? props.totalRows
            : props.pageNumber * props.rowsPerPage
        } of ${props.totalRows}`}
      </Typography>
      <nav>
        <ul>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              // children = '…';
              children = '';
            } else if (type === 'page') {
              children = '';
              // children = (
              //   <button
              //     type="button"
              //     style={{
              //       fontWeight: selected ? 'bold' : undefined,
              //     }}
              //     {...item}
              //   >
              //     {page}
              //   </button>
              // );
            } else {
              children = (
                <IconButton type="button" size="small" {...item}>
                  {type == 'previous' && <NavigateBeforeIcon />}
                  {type == 'next' && <NavigateNextIcon />}
                </IconButton>
              );
            }

            return <li key={index}>{children}</li>;
          })}
        </ul>
      </nav>
    </StyledStack>
  );
}
