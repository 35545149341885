import React, { useState } from 'react';
import { Box, Tab, Tabs, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import AppDialog from '../common/others/AppDialog';
import { dispatchRelativeAlert, isValidEmailOr } from '../../helpers';
import Loading from '../common/others/Loading';
import { userService } from '../../services';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect } from 'react';
import moment from 'moment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '80%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pl: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

//*** Important: tabName must match with object name for POST request. Capital case sensitive ***
const settingTabs = [
  {
    index: 0,
    tabName: 'memberShip',
  },
  {
    index: 1,
    tabName: 'account',
  },
];

const initialDataState = {
  memberShip: null,
  account: null,
};

const UserProfileAdminUpdateDialog = (props) => {
  const { open, state, onClose, buttonTrueText, buttonFalseText } = props;

  const [onSubmiting, setOnSubmiting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [data, setData] = useState(initialDataState);

  useEffect(() => {
    if (tabValue == 0) {
      setData({
        ...data,
        memberShip: {
          isMember: data.memberShip?.isMember || state.memberShip?.isMember || false,
          expiration: data.memberShip?.expiration || state.memberShip?.expiration || null,
          moment_expiration: moment(data.memberShip?.expiration || state.memberShip?.expiration || null),
        },
      });
    } else if (tabValue == 1) {
      setData({
        ...data,
        account: {
          userName: data.account?.userName || state.account?.userName || '',
        },
      });
    }

    return () => {};
  }, [state, tabValue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updateHandler = (e) => {
    e.preventDefault();
    if (!validate()) return;

    let objectNode = settingTabs[tabValue].tabName;
    let _data = { [objectNode]: data[settingTabs[tabValue].tabName] };

    setOnSubmiting(true);
    userService.userProfileAdminUpdate('Agent', state.profileId, _data).then(
      (res) => {
        dispatchRelativeAlert('Profile was updated successfully.');
        setOnSubmiting(false);
      },
      (error) => {
        setOnSubmiting(false);
        dispatchRelativeAlert(error + '.', true);
      }
    );
  };

  const validate = () => {
    let errors = [];
    let isValid = true;

    // 0 = memberShip
    if (tabValue == 0) {
      if (data.memberShip?.isMember && !data.memberShip?.moment_expiration?.isValid()) {
        isValid = false;
        errors.push({
          memberShip: { expiration: 'Member Until is required.' },
        });
      }
    }

    // 1 = Account
    if (tabValue == 1) {
      if (!isValidEmailOr(data.account.userName)) {
        isValid = false;
        errors.push({
          account: { userName: 'Please enter valid email address.' },
        });
      }
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <AppDialog open={open} mainTitle={'Profile Admin Update'} maxWidth="sm">
      <DialogContent dividers>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: 224,
            width: '100%',
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Vertical tabs"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Membership" {...a11yProps(0)} />
            <Tab label="Account" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <FormControl fullWidth>
              <InputLabel id="isMemberLabel">Is Member</InputLabel>
              <Select
                labelId="isMemberLabel"
                id="isMemberSelect"
                value={data.memberShip?.isMember || false}
                label="Is Member"
                onChange={(val) => {
                  setData({
                    ...data,
                    memberShip: {
                      ...data.memberShip,
                      isMember: val.target.value,
                      moment_expiration: null,
                      expiration: null,
                    },
                  });
                }}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                fullWidth
                margin="dense"
                inputVariant="outlined"
                label={'Member Until'}
                clearable={true}
                cleartex="clear"
                slotProps={{
                  // field: {
                  //   clearable: true,
                  //   onClear: () =>
                  //     setData({
                  //       ...data,
                  //       memberShip: {
                  //         ...data.memberShip,
                  //         moment_expiration: null,
                  //         expiration: null,
                  //       },
                  //     }),
                  // },
                  textField: {
                    required: data.memberShip?.isMember,
                    error: data.memberShip?.isMember && !data.memberShip?.moment_expiration?.isValid(),
                    helperText:
                      !data.memberShip?.moment_expiration?.isValid() && data.memberShip?.isMember
                        ? errors.find((i) => i.memberShip)?.memberShip?.expiration
                        : '',
                  },
                }}
                value={data.memberShip?.moment_expiration || null}
                onChange={(val) => {
                  let valid = val?.isValid();
                  setData({
                    ...data,
                    memberShip: {
                      ...data.memberShip,
                      moment_expiration: valid ? val : null,
                      expiration: valid ? val.format('L') : null,
                    },
                  });
                }}
              />
            </LocalizationProvider>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <TextField
              label="Username"
              error={!isValidEmailOr(data.account?.userName)}
              helperText={errors.find((i) => i.account)?.account?.userName}
              value={data.account?.userName || ''}
              onChange={(e) => {
                setData({
                  ...data,
                  account: {
                    ...data.account,
                    userName: e.target.value,
                  },
                });
              }}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={() => onClose()}>
          {buttonFalseText}
        </Button>
        <Button type="submit" disabled={onSubmiting} variant="contained" onClick={updateHandler}>
          {buttonTrueText} {onSubmiting && <Loading lpad forButton />}
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

export default UserProfileAdminUpdateDialog;
