import React, { useContext, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Alert, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Item from './Item';
import { NotaryDeskContext } from '../../context/notary-desk.context';
import { EmptyList } from '../common/others/EmptyList';
import Loading from '../common/others/Loading';
import { useLocation } from 'react-router-dom';
import { AntSwitch } from '../../Style';
import { store } from '../../store';

const RootDiv = styled('div')(
  ({ theme }) => css`
    padding: 2px;

    & .MuiLinearProgress-root {
      top: -14px;
    }

    & .request-columns {
      display: flex;
      flex-direction: row no-wrap;
      justify-content: space-evenly;
      line-height: 60px;
      font-weight: 500;
      font-size: small;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: 0 5px 3px -3px gray;
      position: sticky;
      top: 105px;
    }
    & .request-column {
      display: flex;
    }

    & .signer {
      width: 10%;
    }
    & .docs {
      width: 6%;
    }

    & .comp-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: 500px) {
      & .comp-top {
        display: block;
      }
    }

    & .agentDDL {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    & .request-columns.header {
      background-color: ${theme.palette.nsTableHeaderBG.main};
    }

    & .name-header {
      display: flex;
      flex-direction: row;
      padding-left: 5px;
    }

    & .table-footer {
      display: flex;
      flex-direction: row;
      line-height: 60px;
      background-color: ${theme.palette.nsTableHeaderBG.main};
      width: 100%;
    }

    & .table-footer {
      display: flex;
      flex-direction: column;
      background-color: ${theme.palette.nsTableHeaderBG.main};
      width: 100%;
    }

    & .mobile-table {
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  `
);

const NotaryDeskList = (props) => {
  const { completionReportsList, loading, notaryListDDL, controlSettings, changeNotary, selectedNotary } =
    useContext(NotaryDeskContext);

  let location = useLocation();

  const userRoles = store.getState().reducer.currentUser.userRoles;
  const [activeItemID, setActiveItemID] = useState(null);
  const [awaitingScans, setAwaitingScans] = useState(() => location.state?.hasScanBacks ?? false);
  const [requestList, setRequestList] = useState([]);
  const [emptyListMsg, setEmptyListMsg] = useState('');

  useEffect(() => {
    if (location.state?.requestID) setActiveItemID(location.state?.requestID);
    else setActiveItemID(null);
    setRequestList(completionReportsList);
    return () => {};
  }, [completionReportsList, location.state]);

  useEffect(() => {
    if (awaitingScans) {
      setEmptyListMsg(`You don't have any signings awating Scan Backs.`);
      setRequestList(completionReportsList.filter((item) => item.scanBackRequired));
    } else {
      setRequestList(completionReportsList);
      setEmptyListMsg(`You don't have any open signings.`);
    }
    return () => {};
  }, [awaitingScans, loading]);

  return (
    <RootDiv>
      <div className="comp-top">
        <div>
          <h1 style={{ display: 'flex' }}>
            Notary Desk {loading && <Loading lpad forButton msg="Getting signings..." />}
          </h1>
        </div>
        {controlSettings.pnlAgentDDLVisible && (
          <div className="agentDDL">
            <TextField
              select
              sx={{ minWidth: '200px' }}
              label="Choose a notary"
              value={selectedNotary}
              onChange={changeNotary}
            >
              {notaryListDDL?.map((notary, index) => (
                <MenuItem key={index} value={notary.id}>
                  {notary.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
        {userRoles.includes('NotaryAgent') && controlSettings.enableScanBacks && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">All</Typography>
            <AntSwitch
              checked={awaitingScans}
              onChange={() => setAwaitingScans(!awaitingScans)}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography variant="body2">Awaiting Scan Backs</Typography>
          </Stack>
        )}
      </div>
      {controlSettings?.pnlAgentDDLVisible ? (
        <div>
          <Alert variant="filled" severity="info">
            The following table lists the open signings that have been assigned to you. When the appointment is
            complete, click on the Report tab below to send a completion report to the signing service.
          </Alert>
        </div>
      ) : (
        <Alert variant="filled" severity="info">
          The following table lists the open signings that have been assigned to you. When the appointment is complete,
          click on the 'Report' tab on the signing request below to send a completion report to the signing service.
        </Alert>
      )}
      <br />
      {requestList.length > 0
        ? requestList.map((report, index) => (
            <div
              key={index}
              id={`request${report.requestID}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setActiveItemID(report.requestID);
              }}
            >
              <Item
                report={report}
                selectedNotary={selectedNotary}
                activeItem={activeItemID == report.requestID ? report.requestID : null}
                resetTab={activeItemID == report.requestID ? false : true}
                opacity={activeItemID !== null && activeItemID != report.requestID ? 0.5 : 1}
              />
            </div>
          ))
        : !loading && <EmptyList severity="success" msg={emptyListMsg} />}
    </RootDiv>
  );
};

export default NotaryDeskList;
