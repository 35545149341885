import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTheme } from 'styled-components';
import { deepmerge } from '@mui/utils';

import SigningRequestFormSkeleton from '../components/manage-request/signing-request/SigningRequestFormSkeleton';
import SigningRequestForm from '../components/manage-request/signing-request/SigningRequestForm';
import SigningRequestProvider, { SigningRequestContext } from '../context/signing-request.context';
import { store } from '../store';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';
import LazyFallbackForm from '../components/manage-request/signing-request/LazyFallbackForm';

const inputCustomTheme = {
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
        inputProps: {
          autoComplete: '--off--',
          form: {
            autoComplete: 'off',
          },
        },
      },
      styleOverrides: {
        root: {
          marginBottom: 0,

          '.MuiOutlinedInput-input': {
            height: '15px',
            lineHeight: '15px',
          },
        },
      },
    },
  },
};

const NewSigningRequest = (props) => {
  useDocumentTitle('New Signign Request'); // Set page title
  const mainTheme = useTheme();
  const theme = createTheme(deepmerge(mainTheme, inputCustomTheme));
  const userRoles = store.getState().reducer.currentUser.userRoles;

  return (
    <React.Fragment>
      {userRoles.includes('ServiceOwner') || userRoles.includes('AgentOwner') ? (
        <SigningRequestFormSkeleton />
      ) : (
        <ThemeProvider theme={theme}>
          <SigningRequestProvider>
            <SigningRequestContext.Consumer>
              {(context) => {
                return (
                  <LazyFallbackForm>
                    <SigningRequestForm
                      editOn={true}
                      gettingData={context.gettingData}
                      newSigningRequest={true}
                      showTitle={'New Signing Request'}
                    />
                  </LazyFallbackForm>
                );
              }}
            </SigningRequestContext.Consumer>
          </SigningRequestProvider>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default NewSigningRequest;
