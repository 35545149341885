import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';

import { manageRequestService as service } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    '&.agentDDL': {
      display: 'flex',
      width: 'inherit',

      '& .MuiOutlinedInput-marginDense': {
        paddingTop: props.isWizard ? '' : '1.8px',
        paddingBottom: props.isWizard ? '' : '1.8px',
        top: props.isWizard ? '' : '1.5px',
      },
    },
  }),
}));

export default function AgentDDL(props) {
  const classes = useStyles(props);
  const {
    disabled,
    value = '',
    onChange,
    requestStatus,
    disableClearable,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (!open) return;
      setLoading(true);
      await service.getAgentsforDDL(requestStatus).then(
        (res) => {
          setOptions(res.filter((i) => i.agentReferralID !== -1));
        },
        (error) => console.log(error)
      );
      setLoading(false);
    })();

    return () => {};
  }, [open]);

  const findAgentObj = (val) => {
    let agentObj = options.filter((i) => i.agentReferralID === val)[0];
    return agentObj;
  };

  React.useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id="select-agent"
      className={`${classes.root} agentDDL`}
      disabled={disabled}
      size="small"
      margin="dense"
      disableClearable={disableClearable}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(e) => setOpen(false)}
      value={value}
      onChange={(e, newVal) => onChange(newVal)}
      isOptionEqualToValue={(option, value) =>
        option.agentReferralID === value.agentReferralID
      }
      getOptionLabel={(option) =>
        findAgentObj(option)?.agentReferralName ||
        option.agentReferralName ||
        ''
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Assigned Agent"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
