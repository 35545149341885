import React, { useEffect, useRef, useState } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { DashboardCard } from './style';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Header from './weather/Header';
import WeatherAndForecast from './weather/WeatherAndForecast';
import Loader from './weather/Loader';
import Warning from './weather/Warning';

import { dashboardService } from '../../services/dashboard.service';
import Image from '../../assets/images/weather.jpg';

const WeatherAndForecastStyle = styled('div')(
  ({ theme }) => css`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px !important;
    background-image: url(${Image});

    .wheater-content {
      border-radius: 5px !important;
      background-color: rgba(0, 0, 0, 0.45);

      @media screen and (max-width: 1000px) {
        border-radius: 0px !important;
      }
    }
  `
);

const DashboardWather = (props) => {
  return <></>;

  const mountedRef = useRef(true);
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({});
  const [weatherAndForecastInfo, setWeatherAndForecastInfo] = useState({});
  const [locationInfo, setLocationInfo] = useState({});
  const [contentState, setContentState] = useState('blank');

  const searchCity = (target) => {
    setAddress(target);
  };

  const showWarning = () => {
    setContentState('warning');
    //setTimeout(() => setContentState('blank'), 3000);
  };

  const getAddressOfCoordinates = () => {
    const makeRequest = (position) => {
      setContentState('loading');
      dashboardService
        .getAddressOfCoordinates(
          position.coords.latitude,
          position.coords.longitude
        )
        .then((res) => {
          let component = res.results[0].components;
          let place = component.city || component.county;
          setLocationInfo({
            city: place,
            town: component.town,
            state: component.state_code,
            country: component.country_code,
            county: component.county,
            village: component.village,
          });
        })
        .then(() => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        })
        .catch((error) => {});
    };

    const catchError = (err) => {
      console.log('ERROR(' + err.code + '): ' + err.message);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(makeRequest, catchError);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (address != '') return;
    getAddressOfCoordinates();

    return () => {
      setContentState('');
      setLocationInfo({});
      setCoordinates({});
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, [address]);

  useEffect(() => {
    if (address === '') return;

    setContentState('loading');
    dashboardService
      .getCoordinatesOfAddress(address)
      .then((res) => {
        if (
          res.results.length === 0 ||
          (res.results[0].components.city === undefined &&
            res.results[0].components.town === undefined &&
            res.results[0].components.county === undefined &&
            res.results[0].components.village === undefined &&
            res.results[0].components.state === undefined)
        ) {
          showWarning();
          return;
        }

        setCoordinates(res.results[0].geometry);
        setLocationInfo({
          city: res.results[0].components.city,
          town: res.results[0].components.town,
          state: res.results[0].components.state_code,
          country: res.results[0].components.country_code,
          county: res.results[0].components.county,
          village: res.results[0].components.village,
        });
      })
      .catch((error) => {
        showWarning();
      });

    return () => {
      setContentState('');
      setLocationInfo({});
      setCoordinates({});
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, [address]);

  useEffect(() => {
    if (Object.keys(coordinates).length === 0) return;

    dashboardService
      .getWeatherAndForecast(coordinates)
      .then((res) => {
        setWeatherAndForecastInfo(res);
        setContentState('weatherAndForecast');
      })
      .catch((error) => {
        showWarning();
      });

    return () => {
      setWeatherAndForecastInfo({});
      setContentState('');
      setLocationInfo({});
      setCoordinates({});
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, [coordinates]);

  const Main = {
    blank: () => null,
    loading: () => <Loader />,
    warning: () => <Warning />,
    weatherAndForecast: () => (
      <WeatherAndForecast
        weatherInfo={weatherAndForecastInfo}
        location={locationInfo}
      />
    ),
  };

  return (
    <DashboardCard>
      {props.children} {/* this is for adding ModuleOptions */}
      <CardHeader
        className="card-header"
        title={<div className="title">Five Day Forecast</div>}
      ></CardHeader>
      <CardContent className="car-content-weather">
        <WeatherAndForecastStyle>
          <div className="wheater-content">
            <Header searchCity={searchCity} />
            {contentState && Main[contentState]()}
          </div>
        </WeatherAndForecastStyle>
      </CardContent>
    </DashboardCard>
  );
};

export default DashboardWather;
