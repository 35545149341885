import React, { forwardRef } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
// import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Quill from 'quill/core';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Header from 'quill/formats/header';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Strike from 'quill/formats/strike';
import { FontStyle } from 'quill/formats/font';
import { ColorStyle } from 'quill/formats/color';
import ListItem from 'quill/formats/list';
import { AlignStyle } from 'quill/formats/align';
import { BackgroundStyle } from 'quill/formats/background';
import IndentClass from 'quill/formats/indent';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/header': Header,
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/underline': Underline,
  'formats/strike': Strike,
  'formats/font': FontStyle,
  'formats/color': ColorStyle,
  'formats/background': BackgroundStyle,
  'formats/list': ListItem,
  'formats/indent': IndentClass,
  'formats/align': AlignStyle,
});

const StyledDiv = styled('div')(({ theme }) => css``);

const toolbarOptions = [
  [{ font: [] }, { header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ align: [] }],
  ['clean'],
];

var Block = Quill.import('blots/block');
Block.tagName = 'div';
Block.blotName = 'div';
Quill.register(Block, true);

Quill.register('modules/maxlength', function (quill, options) {
  quill.on('text-change', function (e) {
    let size = quill.getText();
    if (size.length > options.value) quill.history.undo();
  });
});

// QuillEditor is an uncontrolled React component
const QuillEditor = forwardRef(({ readOnly, characterMax, defaultValue, onTextChange, onSelectionChange }, ref) => {
  const containerRef = React.useRef(null);
  const defaultValueRef = React.useRef(defaultValue.replaceAll('<div></div>', ''));
  const onTextChangeRef = React.useRef(onTextChange);
  const onSelectionChangeRef = React.useRef(onSelectionChange);

  React.useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    onSelectionChangeRef.current = onSelectionChange;
  });

  React.useEffect(() => {
    ref.current?.enable(!readOnly);
  }, [ref, readOnly]);

  React.useEffect(() => {
    const container = containerRef.current;
    let divElToAppend = container.ownerDocument.createElement('div');
    const editorContainer = container.appendChild(divElToAppend);

    const quill = new Quill(editorContainer, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
        // clipboard: {
        //   matchVisual: false,
        // },
        maxlength: { value: characterMax },
        history: { delay: 100, userOnly: true },
      },
    });

    ref.current = quill;

    if (defaultValueRef.current) {
      // quill.root.innerHTML = defaultValueRef.current;
      quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current, 'api');
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      onTextChangeRef.current?.(...args);
    });

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      onSelectionChangeRef.current?.(...args);
    });

    return () => {
      ref.current = null;
      container.innerHTML = '';
    };
  }, [ref]);

  return <StyledDiv ref={containerRef}></StyledDiv>;
});

QuillEditor.displayName = 'Editor';

export default QuillEditor;
