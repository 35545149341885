// ==============================|| THIRD PARTY IMPORTS ||============================== //
import React, { Fragment, useEffect, useState, useContext, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  InputAdornment,
  Alert,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Card,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import Dropzone from 'react-dropzone';
import { deviceType, isMobile } from 'react-device-detect';
import { grey } from '@mui/material/colors';

// ==============================|| APP IMPORTS ||============================== //
import AppDialog from '../others/AppDialog';
import AppConfirmationDialog from '../others/AppConfirmationDialog';
import { UploadFilesDialogSaved } from './upload-files-dialog-saved';
import { UploadFilesDialogSelected } from './upload-files-dialog-selected';
import { UploadFilesDialogExternal } from './upload-files-dialog-external';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Loading from '../others/Loading';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { DialogContentCss, StyledDropzoneContainer, UploadFilesCss } from './style';
import { manageRequestService as mgrService, UploadFilesService as service } from '../../../services';
import { dispatchRelativeAlert } from '../../../helpers';
import { ErrorBoundary } from '../../../ErrorBoundery';
import { FileUploadContext } from './FileUploadContext';
import { store } from '../../../store';
import NSDragDropIcon from '../../../assets/images/drag-and-drop.png';
import { useSelector } from 'react-redux';
import { appColors } from '../../../theme/AppThemes';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme, $priColor }) => `
    max-width: 100%;
    min-width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 5px 5px 0 5px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1.5px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${grey[700]};
    }

    &:focus {
      border-color: ${appColors[$priColor].palette.primary.main};
      box-shadow: 0 0 0 1px ${appColors[$priColor].palette.primary.main}
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: isMobile ? 0 : 1, bgcolor: isMobile ? '' : '' }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UploadFilesDialog = (props) => {
  const { onClose, open, title, buttonTrueText, buttonFalseText, documentData } = props;

  const {
    uploadSingleRequest,
    setStartUploadFromChild,
    isSubmitting,
    setIsSubmitting,
    state,
    setState,
    getFiles,
    counterDispatch,
    secondaryButtonText,
    setSecondaryButtonText,
    fileContext,
    canEdit,
    isGettingFiles,
    setFilesForLogging,
    showNavigateAwayAlert,
    setShowNavigateAwayAlert,
  } = useContext(FileUploadContext);

  const { innerWidth: width, innerHeight: height } = window;
  const { customColor: appColor } = useSelector((state) => state.reducer.themeAppearance);

  const [requestController, setRequestController] = useState();
  const [mainTitle, setMainTitle] = useState(title);
  const [complementaryData, setComplementaryData] = useState(documentData);
  const [showAlertNote, setShowAlertNote] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [dragStyle, setDragStyle] = useState('');
  const [sameFiles, setSameFiles] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({
    msg: '',
    open: false,
  });
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState({
    open: false,
  });

  let isValidDocuments = true;
  const { externalFiles, savedFiles, selectedFiles, notes, settings } = state;
  const userRoles = store.getState().reducer.currentUser.userRoles;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('0', '375'));

  useEffect(() => {
    configureScreen();
    return () => {};
  }, [width]);

  useEffect(() => {
    configureScreen();
    getFiles();
    return () => {};
  }, []);

  useEffect(() => {
    counterDispatch({
      type: 'setUploadTypeTotal',
      payload: {
        newFileUploadTotal: state.selectedFiles.length,
        externalUploadTotal: state.externalFiles.length,
      },
    });
    return () => {};
  }, [state]);

  const configureScreen = () => {
    setSecondaryButtonText(buttonFalseText);
    if (width <= 600) {
      setMainTitle(documentData.title);
      setComplementaryData(null);
    }
  };

  const saveNotes = () => {
    mgrService.saveFileUploadNotes(fileContext.requestID, notes).then(
      (res) => {
        dispatchRelativeAlert('Notes was updated successfully.');
      },
      (error) => {
        dispatchRelativeAlert(error, true);
      }
    );
  };

  const handleConfirmation = (value) => {
    if (value) if (isValidDocuments) setOpenSaveConfirmation({ open: true });
    setOpenConfirmation({ msg: '', open: false });
  };

  const handleSaveConfirmation = async (value) => {
    setIsSubmitting(true);
    if (value && isValidDocuments && !uploadSingleRequest) {
      setShowNavigateAwayAlert(true);
      counterDispatch({ type: 'setProcessState' });
      setStartUploadFromChild(true); // Active child api call

      // externalFiles is only for Signing files.
      if (externalFiles.length > 0) uploadExternalFiles(externalFiles);
    } else setIsSubmitting(false);

    setOpenSaveConfirmation({ open: false });
  };

  const validateSelectedFilesForUpload = () => {
    sameFiles.splice(0, sameFiles.length);
    setOpenConfirmation((values) => ({
      msg: '',
      open: false,
    }));

    for (var i = 0; i < selectedFiles.length; i++) {
      if (fileContext.uploadDocType === 'SigningFiles') {
        if (!selectedFiles[i].isLoan && !selectedFiles[i].isTitle && !selectedFiles[i].isOthers) {
          isValidDocuments = false;
          dispatchRelativeAlert('New Files Docs Types are required.', true);
          return;
        }
      }
    }

    for (var i = 0; i < selectedFiles.length; i++) {
      for (var j = 0; j < savedFiles.length; j++) {
        if (selectedFiles[i].name == savedFiles[j].displayName) {
          sameFiles.push(selectedFiles[i].name);
          setSameFiles(sameFiles);
        }
      }
    }

    if (sameFiles.length > 0) {
      isValidDocuments = false;
      setOpenConfirmation((values) => ({
        msg: 'A document(s) with the same name already exists. Would you like to replace them?',
        open: true,
      }));
    }
  };

  const uploadExternalFiles = async (externals) => {
    if (requestController) requestController.abort();
    let newController = new AbortController();
    let signal = newController.signal;

    let _files = {
      uploadedFilesMeta: [],
      externalFiles: externals,
      uploadedFiles: [],
    };

    let externalsCopy = _.cloneDeep(externals) || [];
    let _names = externalsCopy.map((i) => (i.name = `[External]${i.name}`));

    service.uploadFiles(signal, fileContext, _files).then(
      (res) => {
        setFilesForLogging((current) => current.concat(_names));
        counterDispatch({
          type: 'setExternalCounter',
          payload: { externalUploadCounter: externals.length },
        });
      },
      (error) => {
        counterDispatch({
          type: 'setExternalErrorCounter',
          payload: { externalUploadErrorCounter: externals.length },
        });
      }
    );
  };

  const deleteSelectedFile = (file) => {
    const newselectedFiles = selectedFiles.filter(function (value, index, arr) {
      return value.docId !== file.docId;
    });

    setState((values) => ({
      ...values,
      selectedFiles: newselectedFiles,
    }));
  };

  const onDragLeave = () => {
    setDragStyle('');
  };

  const onDragOver = () => {
    setDragStyle('#949794');
  };

  const getValidExtensionsAsString = (string) => {
    // Define a regex to match and capture the groups inside the original regex pattern
    const groupExtractor = /\(([^)]+)\)/g;

    // Extract the groups
    let match;
    const groups = [];
    while ((match = groupExtractor.exec(string)) !== null) {
      groups.push(match[1]);
    }

    return groups[1];
  };

  const validateOnDrop = (files) => {
    for (var i = 0; i < files.length; i++) {
      if (files[i].name.length > 40 && fileContext.uploadDocType == 'CustInvoiceTemplate') {
        dispatchRelativeAlert(`${files[i].name} name must have a max of 40 characters.`, true);
        return;
      }

      const regexName = new RegExp(state.settings.regexCharacters, 'i');
      if (!regexName.test(files[i].name)) {
        dispatchRelativeAlert(
          `${files[i].name} document contains an invalid special character. Allowed special characters include "period, underscore, dash and space"`,
          true
        );
        return;
      }

      const regexExtension = new RegExp(state.settings.regexExtensions, 'i');
      if (!regexExtension.test(files[i].name)) {
        dispatchRelativeAlert(
          `${
            files[i].name
          } document contains an invalid extension. Allowed extensions include "${getValidExtensionsAsString(
            state.settings.regexExtensions
          )}"`,
          true
        );
        return;
      }

      if (documentData.maxSize && files[i].size > documentData.maxSize) {
        dispatchRelativeAlert(`File size exceeds the permitted size limit.`, true);
        return;
      }
    }

    onDrop(files);
  };

  const onDrop = (files) => {
    setDragStyle('');

    for (var i = 0; i < files.length; i++) {
      let doc = files[i];
      selectedFiles.push({
        docId: new Date().getTime() + i,
        name: doc.name,
        path: doc.path,
        size: doc.size,
        lastModified: doc.lastModified,
        description: '',
        isLoan: false,
        isTitle: false,
        isOthers: false,
        file: doc,
      });
    }

    setState((values) => ({
      ...values,
      selectedFiles: selectedFiles,
    }));
  };

  const handleCancel = () => {
    setShowNavigateAwayAlert(false);
    onClose(state.savedFiles);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const addExternalRow = () => {
    const newDociD = new Date().getTime();
    const externalFile = {
      docId: newDociD,
      name: '',
      description: '',
      path: '',
      isLoan: false,
      isTitle: false,
      isOthers: false,
      isExternalPath: true,
      lastModified: new Date(),
      size: 0,
    };
    const newExternalFiles = [...externalFiles, externalFile];
    setState((values) => ({
      ...values,
      externalFiles: newExternalFiles,
    }));
  };

  const deleteExternalFile = (file) => {
    const newExternalFiles = externalFiles.filter(function (value, index, arr) {
      return value.docId !== file.docId;
    });

    setState((values) => ({
      ...values,
      externalFiles: newExternalFiles,
    }));
  };

  const editExternalName = (index, value) => {
    externalFiles[index] = value;
    setState((values) => ({
      ...values,
      externalFiles: externalFiles,
    }));
  };

  const editSelectedFile = (index, value) => {
    selectedFiles[index] = value;
    setState((values) => ({
      ...values,
      selectedFiles: selectedFiles,
    }));
  };

  const validateExternalFilesForUpload = () => {
    for (var i = 0; i < externalFiles.length; i++) {
      if (fileContext.uploadDocType == 'SigningFiles') {
        if (!externalFiles[i].isLoan && !externalFiles[i].isTitle && !externalFiles[i].isOthers) {
          isValidDocuments = false;
          dispatchRelativeAlert('Docs Types are required.', true);
          return;
        }
      }

      if (externalFiles[i].name == '') {
        isValidDocuments = false;
        dispatchRelativeAlert('Name field is required.', true);
      }
      if (externalFiles[i].path == '') {
        isValidDocuments = false;
        dispatchRelativeAlert('Url field is required.', true);
      } else if (!externalFiles[i].path.startsWith('https:')) {
        isValidDocuments = false;
        dispatchRelativeAlert('Url field should start with https.', true);
      }
    }
  };

  const UploadAllDocuments = () => {
    setTabValue(0);
    isValidDocuments = true;
    validateSelectedFilesForUpload();
    validateExternalFilesForUpload();
    if (isValidDocuments) setOpenSaveConfirmation({ open: true });
    else setIsSubmitting(false);
  };

  const getNotesRowCount = () => {
    const lineBreaks = notes?.match(/\n/g);
    if (lineBreaks?.length < 5) {
      if (notes?.length < 300) return 3;
      else if (notes?.length > 300 && notes?.length < 500) return 4;
      else if (notes?.length > 500 && notes?.length < 700) return 5;
      else return 6;
    } else if (lineBreaks?.length > 5) {
      return 5;
    }
    return 2;
  };

  const dropzoneRef = createRef();

  const dropzoneDisableHandler = () => {
    return (fileContext.uploadDocType !== 'SigningFiles' && selectedFiles.length == 1) || isMobile;
  };

  return (
    <AppDialog open={open} mainTitle={mainTitle} complementaryData={complementaryData}>
      <ErrorBoundary>
        {openConfirmation.open && (
          <AppConfirmationDialog
            id="appConfirmationDialog"
            open={openConfirmation.open}
            title={'Confirmation'}
            content={openConfirmation.msg}
            onClose={() => {}}
            confirmCallback={(val) => handleConfirmation(val)}
            contentHtml={
              <Fragment>
                <div>{openConfirmation.msg}</div>
                {sameFiles && (
                  <ul style={{ marginTop: '10px' }}>
                    {sameFiles &&
                      sameFiles.map((data, index) => (
                        <li key={index} style={{ fontWeight: '600' }}>
                          {data}
                        </li>
                      ))}
                  </ul>
                )}
              </Fragment>
            }
          />
        )}
        {openSaveConfirmation.open && (
          <AppConfirmationDialog
            id="appSaveConfirmationDialog"
            open={openSaveConfirmation.open !== false ? true : false}
            title={fileContext.uploadDocType !== 'SigningFiles' ? 'Save uploaded document' : 'Save uploaded documents'}
            contentHtml={
              <div>
                {fileContext.uploadDocType !== 'SigningFiles' && savedFiles.length > 0 ? (
                  <div style={{ marginBottom: '10px' }}>
                    The existing file will be replaced. Do you want to continue?{' '}
                  </div>
                ) : (
                  ''
                )}
                {selectedFiles.length > 0 && (
                  <p style={{ paddingBottom: '10px' }}>
                    <UploadFileOutlinedIcon style={{ margin: '0px 5px -5px 0px' }} />
                    <span>
                      <span style={{ fontWeight: '600' }}>{selectedFiles.length}</span> new{' '}
                      {selectedFiles.length > 1 ? 'files' : 'file'} to save.
                    </span>
                  </p>
                )}
                {externalFiles.length > 0 && (
                  <p style={{ paddingBottom: '10px' }}>
                    <UploadFileOutlinedIcon style={{ margin: '0px 5px -5px 0px' }} />
                    <span>
                      <span style={{ fontWeight: '600' }}>{externalFiles.length}</span> external{' '}
                      {externalFiles.length > 1 ? 'files' : 'file'} to save.
                    </span>
                  </p>
                )}
              </div>
            }
            onClose={() => {}}
            confirmCallback={(val) => handleSaveConfirmation(val)}
          />
        )}
        <DialogContentCss dividers>
          <UploadFilesCss $noGragDrop={['mobile', 'tablet'].includes(deviceType)}>
            {fileContext.uploadDocType == 'SigningFiles' && (
              <div className="notes-instructions">
                <div>
                  <Textarea
                    aria-label="minimum height"
                    $priColor={appColor}
                    minRows={2}
                    maxRows={getNotesRowCount()}
                    placeholder="Notes and Instructions for Upload Files"
                    disabled={!canEdit || (userRoles.includes('NotaryAgent') && !state.settings.showNotePanel)}
                    value={notes || ''}
                    onChange={(e) => {
                      const notes = e.target.value;
                      setState((values) => ({
                        ...values,
                        notes: notes,
                      }));
                    }}
                  />
                </div>
                <div>
                  <Button className="upload-button" disabled={!canEdit} onClick={() => saveNotes()}>
                    {'Update'}
                  </Button>
                </div>
              </div>
            )}
            <UploadFilesDialogSaved savedFiles={savedFiles} />
            {documentData.alertMsg && showAlertNote && !isGettingFiles && (
              <Alert sx={{ mt: '10px' }} severity="warning" onClose={() => setShowAlertNote(false)}>
                {documentData.alertMsg}
              </Alert>
            )}
            <br />
            {showNavigateAwayAlert && (
              <Alert sx={{ mb: 1 }} severity="warning" variant="filled" onClose={() => setShowNavigateAwayAlert(false)}>
                <Typography variant="subtitle2">
                  Do not refresh your browser or close this window while your documents are being uploaded
                </Typography>
              </Alert>
            )}
            {canEdit && state.settings.showUploadPanel && (
              <Card variant={!isMobile ? 'outlined' : ''}>
                <Dropzone
                  ref={dropzoneRef}
                  // disabled={dropzoneDisableHandler()}
                  noClick
                  multiple={fileContext.uploadDocType == 'SigningFiles'}
                  onDrop={validateOnDrop}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                >
                  {({ getRootProps, getInputProps, open, isFocused, isDragReject, isDragAccept }) => {
                    return (
                      <Fragment>
                        <Box
                          className="tab-container"
                          sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                          }}
                        >
                          <Tabs
                            sx={{ paddingTop: '5px' }}
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              label={
                                <div className="new-files-tab">
                                  <img src={NSDragDropIcon} alt="Drag & drop" />
                                  <span>Upload New Files</span>
                                </div>
                              }
                              {...a11yProps(0)}
                            />
                            {fileContext.uploadDocType == 'SigningFiles' && (
                              <Tab label="External Documents" {...a11yProps(1)} />
                            )}
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                          <StyledDropzoneContainer
                            style={{ backgroundColor: dragStyle }}
                            {...getRootProps({
                              className: `dropzone ${dropzoneDisableHandler() ? 'no-border' : ''}`,
                              isFocused,
                              isDragAccept,
                              isDragReject,
                            })}
                          >
                            <input {...getInputProps()} />
                            <UploadFilesDialogSelected
                              selectedFiles={selectedFiles}
                              editSelectedFile={editSelectedFile}
                              deleteSelectedFile={deleteSelectedFile}
                              requestID={documentData.requestID}
                            />
                            {['mobile', 'tablet'].includes(deviceType) ? (
                              <Button
                                className={`btn-browse ${deviceType}`}
                                type="submit"
                                startIcon={<AddIcon />}
                                color="default"
                                onClick={open}
                              >
                                browse files
                              </Button>
                            ) : (
                              <>
                                {selectedFiles.length == 0 && (
                                  <div className="no-files">
                                    <CloudUploadOutlinedIcon />
                                    <p>Drag files to upload</p>
                                    <Divider>or</Divider>
                                    <Button
                                      className="btn-browse"
                                      color="default"
                                      onClick={() => dropzoneRef.current?.open()}
                                    >
                                      browse files
                                    </Button>
                                    <div>
                                      {parseInt(documentData?.maxSize || 0) > 1048576
                                        ? `Max ${documentData?.maxSize / 1048576}mb per file`
                                        : 'Max 60mb per file'}
                                    </div>
                                  </div>
                                )}
                                {selectedFiles.length > 0 && fileContext.uploadDocType == 'SigningFiles' && (
                                  <Button
                                    className="btn-browse with-files"
                                    startIcon={<AddIcon />}
                                    color="default"
                                    onClick={() => dropzoneRef.current?.open()}
                                  >
                                    browse files
                                  </Button>
                                )}
                              </>
                            )}
                          </StyledDropzoneContainer>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                          <UploadFilesDialogExternal
                            externalFiles={externalFiles}
                            addExternalRow={addExternalRow}
                            deleteExternalFile={deleteExternalFile}
                            editExternalName={editExternalName}
                          />
                        </CustomTabPanel>
                      </Fragment>
                    );
                  }}
                </Dropzone>
              </Card>
            )}
          </UploadFilesCss>
        </DialogContentCss>
        <DialogActions>
          {fileContext.uploadDocType == 'SigningFiles' && state.settings.showUploadNotification && (
            <FormControlLabel
              sx={{
                position: 'absolute',
                left: '0px',
              }}
              label={
                <Typography variant="body2">
                  {isSmallScreen ? 'Email Notification' : 'Send Doc Upload Notification'}
                </Typography>
              }
              labelPlacement="start"
              control={
                <Checkbox
                  disabled={userRoles.includes('Customer') || userRoles.includes('NotaryAgent')}
                  checked={state.settings.overrideSendDocsNotice || false}
                  style={{ marginRight: '20px' }}
                  onChange={(e) =>
                    setState((values) => ({
                      ...values,
                      settings: {
                        ...values.settings,
                        overrideSendDocsNotice: e.target.checked,
                      },
                    }))
                  }
                />
              }
            />
          )}
          <Button autoFocus color="default" onClick={handleCancel} disabled={isSubmitting}>
            {secondaryButtonText}
          </Button>
          <Button
            variant="contained"
            className="upload-button"
            disabled={(selectedFiles.length <= 0 && externalFiles.length <= 0) || !canEdit || isSubmitting}
            onClick={UploadAllDocuments}
          >
            {buttonTrueText} {isSubmitting && <Loading forButton lpad />}
          </Button>
        </DialogActions>
      </ErrorBoundary>
    </AppDialog>
  );
};

export default UploadFilesDialog;
