import React from 'react';
import { useEffect } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Card, Divider } from '@mui/material';
import { store } from '../store';

const StyledRoot = styled('div')(
  ({ theme, $withView, $withMarginTop }) => css`
    margin: ${$withView ? 'auto' : `${$withMarginTop ? '10px' : '0px'} auto`};

    ${theme.breakpoints.down('xl')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('xl')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('1800')} {
      width: ${$withView ? '100%' : '1400px'};
    }

    padding: 10px;

    .titleh3 {
      font-size: 17px;
      margin-bottom: 5px;
    }

    .parrafo {
      line-height: 1.8;
      word-spacing: 1.5;
      opacity: 0.8;
    }

    ul {
      margin-top: 20px;
      margin-left: 40px;
      list-style: initial;

      li {
        color: #858585;
        line-height: 110%;
        margin-bottom: 10px;
      }
    }
  `
);

const Privacy = () => {
  const { global } = store.getState().reducer;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return () => {};
  }, []);

  return (
    <StyledRoot id="privacy">
      <h1>Privacy Statement</h1>
      <Divider />
      <br />
      <Card sx={{ p: 2 }}>
        <p className="parrafo">
          {global.portalName} is committed to protecting your privacy and developing technology that gives you the most
          powerful and safe online experience. This Statement of Privacy applies to the {global.portalName} Web site and
          governs data collection and usage. By using the {global.portalName} website, you consent to the data practices
          described in this statement.
        </p>
        <br />
        <h3 className="titleh3">Collection of your Personal Information</h3>
        <p className="parrafo">
          {global.portalName} collects personally identifiable information, such as your e-mail address, name, home or
          work address or telephone number. {global.portalName} also collects anonymous demographic information, which
          is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.
        </p>
        <br />
        <p className="parrafo">
          There is also information about your computer hardware and software that is automatically collected by{' '}
          {global.portalName}. This information can include: your IP address, browser type, domain names, access times
          and referring Web site addresses. This information is used by {global.portalName} for the operation of the
          service, to maintain quality of the service, and to provide general statistics regarding use of the{' '}
          {global.portalName} Web site.
        </p>
        <br />
        <p className="parrafo">
          Please keep in mind that if you directly disclose personally identifiable information or personally sensitive
          data through {global.portalName} public message boards, this information may be collected and used by others.
          Note: {global.portalName} does not read any of your private online communications.
        </p>
        <br />
        <p className="parrafo">
          {global.portalName} encourages you to review the privacy statements of Web sites you choose to link to from{' '}
          {global.portalName} so that you can understand how those Web sites collect, use and share your information.
          {global.portalName} is not responsible for the privacy statements or other content on Web sites outside of the{' '}
          {global.portalName} and {global.portalName} family of Web sites.
        </p>
        <br />
        <h3 className="titleh3">Use of your Personal Information</h3>
        <p className="parrafo">
          {global.portalName} collects and uses your personal information to operate the {global.portalName} Web site
          and deliver the services you have requested. {global.portalName} also uses your personally identifiable
          information to inform you of other products or services available from {global.portalName} and its affiliates.{' '}
          {global.portalName} may also contact you via surveys to conduct research about your opinion of current
          services or of potential new services that may be offered.
        </p>
        <br />
        <p className="parrafo">
          {global.portalName} does not sell, rent or lease its customer lists to third parties. {global.portalName} may,
          from time to time, contact you on behalf of external business partners about a particular offering that may be
          of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address,
          telephone number) is not transferred to the third party. In addition, {global.portalName} may share data with
          trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are prohibited from using your personal information
          except to provide these services to {global.portalName}, and they are required to maintain the confidentiality
          of your information.
        </p>
        <br />
        <p className="parrafo">
          {global.portalName} does not use or disclose sensitive personal information, such as race, religion, or
          political affiliations, without your explicit consent.
        </p>
        <br />
        <p className="parrafo">
          {global.portalName} keeps track of the Web sites and pages our customers visit within {global.portalName}, in
          order to determine what {global.portalName} services are the most popular. This data is used to deliver
          customized content and advertising within {global.portalName} to customers whose behavior indicates that they
          are interested in a particular subject area.
        </p>
        <br />
        <p className="parrafo">
          {global.portalName} Web sites will disclose your personal information, without notice, only if required to do
          so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law
          or comply with legal process served on {global.portalName} or the site; (b) protect and defend the rights or
          property of {global.portalName}; and, (c) act under exigent circumstances to protect the personal safety of
          users of {global.portalName}, or the public.
        </p>
        <br />
        <h3 className="titleh3">Use of Cookies</h3>
        <p className="parrafo">
          The {global.portalName} Web site use "cookies" to help you personalize your online experience. A cookie is a
          text file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or
          deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server
          in the domain that issued the cookie to you.
        </p>
        <br />
        <p className="parrafo">
          One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
          cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize{' '}
          {global.portalName} pages, or register with {global.portalName} site or services, a cookie helps{' '}
          {global.portalName} to recall your specific information on subsequent visits. This simplifies the process of
          recording your personal information, such as billing addresses, shipping addresses, and so on. When you return
          to the same {global.portalName} Web site, the information you previously provided can be retrieved, so you can
          easily use the {global.portalName} features that you customized.
        </p>
        <br />
        <p className="parrafo">
          You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can
          usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you
          may not be able to fully experience the interactive features of the {global.portalName} services or Web sites
          you visit.
        </p>
        <br />
        <h3 className="titleh3">Security of your Personal Information</h3>
        <p className="parrafo">
          {global.portalName} secures your personal information from unauthorized access, use or disclosure.{' '}
          {global.portalName} secures the personally identifiable information you provide on computer servers in a
          controlled, secure environment, protected from unauthorized access, use or disclosure. When personal
          information (such as a credit card number) is transmitted to other Web sites, it is protected through the use
          of encryption, such as the Secure Socket Layer (SSL) protocol.
        </p>
        <br />
        <h3 className="titleh3">Changes to this Statement</h3>
        <p className="parrafo">
          {global.portalName} will occasionally update this Statement of Privacy to reflect company and customer
          feedback.
          {global.portalName} encourages you to periodically review this Statement to be informed of how{' '}
          {global.portalName} is protecting your information.
        </p>
        <br />
        <h3 className="titleh3">Contact Information</h3>
        <p className="parrafo">
          {global.portalName} welcomes your comments regarding this Statement of Privacy. If you believe that{' '}
          {global.portalName} has not adhered to this Statement, please contact {global.portalName} at{' '}
          <a href={`mailto:${global.email}`}>{global.email}</a>. We will use commercially reasonable efforts to promptly
          determine and remedy the problem.
        </p>
      </Card>
    </StyledRoot>
  );
};

export default Privacy;
