import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const UserAdminService = {
  getUserAdminMembers: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = '/userAdmin/getUserAdminMembers';
    return Fetch.get(url, params, options).then(handleResponse);
  },

  deleteUserAdminMember: async function (userID) {
    const options = { headers: await authHeader() };
    const params = { userID: userID };
    const url = `/userAdmin/deleteUserAdminMember`;
    return Fetch.remove(url, params, options).then(handleResponse);
  },

  addOrUpadateUserAdminMember: async function (isNewUser, data) {
    const options = { headers: await authHeader(), body: JSON.stringify(data) };
    const params = { isNewUser: isNewUser };
    const url = `/userAdmin/addOrUpdateUserAdminMember`;
    return Fetch.post(url, params, options).then(handleResponse);
  },
};

export { UserAdminService };
