import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const invoiceService = {
  getInvoiceDetails: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/api/customerInvoice`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  saveInvoice: async function (invoice) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(invoice),
    };
    const params = {};
    const url = `/api/customerInvoice`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  deleteInvoice: async function (requestID, invoiceID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID, invoiceID: invoiceID };
    const url = `/api/customerInvoice`;
    return Fetch.remove(url, params, options).then(handleResponse);
  },

  sendInvoiceNoEmail: async function (requestID, invoiceID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID, invoiceID: invoiceID };
    const url = `/api/customerInvoice/invoiceToHistory`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  sendInvoice: async function (invoice) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(invoice),
    };
    const params = {};
    const url = `/api/customerInvoice/sendInvoice`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  getCustomerInvoicePayments: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/api/customerInvoice/GetCustomerInvoicePayments`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  saveCustomerPayments: async function (invoice) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(invoice),
    };
    const params = {};
    const url = `/api/customerInvoice/customerPaymentsSave`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  updateRequestStatus: async function (requestID, newStatus) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify({ requestID: requestID, newStatus: newStatus }),
    };
    const params = {};
    const url = `/api/customerInvoice/UpdateRequestStatus`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  previewInvoicePDF: async function (invoiceID) {
    const options = { headers: await authHeader() };
    const params = { invoiceID };
    const url = `/api/customerInvoice/previewInvoicePDF`;
    return Fetch.get(url, params, options)
      .then(async (response) => {
        const fileName = response?.headers
          ?.get('content-disposition')
          ?.split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace(/^"(.*)"$/, '$1');
        const blob = await response.blob();
        return { fileName, blob };
      })
      .then((res) => {
        let url = window.URL.createObjectURL(res.blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = res.fileName;
        a.click();
      });
  },

  /** Agent Invoice Methods **/
  getAgentInvoiceDetails: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/api/agentInvoice`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  saveAgentfees: async function (invoice) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(invoice),
    };
    const params = {};
    const url = `/api/agentInvoice/notaryFeesSave`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  saveAgentPayments: async function (invoice) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(invoice),
    };
    const params = {};
    const url = `/api/agentInvoice/notaryPaymentsSave`;
    return Fetch.post(url, params, options).then(handleResponse);
  },
};

export { invoiceService };
