import React, { useEffect, useRef, useReducer } from 'react';
import { TextField, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import EmailFormControl from './EmailFormControl';
import RichTextEditor from '../../common/others/RichTextEditor';
import { isValidEmail } from '../../../helpers';
import { AutocompleteWithChip } from '../../../Style';

const textFieldCSS = {
  '.MuiFormLabel-root': {
    color: 'black',
  },
};

const initialState = {
  emailSplited: false,
  inputs: {
    disableFocus: true,
    showToInput: false,
    showCCInput: false,
    showBCCInput: false,
    showCCsBtn: false,
    showCCBtn: false,
    showBCCBtn: false,
  },
  emails: {
    allEmails: [],
    toEmails: [],
    ccEmails: [],
    bccEmails: [],
  },
  errors: {
    toEmails: false,
    ccEmails: false,
    bccEmails: false,
    fromEmail: false,
    subject: false,
    body: false,
  },
};

const removePrefixByGroup = (emails = [], prefix) => {
  let groupArray = emails.filter((element) => element.includes(prefix));
  let updatedGroupArray = groupArray.map((el) => el.replace(prefix, ''));
  return updatedGroupArray;
};

function reducer(state, action) {
  switch (action.type) {
    case 'initialLoad':
      return {
        ...state,
        emails: {
          allEmails: [
            ...action.toEmails.map((i) => 'to:' + i),
            ...action.ccEmails.map((i) => 'cc:' + i),
            ...action.bccEmails.map((i) => 'bcc:' + i),
          ],
          toEmails: action.toEmails,
          ccEmails: action.ccEmails,
          bccEmails: action.bccEmails,
        },
      };
    case 'splitEmails':
      let _splited = action.ref === 'in' ? true : false;
      let ccIsntEmpty = state.emails.ccEmails.length ? true : false;
      let bccIsntEmpty = state.emails.bccEmails.length ? true : false;
      let _showCC = action.ref === 'out' ? false : ccIsntEmpty;
      let _showBCC = action.ref === 'out' ? false : bccIsntEmpty;

      return {
        ...state,
        emailSplited: _splited,
        inputs: {
          disableFocus: true,
          showToInput: true,
          showCCInput: _showCC,
          showBCCInput: _showBCC,
          showCCsBtn: action.ref !== 'out' && !_showCC && !_showBCC,
        },
      };
    case 'toogleCCs':
      return {
        ...state,
        emailSplited: true,
        inputs: {
          ...state.inputs,
          [action.value]: true,
          ...action.value,
          disableFocus: false,
          showCCsBtn: false,
          showCCBtn: action.value === 'showCCInput' ? false : true,
          showBCCBtn: action.value === 'showCCInput' ? true : false,
        },
      };
    case 'updateEmail':
      let _allEmails = {};
      if (action.input === 'allEmails') {
        _allEmails = {
          allEmails: action.value,
          toEmails: removePrefixByGroup(action.value, 'to:'),
          ccEmails: removePrefixByGroup(action.value, 'cc:'),
          bccEmails: removePrefixByGroup(action.value, 'bcc:'),
        };
      } else {
        _allEmails = {
          ...state.emails,
          [action.input]: action.value,
        };
      }

      return {
        ...state,
        emails: {
          ..._allEmails,
          allEmails:
            action.input !== 'allEmails'
              ? [
                  ..._allEmails.toEmails.map((i) => 'to:' + i),
                  ..._allEmails.ccEmails.map((i) => 'cc:' + i),
                  ..._allEmails.bccEmails.map((i) => 'bcc:' + i),
                ]
              : _allEmails.allEmails,
        },
      };
    case 'setErrors':
      let ccs = action.value.ccEmails;
      let bccs = action.value.bccEmails;
      let newState = {
        emailSplited: ccs || bccs ? true : false,
        inputs: {
          ...state.inputs,
          showCCInput: ccs ? true : false,
          showBCCInput: bccs ? true : false,
        },
      };
      return {
        ...state,
        emailSplited: newState.emailSplited,
        inputs: { ...newState.inputs },
        errors: { ...action.value },
      };
    default:
      '';
  }
  // return state;
}

function EmailSection(props) {
  const mountedRef = useRef(true);
  const recipientsDivRef = useRef();
  const {
    toolbarId,
    editorHeight,
    dataSection,
    validations,
    setDataSection,
    showSingleToInput,
    showFrom,
    showReplyTo,
  } = props;

  const errors = useRef(validations);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (mountedRef.current) {
      dispatch({ type: 'initialLoad', ...dataSection });
      if (showSingleToInput) dispatch({ type: 'splitEmails', ref: 'in' });
    }
    return () => {
      mountedRef.current = false;
    };
  }, [dataSection]);

  useEffect(() => {
    const activators = ['fromEmail', 'ccEmails', 'bccEmails'];
    const checkIfClickedOutside = (e) => {
      let exclude = activators.includes(e.target.id);
      if (!exclude) {
        if (recipientsDivRef.current?.contains(e.target)) {
          dispatch({ type: 'splitEmails', ref: 'in' });
        } else {
          dispatch({ type: 'splitEmails', ref: 'out' });
        }
      }
    };

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    if (!_.isEqual(errors.current, props.validations)) {
      errors.current = props.validations;
      dispatch({ type: 'setErrors', value: { ...props.validations } });
    }

    return () => {};
  }, [props.validations]);

  const handleKeyDown = (event) => {
    let val = event.target.value;
    if (['Tab', 'Enter', ',', ';'].includes(event.key) && val) {
      if (dataSection.fromEmail) return;
      setDataSection({ ...dataSection, fromEmail: val });
    }
  };

  useEffect(() => {
    //   if (!mountedRef.current) {
    //     handleCallbackUpdate(state);
    //   }
    setTimeout(() => {
      handleCallbackUpdate(state);
    }, 250);
    return () => {};
  }, [state]);

  const handleCallbackUpdate = (event) => {
    setDataSection({
      ...dataSection,
      ...{ ...state.emails, [event.input]: event.value },
    });
  };

  return dataSection ? (
    <div style={{ marginTop: '10px' }}>
      <div ref={recipientsDivRef} className={'recipients'}>
        {!state.emailSplited && !showSingleToInput ? (
          <EmailFormControl
            id={'toEmails'}
            label={showSingleToInput || 'To'}
            disableEdit={showSingleToInput ? true : false}
            error={state.errors}
            inputControl={state.inputs}
            emails={state.emails.allEmails}
            dispatchEvent={(event) => dispatch({ ...event, input: 'allEmails' })}
          />
        ) : (
          <>
            {state.inputs.showToInput && (
              <EmailFormControl
                id={'toEmails'}
                label={showSingleToInput || 'To'}
                disableEdit={showSingleToInput ? true : false}
                error={state.errors}
                inputControl={state.inputs}
                emails={state.emails.toEmails}
                dispatchEvent={(e) => dispatch({ ...e, input: 'toEmails' })}
              />
            )}
            {!showSingleToInput && (
              <>
                {state.inputs.showCCInput && (
                  <EmailFormControl
                    id={'ccEmails'}
                    label={'Cc'}
                    error={state.errors}
                    inputControl={state.inputs}
                    emails={state.emails.ccEmails}
                    dispatchEvent={(e) => dispatch({ ...e, input: 'ccEmails' })}
                  />
                )}
                {state.inputs.showBCCInput && (
                  <EmailFormControl
                    id={'bccEmails'}
                    label={'Bcc'}
                    error={state.errors}
                    inputControl={state.inputs}
                    emails={state.emails.bccEmails}
                    dispatchEvent={(e) => dispatch({ ...e, input: 'bccEmails' })}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      {showFrom && (
        <AutocompleteWithChip
          multiple
          open={false}
          freeSolo
          disableClearable
          clearOnBlur={true}
          forcePopupIcon={false}
          disabled={dataSection.fromEmail ? true : false}
          options={[]}
          value={dataSection.fromEmail ? [dataSection.fromEmail] : []}
          onChange={(e) => {}}
          getOptionLabel={(option) => option}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              return (
                <Chip
                  size="small"
                  label={option}
                  key={index}
                  color={`${isValidEmail(option) ? 'default' : 'error'}`}
                  variant="outlined"
                  deleteIcon={<CloseIcon />}
                  onDelete={() => setDataSection({ ...dataSection, fromEmail: '' })}
                  {...getTagProps}
                />
              );
            })
          }
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown;
            return (
              <TextField
                sx={textFieldCSS}
                {...params}
                disabled={dataSection.fromEmail ? true : false}
                label="From Email"
                error={validations?.fromEmail && !dataSection.fromEmail ? true : false}
                helperText={validations?.fromEmail && !dataSection.fromEmail ? validations?.fromEmail : ''}
              />
            );
          }}
        />
      )}
      {showReplyTo && (
        <TextField
          sx={textFieldCSS}
          id="replyTo"
          label="Reply To"
          defaultValue={dataSection.replyTo}
          InputProps={{
            readOnly: true,
          }}
        />
      )}
      <TextField
        sx={textFieldCSS}
        id="subject"
        autoComplete="-off-"
        label="Subject"
        error={validations?.subject && !dataSection.subject ? true : false}
        helperText={validations?.subject && !dataSection.subject ? validations?.subject : ''}
        value={dataSection.subject}
        onChange={(e) => {
          setDataSection({ ...dataSection, subject: e.target.value });
        }}
      />
      <br />
      <RichTextEditor
        readOnly={false}
        editorHeight={editorHeight}
        value={dataSection.body}
        onChange={(html) => setDataSection({ ...dataSection, body: html })}
      />
    </div>
  ) : (
    ''
  );
}

export default EmailSection;
