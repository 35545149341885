import React from 'react';
import { Autocomplete, Button, Chip, IconButton, LinearProgress, TextField } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import { HtmlTooltip, StyledAccordion } from '../../Style';
import { useTranslation } from 'react-i18next';
import { useBusinessSettings } from '../../context/business-settings.context';
import { isValidEmail, isValidEmailOr } from '../../helpers';

const EmailAddresses = ({ callBack = () => {} }) => {
  const { t, i18n } = useTranslation();
  const panelKey = 'emailAddresses';
  const panelName = 'Email Addresses';
  const {
    dataIsLoading,
    expanded,
    setExpanded,
    currentSetting,
    setCurrentSetting,
    updateBusSettings,
    hasDirtyChanges,
    progressType,
  } = useBusinessSettings();

  const handleNavigationCallback = (e) => {
    if (hasDirtyChanges) {
      callBack({ confirmation: false, accordion: panelKey });
      e.stopPropagation();
    } else {
      if (expanded !== panelKey) {
        callBack({ confirmation: true, accordion: panelKey });
      } else {
        setExpanded(false);
      }
    }
  };

  const renderTooltipInfo = (title) => (
    <HtmlTooltip arrow maxwidth="400px" title={title}>
      <IconButton>
        <InfoOutlinedIcon color="info" fontSize="small" />
      </IconButton>
    </HtmlTooltip>
  );

  const [inputValue, setInputValue] = React.useState({
    signingUploadRecipients: '',
    scanbackUploadRecipients: '',
    agreementRecipients: '',
  });

  const handleKeyDown = (event, prop) => {
    let val = event.target.value;

    if (event.type == 'blur') {
      if (isValidEmail(val)) {
        let _tos = _.cloneDeep(currentSetting[prop]?.to || []);
        _tos = [..._tos, val];
        setInputValue({ ...inputValue, [prop]: '' });
        setCurrentSetting({
          ...currentSetting,
          [prop]: { ...currentSetting[prop], to: _tos },
        });
      } else setInputValue({ ...inputValue, [prop]: '' });
    }
  };

  return (
    <>
      <StyledAccordion
        id={`${panelKey}-Accordion`}
        expanded={expanded === panelKey}
        onChange={(e) => handleNavigationCallback(e)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="billingDefaultsPanel-content"
          id="billingDefaultsPanel-header"
          className={expanded ? 'activePanel' : ''}
        >
          <Typography className="heading">Email Addresses</Typography>
          <Typography className={`secondaryHeading ${expanded === panelKey ? 'activePanel' : ''}`}>
            Email Addresses for Email Notices
          </Typography>
        </AccordionSummary>
        {currentSetting && progressType == panelKey && (
          <>
            <AccordionDetails sx={{ p: 0 }}>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Initial Signing Request Notice - Main</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    Receives a full copy of the initial signing request.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__mainEmail_1')}`}
                    value={currentSetting.mainEmail_1 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, mainEmail_1: e.target.value })}
                  />
                  <Typography sx={{ mb: 1.5 }} variant="body2">
                    The above email is used as the 'Reply to' email for all confirmations and email notices that you
                    send to your agents and customers.
                  </Typography>
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__mainEmail_2')}`}
                    value={currentSetting.mainEmail_2 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, mainEmail_2: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__mainEmail_3')}`}
                    value={currentSetting.mainEmail_3 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, mainEmail_3: e.target.value })}
                  />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">
                    <div style={{ display: 'block' }}>
                      Initial Signing Request Notice - Mobile{' '}
                      {renderTooltipInfo(
                        `Verizon SMS messages are formatted as your 10 digit wireless number followed by @vtext.com and ATT SMS messages are formatted as your 10 digit wireless number followed by @txt.att.net. Check with your cell carrier for the proper format. Messages are limited to 160 characters.`
                      )}
                    </div>
                  </Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    Receives an abbreviated version of the initial signing request.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__mobileEmail_1')}`}
                    value={currentSetting.mobileEmail_1 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, mobileEmail_1: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__mobileEmail_2')}`}
                    value={currentSetting.mobileEmail_2 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, mobileEmail_2: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__mobileEmail_3')}`}
                    value={currentSetting.mobileEmail_3 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, mobileEmail_3: e.target.value })}
                  />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Copy of Invoice Emails</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    These emails are used to copy yourself when sending invoices to your customer.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtInvoiceEmail1')}`}
                    value={currentSetting?.txtInvoiceEmail1 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtInvoiceEmail1: e.target.value })}
                  />
                  <Typography sx={{ mb: 1.5 }} variant="body2">
                    If the above email is not entered, Main Email #1 will be used. Enter 'DoNotUse@NotarySoftware.com'
                    if you wish not to receive copies of these emails.
                  </Typography>
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtInvoiceEmail2')}`}
                    value={currentSetting.txtInvoiceEmail2 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtInvoiceEmail2: e.target.value })}
                  />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Agent Completion Notices</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    These emails are used to receive completion report notices submitted by your notaries.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtAgentCompleteEmail1')}`}
                    value={currentSetting?.txtAgentCompleteEmail1 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtAgentCompleteEmail1: e.target.value })}
                  />
                  <Typography sx={{ mb: 1.5 }} variant="body2">
                    If the above email is not entered, Main Email #1 will be used. Enter 'DoNotUse@NotarySoftware.com'
                    if you wish not to receive copies of these emails.
                  </Typography>
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtAgentCompleteEmail2')}`}
                    value={currentSetting.txtAgentCompleteEmail2 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtAgentCompleteEmail2: e.target.value })}
                  />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Registration Notices</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    These emails are used to receive notices that a customer has registered at your site.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtRegEmail1')}`}
                    value={currentSetting?.txtRegEmail1 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtRegEmail1: e.target.value })}
                  />
                  <Typography sx={{ mb: 1.5 }} variant="body2">
                    If the above email is not entered, Main Email #1 will be used. Enter 'DoNotUse@NotarySoftware.com'
                    if you wish not to receive copies of these emails.
                  </Typography>
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtRegEmail2')}`}
                    value={currentSetting.txtRegEmail2 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtRegEmail2: e.target.value })}
                  />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Copy Of Confirmation</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    These emails are used to send a copy of the Customer and Agent Confirmation emails.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtConfirmEmail_1')}`}
                    value={currentSetting?.txtConfirmEmail_1 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtConfirmEmail_1: e.target.value })}
                  />
                  <Typography sx={{ mb: 1.5 }} variant="body2">
                    If the above email is not entered, Main Email #1 will be used. Enter 'DoNotUse@NotarySoftware.com'
                    if you wish not to receive copies of these emails.
                  </Typography>
                  <TextField
                    fullWidth
                    label={`${t('emailAddressSettings.ns__txtConfirmEmail_2')}`}
                    value={currentSetting.txtConfirmEmail_2 || ''}
                    onChange={(e) => setCurrentSetting({ ...currentSetting, txtConfirmEmail_2: e.target.value })}
                  />
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Document Upload Notices</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    These emails are used to receive notices when a customer or agent uploads files to a signing.
                  </Typography>
                </div>
                <div className="rows no-border">
                  <Typography variant="body2" sx={{ mb: 1.5 }}>
                    This feature can be turned on for a specific signing or can be set in the Website Settings area so
                    all new signings are set for this notification. (Enter a max of 2 Emails per input)
                  </Typography>
                  <Autocomplete
                    id="agreement-email-tags"
                    sx={{ '.MuiAutocomplete-tag': { maxWidth: 'fit-content' } }}
                    multiple
                    limitTags={2}
                    options={[]}
                    freeSolo
                    inputValue={inputValue.signingUploadRecipients}
                    onInputChange={(e, val) => {
                      if (currentSetting.signingUploadRecipients?.to?.length === 2) {
                        e.preventDefault();
                        e.stopPropagation();
                      } else setInputValue({ ...inputValue, signingUploadRecipients: val });
                    }}
                    value={currentSetting?.signingUploadRecipients?.to || []}
                    onChange={(event, newValue) => {
                      if (newValue > 3) newValue.pop();

                      if (!isValidEmailOr(event.target.value)) return;
                      setCurrentSetting({
                        ...currentSetting,
                        signingUploadRecipients: { ...currentSetting.signingUploadRecipients, to: newValue },
                      });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            sx={{ opacity: 'initial !important' }}
                            variant="outlined"
                            deleteIcon={<CloseIcon />}
                            color={`${isValidEmailOr(option) ? 'primary' : 'error'}`}
                            label={option}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => {
                      params.inputProps.onBlur = (event) => handleKeyDown(event, 'signingUploadRecipients');
                      return (
                        <TextField
                          {...params}
                          label="Signing Upload Recipients"
                          placeholder={`${currentSetting.signingUploadRecipients?.to?.length === 2 ? '' : 'Enter Email'}`}
                          error={!isValidEmailOr(params.inputProps.value) ? true : false}
                        />
                      );
                    }}
                  />
                  <Typography variant="body2" sx={{ mb: 1.5 }}>
                    These emails are used to receive notices when an agent uploads scan back files to a signing. (Enter
                    a max of 2 Emails per input)
                  </Typography>
                  <Autocomplete
                    id="agreement-email-tags"
                    sx={{ '.MuiAutocomplete-tag': { maxWidth: 'fit-content' } }}
                    multiple
                    limitTags={2}
                    options={[]}
                    freeSolo
                    inputValue={inputValue.scanbackUploadRecipients}
                    onInputChange={(e, val) => {
                      if (currentSetting.scanbackUploadRecipients?.to?.length === 2) {
                        e.preventDefault();
                        e.stopPropagation();
                      } else setInputValue({ ...inputValue, scanbackUploadRecipients: val });
                    }}
                    value={currentSetting?.scanbackUploadRecipients?.to || []}
                    onChange={(event, newValue) => {
                      if (newValue > 3) newValue.pop();

                      if (!isValidEmailOr(event.target.value)) return;
                      setCurrentSetting({
                        ...currentSetting,
                        scanbackUploadRecipients: { ...currentSetting.scanbackUploadRecipients, to: newValue },
                      });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            sx={{ opacity: 'initial !important' }}
                            variant="outlined"
                            deleteIcon={<CloseIcon />}
                            color={`${isValidEmailOr(option) ? 'primary' : 'error'}`}
                            label={option}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => {
                      params.inputProps.onBlur = (event) => handleKeyDown(event, 'scanbackUploadRecipients');
                      return (
                        <TextField
                          {...params}
                          label="Scanback Upload Recipients"
                          placeholder={`${currentSetting.scanbackUploadRecipients?.to?.length === 2 ? '' : 'Enter Email'}`}
                          error={!isValidEmailOr(params.inputProps.value) ? true : false}
                        />
                      );
                    }}
                  />
                  <Typography sx={{ mt: 1 }} variant="body2">
                    If the above emails are not entered, Main Email #1 will be used.
                  </Typography>
                </div>
              </div>
              <div className="row-wrapper">
                <div className="rows">
                  <Typography variant="subtitle3">Agreements</Typography>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    These emails are used to receive notices for contractual agreements originated from your website.
                    (Enter a max of 2 Emails)
                  </Typography>
                </div>
                <div className="rows no-border">
                  <Autocomplete
                    id="agreement-email-tags"
                    sx={{ '.MuiAutocomplete-tag': { maxWidth: 'fit-content' } }}
                    multiple
                    limitTags={2}
                    options={[]}
                    freeSolo
                    inputValue={inputValue.agreementRecipients}
                    onInputChange={(e, val) => {
                      if (currentSetting.agreementRecipients?.to?.length === 2) {
                        e.preventDefault();
                        e.stopPropagation();
                      } else setInputValue({ ...inputValue, agreementRecipients: val });
                    }}
                    value={currentSetting?.agreementRecipients?.to || []}
                    onChange={(event, newValue) => {
                      if (newValue > 3) newValue.pop();

                      if (!isValidEmailOr(event.target.value)) return;
                      setCurrentSetting({
                        ...currentSetting,
                        agreementRecipients: { ...currentSetting.agreementRecipients, to: newValue },
                      });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            sx={{ opacity: 'initial !important' }}
                            variant="outlined"
                            deleteIcon={<CloseIcon />}
                            color={`${isValidEmailOr(option) ? 'primary' : 'error'}`}
                            label={option}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => {
                      params.inputProps.onBlur = (event) => handleKeyDown(event, 'agreementRecipients');
                      return (
                        <TextField
                          {...params}
                          placeholder={`${currentSetting.agreementRecipients?.to?.length === 2 ? '' : 'Enter Email'}`}
                          error={!isValidEmailOr(params.inputProps.value) ? true : false}
                        />
                      );
                    }}
                  />
                  <Typography sx={{}} variant="body2">
                    If the above email is not entered, Main Email #1 will be used.
                  </Typography>
                  <div></div>
                </div>
              </div>
            </AccordionDetails>
            <div className="action-section">
              <Button
                disabled={dataIsLoading}
                variant="contained"
                onClick={() => updateBusSettings(panelKey, panelName)}
              >
                Update
              </Button>
            </div>
          </>
        )}
      </StyledAccordion>
      {dataIsLoading && progressType == panelKey && <LinearProgress sx={{ top: '-10px' }} color="inherit" />}
    </>
  );
};

export default EmailAddresses;
