import * as React from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

import Alert from '@mui/material/Alert';

const AlertRoot = styled(Alert)(
  ({ theme }) => css`
    &.MuiAlert-root {
      border: 1px solid ${theme.palette.mode == 'dark' ? 'inherit' : '#dddddd'};
    }
  `
);

function EmptyList(props) {
  return (
    <>
      {props.brt && <br />}
      <AlertRoot severity={props.severity || 'info'}>{props.msg}</AlertRoot>
      {props.brb && <br />}
    </>
  );
}

export { EmptyList };
