import { useEffect, useState } from 'react';

const useBreadcrumb = (history) => {
  const [breadCrumb, setBreadCrumb] = useState([]);

  useEffect(() => {
    let removePrevBread = history?.location?.state?.removePrevBread;
    let _breadCrumb = [];

    const calcBreadcrumb = async () => {
      const popBreads = () => {
        if (removePrevBread)
          return breadCrumb.length ? breadCrumb.slice(0, -1) : [];
        else return [...breadCrumb];
      };
      _breadCrumb = await popBreads();
      setBreadCrumb([..._breadCrumb, history?.location?.pathname]);
    };

    calcBreadcrumb();
    return () => setBreadCrumb([]);
  }, [history.location, history?.location?.state?.removePrevBread]);

  const updateBreadcrumb = (newBreadcrumb) => {
    setBreadCrumb(newBreadcrumb);
  };

  return [breadCrumb, updateBreadcrumb];
};

export default useBreadcrumb;
