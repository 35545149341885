// ==============================|| THIRD PARTY IMPORTS ||============================== //
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import { Prompt } from 'react-router';
import Tab from '@mui/material/Tab';
import { Card, Button, Box, LinearProgress, Link, Typography, Alert, Stack, Tabs } from '@mui/material';
import { NotaryDeskContext } from '../../context/notary-desk.context';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { format, parseISO } from 'date-fns';
import moment from 'moment';

// ==============================|| APP IMPORTS ||============================== //
import SigningRequestProvider, { SigningRequestContext } from '../../context/signing-request.context';
import { dispatchRelativeAlert, parseStringTime, validateURL } from '../../helpers';

import ComponentAlertRelative from '../common/others/ComponentAlertRelative';
import UploadFilesDialog from '../common/file-uploader/upload-files-dialog';
import FileUploadProvider, { FileUploadContext } from '../common/file-uploader/FileUploadContext';
import ScanBackUploader from '../common/file-uploader/scan-backs';
import AppAlertDialog from '../common/others/AppAlertDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CompletionInfoDesk from '../manage-request/signing-request/CompletionInfoDesk';
import AppConfirmationDialog from '../common/others/AppConfirmationDialog';
import CompletionInfoAlert from './CompletionInfo';

const locationIcon = require(`../../assets/images/search_map_bg.png`);

const RootCard = styled(Card)(
  ({ theme, removepad }) => css`
    width: 100%;
    line-height: auto;
    margin-bottom: 10px !important;
    border-radius: 5px !important;

    & div[id^='nd-tabpanel-'] > div:not(#alert-relative) {
      padding: ${removepad == 'true' ? '0px' : '15px'};
    }

    & .link-not-allowed {
      cursor: not-allowed;
    }

    & .MuiLinearProgress-root {
      top: 0px !important;
      width: 100%;
      margin-left: -15px;
      position: absolute;
    }

    & .req-info {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    & .info-panel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      font-size: small;
    }

    & .req-info-col {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    & .button-div {
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;
      align-items: center;
      text-align: center;
      gap: 10px;
    }

    & .link2 {
      text-decoration: underline;
      cursor: pointer;
      align-items: right;

      & :hover {
        background-color: transparent;
      }
    }

    & .instructions {
      text-decoration: none;
      text-align: left;
      position: relative;
    }

    & .report {
      position: relative;
    }

    & .desktop-tab {
      @media screen and (max-width: 550px) {
        display: none;
      }
    }

    & .mobile-tab {
      display: block;

      @media screen and (min-width: 550px) {
        display: none;
      }
    }

    & .mobile-hide {
      @media screen and (max-width: 550px) {
        display: none;
      }
    }

    & .completion-info {
      display: flex;
      flex-direction: row;
      gap: 5px;
      width: 100%;
      flex-wrap: wrap;

      & .MuiAlert-message {
        display: flex;
        width: 100%;
      }

      & .mobile-hide {
        @media screen and (max-width: 550px) {
          display: none;
        }
      }
    }

    .MuiTab-root {
      text-transform: none;

      ${theme.breakpoints.down('sm')} {
        min-width: auto;
      }

      @media screen and (max-width: 450px) {
        padding: 10px;
      }
    }
  `
);

function CustomTabPanel(props) {
  const { children, value, index, showAlert, ...other } = props;
  const alertWrapperRef = useRef(null);
  const alerts = useSelector((state) => state.reducer.appAlerts || []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nd-tabpanel-${index}`}
      aria-labelledby={`nd-tab-${index}`}
      {...other}
    >
      <div id="alert-relative" ref={alertWrapperRef}>
        {alerts.length > 0 && value === index && showAlert && <ComponentAlertRelative wrapperRef={alertWrapperRef} />}
      </div>
      {value === index && <div style={{}}>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `nd-tab-${index}`,
    'aria-controls': `nd-tabpanel-${index}`,
  };
}

const Item = ({ activeItem, opacity, resetTab, report, selectedNotary }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getCompletionReportsList } = useContext(NotaryDeskContext);
  const [tabValue, setTabValue] = useState(0);
  const [documentData, setDocumentData] = useState(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openScanbackAlert, setOpenScanbackAlert] = useState(false);
  const blockTabsOnFileUploadRef = React.useRef();
  const [blockTabsOnFileUpload, setBlockTabsOnFileUpload] = React.useState(false);
  const [attemptedTabValue, setAttemptedTabValue] = useState();

  useEffect(() => {
    if (report.scanBackRequired) {
      setTabValue(history.location.state?.defaultPanelTab || 0);
    }
    return () => {};
  }, [history.location.state]);

  const processOpenUploadDialog = (request) => {
    setDocumentData({
      title:
        'Request #' +
        request.requestSeqID +
        ' - ' +
        [request.borrowerFirstName, request.borrowerLastName].join(' ') +
        ' ' +
        format(parseISO(request.signingDate), 'MM/dd/yyyy'),
      requestID: request.requestID,
      requestSeqID: request.requestSeqID,
    });
    setOpenUploadDialog(true);
  };

  const handleSubmit = async (signingRequest, updateFunction) => {
    let response = '';
    let selectedOption = {};
    for (let option of signingRequest.completionOptions) {
      if (option.selected === true) {
        selectedOption = option;
        break;
      }
    }

    if (selectedOption.value === undefined) {
      dispatchRelativeAlert('Please select a completion result.', true);
      return;
    } else if (selectedOption.value === 'Completed, all docs signed!') {
      if (
        signingRequest.completionDate &&
        parseStringTime(signingRequest.completionTime) &&
        signingRequest.completionReturnedDate &&
        signingRequest.completionReturnedVia &&
        signingRequest.completionTrackingNumber
      ) {
        response = await updateFunction();
      } else {
        dispatchRelativeAlert(
          `One or more of the Completion Report Fields is missing information for the selection "${selectedOption.value}"`,
          true
        );
        return;
      }
    } else if (selectedOption.value === 'Completed with exception') {
      if (
        signingRequest.completionDate &&
        parseStringTime(signingRequest.completionTime) &&
        signingRequest.completionReturnedVia &&
        signingRequest.completionTrackingNumber &&
        signingRequest.completionComments &&
        signingRequest.completionReturnedDate
      ) {
        response = await updateFunction();
      } else {
        dispatchRelativeAlert(
          `One or more of the Completion Report Fields is missing information for the selection "${selectedOption.value}"`,
          true
        );
        return;
      }
    } else {
      if (signingRequest.completionDate && signingRequest.completionComments) {
        response = await updateFunction();
      } else {
        dispatchRelativeAlert(
          `One or more of the Completion Report Fields is missing information for the selection "${selectedOption.value}"`,
          true
        );
        return;
      }
    }

    if (response == 'reload') {
      getCompletionReportsList(selectedNotary);
      if (history.location.state?.requestID == report.requestID) {
        history.replace({ ...history.location, state: null });
      }
    }
    return;
  };

  const handleTabChange = (event, newTabValue) => {
    dispatch({ type: 'CLEAR_ALERTS' });

    if (blockTabsOnFileUploadRef.current === 3) setBlockTabsOnFileUpload(true);
    else setTabValue(newTabValue);

    let eventTargetId = event.target.id.replace('nd-tab-', '');
    if (['0', '1', '2'].includes(eventTargetId)) {
      blockTabsOnFileUploadRef.current = eventTargetId;
      setAttemptedTabValue(eventTargetId);
    }
  };

  const getHudDocsFlag = (hudDocsFlag) => {
    return hudDocsFlag === '000' ? 'No docs' : 'Docs are in'; //report.hudDocsFlag;
  };

  useEffect(() => {
    if (resetTab) {
      dispatch({ type: 'CLEAR_ALERTS' });
      setTabValue(0);
    }
    return () => {};
  }, [resetTab]);

  const mapURL = validateURL(report?.mapLink) ? (
    <span>
      <Link href={report.mapLink} target={'_blank'} rel="noopener noreferrer">
        <img src={locationIcon} alt="" />
      </Link>
    </span>
  ) : (
    <span>
      <img className={'link-not-allowed'} src={locationIcon} alt="" />
    </span>
  );

  let showAlert = report.requestID == activeItem && !openUploadDialog;

  const itemCardRef = useRef(null);

  const scrollIntoView = () => {
    if (activeItem == report.requestID) {
      itemCardRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <RootCard
        ref={itemCardRef}
        raised={activeItem == report.requestID}
        removepad={tabValue == 3 ? 'true' : 'false'}
        sx={{ opacity: opacity, boxShadow: opacity == 0.5 ? 'none' : '' }}
      >
        <Box sx={{ width: '100%', position: 'relative' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="nd-tabs">
              <Tab label="Info" {...a11yProps(0)} />
              <Tab label="Instructions" {...a11yProps(1)} />
              <Tab label="Completion Report" {...a11yProps(2)} />
              {report.scanBackRequired && (
                <Tab
                  label={
                    <Stack direction="row" spacing={3} sx={{ alignItems: 'center', gap: '5px' }}>
                      <DocumentScannerOutlinedIcon sx={{ fontSize: '1rem', color: 'coral' }} />
                      Scan Backs
                    </Stack>
                  }
                  {...a11yProps(3)}
                />
              )}
            </Tabs>
          </Box>
          <CustomTabPanel showAlert={showAlert} value={tabValue} index={0} className="request-info">
            <CompletionInfoAlert report={report} />
            <div className="info-panel desktop-tab">
              <div className="req-info-col">
                <div className="req-info">
                  <Typography variant="subtitle2">Name:</Typography>
                  <span>{report.fullName}</span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Req #:</Typography>
                  <span>{report.requestSeqID}</span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Loan:</Typography>
                  <span>{report.loanNumber}</span>
                </div>
              </div>
              <div className="req-info-col">
                <div className="req-info">
                  <Typography variant="subtitle2">Signing Date:</Typography>
                  <span>
                    {moment(report.signingDateTime).format(
                      `MM/DD/YYYY ${moment(report.signingTime, 'hh:mm A', true).isValid() ? 'hh:mm A' : ''}`
                    )}
                  </span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Location:</Typography>
                  <span>{report.cityState}</span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Directions:</Typography>
                  {mapURL}
                </div>
              </div>
              <div className="req-info-col" style={{ alignItems: 'flex-end' }}>
                <div className="req-info">
                  <Typography variant="subtitle2">Docs:</Typography>
                  <span
                    className="link2"
                    onClick={() => {
                      processOpenUploadDialog(report);
                    }}
                  >
                    {getHudDocsFlag(report.hudDocsFlag)}
                  </span>
                </div>
              </div>
            </div>
            <div className="info-panel mobile-tab">
              <div className="req-info-col">
                <div className="req-info">
                  <Typography variant="subtitle2">Name:</Typography>
                  <span>{report.fullName}</span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Req #:</Typography>
                  <span>{report.requestSeqID}</span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Loan:</Typography>
                  <span>{report.loanNumber}</span>
                </div>
                <div className="req-info">
                  <p>
                    <strong>Docs: </strong>
                  </p>
                  <p
                    className="link2"
                    onClick={() => {
                      processOpenUploadDialog(report);
                    }}
                  >
                    {getHudDocsFlag(report.hudDocsFlag)}
                  </p>
                </div>
              </div>
              <div className="req-info-col">
                <div className="req-info">
                  <Typography variant="subtitle2">Date:</Typography>
                  <span>
                    {moment(report.signingDateTime).format(
                      `MM/DD/YYYY ${moment(report.signingTime, 'hh:mm A', true).isValid() ? 'hh:mm A' : ''}`
                    )}
                  </span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Location:</Typography>
                  <span>{report.cityState}</span>
                </div>
                <div className="req-info">
                  <Typography variant="subtitle2">Directions:</Typography>
                  {mapURL}
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel showAlert={showAlert} value={tabValue} index={1} className="instructions">
            <SigningRequestProvider requestID={report.requestID} context={'notaryDesk'}>
              <SigningRequestContext.Consumer>
                {(context) => (
                  <>
                    {context.gettingData ? (
                      <LinearProgress color="inherit" />
                    ) : (
                      <>
                        <CompletionInfoAlert report={report} />
                        <div className="comp-title">Signing Instructions</div>
                        <p style={{ whiteSpace: 'pre' }}>{context.signingRequest?.otherComments}</p>
                        <br />
                        <div className="comp-title">Shipping Instructions</div>
                        <p style={{ whiteSpace: 'pre' }}>{context.signingRequest?.shippingInstructions}</p>
                      </>
                    )}
                  </>
                )}
              </SigningRequestContext.Consumer>
            </SigningRequestProvider>
          </CustomTabPanel>
          <CustomTabPanel showAlert={showAlert} value={tabValue} index={2} className="report">
            <SigningRequestProvider
              requestID={report.requestID}
              context={'notaryDesk'}
              notifyAlertMsg={(event) => {
                if (!event) return;
                setOpenScanbackAlert({ open: true, msg: event });
              }}
            >
              <SigningRequestContext.Consumer>
                {(context) => {
                  report.signingStatusID = context.signingRequest?.signingStatusID;
                  if (!context.gettingData) scrollIntoView();
                  return (
                    <>
                      {!context.completionOptions.length && <LinearProgress color="inherit" />}

                      <CompletionInfoAlert report={report} context={context} />

                      {context.completionOptions.length > 0 && (
                        <>
                          {/* REPORT FORM */}
                          <CompletionInfoDesk />
                          {/* REPORT FORM */}
                          {context.signingRequest?.signingStatusID < 4 && (
                            <div className="button-div">
                              <Button variant="text" color="default" onClick={() => setTabValue(0)}>
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleSubmit(context.signingRequest, context.updateCompletionReport);
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  );
                }}
              </SigningRequestContext.Consumer>
            </SigningRequestProvider>
          </CustomTabPanel>
          {report.scanBackRequired && (
            <CustomTabPanel showAlert={showAlert} value={tabValue} index={3} className="scan-backs">
              <>
                <CompletionInfoAlert report={report} spacing={{ margin: '16px 16px 0 16px' }} />
                <FileUploadProvider
                  context={{ uploadDocType: 'ScanBacks', requestID: report.requestID }}
                  notifyAlertMsg={(event) => {
                    if (event && report.signingStatusID !== 4) {
                      setOpenScanbackAlert({
                        open: true,
                        msg: 'Thank you for uploading your scanned documents. Your request will not be completed until you submit your completion report.',
                      });
                    } else if (event && report.signingStatusID == 4) {
                      getCompletionReportsList(selectedNotary);
                    }
                    setBlockTabsOnFileUpload(false);
                  }}
                >
                  <FileUploadContext.Consumer>
                    {(context) => {
                      if (!context.isGettingFiles) scrollIntoView();
                      if (context.state.selectedFiles?.length > 0) {
                        blockTabsOnFileUploadRef.current = 3;
                      } else blockTabsOnFileUploadRef.current = undefined;

                      return (
                        <>
                          {context.state.selectedFiles?.length > 0 && context.isSubmitting === true && (
                            <Prompt
                              when={context.state.selectedFiles?.length > 0 && context.isSubmitting}
                              message={JSON.stringify({
                                open: true,
                                title: 'Alert',
                                switchBtn: true,
                                falseEventText: 'No',
                                trueEventText: 'Yes',
                                msg: 'If you navigate away, your file upload will be canceled. Are you sure you want to leave?',
                              })}
                            />
                          )}
                          <ScanBackUploader
                            title={'Scan Backs'}
                            buttonTrueText={'Upload'}
                            buttonFalseText={'Close'}
                            data={{ signingStatusID: report.signingStatusID }}
                            onClose={(e) => {}}
                          />
                        </>
                      );
                    }}
                  </FileUploadContext.Consumer>
                </FileUploadProvider>
              </>
            </CustomTabPanel>
          )}
        </Box>
        {blockTabsOnFileUpload && (
          <AppConfirmationDialog
            id="appConfirmationDialog"
            open={true}
            title="Alert"
            content="If you navigate away, your file upload will be canceled. Are you sure you want to leave?"
            switchBtn={true}
            buttonTrueText="Yes"
            buttonFalseText="No"
            onClose={(e) => setBlockTabsOnFileUpload(false)}
            confirmCallback={(e) => {
              if (e) {
                blockTabsOnFileUploadRef.current = undefined;
                setTabValue(parseInt(attemptedTabValue));
              }
              setBlockTabsOnFileUpload(e);
            }}
          />
        )}
        {openUploadDialog && (
          <FileUploadProvider
            notaryDesk
            context={{
              uploadDocType: 'SigningFiles',
              requestID: report.requestID,
            }}
          >
            <UploadFilesDialog
              open={openUploadDialog}
              title={'Documents Upload'}
              buttonTrueText={'Save'}
              buttonFalseText={'Close'}
              documentData={documentData}
              onClose={(e) => {
                getCompletionReportsList(selectedNotary);
                setOpenUploadDialog(false);
              }}
            />
          </FileUploadProvider>
        )}
        {openScanbackAlert.open && report.scanBackRequired && (
          <AppAlertDialog
            severity="info"
            open={openScanbackAlert.open}
            title={'Scan Backs'}
            content={openScanbackAlert.msg}
            onClose={() => {
              setOpenScanbackAlert(false);
            }}
          />
        )}
      </RootCard>
    </>
  );
};

export default Item;
