import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import { ContactManagerContext } from '../../../context/contact-manager.context';
import { EmptyList } from '../../common/others/EmptyList';
import Loading from '../../common/others/Loading';
import CustomPagination from '../../common/others/Pagination';
import { dispatchAlert } from '../../../helpers';
import { connect } from 'react-redux';
import { DataItemCustomer } from './DataItemCustomer';
import { Card, Collapse, Divider, Skeleton } from '@mui/material';
import DataListItem from './DataListItem';
import Show from '../../common/others/Show';
import { useAgentProfileFormStore } from '../../agent-profile-form/hooks/useAgentProfileFormStore';
import Box from '@mui/material/Box';
import { TransitionGroup } from 'react-transition-group';
import { useTheme } from '@mui/styles';
import ShineAnimation from '../../common/transitions/shine-animation';
import useTaskInterval from '../../../helpers/hooks/useTaskInterval';
import { contactManagerService as service } from '../../../services';

const DataListRender = (props) => {
  const context = useContext(ContactManagerContext);
  const renderFunc = props.children || props.render;

  return (
    <Fragment>
      {props.dataListCount === undefined &&
        props.dataListCount === undefined &&
        (props.loadingdata === undefined || props.loadingdata) &&
        props.onInitialLoad()}
      {props.dataList !== undefined && props.loadingdata && props.onLoading(true)}
      {props.dataList !== undefined && !props.loadingdata && props.dataListCount == 0 && props.onEmptyDataList()}
      {props.dataList?.map(renderFunc)}
      {context.dataList?.length > 0 && (
        <CustomPagination
          pageType={'contactManager'}
          pageSizeOptions={context.pageSizesForList}
          pageSize={context.currentPaginationPageSize()}
          pageNumber={context.filterPayLoad.currentPageNumber}
          totalPages={context.totalPages}
          handlePageSize={context.handlePageSize}
          handlePageNumber={context.handlePageNumber}
        />
      )}
    </Fragment>
  );
};

const DataList = ({ store, ...props }) => {
  const {
    listMode,
    renderList,
    setRenderList,
    renderListView,
    requestID,
    getContactList,
    loadingDataList,
    filterPayLoad,
    dataList,
    assignNotaryState,
    assignedAgentFromRoute,
    assignAgentToRequest,
    agentEmailRecipients,
    emailTemplate,
    setEmailTemplate,
    setAgentEmailRecipients,
    selectedEmailCount,
    setSelectedEmailCount,
    selectedEmailCountLimit,
    fromContactManager,
    profileLookups,
    controlSetting,
  } = useContext(ContactManagerContext);

  const theme = useTheme();

  const addedProfiles = useAgentProfileFormStore((state) => state.addedProfiles);
  const replaceProfile = useAgentProfileFormStore((state) => state.replaceProfile);

  const { addTask, taskStatusesRef, runningTasksCount } = useTaskInterval({
    callback: async (task) => {
      let newController = new AbortController();
      let signal = newController.signal;
      try {
        const profile = await service.getNewProfile(task.id, signal);

        if (!profile?.notSynced) {
          replaceProfile({ ...profile, identityId: task.id, notSynced: false });
        }

        return profile ? { success: !profile?.notSynced, data: profile } : {};
      } catch (error) {
        throw error;
      }
    },
    notifier: (error) => {
      dispatchAlert(
        'Your profile is set up. The full sync is underway and might require additional time. Please refresh your browser and search for your notary'
      );
    },
    syncInterval: 7000,
    maxCallbackRerun: 12,
  });

  useEffect(() => {
    if (renderList || props.reload) {
      // TODO: Investigate why I need to wait to get the filters right
      // setTimeout is a temporary "fix" workaround
      setTimeout(() => getContactList(), 500);
    }
    return () => setRenderList(false);
  }, [props.reload]);

  const selectedAgentInList = assignNotaryState?.publicId || assignedAgentFromRoute; //v2PublicID

  const handleSelectedEmails = useCallback(
    async (event) => {
      if (listMode == 'email') {
        if (event.remove) {
          let _newArray = agentEmailRecipients.filter((i) => i.publicId !== event.publicId);
          setAgentEmailRecipients(_newArray);
          setSelectedEmailCount(selectedEmailCount - 1);
          setEmailTemplate({ ...emailTemplate, recipients: _newArray });
        } else {
          if (selectedEmailCount + 1 > selectedEmailCountLimit) {
            let msg = `Only ${selectedEmailCountLimit} email addresses are allowed to be selected.`;
            dispatchAlert(msg, true);
            return false;
          }
          setSelectedEmailCount(selectedEmailCount + 1);
          setAgentEmailRecipients([...agentEmailRecipients, event]);
          setEmailTemplate({
            ...emailTemplate,
            recipients: [...agentEmailRecipients, event],
          });
        }
        return true;
      }
    },
    [listMode, selectedEmailCount, agentEmailRecipients]
  );

  const handleAgentAssignation = useCallback((agentId) => {
    assignAgentToRequest(agentId);
  }, []);

  const setClassNameForAssignedAgent = (contact) => {
    return contact.v2PublicID === selectedAgentInList ? 'agent-selected' : '';
  };

  useEffect(() => {
    const [addedProfile] = addedProfiles;
    if (addedProfile && addedProfile?.notSynced) {
      const task = { id: addedProfile.identityId };
      addTask(task);
    }
  }, [addedProfiles]);

  return (
    <Fragment>
      <Show when={addedProfiles?.length > 0}>
        <Box sx={{ overflow: 'hidden', position: 'relative', mt: 1 }}>
          <TransitionGroup>
            {addedProfiles.map((profile) => {
              return (
                <Collapse
                  direction="down"
                  key={profile.identityId}
                  sx={{ position: 'relative' }}
                  timeout={{ enter: 1000, exit: 500 }}
                >
                  <ShineAnimation showAnimation={taskStatusesRef?.current[profile.identityId]?.intervalId}>
                    <DataListItem
                      controlSetting={controlSetting}
                      contact={profile}
                      handleSelectedEmails={handleSelectedEmails}
                      handleAgentAssignation={handleAgentAssignation}
                      filterPayLoad={filterPayLoad}
                      fromContactManager={fromContactManager}
                      profileLookups={profileLookups}
                      requestID={requestID}
                      listMode={listMode}
                      agentEmailRecipients={agentEmailRecipients}
                      renderListView={renderListView}
                      setClassNameForAssignedAgent={setClassNameForAssignedAgent}
                      store={store}
                    />
                  </ShineAnimation>
                </Collapse>
              );
            })}
          </TransitionGroup>
        </Box>

        <Show when={runningTasksCount === 0}>
          <Divider sx={{ width: '100%', my: 2 }} />
        </Show>

        <Show when={runningTasksCount > 0}>
          <Loading
            linear={true}
            forList
            msg={'Synchronizing new Profiles ...'}
            linearProgress={{ mb: 1 }}
            label={{ color: theme.palette.error.main, fontWeight: 'bold', whiteSpace: 'nowrap', mb: 1 }}
          />
        </Show>
      </Show>

      <DataListRender
        loadingdata={loadingDataList}
        dataListCount={dataList?.length}
        dataList={dataList}
        onInitialLoad={() => (
          <>
            <Loading linear={true} forList />
            <Card sx={{ display: 'flex', alignItems: 'center', gap: '10px', p: 1 }}>
              <Skeleton variant="circular" width={40} height={40} />
              <span style={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </span>
            </Card>
          </>
        )}
        onLoading={(showLienar) => <Loading linear={showLienar} forList />}
        onEmptyDataList={() => <EmptyList msg="Contacts were not found." />}
      >
        {(contact, index) =>
          contact && (
            <Fragment key={index}>
              {contact.userType == 'agent' ? (
                <DataListItem
                  controlSetting={controlSetting}
                  contact={contact}
                  handleSelectedEmails={handleSelectedEmails}
                  handleAgentAssignation={handleAgentAssignation}
                  filterPayLoad={filterPayLoad}
                  fromContactManager={fromContactManager}
                  profileLookups={profileLookups}
                  requestID={requestID}
                  listMode={listMode}
                  agentEmailRecipients={agentEmailRecipients}
                  renderListView={renderListView}
                  setClassNameForAssignedAgent={setClassNameForAssignedAgent}
                  store={store}
                />
              ) : (
                <DataItemCustomer contact={contact} />
              )}
            </Fragment>
          )
        }
      </DataListRender>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({ store: state.reducer });
export default connect(mapStateToProps)(DataList);
