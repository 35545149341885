import React, { useState, useEffect, useRef, setState } from 'react';
import { Button, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const StyledDiv = styled(Card)(
  ({ theme }) => css`
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 5px 3px -3px gray;
  `
);

const UserAdminTableFooter = ({ onClick }) => {
  return (
    <StyledDiv>
      <Button variant="contained" endIcon={<AddIcon />} onClick={onClick}>
        Add User
      </Button>
      <Typography variant="h6">
        <u>Explantion of Roles Noted in Table</u>
      </Typography>
      <Typography>
        <b>Admin:</b>
        This role allows the user to edit the content of the site or manage users above.
      </Typography>
      <Typography>
        <b>Staff:</b>
        Participates in the 'Staff Assignment' feature. The 'initials' will be displayed on the request and the 'Order'
        is used in the assignment process.
      </Typography>
      <Typography>
        <b>Auth:</b>
        Activates or inactivates the login.
      </Typography>
    </StyledDiv>
  );
};

export default UserAdminTableFooter;
