import React, { useState, useRef, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GetInitialFilterOptions } from '../components/manage-request/Invoice/filter/model';
import { dispatchAlert } from '../helpers';
import { agentPaymentMgtService as service } from '../services';
import useBreadcrumb from '../helpers/hooks/useBreadcrumb';

export const AgentPaymentMgtContext = createContext();

const pageSizesForList = [
  { value: 10, label: '10 rows' },
  { value: 20, label: '20 rows' },
  { value: 40, label: '40 rows' },
  { value: 80, label: '80 rows' },
  { value: 100, label: '100 rows' },
  { value: 150, label: '150 rows' },
  { value: 200, label: '200 rows' },
  { value: 500, label: '500 rows' },
];

const filterOptions = (type) => {
  return [
    {
      id: 'borrowerLastName',
      label: 'Borrower Last Name',
      type: 'input',
    },
    { id: 'agentLastName', label: 'Agent Last Name', type: 'input' },
    {
      id: 'regInfo',
      label: 'Agent Docs',
      type: 'dropdown',
      useCustomForm: true,
      otherOptions: {
        regStatus: [
          {
            index: '-1',
            value: 'ALL',
          },
          {
            index: '1',
            value: 'Pending',
          },
          {
            index: '2',
            value: 'Approved',
          },
          {
            index: '3',
            value: 'Declined',
          },
        ],
        actions: [
          {
            index: '1',
            value: 'Include',
          },
          {
            index: '0',
            value: 'Exclude',
          },
        ],
      },
    },
    {
      id: 'requestStatus',
      label: 'Request Status',
      type: 'dropdown',
      backendValProp: 'value',
    },
  ];
};

const getLookupsForDDL = (reg1, reg2) => {
  return {
    regInfo: [
      {
        index: 'reg_W9',
        value: 'W9',
      },
      {
        index: 'reg_OC',
        value: 'OC',
      },
      {
        index: 'reg_HC',
        value: 'HC',
      },
      {
        index: 'reg_C1',
        value: reg1,
      },
      {
        index: 'reg_C2',
        value: reg2,
      },
    ],
    ok: [
      {
        index: '-1',
        value: 'ALL',
      },
      {
        index: '1',
        value: 'Pending',
      },
      {
        index: '2',
        value: 'Approved',
      },
      {
        index: '3',
        value: 'Declined',
      },
    ],
    requestStatus: [
      {
        index: '-1',
        value: 'ALL',
      },
      {
        index: '1',
        value: 'New',
      },
      {
        index: '2',
        value: 'Pending',
      },
      {
        index: '3',
        value: 'Confirmed',
      },
      {
        index: '4',
        value: 'Complete',
      },
      {
        index: '5',
        value: 'Invoiced',
      },
      {
        index: '6',
        value: 'Closed',
      },
      {
        index: '9',
        value: 'Declined',
      },
      {
        index: '10',
        value: 'Canceled',
      },
    ],
  };
};

const agentPaymentsInitialState = () => {
  return {
    agentPayments: {
      totalApproved: 0.0,
      selected: false,
      open: false,
      batchId: 0,
      lastBatchId: 0,
      lastBatchDate: '',
      wasExported: false,
      data: [],
      batchList: [],
    },
    pagination: { pageNumber: 1, pageSize: 20, totalRecords: 0 },
    filter: null,
    controlSetting: {},
  };
};

const initialFilterPayload = {
  from: '',
  to: '',
  showPaid: false,
  hideCanceled: false,
  borrowerLastName: '',
  agentLastName: '',
  regInfo: {
    regInfo: [],
    regStatus: 'ALL',
    action: 'Included',
  },
  requestStatus: '',
};

const AgentPaymentMgtProvider = (props) => {
  const mountedRef = useRef(true);
  const history = useHistory();
  const [breadCrumb, updateBreadcrumb] = useBreadcrumb(history);
  const [loadingDataList, setLoadingDataList] = useState(false);
  const [agentLookups, setAgentLookups] = useState({});
  const [filterPayLoad, setFilterPayLoad] = useState(initialFilterPayload);
  const [appliedFilters, setAppliedFilters] = useState(() => {
    return GetInitialFilterOptions(filterOptions());
  });
  const [agentPayments, setAgentPayments] = useState(
    agentPaymentsInitialState().agentPayments
  );
  const [agentPaymentsInBatch, setAgentPaymentsInBatch] = useState([]);
  const [batchAgentPaymentsInit, setBatchAgentPaymentsInit] = useState([]);
  const [pagination, setPagination] = useState(
    agentPaymentsInitialState().pagination
  );
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    getRegCustomLabels();
    getAgentPaymentsList(filterPayLoad, false, true);
    return () => {};
  }, []);

  useEffect(() => {
    if (appliedFilters && agentLookups) {
      setAppliedFilters(
        mapSelectTypesForFancyFilter(agentLookups, appliedFilters)
      );
    }
    return () => {};
  }, [agentLookups]);

  const mapSelectTypesForFancyFilter = (lookups, applyFilters) => {
    applyFilters.map((item) => {
      switch (item.id) {
        case 'regInfo':
          item.options = lookups.regInfo;
          break;
        case 'ok':
          item.options = lookups.ok;
          break;
        case 'requestStatus':
          item.options = lookups.requestStatus;
          break;
      }
    });
    return applyFilters;
  };

  const setPageList = (response, batchList) => {
    response.data.forEach((child) => {
      let count = 0;
      child.data.forEach((batch) => {
        if (batchList.find((x) => x.requestID === batch.requestID)) {
          batch.selected = true;
          count++;
        } else {
          batch.selected = false;
        }
      });

      if (child.data.length == count) child.selected = true;
      else child.selected = false;

      if (count > 0) child.open = true;
    });

    let countParents = 0;
    response.data.forEach((item) => {
      if (item.selected) countParents++;
    });

    if (response.data.length == countParents) {
      response.selected = true;
    } else response.selected = false;

    response.totalApproved = batchList
      .map((item) => (item.selected ? item.totalBalance : 0))
      .reduce((prev, curr) => prev + curr, 0);

    setAgentPayments(response);
  };

  const getRegCustomLabels = async () => {
    return await service.getRegCustomLabels().then(
      (res) => {
        if (!mountedRef.current) return null;
        setAgentLookups(
          getLookupsForDDL(res.regCustomInitials1, res.regCustomInitials2)
        );
      },
      (error) => {
        dispatchAlert(error, true);
      }
    );
  };

  const getAgentPaymentsList = async (
    params = filterPayLoad,
    onPagination = false,
    isOnce = false
  ) => {
    setLoadingDataList(true);
    let payload = {
      ...params,
      currentPageNumber: !onPagination ? 1 : params.currentPageNumber,
    };
    setFilterPayLoad(payload);
    return await service.GetAgentPaymentsManagement(payload).then(
      (res) => {
        if (!mountedRef.current) return null;
        if (isOnce) {
          setAgentPaymentsInBatch([...res.batchList]);
          setBatchAgentPaymentsInit([...res.batchList]);
          setPageList(res, res.batchList);
          agentPayments.totalApproved = res.totalApproved;
        } else {
          setPageList(res, agentPaymentsInBatch);
        }
        setPagination(res.pagination);
        setLoadingDataList(false);
      },
      (error) => {
        setLoadingDataList(false);
        dispatchAlert(error, true);
      }
    );
  };

  const handlePageSize = (e) => {
    let val = e.target?.value ? e.target.value : e;
    if (filterPayLoad.pageNumber > Math.ceil(pagination?.totalRecords / val)) {
      getAgentPaymentsList({
        ...filterPayLoad,
        pageSize: val,
        pageNumber: 1,
      });
    } else {
      getAgentPaymentsList({
        ...filterPayLoad,
        pageSize: val,
      });
    }
  };

  const handlePageNumber = (e) => {
    let val = e.target?.value ? e.target.value : e;
    getAgentPaymentsList({ ...filterPayLoad, pageNumber: val });
  };

  const currentPaginationPageSize = () => {
    let defaultRowNumber = pagination.pageSize || 0;
    if (!defaultRowNumber) return 20; // is the minimun option to select in the ddl.
    return filterPayLoad.pageSize
      ? filterPayLoad.pageSize
      : parseInt(defaultRowNumber);
  };

  const totalPages = Math.ceil(
    pagination.totalRecords / currentPaginationPageSize() || 1
  );

  const undoLastBatch = async () => {
    setLoadingDataList(true);

    return await service.undoLastBatch(agentPayments.lastBatchId).then(
      (res) => {
        dispatchAlert('Last post batch was successfully undone.');
        if (!mountedRef.current) return null;
        getAgentPaymentsList(filterPayLoad, false, true);
        setLoadingDataList(false);
      },
      (error) => {
        setLoadingDataList(false);
        dispatchAlert(error, true);
      }
    );
  };

  const undoCurrentBatch = async (refreshData = true) => {
    setLoadingDataList(refreshData);
    return await service.undoCurrentBatch(agentPayments.batchId).then(
      (res) => {
        dispatchAlert('Batch was successfully undone.');
        if (!mountedRef.current) return null;
        if (refreshData) getAgentPaymentsList(filterPayLoad, false, true);
        else {
          agentPayments.batchId = 0; // must be like this
          agentPayments.totalApproved = 0.0; // must be like this
          setAgentPaymentsInBatch([]);
          setBatchAgentPaymentsInit([]);
        }
        setLoadingDataList(false);
      },
      (error) => {
        setLoadingDataList(false);
        dispatchAlert(error, true);
      }
    );
  };

  const createNewBatch = async () => {
    setIsCreating(true);
    let msg = agentPayments.batchId > 0 ? 'updated' : 'created';

    // let _totalApproved = agentPaymentsInBatch
    //   .map((item) => item.totalBalance)
    //   .reduce((prev, curr) => prev + curr, 0);

    // if (agentPayments.totalApproved != _totalApproved) {
    //   dispatchAlert(
    //     'The Approved total does not match with the selected items.',
    //     true
    //   );
    //   setIsCreating(false);
    //   return;
    // }

    return await service.createNewBatch(agentPaymentsInBatch).then(
      (res) => {
        dispatchAlert(`Batch was ${msg} successfully.`);
        if (!mountedRef.current) return null;

        setAgentPayments({ ...agentPayments, batchId: res, lastBatchId: res });
        setIsCreating(false);
        setBatchAgentPaymentsInit([...agentPaymentsInBatch]);
      },
      (error) => {
        setIsCreating(false);
        setAgentPaymentsInBatch([]);
        dispatchAlert(error, true);
      }
    );
  };

  const getAgentPaymentReport = async (format, byAgent = false) => {
    let filename = '';
    return await service
      .getAgentPaymentReport(agentPayments.batchId, format, byAgent)
      .then((response) => {
        if (response.ok) {
          if (!mountedRef.current) return null;
          setAgentPayments((values) => ({
            ...values,
            wasExported: response,
          }));
          const header = response.headers.get('Content-Disposition');
          const parts = header?.split(';');
          filename = parts[1].split('=')[1];
          return response.blob();
        } else {
          throw new Error('Error occurred while downloading the file.');
        }
      })
      .then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => dispatchAlert(error.message, true));
  };

  const postApproved = async () => {
    setLoadingDataList(true);
    const doPost = async () => {
      await service.postApproved(agentPayments.batchId).then(
        (res) => {
          dispatchAlert('Payment batch was posted successfully.');
          return res;
        },
        (error) => {
          setLoadingDataList(false);
          dispatchAlert(error, true);
          return error;
        }
      );
    };

    await doPost();
    await service
      .GetAgentPaymentsManagement({ ...filterPayLoad, currentPageNumber: 1 })
      .then(
        (res) => {
          if (!mountedRef.current) return null;
          setAgentPaymentsInBatch([]);
          setBatchAgentPaymentsInit([]);
          setPageList(res, []);
          agentPayments.batchId = 0;
          agentPayments.totalApproved = 0.0;
        },
        (error) => dispatchAlert(error, true)
      );
    setLoadingDataList(false);
  };

  const comparePayments = () => {
    let counter = 0;
    let equals = 0;

    batchAgentPaymentsInit.forEach((child) => {
      if (child.selected) {
        counter++;
        agentPaymentsInBatch.forEach((batch) => {
          if (child.tableID == batch.tableID) {
            equals++;
          }
        });
      }
    });
    return counter == equals && equals == agentPaymentsInBatch.length;
  };

  const assignBatchPayments = (row) => {
    if (!row.selected) {
      let arr = agentPaymentsInBatch.filter(function (item) {
        return item.requestID !== row.requestID;
      });
      let _totalMinus = agentPayments.totalApproved - row.totalBalance;
      agentPayments.totalApproved = _totalMinus;
      setAgentPaymentsInBatch((values) => [...arr]);
    } else {
      let exists = agentPaymentsInBatch.filter(function (item) {
        return item.requestID == row.requestID;
      });

      let _totalPlus =
        agentPayments.totalApproved +
        (exists.length == 0 ? row.totalBalance : 0);
      agentPayments.totalApproved = _totalPlus;
      setAgentPaymentsInBatch((values) => [...values, row]);
    }
  };

  const assignManyBatchPayments = (rows) => {
    let batchs = [...agentPaymentsInBatch];
    for (let i = 0; i < rows.length; i++) {
      var row = rows[i];
      if (!row.selected) {
        let arr = batchs.filter(function (item) {
          return item.requestID !== row.requestID;
        });
        batchs = [...arr];
      } else {
        let finded = batchs.find(function (item) {
          return item.requestID == row.requestID;
        });

        batchs = finded == undefined ? [...batchs, row] : [...batchs];
      }
    }

    agentPayments.totalApproved = batchs
      .map((item) => (item.selected ? item.totalBalance : 0))
      .reduce((prev, curr) => prev + curr, 0);
    setAgentPaymentsInBatch((values) => [...batchs]);
  };

  const openChild = (row) => {
    row.open = !row.open;
    setAgentPayments((values) => ({
      ...values,
    }));
  };

  const getUniqueItems = (array1, array2) => {
    let arrayConcatenado = array1.concat(array2);
    let elementosUnicos = [];

    for (let i = 0; i < arrayConcatenado.length; i++) {
      if (
        arrayConcatenado.indexOf(arrayConcatenado[i]) ===
        arrayConcatenado.lastIndexOf(arrayConcatenado[i])
      ) {
        elementosUnicos.push(arrayConcatenado[i]);
      }
    }

    return elementosUnicos;
  };

  const selectAllRow = () => {
    agentPayments.selected = !agentPayments.selected;
    let payments = [];
    agentPayments.data.map((row, index) => {
      row.selected = agentPayments.selected;
      if (row.selected) row.open = row.selected;

      row.data.map((child, index) => {
        child.selected = agentPayments.selected;
        payments.push(child);
      });
    });

    let batches = [];
    if (agentPayments.selected) {
      let toAdd = [];
      agentPayments.data.map((parent, index) => {
        parent.data.map((child, index) => {
          const re = agentPaymentsInBatch.find(
            (element) => element.requestID == child.requestID
          );
          if (!re) {
            toAdd.push(child);
          }
        });
      });

      batches = [...agentPaymentsInBatch, ...toAdd];
      agentPayments.totalApproved = batches
        .map((item) => (item.selected ? item.totalBalance : 0))
        .reduce((prev, curr) => prev + curr, 0);

      setAgentPaymentsInBatch((values) => batches);
    } else {
      let toRemoved = [];
      agentPayments.data.map((parent, index) => {
        parent.data.map((child, index) => {
          toRemoved = [
            ...toRemoved,
            ...agentPaymentsInBatch.filter(
              (m) => child.requestID == m.requestID
            ),
          ];
        });
      });

      let newBatch = getUniqueItems(toRemoved, agentPaymentsInBatch);
      agentPayments.totalApproved = newBatch
        .map((item) => (item.selected ? item.totalBalance : 0))
        .reduce((prev, curr) => prev + curr, 0);

      setAgentPaymentsInBatch(newBatch);
    }
  };

  const selectRow = (childItem, summaryItem = null) => {
    childItem.selected = !childItem.selected;
    if (childItem.selected) childItem.open = childItem.selected;
    if (childItem.data) {
      let children = [];
      childItem.data.map((child, index) => {
        child.selected = childItem.selected;
        children.push(child);
      });
      assignManyBatchPayments(children);
    } else {
      let allChecked = 0;
      summaryItem.data.map((child, index) => {
        if (child.selected) {
          allChecked++;
        }
      });
      assignBatchPayments(childItem);

      if (summaryItem.data.length == allChecked) {
        summaryItem.selected = true;
      } else {
        summaryItem.selected = false;
      }
    }

    let count = 0;
    agentPayments.data.forEach((item) => {
      if (item.selected) count++;
    });

    if (agentPayments.data.length == count) agentPayments.selected = true;
    else agentPayments.selected = false;
  };

  const openAllChild = () => {
    agentPayments.open = !agentPayments.open;

    agentPayments.data.map((row, index) => {
      row.open = agentPayments.open;
    });

    setAgentPayments((values) => ({
      ...values,
    }));
  };

  return (
    <AgentPaymentMgtContext.Provider
      value={{
        breadCrumb,
        agentPayments,
        pageSizesForList,
        pagination,
        totalPages,
        filterPayLoad,
        loadingDataList,
        appliedFilters,
        isCreating,
        agentPaymentsInBatch,
        updateBreadcrumb,
        initialFilterPayload,
        createNewBatch,
        undoLastBatch,
        setFilterPayLoad,
        setAppliedFilters,
        getAgentPaymentsList,
        postApproved,
        setAgentPayments,
        setAgentPaymentsInBatch,
        filterOptions,
        getLookupsForDDL,
        comparePayments,
        openChild,
        selectRow,
        selectAllRow,
        openAllChild,
        undoCurrentBatch,
        handlePageSize,
        handlePageNumber,
        getAgentPaymentReport,
      }}
    >
      {props.children}
    </AgentPaymentMgtContext.Provider>
  );
};

export default AgentPaymentMgtProvider;
