import React from 'react';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const ForecastStyle = styled('div')(
  ({ theme }) => css`
    .Forecast__title {
      font-size: 0.9rem;
      text-align: center;
    }

    .Forecast__weather-icon {
      width: 60px;
      height: 60px;
    }

    .Forecast__weather-icon:hover {
      animation-name: icons;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    @keyframes icons {
      0% {
        position: relative;
        top: 0;
      }
      50% {
        position: relative;
        top: 0.25rem;
      }
      100% {
        position: relative;
        top: 0;
      }
    }

    .Forecast__temperature {
      display: flex;
      justify-content: center;
    }

    .temperature__max {
      margin-right: 0.2rem;
      color: #f37928;
    }

    .temperature__min {
      margin-left: 0.2rem;
    }
  `
);

const Forecast = ({ weatherInfo, date }) => {
  return (
    <ForecastStyle>
      <h1 className="Forecast__title">{[date[0], date[1], date[2]]}</h1>
      <img
        className="Forecast__weather-icon"
        src={
          'https://openweathermap.org/img/wn/' +
          weatherInfo.weather[0].icon +
          '.png'
        }
        alt={weatherInfo.weather[0].main}
      />
      <div className="Forecast__temperature">
        <span className="temperature__max">
          {Math.round(weatherInfo.temp.max)}
          <sup className="temperature__symbol">°</sup>
        </span>
        <span className="temperature__min">
          {Math.round(weatherInfo.temp.min)}
          <sup className="temperature__symbol">°</sup>
        </span>
      </div>
    </ForecastStyle>
  );
};

export default Forecast;
