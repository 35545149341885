import React, { memo, useCallback, useEffect, useRef, useState, useTransition } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AgreementStatusCard } from '../agent-agreement/AgreementStatusCard';
import { useAgreementStore } from '../agent-agreement/AgentAgreementModal';
import { useCloseModal, useModalAlert } from '../../helpers/hooks/useModals';
import { MESSAGES, useAgreementModals, useAgreementServices } from '../../views/AgentSigningAgreement';
import { connect } from 'react-redux';
import { dashboardService } from '../../services/dashboard.service';
import { UploadFilesService } from '../../services';
import { useMediaQuery } from '@mui/material';

const useDashboardAgentDownloads = () => {
  const [agreements, setAgreements] = useState([]);
  const [currentAgreement, setCurrentAgreement] = useState(null);

  useEffect(() => {
    dashboardService.getAgentAgreements().then((res) => {
      setCurrentAgreement(res.shift());
      setAgreements(res);
    });
  }, []);

  return {
    agreements,
    currentAgreement,
  };
};

const DashboardAgentDownloads = ({ store, dispatch, children, ...props }) => {
  const { agreements, currentAgreement } = useDashboardAgentDownloads();

  const mountedRef = useRef(true);
  const theme = useTheme();

  const [isPending, startTransition] = useTransition();
  const disableButtonActions = useAgreementStore((state) => {
    return state.disableButtonActions;
  });

  const resetButtonActions = useAgreementStore((state) => {
    return state.reset;
  });

  const setAgreementDocId = useAgreementStore((state) => {
    return state.setAgreementDocId;
  });

  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { UpdateSignAgreementSelection, loading, signAgreementError } = useAgreementServices(props);

  const { showAgreementModal, showConfirmModal, showDeclineModal } = useAgreementModals(store);
  const { closeLatestModal, resetModals } = useCloseModal();
  const { pushAlert } = useModalAlert();

  const agreementId = currentAgreement?.userAgreementId;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const handleClose = () => {
    closeLatestModal();
  };

  const notify = (type, message) => {
    pushAlert({
      open: true,
      color: type,
      message,
      ...(type === 'error' ? { timeOut: 8000 } : {}),
    });
  };

  const redirectToHome = () => {
    setTimeout(() => {
      dispatch({
        type: 'SET_SIGNED_AGREEMENT',
        payload: {
          needServiceAgreementSigned: false,
        },
      });
      window.location.reload();
      resetModals();
      resetButtonActions();
    }, 3000);
  };

  const handleDecline = useCallback(
    (choice) => {
      UpdateSignAgreementSelection(
        'PUT',
        {},
        {
          id: agreementId,
          status: 2,
          comment: choice,
        }
      )
        .then(() => {
          disableButtonActions();
          notify('info', MESSAGES.DECLINE);
          redirectToHome();
        })
        .catch((error) => {
          notify('error', MESSAGES.ERROR);
        })
        .finally(handleClose);
    },
    [agreementId]
  );

  const handleConfirm = useCallback(() => {
    UpdateSignAgreementSelection(
      'PUT',
      {},
      {
        id: agreementId,
        signedAgreement: true,
        status: 1,
      }
    )
      .then((resp) => {
        disableButtonActions();
        notify('success', MESSAGES.AGREE);
        redirectToHome();
      })
      .catch((error) => {
        notify('error', MESSAGES.ERROR);
      })
      .finally(handleClose);
  }, [agreementId]);

  const handleIAgree = useCallback(() => {
    showConfirmModal({
      handleConfirm,
      handleClose,
    });
  }, [agreementId]);

  const handleIDecline = useCallback(() => {
    showDeclineModal({
      handleDecline,
      handleClose,
    });
  }, [agreementId]);

  const handleShowAgreement = useCallback(() => {
    showAgreementModal({
      preventDecline: true,
      handleClose,
      handleIAgree,
      handleIDecline,
    });
  }, [loading, agreementId]);

  const handleDownload = useCallback((docID) => {
    UploadFilesService.downloadFile(
      {
        uploadDocType: 'ServiceAgreementForAgent',
      },
      docID
    );
  }, []);

  useEffect(() => {
    setAgreementDocId(currentAgreement?.contractId);
  }, [currentAgreement]);

  return (
    <AgreementStatusCard
      children={children}
      isMobile={isMedium || isMobile}
      currentAgreement={currentAgreement}
      agreements={agreements}
      onShowAgreement={handleShowAgreement}
      onDownload={handleDownload}
    />
  );
};

const mapStateToProps = (state) => ({
  store: state.reducer,
  dispatch: state.dispatch,
});

export default connect(mapStateToProps)(memo(DashboardAgentDownloads));
