import { isValidEmail, parseStringTime } from '../helpers';

export class BorrowerValidator {
  requiredFields = null;
  inputs = {
    borrowerFirstName: { isValid: true, error: 'Signer First Name' },
    borrowerLastName: { isValid: true, error: 'Signer Last name' },
    coBorrowerFirstName: { isValid: true, error: 'CoSigner First Name' },
    coBorrowerLastName: { isValid: true, error: 'CoSigner Last Name' },
    signingAddress1: { isValid: true, error: 'Signing Address' },
    signingCity: { isValid: true, error: 'Signing City' },
    signingState: { isValid: true, error: 'Signing State' },
    signingZipCode: { isValid: true, error: 'Signing Zip code' },
    dayPhone: { isValid: true, error: 'Signing Phone' },
  };

  constructor(payload, required = null) {
    this.requiredFields = required;
    this.setter(payload);
  }

  setter = (payload) => {
    Object.assign(this.inputs.borrowerFirstName, { value: payload?.borrowerFirstName });
    Object.assign(this.inputs.borrowerLastName, { value: payload?.borrowerLastName });
    Object.assign(this.inputs.coBorrowerFirstName, { value: payload?.coBorrowerFirstName });
    Object.assign(this.inputs.coBorrowerLastName, { value: payload?.coBorrowerLastName });
    Object.assign(this.inputs.signingAddress1, { value: payload?.signingAddress1 });
    Object.assign(this.inputs.signingCity, { value: payload?.signingCity });
    Object.assign(this.inputs.signingState, { value: payload?.signingState });
    Object.assign(this.inputs.signingZipCode, { value: payload?.signingZipCode });
    Object.assign(this.inputs.dayPhone, { value: payload?.dayPhone });
  };

  validateForm = () => {
    const validate = (property) => {
      let pass1 = this.inputs[property].value?.trim() ? 1 : 0;
      let pass2 = this.requiredFields ? (this.requiredFields[property] ? 1 : 0) : 1;

      if (pass2 == 0) return true;
      return pass1 + pass2 == 2 ? true : false;
    };

    const setValidator = (property, preComputed = null, customError = '') => {
      this.inputs[property] = {
        ...this.inputs[property],
        isValid: preComputed !== null ? preComputed : validate(property),
        error: customError ? customError : this.inputs[property].error,
      };
    };

    setValidator('borrowerFirstName');
    setValidator('borrowerLastName');
    setValidator('coBorrowerFirstName');
    setValidator('coBorrowerLastName');
    setValidator('signingAddress1');
    setValidator('signingCity');
    setValidator('signingState');
    setValidator('signingZipCode');
    setValidator('dayPhone');

    return this;
  };

  isFormValid = () => {
    let errors = [];
    Object.getOwnPropertyNames(this.inputs).forEach((val) => errors.push(this.inputs[val]));

    let invalidForm = errors.find(({ isValid }) => isValid === false) ? true : false;
    return !invalidForm;
  };

  getErrors = () => {
    return {
      ...Object.fromEntries(Object.entries(this.inputs).map(([key, value]) => [key, value.isValid ? '' : value.error])),
    };
  };

  destroyAll() {
    // Nullify properties
    this.requiredFields = null;
    this.inputs = null;
  }
}

export class AltLocationValidator {
  requiredFields = null;
  inputs = {
    altLoc_Name: { isValid: true, error: '' },
    altLoc_Add1: { isValid: true, error: 'Signing Location Address' },
    altLoc_Add2: { isValid: true, error: '' },
    altLoc_City: { isValid: true, error: 'Signing Location City ' },
    altLoc_State: { isValid: true, error: 'Signing Location State' },
    altLoc_Zip: { isValid: true, error: 'Signing Location Zip Code' },
  };

  constructor(payload, required = null) {
    this.requiredFields = required;
    this.setter(payload);
  }

  setter = (payload) => {
    Object.assign(this.inputs.altLoc_Name, { value: payload?.altLoc_Name });
    Object.assign(this.inputs.altLoc_Add1, { value: payload?.altLoc_Add1 });
    Object.assign(this.inputs.altLoc_Add2, { value: payload?.altLoc_Add2 });
    Object.assign(this.inputs.altLoc_City, { value: payload?.altLoc_City });
    Object.assign(this.inputs.altLoc_State, { value: payload?.altLoc_State });
    Object.assign(this.inputs.altLoc_Zip, { value: payload?.altLoc_Zip });
  };

  validateForm = () => {
    const validate = (property) => {
      let pass1 = this.inputs[property].value?.trim() ? 1 : 0;
      let pass2 = this.requiredFields ? (this.requiredFields[property] ? 1 : 0) : 1;

      if (pass2 == 0) return true;
      return pass1 + pass2 == 2 ? true : false;
    };

    const setValidator = (property, preComputed = null, customError = '') => {
      this.inputs[property] = {
        ...this.inputs[property],
        isValid: preComputed !== null ? preComputed : validate(property),
        error: customError ? customError : this.inputs[property].error,
      };
    };
    setValidator('altLoc_Name');
    setValidator('altLoc_Add1');
    setValidator('altLoc_Add2');
    setValidator('altLoc_City');
    setValidator('altLoc_State');
    setValidator('altLoc_Zip');
  };

  isFormValid = () => {
    let errors = [];
    Object.getOwnPropertyNames(this.inputs).forEach((val) => errors.push(this.inputs[val]));

    let invalidForm = errors.find(({ isValid }) => isValid === false) ? true : false;
    return !invalidForm;
  };

  getErrors = () => {
    return {
      ...Object.fromEntries(Object.entries(this.inputs).map(([key, value]) => [key, value.isValid ? '' : value.error])),
    };
  };

  destroyAll() {
    // Nullify properties
    this.requiredFields = null;
    this.inputs = null;
  }
}

export class AppointmentValidator {
  modelType = null;
  requiredFields = null;
  inputs = {
    numOfLoans: { isValid: true, error: 'Number of Loans' },
    signingDate: { isValid: true, error: 'Signing Date' },
    signingTime: { isValid: true, error: 'Signing Time' },
    loanNumber: { isValid: true, error: 'Loan Number' },
    willSendDocsVia: { isValid: true, error: 'Send Documents Via' },
    contactName: { isValid: true, error: 'Contact Name' },
    contactPhone: { isValid: true, error: 'Contact Phone' },
    contactEmail: { isValid: true, error: 'Contact Email' },
    schedulingNotes: { isValid: true, error: '' },
  };

  constructor(payload, required = null) {
    this.requiredFields = required;
    this.setter(payload);
  }

  setter = (payload) => {
    Object.assign(this.inputs.numOfLoans, { value: payload?.numOfLoans });
    Object.assign(this.inputs.signingDate, { value: payload?.signingDate });
    Object.assign(this.inputs.signingTime, { value: payload?.signingTime });
    Object.assign(this.inputs.loanNumber, { value: payload?.loanNumber });
    Object.assign(this.inputs.willSendDocsVia, { value: payload?.willSendDocsVia });
    Object.assign(this.inputs.contactName, { value: payload?.contactName });
    Object.assign(this.inputs.contactPhone, { value: payload?.contactPhone });
    Object.assign(this.inputs.contactEmail, { value: payload?.contactEmail });
    Object.assign(this.inputs.schedulingNotes, { value: payload?.schedulingNotes });
  };

  validateForm = () => {
    const validate = (property) => {
      let pass1 = this.inputs[property].value?.trim() ? 1 : 0;
      let pass2 = this.requiredFields ? (this.requiredFields[property] ? 1 : 0) : 1;

      if (pass2 == 0) return true;
      return pass1 + pass2 == 2 ? true : false;
    };

    const setValidator = (property, preComputed = null, customError = '') => {
      this.inputs[property] = {
        ...this.inputs[property],
        isValid: preComputed !== null ? preComputed : validate(property),
        error: customError ? customError : this.inputs[property].error,
      };
    };

    let signingTimeVal = this.inputs.signingTime.value;
    let schedulingNotesVal = this.inputs.schedulingNotes.value;
    let contactEmailVal = this.inputs.contactEmail.value;

    setValidator('numOfLoans');
    setValidator('signingDate');
    setValidator('signingTime', Boolean(parseStringTime(signingTimeVal) !== null || schedulingNotesVal));
    setValidator('loanNumber');
    setValidator('willSendDocsVia');
    setValidator('contactName');
    setValidator('contactPhone');
    setValidator('contactEmail', isValidEmail(contactEmailVal) && this.requiredFields.contactEmail);
  };

  isFormValid = () => {
    let errors = [];
    Object.getOwnPropertyNames(this.inputs).forEach((val) => errors.push(this.inputs[val]));

    let invalidForm = errors.find(({ isValid }) => isValid === false) ? true : false;
    return !invalidForm;
  };

  getErrors = () => {
    return {
      ...Object.fromEntries(Object.entries(this.inputs).map(([key, value]) => [key, value.isValid ? '' : value.error])),
    };
  };

  destroyAll() {
    // Nullify properties
    this.modelType = null;
    this.inputs = null;
  }
}

export class AdditionalContactValidator {
  requiredFields = null;
  inputs = {
    contactName: { isValid: true, error: '' },
    contactPhone: { isValid: true, error: '' },
    contactEmail: { isValid: true, error: 'Additional Contact Email' },
  };

  constructor(payload, required = null) {
    this.requiredFields = required;
    this.setter(payload);
  }

  setter = (payload) => {
    Object.assign(this.inputs.contactName, { value: payload?.contactName });
    Object.assign(this.inputs.contactPhone, { value: payload?.contactPhone });
    Object.assign(this.inputs.contactEmail, { value: payload?.contactEmail });
  };

  validateForm = () => {
    const validate = (property) => {
      let pass1 = this.inputs[property].value?.trim() ? 1 : 0;
      let pass2 = this.requiredFields ? (this.requiredFields[property] ? 1 : 0) : 1;

      if (pass2 == 0) return true;
      return pass1 + pass2 == 2 ? true : false;
    };

    const setValidator = (property, preComputed = null, customError = '') => {
      this.inputs[property] = {
        ...this.inputs[property],
        isValid: preComputed !== null ? preComputed : validate(property),
        error: customError ? customError : this.inputs[property].error,
      };
    };
    setValidator('contactName');
    setValidator('contactPhone');
    setValidator('contactEmail', isValidEmail(this.inputs.contactEmail.value));
  };

  isFormValid = () => {
    let errors = [];
    Object.getOwnPropertyNames(this.inputs).forEach((val) => errors.push(this.inputs[val]));

    let invalidForm = errors.find(({ isValid }) => isValid === false) ? true : false;
    return !invalidForm;
  };

  getErrors = () => {
    return {
      ...Object.fromEntries(Object.entries(this.inputs).map(([key, value]) => [key, value.isValid ? '' : value.error])),
    };
  };

  destroyAll() {
    // Nullify properties
    this.requiredFields = null;
    this.inputs = null;
  }
}

/*
  constructor(type, payload, required = null) {
    this.modelType = type;
    this.requiredFields = required;

    switch (type) {
      case 'borrowerInfo':
        this.borrowerInfo.borrowerFirstName = {
          ...this.borrowerInfo.borrowerFirstName,
          value: payload.borrowerFirstName,
        };
        this.borrowerInfo.borrowerLastName = { ...this.borrowerInfo.borrowerLastName, value: payload.borrowerLastName };
        this.borrowerInfo.coBorrowerFirstName = {
          ...this.borrowerInfo.coBorrowerFirstName,
          value: payload.coBorrowerFirstName,
        };
        this.borrowerInfo.coBorrowerLastName = {
          ...this.borrowerInfo.coBorrowerLastName,
          value: payload.coBorrowerLastName,
        };
        this.borrowerInfo.signingAddress1 = { ...this.borrowerInfo.signingAddress1, value: payload.signingAddress1 };
        this.borrowerInfo.signingCity = { ...this.borrowerInfo.signingCity, value: payload.signingCity };
        this.borrowerInfo.signingState = { ...this.borrowerInfo.signingState, value: payload.signingState };
        this.borrowerInfo.signingZipCode = { ...this.borrowerInfo.signingZipCode, value: payload.signingZipCode };
        this.borrowerInfo.dayPhone = { ...this.borrowerInfo.dayPhone, value: payload.dayPhone };
      default:
        break;
    }
  }
  */
