import React, { useContext, useEffect, useState, useRef } from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { isMobile } from 'react-device-detect';
import { Divider, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { NSDatePicker } from '../../../common/pickers/NSDatePicker';
import FilterBuilder from '../../../common/fancy-filter/FilterBuilder';
import FilterMenuList from '../../../common/fancy-filter/FilterMenuList';
import FilterApplyForm from '../../../common/fancy-filter/FilterApplyForm';
import AppliedFilters from '../../../common/fancy-filter/AppliedFilters';
import SearchButton from '../../../common/fancy-filter/SearchButton';
import { AgentPaymentMgtContext } from '../../../../context/agent-payment-mgt.context';
import { InvoicePaymentsCss } from '../style';
import RegInforForm from './RegInforForm';

import { AntSwitch, Div } from '../../../../Style';
import AppConfirmationDialog from '../../../common/others/AppConfirmationDialog';
import { store } from '../../../../store';

const initialFancyFilter = (from, to, showPaid, hideCanceled, filters) => {
  return {
    from: from,
    to: to,
    showPaid: showPaid,
    hideCanceled: hideCanceled,
    filters: filters,
  };
};

export const AgentPaymentMgtFilter = (props) => {
  const mountedRef = useRef(true);
  const viewport = store.getState().reducer.appViewport;

  const {
    filterPayLoad,
    setFilterPayLoad,
    getAgentPaymentsList,
    appliedFilters,
    setAppliedFilters,
    agentPayments,
    undoCurrentBatch,
    setAgentPaymentsInBatch,
    initialFilterPayload,
  } = useContext(AgentPaymentMgtContext);

  const [fancyFilterData, setFancyFilterData] = useState(
    initialFancyFilter(
      filterPayLoad.from,
      filterPayLoad.to,
      filterPayLoad.showPaid,
      filterPayLoad.hideCanceled,
      appliedFilters
    )
  );

  const [openConfirmation, setOpenConfirmation] = useState({});

  useEffect(() => {
    setFancyFilterData({
      ...fancyFilterData,
      filters: appliedFilters,
    });
    return () => {};
  }, [appliedFilters]);

  const getAgentPaymentsListHandler = async (fancyFilters = []) => {
    let payload = await _.cloneDeep(filterPayLoad);

    fancyFilters.map((filter) => {
      if (!filter.mdBreakpointDown || (filter.mdBreakpointDown && matchMediumAndDown)) {
        if (filter.value && filter.backendValProp && typeof filter.value === 'object') {
          return (payload[filter.id] = filter.value[filter.backendValProp]);
        } else if (filter.value) {
          return (payload[filter.id] = filter.value);
        } else {
          return (payload[filter.id] = initialFilterPayload[filter.id]);
        }
      }
    });

    setFilterPayLoad(payload);
    getAgentPaymentsList(payload);
  };

  const fancyFilterEventHandler = (data, type) => {
    if (type === 'filter' || type === 'delete') {
      let _filtersCopy = [...fancyFilterData.filters];
      _filtersCopy.map((item) =>
        item.id == data.id
          ? ((item.value = data.value),
            (item.defaultValue = data.defaultValue),
            (item.valueLabel = data.valueLabel),
            (item.lastEnteredTimestamp = moment().format('x')))
          : item
      );

      // TODO: Add code to map _filtersCopy to the filters that goes to the API.
      let filterData = { ...fancyFilterData, filters: _filtersCopy };
      setFancyFilterData(filterData);
      setAppliedFilters(filterData.filters);
      getAgentPaymentsListHandler(filterData.filters);
    } else {
      switch (type) {
        case 'to':
          setFancyFilterData({ ...fancyFilterData, to: data });
          setFilterPayLoad({ ...filterPayLoad, to: data });
          break;

        case 'from':
          setFancyFilterData({ ...fancyFilterData, from: data });
          setFilterPayLoad({ ...filterPayLoad, from: data });
          break;

        case 'showPaid':
          setFancyFilterData({ ...fancyFilterData, showPaid: data });
          setFilterPayLoad({ ...filterPayLoad, showPaid: data });
          break;

        // case 'hideCanceled':
        //   setFancyFilterData({ ...fancyFilterData, hideCanceled: data });
        //   setFilterPayLoad({ ...filterPayLoad, hideCanceled: data });
        //   break;
      }
    }
  };

  const getCurrentAppliedFilter = () => {
    if (appliedFilters) {
      return appliedFilters.filter((item) =>
        item.useCustomForm ? item.value : (item.multiple && item.value.length) || (!item.multiple && item.value)
      );
    } else return [];
  };

  const undoActiveBatchHanler = async (value) => {
    if (value) {
      await undoCurrentBatch(false);
      fancyFilterEventHandler(!filterPayLoad.showPaid, 'showPaid');
    }
    setOpenConfirmation({ open: false });
  };

  useEffect(() => {
    if (!mountedRef.current) getAgentPaymentsList(filterPayLoad);
    return () => {
      mountedRef.current = false;
    };
  }, [filterPayLoad.showPaid]);

  const filterBuilder = (
    <FilterBuilder
      onShowOptions={({ ref, setShowOptions }) => (
        <FilterMenuList
          dense
          builderRef={ref}
          options={fancyFilterData.filters.filter(
            (item) =>
              !(item.multiple && (item.useCustomForm ? item.value : item.value.length)) &&
              !(!item.multiple && item.value)
          )}
          onClose={setShowOptions}
          onSelectCustomForm={(props) => {
            return (
              <RegInforForm
                menuRef={props.menuRef}
                form={props.openCustomForm}
                onClose={props.setOpenCustomForm}
                applyFilter={(data) => {
                  setShowOptions(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
          onSelectForm={(props) => {
            return (
              <FilterApplyForm
                menuRef={props.menuRef}
                form={props.openForm}
                onClose={props.setOpenForm}
                onChange={(data) => {
                  setShowOptions(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
        />
      )}
    >
      {getCurrentAppliedFilter().length ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
    </FilterBuilder>
  );

  return (
    <>
      <InvoicePaymentsCss>
        <Div className="fancy-filter">
          <div className="builder">
            {!['xs'].includes(viewport) ? (
              <>
                <div className="search-filter">
                  <Stack className="switch-stack">
                    <AntSwitch
                      inputProps={{ 'aria-label': 'ant design' }}
                      checked={filterPayLoad.showPaid}
                      onChange={async (event) => {
                        let checked = event.target.checked;
                        if (agentPayments.batchId > 0) {
                          setOpenConfirmation((values) => ({
                            msg: `This action will remove the current payment batch or all active selections. Do you want to continue?`,
                            open: true,
                            handler: (val) => undoActiveBatchHanler(val),
                          }));
                        } else {
                          fancyFilterEventHandler(checked, 'showPaid');
                          setAgentPaymentsInBatch([]);
                        }
                      }}
                    />
                    <Typography variant="body2">Show Paid</Typography>
                  </Stack>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 2 }} />
                  <div className="invoice-date-wrapper">
                    <div className="invoice-date no-xs">
                      <NSDatePicker
                        label="From"
                        onChangeValidation
                        emitDate={(date) => fancyFilterEventHandler(date, 'from')}
                      />
                    </div>
                    <div className="invoice-date no-xs">
                      <NSDatePicker
                        label="To"
                        onChangeValidation
                        emitDate={(date) => fancyFilterEventHandler(date, 'to')}
                      />
                    </div>
                    {getCurrentAppliedFilter().length == 0 && <>{filterBuilder}</>}
                  </div>
                </div>
                <div className="search-now-btn">
                  <SearchButton text="Search Now" onClick={() => getAgentPaymentsListHandler()} />
                </div>
              </>
            ) : (
              <div className="search-filter">
                <div className="first-group">
                  <Stack className="switch-stack">
                    <AntSwitch
                      inputProps={{ 'aria-label': 'ant design' }}
                      checked={filterPayLoad.showPaid}
                      onChange={async (event) => {
                        let checked = event.target.checked;
                        if (agentPayments.batchId > 0) {
                          setOpenConfirmation((values) => ({
                            msg: `This action will remove the current payment batch or all active selections. Do you want to continue?`,
                            open: true,
                            handler: (val) => undoActiveBatchHanler(val),
                          }));
                        } else {
                          fancyFilterEventHandler(checked, 'showPaid');
                          setAgentPaymentsInBatch([]);
                        }
                      }}
                    />
                    <Typography variant="body2">Show Paid</Typography>
                  </Stack>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ m: isMobile ? 1 : 2 }} />
                  <div className="search-now-btn">
                    <SearchButton text="Search Now" onClick={() => getAgentPaymentsListHandler()} />
                  </div>
                </div>
                <div style={{ flexBasis: '100%', height: 0 }}></div>
                <div className="invoice-date">
                  <NSDatePicker
                    label="From"
                    renderMobile={true}
                    onChangeValidation
                    emitDate={(date) => {
                      fancyFilterEventHandler(date, 'from');
                    }}
                  />
                </div>
                <div className="invoice-date">
                  <NSDatePicker
                    label="To"
                    renderMobile={true}
                    onChangeValidation
                    emitDate={(date) => {
                      fancyFilterEventHandler(date, 'to');
                    }}
                  />
                </div>
                {getCurrentAppliedFilter().length == 0 && <>{filterBuilder}</>}
              </div>
            )}
          </div>
        </Div>
        {getCurrentAppliedFilter().length > 0 && (
          <AppliedFilters
            style={props.style}
            filters={getCurrentAppliedFilter()}
            onDelete={(data) => fancyFilterEventHandler(data, 'delete')}
            onUpdate={(data) => fancyFilterEventHandler(data, 'filter')}
            onSelectCustomForm={(props) => {
              return (
                <RegInforForm
                  editFilter
                  menuRef={props.menuRef}
                  form={props.form}
                  onClose={() => props.setForm(false)}
                  applyFilter={(data) => {
                    props.setForm(false);
                    fancyFilterEventHandler(data, 'filter');
                  }}
                />
              );
            }}
            onSelectForm={(props) => {
              return (
                <FilterApplyForm
                  editFilter
                  menuRef={props.menuRef}
                  form={props.form}
                  onClose={() => props.setForm(false)}
                  onChange={(data) => fancyFilterEventHandler(data, 'filter')}
                />
              );
            }}
          >
            {filterBuilder}
          </AppliedFilters>
        )}
      </InvoicePaymentsCss>
      {openConfirmation.open && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={openConfirmation.open}
          content={openConfirmation.msg}
          onClose={() => {}}
          confirmCallback={(val) => openConfirmation.handler(val)}
        />
      )}
    </>
  );
};
