import React, { Fragment, useContext, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ContactManagerContext } from '../../context/contact-manager.context';

const StyledBreadcrumb = styled('ul')(
  ({ theme }) => css`
    padding-left: 0px;
    list-style: none;
    display: inline-block;

    .icon {
      /* font-size: 14px; */

      .MuiSvgIcon-root {
        top: 3px;
        position: relative;
      }
    }

    li {
      float: left;

      a {
        color: #fff;
        display: block;
        background: #2c91e7;
        text-decoration: none;
        position: relative;
        height: 30px;
        line-height: 30px;
        padding: 0 10px 0 5px;
        text-align: center;
        margin-right: 23px;
      }

      /* &:nth-child(even) {
        a {
          background-color: red;

          &:before {
            border-color: red;
            border-left-color: transparent;
          }
          &:after {
            border-left-color: red;
          }
        }
      } */

      &:first-child {
        a {
          padding-left: 15px;
          border-radius: 4px 0 0 4px;
          &:before {
            border: none;
          }
        }
      }

      &:last-child {
        pointer-events: none;

        a {
          padding-right: 15px;
          border-radius: 0 4px 4px 0;
          &:after {
            border: none;
          }

          background-color: #0070b9;

          &:before {
            border-color: #0070b9;
            border-left-color: transparent;
          }
        }
      }

      a {
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          border: 0 solid #2c91e7;
          border-width: 15px 10px;
          width: 0;
          height: 0;
        }

        &:before {
          left: -20px;
          border-left-color: transparent;
        }

        &:after {
          left: 100%;
          border-color: transparent;
          border-left-color: #2c91e7;
        }

        &:hover {
          background-color: #1f6fb3;

          &:before {
            border-color: #1f6fb3;
            border-left-color: transparent;
          }
          &:after {
            border-left-color: #1f6fb3;
          }
        }

        &:active {
          background-color: #159135;

          &:before {
            border-color: #159135;
            border-left-color: transparent;
          }
          &:after {
            border-left-color: #159135;
          }
        }
      }
    }
  `
);

const Breadcrumb = (props) => {
  const { breadCrumb = [], setBreadCrumb = () => {} } = useContext(ContactManagerContext) || {};
  const [hide, setHide] = useState(true);
  const [state, setState] = useState([]);

  useEffect(() => {
    let newArray = [];
    let lastRootIndex = breadCrumb.lastIndexOf('/contact-manager');

    if (lastRootIndex == -1) {
      setHide(true);
    } else {
      setHide(false);
      newArray = breadCrumb.filter((i, index) => index >= lastRootIndex);
    }
    setState(newArray);
    return () => {};
  }, [breadCrumb]);

  /**
   *
   * This regex below will exclude the word new
   * /^\/contact-manager\/profile\/[a-zA-Z]+\/(?!.*new$).*[a-zA-Z0-9]+$/;
   */

  const setBreadcrumbObjectForContactManager = (link) => {
    if (/^\/contact-manager$/.test(link)) {
      return { label: 'Contact Manager', stopDataRefresh: true };
    } else if (/^\/contact-manager\/profile\/[a-zA-Z]+\/new$/.test(link)) {
      return { label: 'New' };
    } else if (/^\/contact-manager\/profile\/[a-zA-Z]+\/[a-zA-Z0-9]+$/.test(link)) {
      return { label: 'Profile' };
    }
  };

  const onClick = (link, event) => {
    let clickIndex = state.findIndex((i) => i == link);
    let newState = state.filter((i, index) => index <= clickIndex);
    setBreadCrumb(newState);
    props.breadCrumbEvent(event);
  };

  return (
    <Fragment>
      {!hide ? (
        <StyledBreadcrumb id="breadcrumb">
          <li>
            <Link to={{ pathname: '/Dashboard' }}>
              <span className="icon">
                <DashboardOutlinedIcon />
              </span>
            </Link>
          </li>
          {_.uniq(state).map((link, index) => {
            let object = setBreadcrumbObjectForContactManager(link);
            return object?.label ? (
              <li key={index}>
                <Link to={{ pathname: link }} onClick={(e) => onClick(link, object)}>
                  {object?.label}
                </Link>
              </li>
            ) : (
              ''
            );
          })}
        </StyledBreadcrumb>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default Breadcrumb;
