import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

export const adminToolsService = {
  getTechTools,
  runTechTool,
};

async function getTechTools() {
  const options = { headers: await authHeader(false) };
  const params = {};
  const url = '/api/AdminTools/getTechTools';
  return Fetch.get(url, params, options).then(handleResponse);
}

async function runTechTool(getTable, body) {
  const options = { headers: await authHeader(false), body: JSON.stringify(body) };
  const params = { getTable: getTable };
  const url = '/api/AdminTools/RunTechTool';
  return Fetch.post(url, params, options).then(handleResponse);
}
