import React from 'react';
import { Button, DialogContentText, useMediaQuery } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles, useTheme } from '@mui/styles';
import { F } from '../../helpers/functions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLoadingStore } from '../../context/loading.store';

const useStyles = makeStyles((theme, context) => ({
  root: {
    '& .MuiLoadingButton-loadingIndicator': {
      marginTop: '0',
      right: 'auto',
      marginLeft: '10px',
      position: 'relative',
      color: theme.palette.primary.main,
    },
  },
}));

const ConfirmAgreementModal = ({ open, handleConfirm = F, handleClose = F, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const loading = useLoadingStore((state) => state.loading);

  return (
    <>
      <DialogContent>
        <DialogContentText>
          Please confirm your agreement to the terms and conditions outlined in our agreement by clicking the OK button
          below.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button variant="outlined" disabled={loading} color="info" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton loading={loading} autoFocus variant="contained" color="primary" onClick={handleConfirm}>
          OK
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ConfirmAgreementModal;
