import React, { useState } from 'react';
import { Collapse } from '@mui/material';

export const Show = ({ when, animated = false, children }) => {
  return when ? (
    animated ? (
      <Collapse in timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    ) : (
      <> {children}</>
    )
  ) : null;
};

export default Show;
