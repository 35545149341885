import React, { useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { connect, useSelector } from 'react-redux';
import ScrollToTop from 'react-scroll-to-top';
import { Divider, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

import AppRoutes from './AppRoutes';
import NSLogo from './assets/images/ns_logo-removebg.png';
import Menu from './components/layout/menu/Index';
import ComponentAlert from './components/common/others/ComponentAlert';
import { ErrorBoundary } from './ErrorBoundery';
import moment from 'moment';
import ModuleMgtToolBar from './components/host/modules/ModuleMgtToolBar';

// const AppBarOffset = styled('div')(({ theme }) => theme.mixins.toolbar);

const StyledAppContent = styled('div')(
  ({ theme, $isUserLogged, $menuWidth }) => css`
    display: flex;
    flex-direction: ${$isUserLogged ? 'inherit' : 'column'};
    min-height: ${$isUserLogged ? 'inherit' : '100vh'};

    .MuiAppBar-positionFixed {
      z-index: 1201;
    }

    .MuiAppBar-root {
      padding: 0;
      padding-right: 0 !important;

      .MuiToolbar-root {
        .toolbar-left {
          flex-grow: 1;
          display: flex;
          align-items: center;

          div:first-child {
            margin-right: 15px;
          }
        }
      }
    }

    #page {
      width: 100%;
      margin-top: ${$isUserLogged ? '50px' : '0px'} !important;
      display: block;
      height: calc(100vh - 50px);
    }

    .content {
      padding-bottom: 15px;
      flex: 1;
    }

    #footer {
      display: ${$isUserLogged ? 'none' : 'flex'};
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px 0px;
      font-size: smaller;
      /* background: linear-gradient(rgb(245 245 245), rgb(35 35 35 / 50%));
      border-top: 2px solid rgb(225 225 225); */

      .logo {
        display: flex;
        align-items: center;
        font-family: cursive;
        font-size: 1rem;

        img {
          height: 60px;
        }
      }

      .ns-com-link {
        color: ${theme.palette.primary.main};
        text-decoration: none;
      }

      .ns-com-link:hover {
        text-decoration: underline;
      }

      .ns-com-link:visited {
        color: ${theme.palette.primary.main};
      }

      .legal-info {
        display: flex;
        justify-content: center;
        margin: 10px 0px 10px;

        a {
          /* font-size: 0.725rem; */
        }

        hr {
          margin: 0;
          margin-left: 4px;
          margin-right: 5px;
        }
      }
    }
  `
);

const AppContent = ({ store: store, ...props }) => {
  const { global, currentUser } = store;

  return (
    <StyledAppContent $isUserLogged={store.accessToken.token ? true : false} $menuWidth={store.menuState.width}>
      <Menu />
      <div id="page">
        {currentUser.userRoles.includes('SuperUser') && (
          <ModuleMgtToolBar {...global} reloadView={() => window.location.reload()} />
        )}
        <main className="content">
          {['md', 'lg', 'xl'].includes(store.appViewport) && <ScrollToTop smooth top={500} />}

          <ErrorBoundary appRoot>
            {store.appAlerts.length > 0 && <ComponentAlert menuState={store.menuState} />}
            {/* <AppBarOffset /> */}
            <AppRoutes />
          </ErrorBoundary>
        </main>
      </div>
      {!store.accessToken.token && (
        <footer id="footer">
          {/* <div className="logo">
          <img src={NSLogo} alt="coming soon..." />{' '}
          <span>
            Sign More in Less Time.
            <br />
            Enjoy Life!
          </span>
        </div> */}
          <div className="center-info">
            <Typography sx={{ fontSize: 'inherit' }}>
              © {moment().year()} - Powered by{' '}
              <a href="https://www.notarysoftware.com" target="_blank" rel="noreferrer" className="ns-com-link">
                NotarySoftware.com
              </a>
            </Typography>
          </div>
          <div className="legal-info">
            <Link
              component={RouterLink}
              color="primary"
              underline="hover"
              to="/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms Of Use
            </Link>
            <Divider sx={{ m: 1 }} orientation="vertical" flexItem></Divider>
            <Link
              component={RouterLink}
              color="primary"
              underline="hover"
              to="/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Statement
            </Link>
          </div>
        </footer>
      )}
    </StyledAppContent>
  );
};

const mapStateToProps = (state) => ({ store: state.reducer });
export default connect(mapStateToProps)(AppContent);
