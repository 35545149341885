import React, { Fragment } from 'react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import Simple from './forms/Simple';
import { DropDown } from './forms/DropDown';

const FilterApplyForm = (props) => {
  const { menuRef, form, onChange, onClose, editFilter } = props;

  const handleFormEvent = (event) => {
    if (event) onChange({ ...form, ...event });
    onClose();
  };

  return (
    <Popper
      sx={{ zIndex: 1 }}
      transition
      open={true}
      anchorEl={menuRef?.current || menuRef}
      // placement={editFilter ? 'bottom' : 'left-start'}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={{ width: '300px' }}>
            <Fragment>
              {form.type === 'input' && (
                <Simple {...form} applyFilter={handleFormEvent} />
              )}
              {form.type === 'dropdown' && (
                <DropDown {...form} applyFilter={handleFormEvent} />
              )}
            </Fragment>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default FilterApplyForm;
