import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const AgentConfirmation = (props) => {
  const [moreOptionAnchorEl, setMoreOptionAnchorEl] = useState(null);
  const moreOptionOpen = Boolean(moreOptionAnchorEl);

  return (
    <>
      <IconButton
        sx={props.sx}
        size="small"
        aria-expanded={'true'}
        aria-haspopup="true"
        onClick={(event) => setMoreOptionAnchorEl(event.currentTarget)}
      >
        {props.vertical ? (
          <MoreVertOutlinedIcon fontSize="small" />
        ) : (
          <MoreHorizOutlinedIcon fontSize="small" />
        )}
      </IconButton>
      <Menu
        id="agent-appt-comfirmation"
        sx={{
          '.MuiMenuItem-root:not(.Mui-disabled,.dont-inherit)': {
            paddingLeft: '35px',
          },
        }}
        MenuListProps={{
          style: {},
        }}
        anchorEl={moreOptionAnchorEl}
        open={moreOptionOpen}
        onClose={() => setMoreOptionAnchorEl(null)}
      >
        <MenuItem
          className="dont-inherit"
          onClick={(e) => {
            props.onUpdate(!props.confirmed);
            setMoreOptionAnchorEl(null);
          }}
        >
          <ListItemIcon>
            {props.confirmed ? (
              <CheckBoxOutlinedIcon fontSize="small" color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>Appointment Confirmed</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AgentConfirmation;
