import React, { useState, useEffect, Fragment } from 'react';
import { Button, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import StaffDDL from './StaffDDL';
import { getJsonOr } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',

    '& .MuiDialog-root > div > div': {
      '@media screen and (max-width: 600px)': {
        margin: '0px !important',
      },
    },

    '& .staffDDL': {
      minWidth: '300px',
    },

    '& .kaWxti': {
      margin: '0px !important',
    },

    [theme.breakpoints.down('sm')]: {},
  },
}));

const AssignStaffDialog = (props) => {
  const classes = useStyles();
  const { onClose, open, request } = props;

  const staff = getJsonOr(props.request.assignedStaff);
  const assignedStaff = staff ? staff.shift() : null;
  const [selectedStaff, setSelectedStaff] = useState(assignedStaff);

  useEffect(() => {
    return () => {
      setSelectedStaff(assignedStaff);
    };
  }, [open]);

  const handleClose = (event) => {
    event.preventDefault();
    let btnEvent = event.target.textContent;
    switch (btnEvent) {
      case 'Cancel':
        onClose('cancel');
        return;
      case 'Assign':
        onClose(selectedStaff);
        return;
      default:
    }
  };

  return (
    <Dialog
      aria-labelledby="assign-staff-dialog"
      className={classes.root}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="assign-staff-dialog">Assign Staff</DialogTitle>
      <DialogContent dividers>
        <div className="staffDDL">
          <StaffDDL
            isWizard
            ddlType="Detail"
            value={selectedStaff}
            onChange={setSelectedStaff}
            request={request}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={selectedStaff !== null ? false : true}
          onClick={handleClose}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignStaffDialog;
