import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const sharedService = {
  logClientError: async function (data) {
    const options = { headers: await authHeader(), body: JSON.stringify(data) };
    const params = {};
    const url = '/api/shared/LogClientError';
    return Fetch.post(url, params, options).then(handleResponse);
  },
};

export { sharedService };
