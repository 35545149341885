import React from 'react';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root:nth-child(n+1)': {
      padding: '20px',
    },

    '& .MuiSkeleton-root': {
      // transform: 'scale(1, 1)',
    },
  },

  RequestAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .buttons': {
      display: 'flex',
      gap: '10px',
    },
  },

  RequestInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  BorrowerInfo: {
    '& .form-inputs': {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gridGap: '20px',
      fontSize: '15px',

      '& .main-info': {
        zIndex: '0',
        fontSize: '15px',
        columnGap: '15px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: `"borrowerfirst borrowerlast"
        "coborrowerfirst coborrowerlast"
        "addr1 addr1"
        "addr2 addr2"
        "city city"`,
      },

      '& .addr1': {
        gridArea: 'addr1',
      },
      '& .addr2': {
        gridArea: 'addr2',
      },
      '& .city': {
        gridArea: 'city',
      },
    },

    '& label': {
      fontSize: 'inherit',
    },

    [theme.breakpoints.down('sm')]: {
      '& .form-inputs': {
        display: 'block',
      },
    },
  },

  SigningAltLocation: {
    '& .form-inputs': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '15px',
    },

    '& .note': {
      textAlign: 'center',
      fontSize: '0.75rem',
    },

    '& label': {
      fontSize: 'inherit',
    },

    [theme.breakpoints.down('sm')]: {
      '& .form-inputs': {
        display: 'block',
      },
    },
  },

  SigningInfo: {
    '& .loan-types': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    '& .left-right-wrapper': {
      display: 'grid',
      gridGap: '15px',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `"left-section right-section"`,
      marginTop: '15px',

      '& .left-section': {
        gridArea: 'left-section',

        '& .MuiFormControl-root': {
          marginBottom: '13px',
        },
      },

      '& .right-section': {
        gridArea: 'right-section',

        '& .MuiFormControl-root': {
          marginBottom: '13px',
        },
      },
    },

    '& .shipperName': {
      gridGap: '15px',
      width: '100%',

      '& div:nth-child(1)': {
        minWidth: '43%',
      },
      '& div:nth-child(2)': {
        minWidth: 'calc(58% - 19px)',
        fontSize: '15px',
      },
    },
  },

  InvoiceConctactInfo: {
    '& .wrapper-section': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '20px',
    },
  },
}));

const SigningRequestFormLoading = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color="default" />
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <Typography variant="subtitle2" color="default">{`Loading form data...`}</Typography>
        </Box>
      </Box>
      <div className={classes.RequestAction}>
        <div className="view-title">Signing Request</div>
        <div className="buttons">
          <Skeleton variant="rounded" width={80} height={35} />
          <Skeleton variant="rounded" width={80} height={35} />
          <Skeleton variant="rounded" width={80} height={35} />
        </div>
      </div>
      <Card className={classes.RequestInfo}>
        <Skeleton variant="text" width="20%" />
        <Skeleton variant="text" width="20%" />
        <Skeleton variant="text" width="20%" />
      </Card>
      <br />
      <Card className={classes.BorrowerInfo}>
        <div className="comp-title">Borrower Information</div>
        <div className="form-inputs">
          <div className="main-info">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </div>
          <div className="phones-other">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </Card>
      <br />
      <Card className={classes.SigningAltLocation}>
        <div className="comp-title">Signing Location (If different than above)</div>
        <p className="comp-notes">
          Use the below section to specify the location of the signing if different than the above address.
        </p>
        <div className="form-inputs">
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </div>
        <br />
        <p className="note">
          Note: If any of the address is entered in this section, the zip code will be required to be input as well.
        </p>
      </Card>
      <br />
      <Card className={classes.SigningInfo}>
        <div className="comp-title">Signing Information</div>
        <div className="left-right-wrapper">
          <div className="left-section">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <br />
            <div className="comp-sub-title">Loan Documents Information</div>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <p className="comp-input-notes">Package Tracking (if applicable)</p>
            <div className="shipperName">
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </div>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <br />
            <div className="comp-sub-title">Loan/Escrow Company Information</div>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </div>
          <div className="right-section">
            <div className="comp-sub-title">Return Shipping Information</div>
            <div className="shipperName">
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </div>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <br />
            <div className="comp-sub-title">Return Shipping Instructions</div>
            <Skeleton variant="rectangular" height={70} />
            <br />
            <div className="comp-sub-title">Signing Instructions</div>
            <Skeleton variant="rectangular" height={70} />
          </div>
        </div>
      </Card>
      <br />
      <Card className={classes.InvoiceConctactInfo}>
        <div className="comp-title">Invoice Contact Information</div>
        <p className="comp-notes">
          Use the below section to specify where the invoice is to be sent for this signing (if different than above).
        </p>
        <div className="wrapper-section">
          <div className="left-section">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rectangular" height={70} />
          </div>
          <div className="right-section">
            <p className="comp-sub-title">Emails to Send Invoice To</p>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </div>
        </div>
      </Card>
      <br />
      {/* <CompletionInfo /> */}
      {/* <BillingRequest /> */}
    </div>
  );
};

export default SigningRequestFormLoading;
