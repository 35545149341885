import React, { Fragment, useContext } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
} from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { currencyFormat } from '../../../helpers/currency-format';
import { AgentPaymentMgtContext } from '../../../context/agent-payment-mgt.context';

export const AgentPaymentMgtParent = ({ summaryItem: data }) => {
  const { openChild, selectRow } = useContext(AgentPaymentMgtContext);

  return (
    <ListItem className="header">
      <ListItemText
        className="body-name-cell parent-cell"
        primary={
          <Fragment>
            <div className="body-name-check">
              <div>
                <Checkbox
                  size="small"
                  className="check-all"
                  checked={data.selected}
                  onChange={() => selectRow(data)}
                />
              </div>
            </div>
            <div className="body-name-avatar">
              <Avatar
                className="body-name-avatar-img"
                src={
                  data.publicId
                    ? `https://notarycafe.com/Content/profile/${data.publicId}_1.png`
                    : ''
                }
              />
            </div>
            <div className="body-name">
              <div className="body-name-top">
                {data.fullName + ' - ' + data.requestCount + ' Request(s)'}
              </div>
              <div className="body-name-bottom">
                <label
                  style={{
                    top: '2px',
                    position: 'relative',
                    paddingRight: '10px',
                  }}
                >
                  Agent Docs:
                </label>{' '}
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={data.reg_W9}
                      name="w9"
                      size="small"
                      color="default"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    />
                  }
                  label={<span className="body-name-check-label">W9</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={data.reg_OC}
                      name="oc"
                      size="small"
                      color="default"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    />
                  }
                  label={<span className="body-name-check-label">OC</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={data.reg_HC}
                      name="hc"
                      size="small"
                      color="default"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    />
                  }
                  label={<span className="body-name-check-label">HC</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={data.reg_C1}
                      name="c1"
                      size="small"
                      color="default"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    />
                  }
                  label={
                    <span className="body-name-check-label">
                      {data.regCustomInitials1}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={data.reg_C2}
                      name="c2"
                      size="small"
                      color="default"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    />
                  }
                  label={
                    <span className="body-name-check-label">
                      {data.regCustomInitials2}
                    </span>
                  }
                />
              </div>
            </div>
          </Fragment>
        }
      />
      <ListItemText
        className="body-total-cell col"
        primary={
          <div className="body-name-top">
            {currencyFormat(data.totalPayable)}
          </div>
        }
      />
      <ListItemText
        className="body-total-cell col"
        primary={
          <div className="body-name-top">{currencyFormat(data.totalPaid)}</div>
        }
      />
      <ListItemText
        className="body-total-cell"
        primary={
          <div className="body-name-top">
            {currencyFormat(data.totalBalance)}
          </div>
        }
      />
      <ListItemText
        className="toggle"
        primary={
          <div>
            <IconButton onClick={() => openChild(data)}>
              {data.open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </div>
        }
      />
    </ListItem>
  );
};
