import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react';
import { TextField, Checkbox, Link, Box, CircularProgress, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import moment from 'moment';
import CircularProgressWithLabel from '../others/CircularProgressWithLabel';
import { FileUploadContext } from './FileUploadContext';
import { UploadFilesService as service } from '../../../services';
import { dispatchRelativeAlert, nsRelativeAlert } from '../../../helpers';
import { debounce } from 'lodash';
import AppConfirmationDialog from '../others/AppConfirmationDialog';

export const UploadFilesItem = ({ newFileSelected, columns, row, editCallback, deleteCallback }) => {
  const {
    state,
    setState,
    signalController,
    startUploadFromChild,
    counterDispatch,
    collectFileError,
    setCollectFileError,
    uploadFiles,
    uploadFilesWithProgress,
    setFilesForLogging,
    fileContext,
    canEdit,
    setIsSubmitting,
    setStartUploadFromChild,
  } = useContext(FileUploadContext);

  const [file, setFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, serError] = useState('');
  const debounceFn = useCallback(debounce(handleDebounceFn, 1800), []);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  useEffect(() => {
    setFile(row);
    return () => {};
  }, []);

  useEffect(() => {
    if (startUploadFromChild && newFileSelected) itemUploadFile();
    return () => {};
  }, [startUploadFromChild]);

  function handleDebounceFn(file) {
    if (fileContext.uploadDocType == 'SigningFiles') {
      if (!file.isLoan && !file.isTitle && !file.isOthers) dispatchRelativeAlert('Docs Types are required.', true);
      else UpdateMetaFile(file);
    } else {
      UpdateMetaFile(file);
    }
  }

  const UpdateMetaFile = (file) => {
    service.UpdateMetaFile(fileContext, fileContext.requestID, file).then(
      (res) => {
        dispatchRelativeAlert('Document information was updated successfully.');
      },
      (error) => {
        dispatchRelativeAlert(error, true);
      }
    );
  };

  const validateSavedFile = (file) => {
    debounceFn(file);
  };

  const displayField = (col) => {
    if (!col.context || col.context == fileContext.uploadDocType)
      switch (col.type) {
        case 'Link':
          return (
            <div style={{ texAlign: 'left' }}>
              {file.isExternalPath ? (
                <Link
                  sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                  href={file.path}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  <LinkIcon />
                  {file?.displayName || file[col.field]}
                </Link>
              ) : (
                <Link
                  sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                  className="link"
                  underline="hover"
                  component="button"
                  onClick={() => service.downloadFile(fileContext, file.docId)}
                >
                  <DownloadIcon />
                  {file?.displayName || file[col.field]}
                </Link>
              )}
            </div>
          );

        case 'TextField':
          return (
            <Fragment>
              <Tooltip title={file[col.field]}>
                <TextField
                  sx={{ input: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                  className="input-description"
                  value={file[col.field] || ''}
                  disabled={!canEdit || !state.settings.enableFileEdition}
                  onChange={(e) => {
                    file[col.field] = e.target.value;
                    setFile((values) => ({
                      ...values,
                      [col.field]: e.target.value,
                    }));
                    editCallback ? editCallback(file) : validateSavedFile(file);
                  }}
                />
              </Tooltip>
            </Fragment>
          );

        case 'Checkbox':
          return (
            <Fragment>
              <Checkbox
                checked={file[col.field] || false}
                disabled={!canEdit || !state.settings.enableFileEdition}
                onChange={(e) => {
                  e.stopPropagation();
                  file[col.field] = e.target.checked;
                  setFile({
                    ...file,
                    [col.field]: e.target.checked,
                  });
                  editCallback ? editCallback(file) : validateSavedFile(file);
                }}
                name={col.field.toString()}
                size="small"
              />
            </Fragment>
          );

        case 'DateTime':
          return (
            <Fragment>
              {/* {moment(file[col.field]).format('MM/DD/YYYY h:mm A')} */}
              {moment(file[col.field]).format('MM/DD/YYYY')}
            </Fragment>
          );

        // case 'Size':
        //   return (
        //     <Fragment>
        //       {file[col.field] > 0 ? (
        //         <>{newFileSelected ? Math.round(file[col.field] / 1000) : file[col.field]} K</>
        //       ) : (
        //         <LinkOutlinedIcon />
        //       )}
        //     </Fragment>
        //   );

        case 'LabelName':
          return (
            <div className="progress-name">
              {isUploading ? (
                progress >= 0 &&
                progress <= 100 && (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgressWithLabel value={progress} />
                  </Box>
                )
              ) : (
                <>
                  {progress == 100 && <CheckIcon color="success" />}
                  {progress == -1 && (
                    <Tooltip title={error}>
                      <CloseIcon color="error" />
                    </Tooltip>
                  )}
                </>
              )}
              <span className={progress > 0 ? 'text-padding-left' : ''}>{file[col.field]}</span>
            </div>
          );

        default:
          return <Fragment>{file[col.field]}</Fragment>;
      }
  };

  const itemUploadFile = async () => {
    try {
      if (signalController) signalController.abort();

      let newController = new AbortController();
      let signal = newController.signal;
      let _fileMeta = _.cloneDeep(file);
      delete _fileMeta.file;
      delete _fileMeta.lastModified;

      let _file = {
        uploadedFilesMeta: [...new Array(), _fileMeta],
        externalFiles: [],
        uploadedFiles: [...new Array(), file.file],
      };

      setIsUploading(true);

      const handleProggress = (progress) => setProgress(progress);

      if (['SigningFiles', 'ScanBacks'].includes(fileContext.uploadDocType)) {
        try {
          await uploadFilesWithProgress(signal, _file, handleProggress).then(
            (res) => {
              row.hasError = false;
              setFilesForLogging((current) => [...current, _fileMeta?.name]);
              counterDispatch({ type: newFileSelected ? 'setNewUploadCounter' : 'setExternalCounter' });
            },
            (error) => {
              row.hasError = true;
              counterDispatch({ type: newFileSelected ? 'setNewUploadErrorCounter' : 'setExternalErrorCounter' });
              setCollectFileError([...collectFileError, error]);
              nsRelativeAlert(error, 3);
              serError(error);
            }
          );
        } catch (error) {
          // console.log(error);
          setCollectFileError([...collectFileError, error]);
          nsRelativeAlert(error, 3);
          serError(error);
        }
      } else {
        await uploadFiles(signal, _file).then(
          (res) => {
            row.hasError = false;
            dispatchRelativeAlert('Document was uploaded successfully.');
          },
          (error) => {
            dispatchRelativeAlert(error, true);
            row.hasError = true;
            setCollectFileError([...collectFileError, error.message]);
          }
        );
      }
      setStartUploadFromChild(false);
      setIsUploading(false);
      setIsSubmitting(false);
    } catch (error) {
      setStartUploadFromChild(false);
      setIsUploading(false);
      setIsSubmitting(false);
    }
  };

  const itemDeleteFile = async () => {
    setIsDeleting(true);
    await service.deleteFile(fileContext, file.docId).then(
      (res) => {
        const newSavedFiles = state.savedFiles.filter(function (value) {
          return value.docId !== file.docId;
        });
        setState((values) => ({
          ...values,
          savedFiles: [...newSavedFiles],
        }));
        dispatchRelativeAlert('Document was deleted successfully.');
      },
      (error) => {
        if (error?.title == 'Bad Request') {
          dispatchRelativeAlert('A problem was  found while deleting the file', true);
        } else dispatchRelativeAlert(error, true);
      }
    );
    setIsDeleting(false);
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteConfirmation = (val) => {
    if (val) itemDeleteFile();
    else setOpenDeleteConfirmation(false);
  };

  return file ? (
    <Fragment>
      {openDeleteConfirmation && (
        <AppConfirmationDialog
          id="appDeleteConfirmationDialog"
          open={openDeleteConfirmation}
          title={'Delete uploaded documents'}
          content={'Do you really want to delete this file?'}
          onClose={() => {}}
          confirmCallback={(val) => handleDeleteConfirmation(val)}
        />
      )}
      {columns?.map((column, index) => (
        <div key={index} style={{ flex: column.flex, display: column.display }} className="col">
          {displayField(column)}
        </div>
      ))}
      <div style={{ flex: 0.5 }} className="col">
        {!isDeleting ? (
          <IconButton
            onClick={() => {
              deleteCallback ? deleteCallback(file) : setOpenDeleteConfirmation(true);
            }}
            disabled={(!canEdit || !state.settings.enableFileDeletion) && !newFileSelected}
          >
            <DeleteIcon fontSize="medium" className="icon-color" />
          </IconButton>
        ) : (
          <Box>
            <CircularProgress size={'1rem'} />
          </Box>
        )}
      </div>
    </Fragment>
  ) : (
    <></>
  );
};
