import React, { useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { Badge, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { CreateNewFolder, Folder, NoteAddOutlined, NoteOutlined } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import { store } from '../../../store';
import { FileCounter } from '../../../Style';

const StyledComponent = styled('div')(({ theme }) => css``);

const MoreOptions = ({
  request,
  horizontal,
  vertical,
  requestLog,
  appointmentDialog,
  signingUploader,
  scanBacksUploader,
  assignStaffDialog,
}) => {
  const history = useHistory();
  const userRoles = store.getState().reducer.currentUser.userRoles;
  const [moreOptionAnchorEl, setMoreOptionAnchorEl] = useState(null);
  const moreOptionOpen = Boolean(moreOptionAnchorEl);

  const isCustomerOrAgent = () => {
    return userRoles.includes('Customer') || userRoles.includes('NotaryAgent');
  };

  const quickConfirmations = [
    <MenuItem key="1" sx={{ fontWeight: 600, opacity: '1 !important' }} disabled onClick={(e) => {}}>
      Notification
    </MenuItem>,
    <MenuItem
      key="2"
      onClick={(e) => {
        appointmentDialog({ type: 'Confirm', icon: <SendOutlinedIcon className="rotate" />, request: request });
        setMoreOptionAnchorEl(null);
      }}
    >
      Confirm
    </MenuItem>,
    <MenuItem
      key="3"
      onClick={(e) => {
        appointmentDialog({
          type: 'Complete',
          icon: <PlaylistAddCheckOutlinedIcon />,
          request: request,
          moreOnSend: ['Send and Invoice'],
        });
        setMoreOptionAnchorEl(null);
      }}
    >
      Mark Complete
    </MenuItem>,
    <MenuItem
      key="4"
      onClick={(e) => {
        appointmentDialog({ type: 'NotaryNote', icon: <EventNoteOutlinedIcon />, request: request });
        setMoreOptionAnchorEl(null);
      }}
    >
      Notary Note
    </MenuItem>,
  ];

  const billingButtons = [
    !isCustomerOrAgent() && (
      <MenuItem key="0" sx={{ fontWeight: 600, opacity: '1 !important' }} disabled>
        Billing
      </MenuItem>
    ),
    !(userRoles.includes('NotaryAgent') || (userRoles.includes('Customer') && !request.invoiced)) && (
      <Tooltip
        key="1"
        title={request.invoiceCreated ? (request.invoiced ? 'Invoiced' : 'Invoice Created') : 'To Invoice'}
        disableHoverListener={userRoles.includes('Customer') ? true : false}
        disableFocusListener={userRoles.includes('Customer') ? true : false}
      >
        <MenuItem onClick={(e) => history.push(`/manage-request/invoice/customer/${request.requestID}`)}>
          Invoice
        </MenuItem>
      </Tooltip>
    ),
    !isCustomerOrAgent() && (
      <Tooltip
        key="2"
        sx={{ cursor: 'pointer' }}
        title={request.customerPaid ? 'Invoice Paid' : 'Record Invoice Payment'}
        disableHoverListener={userRoles.includes('Customer') ? true : false}
        disableFocusListener={userRoles.includes('Customer') ? true : false}
      >
        <MenuItem
          onClick={(e) => {
            history.push(`/manage-request/invoice/customer/payment/${request.requestID}`);
          }}
        >
          Receive Payment
        </MenuItem>
      </Tooltip>
    ),
    !isCustomerOrAgent() && (
      <Tooltip
        key="3"
        title={
          request.notaryPaid ? `Paid Agent $${request.notaryInvoiceTotal}` : `Pay Agent $${request.notaryInvoiceTotal}`
        }
        disableHoverListener={userRoles.includes('Customer') ? true : false}
        disableFocusListener={userRoles.includes('Customer') ? true : false}
      >
        <MenuItem
          onClick={(e) => {
            history.push(`/manage-request/invoice/agent/payment/${request.requestID}`);
          }}
        >
          Pay Agent
        </MenuItem>
      </Tooltip>
    ),
  ];

  const parseHudDocsFlag = () => {
    let stringArray = request.hudDocsFlag.split('');
    return request.fileCount < 1 ? (
      <>
        {stringArray[0] == '1' && (
          <Badge badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>L</FileCounter>
          </Badge>
        )}
        {stringArray[1] == '1' && (
          <Badge badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>T</FileCounter>
          </Badge>
        )}
        {request.hudDocsFlag == '000' && <Badge badgeContent={0} showZero color="primary" />}
      </>
    ) : (
      <Badge badgeContent={request.fileCount} showZero color="primary" />
    );
  };

  return (
    <StyledComponent>
      <IconButton
        size="medium"
        aria-expanded={moreOptionOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event) => setMoreOptionAnchorEl(event.currentTarget)}
      >
        {vertical && <MoreVertOutlinedIcon fontSize="small" />}
        {horizontal && <MoreHorizOutlinedIcon fontSize="small" />}
      </IconButton>
      <Menu
        id="request-mobile-menu-item"
        sx={{
          '.MuiMenuItem-root:not(.Mui-disabled,.dont-inherit)': {
            paddingLeft: '35px',
          },
        }}
        PaperProps={{ style: { width: '25ch' } }}
        MenuListProps={{
          style: {},
        }}
        anchorEl={moreOptionAnchorEl}
        open={moreOptionOpen}
        onClose={() => setMoreOptionAnchorEl(null)}
      >
        {requestLog && (
          <MenuItem
            className="dont-inherit"
            onClick={(e) => history.push(`/manage-request/detail/${request.requestID}/log`)}
          >
            <ListItemIcon>
              {request.hasLogNotes === 'N' ? (
                <CreateNewFolder fontSize="small" sx={{ color: '#76a6d5' }} />
              ) : (
                <Folder fontSize="small" sx={{ color: '#76a6d5' }} />
              )}
            </ListItemIcon>
            <ListItemText>Request Log</ListItemText>
          </MenuItem>
        )}
        {signingUploader && (
          <MenuItem
            className="dont-inherit"
            onClick={() => {
              signingUploader(request);
              setMoreOptionAnchorEl(null);
            }}
          >
            <ListItemIcon>
              {request.fileSize < 1 ? (
                <NoteAddOutlined sx={{ color: '#76a6d5' }} />
              ) : (
                <NoteOutlined sx={{ color: '#76a6d5' }} />
              )}
            </ListItemIcon>
            <ListItemText>Documents</ListItemText>
            <div>{parseHudDocsFlag()}</div>
          </MenuItem>
        )}
        {scanBacksUploader &&
          request.scanBackRequired &&
          (!userRoles.includes('NotaryAgent') || (request.signingStatusID <= 4 && !request.scanbacksDocsIn)) && (
            <MenuItem
              className="dont-inherit"
              onClick={() => {
                if (userRoles.includes('NotaryAgent'))
                  history.push('/notary-desk', {
                    defaultPanelTab: 3,
                    requestID: request.requestID,
                  });
                else {
                  scanBacksUploader(request);
                  setMoreOptionAnchorEl(null);
                }
              }}
            >
              <ListItemIcon sx={{ color: 'coral' }}>
                <DocumentScannerOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Scan Backs</ListItemText>
            </MenuItem>
          )}
        {assignStaffDialog && !(userRoles.length === 1 && isCustomerOrAgent()) && (
          <MenuItem
            className="dont-inherit"
            onClick={(e) => {
              assignStaffDialog(true);
              setMoreOptionAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <PersonAddIcon sx={{ color: '#76a6d5' }} />
            </ListItemIcon>
            <ListItemText>{request.staff === '***' ? 'Assign Staff' : `Staff: ${request.staff}`}</ListItemText>
          </MenuItem>
        )}
        {billingButtons.map((menu, index) => menu)}
        <Divider />
        {!isCustomerOrAgent() && quickConfirmations.map((menu, index) => menu)}
      </Menu>
    </StyledComponent>
  );
};

export default MoreOptions;
