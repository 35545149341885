import React, { useContext, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Button, SvgIcon } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';

import { ReactComponent as Default } from '../../../assets/images/storage/default.svg';
import { ReactComponent as AmazonS3Bucket } from '../../../assets/images/storage/amazon-s3.svg';
import { ReactComponent as DropBox } from '../../../assets/images/storage/dropbox.svg';
import { ReactComponent as GoogleDrive } from '../../../assets/images/storage/google-drive.svg';
import { EditableTable } from '../others/EditableTable';

const StyledFileStorage = styled('div')(
  ({ theme }) => css`
    .key-values {
      padding-top: 10px;
    }

    .encrypt-info {
      text-align: initial;
      color: blue;
      font-style: italic;
    }
  `
);

const awsFileStorageCols = [
  {
    id: 1,
    field: 'key',
    type: 'text',
    headerName: 'AWS S3 Bucket Keys',
    editable: false,
    flex: 1,
  },
  {
    id: 2,
    field: 'value',
    headerName: 'Value',
    type: 'text',
    editable: true,
    flex: 1,
  },
];

const awsFileStorageRows = [
  {
    id: 1,
    key: 'BucketName',
    value: '',
  },
  {
    id: 2,
    key: 'FilePath',
    value: '',
  },
  {
    id: 3,
    key: 'AccessKey',
    value: '',
  },
  {
    id: 4,
    key: 'SecretKey',
    value: '',
  },
  {
    id: 5,
    key: 'AWSRegion',
    value: '',
  },
];

const FileStorageOptions = ({ values, emitNewValues }) => {
  const [storageKeyValueArray, setStorageKeyValueArray] = useState([]);
  const [openStorageOptions, setOpenStorageOptions] = useState(false);

  const buttons = [
    <Button
      key="aws"
      sx={{ fontWeight: openStorageOptions == 'AWS_S3' ? 'bolder' : '' }}
      onClick={() => handleOptionSelection('AWS_S3')}
      color={openStorageOptions == 'AWS_S3' ? 'primary' : 'default'}
    >
      <SvgIcon
        sx={{ width: '50px', height: '50px' }}
        inheritViewBox
        component={AmazonS3Bucket}
      />
      AWS S3 Bucket
    </Button>,
    <Button
      key="drop"
      disabled
      sx={{ fontWeight: openStorageOptions == 'DropBox' ? 'bolder' : '' }}
      onClick={() => handleOptionSelection('DropBox')}
      color={openStorageOptions == 'DropBox' ? 'primary' : 'default'}
    >
      <SvgIcon
        sx={{ width: '50px', height: '50px' }}
        inheritViewBox
        component={DropBox}
      />
      Drop Box
    </Button>,
    <Button
      key="google"
      disabled
      sx={{
        fontWeight: openStorageOptions == 'GoogleDrive' ? 'bolder' : '',
      }}
      onClick={() => handleOptionSelection('GoogleDrive')}
      color={openStorageOptions == 'GoogleDrive' ? 'primary' : 'default'}
    >
      <SvgIcon
        sx={{ width: '50px', height: '50px' }}
        inheritViewBox
        component={GoogleDrive}
      />
      Google Drive
    </Button>,
  ];

  useEffect(() => {
    setOpenStorageOptions(values ? values?.FileStorage : false);
    setStorageKeyValueArray(convertObjectToArray());
    return () => {};
  }, []);

  const handleOptionSelection = (storageType) => {
    setOpenStorageOptions(storageType);
    setStorageKeyValueArray(convertObjectToArray(storageType));
  };

  const convertObjectToArray = () => {
    if (values) {
      const entries = Object.entries(values);
      let array = new Array();
      entries.map((item) => {
        if (item[0] == 'FileStorage') {
          array = [
            ...array,
            { key: item[0], value: item[1], lock_value: true },
          ];
        } else {
          array = [...array, { key: item[0], value: item[1] }];
        }
      });
      return array;
    }
    return awsFileStorageRows;
  };

  const handleFileStorageTableUpdate = async (event) => {
    const mapToObject = () => {
      let fileStorage = {
        FileStorage: openStorageOptions,
      };
      event.state.map((item) => {
        fileStorage[item.key] = item.value;
      });
      return fileStorage;
    };

    let json = await mapToObject();
    emitNewValues(json);
  };

  return (
    <StyledFileStorage>
      <ButtonGroup
        size="small"
        color="default"
        variant="text"
        orientation="horizontal"
        aria-label="text button group"
      >
        {buttons}
      </ButtonGroup>
      {openStorageOptions && (
        <div className="key-values">
          <p className="encrypt-info">
            All the entered key/values information will be encrypted in the
            system.
          </p>
          <EditableTable
            addNew
            edit
            columns={awsFileStorageCols}
            rows={storageKeyValueArray}
            emitNewRows={(val) => handleFileStorageTableUpdate(val)}
          />
        </div>
      )}
    </StyledFileStorage>
  );
};

export default FileStorageOptions;
