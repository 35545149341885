import React, { Fragment, useContext, useEffect } from 'react';
import { UploadFilesItem } from './upload-files-item';
import { UploadFilesItemMobile } from './upload-files-item-mobile';
import { StyledSelectedFiles } from './style';
import { store } from '../../../store';
import { FileUploadContext } from './FileUploadContext';

const cols = [
  {
    id: 1,
    field: 'name',
    name: 'Name',
    type: 'LabelName',
    flex: 3,
  },
  {
    id: 2,
    field: 'description',
    name: 'Description',
    type: 'TextField',
    flex: 3,
  },
  {
    id: 3,
    field: 'isLoan',
    name: 'Loan',
    type: 'Checkbox',
    context: 'SigningFiles',
    flex: 0.5,
  },
  {
    id: 4,
    field: 'isTitle',
    name: 'Title',
    type: 'Checkbox',
    context: 'SigningFiles',
    flex: 0.5,
  },
  {
    id: 5,
    field: 'isOthers',
    name: 'Other',
    type: 'Checkbox',
    context: 'SigningFiles',
    flex: 0.5,
  },
  {
    id: 6,
    field: 'lastModified',
    name: 'Uploaded Date',
    type: 'DateTime',
    mobileHidden: true,
    flex: 1.5,
  },
];

export const UploadFilesDialogSelected = (props) => {
  const { selectedFiles, deleteSelectedFile, isSubmitting, editSelectedFile } = props;

  const { fileContext } = useContext(FileUploadContext);
  const viewport = store.getState().reducer.appViewport;

  const getColumns = () => {
    if (fileContext.uploadDocType == 'SigningFiles') {
      return cols.map((el) => {
        if (el.field == 'name') el['mobileHidden'] = true;
        return el;
      });
    } else if (fileContext.uploadDocType == 'ScanBacks') {
      const excludes = ['description', 'isLoan', 'isTitle', 'isOthers', 'size'];
      return cols.filter((item) => !excludes.includes(item.field));
    } else {
      const excludes = ['description', 'isLoan', 'isTitle', 'isOthers', 'size'];
      return cols.filter((item) => !excludes.includes(item.field));
    }
  };

  return (
    <StyledSelectedFiles id="selectedFiles">
      {['xs', 'sm'].includes(viewport) ? (
        selectedFiles?.map((row, index) => (
          <UploadFilesItemMobile
            scanBacks={props.scanbacks}
            newFileSelected
            key={row.docId}
            columns={getColumns()}
            row={row}
            editCallback={(event) => editSelectedFile(index, event)}
            deleteCallback={(event) => deleteSelectedFile(event)}
            isSubmitting={isSubmitting}
          />
        ))
      ) : (
        <React.Fragment>
          <div className="table-header">
            {getColumns().map((column, index) => (
              <div key={index} style={{ flex: column.flex, display: column.display }} className="col">
                <label>{column.name}</label>
              </div>
            ))}
            <div style={{ flex: 0.5 }} className="col">
              <label>{}</label>
            </div>
          </div>
          <div className="table-body">
            {selectedFiles?.map((row, index) => (
              <div key={row.docId} className="row">
                <UploadFilesItem
                  newFileSelected
                  columns={getColumns()}
                  row={row}
                  editCallback={(event) => editSelectedFile(index, event)}
                  deleteCallback={(event) => deleteSelectedFile(row)}
                  isSubmitting={isSubmitting}
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </StyledSelectedFiles>
  );
};
