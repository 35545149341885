import React, { Fragment, useContext, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Badge, Box, Button, ButtonGroup, Divider, Tooltip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { store } from '../../../store';
import { parseStringTime } from '../../../helpers';
import { ManageRequestContext } from '../../../context/manage-request.context';
import { FileCounter, HtmlTooltip, RequestStatus, StyledRouteLink } from '../../../Style';
import AssignAgentCard from './AssignAgentCard';
import MoreOptions from './MoreOptions';
import AgentConfirmation from '../signing-request/AgentConfirmation';

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => css`
    padding: 5px;
    background-color: inherit !important;

    .MuiAccordionSummary-content {
      display: block;
      margin: 5px;
      cursor: default;

      .top-summary {
        display: flex;
        justify-content: space-between;
      }

      .bottom-summary {
        padding: 5px;
        margin-top: 7px;
        background-color: ${theme.palette.mode == 'light' ? '#d3d3d329' : '#12191d'};
        border: 1px solid ${theme.palette.mode == 'light' ? '#efefef40' : '#efefef57'};

        .content-row {
          display: flex;

          > div:nth-child(1) {
            width: 115px;
            font-weight: 500;
            margin-right: 10px;
          }

          > div:nth-child(2) {
            width: 100%;
            display: flex;
          }
        }
      }

      .show-more {
        top: 7px;
      }
    }
  `
);

const AccordionDetailsCardStyled = styled(AccordionDetails)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .agent {
      flex: 1.5;
    }

    .customer {
      flex: 1;
    }
  `
);

const ItemMobile = ({ request, appointmentDialog, signingUploader, scanBacksUploader, assignStaffDialog }) => {
  const { controlSetting, quickSigningUpdate } = useContext(ManageRequestContext);

  const userRoles = store.getState().reducer.currentUser.userRoles;

  const [expanded, setExpanded] = useState(false);

  const isCustomerOrAgent = () => {
    return userRoles.includes('Customer') || userRoles.includes('NotaryAgent');
  };

  const parseHudDocsFlag = () => {
    let stringArray = request.hudDocsFlag.split('');
    return request.fileCount < 1 ? (
      <>
        {stringArray[0] == '1' && (
          <Badge sx={{ margin: '-5px 0px 0px 5px' }} badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>L</FileCounter>
          </Badge>
        )}
        {stringArray[1] == '1' && (
          <Badge sx={{ margin: '-5px 0px 0px 0px' }} badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>T</FileCounter>
          </Badge>
        )}
        {request.hudDocsFlag == '000' && (
          <Badge sx={{ margin: '-5px 10px 0px 15px' }} badgeContent={0} showZero color="primary" />
        )}
      </>
    ) : (
      <Badge sx={{ margin: '-5px 10px 0px 15px' }} badgeContent={request.fileCount} showZero color="primary" />
    );
  };

  return (
    <Accordion
      id="requestAccordion"
      className="mobile"
      aria-controls="requestAccordion"
      expanded={expanded === 'requestAccordion'}
      onChange={(e) => e.stopPropagation()}
    >
      <StyledAccordionSummary aria-controls="requestAccordion">
        <div className="top-summary">
          <Box sx={{ display: 'flex', alignItems: 'left' }}>
            <ButtonGroup
              sx={{
                '.MuiButtonGroup-grouped': { borderRight: '0 !important' },
              }}
              variant="text"
              size="small"
            >
              <Button sx={{ fontWeight: 600 }}>
                <StyledRouteLink className="link2" to={`/manage-request/detail/${request.requestID}`}>
                  {`Request: ${request.requestSeqID}`}
                </StyledRouteLink>
              </Button>
              <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
              <Button onClick={() => signingUploader(request)}>Docs: {parseHudDocsFlag()}</Button>
              {!isCustomerOrAgent() && (
                <>
                  <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
                  <Button onClick={() => assignStaffDialog(request)}>
                    {request.staff}
                    <ManageAccountsIcon fontSize="small" />
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Box>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RequestStatus $colorBase={request.statusColor}>{request.statusNameShort}</RequestStatus>
            <MoreOptions
              vertical
              request={request}
              settings={controlSetting}
              requestLog
              appointmentDialog={(e) => appointmentDialog(e)}
              scanBacksUploader={(e) => scanBacksUploader(request)}
              // signingUploader={(e) => signingUploader(request)}
              // assignStaffDialog={(e) => assignStaffDialog(request)}
            />
          </div>
        </div>
        <div className="bottom-summary">
          <div className="content-row">
            <div>Signer:</div>
            <div>
              <strong style={{ fontSize: '0.85rem' }}>{request.borrowerLastName}</strong>
              {request.borrowerFirstName && <span>{`, ${request.borrowerFirstName}`}</span>}
            </div>
          </div>
          {(request.coBorrowerLastName || request.coBorrowerFirstName) && (
            <div className="content-row">
              <div>CoSigner:</div>
              <div>
                <strong>{request.coBorrowerLastName}</strong>
                {request.coBorrowerFirstName && <span>{`, ${request.coBorrowerFirstName}`}</span>}
              </div>
            </div>
          )}
          <div className="content-row">
            <div>Where:</div>
            <div>{request.cityState}</div>
          </div>
          <div className="content-row">
            <div>
              When:{' '}
              <AgentConfirmation
                sx={{ padding: 0 }}
                confirmed={request.agentAppointmentConfirmed}
                onUpdate={(value) => {
                  let data = {
                    updateType: 'AgentApptConfirm',
                    requestID: request.requestID,
                    requestSeqID: request.requestSeqID,
                    agentAppointmentConfirmed: value,
                  };
                  quickSigningUpdate(request.requestID, data).then((res) => {
                    if (res) request.agentAppointmentConfirmed = value;
                    else request.agentAppointmentConfirmed = false;
                  });
                }}
              />
            </div>
            <div>
              {format(parseISO(request.signingDate), 'MM/dd/yy E')}
              {(parseStringTime(request.signingTime) || request.showUndefinedSigningTime) && (
                <>
                  <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    <AccessTimeOutlinedIcon sx={{ fontSize: '1rem' }} color="default" />
                    {parseStringTime(request.signingTime) ? (
                      request.signingTime
                    ) : (
                      <b style={{ fontSize: '0.95em' }}>{request.schedulingNotes}</b>
                    )}
                  </span>
                </>
              )}
              {!request.showUndefinedSigningTime &&
                (request.schedulingNotes || !parseStringTime(request.signingTime)) && (
                  <>
                    <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
                    <span style={{ display: 'flex' }}>
                      <HtmlTooltip
                        arrow
                        placement="bottom-start"
                        title={
                          <>
                            {request.schedulingNotes ? request.schedulingNotes : ''}
                            {!parseStringTime(request.signingTime) && (
                              <>
                                <br />
                                {request.signingTime}
                              </>
                            )}
                          </>
                        }
                      >
                        <Typography variant="body2" component="span" color="primary">
                          <u>See Notes</u>
                        </Typography>
                      </HtmlTooltip>
                    </span>
                  </>
                )}
              <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
              {request.agentAppointmentConfirmed && (
                <p style={{ display: 'flex' }}>
                  <CheckBoxOutlinedIcon sx={{ fontSize: '1rem' }} color="success" />
                  {'  '}
                  <Typography component="span" color="text.success" sx={{ fontSize: '0.95em', fontWeight: 600 }}>
                    Appt. Confirmed
                  </Typography>
                </p>
              )}
              {/*
                <AgentConfirmation
                  confirmed={request.agentAppointmentConfirmed}
                  onUpdate={(value) => {
                    let data = {
                      updateType: 'AgentApptConfirm',
                      requestID: request.requestID,
                      requestSeqID: request.requestSeqID,
                      agentAppointmentConfirmed: value,
                    };
                    quickSigningUpdate(request.requestID, data).then((res) => {
                      if (res) request.agentAppointmentConfirmed = value;
                      else request.agentAppointmentConfirmed = false;
                    });
                  }}
                />
             */}
              {/* &nbsp;&nbsp;
              {!parseStringTime(request.signingTime) && request.signingTime && (
                <HtmlTooltip arrow title={request.signingTime}>
                  <span>
                    <Link to="#" className="link">
                      See Notes
                    </Link>
                  </span>
                </HtmlTooltip>
              )}
              {parseStringTime(request.signingTime) && (
                <Fragment>
                  <span>{request.signingTime}</span>
                </Fragment>
              )}
              &nbsp;&nbsp;
              {request.schedulingNotes && (
                <Fragment>
                  <HtmlTooltip arrow title={request.schedulingNotes}>
                    <span>
                      <Link to="#" className="link">
                        See Notes
                      </Link>
                    </span>
                  </HtmlTooltip>
                </Fragment>
              )} */}
            </div>
          </div>
        </div>
        <Button
          className="show-more"
          variant="text"
          onClick={(e) => setExpanded(expanded ? false : 'requestAccordion')}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
      </StyledAccordionSummary>
      <AccordionDetailsCardStyled>
        <div
          style={{
            display:
              userRoles.includes('Customer') && controlSetting.chkShowAgentInfoToCustomer === false ? 'none' : 'block',
          }}
          className="agent"
        >
          <AssignAgentCard request={request} setting={controlSetting} />
        </div>
        <div className="customer">
          <p className="">
            <b>Customer:</b>
          </p>
          <p>{request.contactInfo}</p>
          <p>{request.loanOfficerCompany}</p>
          <p>{request.contactPhone}</p>
          <p>
            <a className=" email" href={`mailto:${request.contactEmail}`}>
              {request.contactEmail}
            </a>
          </p>
        </div>
      </AccordionDetailsCardStyled>
    </Accordion>
  );
};

export default ItemMobile;
