import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  AdditionalContactValidator,
  AltLocationValidator,
  AppointmentValidator,
  BorrowerValidator,
} from '../../../context/signing-request.validator';
import { SigningRequestContext } from '../../../context/signing-request.context';
import { dispatchAlertKeepOpen } from '../../../helpers';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import { signingRequestService as service } from '../../../services/signing-request.service';

export const FormContext = createContext(null);

const FormProvider = ({ editOn = false, onSubmit, children }) => {
  const borrowerSectHasRendered = React.useRef(false);
  const altLocationSectHasRendered = React.useRef(false);
  const appointmentSectHasRendered = React.useRef(false);
  const invoiceContactSectHasRendered = React.useRef(false);
  const completionInfoSectHasRendered = React.useRef(false);
  const billingSectHasRendered = React.useRef(false);

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [required, setRequired] = useState({});
  const [editMode, setEditMode] = useState(editOn);
  const [showInputError, setShowInputError] = useState(false);
  const [confirmationHandler, setConfirmationHandler] = useState(false);
  const [confirmationObj, setConfirmationObj] = useState('');
  const [borrowerValidator, setBorrowerValidator] = useState();
  const [altLocationValidator, setAltLocationValidator] = useState();
  const [appointmentValidator, setAppointmentValidator] = useState();
  const [additionalContactValidator, setAdditionalContactValidator] = useState();
  const [sharedDataObject, setSharedDataObject] = useState({});
  const [collectData, setCollectData] = useState(false);

  const { settings, requestID, fromManageRequest, requiredFields, setSettings } = useContext(SigningRequestContext);

  useEffect(() => {
    return () => {
      borrowerValidator?.destroyAll();
      if (settings.pnl_AlternateLocation_Visible) altLocationValidator?.destroyAll();
      appointmentValidator?.destroyAll();
      additionalContactValidator?.destroyAll();
    };
  }, []);

  useEffect(() => {
    setRequired(requiredFields);
    setBorrowerValidator(new BorrowerValidator({}, requiredFields));
    setAltLocationValidator(new AltLocationValidator({}, requiredFields));
    setAppointmentValidator(new AppointmentValidator({}, requiredFields));
    setAdditionalContactValidator(new AdditionalContactValidator({}, { contactEmail: true }));

    return () => {};
  }, [requiredFields]);

  useEffect(() => {
    let altLocaVisible = settings.pnl_AlternateLocation_Visible;
    let intervalId = null;

    const checkForChanges = async () => {
      if (collectData.collecting) {
        if (
          (borrowerSectHasRendered.current ? collectData.borrowerSection !== null : true) &&
          (altLocationSectHasRendered.current ? collectData.altLocationSection !== null : true) &&
          (appointmentSectHasRendered.current ? collectData.appointmentSection !== null : true) &&
          (invoiceContactSectHasRendered.current ? collectData.invoiceContactSection !== null : true) &&
          (completionInfoSectHasRendered.current ? collectData.completionInfoSection !== null : true) &&
          (billingSectHasRendered.current ? collectData.billingSection !== null : true)
        ) {
          let section1_valid = borrowerValidator.isFormValid();
          let section2_valid = altLocaVisible ? altLocationValidator.isFormValid() : true;
          let section3_valid = appointmentValidator.isFormValid();
          let section4_valid = additionalContactValidator.isFormValid();

          if (section1_valid && section2_valid && section3_valid && section4_valid) {
            setCollectData((prev) => ({ ...prev, collecting: false }));
            //
            // onSubmit() can still fail after calling the API
            let wasSucceeded = await onSubmit(collectData, collectData.action);
            setEditMode(wasSucceeded ? false : true);
            setShowInputError(wasSucceeded ? false : true);
            //
            clearInterval(intervalId);
          } else {
            setEditMode(true);
            setShowInputError(true);
            let altLocationErrors = altLocaVisible ? altLocationValidator.getErrors() : {};
            let allErrors = {
              ...borrowerValidator.getErrors(),
              ...altLocationErrors,
              ...appointmentValidator.getErrors(),
            };
            let toReview = [];
            // Object.getOwnPropertyNames(allErrors).forEach((val, idx, array) =>
            //   allErrors[val] ? toReview.push(val.charAt(0).toUpperCase() + val.slice(1)) : '',
            // );
            Object.values(allErrors).forEach((val, idx, array) => (val ? toReview.push(val) : ''));
            dispatchAlertKeepOpen(
              `<div>Please review the form and ensure all required fields are filled out before submitting.</div><p style="margin-top: 5px;"><strong>To Review:<strong> <span style="font-weight: 300;">${toReview.join(
                ', ',
              )}<span></p>`,
              true,
            );
            setCollectData((prev) => ({ ...prev, collecting: false }));
            clearInterval(intervalId);
          }
        } else {
          clearInterval(intervalId);
        }
      } else clearInterval(intervalId);
    };

    // Start interval to check if collecting data has finished
    intervalId = setInterval(checkForChanges, 250);
    if (!collectData.collecting) clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [collectData]);

  const validateBorrower = useCallback(
    (state) => {
      if (borrowerValidator) {
        borrowerValidator.setter(state);
        borrowerValidator.validateForm();
        if (!borrowerValidator.isFormValid()) {
          return borrowerValidator.getErrors();
        }
      }
    },
    [borrowerValidator],
  );

  const validateAltLocation = useCallback(
    (state) => {
      if (altLocationValidator) {
        let _required = {
          altLoc_Name: state.altLoc_Name ? true : null, // false because optional
          altLoc_Add1: state.altLoc_Add1 ? true : null,
          altLoc_Add2: state.altLoc_Add2 ? true : null, // false because optional
          altLoc_City: state.altLoc_City ? true : null,
          altLoc_State: state.altLoc_State ? true : null,
          altLoc_Zip: state.altLoc_Zip ? true : null,
        };

        const invalidComp = Object.values(_required).filter((val) => val === true);

        //override required fields
        altLocationValidator.requiredFields = {
          altLoc_Name: false,
          altLoc_Add1: invalidComp.length ? true : false,
          altLoc_Add2: false,
          altLoc_City: invalidComp.length ? true : false,
          altLoc_State: invalidComp.length ? true : false,
          altLoc_Zip: invalidComp.length ? true : false,
        };

        altLocationValidator.setter(state);
        altLocationValidator.validateForm();

        if (!altLocationValidator.isFormValid()) {
          return altLocationValidator.getErrors();
        }
      }
    },
    [altLocationValidator],
  );

  const validateAppointment = useCallback(
    (state) => {
      if (appointmentValidator) {
        appointmentValidator.setter(state);
        appointmentValidator.validateForm();
        if (!appointmentValidator.isFormValid()) {
          return appointmentValidator.getErrors();
        }
      }
    },
    [appointmentValidator],
  );

  const validateadditionalContact = useCallback(
    (state) => {
      if (additionalContactValidator) {
        additionalContactValidator.setter(state);
        additionalContactValidator.validateForm();
        if (!additionalContactValidator.isFormValid()) {
          return additionalContactValidator.getErrors();
        }
      }
    },
    [additionalContactValidator],
  );

  const handleConfirmation = (event, value) => {
    if (value) {
      setConfirmationObj({ event: event, confirmed: true });
      setConfirmationHandler(false);
    } else {
      setConfirmationHandler(false);
      setConfirmationObj(null);
    }
  };

  const onCustomerDDLChange = (event) => {
    if (event !== null) {
      service.getCustomerDefaults(fromManageRequest, event.userID, settings.pnl_BillingInfo_Visible).then(
        (res) => {
          setSharedDataObject({
            signingInfo: {
              userID: event.userID,
              customerString: event.customer,
              customerDDLSort: event.theSort,
              shipperName: res.txtDefaultShipperName || '',
              loanOfficerCompany: res.companyName || '',
              shipperAccount: res.defaultShipperAccount || '',
              shippingInstructions: res.defaultShippingInstructions || '',
              otherComments: res.defaultOtherComments || '',
              contactEmail: res.contactEmail || '',
              contactName: res.contactName || '',
              contactFax: res.fax || '',
              contactPhone: res.preferredPhone || '',
              contactCell: res.cellPhone || '',
            },
            invoiceContactInfo: {
              altInvoiceAddress: res.defaultInvoiceAddress || '',
              altInvoiceCompany: res.defaultInvoiceCompany || '',
              invoiceEmail_1: res.ccInvoice1 || '',
              invoiceEmail_2: res.ccInvoice2 || '',
              invoiceEmail_3: res.ccInvoice3 || '',
            },
            customerPricing: {
              priceDesc1: res.priceDesc1 || '',
              priceDesc2: res.priceDesc2 || '',
              priceDesc3: res.priceDesc3 || '',
              priceDesc4: res.priceDesc4 || '',
              priceDesc5: res.priceDesc5 || '',
              priceQty1: res.priceQty1 || 0,
              priceQty2: res.priceQty2 || 0,
              priceQty3: res.priceQty3 || 0,
              priceQty4: res.priceQty4 || 0,
              priceQty5: res.priceQty5 || 0,
              priceAmt1: res.priceAmt1 || 0,
              priceAmt2: res.priceAmt2 || 0,
              priceAmt3: res.priceAmt3 || 0,
              priceAmt4: res.priceAmt4 || 0,
              priceAmt5: res.priceAmt5 || 0,
            },
          });
        },
        (error) => {
          // console.log(error);
        },
      );
    }
  };

  const contextValues = useMemo(() => {
    return {
      settings,
      requestID,
      fromManageRequest,
      editMode,
      showInputError,
      required,
      borrowerSectHasRendered,
      altLocationSectHasRendered,
      appointmentSectHasRendered,
      invoiceContactSectHasRendered,
      completionInfoSectHasRendered,
      billingSectHasRendered,
      borrowerValidator,
      altLocationValidator,
      appointmentValidator,
      additionalContactValidator,
      confirmationObj,
      collectData,
      setSettings,
      sharedDataObject,
      disableSubmit,
      setDisableSubmit,
      setConfirmationHandler,
      setCollectData,
      setEditMode,
      validateBorrower,
      validateAltLocation,
      validateAppointment,
      validateadditionalContact,
      onCustomerDDLChange,
    };
  }, [
    editMode,
    required,
    collectData,
    confirmationObj,
    sharedDataObject,
    disableSubmit,
    validateBorrower,
    validateAltLocation,
    validateAppointment,
    validateadditionalContact,
    setCollectData,
  ]);

  return (
    <FormContext.Provider value={contextValues}>
      <React.Fragment>
        {children}
        {confirmationHandler && (
          <AppConfirmationDialog
            id="appConfirmationDialog"
            open={confirmationHandler !== false ? true : false}
            title={confirmationHandler.title}
            content={confirmationHandler.msg}
            buttonTrueText={'Ok'}
            color={'primary'}
            onClose={() => {}}
            confirmCallback={(val) => handleConfirmation(confirmationHandler.event, val)}
          />
        )}
      </React.Fragment>
    </FormContext.Provider>
  );
};

export default FormProvider;
