import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
        margin: 0;
    }

    html {
        box-sizing: border-box;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    ul, li, h1, h2, h3, p, button {
        margin: 0;
    }

    ul {
        list-style: none;
    }

    body {
        background-color: ${(props) => props.theme.palette.nsAppBG?.main};
        margin: 0 auto;
        overscroll-behavior: none;
        width: 100%;
        font-size: 0.85rem;
        padding-right: 0px !important;
            overflow: auto !important;
    }

    .MuiFormLabel-root.MuiInputLabel-shrink {
        font-size: 17px;
    }

    .MuiFormLabel-asterisk {
        color: red;
    }

    .MuiInputBase-root.Mui-disabled {
        color: ${(props) => (props.theme.palette.mode === 'light' ? 'rgb(0 0 0 / 60%)' : '')};
    }

    & .label-with-checkbox {
        display: flex;
        align-items: center;

        > :nth-child(1) {
            font-size: 0.85rem;
            font-weight: 500;
            margin-right: 5px;
        }
    }

    & .label-with-info {
        display: flex;
        align-items: center;
        height: 20px;

        > :nth-child(1) {
            font-size: 0.85rem;
            font-weight: 500;
        }
    }

    & .label-with-info.mb {
        margin-bottom: 10px;
    }

    & .section-title-with-info {
        display: flex;
        margin-top: 10px;
        align-items: center;

        > :nth-child(1) {
            font-size: 1.1rem;
            font-weight: 500;
        }

        > :nth-child(2) {
            font-size: 0.8rem;
        }
    }

    & .label-formcontrol-below {
        display: flex;
        align-items: center;
        height: 20px;

        > :nth-child(1) {
            font-size: 0.85rem;
            font-weight: 500;
        }
    }

    & .view-title {
        font-size: 25px;
        font-weight: 500;
        padding: 5px 0;
        margin-bottom: 15px;
    };

    & .comp-title {
        font-size: 1rem;
        /* padding-bottom: 20px; */
        font-weight: 500;
    };

    & .comp-sub-title {
        position: relative;
        font-size: .85rem;
        font-weight: 500;
        /* min-height: 40px;
        line-height: 40px; */
        display: flex;
        justify-content: space-between;
    };

    & .comp-sub-title-center {
        font-size: .8rem;
        padding: 15px 0px 5px;
        font-weight: 500;
        text-align: center;
    };

    & .comp-notes {
        font-size: .75rem; 
        margin-bottom: 12px;
    };

    & .comp-notes-center {
        text-align: center;
        font-size: .75rem; 
        margin: 5px;
    };

    & .comp-input-notes {
        font-size: .75rem; 
        margin-top: 9px;
        margin-bottom: 10px;
    };

    & .comp-input-notes.with-error {
        color: red !important;
    };

    & .comp-info {
        font-size: 12px;
    }

    & .MuiOutlinedInput-root {
        fieldset { legend {
            font-size: 0.95em; 
        }}
    }

    #app {
        height: 100vh;

        & .top-content-logo {
            margin-bottom: 10px;
        }
       
        & .MuiSnackbar-root {
            z-index: 5000;
        }
        & .environment-tag {
            color: aqua;
            font-size: 12px;
            margin-left: 10px;
            padding: 0px 5px 0px 5px;
        }
        
        @media screen and (max-width: 600px) {

            & .inline-block-title-space-arround {
                display: inline-block;
            }
        }

        & .big-form {
            .MuiFormControl-root,
            .MuiTextField-root {
                background-color:  ${(props) =>
                  props.theme.palette.mode == 'dark' ? props.theme.palette.nsAppBG?.main : '#f0f8ffcf'};

                .MuiFormHelperText-root {
                    margin: 0;
                    background-color: ${(props) => (props.theme.palette.mode == 'dark' ? 'rgb(30 37 41);' : 'white')};
                }

                .MuiSelect-select {
                    min-height: auto;
                }

                .MuiFormLabel-root.MuiInputLabel-shrink {
                    font-size: 17.9px;
                    transform: translate(11px, -9px) scale(0.75);
                }
            }
        }
    }
`;
