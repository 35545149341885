import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';

const RenderExpandableCell = (props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const { value } = props;

  const textElementRef = useRef(null);

  const checkOverflow = () => {
    // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
    const clientWidth = textElementRef.current.getBoundingClientRect().width;

    textElementRef.current.style.overflow = 'visible';
    const contentWidth = textElementRef.current.getBoundingClientRect().width;
    textElementRef.current.style.overflow = 'hidden';

    setIsOverflow(contentWidth > clientWidth);
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <Tooltip disableInteractive={false} title={value} disableHoverListener={!isOverflowed}>
      <span
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </span>
    </Tooltip>
  );
};

function getColumns(columns) {
  let columnObjects = [];
  columnObjects = columns.map((col) => {
    let defaultWidth = 100;
    switch (col) {
      case 'CompanyName':
      case 'Street':
      case 'Address2':
      case 'Email':
      case 'UserName':
        defaultWidth = 200;
        break;
      default:
        break;
    }

    return {
      field: col,
      headerName: col.charAt(0).toUpperCase() + col.slice(1),
      width: defaultWidth,
      editable: false,
      renderCell: (params) => <RenderExpandableCell {...params} />,
    };
  });
  return columnObjects;
}

export default function BasicTable({ data }) {
  const [rows, setRows] = React.useState([]);
  const [colums, setColums] = React.useState([]);

  React.useEffect(() => {
    setRows(data.map((obj, index) => ({ ...obj, id: index })));
    setColums(getColumns(Object.keys(data[0])));
    return () => {};
  }, [data]);

  return (
    <DataGrid
      sx={{
        '.MuiDataGrid-footerContainer': {
          '.MuiTablePagination-root': {
            overflow: 'hidden',

            '.MuiInputBase-fullWidth': {
              width: 'auto',
            },
          },
        },
      }}
      density="compact"
      disableColumnFilter
      slots={{ toolbar: GridToolbar }}
      // autoHeight
      rows={rows}
      columns={colums}
      // getRowId={(row) => row.PrimaryRecord}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
      }}
      pageSizeOptions={[25, 50, 100]}
      checkboxSelection
      disableRowSelectionOnClick
    />
  );
}
