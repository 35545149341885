import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Items from '../components/notary-desk/Items';
import NotaryDeskProvider from '../context/notary-desk.context';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const NotaryDesk = (props) => {
  useDocumentTitle('Notary Desk'); // Set page title
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <NotaryDeskProvider>
          <Items {...props} />
        </NotaryDeskProvider>
      </div>
    </Fragment>
  );
};

export default NotaryDesk;
