import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { DialogTitle } from '@mui/material';

function AppAlertDialog(props) {
  const {
    title = 'Alert',
    severity = 'primary',
    content = '',
    contentHtml = '',
    buttonTrueText = 'OK',
    buttonFalseText = 'Cancel',
    buttonTrueOnLeft = false,
    open = false,
    onClose,
    addTokenTimer = false,
    value = '',
    onlyButtonClose = false,
    ...other
  } = props;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      fullWidth
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle id="alert-dialog-title" severity={severity}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {severity == 'primary' && <InfoOutlinedIcon />}
          {severity == 'error' && <ErrorOutlineOutlinedIcon />}
          {severity == 'warning' && <WarningAmberOutlinedIcon />}
          {severity == 'info' && <InfoOutlinedIcon />}
          {severity == 'success' && <TaskAltOutlinedIcon />}
          {` `}
          <span>{title}</span>
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: '15px !important' }} dividers>
        {contentHtml ? contentHtml : <Fragment>{content}</Fragment>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus color={'default'} onClick={() => onClose(true)}>
          {buttonTrueText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AppAlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // value: PropTypes.object.isRequired,
};

export default AppAlertDialog;
