import React, { useState, useRef, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import { notaryDeskService as service } from '../services';
import { dispatchAlert } from '../helpers';

export const NotaryDeskContext = createContext();

const NotaryDeskProvider = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [completionReportsList, setCompletionReportsList] = useState([]);
  const [completionOptions, setCompletionOptions] = useState({});
  const [selectedNotary, setSelectedNotary] = useState(-1);
  const [notaryListDDL, setNotaryListDDL] = useState([]);
  const [controlSettings, setControlSettings] = useState(false);

  useEffect(() => {
    getCompletionReportsList(selectedNotary);
    return () => {
      // console.log(history.location.pathname);
      // if (history.location.pathname !== '/dashboard') {
      //   history.replace({ ...history.location, state: null });
      // }
    };
  }, []);

  const changeNotary = (event) => {
    setSelectedNotary(event.target.value);
    getCompletionReportsList(event.target.value);
  };

  const getCompletionReportsList = (selectedNotary) => {
    setLoading(true);
    return service.getCompletionReports(selectedNotary).then(
      (res) => {
        setControlSettings(res.settings);
        setNotaryListDDL(res.notaryListDDL);
        setCompletionReportsList(res.completionReportList);
        setSelectedNotary(res.settings?.defaultAgentIDForDDL);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        dispatchAlert('[Replace]' + error, true);
      }
    );
  };

  return (
    <NotaryDeskContext.Provider
      value={{
        loading,
        controlSettings,
        completionReportsList,
        notaryListDDL,
        selectedNotary,
        completionOptions,
        setCompletionOptions,
        changeNotary,
        setCompletionReportsList,
        getCompletionReportsList,
      }}
    >
      {props.children}
    </NotaryDeskContext.Provider>
  );
};

export default NotaryDeskProvider;
