import React, { useState, useEffect, useRef } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';

import TabCollapse from './TabCollapse';
import TabItem from './TabItem';
import { Card } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => css``);

const TabItems = (props) => {
  const handleMove = (tabIndex, direction, parentIndex = null) => {
    return props.onMove(tabIndex, direction === 'up' ? -1 : 1, parentIndex);
  };

  return (
    <StyledCard>
      <List component="nav" aria-labelledby="nested-list-subheader">
        {props.tabs.map((item, mapIndex) => (
          <div key={item.index}>
            {item.level === 0 && item.tabChildren.length > 0 ? (
              <TabCollapse
                {...item}
                onMove={(index, direc) => handleMove(index, direc, mapIndex)}
                onSucceedAction={props.onSucceedAction}
              />
            ) : (
              <TabItem {...item} onMove={props.onMove} onSucceedAction={props.onSucceedAction} />
            )}
          </div>
        ))}
      </List>
    </StyledCard>
  );
};

export default TabItems;
