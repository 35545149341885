import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

export const moduleTabService = {
  getPortalTabs,
  updateTab,
  addTab,
  updatePortalTabOrder,
  getPortalTabsForDDL,
  getPortalModulesByPortal,
  assignModuleToTab,
  updateTabModule,
  addNewModuleForAssignment,
  deleteTabModule,
  deleteAllModuleFromTab,
};

async function getPortalTabs(portalID) {
  const options = { headers: await authHeader() };
  const params = { portalID: portalID };
  const url = `/tab/getportaltabs`;
  return Fetch.get(url, params, options).then(handleResponse);
}

async function updateTab(data) {
  const options = { headers: await authHeader(), body: data };
  const params = {};
  const url = `/tab`;
  return Fetch.update(url, params, options).then(handleResponse);
}

async function updatePortalTabOrder(data, yDirection) {
  const options = { headers: await authHeader(), body: data };
  const params = { yDirection: yDirection };
  const url = `/tab/updatetaborder`;
  return Fetch.update(url, params, options).then(handleResponse);
}

async function addTab(data) {
  const options = { headers: await authHeader(), body: data };
  const params = {};
  const url = `/tab`;
  return Fetch.post(url, params, options).then(handleResponse);
}

async function getPortalTabsForDDL(portalID) {
  const options = { headers: await authHeader() };
  const params = { portalID: portalID };
  const url = `/tab/getportaltabsforddl`;
  return Fetch.get(url, params, options).then(handleResponse);
}

async function getPortalModulesByPortal(portalID) {
  const options = { headers: await authHeader() };
  const params = { portalID: portalID };
  const url = `/module/getModulesByPortal`;
  return Fetch.get(url, params, options).then(handleResponse);
}

// Modules Managerment for View/PAge

async function assignModuleToTab(desktopModuleID) {
  const options = { headers: await authHeader() };
  const params = { desktopModuleID: desktopModuleID };
  const url = '/module/addTabModule';
  return Fetch.update(url, params, options).then(handleResponse);
}

async function updateTabModule(module) {
  const options = { headers: await authHeader(), body: module };
  const params = {};
  const url = '/module/updateTabModule';
  return Fetch.update(url, params, options).then(handleResponse);
}

async function addNewModuleForAssignment(object) {
  const options = { headers: await authHeader() };
  const params = object;
  const url = '/module/addNewModuleForAssignment';
  return Fetch.post(url, params, options).then(handleResponse);
}

async function deleteTabModule(module) {
  const options = { headers: await authHeader(), body: module };
  const params = {};
  const url = '/module/deleteTabModule';
  return Fetch.remove(url, params, options).then(handleResponse);
}

async function deleteAllModuleFromTab(tabID) {
  const options = { headers: await authHeader() };
  const params = { tabID: tabID };
  const url = '/module/deleteTabModules';
  return Fetch.remove(url, params, options).then(handleResponse);
}
