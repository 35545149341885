import React, { useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';

import CustomPagination from '../../common/others/Pagination';
import { ManageRequestContext } from '../../../context/manage-request.context';
import ItemMobile from './ItemMobile';
import ItemTablet from './ItemTablet';
import ItemDesktop from './ItemDesktop';
import { EmptyList } from '../../common/others/EmptyList';
import { useContext } from 'react';
import Loading from '../../common/others/Loading';
import { connect, useSelector } from 'react-redux';
import FileUploadProvider from '../../common/file-uploader/FileUploadContext';
import AppDialog from '../../common/others/AppDialog';
import ScanBackUploader from '../../common/file-uploader/scan-backs';
import AppointmentDialog from '../../manage-request/AppointmentDialog';
import UploadFilesDialog from '../../common/file-uploader/upload-files-dialog';
import AssignStaffDialog from '../AssignStaffDialog';
import { scanBackFilterObject } from './filter/models';
import { useMediaQuery } from '@mui/material';

const RootDiv = styled('div')(
  ({ theme, $altCardColor }) => css`
    z-index: 0;
    position: relative;

    /* start of large tablet styles */

    & .MuiLinearProgress-root {
      top: -14px;
    }

    .item-wrapper:nth-child(n + 2) {
      margin-top: 7px;
    }

    /* #left {
        border-style: solid;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-top-color: #bbbbbb;
        border-right-color: #bbbbbb;
        border-bottom-color: #bbbbbb;
        border-left-color: #bbbbbb;
      }

      #right {
        border-style: solid;
        border-left-width: 1px;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-right-width: 1px;
        border-top-color: #bbbbbb;
        border-right-color: #bbbbbb;
        border-bottom-color: #bbbbbb;
        border-left-color: #bbbbbb;
      } */

    .item-wrapper {
      #left,
      #right {
        box-shadow: none;
        border: 1px solid ${theme.palette.mode == 'dark' ? '#414141' : '#d5d5d5'};
      }
    }

    .item-wrapper:nth-child(even) {
      #left,
      #right {
        border: 1px solid ${theme.palette.mode == 'dark' ? '#5b5b5b' : '#bfbfbf'};
        background-color: ${$altCardColor
          ? theme.palette.mode == 'dark'
            ? theme.palette.nsTableRowHoverBG.main
            : 'rgb(241 241 241 / 64%)'
          : ''};

        /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 2px rgba(0, 0, 0, 0.12); */
      }
    }

    .item-wrapper:hover {
      #left,
      #right {
        background-color: ${theme.palette.mode == 'dark' ? theme.palette.nsTableRowHoverBG.main : 'aliceblue'};
      }
    }

    @media screen and (max-width: 991px) {
      /* start of large tablet styles */
    }

    @media screen and (max-width: 767px) {
      /* start of medium tablet styles */
    }

    @media screen and (max-width: 479px) {
      /* start of phone styles */
    }
  `
);

const SigningRequestListCard = ({ store: store, ...props }) => {
  const history = useHistory();

  const {
    loading,
    getGeneralRequests,
    requestList,
    pageSizesForList,
    filterPayLoad,
    controlSetting,
    handlePageSize,
    handlePageNumber,
    totalPages,
    currentPaginationPageSize,
    parseSendResponse,
    assignStaffToRequest,
    appliedFilters,
    setAppliedFilters,
    breadcrumbState,
  } = useContext(ManageRequestContext);

  const requestLitInitRef = useRef(true);
  const location = useLocation();
  const theme = useTheme();
  const menuState = store.menuState.current;
  const { mgrReqStorage } = useSelector((state) => state.reducer.portalStorage);

  const [appointmentDialog, setAppointmentDialog] = useState(false);
  const [signingUploader, setSigningUploader] = useState(false);
  const [scanBacksUploader, setScanBacksUploader] = useState(false);
  const [assignStaffDialog, setAssignStaffDialog] = useState(false);

  useEffect(() => {
    if (requestLitInitRef.current) {
      if (breadcrumbState?.forceListReload) {
        getGeneralRequests(filterPayLoad);
      } else {
        // Get parameter from other locations. ex: Dashboard
        let hasScanBacks = location.state?.fromDashboard?.hasScanBacks || false;
        let priorToToday = location.state?.fromDashboard?.priorToToday || false;

        if (controlSetting) {
          if (hasScanBacks) {
            let _filtersCopy = _.cloneDeep(appliedFilters);
            let objIndex = _filtersCopy.findIndex((obj) => obj.id == 'requestDocs');
            _filtersCopy[objIndex].value = [scanBackFilterObject];
            _filtersCopy[objIndex].lastEnteredTimestamp = 1;
            setAppliedFilters(_filtersCopy);
          }

          let momentDate = null;
          if (priorToToday) momentDate = moment().add(-1, 'd').toISOString();

          getGeneralRequests({
            ...filterPayLoad,
            requestStatus: hasScanBacks ? '1234569' : filterPayLoad.requestStatus,
            dateTo: priorToToday ? momentDate : filterPayLoad.dateTo,
            requestDocs: hasScanBacks ? [scanBackFilterObject] : [],
          });
        }
      }
      requestLitInitRef.current = false;
    }

    return () => {};
  }, [controlSetting]);

  const handleRequestActionPerform = (response, redirectTo) => {
    setAppointmentDialog();
    if (response) parseSendResponse(response);
    if (redirectTo) history.push(redirectTo);
    else getGeneralRequests({ ...filterPayLoad, pageSize: filterPayLoad.pageSize });
  };

  const handleCloseAssignStaff = (value) => {
    if (value === 'cancel') {
      setAssignStaffDialog(false);
    } else {
      setAssignStaffDialog(false);
      assignStaffToRequest(assignStaffDialog.requestID, value);
    }
  };

  let between1200_1550 = useMediaQuery(theme.breakpoints.between('1200', '1550'));
  const calculateForTableSize = () => {
    return ['xsm', 'md'].includes(store.appViewport) || between1200_1550;
  };

  return (
    <RootDiv $altCardColor={mgrReqStorage.altCardColor}>
      {loading || requestList == null ? (
        <Loading linear={requestList == null ? false : true} forList />
      ) : (
        <>{requestList?.length == 0 && <EmptyList msg="No Results Found" />}</>
      )}
      {requestList?.map((request, index) => (
        <div key={index} className="item-wrapper">
          {['xs', 'sm'].includes(store.appViewport) && (
            <ItemMobile
              request={request}
              appointmentDialog={(event) => setAppointmentDialog(event)}
              signingUploader={(request) => setSigningUploader(request)}
              scanBacksUploader={(request) => setScanBacksUploader(request)}
              assignStaffDialog={(request) => setAssignStaffDialog(request)}
            />
          )}
          {['xsm', 'md'].includes(store.appViewport) && !between1200_1550 && (
            <ItemTablet
              request={request}
              appointmentDialog={(event) => setAppointmentDialog(event)}
              signingUploader={(request) => setSigningUploader(request)}
              scanBacksUploader={(request) => setScanBacksUploader(request)}
              assignStaffDialog={(request) => setAssignStaffDialog(request)}
            />
          )}
          {between1200_1550 && (
            <>
              {menuState == 'expanded' ? (
                <ItemTablet
                  request={request}
                  appointmentDialog={(event) => setAppointmentDialog(event)}
                  signingUploader={(request) => setSigningUploader(request)}
                  scanBacksUploader={(request) => setScanBacksUploader(request)}
                  assignStaffDialog={(request) => setAssignStaffDialog(request)}
                />
              ) : (
                <ItemDesktop
                  request={request}
                  appointmentDialog={(event) => setAppointmentDialog(event)}
                  signingUploader={(request) => setSigningUploader(request)}
                  scanBacksUploader={(request) => setScanBacksUploader(request)}
                  assignStaffDialog={(request) => setAssignStaffDialog(request)}
                />
              )}
            </>
          )}
          {['lg', 'xl'].includes(store.appViewport) && !between1200_1550 && (
            <ItemDesktop
              request={request}
              appointmentDialog={(event) => setAppointmentDialog(event)}
              signingUploader={(request) => setSigningUploader(request)}
              scanBacksUploader={(request) => setScanBacksUploader(request)}
              assignStaffDialog={(request) => setAssignStaffDialog(request)}
            />
          )}
        </div>
      ))}
      {requestList?.length > 0 && currentPaginationPageSize() && (
        <CustomPagination
          pageSizeOptions={pageSizesForList}
          pageSize={currentPaginationPageSize()}
          pageNumber={filterPayLoad.pageNumber}
          totalPages={totalPages}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
        />
      )}
      {appointmentDialog && (
        <AppointmentDialog
          open={appointmentDialog ? true : false}
          content={appointmentDialog}
          settings={controlSetting}
          ignoreDetailContext={true}
          request={appointmentDialog.request}
          confirmCallback={handleRequestActionPerform}
        />
      )}
      {signingUploader && (
        <FileUploadProvider
          context={{
            uploadDocType: 'SigningFiles',
            requestID: signingUploader.requestID,
          }}
        >
          <UploadFilesDialog
            open={signingUploader ? true : false}
            title={'Documents Upload'}
            buttonTrueText={'Save'}
            buttonFalseText={'Close'}
            documentData={{
              title:
                'Request #' +
                signingUploader.requestSeqID +
                ' - ' +
                [signingUploader.borrowerFirstName, signingUploader.borrowerLastName].join(' ') +
                ' ' +
                format(parseISO(signingUploader.signingDate), 'MM/dd/yyyy'),
            }}
            onClose={(e) => {
              getGeneralRequests(filterPayLoad);
              setSigningUploader(false);
            }}
          />
        </FileUploadProvider>
      )}
      {scanBacksUploader && (
        <FileUploadProvider
          context={{
            uploadDocType: 'ScanBacks',
            requestID: scanBacksUploader.requestID,
            requestSeqID: scanBacksUploader.requestSeqID,
            customerID: scanBacksUploader.userID,
          }}
        >
          <AppDialog
            open={scanBacksUploader ? true : false}
            mainTitle="Scan Backs"
            complementaryData={{
              title:
                'Request #' +
                scanBacksUploader.requestSeqID +
                ' - ' +
                [scanBacksUploader.borrowerLastName, scanBacksUploader.borrowerFirstName].join(', ') +
                ' ' +
                format(parseISO(scanBacksUploader.signingDate), 'MM/dd/yyyy'),
            }}
          >
            <ScanBackUploader
              dialog
              open={scanBacksUploader ? true : false}
              data={scanBacksUploader}
              onClose={() => {
                getGeneralRequests(filterPayLoad);
                setScanBacksUploader(false);
              }}
            />
          </AppDialog>
        </FileUploadProvider>
      )}
      {assignStaffDialog && (
        <AssignStaffDialog
          open={assignStaffDialog ? true : false}
          request={assignStaffDialog}
          onClose={handleCloseAssignStaff}
        />
      )}
    </RootDiv>
  );
};

const mapStateToProps = (state) => ({ store: state.reducer });
export default connect(mapStateToProps)(SigningRequestListCard);
