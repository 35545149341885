import React, { useEffect, useContext, useState, Fragment } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AccordionWrapper, HtmlTooltip } from '../../Style';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import { UserProfileContext } from '../../context/user-profile.context';
import ProfileTextField from './ProfileTextField';
import {
  additionalInfoColumns,
  customerLoanOfficerDefaults,
  pricingColumns,
  customerEmailColumns,
  invoiceContactEmail,
  customerPhoneNumbers,
} from './ProfileContent/profileInfo';
import { EditableTable } from '../common/others/EditableTable';
import ShippingCompany from '../common/ddls/ShippingCompany';
import { sanitizeHTML } from '../../helpers';
import { CountryStates } from '../common/ddls/CountryStates';
import { AppTextarea } from '../common/others/NSTextarea';
import { store } from '../../store';
import UserPasswordUpdateDialog from './UserPasswordUpdateDialog';

const StyledSection = styled('div')(
  ({ theme, $editMode, $canEdit, $withMenu }) => css`
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.between('md', 'lg')} {
      & {
        display: ${$withMenu ? 'block' : 'flex'};
      }
    }

    .col:nth-child(1) {
      margin-right: 10px;
      flex: 1;
    }

    .col:nth-child(2) {
      /* margin-left: 10px; */
      flex: 1;
    }

    .profile-row {
      display: flex;
      align-items: center;
      padding-right: 0px !important;
      height: ${!$editMode || !$canEdit ? '25px' : 'initial'};
      width: 100%;
      margin-bottom: 7px;

      > div:nth-child(1) {
        flex: 1;
      }

      > div:nth-child(2) {
        flex: 3;
      }

      .split {
        > div:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .same-as-billing {
      right: 20px;
      align-items: end;
      width: 165px;
      position: absolute;
      top: 0;
      line-height: 50px;
    }

    ${theme.breakpoints.down('md')} {
      & {
        display: block;
      }

      & .col:nth-child(1) {
        margin-right: 0px;
      }

      & .col:nth-child(2) {
        margin-left: 0px;
      }
    }

    .customer-row {
      width: 100%;
      margin-bottom: 15px;
    }

    .customer-title {
      display: flex;
      align-items: center;
      width: 100%;
    }
  `
);

export function ProfileAccordion(props) {
  const { index, title, children, expandAll, editMode, canEdit, sx } = props;

  const [expanded, setExpanded] = useState(false);
  const [_expandAll, _setExpandAll] = useState(expandAll);

  useEffect(() => {
    setExpanded(!expanded);
    _setExpandAll(expandAll);
    return () => {};
  }, [expandAll]);

  const handleChange = (panel) => (event, isExpanded) => {
    _setExpandAll(false);
    setExpanded(isExpanded ? panel : false);
  };

  const setOpacity = () => {
    return editMode ? (canEdit ? 1 : 0.8) : 1;
  };

  return (
    <AccordionWrapper mb={'10px'} {...(sx || {})}>
      <Accordion
        sx={{ ...(sx || {}), opacity: setOpacity() }}
        expanded={expanded === `panel${index}` || _expandAll}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          id={`panel${index}bh-header`}
          sx={{ color: '#266b7b' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
        >
          <div className={`summaryWrapper ${expanded === `panel${index}` ? 'expanded' : ''}`}>
            <strong>{title}</strong>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start !important',
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  );
}

const CustomerProfile = (props) => {
  const context = useContext(UserProfileContext);
  const {
    fake,
    profileId,
    profileInfo,
    setProfileInfo,
    setUpdatedProfile,
    updatedProfile,
    formErrors,
    setFormErrors,
    mapElementFreeText,
    lookupList,
  } = context;

  const { canEdit, editMode, disabled } = props;
  const { current: menuState } = store.getState().reducer.menuState;

  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [openUserSecurityDialog, setOpenUserSecurityDialog] = useState(false);

  useEffect(() => {
    if (!profileInfo.PhoneNumbers) {
      setProfileInfo({
        ...profileInfo,
        PhoneNumbers: [
          {
            id: 1,
            phoneTypeId: lookupList.SelectPhoneTypes?.find((ph) => ph.value == 'Work')?.index,
            number: '',
            lookupName: 'Work',
            isDefault: true,
            Preferred: true,
            canText: false,
            lock_lookupName: true,
          },
        ],
        Emails: [
          {
            id: 1,
            emailTypeId: lookupList.SelectEmailTypes?.find((ph) => ph.value == 'Work')?.index,
            address: '',
            lookupName: 'Work',
            isDefault: true,
            lock_lookupName: true,
          },
        ],
      });
    }

    return () => {};
  }, [profileInfo.PhoneNumbers, profileInfo.Emails]);

  const billingAddress = profileInfo.Addresses?.find((address) => {
    return address.name?.toLowerCase().includes('billing');
  });

  useEffect(() => {
    setSameAsBilling(billingAddress?.isShipping ? true : false);
    return () => {};
  }, [billingAddress]);

  const shippingAddress = profileInfo.Addresses?.find((address) => {
    return address.name?.toLowerCase().includes('shipping');
  });

  const updateAddresses = (value, profileObj, profileItem) => {
    let temp = _.cloneDeep(profileInfo.Addresses);
    let index = temp.findIndex((el) => el.name === profileObj);
    temp[index][profileItem] = value;

    if (profileItem == 'isShipping' && value) {
      let shippingIndex = temp.findIndex((el) => el.name?.toLowerCase().includes('shipping'));
      temp[shippingIndex].city = '';
      temp[shippingIndex].isShipping = false;
      temp[shippingIndex].line2 = '';
      temp[shippingIndex].state = '';
      temp[shippingIndex].street = '';
      temp[shippingIndex].zip = '';

      setProfileInfo({ ...profileInfo, Addresses: temp });
      setUpdatedProfile({ ...updatedProfile, Addresses: temp });
    } else {
      setProfileInfo({ ...profileInfo, Addresses: temp });
      setUpdatedProfile({ ...updatedProfile, Addresses: temp });
    }
  };

  const profileUpdate = (value, profileObj) => {
    setProfileInfo({ ...profileInfo, [profileObj]: value });
    setUpdatedProfile({ ...updatedProfile, [profileObj]: value });
  };

  const [officerRadio, setOfficerRadio] = React.useState(
    profileInfo.Officers?.useOfficerInfoForContactInfo
      ? 'useOfficerInfoAsContact'
      : profileInfo.OtherInformation?.includeOfficerInEmails
        ? 'includeOfficerInEmail'
        : null
  );

  function handleOfficeRadioButtons(event) {
    const setMainState = async (hardVal, eventVal) => {
      let otherInfoClone;
      let officerClone;

      const doClone = async () => {
        otherInfoClone = _.cloneDeep(profileInfo.OtherInformation);
        otherInfoClone.includeOfficerInEmails = eventVal == 'useOfficerInfoAsContact' ? false : hardVal;

        officerClone = _.cloneDeep(profileInfo.Officers);
        officerClone.useOfficerInfoForContactInfo = eventVal == 'includeOfficerInEmail' ? false : hardVal;
      };

      await doClone();

      setProfileInfo({
        ...profileInfo,
        OtherInformation: otherInfoClone,
        Officers: officerClone,
      });

      setUpdatedProfile({
        ...updatedProfile,
        OtherInformation: otherInfoClone,
        Officers: officerClone,
      });
    };

    if (event.target.value === officerRadio) {
      setOfficerRadio(null);
      setMainState(false, 'clear');
    } else {
      setOfficerRadio(event.target.value);
      setMainState(true, event.target.value);
    }
  }

  return (
    <Fragment>
      <StyledSection $editMode={editMode} $canEdit={canEdit} $withMenu={menuState == 'expanded' ? true : false}>
        <div className="col">
          <ProfileAccordion title={'Name & Company'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
            <div className="profile-row">
              <ProfileTextField title value={'Name:'} />
              {editMode && canEdit ? (
                <div className="split" style={{ display: 'flex' }}>
                  <ProfileTextField
                    edit={canEdit}
                    disabled={disabled}
                    required={!profileInfo.Entities?.[0].firstName ? true : false}
                    error={formErrors?.firstName && !profileInfo.Entities?.[0].firstName ? true : false}
                    value={profileInfo.Entities?.[0].firstName || ''}
                    onChange={(e) => {
                      let temp = _.cloneDeep(profileInfo.Entities);
                      temp[0].firstName = e.target.value;
                      profileUpdate(temp, 'Entities');
                    }}
                  />
                  <ProfileTextField
                    edit={canEdit}
                    disabled={disabled}
                    required={!profileInfo.Entities?.[0].lastName ? true : false}
                    error={formErrors?.lastName && !profileInfo.Entities?.[0].lastName ? true : false}
                    value={profileInfo.Entities?.[0].lastName || ''}
                    onChange={(e) => {
                      let temp = _.cloneDeep(profileInfo.Entities);
                      temp[0].lastName = e.target.value;
                      profileUpdate(temp, 'Entities');
                    }}
                  />
                </div>
              ) : (
                <ProfileTextField
                  edit={canEdit}
                  disabled={disabled}
                  value={`${profileInfo.Entities?.[0].firstName} ${profileInfo.Entities?.[0].lastName}` || ''}
                />
              )}
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'Company:'} />
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={profileInfo.Entities?.[0].parentName || ''}
                onChange={(e) => {
                  let temp = _.cloneDeep(profileInfo.Entities);
                  temp[0].parentName = e.target.value;
                  profileUpdate(temp, 'Entities');
                }}
              />
            </div>
          </ProfileAccordion>

          <ProfileAccordion title={'Billing'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
            <div className="profile-row">
              <ProfileTextField title value={'Address:'} />
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={billingAddress?.street || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'PrimaryBilling', 'street');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'Address2:'} />
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={billingAddress?.line2 || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'PrimaryBilling', 'line2');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'City:'} />
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={billingAddress?.city || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'PrimaryBilling', 'city');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'State:'} />
              {editMode && canEdit ? (
                <CountryStates
                  noLabel
                  value={billingAddress?.state || ''}
                  handleState={(e) => {
                    updateAddresses(e, 'PrimaryBilling', 'state');
                  }}
                />
              ) : (
                <ProfileTextField edit={canEdit} disabled={disabled} value={billingAddress?.state || ''} />
              )}
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'Zip:'} />
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                required={editMode && billingAddress?.zip ? false : editMode}
                error={formErrors?.zipCode && !billingAddress?.zip ? true : false}
                value={billingAddress?.zip || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'PrimaryBilling', 'zip');
                }}
              />
            </div>
          </ProfileAccordion>

          <ProfileAccordion editMode={editMode} canEdit={canEdit} title={'Shipping'} expandAll={props.expandAll}>
            <div className="same-as-billing">
              <FormControlLabel
                disabled={!editMode}
                label={'Same as Billing'}
                control={<Checkbox size="small" />}
                checked={sameAsBilling}
                onChange={(e) => {
                  setSameAsBilling(e.target.checked);
                  updateAddresses(e.target.checked, 'PrimaryBilling', 'isShipping');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'Address:'} />
              <ProfileTextField
                sx={{ cursor: sameAsBilling ? 'not-allowed' : 'auto' }}
                edit={canEdit}
                disabled={disabled || sameAsBilling}
                value={sameAsBilling ? billingAddress?.street : shippingAddress?.street || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'Shipping', 'street');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'Address2:'} />
              <ProfileTextField
                sx={{ cursor: sameAsBilling ? 'not-allowed' : 'auto' }}
                edit={canEdit}
                disabled={disabled || sameAsBilling}
                value={sameAsBilling ? billingAddress?.line2 : shippingAddress?.line2 || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'Shipping', 'line2');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'City:'} />
              <ProfileTextField
                sx={{ cursor: sameAsBilling ? 'not-allowed' : 'auto' }}
                edit={canEdit}
                disabled={disabled || sameAsBilling}
                value={sameAsBilling ? billingAddress?.city : shippingAddress?.city || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'Shipping', 'city');
                }}
              />
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'State:.'} />
              {editMode && canEdit ? (
                <CountryStates
                  noLabel
                  sx={{ cursor: sameAsBilling ? 'not-allowed' : 'auto' }}
                  disabled={disabled || sameAsBilling}
                  value={sameAsBilling ? billingAddress?.state : shippingAddress?.state || ''}
                  handleState={(e) => {
                    updateAddresses(e, 'Shipping', 'state');
                  }}
                />
              ) : (
                <ProfileTextField
                  sx={{ cursor: sameAsBilling ? 'not-allowed' : 'auto' }}
                  edit={canEdit}
                  disabled={disabled}
                  value={sameAsBilling ? billingAddress?.state : shippingAddress?.state || ''}
                />
              )}
            </div>
            <div className="profile-row">
              <ProfileTextField title value={'Zip:'} />
              <ProfileTextField
                sx={{ cursor: sameAsBilling ? 'not-allowed' : 'auto' }}
                edit={canEdit}
                disabled={disabled || sameAsBilling}
                value={sameAsBilling ? billingAddress?.zip : shippingAddress?.zip || ''}
                onChange={(e) => {
                  updateAddresses(e.target.value, 'Shipping', 'zip');
                }}
              />
            </div>
          </ProfileAccordion>

          <ProfileAccordion
            editMode={editMode}
            canEdit={canEdit}
            title="Pricing Information"
            expandAll={props.expandAll}
          >
            <EditableTable
              addNew
              maxRows={5}
              edit={canEdit}
              columns={pricingColumns}
              rows={profileInfo.Pricings_v1Schema || []}
              emitNewRows={(e) => {
                setUpdatedProfile({
                  ...updatedProfile,
                  Pricings_v1Schema: e.state,
                });
                setProfileInfo({
                  ...profileInfo,
                  Pricings_v1Schema: e.state,
                });
              }}
            />
            <br />
            <Typography variant="subtitle2" fontWeight={'bold'}>
              Other Pricing Notes:
            </Typography>
            <AppTextarea
              maxRows={4}
              edit={canEdit}
              disabled={disabled}
              value={mapElementFreeText(8)?.value}
              onChange={(val) => {
                let temp = _.cloneDeep(profileInfo.ElementFreeTexts);
                let index = temp.map((object) => object.element).indexOf(8);
                temp[index].value = val;
                profileUpdate(temp, 'ElementFreeTexts');
              }}
            />
          </ProfileAccordion>

          <ProfileAccordion
            editMode={editMode}
            canEdit={canEdit}
            title={'Additional Information'}
            expandAll={props.expandAll}
          >
            <EditableTable
              addNew
              maxRows={4}
              edit={canEdit}
              columns={additionalInfoColumns}
              rows={profileInfo.CustomFields || []}
              emitNewRows={(e) => {
                setUpdatedProfile({
                  ...updatedProfile,
                  CustomFields: e.state,
                });
                setProfileInfo({
                  ...profileInfo,
                  CustomFields: e.state,
                });
              }}
            />
          </ProfileAccordion>

          <ProfileAccordion editMode={editMode} canEdit={canEdit} title={'User & Password'} expandAll={props.expandAll}>
            <div className="profile-row">
              <ProfileTextField title disabled value={'Username:'} />
              {profileId == 'new' ? (
                <ProfileTextField
                  edit={canEdit}
                  disabled={disabled}
                  required={!profileInfo.Entities?.[0].userName ? true : false}
                  error={formErrors?.userName ? true : false}
                  value={profileInfo.Entities?.[0].userName || ''}
                  onChange={(e) => {
                    let temp = _.cloneDeep(profileInfo.Entities);
                    temp[0].userName = e.target.value;
                    profileUpdate(temp, 'Entities');
                  }}
                />
              ) : (
                <>
                  <ProfileTextField
                    sx={{ fontWeight: 100 }}
                    title
                    disabled
                    value={profileInfo.Entities?.[0].userName}
                  />
                  <Button
                    variant="text"
                    onClick={() =>
                      setOpenUserSecurityDialog({
                        title: 'Update Username',
                        profileId: parseInt(profileId) || 0,
                        userName: profileInfo.Entities?.[0].userName || '',
                      })
                    }
                  >
                    Update Username
                  </Button>
                </>
              )}
            </div>
            <div className="profile-row">
              <ProfileTextField title disabled value={'Password:'} />
              {profileId == 'new' ? (
                <ProfileTextField
                  type={'password'}
                  edit={canEdit}
                  disabled={disabled}
                  required={!profileInfo.Entities?.[0].password ? true : false}
                  error={formErrors?.password ? true : false}
                  value={profileInfo.Entities?.[0].password || ''}
                  onChange={(e) => {
                    let temp = _.cloneDeep(profileInfo.Entities);
                    temp[0].password = e.target.value;
                    profileUpdate(temp, 'Entities');
                  }}
                />
              ) : (
                <>
                  <ProfileTextField title disabled value={'********'} />
                  <Button
                    variant="text"
                    onClick={() =>
                      setOpenUserSecurityDialog({
                        title: 'Change Password',
                        profileId: parseInt(profileId) || 0,
                      })
                    }
                  >
                    Change Password
                  </Button>
                </>
              )}
            </div>
            {profileId == 'new' && (
              <div className="profile-row">
                <ProfileTextField title disabled value={'Confirm Pass.:'} />
                <ProfileTextField
                  type={'password'}
                  edit={canEdit}
                  disabled={disabled}
                  required={!profileInfo.Entities?.[0].confirmPassword ? true : false}
                  error={formErrors?.confirmPassword ? true : false}
                  helperText={formErrors?.confirmPassword}
                  value={profileInfo.Entities?.[0].confirmPassword || ''}
                  onChange={(e) => {
                    let temp = _.cloneDeep(profileInfo.Entities);
                    temp[0].confirmPassword = e.target.value;
                    profileUpdate(temp, 'Entities');

                    if (profileInfo.Entities?.[0].password == e.target.value) {
                      setFormErrors({ ...formErrors, confirmPassword: '' });
                    }
                  }}
                />
              </div>
            )}
          </ProfileAccordion>
        </div>

        <div className="col">
          <ProfileAccordion editMode={editMode} canEdit={canEdit} title={'Phone Numbers'} expandAll={props.expandAll}>
            <EditableTable
              addNew
              minRows={1}
              maxRows={5}
              showErrorOnRow={formErrors?.workPhone ? true : false}
              required={formErrors?.workPhone ? true : false}
              edit={canEdit}
              lookups={lookupList.SelectPhoneTypes || []}
              columns={customerPhoneNumbers}
              rows={profileInfo.PhoneNumbers || []}
              emitNewRows={(e) => {
                setUpdatedProfile({
                  ...updatedProfile,
                  PhoneNumbers: e.state,
                });
                setProfileInfo({
                  ...profileInfo,
                  PhoneNumbers: e.state,
                });
              }}
            />
            <br />
            <Typography variant="subtitle2" fontWeight={'bold'}>
              Other Contact Info:
            </Typography>
            <AppTextarea
              maxRows={4}
              disabled={disabled}
              edit={canEdit}
              value={mapElementFreeText(7)?.value}
              onChange={(val) => {
                let temp = _.cloneDeep(profileInfo.ElementFreeTexts);
                let index = temp.map((object) => object.element).indexOf(7);
                temp[index].value = val;
                profileUpdate(temp, 'ElementFreeTexts');
              }}
            />
          </ProfileAccordion>

          <ProfileAccordion
            editMode={editMode}
            canEdit={canEdit}
            title={'Email Addresses/Website'}
            expandAll={props.expandAll}
          >
            <EditableTable
              addNew
              minRows={1}
              maxRows={2}
              showErrorOnRow={formErrors?.mainEmail ? true : false}
              required={formErrors?.mainEmail ? true : false}
              edit={canEdit}
              lookups={lookupList.SelectEmailTypes || []}
              columns={customerEmailColumns}
              rows={profileInfo.Emails || []}
              emitNewRows={(e) => {
                setUpdatedProfile({
                  ...updatedProfile,
                  Emails: e.state,
                });
                setProfileInfo({
                  ...profileInfo,
                  Emails: e.state,
                });
              }}
            />
            <br />
            <div className="profile-row">
              <ProfileTextField title value={'Website URL:'} />
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={mapElementFreeText(101)?.value || ''}
                onChange={(e) => {
                  let temp = _.cloneDeep(profileInfo.ElementFreeTexts);
                  let index = temp.map((object) => object.element).indexOf(101);
                  temp[index].value = e.target.value;
                  profileUpdate(temp, 'ElementFreeTexts');
                }}
              />
            </div>
          </ProfileAccordion>

          <ProfileAccordion
            editMode={editMode}
            canEdit={canEdit}
            title={'Other Customer Information'}
            expandAll={props.expandAll}
          >
            <div className="label-with-info mb">
              <div>Invoice Contact Emails:</div>
              <HtmlTooltip
                arrow
                maxwidth="400px"
                title={
                  <>
                    {'If input, the first email will be used for the invoice '}
                    {'instead of the contact email in the signing request. The '}
                    {'second and third emails will be added to the invoice email '}
                    {`as additional 'Send To' addresses.`}
                  </>
                }
              >
                <IconButton>
                  <InfoOutlinedIcon color="info" />
                </IconButton>
              </HtmlTooltip>
            </div>

            {/* keep div below */}
            <div></div>
            {/* keep div above */}

            <EditableTable
              addNew
              maxRows={3}
              edit={canEdit}
              columns={invoiceContactEmail}
              rows={profileInfo.OtherInformation?.invoiceContactEmails || []}
              emitNewRows={(e) => {
                let temp = _.cloneDeep(profileInfo.OtherInformation);
                temp.invoiceContactEmails = e.state;
                setUpdatedProfile({
                  ...updatedProfile,
                  OtherInformation: temp,
                });
                setProfileInfo({
                  ...profileInfo,
                  OtherInformation: temp,
                });
              }}
            />
            <br />
            <div className="label-with-info mb">
              <div>Default Invoice Company:</div>
              <HtmlTooltip arrow title={`Information to use on invoice if not the same as profile billing address.`}>
                <IconButton>
                  <InfoOutlinedIcon color="info" />
                </IconButton>
              </HtmlTooltip>
            </div>
            <div className="customer-row">
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={profileInfo.OtherInformation?.defaultInvoiceCompany || ''}
                onChange={(e) => {
                  let temp = _.cloneDeep(profileInfo.OtherInformation);
                  temp.defaultInvoiceCompany = e.target.value;
                  profileUpdate(temp, 'OtherInformation');
                }}
              />
            </div>
            <div className="customer-title">
              <Typography variant="body2" fontWeight="bold">
                Default Invoice Address:
              </Typography>
            </div>
            <div className="customer-row">
              <AppTextarea
                minRows={3}
                edit={canEdit}
                disabled={disabled}
                value={profileInfo.OtherInformation?.defaultInvoiceAddress}
                onChange={(val) => {
                  let temp = _.cloneDeep(profileInfo.OtherInformation);
                  temp.defaultInvoiceAddress = val;
                  profileUpdate(temp, 'OtherInformation');
                }}
              />
            </div>
            <div className="customer-title">
              <Typography variant="body2" fontWeight="bold">
                Default Shipper Name:
              </Typography>
            </div>
            <div className="customer-row">
              {editMode && canEdit ? (
                <ShippingCompany
                  value={profileInfo.OtherInformation?.defaultShipperName || ''}
                  ddlOnChange={(shippingCompany) => {
                    let temp = _.cloneDeep(profileInfo.OtherInformation);
                    temp.defaultShipperName = shippingCompany;
                    profileUpdate(temp, 'OtherInformation');
                  }}
                />
              ) : (
                <Typography variant="subtitle2">{profileInfo.OtherInformation?.defaultShipperName || ''}</Typography>
              )}
            </div>
            <div className="customer-title">
              <Typography variant="body2" fontWeight="bold">
                Default Shipper Acct#:
              </Typography>
            </div>
            <div className="customer-row">
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={profileInfo.OtherInformation?.defaultShipperAccount || ''}
                onChange={(e) => {
                  let temp = _.cloneDeep(profileInfo.OtherInformation);
                  temp.defaultShipperAccount = e.target.value;
                  profileUpdate(temp, 'OtherInformation');
                }}
              />
            </div>
            <div className="customer-title">
              <Typography variant="body2" fontWeight="bold">
                Default Return Shipping Instructions:
              </Typography>
              <Typography sx={{ paddingTop: '4px', paddingLeft: '4px' }} variant="caption">
                500 Characters
              </Typography>
            </div>
            {editMode && canEdit ? (
              <div className="customer-row">
                <AppTextarea
                  minRows={6}
                  maxLength={1000}
                  edit={canEdit}
                  disabled={disabled}
                  value={profileInfo.OtherInformation?.defaultShippingInstructions}
                  onChange={(val) => {
                    let temp = _.cloneDeep(profileInfo.OtherInformation);
                    temp.defaultShippingInstructions = val;
                    profileUpdate(temp, 'OtherInformation');
                  }}
                />
              </div>
            ) : (
              <div className="customer-row">
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={sanitizeHTML(profileInfo.OtherInformation?.defaultShippingInstructions)}
                />
              </div>
            )}
            <div className="customer-title">
              <Typography variant="body2" fontWeight="bold">
                Default Signing Instructions:
              </Typography>
              <Typography sx={{ paddingTop: '4px', paddingLeft: '4px' }} variant="caption">
                1000 Characters
              </Typography>
            </div>
            {editMode ? (
              <div className="customer-row">
                <AppTextarea
                  minRows={6}
                  maxLength={1000}
                  edit={canEdit}
                  disabled={disabled}
                  value={profileInfo.OtherInformation?.defaultSigningInstructions}
                  onChange={(val) => {
                    let temp = _.cloneDeep(profileInfo.OtherInformation);
                    temp.defaultSigningInstructions = val;
                    profileUpdate(temp, 'OtherInformation');
                  }}
                />
              </div>
            ) : (
              <div className="customer-row">
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={sanitizeHTML(profileInfo.OtherInformation?.defaultSigningInstructions)}
                />
              </div>
            )}
          </ProfileAccordion>
        </div>
      </StyledSection>

      <ProfileAccordion
        editMode={editMode}
        canEdit={canEdit}
        title={'Loan/Title Officer Defaults'}
        expandAll={props.expandAll}
      >
        <Box sx={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <RadioGroup row name="officers-group" value={officerRadio}>
              <FormControlLabel
                sx={{
                  marginLeft: '0px',
                  '.MuiTypography-root': { fontSize: '0.8rem' },
                }}
                label="Include Loan/Escrow Officer on Email Notices"
                labelPlacement="start"
                control={<Radio size="small" onClick={handleOfficeRadioButtons} />}
                disabled={!editMode}
                value="includeOfficerInEmail"
              />
              <FormControlLabel
                sx={{ '.MuiTypography-root': { fontSize: '0.8rem' } }}
                label="Use officer info as contact info"
                labelPlacement="start"
                control={<Radio size="small" onClick={handleOfficeRadioButtons} />}
                disabled={!editMode}
                value="useOfficerInfoAsContact"
              />
            </RadioGroup>
          </div>
          <EditableTable
            addNew
            needRender
            maxRows={15}
            edit={canEdit}
            columns={customerLoanOfficerDefaults}
            rows={profileInfo.Officers?.officerDefaults || []}
            emitNewRows={(e) => {
              let temp = _.cloneDeep(profileInfo.Officers);
              temp.officerDefaults = e.state;
              profileUpdate(temp, 'Officers');
            }}
          />
        </Box>
      </ProfileAccordion>
      <br />
      <br />
      {openUserSecurityDialog && (
        <UserPasswordUpdateDialog
          open={true}
          state={openUserSecurityDialog}
          buttonTrueText={'Update'}
          buttonFalseText={'Close'}
          onClose={(val) => {
            setOpenUserSecurityDialog(false);
            if (val) {
              let temp = _.cloneDeep(profileInfo.Entities);
              temp[0].userName = val;
              setProfileInfo({
                ...profileInfo,
                Entities: temp,
              });
            }
          }}
        />
      )}
    </Fragment>
  );
};

export default CustomerProfile;
