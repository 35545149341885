import React, { useState, useRef, useEffect, createContext, useReducer } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { contactManagerService as service, profileService, signingRequestService as srService } from '../services';
import { dispatchAlert } from '../helpers';
import { GetInitialFilterOptions } from '../components/contact-manager/filter/models';
import _ from 'lodash';

export const ContactManagerContext = createContext();

const contactMgtDDLOptions = [
  { id: 1, label: 'Personal Agent List', value: 'Personal', type: 'agent' },
  {
    id: 2,
    label: 'Agent Availability Responses',
    value: 'AvailabilityResponses',
    type: 'agent',
  },
  { id: 3, label: 'Global Agent List', value: 'Global', type: 'agent' },
  { id: 4, label: 'All Agents', value: 'All', type: 'agent' },
  { id: 5, label: 'Agent Registrations - Pending', value: 'RegPending', type: 'agent' },
  { id: 6, label: 'Agent Registrations - Approved', value: 'RegApproved', type: 'agent' },
  { id: 7, label: 'Agent Registrations - Declined', value: 'RegDeclined', type: 'agent' },
  { id: 8, label: 'Customers', value: 'Customer', type: 'customer' },
];

const pageSizesForList = [
  { value: 0, label: '' },
  { value: 5, label: '5 rows' },
  { value: 10, label: '10 rows' },
  { value: 15, label: '15 rows' },
  { value: 25, label: '25 rows' },
  { value: 50, label: '50 rows' },
];

const initialFilterPayload = {
  onProximity: false,
  listSelection: 'Personal',
  requestID: 0,
  currentPageNumber: 1,
  pageSize: 0,
  sortField: 'LastName',
  alpha: 'All',
  multiSearch: '',
  state: '',
  city: '',
  zip: '',
  isApproved: '-1',
  groupID: 0,
  sO_Rating: 0,
  zipSearch: '',
  searchWithin: 50,
  showHidden: false,
  pendingReg: 0,
  buttonPress: false,
  publicOnly: 0,
  capabilities: [],
  backgroundChecks: [],
  languages: [],
  multiSearchHolder: '',
  zipSearchHolder: '',
};

const customerFilters = [];

const agentFilters = [
  {
    id: 'sO_Rating',
    label: 'Rating',
    type: 'dropdown',
    dataType: 'number',
    backendValProp: 'value',
    disableClearable: true,
  },
  {
    id: 'languages',
    label: 'Language',
    type: 'dropdown',
    sortBy: 'value',
    dataType: 'array',
  },
  {
    id: 'capabilities',
    label: 'Capabilities',
    type: 'dropdown',
    sortBy: 'value',
    dataType: 'array',
  },
  {
    id: 'backgroundChecks',
    label: 'Background',
    type: 'dropdown',
    sortBy: 'value',
    dataType: 'array',
  },
  {
    id: 'agentRegDocs',
    label: 'Agent Docs',
    type: 'dropdown',
    dataType: 'dropdown',
    sortBy: 'value',
    useCustomForm: true,
    otherOptions: {
      expiration: [
        {
          index: '0',
          value: 'Include Expired',
        },
        {
          index: '1',
          value: 'Exclude Expired',
        },
      ],
    },
  },
];

const filtersCommon = [];

const filterOptions = (type, listSelection) => {
  let moreFiltersByUserType = [];
  if (listSelection == 'Customer') moreFiltersByUserType = customerFilters;
  else moreFiltersByUserType = agentFilters;

  if (type === 'advanced') {
    let advancedFilters = [
      { id: 'city', label: 'City', type: 'input' },
      {
        id: 'state',
        label: 'State',
        type: 'dropdown',
        backendValProp: 'value2',
        disableClearable: true,
      },
      { id: 'zip', label: 'Zip', type: 'input' },
    ];
    return advancedFilters.concat(filtersCommon).concat(moreFiltersByUserType);
  } else {
    let proximityFilters = [
      {
        id: 'searchWithin',
        label: 'Zip Within',
        type: 'input',
        permanent: true,
        value: 50,
      },
    ];
    return proximityFilters.concat(filtersCommon).concat(moreFiltersByUserType);
  }
};

const selectedEmailCountLimit = 10;

export const getProfileLookupsForDDL = async () => {
  return await profileService.getProfileLookupsForDDL().then((res) => res);
};

function assignNotaryReducerState(state, action) {
  switch (action.type) {
    case 'setLoading':
      return {
        ...state,
        propFeesIsLoading: action.payload.propFeesIsLoading,
        agreedFeesIsLoading: action.payload.agreedFeesIsLoading,
      };
    case 'fillTable':
      return {
        ...state,
        propFeesIsLoading: false,
        agreedFeesIsLoading: false,
        proposedFees: action.payload.proposedFees,
        agreesFees: action.payload.agreesFees,
      };
    case 'setApptDialog':
      return {
        ...state,
        apptDialog_onClick: action.payload.apptDialog_onClick,
        apptDialog_onLoading: action.payload.apptDialog_onLoading,
        apptDialog_onResponse: action.payload.apptDialog_onResponse,
        type: 'Confirm',
        icon: <SendOutlinedIcon className="rotate" />,
      };
    case 'setCustomChanges':
      return {
        ...state,
        savingCustomChanges: action.payload.savingCustomChanges,
      };
    default:
      '';
  }
}

const ContactManagerProvider = (props) => {
  const mountedRef = useRef(true);
  const history = useHistory();
  const search = useLocation().search;

  const { fromManageRequest = true, fromAssignNotary, fromContactManager } = props;

  /* from url query or params */
  const fromDashboard = history?.location?.state?.fromDashboard;
  const zip = new URLSearchParams(search).get('zip');
  const requestZipCode = zip || history?.location?.state?.zip;
  let fromRequestList = history?.location?.state?.fromRequestList;
  let assignedAgentFromRoute = history?.location?.state?.assignedAgent;
  /**********/

  const [requestController, setRequestController] = useState();
  const [assignNotaryState, setAssignNotaryState] = useState();
  const [requestID, setRequestID] = useState(useParams()?.requestID);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [renderList, setRenderList] = useState(false);
  const [renderListView, setRenderListView] = useState(0);
  const [loadingDataList, setLoadingDataList] = useState();
  const [filterPayLoad, setFilterPayLoad] = useState(initialFilterPayload);
  const [controlSetting, setControlSetting] = useState();
  const [profileLookups, setProfileLookups] = useState();
  const [appliedFilters, setAppliedFilters] = useState(() => {
    if (assignNotaryState) {
      return GetInitialFilterOptions([...filterOptions('proximity', filterPayLoad.listSelection)]);
    } else {
      return GetInitialFilterOptions([...filterOptions('advanced', filterPayLoad.listSelection)]);
    }
  });
  const [listMode, setListMode] = useState('contacts');
  const [dataList, setDataList] = useState();
  const [pagination, setPagination] = useState('');
  const [selectedEmailCount, setSelectedEmailCount] = useState(0);
  const [agentEmailRecipients, setAgentEmailRecipients] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState();
  const agreedFees = useRef();

  const [assignNotaryReducer, setAssignNotaryReducer] = useReducer(assignNotaryReducerState, {
    propFeesIsLoading: false,
    agreedFeesIsLoading: false,
    apptDialog_onClick: false,
    apptDialog_onLoading: false,
    apptDialog_onResponse: null,
  });

  useEffect(() => {
    let removePrevBread = history?.location?.state?.removePrevBread;
    let _breadCrumb = [];

    const calcBreadcrumb = async () => {
      const popBreads = () => {
        if (removePrevBread) return breadCrumb.length ? breadCrumb.slice(0, -1) : [];
        else return [...breadCrumb];
      };
      _breadCrumb = await popBreads();
      setBreadCrumb([..._breadCrumb, history?.location?.pathname]);
    };
    calcBreadcrumb();

    return () => {
      setBreadCrumb([]);
      requestController?.abort();
    };
  }, [history.location]);

  useEffect(() => {
    if (mountedRef.current) getProfileLookupsForDDL().then((res) => setProfileLookups(res));

    if (fromAssignNotary) {
      let theZipCode = assignNotaryState?.signingZip || requestZipCode;
      if (theZipCode) proximityToggle(true, theZipCode);
    } else {
      proximityToggle(false, '');
    }

    return () => {
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, [assignNotaryState?.signingZip]);

  useEffect(() => {
    if (appliedFilters && profileLookups) {
      setAppliedFilters(mapSelectTypesForFancyFilter(profileLookups, appliedFilters));
    }

    return () => {};
  }, [profileLookups, assignNotaryState?.languagePref, filterPayLoad?.onProximity]);

  /*
    * resetFilterAndOrGetContactList: when swithching from Agent to Customer there are filter that don't apply for both_
      therefore the filter needs to be remove and/or reassign.
  */
  const prevListSelection = useRef();
  useEffect(() => {
    let option = contactMgtDDLOptions.find((i) => i.value == filterPayLoad.listSelection);
    if (prevListSelection.current && prevListSelection.current?.value !== filterPayLoad.listSelection) {
      if (option.type !== prevListSelection.current.type) resetFilterAndOrGetContactList(true);
      else getContactList(); // if DDL selection if of the same agent type then just pull as always.
    }

    prevListSelection.current = { value: filterPayLoad.listSelection, type: option.type };
    return () => {};
  }, [filterPayLoad.listSelection]);

  const assignAgentToRequest = async (agentPublicID) => {
    let action = agentPublicID ? 'Assign' : 'UnAssign';
    setAssignNotaryState({ ...assignNotaryState, agreedFees: [] });
    setAssignNotaryReducer({ type: 'setLoading', payload: { ...assignNotaryReducer, agreedFeesIsLoading: true } });

    await srService.assignAgentToRequest(requestID, agentPublicID, action).then(
      (res) => {
        if (action == 'Assign') {
          dispatchAlert('Agent was assigned successfully');
          setAssignNotaryState({
            publicId: agentPublicID,
            name: res.notaryNameAndCompany,
            agreedFees: JSON.parse(res.agentAgreedFees),
          });
          agreedFees.current = JSON.parse(res.agentAgreedFees);
          setListMode('contacts');
        } else {
          dispatchAlert('Agent was unassigned successfully');
          assignedAgentFromRoute = null;
          setAssignNotaryState({ publicId: '', name: '', agreedFees: [] });
          agreedFees.current = [];
        }
      },
      (error) => dispatchAlert(error, true)
    );

    setAssignNotaryReducer({
      type: 'setLoading',
      payload: {
        ...assignNotaryReducer,
        agreedFeesIsLoading: false,
        propFeesIsLoading: false,
      },
    });
  };

  // DLL options that comes from API CALL
  const mapSelectTypesForFancyFilter = (lookups, applyFilters) => {
    applyFilters.map((item) => {
      switch (item.id) {
        case 'state':
          item.options = lookups?.SelectStates;
          break;
        case 'capabilities':
          item.options = lookups?.SelectCapabilityTypes;
          break;
        case 'backgroundChecks':
          item.options = lookups?.SelectProviderTypes;
          break;
        case 'languages':
          item.options = lookups?.SelectLanguageTypes;

          // below IF is a workaround to avoid double api call from Contact Manager
          if (history.location?.pathname.startsWith('/manage-request/assign-notary')) {
            if (assignNotaryState?.languagePref) {
              let languages = lookups?.SelectLanguageTypes.filter((item) =>
                assignNotaryState.languagePref.split(',').includes(item.value)
              );
              item.value = languages;
              setFilterPayLoad((values) => ({ ...values, languages: [...values.languages, ...languages] }));
              getContactList({ ...filterPayLoad, languages: [...filterPayLoad.languages, ...languages] });
            } else getContactList();
          }
          break;
        case 'agentRegDocs':
          item.options = lookups.RegCustomDocs;
          break;
      }
    });
    return applyFilters;
  };

  const getContactList = async (params = filterPayLoad, onPagination = false) => {
    //cancel the previous request
    if (requestController) requestController.abort();
    //set a new AbortController instance to the reducer
    let newController = new AbortController();
    let signal = newController.signal;
    setRequestController(newController);

    setLoadingDataList(true);
    let payload = {
      ...params,
      currentPageNumber: !onPagination ? 1 : params.currentPageNumber,
      onAssignNotary: requestID ? true : false,
    };
    setFilterPayLoad(payload);
    return await service.getContactList(payload, signal).then(
      (res) => {
        // if (!mountedRef.current) return null;
        setDataList(res.contactList || []);
        setPagination(res.pagination);
        setControlSetting(res.settings);
        setLoadingDataList(false);
        return res;
      },
      (error) => {
        if (typeof error === 'string') {
          if (!error?.toString().includes('aborted')) {
            setLoadingDataList(false);
            dispatchAlert(error, true);
            return error;
          }
        }
      }
    );
  };

  const currentPaginationPageSize = () => {
    let defaultRowNumber = pagination?.pageSize || 0;
    if (!defaultRowNumber) return 5; // is the minimun option to select in the ddl.
    return filterPayLoad.pageSize ? filterPayLoad.pageSize : parseInt(defaultRowNumber);
  };

  const handlePageSize = (e) => {
    let val = e.target?.value ? e.target.value : e;
    if (filterPayLoad.currentPageNumber > Math.ceil(pagination.totalRecords / val)) {
      setFilterPayLoad({ ...filterPayLoad, pageSize: val, currentPageNumber: 1 });
      getContactList({ ...filterPayLoad, pageSize: val, currentPageNumber: 1 }, true);
    } else {
      setFilterPayLoad({ ...filterPayLoad, pageSize: val });
      getContactList({ ...filterPayLoad, pageSize: val }, true);
    }
  };

  const handlePageNumber = (e) => {
    let val = 1;
    if (typeof e === 'number') val = e;
    else val = e.target?.value ? e.target.value : e;

    setFilterPayLoad({ ...filterPayLoad, currentPageNumber: val });
    getContactList({ ...filterPayLoad, currentPageNumber: val }, true);
  };

  const handleSort = (sorter) => {
    getContactList({ ...filterPayLoad, sortField: sorter });
  };

  const proximityToggle = (event, mainInput, forcePull = false) => {
    let payloadReset = initialFilterPayload;
    payloadReset.onProximity = event;
    payloadReset.sortField = event ? 'Miles' : 'LastName';
    payloadReset.listSelection = fromDashboard ? 'Customer' : filterPayLoad.listSelection;
    payloadReset.requestID = parseInt(requestID) || 0;
    payloadReset.multiSearchHolder = filterPayLoad.multiSearchHolder;
    payloadReset.zipSearchHolder = filterPayLoad.zipSearchHolder;

    if (event) {
      payloadReset.searchWithin = 50;
      payloadReset.multiSearch = undefined;
      payloadReset.zipSearch = mainInput;
      payloadReset.zipSearchHolder = mainInput;
      setFilterPayLoad(payloadReset);
      setAppliedFilters(reloadFilterMenuList('proximity'));
      setRenderList(true);
      if (forcePull) getContactList(payloadReset);
    } else {
      setAppliedFilters(reloadFilterMenuList('advanced'));
      payloadReset.listSelection = 'Personal';
      payloadReset.multiSearch = mainInput;
      payloadReset.multiSearchHolder = mainInput;
      payloadReset.zipSearch = undefined;
      payloadReset.searchWithin = undefined;
      setFilterPayLoad(payloadReset);
      setRenderList(true);
      if (forcePull) getContactList(payloadReset);
    }
  };

  const reloadFilterMenuList = (type) => {
    let _proxiFitlers = GetInitialFilterOptions([...filterOptions(type, filterPayLoad.listSelection)]);

    let updatedFilterForState = _proxiFitlers.map((prox) => {
      let _options = [];
      if (prox.type === 'dropdown') {
        _options = appliedFilters.find((appl) => appl.id == prox.id)?.options;
      }
      return { ...prox, options: _options };
    });
    return updatedFilterForState;
  };

  const resetFilterAndOrGetContactList = async (triggerPull = false) => {
    async function leftJoinArrays(leftArray, rightArray, leftKey, rightKey) {
      const promises = leftArray.map(async (leftElement) => {
        const matchingRightElement = rightArray.find((rightElement) => leftElement[leftKey] === rightElement[rightKey]);
        return { ...leftElement, ...matchingRightElement };
      });
      return Promise.all(promises);
    }

    async function mapFilterToApiPayload(filters = []) {
      let payload = { ...filterPayLoad };
      Object.keys(payload).map((key) => {
        const filter = filters.find((el) => el.id === key);
        if (!filter) {
          let mustKeep = ['multiSearch', 'multiSearchHolder', 'zipSearch', 'zipSearchHolder'].includes(key);
          return (payload[key] = mustKeep ? payload[key] : initialFilterPayload[key]);
        } else if (filter.value && filter.backendValProp && typeof filter.value === 'object') {
          return (payload[filter.id] = filter.value[filter.backendValProp]);
        } else if (filter.value) {
          return (payload[filter.id] = filter.value);
        } else {
          return (payload[filter.id] = initialFilterPayload[filter.id]);
        }
      });
      return payload;
    }

    let pullType = filterPayLoad?.onProximity ? 'proximity' : 'advanced';
    let initialOptions = GetInitialFilterOptions([...filterOptions(pullType, filterPayLoad.listSelection)]);

    const newAppliedFilters = await leftJoinArrays(initialOptions, appliedFilters, 'id', 'id');
    const newPayload = await mapFilterToApiPayload(newAppliedFilters);

    setAppliedFilters(mapSelectTypesForFancyFilter(profileLookups, newAppliedFilters));

    if (triggerPull) getContactList({ ...newPayload, listSelection: filterPayLoad.listSelection });
  };

  const totalPages = Math.ceil(pagination?.totalRecords / currentPaginationPageSize() || 1);

  return (
    <ContactManagerContext.Provider
      value={{
        breadCrumb,
        fromManageRequest,
        fromRequestList,
        loadingDataList,
        dataList,
        pagination,
        initialFilterPayload,
        filterPayLoad,
        controlSetting,
        appliedFilters,
        contactMgtDDLOptions,
        listMode,
        totalPages,
        pageSizesForList,
        requestID,
        requestZipCode,
        assignNotaryState,
        assignedAgentFromRoute,
        selectedEmailCount,
        selectedEmailCountLimit,
        agentEmailRecipients,
        agreedFees,
        assignNotaryReducer,
        profileLookups,
        renderList,
        fromAssignNotary,
        fromContactManager,
        emailTemplate,
        renderListView,
        setRenderListView,
        setRenderList,
        setBreadCrumb,
        setRequestID,
        setAssignNotaryState,
        getProfileLookupsForDDL,
        setEmailTemplate,
        setAssignNotaryReducer,
        setAgentEmailRecipients,
        assignAgentToRequest,
        setSelectedEmailCount,
        proximityToggle,
        getContactList,
        setListMode,
        setAppliedFilters,
        setFilterPayLoad,
        setDataList,
        currentPaginationPageSize,
        handlePageSize,
        handlePageNumber,
        handleSort,
      }}
    >
      {props.children}
    </ContactManagerContext.Provider>
  );
};

export default ContactManagerProvider;
