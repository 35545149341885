import React, { useCallback } from 'react';
import { useDirtyChangesStore } from '../../context/dirty-changes.store';
import { useCloseModal, useModal } from './useModals';
import AppConfirmationModal from '../../components/common/others/AppConfirmationModal';

const dirtyModalConfig = {
  title: 'Are you sure?',
  content: 'You have unsaved changes, are you sure you want to leave?',
  buttonTrueText: 'Yes',
  buttonFalseText: 'No',
};

export const useDirtyChangesPrompt = () => {
  const cleanDirtyState = useDirtyChangesStore((s) => s.reset);
  const hasDirtyChanges = useDirtyChangesStore((s) => s.isDirty);

  const { showModal } = useModal({
    template: <AppConfirmationModal />,
    PaperProps: { elevation: 2 },
  });

  const { closeLatestModal } = useCloseModal();

  const handleClose = useCallback(
    (callback) => (isConfirmed) => {
      closeLatestModal();
      if (isConfirmed) {
        cleanDirtyState();
        callback(isConfirmed);
      }
    },
    [closeLatestModal, cleanDirtyState]
  );

  const promptDirtyChanges = useCallback(
    (config, callback, showPrompt = false) => {
      if (hasDirtyChanges || showPrompt) {
        showModal({
          ...dirtyModalConfig,
          onClose: handleClose(callback),
        });
      } else {
        callback();
      }
    },
    [hasDirtyChanges]
  );

  return { promptDirtyChanges };
};

export default useDirtyChangesPrompt;
