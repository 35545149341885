import React, { Fragment } from 'react';
import { css, useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { userService } from '../../../services';
import { Avatar, Chip, Divider, Drawer, Link } from '@mui/material';
import TimeCountDown from '../../common/others/TimeCountDown';
import Items from './Items';

const StyledMenu = styled(Drawer)(
  ({ theme, viewport, state }) => css`
    position: relative;
    display: ${['expanded', 'icons'].includes(state.current) ? 'block' : 'none'};
    width: ${state.width};
    top: 50px;
    border-top: 0;

    .MuiPaper-root {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      overflow: auto;
      width: ${state.width};
      border-top: 0;
      margin-top: ${['xs', 'sm', 'xsm'].includes(viewport) ? '0px' : '50px'};
      z-index: auto;
    }

    .MuiPaper-root::-webkit-scrollbar {
      width: 5px;
    }

    .MuiPaper-root::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1.5px solid lightgrey; //border externo
      border-radius: 5px;
    }

    .MuiPaper-root::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.primary.main};
      /* border: 2px solid #616161; //border interno */
      border-radius: 5px;
    }

    #menu-items {
      flex: 1;
    }

    #menu-bottom {
      bottom: 50px;
      position: sticky;
      width: 100%;
      z-index: 100;
      background-color: inherit;

      #menu-dev-info {
        text-align: center;

        .appTimer {
          display: flex;
          justify-content: center;

          div:first-child {
            color: brown;
            font-weight: 500;
            padding-right: 10px;
          }
        }
      }

      .legal-info {
        display: flex;
        justify-content: center;
        margin: 10px 0px 10px;

        hr {
          margin: 0;
          margin-left: 4px;
          margin-right: 5px;
        }
      }

      .portal-logo {
        img {
          max-width: 200px;
          display: block;
          margin: auto;
        }
      }
    }
  `
);

// Menu states are "expanded  | icons | hidden"

const Menu = ({ store: store, ...props }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { appViewport, menuState, global, portalTabs } = store;

  const sortMenuItems = portalTabs.sort((a, b) => (a.tabOrder > b.tabOrder ? 1 : -1));

  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../../../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  const drawerContent = (
    <>
      <div id="menu-items">
        <Items viewport={appViewport} menuState={menuState} items={sortMenuItems} />
      </div>
      <div id="menu-bottom">
        {menuState.current === 'expanded' && (
          <>
            <div id="menu-dev-info">
              {['development', 'staging'].includes(BUILD_ENV) && (
                <Fragment>
                  <Chip
                    style={{
                      flexDirection: 'row-reverse',
                      padding: '17px',
                    }}
                    variant="outlined"
                    size="small"
                    label={`${BUILD_ENV === 'staging' ? 'QA' : 'DEV'} - Viewport`}
                    avatar={
                      <Avatar
                        style={{
                          color: 'white',
                          padding: '15px',
                          textTransform: 'uppercase',
                          marginRight: '-15px',
                        }}
                      >
                        {appViewport}
                      </Avatar>
                    }
                  />
                  {userService.tokenTimeRemaining() && (
                    <div className="appTimer">
                      <div>Timeout in: </div>
                      <TimeCountDown timeInSecondsLef={userService.tokenTimeRemaining()} setTimeCountDown={() => {}} />
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            {global.logoFile && (
              <div className="portal-logo">
                {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
              </div>
            )}
            <div className="legal-info">
              <Link
                component={RouterLink}
                color="primary"
                variant="caption"
                underline="hover"
                to="/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms Of Use <OpenInNewOutlinedIcon sx={{ height: '12px' }} />
              </Link>
              <Divider sx={{ m: 1 }} orientation="vertical" flexItem></Divider>
              <Link
                component={RouterLink}
                color="primary"
                variant="caption"
                underline="hover"
                to="/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Statement <OpenInNewOutlinedIcon sx={{ height: '12px' }} />
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );

  const container = !window ? () => window().document.body : undefined;

  return (
    <>
      <StyledMenu
        state={menuState}
        viewport={appViewport}
        container={container}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={menuState.current === 'expanded' ? true : false}
        onClose={() => dispatch({ type: 'SET_MENU_STATE', payload: 'hidden' })}
        ModalProps={{ keepMounted: true }}
        sx={{ display: { sm: 'block', md: 'none' } }}
      >
        {sortMenuItems.length > 0 && drawerContent}
      </StyledMenu>
      <StyledMenu
        state={menuState}
        viewport={appViewport}
        variant={appViewport == 'xsm' ? 'temporary' : 'permanent'}
        open={menuState.current === 'expanded' ? true : false}
        onClose={() => dispatch({ type: 'SET_MENU_STATE', payload: appViewport == 'xsm' ? 'icons' : 'hidden' })}
        style={{ display: appViewport == 'xsm' ? 'block !important' : '' }}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        {sortMenuItems.length > 0 && drawerContent}
      </StyledMenu>
    </>
  );
};

const mapStateToProps = (state) => ({ store: state.reducer });
export default connect(mapStateToProps)(Menu);
