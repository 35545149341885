import React, { useState, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PropTypes from 'prop-types';

const FilterMenuList = (props) => {
  const { builderRef, options = [] } = props;
  const menuRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [openCustomForm, setOpenCustomForm] = useState(false);

  const handleClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) return;
    setOpenMenu(!openMenu);
    props.onClose(!openMenu);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={menuRef}>
        <Popper
          sx={{ zIndex: 1 }}
          transition
          open={openMenu}
          anchorEl={builderRef.current}
          // placement={'right-start'}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <MenuList dense>
                  {options.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <MenuItem
                          id={item.id}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenForm(false);
                            setOpenCustomForm(false);
                            setTimeout(() => {
                              item.useCustomForm
                                ? setOpenCustomForm(item)
                                : setOpenForm(item);
                            }, 300);
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      </div>
                    );
                  })}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
        {openCustomForm &&
          props.onSelectCustomForm({
            menuRef,
            openCustomForm,
            setOpenCustomForm,
          })}
        {openForm && props.onSelectForm({ menuRef, openForm, setOpenForm })}
      </div>
    </ClickAwayListener>
  );
};

FilterMenuList.propTypes = {
  builderRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onClose: PropTypes.func.isRequired,
};

export default FilterMenuList;
