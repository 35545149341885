import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CustomerDDL from './CustomerDDL';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const RequestWizard = (props) => {
  const classes = useStyles();
  const { onClose, open } = props;
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  useEffect(() => {
    return () => {
      setSelectedCustomer(null);
    };
  }, [open]);

  const handleClose = (event) => {
    event.preventDefault();
    let btnEvent = event.target.textContent;
    switch (btnEvent) {
      case 'Cancel':
        onClose('cancel');
        return;
      case 'Next':
        onClose(selectedCustomer);
        return;
      default:
    }
  };

  return (
    <Dialog
      aria-labelledby="request-wizard-dialog"
      className={classes.root}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="request-wizard-dialog">Select a Customer</DialogTitle>
      <DialogContent dividers>
        {`By selecting a customer, the Signing Request form will be pre filled with the selected customer information.`}
        <br />
        <CustomerDDL
          isWizard
          value={selectedCustomer}
          onChange={setSelectedCustomer}
        />
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={selectedCustomer !== null ? false : true}
          variant="contained"
          onClick={handleClose}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestWizard;
