import React from 'react';

const CommonInfo = (props) => {
  return (
    <>
      <div>
        <div>
          The following fields may be used in the message text below. The system will automatically replace the text
          with the actual information when the message is utilized.
        </div>
      </div>
      <div>
        <div>
          <p style={{ textDecorationLine: 'underline' }}>Can be used in the subject and message:</p>
          [SignerFirstName], [SignerLastName], [CoSignerFirstName], [CoSignerLastName], [RequestNumber], [RequestDate],
          [RequestTime], [RequestCity] ,[LoanNumber], [LoanNumber2]
        </div>
      </div>
      <div>
        <div>
          <p style={{ textDecorationLine: 'underline', marginBottom: 2 }}>Can only be used in the message:</p>
          <p style={{ marginBottom: 10 }}>
            General Items: [AgentFullName], [AgentPhoneNumbers], [AgentAddress], [AgentEmail], [AgentFees],
            [CustomerFees], [LoanOfficerCompany], [ContactName], [CustomerContactInfo], [ContactPhone], [ContactCell]
            [ContactFax], [ContactEmail], [LoanOfficerName], [LoanOfficerPhone], [OtherContactInfo],
            [SigningInstructions], [WillSendDocsVia], [Language], [ShipperAndAccount], [ReturnShippingInstructions],
            [SigningAddress1], [SigningAddress2], [SigningCity], [SigningState], [SigningZip], [HomeAddress1],
            [HomeAddress2], [HomeCity], [HomeState], [HomeZip], [SigningDaytimePhone], [SigningEveningPhone],
            [SigningOtherPhone], [SigningOtherContactInfo], [LoanTypes], [ShippingCompanyDocsOut],
            [TrackingNumberDocsOut], [NumOfLoans]
          </p>
          <p style={{ marginBottom: 10 }}>
            Completion Items: [CompletedDateTime], [DateDocsReturned], [ShippingCompany], [TrackingNumber],
            [ShippingCompany2], [TrackingNumber2], [CompletionComments]
          </p>
          <p style={{ marginBottom: 10 }}>
            Note:If an alternate signing address is input, the code [SigningLocationName], [SigningAddress1],
            [SigningAddress2], [SigningCity], [SigningState], [SigningZip] will display both the home address and the
            signing address. Also, the code [RequestCity] will show the alternate signing address city if one is input.
          </p>
        </div>
      </div>
    </>
  );
};

export default CommonInfo;
