import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles, useTheme } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import { F } from '../../helpers/functions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLoadingStore } from '../../context/loading.store';

const useStyles = makeStyles((theme, context) => ({
  root: {
    '& .MuiLoadingButton-loadingIndicator': {
      marginTop: '0',
      right: 'auto',
      marginLeft: '10px',
      position: 'relative',
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRadioGroup = ({ handleChoiceChanged = F }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [customInput, setCustomInput] = useState('');

  useEffect(() => {
    handleChoiceChanged(!!customInput ? customInput : selectedValue);
  }, [selectedValue, customInput]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value !== 'custom') {
      // Clear custom input if another option is selected
      setCustomInput('');
    }
  };

  const declineOptions = [
    'I need more information before agreeing to your service agreement.',
    'I do not agree with the terms of your service agreement.',
  ];

  const handleCustomInputChange = (event) => {
    setCustomInput(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="subtitle1" align="left" sx={{ fontSize: '16px' }}>
          Please select a reason for declining our agreement
        </Typography>
      </FormLabel>
      <RadioGroup value={selectedValue} onChange={handleChange}>
        {declineOptions.map((opt, i) => (
          <FormControlLabel key={i} value={opt} control={<Radio />} label={opt} />
        ))}
        <FormControlLabel value="custom" control={<Radio />} label="I have another reason." />
      </RadioGroup>
      {selectedValue === 'custom' && (
        <TextField
          label="Reason for declining"
          variant="outlined"
          value={customInput}
          onChange={handleCustomInputChange}
          multiline
          rows={4}
          fullWidth
        />
      )}
    </FormControl>
  );
};

const DeclineAgreementModal = ({ open, handleDecline, handleClose, ...props }) => {
  const [choice, setChoice] = useState('');
  const theme = useTheme();
  const classes = useStyles(theme);
  const loading = useLoadingStore((state) => state.loading);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDeclineWithChoice = useCallback(() => {
    handleDecline(choice);
  }, [choice]);

  // Disable decline button when no reason is selected
  const disableDecline = choice.length === 0 || choice === 'custom';

  return (
    <>
      <DialogTitle className={classes.header}>
        Are you sure you want to decline this agreement?
        <br />
      </DialogTitle>
      <DialogContent sx={{ padding: isMobile ? '0' : '10px', paddingBottom: 0 }}>
        <CustomRadioGroup handleChoiceChanged={setChoice} />
      </DialogContent>
      <DialogActions className={classes.root} sx={{ flexDirection: 'column' }}>
        <Stack
          direction="row"
          useFlexGap
          spacing={{ xs: 1, sm: 2, p: 2 }}
          sx={{ width: '100%', justifyContent: 'center' }}
        >
          <LoadingButton
            loading={loading}
            variant="outlined"
            color={disableDecline ? 'text' : 'error'}
            onClick={handleDeclineWithChoice}
            disabled={disableDecline}
            fullWidth
            focusRipple
          >
            I Decline
          </LoadingButton>

          <Button variant="contained" disabled={loading} color="info" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default DeclineAgreementModal;
