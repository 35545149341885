import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export const InvoiceCss = styled('div')(
  ({ theme }) => css`
    .invoice-title {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      margin: 10px 0px 10px 10px;
    }

    .card-title {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin: 5px 0px 5px 0px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        width: 100%;
        display: inline-block !important;
      }
    }

    .card-sub-title {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }

    .control-group {
      display: flex;
      gap: 10px;
    }

    ${theme.breakpoints.down('sm')} {
      .control-group {
        display: block;
      }
    }

    .card {
      margin-bottom: 5px;
      @media screen and (min-width: 600px) {
        margin-bottom: 10px;
      }

      ${theme.breakpoints.down('sm')} {
        .MuiCardContent-root {
          padding: 8px;
        }
      }
    }

    .flex-container {
      @media screen and (min-width: 600px) {
        display: flex;
      }
    }

    .card-company {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 50%;
        margin-right: 5px;
      }
    }

    .card-customer {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 50%;
        margin-left: 5px;
      }
    }

    .flex-email-container {
      display: flex;
    }

    .emails-container {
      width: 100%;
      margin-right: 5px;
    }

    .emails-add {
      margin-top: 5px;
    }

    .invoice-comments {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 50%;
        margin-right: 15px;
      }
    }

    .borrower-info {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 50%;
        margin-left: 15px;
      }
    }

    .buttons {
      text-align: right;
      padding: 10px 10px 10px 0px;
      @media screen and (max-width: 600px) {
        padding: 0px 10px 10px 0px;
      }
    }

    .button {
      margin-right: 10px;
      @media screen and (max-width: 600px) {
        margin: 5px 5px 5px 5px;
      }
    }

    .totals {
      width: 120px;
    }

    .terms {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 400px;
      }
    }

    .totals-container {
      width: 50%;
      text-align: right;
    }

    .terms-container {
      width: 50%;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 1000px) {
      .card-title {
        display: block;

        .buttons {
          text-align: left;
          padding: 0px 10px 10px 10px;
        }
      }
    }
  `
);

export const InvoicePaymentsCss = styled('div')(
  ({ theme }) => css`
    .card {
      margin-bottom: 5px;
      @media screen and (min-width: 600px) {
        margin-bottom: 10px;
      }
    }

    .totals-container {
      width: 50%;
      float: right;
      padding-top: 5px;
      @media screen and (min-width: 600px) {
        width: 30%;
      }
    }

    .card-agent-title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin: 5px 0px 5px 0px;
      @media screen and (max-width: 600px) {
        width: 100%;
        display: inline-block !important;
      }
    }

    .agent-invoice-title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin: 10px 0px 10px 0px;
    }

    .agent-invoice-sub-title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin: 10px 0px 10px 0px;
    }

    .card-title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin: 5px 0px 5px 0px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        width: 100%;
        display: inline-block !important;
      }
    }

    .invoice-title {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      margin: 10px 0px 10px 10px;
      @media screen and (max-width: 600px) {
        margin: 10px 0px 0px 10px;
      }
    }

    .buttons {
      text-align: right;
      padding: 10px 10px 10px 0px;
      @media screen and (max-width: 600px) {
        padding: 0px 10px 10px 0px;
      }
    }

    .button {
      margin-right: 10px;
      @media screen and (max-width: 600px) {
        margin: 5px 5px 5px 5px;
      }
    }

    .flex-container {
      @media screen and (min-width: 600px) {
        display: flex;
      }
    }

    .card-table {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 60%;
        margin-right: 5px;
      }
    }

    .card-comments {
      width: 100%;
      @media screen and (min-width: 600px) {
        width: 40%;
        margin-left: 5px;
      }
    }

    .totals {
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .bold {
      font-weight: 500;
    }

    .link {
      cursor: pointer;
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    }

    .link-status {
      text-align: center;
      margin-top: 10px;
    }

    //agent payment management

    .header-name {
      width: 57%;
      display: inline-table;
      font-weight: 500;
      text-align: center;
      @media screen and (max-width: 600px) {
        width: 65%;
      }
    }

    .header-total {
      width: 16%;
      display: inline-table;
      font-weight: 500;
      text-align: center;
      @media screen and (max-width: 600px) {
        width: 35%;
      }
    }

    .header-title {
      font-size: 0.9rem;
    }

    .header-title-flex {
      font-size: 0.9rem;
      display: flex;
      align-items: center;
    }

    .body-name-cell {
      width: 57%;
      display: inline-table;
      margin: 0px;
      @media screen and (max-width: 600px) {
        width: 65%;
        .eforPI {
          display: flex;
        }
      }
    }

    .body-total-cell {
      width: 16%;
      display: inline-table;
      text-align: center;
      margin: 0px;
      @media screen and (max-width: 600px) {
        width: 35%;
      }
    }

    .body-name {
      display: inline-table;
      vertical-align: middle;
      line-height: normal;
    }

    .body-name-check {
      display: inline-table;
      vertical-align: middle;
      margin-right: 8px;
    }

    .header-toggle {
      display: inline-table;
      width: 45px;
    }

    .toggle {
      display: inline-table;
      width: 47px;

      @media screen and (max-width: 600px) {
        width: 5px;
      }
    }

    .body-name-avatar {
      display: inline-table;
      vertical-align: middle;
      margin-right: 10px;
    }

    .check-all-container {
      padding-left: 5px;
    }

    .title-container {
      text-align: center;
      margin: 0 auto;
    }

    .body-name-avatar-img {
      width: 50px;
      height: 50px;
    }

    .body-name-top {
      font-weight: 500;
      font-size: 0.85rem;
    }

    .body-detail {
      font-size: 0.8rem;
      display: inline-flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        .bURwBf {
          margin-top: 5px;
          height: 25px;
        }
      }
    }

    .due {
      @media screen and (max-width: 600px) {
        padding-right: 50px;
      }
    }

    .body-detail-total {
      padding-right: 45px;
    }

    .body-detail-paid {
      padding-right: 30px;
    }

    .body-name-bottom {
      font-weight: 400;
      font-size: 0.7rem;

      .MuiFormControlLabel-root {
        height: 20px;

        .MuiCheckbox-root {
          color: ${theme.palette.mode == 'light' ? 'rgb(0 0 0 / 36%)' : ''};
          padding: 1px;
          margin-left: 0px;
        }

        .MuiFormControlLabel-label.Mui-disabled {
          color: ${theme.palette.mode == 'light' ? 'rgb(0 0 0 / 68%)' : ''};
        }
      }

      .MuiSvgIcon-root {
        width: 0.75em;
        height: 0.75em;
      }

      @media screen and (max-width: 600px) {
        display: None;
      }
    }

    .body-name-check-label {
      font-weight: 400;
      font-size: 0.7rem;
    }

    .col {
      @media screen and (max-width: 600px) {
        display: None;
      }
    }

    .header {
      background-color: ${theme.palette.background.paper};
      padding: 0px;
    }

    .detail {
      padding-left: 50px;
      padding-right: 45px;
      @media screen and (max-width: 600px) {
        padding-left: 7px;
        padding-right: 10px;
      }
    }

    .detail-content {
      margin-bottom: 5px;
    }

    .parent-cell {
      padding: 5px 5px 5px 5px;
    }

    .link {
      color: ${theme.palette.mode == 'dark' ? '#91b1cf' : '#004a91'};
      margin-left: 10px;
      text-decoration: none;
    }

    .actions {
      display: flex;
      justify-content: flex-start;
      padding: 10px;

      .left {
        display: flex;
        flex: 2;
        justify-content: start;
        align-items: center;
        gap: 10px;
      }

      .right {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;
      }
    }

    .notes {
      margin-top: 5px;
      padding: 16px 16px 0px 16px;
    }

    .switch-stack {
      top: 1px;
      position: relative;
      align-items: center;
      background-color: ${theme.palette.mode == 'light' ? '#e1e1ff' : ''};
      padding: 5px;
      border-radius: 5px;
      height: 40px;
      width: 80px;
      min-width: 80px;

      .MuiTypography-caption {
      }
    }

    .invoice-date-wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
      max-width: 430px;
    }

    .invoice-date {
      display: flex;
      flex: 1;
      padding: 0px 5px 0px 0px;
      min-width: 155px;
    }

    @media screen and (min-width: 745px) {
      .invoice-date.no-xs {
        max-width: 185px;
      }
    }

    .fancy-filter {
      padding: 5px 10px;

      .builder {
        display: flex;
        flex-wrap: wrap;

        .search-filter {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .first-group {
            display: flex;
            width: 100%;
          }

          button {
            padding: 6px;
            top: 2px;
            margin-left: 5px;
            text-transform: none;
          }

          @media screen and (max-width: 500px) {
            button {
              min-width: 0px;
              top: 2px;
              margin-left: 4px;
              text-transform: none;
            }
          }
        }

        @media screen and (min-width: 745px) {
          .search-filter {
            width: auto;
          }
        }

        .search-now-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;
          justify-content: right;
        }
      }
    }

    .tooltip-notes {
      margin: 0px 0px 0px 0px;
    }

    .divider {
      margin: 0px 10px 0px 10px;
    }

    .rpt-buttons-mobile {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .list-options {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;

      .options {
        display: inline-flex;
        align-items: center;

        button {
          text-transform: none;
        }

        .option-chips {
          display: contents;
          .MuiChip-root {
            border-radius: 5px;

            .MuiChip-label {
              font-size: 0.75rem;
            }
          }

          .MuiChip-root:nth-of-type(2) {
            margin-left: 5px;
          }
        }
      }

      .approved-total {
        font-weight: 500;

        font-size: 0.85rem;
        margin: 5px;
      }
    }

    .card-content-List {
      padding: 10px;

      .MuiList-root {
        li {
          padding: 0px;
        }
      }
    }

    .desktop-checks {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .mobile-checks {
      @media screen and (min-width: 600px) {
        display: none;
      }
    }

    .very-top {
      margin-top: 5px;
    }

    .fixed {
      position: sticky;
      z-index: 2;
    }
  `
);
