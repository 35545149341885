import React, { Fragment, memo, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Card, Chip, Divider, IconButton, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import UpdateIcon from '@mui/icons-material/Update';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

// import ProfileDialogNew from './ProfileDialogNew';
import { HtmlTooltip } from '../../../Style';
import { appColors } from '../../../theme/AppThemes';
import { useSelector } from 'react-redux';
import AgentAvailRequestInfo from './AgentAvailRequestInfo';
import moment from 'moment';
import Show from '../../common/others/Show';
import { isEmptyObject } from '../../../helpers';
const proMemberIcon = require(`../../../assets/images/ico_proStatus.png`);

const _scale = require(`../../../assets/images/scale.png`);
const _24Time = require(`../../../assets/images/24-time.png`);
const _certified = require(`../../../assets/images/certified.png`);
const _language = require(`../../../assets/images/language.png`);
const _ron = require(`../../../assets/images/video-call.png`);

const _scaleDark = require(`../../../assets/images/scale-dark.png`);
const _24TimeDark = require(`../../../assets/images/24-time-dark.png`);
const _certifiedDark = require(`../../../assets/images/certified-dark.png`);
const _languageDark = require(`../../../assets/images/language-dark.png`);
const _ronDark = require(`../../../assets/images/video-call-dark.png`);

// active CAPs
const _scaleColored = require(`../../../assets/images/scale-color.png`);
const _24TimeColored = require(`../../../assets/images/24-time-color.png`);
const _certifiedColored = require(`../../../assets/images/certified-color.png`);
const _certifiedColoredDark = require(`../../../assets/images/certified-colored-dark.png`);
const _languageColored = require(`../../../assets/images/language-color.png`);
const _ronColored = require(`../../../assets/images/video-call-color.png`);

const SmallAvatar = styled(Avatar)(
  ({ theme }) => css`
    width: 50px;
    height: 22px;
    border-radius: 7px;
    border: 2.5px solid ${theme.palette.background.paper} !important;
    zoom: 90%;

    img {
      object-fit: cover;
      top: 0.5px;
    }
  `
);

const StyledDataItemRow = styled('div')(
  ({ theme, assigned }) => css`
    display: flex;
    width: 100%;
    margin-bottom: 7px;
    border-radius: 10px;
    border: ${assigned ? '1.5px solid green' : 0};

    .MuiCard-root {
      padding: 5px;
      font-size: 0.75rem;
    }

    :hover {
      #rowLeft {
        > div:nth-child(2) {
          .MuiSvgIcon-root {
            color: ${theme.palette.success.main};
            animation: left_to_right 1s linear infinite;
          }
          @keyframes left_to_right {
            from {
              margin-left: 20%;
            }
            to {
              margin-left: 40%;
            }
          }
        }
      }
    }
  `
);

const StyledDataItemAssign = styled(Card)(
  ({ theme }) => css`
    width: 80px;
    text-align: center;
    text-align: center;
    margin-right: 1px;
    padding: 0px !important;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    .double-arrow {
      color: #c1a20f;
      font-size: 1.6rem;
    }

    &.agent-selected {
      .double-arrow {
        color: green !important;
      }
    }

    :hover {
      > div:nth-child(2) {
        .MuiSvgIcon-root {
          animation: none !important;
        }
      }
    }

    height: 100%;
    position: relative;

    > div {
      height: 47%;
      position: relative;
      justify-content: center;
      align-content: center;
    }

    > div:first-child {
      place-items: center;
    }

    > div:nth-child(1) {
      .MuiSvgIcon-root {
        font-size: 1.6rem;
        width: 0.76em;
      }
    }

    .miles {
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.7rem;
      height: 20px;
      color: #03a9f4;
      width: fit-content;
      justify-self: end;
      margin-left: 5px;

      .MuiChip-label {
        padding-left: 3px;
        padding-right: 4px;
      }
    }
  `
);

const StyledDataItem = styled(Card)(
  ({ theme, $showLeftSection, $priColor }) => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    > div {
      flex: 1 1 0px;
      align-self: center;
    }

    .strong-label {
      font-weight: 500;
    }

    &.agent-selected {
      .names {
        h3 {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : '#005e74'};
        }
      }
    }

    @media screen and (max-width: 1000px) {
      max-height: 155px;
    }

    .outer-div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .mobile-show {
      @media screen and (min-width: 700px) {
        display: flex;
      }
    }

    .soComments {
      position: absolute;
      top: 5px;
      right: -25px;
    }

    :hover {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};
      clip-path: none;

      .names {
        h3,
        .MuiSvgIcon-root {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : ''};
        }
      }

      .location-col {
        .location {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : ''};
        }
      }

      .stats-others {
        .profile-completion {
          .percentage {
            background: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};

            span: {
              font-weight: 600 !important;
            }
          }
        }
      }
    }

    .email-checkBox {
      flex-grow: 0.1;
      span {
        margin-left: -10px;
      }
    }

    .avatar {
      flex-grow: 0;

      .MuiStack-root {
        margin-right: 10px;
      }

      .MuiAvatar-root {
        border: 2px solid ${theme.palette.mode == 'dark' ? theme.palette.border.main : theme.palette.primary.main};
        color: ${theme.palette.primary.main};
        background-color: ${theme.palette.mode == 'dark'
          ? theme.palette.background.paper
          : appColors[$priColor].palette.border.main};
      }

      .MuiBadge-badge {
        padding: 0px;
        background-color: transparent;

        .MuiAvatar-root {
          width: 35px;
          height: 16px;
        }
      }

      @media screen and (max-width: 700px) {
        margin-right: 20px;
      }
    }

    .pointer-events-none {
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }

    .names {
      flex: 1.25;

      > div {
        display: flex;
        width: fit-content;
        cursor: pointer;

        .MuiSvgIcon-root.launch-icon {
          font-size: 1.1rem;
          top: 2px;
          position: relative;
          color: #40a1b7;
        }
      }

      h3 {
        color: #40a1b7;
      }

      p {
        max-width: 175px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }
    }

    .location-col {
      flex: 1.5;

      .location {
        color: #40a1b7;
        font-weight: 500;
      }

      > div {
        overflow-wrap: break-word;
        display: flex;
        padding-left: 8px;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }

      span {
        word-break: break-word;
        line-height: 100%;
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
        align-items: center;
      }
    }

    .alt-names {
      display: flex;
      flex-direction: column;
      margin-left: 4px;

      h3 {
        color: #40a1b7 !important;
      }

      p {
        margin-right: 4px;
      }
    }

    .contact {
      flex: 1.5;

      > div {
        overflow-wrap: break-word;
        display: flex;
        padding-left: 8px;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;

        .MuiSvgIcon-root {
          font-size: 1rem;
          align-self: center;
        }
      }

      span {
        word-break: break-word;
        line-height: 100%;
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
        align-items: center;
      }
    }

    .stats-others {
      /* flex: 1; */

      > div {
        display: flex;
        align-items: center;

        > div:nth-child(1) {
          flex: 1;
          min-width: 75px;
          text-align: right;
        }

        > div:nth-child(2) > div:first-child {
          flex: 1;
        }
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
      }

      .profile-completion {
        display: flex;
        /* width: 100%; */
        /* align-items: center;
        justify-content: start; */

        > div:first-child {
          position: relative;
        }

        .percentage {
          position: absolute;
          left: 15%;
          top: -8px;
          font-weight: bolder;
          background: ${theme.palette.background.paper};
          border-radius: 15%;
          padding: 0px 2px 0px;
        }
      }

      .capa {
        .capa-icons {
          display: flex;
          gap: 5px;
          width: 135px;
          justify-content: space-evenly;

          img {
            width: 18px;
            height: 19px;
          }
        }
      }
    }

    .features {
      flex: 1.75;

      > div {
        display: flex;
        align-items: center;

        > div:nth-child(1) {
          flex: 1;
          min-width: 90px;
          text-align: right;
        }

        > div:nth-child(2) {
          flex: 1;
        }
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
      }

      .label-description {
        display: flex;
        word-break: break-word;
        /* line-height: 100%; */

        span {
          max-width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    @media screen and (min-width: 1500px) {
      .features {
        margin-left: -30px;
      }
    }

    .label-icon {
      display: flex;
      width: 30px;

      .MuiSvgIcon-root:nth-child(2) {
        margin-left: -5px;
      }
    }

    .show-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 110px;
      flex: auto;
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .profile-updated {
      height: auto;
      border-radius: 5px;

      .MuiChip-icon {
        margin-left: 2.5px;
      }

      .MuiChip-label {
        width: 30px;
        padding: 0px;
        padding-right: 4px;
        font-size: 0.7rem;
        text-align: end;
      }
    }

    .more-icons {
      position: absolute;
      bottom: 5px;
      right: 5px;
      text-align: right;
      display: grid;
    }

    .miles {
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.7rem;
      height: 20px;
      color: #03a9f4;
      width: fit-content;
      justify-self: end;
      margin-bottom: 5px;

      .MuiChip-label {
        padding-left: 3px;
        padding-right: 4px;
      }
    }

    .more-icons.favorite {
      display: flex;
      gap: 5px;
      height: 20px;
      line-height: 20px;
    }
  `,

  ({ withnotes, $showLeftSection }) =>
    withnotes === 'true' &&
    css`
      position: relative;
      border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 102%);

      ::after {
        content: '';
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        background-color: #e1e1f2;
        top: 0;
        right: 0;
        border-bottom-left-radius: 20px;
        border-bottom-style: groove;
        text-align: center;
        z-index: 5;
      }

      :hover {
        .soComments {
          right: 5px;
          z-index: 5;
          color: blueviolet;
        }

        ::after {
          font-family: 'Material Icons';
          font-size: 1.25rem;
          color: blueviolet;
          z-index: 0;
          width: 35px;
          height: 35px;
        }
      }
    `
);

const ratingLabels = {
  0: 'Unrated',
  1: 'Useless',
  2: 'Poor',
  3: 'Ok',
  4: 'Good',
  5: 'Excellent',
};

const Item = ({
  setting,
  listMode,
  contact,
  requestID,
  fromContactManager,
  profileLookups,
  assignedclassName,
  onProximitySearch,
  agentEmailRecipients,
  emitSelectedEmail,
  assignAgent = () => {},
}) => {
  const history = useHistory();
  const theme = useTheme();
  const currentRoute = history.location.pathname.replaceAll('/', '');
  const showLeftSection = currentRoute == 'contact-manager' ? false : true;
  const { customColor: appColor } = useSelector((state) => state.reducer.themeAppearance);

  const checkIfWasSelected = () => {
    if (agentEmailRecipients.length > 0) {
      let obj = agentEmailRecipients.filter((i) => i.publicId == contact.v2PublicID)[0];
      if (obj) return true;
      else return false;
    }
  };

  // const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = useState(() => checkIfWasSelected());
  const [capabilities, setCapabilities] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);

  const profileImage = `https://notarycafe.com/Content/profile/${contact.v2PublicID}_1.png`;

  useEffect(() => {
    setCapabilities(getNamesFromLookups('CAP', true));
    setLanguages(getNamesFromLookups('LANG', true));

    if (listMode != 'email') setChecked(false);
    return () => {};
  }, [listMode, contact]);

  const proMemberIconRender = (event) => {
    return contact.isMember ? <SmallAvatar alt="pro" src={proMemberIcon} /> : '';
  };

  const handleEmailCheckBox = (event, contactEmail) => {
    if (!checked) {
      let obj = { publicId: contact.v2PublicID, email: contactEmail };
      if (emitSelectedEmail(obj)) setChecked(true);
    } else {
      emitSelectedEmail({
        publicId: contact.v2PublicID,
        remove: true,
      });
      setChecked(false);
    }
  };

  const testIconClick = (event) => {
    event.stopPropagation();
  };

  const getNamesFromLookups = (type, withCommas) => {
    const invalidProfiles = !profileLookups || isEmptyObject(profileLookups);
    if (!['BGC', 'CAP', 'LANG'].includes(type) || invalidProfiles) return [];

    const _BGC = (id) => profileLookups['SelectProviderTypes']?.find((item) => item.index === id)?.value || '';
    const _CAP = (id) => profileLookups['SelectCapabilityTypes']?.find((item) => item.index === id)?.value || '';
    const _LANG = (id) => profileLookups['SelectLanguageTypes']?.find((item) => item.index === id)?.value || '';

    if (type == 'BGC')
      return (
        contact.backgroundCheckList?.map((bck) => {
          const lookupName = _BGC(bck.ncLookupId);
          return withCommas ? lookupName : { ...bck, lookupName };
        }) || []
      );
    else if (type == 'CAP') {
      let res = contact.capabilityList?.reduce((acc, { ...attribs }) => {
        const lookupName = _CAP(attribs.ncLookupId);
        attribs.isCapable && acc.push(withCommas ? lookupName : { ...attribs, lookupName });
        return acc;
      }, []);
      if (languages.filter((i) => i !== '').length > 0) res?.push('Multi-Language');
      return res || [];
    } else if (type == 'LANG')
      return (
        contact.languageList?.map((bck) => {
          if (!bck.isSpoken) return '';
          const lookupName = _LANG(bck.ncLookupId);
          return withCommas ? lookupName : { ...bck, lookupName };
        }) || []
      );
    else return [];
  };

  const backgroundCheckTooltip = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Background Checks:</b>
        </div>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        {getNamesFromLookups('BGC').map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <strong>
                #{index + 1}&nbsp;&nbsp;
                {item.lookupName}.&nbsp;&nbsp;
              </strong>
            </div>
            <div style={{ flex: 1, textAlign: 'end' }}>
              <i>
                Expires On: <b>{item.expiresOn}</b>
              </i>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  const CapabilitiesTooltip = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Main Capabilities: </b>
          <Typography component="span" variant="body2">
            {getNamesFromLookups('CAP', true)
              .map((item) => {
                if (['Attorney', '24 Hour Service', 'CSA', 'RON Capable', 'Multi-Language'].includes(item)) return item;
                else return null;
              })
              .filter((item) => item) // Filter out null values
              .join(', ') || <i>None</i>}
          </Typography>
        </div>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <div className={'with-header'}>
          <b>Others: </b>
          <Typography component="span" variant="body2">
            {getNamesFromLookups('CAP', true)
              .map((item) => {
                if (!['Attorney', '24 Hour Service', 'CSA', 'RON Capable', 'Multi-Language'].includes(item) && item)
                  return item;
                else return null;
              })
              .filter((item) => item) // Filter out null values
              .join(', ') || <i>None</i>}
          </Typography>
        </div>
      </Fragment>
    );
  };

  const regDocumentTooltipContent = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Registered Documents:</b>
        </div>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        {contact.regDocumentList?.map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 2 }}>
              <strong>#{index + 1}&nbsp;&nbsp;</strong>
              {item.name} ({item.isCustom ? item.initials : item.code}
              ).&nbsp;&nbsp;
            </div>
            {item.expirationDate && (
              <div style={{ textAlign: 'end' }}>
                <i>
                  Expires On: <b>{item.expirationDate}</b>
                </i>
              </div>
            )}
          </div>
        ))}
      </Fragment>
    );
  };

  const serviceAgreementTooltipContent = () => (
    <Fragment>
      <div className={'with-header'}>
        <b>Service Agreement:</b> <span style={{ fontWeight: 300 }}>(Online Contract)</span>
      </div>
      {contact.serviceAgreement !== null && (
        <>
          <Divider sx={{ mt: 0.5, mb: 1 }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {contact.serviceAgreement?.isAgreed ? (
                <>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <VerifiedIcon color="success" /> This agent has accepted your contractor agreement.
                  </p>
                  <p>
                    <b>Agreed On:</b>{' '}
                    <span style={{ fontWeight: 300 }}>
                      <i>{moment(contact.serviceAgreement.agreementStatusDate).format('MM/DD/YYYY')}</i>
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <NewReleasesIcon color="warning" /> This agent has declined your contractor agreement.
                  </p>
                  <p>
                    <b>Declined On:</b>{' '}
                    <span style={{ fontWeight: 300 }}>
                      <i>{moment(contact.serviceAgreement.agreementStatusDate).format('MM/DD/YYYY')}</i>
                    </span>
                  </p>
                  <p>
                    <b>Reason:</b>{' '}
                    <span style={{ fontWeight: 300 }}>
                      <i>{contact.serviceAgreement.comments}</i>
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );

  const getRegDocSimpleArray = (excludeOC = false) => {
    let regDocs = contact.regDocumentList.filter((doc) => doc.code !== (excludeOC ? 'OC' : ''));
    return regDocs.map((d) => (d.isCustom ? d.initials : d.code)).join(', ');
  };

  const profileUpdateTimeAgo = () => (
    <>
      {contact.updatedStatus == 'Red' && (
        <HtmlTooltip arrow title="Profile updated over 180 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'red !important' }} />}
            label="180"
          />
        </HtmlTooltip>
      )}
      {contact.updatedStatus == 'Yellow' && (
        <HtmlTooltip arrow title="Profile updated within the last 120 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'orange !important' }} />}
            label="120"
          />
        </HtmlTooltip>
      )}
      {contact.updatedStatus == 'Green' && (
        <HtmlTooltip arrow title="Profile updated within the last 60 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'green !important' }} />}
            label="60"
          />
        </HtmlTooltip>
      )}
    </>
  );

  return (
    <div style={{ display: 'flex' }}>
      {listMode === 'email' && (
        <div className="email-checkBox" style={{ alignSelf: 'center' }}>
          <Checkbox
            color={'info'}
            checkedIcon={<MailOutlinedIcon />}
            checked={checked}
            onChange={(e) => handleEmailCheckBox(e, contact.mainEmail)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
      <StyledDataItemRow assigned={assignedclassName} sx={{ boxShadow: assignedclassName ? 3 : 0 }}>
        {showLeftSection && (
          <StyledDataItemAssign id="rowLeft" className={assignedclassName}>
            <AgentAvailRequestInfo availRequest={contact.agentAvailabilityInfo} />
            <Divider flexItem />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {onProximitySearch && setting.showProximityEnable && (
                <>
                  <Chip
                    className="miles"
                    color="default"
                    size="small"
                    onClick={(e) => {
                      if (contact.mapLink) window.open(contact.mapLink, '_blank');
                      else e.stopPropagation();
                    }}
                    label={contact.miles ? contact.miles : '0'}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ m: '5px 0px 5px 5px' }} />
                </>
              )}
              <div>
                <IconButton className="double-arrow" onClick={() => assignAgent(contact.v2PublicID)}>
                  <DoubleArrowRoundedIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </StyledDataItemAssign>
        )}
        <StyledDataItem
          id="rowRight"
          className={assignedclassName}
          $showLeftSection={showLeftSection}
          $priColor={appColor}
          withnotes={contact.sO_Comments ? 'true' : 'false'}
          // onClick={() => setOpenDialog(true)}
        >
          <div className="avatar">
            <Show when={contact?.notSynced}>
              <HtmlTooltip
                style={{ display: 'flex' }}
                arrow
                followCursor
                title={'Profile is out of sync with Notary Cafe.'}
              >
                <SyncProblemIcon
                  sx={{
                    width: 24,
                    height: 24,
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    zIndex: 1,
                    borderRadius: '50%',
                    color: theme.palette.warning.main,
                    background: theme.palette.nsTableHeaderBG.main,
                  }}
                />
              </HtmlTooltip>
            </Show>
            <Stack direction="row" spacing={2}>
              {proMemberIconRender() ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={proMemberIconRender()}
                >
                  <Avatar sx={{ width: 45, height: 45 }} src={profileImage} />
                </Badge>
              ) : (
                <Avatar sx={{ width: 45, height: 45 }} src={profileImage} />
              )}
            </Stack>
          </div>
          <div className={`names ${contact?.notSynced ? 'pointer-events-none' : ''}`}>
            <div
              onClick={(e) => {
                let partialUrl = '';
                if (fromContactManager) {
                  partialUrl = `/contact-manager/profile`;
                } else {
                  partialUrl = `/manage-request/assign-notary/${requestID}/contact`;
                }
                history.push({
                  pathname:
                    partialUrl + `/agent/${contact.userTypeInitial === 'a' ? contact.v2PublicID : contact.userID}`,
                  state: {
                    fullName: contact.nameAndCompany.fullName,
                    company: contact.nameAndCompany.company,
                  },
                });
                e.stopPropagation();
              }}
            >
              <h3>{contact.nameAndCompany.fullName}</h3>
              {onProximitySearch && <LaunchOutlinedIcon className="launch-icon" fontSize="small" />}
            </div>
            <p>{contact.nameAndCompany.company.replace('<br>', '')}</p>
          </div>
          <div className="location-col">
            <div className="">
              <span className="label-icon">
                <LocationOnOutlinedIcon color="default" />
                <ArrowRightOutlinedIcon color="disabled" />
              </span>
              <span className="location show-ellipsis">
                {contact.billingAddress?.state} -{contact.billingAddress?.city} {contact.billingAddress?.state}{' '}
                {contact.billingAddress?.zipCode}
              </span>
            </div>
            <div className="stats">
              <span className="label-icon">
                <BarChartOutlinedIcon color="default" />
                <ArrowRightOutlinedIcon color="disabled" />
              </span>
              <span className="show-ellipsis">
                <strong>12m:</strong>
                {contact.signings_12mths} {` | `} <strong>My: </strong>
                {contact.signings_MyPortal} {` | `} <FavoriteBorderOutlinedIcon style={{ fontSize: '1.2em' }} />
                {contact.preferredCount}
              </span>
            </div>
          </div>
          <div className="contact">
            <div className="">
              <span className="label-icon">
                <AlternateEmailOutlinedIcon color="default" />
                <ArrowRightOutlinedIcon color="disabled" />
              </span>
              <span className="show-ellipsis" onClick={(e) => e.stopPropagation()}>
                {contact.mainEmail.toLowerCase()}
              </span>
            </div>
            <div className="">
              <span className="label-icon">
                <PhoneIphoneOutlinedIcon color="default" />
                <ArrowRightOutlinedIcon color="disabled" />
              </span>
              <HtmlTooltip disableFocusListener arrow followCursor maxwidth="350px" title={contact.allPhoneInfo}>
                <span className="show-ellipsis">{contact.preferredPhone}</span>
              </HtmlTooltip>
            </div>
          </div>
          <div className="stats-others">
            <div className="profile-completion">
              <HtmlTooltip style={{ display: 'flex' }} arrow followCursor title={'Profile Completion'}>
                <span className="label-icon">
                  <FactCheckOutlinedIcon color="default" />
                  <ArrowRightOutlinedIcon color="disabled" />
                </span>
                <span style={{ width: '32px' }}>{`${contact.profileScore}%`}&nbsp;</span>
              </HtmlTooltip>
              {profileUpdateTimeAgo()}
              <Divider sx={{ ml: 1, mr: 1 }} orientation="vertical" flexItem />
              <HtmlTooltip style={{ display: 'flex' }} arrow followCursor title={'Rating'}>
                <span className="label-icon">
                  <StarOutlineOutlinedIcon color="default" />
                  <ArrowRightOutlinedIcon color="disabled" />
                </span>
                {contact.sO_Rating < 0 ? 0 : contact.sO_Rating}
              </HtmlTooltip>
            </div>
            <div className="capa">
              <span className="label-icon">
                <CopyrightOutlinedIcon color="default" />
                <ArrowRightOutlinedIcon color="disabled" />
              </span>
              <HtmlTooltip arrow followCursor maxwidth="250px" title={CapabilitiesTooltip()}>
                <div className="capa-icons">
                  {capabilities?.includes('Attorney') ? (
                    <img alt="scale" src={_scaleColored} />
                  ) : (
                    <img
                      alt="scale"
                      src={theme.palette.mode == 'dark' ? _scaleDark : _scale}
                      style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                    />
                  )}
                  {capabilities?.includes('24 Hour Service') ? (
                    <img alt="24Time" src={_24TimeColored} />
                  ) : (
                    <img
                      alt="24Time"
                      src={theme.palette.mode == 'dark' ? _24TimeDark : _24Time}
                      style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                    />
                  )}
                  {capabilities?.includes('CSA') ? (
                    <div style={{ position: 'relative', display: 'inline-block', zIndex: 0 }}>
                      <img
                        style={{
                          width: '18px',
                          height: '19px',
                          display: 'block',
                        }}
                        alt="certified"
                        src={theme.palette.mode == 'dark' ? _certifiedColoredDark : _certifiedColored}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '3px',
                          width: '70%',
                          height: '70%',
                          backgroundColor: 'rgb(255 255 255)',
                          transform: 'translateY(-50%)',
                          borderRadius: '50px',
                          zIndex: -1,
                        }}
                      ></div>
                    </div>
                  ) : (
                    <img
                      alt="certified"
                      src={theme.palette.mode == 'dark' ? _certifiedDark : _certified}
                      style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                    />
                  )}
                  {languages.filter((i) => i !== '').length > 0 ? (
                    <img alt="language" src={_languageColored} />
                  ) : (
                    <img
                      alt="language"
                      src={theme.palette.mode == 'dark' ? _languageDark : _language}
                      style={{ opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25 }}
                    />
                  )}
                  {capabilities?.includes('RON Capable') ? (
                    <img
                      style={{
                        width: '18px',
                        height: '20px',
                        display: 'block',
                      }}
                      alt="ron"
                      src={_ronColored}
                    />
                  ) : (
                    <img
                      style={{
                        width: '18px',
                        height: '20px',
                        display: 'block',
                        opacity: theme.palette.mode == 'dark' ? 0.15 : 0.25,
                      }}
                      alt="language"
                      src={theme.palette.mode == 'dark' ? _ronDark : _ron}
                    />
                  )}
                </div>
              </HtmlTooltip>
            </div>
          </div>
          <div className="features">
            <div style={{ display: 'flex', gap: '5px' }}>
              <div className="strong-label">BGC:</div>
              <div className="label-description" style={{ display: 'flex' }}>
                {getNamesFromLookups('BGC').length ? (
                  <HtmlTooltip arrow followCursor title={backgroundCheckTooltip()} minwidth="350px">
                    <span className="bgc-value">{getNamesFromLookups('BGC', true).join(', ')}</span>
                  </HtmlTooltip>
                ) : (
                  <NotInterestedOutlinedIcon color="disabled" sx={{ fontSize: 'small' }} />
                )}
              </div>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div className="strong-label">Agent Docs:</div>
              <div className="label-description" style={{ display: 'flex', alignItems: 'center' }}>
                {contact.serviceAgreement !== null && (
                  <>
                    <HtmlTooltip disableFocusListener arrow followCursor title={serviceAgreementTooltipContent()}>
                      <Chip
                        sx={{
                          height: '15px',
                          lineHeight: '15px',
                          '.MuiChip-label': { fontSize: '10px', fontWeight: 'bold' },
                        }}
                        color={contact.serviceAgreement.isAgreed ? 'success' : 'warning'}
                        label="OC"
                        size="small"
                      />
                    </HtmlTooltip>
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                      {getRegDocSimpleArray(true).length > 0 ? '-' : ''}{' '}
                    </span>
                  </>
                )}
                {contact.regDocumentList?.length ? (
                  <HtmlTooltip disableFocusListener arrow followCursor title={regDocumentTooltipContent()}>
                    <span>{getRegDocSimpleArray(contact.serviceAgreement !== null ? true : false)}</span>
                  </HtmlTooltip>
                ) : (
                  contact.serviceAgreement === null && (
                    <NotInterestedOutlinedIcon color="disabled" sx={{ fontSize: 'small' }} />
                  )
                )}
              </div>
            </div>
          </div>
          {contact.sO_Comments && (
            <div className="soComments">
              <HtmlTooltip arrow disableFocusListener title={contact.sO_Comments}>
                <StickyNote2OutlinedIcon fontSize="small" />
              </HtmlTooltip>
            </div>
          )}
          <div className="more-icons favorite">
            {contact.inPersonalList && (
              <div>
                <FavoriteIcon style={{ color: 'red', width: '0.9rem' }} />
              </div>
            )}
            {!showLeftSection && setting.showProximityEnable && (
              <Chip
                className="miles"
                onClick={(e) => testIconClick(e)}
                color="default"
                size="small"
                label={contact.miles ? contact.miles : '0'}
              />
            )}
          </div>
        </StyledDataItem>
      </StyledDataItemRow>
      {/* {openDialog && (
        <ProfileDialogNew
          open={openDialog}
          contact={contact}
          onClose={setOpenDialog}
        />
      )} */}
    </div>
  );
};

// function areEqual(prevProps, nextProps) {
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */
//   return _.isEqual(nextProps, prevProps);
// }

export const DataItemDesktopCompact = memo(Item);
