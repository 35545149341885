import React, { useContext, useEffect } from 'react';
import { Button } from '@mui/material';

import { UploadFilesItem } from './upload-files-item';
import { UploadFilesItemMobile } from './upload-files-item-mobile';
import { StyledExternalFiles } from './style';
import { FileUploadContext } from './FileUploadContext';
import { store } from '../../../store';

const cols = [
  {
    id: 1,
    field: 'name',
    name: 'Link',
    type: 'TextField',
    flex: 3,
  },
  {
    id: 2,
    field: 'description',
    name: 'Description',
    type: 'TextField',
    flex: 2.5,
  },
  {
    id: 2,
    field: 'path',
    name: 'Url',
    type: 'TextField',
    flex: 2.5,
  },
  {
    id: 3,
    field: 'isLoan',
    name: 'Loan',
    type: 'Checkbox',
    flex: 0.5,
  },
  {
    id: 4,
    field: 'isTitle',
    name: 'Title',
    type: 'Checkbox',
    flex: 0.5,
  },
  {
    id: 5,
    field: 'isOthers',
    name: 'Other',
    type: 'Checkbox',
    flex: 0.5,
  },
  {
    id: 6,
    field: 'lastModified',
    name: 'Uploaded Date',
    type: 'DateTime',
    mobileHidden: true,
    flex: 1.5,
  },
];

export const UploadFilesDialogExternal = ({
  externalFiles,
  deleteExternalFile,
  addExternalRow,
  editExternalName,
}) => {
  const { canEdit, fileContext } = useContext(FileUploadContext);
  const viewport = store.getState().reducer.appViewport;

  const getColumns = () => {
    if (fileContext.uploadDocType == 'SigningFiles') {
      return cols;
    } else if (fileContext.uploadDocType == 'ScanBacks') {
      const excludes = ['description', 'isLoan', 'isTitle', 'isOthers', 'size'];
      return cols.filter((item) => !excludes.includes(item.field));
    } else {
      const excludes = ['description', 'isLoan', 'isTitle', 'isOthers', 'size'];
      return cols.filter((item) => !excludes.includes(item.field));
    }
  };

  const editExternalFile = (index, event) => {
    editExternalName(index, event);
  };

  return (
    <StyledExternalFiles>
      {['xs', 'sm'].includes(viewport) ? (
        externalFiles?.map((row, index) => (
          <UploadFilesItemMobile
            externalFiles
            key={row.docId}
            columns={getColumns()}
            row={row}
            editCallback={(event) => editExternalFile(index, event)}
            deleteCallback={(event) => deleteExternalFile(event)}
          />
        ))
      ) : (
        <div>
          <div className="table-header">
            {getColumns().map((column, index) => (
              <div
                key={index}
                style={{ flex: column.flex, display: column.display }}
                className="col"
              >
                <label>{column.name}</label>
              </div>
            ))}
            <div style={{ flex: 0.5 }} className="col">
              <label>{}</label>
            </div>
          </div>
          <div>
            {externalFiles?.map((row, index) => (
              <div key={row.docId} className="table-body">
                <UploadFilesItem
                  externalFiles
                  key={row.docId}
                  columns={getColumns()}
                  row={row}
                  editCallback={(event) => editExternalFile(index, event)}
                  deleteCallback={(event) => deleteExternalFile(event)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div style={{ marginTop: '10px' }}>
        <Button color="default" onClick={addExternalRow} disabled={!canEdit}>
          Add New
        </Button>
      </div>
    </StyledExternalFiles>
  );
};
