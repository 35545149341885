import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const profileService = {
  getProfileLookupsForDDL: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = `/api/Profile/getProfileLookupsForDDL`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  getFullProfile: async function (profileType, profileId, fromContactMgr) {
    const options = { headers: await authHeader() };
    const params = {};
    const url = `/api/${
      fromContactMgr ? 'ContactManager/' : 'Profile/'
    }${profileType}/${profileId == 'new' ? 0 : profileId}`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  createProfile: async function (profileType, updatedProfile, fromContactMgr) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(updatedProfile),
    };
    const params = {};
    const url = `/api/${
      fromContactMgr ? 'ContactManager/' : 'Profile/'
    }${profileType}/new`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  updateProfile: async function (
    profileType,
    profileId,
    updatedProfile,
    fromContactMgr
  ) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(updatedProfile),
    };
    const params = {};
    const url = `/api/${
      fromContactMgr ? 'ContactManager/' : 'Profile/'
    }${profileType}/${profileId}`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  deleteProfile: async function (profileType, profileId, fromContactMgr) {
    const options = {
      headers: await authHeader(),
      body: profileId,
    };
    const params = {};
    const url = `/api/${
      fromContactMgr ? 'ContactManager/' : 'Profile/'
    }${profileType}/${profileId}`;
    return Fetch.remove(url, params, options).then(handleResponse);
  },

  toggleAgentInPersonalList: async function (profileId, isFavorite) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(isFavorite),
    };
    const params = { profileId: profileId };
    const url = `/api/Profile/toggleAgentInPersonalList`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  notaryCafeLogon: async function () {
    return true;
    //   const requestOptions = {
    //     method: 'POST',
    //     // credentials: 'include',
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     body: new URLSearchParams({
    //       userName: 'joeagent',
    //       password: 'Testing123',
    //       rememberMe: false,
    //     }),
    //   };

    //   return await fetch(
    //     `http://localhost:51086/Account/ExternalLogOn`,
    //     requestOptions
    //   )
    //     .then(handleResponse)
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
  },
};

export { profileService };
