import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Clear } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import NumberFormat from 'react-number-format';
import { HtmlTooltip } from '../../../Style';

const ClearButton = styled(Clear)(
  ({ theme }) => css`
    cursor: pointer;
  `
);

const MainSearchInput = (props) => {
  const {
    margin,
    placeHolder,
    moreInfoText = '',
    inputType,
    value,
    onChange = () => {},
    getContactList,
    allowLeadingZeros,
  } = props;
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    setSearchValue(value);
    return () => {};
  }, [value]);

  const handleClear = () => {
    setSearchValue('');
    onChange('');
  };

  const handleChange = (dataType, event) => {
    if (dataType === 'number') {
      onChange(parseInt(event.target.value));
      setSearchValue(parseInt(event.target.value));
    } else {
      onChange(event.target.value);
      setSearchValue(event.target.value);
    }
  };

  return (
    <>
      {inputType == 'number' && (
        <NumberFormat
          margin={margin}
          customInput={TextField}
          onKeyDown={(e) => {
            if (e.code === 'Enter') getContactList();
          }}
          format="#####"
          placeholder={placeHolder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? <ClearButton id="clear" onClick={handleClear} /> : ''}
              </InputAdornment>
            ),
          }}
          value={searchValue > 0 ? parseInt(searchValue) : ''}
          onChange={(e) => {
            handleChange('number', e);
          }}
        />
      )}
      {inputType == 'numberAsString' && (
        <NumberFormat
          type="text"
          margin={margin}
          customInput={TextField}
          allowLeadingZeros
          onKeyDown={(e) => {
            if (e.code === 'Enter') getContactList();
          }}
          format="#####"
          placeholder={placeHolder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? <ClearButton id="clear" onClick={handleClear} /> : ''}
              </InputAdornment>
            ),
          }}
          value={searchValue || ''}
          onChange={(e) => handleChange('string', e)}
        />
      )}
      {inputType == 'string' && (
        <TextField
          // sx={{ width: '40ch' }}
          margin={margin}
          placeholder={placeHolder}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              getContactList();
            }
          }}
          InputProps={{
            sx: { pl: 0 },
            startAdornment: moreInfoText ? (
              <InputAdornment sx={{ mr: '3px' }} position="start">
                <HtmlTooltip arrow maxwidth="400px" title={moreInfoText}>
                  <IconButton sx={{ top: 0, padding: '5px' }}>
                    <InfoOutlinedIcon color="info" fontSize="small" />
                  </IconButton>
                </HtmlTooltip>
              </InputAdornment>
            ) : (
              ''
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? <ClearButton id="clear" onClick={handleClear} /> : ''}
              </InputAdornment>
            ),
          }}
          value={searchValue || ''}
          onChange={(e) => handleChange('string', e)}
        />
      )}
    </>
  );
};

MainSearchInput.propTypes = {};

export default MainSearchInput;
