import React, { useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Avatar,
  Badge,
  Card,
  ImageListItem,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

import { store } from '../../../store';
import { StyledRouteLink } from '../../../Style';
import { Link } from 'react-router-dom';

const StyledAssignedAgent = styled(Card)(
  ({ theme, $isAssigned, $isCustomer }) => css`
    min-height: ${!$isAssigned ? '100px' : 'auto'};
    display: ${!$isAssigned ? 'flex' : 'inherit'};
    border-radius: 5px;
    border: 1px solid ${theme.palette.mode == 'light' ? theme.palette.border.main : '#474a4d'};

    ${$isAssigned} {
      background: linear-gradient(
        ${theme.palette.mode == 'light' ? theme.palette.clear.main : theme.palette.nsAppBG?.main},
        ${theme.palette.clearx2.main}
      );
    }

    ${theme.breakpoints.only('md')} {
      min-width: 140px;
      width: auto;
    }

    .MuiListItem-root {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      background: linear-gradient(
        ${theme.palette.mode == 'light' ? theme.palette.clear.main : theme.palette.nsAppBG?.main},
        ${theme.palette.clearx2.main}
      );

      .launch-profile {
        display: none;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }

    .assigned-wrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${theme.palette.mode == 'light' ? '#eeeeee' : '#474a4d'};

      .assigned {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;

        > div:nth-child(1) {
          color: ${$isAssigned && theme.palette.mode == 'light' ? '#666666' : ''};
          text-align: center;
          font-weight: 500;
        }
      }
    }

    .unassing {
      align-self: center;
      margin: 0 auto;
      text-align: center;
    }

    .MuiAvatar-root {
      width: 40px;
      height: 38px;
      border-top-left-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .MuiAvatar-fallback {
        color: lightgray;
      }
    }
  `
);

const AssignAgentCard = ({ request, setting }) => {
  let history = useHistory();
  const userRoles = store.getState().reducer.currentUser.userRoles;

  // const [openContactDialog, setOpenContactDialog] = useState(false);

  const assignNotaryLink = (linkName) => {
    let isAssigned = linkName == '(Assign Notary)' ? false : true;
    let isCustomer = userRoles.includes('Customer');
    let showAgentInfo = setting.chkShowAgentInfoToCustomer;
    let disableAssignNotary = setting.disableAssignNotary;

    if (isCustomer && !isAssigned) linkName = 'Unassigned';

    return (
      ((isCustomer && showAgentInfo) || !isCustomer) && (
        <>
          {disableAssignNotary ? (
            <p
              style={{
                fontWeight: 500,
                cursor: !isAssigned ? 'not-allowed' : '',
              }}
            >
              {!isAssigned ? 'Unassinged' : 'Assigned'}
            </p>
          ) : (
            <StyledRouteLink
              disabled={true}
              to={{
                pathname: `/manage-request/assign-notary/${request.requestID}`,
                state: {
                  fromRequestList: true,
                  zip: request.signingZip,
                  assignedAgent: request.assignedAgentPublicID,
                },
              }}
            >
              <Typography variant="subtitle2" color="text.primary">
                {isAssigned ? 'Manage Assignment' : 'Assign Notary'}
              </Typography>
            </StyledRouteLink>
          )}
        </>
      )
    );
  };

  const getProfileLink = () => {
    if (userRoles.includes('Customer')) return '';
    return `/manage-request/contact/agent/${request.assignedAgentPublicID}`;
  };

  const enableContactBtn = () => {
    if (userRoles.includes('Customer')) return false;
    return true;
  };

  return (
    <StyledAssignedAgent
      variant="outlined"
      $isAssigned={request.assignedAgentPublicID ? true : false}
      $isCustomer={userRoles.includes('Customer') ? true : false}
    >
      {request.assignedAgentPublicID ? (
        <>
          <div className="assigned-wrapper">
            <Avatar
              sx={{ '.MuiAvatar-img': { objectFit: 'fill' } }}
              src={`https://notarycafe.com/Content/profile/${request.assignedAgentPublicID}_1.png`}
              loading="lazy"
            />
            <div className="assigned">{assignNotaryLink(request.assignedAgentName)}</div>
          </div>
          <ListItem alignItems="flex-start" disableGutters disablePadding>
            <ListItemButton
              sx={{
                p: 0,
                '&.MuiButtonBase-root:hover': {
                  cursor: !enableContactBtn() ? 'auto' : 'pointer',
                  bgcolor: !enableContactBtn() ? 'transparent' : 'inherit',
                  '& .launch-profile': {
                    display: 'initial',
                  },
                },
              }}
              disableRipple={!enableContactBtn() ? true : false}
              component={!enableContactBtn() ? 'div' : 'button'}
              onClick={(e) => {
                if (!enableContactBtn() || e.target.tagName == 'A') {
                  e.preventDefault();
                }
                e.stopPropagation();
                history.push(getProfileLink());
                // setOpenContactDialog({
                //   userType: 'agent',
                //   v2PublicID: request.assignedAgentPublicID,
                //   fullName: request.assignedAgentName,
                // });
              }}
            >
              {enableContactBtn() && (
                <PersonPinCircleOutlinedIcon color="default" fontSize="medium" className="launch-profile" />
              )}
              <ListItemText
                sx={{
                  m: '0px 2px 1px 5px',
                  '.MuiListItemText-secondary': { display: 'grid' },
                }}
                primaryTypographyProps={{
                  variant: 'subtitle2',
                }}
                secondaryTypographyProps={{
                  component: 'div',
                }}
                primary={request.assignedAgentName}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      <Stack direction="row" spacing={3}>
                        {request.agentPreferredPhone}
                        <StarBorderOutlinedIcon sx={{ fontSize: '1rem' }} fontSize="small" color="default" />
                      </Stack>
                    </Typography>
                    <Typography
                      component="a"
                      variant="body2"
                      color="text.primary"
                      onClick={(e) => e.stopPropagation()}
                      href={`mailto:${request.agentMainEmail}`}
                    >
                      {request.agentMainEmail}
                    </Typography>
                  </>
                }
              />
            </ListItemButton>
          </ListItem>
        </>
      ) : (
        <div className="unassing">
          {assignNotaryLink(request.assignedAgentName)}
          {request.agentAvailabilityRequestCount > 0 && (
            <>
              <span>Availability Count:</span>
              <Badge
                badgeContent={request.agentAvailabilityRequestCount}
                color="info"
                sx={{ zoom: '85%', marginLeft: '5px', '.MuiBadge-badge': { padding: 0, lineHeight: 2 } }}
              >
                <MailOutlinedIcon color="action" fontSize="small" />
              </Badge>
            </>
          )}
        </div>
      )}
    </StyledAssignedAgent>
  );
};

export default AssignAgentCard;
