import React from 'react';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles, useTheme } from '@mui/styles';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useAgreementData } from './hooks/useAgreementData';
import { F } from '../../helpers/functions';
import ModalTitle from '../common/modal-service/ModalTitle';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import PdfPreviewer from '../common/PdfPreview/PdfPreviewer';
import PdfPreviewProvider from '../common/PdfPreview/PdfPreviewProvider';
import Show from '../common/others/Show';
import { useLoadingStore } from '../../context/loading.store';

const useStyles = makeStyles((theme, context) => ({
  footer: {
    '& button': {
      maxWidth: '320px',
    },
    '& .ns-agreement-company': {
      color: theme.palette.primary.main,
    },
  },

  header: {
    display: 'block !important',
    '& h1': {
      fontWeight: '600',
    },
    '& .ns-agreement-company': {
      color: theme.palette.primary.main,
    },
  },
}));

export const useAgreementStore = create(
  devtools((set) => ({
    blockButtonActions: false,
    agreementDocId: null,
    setAgreementDocId: (agreementDocId) => set(() => ({ agreementDocId })),
    disableButtonActions: () => set(() => ({ blockButtonActions: true })),
    reset: () => set(() => ({ blockButtonActions: false })),
  }))
);

export const AgentAgreementModal = ({
  open,
  docID,
  pdfUrl,
  companyName,
  preventDecline = false,
  handleClose = F,
  handleIAgree = F,
  handleIDecline = F,
  ...props
}) => {
  const pdfSettings = { uploadDocType: 'ServiceAgreementForAgent' };
  const disabledActions = useAgreementStore((state) => state.blockButtonActions);
  const agreementDocId = useAgreementStore((state) => state.agreementDocId);

  const theme = useTheme();
  const classes = useStyles(theme);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <ModalTitle className={classes.header} closeable handleClose={handleClose}>
        <Stack direction={'row'} justifyContent="flex-start" alignItems={'center'}>
          <Show when={!isMobile}>
            <AssignmentOutlinedIcon sx={{ fontSize: '48px' }} />
          </Show>
          <Box component="header" sx={{ px: 2 }}>
            <Typography
              variant="h1"
              align="left"
              sx={{ fontSize: isMobile ? '16px' : '18px', maxWidth: '100%', marginRight: '20px', pb: isMobile ? 1 : 0 }}
            >
              Service Agreement for <span className="ns-agreement-company">{companyName}</span>
            </Typography>
            <Show when={!isMobile}>
              <Typography variant="subtitle1" align="left" sx={{ fontSize: '16px' }}>
                Please review the service agreement below and click on "I Agree" if you agree with the terms.
              </Typography>
            </Show>
          </Box>
        </Stack>
      </ModalTitle>
      <DialogContent sx={{ padding: isMobile ? '0 !important' : '10px', paddingBottom: 0 }}>
        <Box
          direction="column"
          spacing={isMobile ? 0 : 2}
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: isMobile ? 'none' : '1px solid #e0e0e0',
            borderBottom: isMobile && '1px solid #e0e0e0',
            borderRadius: !isMobile && '5px',
            p: 1,
          }}
        >
          <PdfPreviewProvider docID={agreementDocId} bodyParams={pdfSettings}>
            {(doc) => <PdfPreviewer pdfUrl={doc} />}
          </PdfPreviewProvider>
        </Box>
      </DialogContent>
      <DialogActions className={classes.footer} sx={{ flexDirection: 'column' }}>
        <Stack direction="row" useFlexGap spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%', justifyContent: 'center' }}>
          <Show when={preventDecline}>
            <Button
              variant="outlined"
              disabled={disabledActions}
              color="text"
              onClick={handleClose}
              fullWidth
              focusRipple
            >
              Close
            </Button>
          </Show>

          <Show when={!preventDecline}>
            <Button
              variant="outlined"
              disabled={disabledActions}
              color="secondary"
              onClick={handleIDecline}
              fullWidth
              focusRipple
            >
              I Decline
            </Button>
          </Show>

          <Button variant="contained" disabled={disabledActions} color="primary" onClick={handleIAgree} fullWidth>
            I Agree
          </Button>
        </Stack>
        <Typography variant="body1" align="center" sx={{ p: 1, lineHeight: isMobile ? '100%' : 'normal' }}>
          On completion, a copy of the agreement will be emailed to you.
        </Typography>
      </DialogActions>
    </>
  );
};

export default AgentAgreementModal;
