import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import { FileChip, Input } from '../../../Style';
import { UploadFilesService as service } from '../../../services';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'wrap',
      paddingTop: '10px !important',
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '16px',
    },

    '& .MuiFormHelperText-root': {
      fontWeight: 600,
    },
  }),
}));

const FileUpload = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [files, setFiles] = useState(props.files);
  const [fileError, setFileError] = useState('');

  useEffect(() => {
    props.handleAttachments(files);
    return () => {};
  }, [files]);

  function handleUpload(event) {
    let file = event.target.files[0];
    const currentFilesSize = files.reduce((a, b) => a + (b['size'] || 0), 0);
    const fileSizeTotal = parseInt(currentFilesSize) + parseInt(file.size);

    if (fileSizeTotal > 1000000) {
      setFileError('Total files limit is 1MB');
    } else {
      if (parseInt(file.size) > 300000) {
        setFileError('File size limit is 300KB');
      } else {
        setFiles([...files, file]);
        setFileError('');
      }
    }
  }

  return (
    <Autocomplete
      className={classes.root}
      multiple
      size="small"
      margin="dense"
      freeSolo
      disableClearable
      forcePopupIcon={false}
      open={false}
      onOpen={() => {}}
      value={files}
      onChange={(event, newValue) => {
        setFiles(newValue);
      }}
      options={[]}
      getOptionLabel={(option) => option.name}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) =>
          option.docID ? (
            <FileChip
              theme={theme.palette}
              docid={option.docID}
              size="small"
              label={option.name}
              key={index}
              {...getTagProps({ index })}
              onClick={() =>
                service.downloadFile(
                  {
                    uploadDocType: 'CustomerDefault',
                    customerID: props.customerID,
                  },
                  option.docID
                )
              }
            />
          ) : (
            <FileChip
              theme={theme.palette}
              size="small"
              label={option.name}
              key={index}
              {...getTagProps({ index })}
            />
          )
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          margin={props.margin}
          label={'Attachment for Agent (300KB per file, Max-Total=1MB)'}
          error={fileError ? true : false}
          helperText={fileError ? fileError : ''}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <label htmlFor="contained-button-file">
                <Input
                  accept="*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleUpload}
                />
                <Button color="default" component="span">
                  <AttachFileIcon /> Upload
                </Button>
              </label>
            ),
          }}
        />
      )}
    />
  );
};

FileUpload.propTypes = {};

export default FileUpload;
