import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { store } from '../store';
import TabItems from '../components/host/tabs/TabItems';
import { moduleTabService as service, portalService } from '../services';
import FormDialog from '../components/host/tabs/FormDialog';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';
import { dispatchAlert } from '../helpers';

const useStyles = makeStyles((theme, context) => ({
  root: {
    padding: '5px',

    '& div:nth-child(2)': {
      textAlign: 'right',
    },
  },
}));

const UP = -1;
const DOWN = 1;

const TabsPage = (props) => {
  useDocumentTitle('Tab Settings'); // Set page title
  const classes = useStyles();
  const { portalID } = store.getState().reducer.global;

  const [tabs, setTabs] = useState([]);
  const [openTabEditDiablog, setOpenTabEditDiablog] = useState(false);

  useEffect(() => {
    getTabs();
  }, []);

  const getTabs = () => {
    service.getPortalTabs(portalID).then(
      (res) => {
        setTabs(res);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleTabEditClickOpen = () => {
    setOpenTabEditDiablog(true);
  };

  const handleTabEditClickClose = (tab) => {
    if (tab) {
      addTab(tab);
    } else {
      setOpenTabEditDiablog(false);
    }
  };

  const addTab = async (tab) => {
    await service.addTab(JSON.stringify(tab)).then(
      (response) => {
        dispatchAlert('Tab was updated successfully.');
        setOpenTabEditDiablog(false);
        getTabs();

        portalService.getPortalTabs(portalID).then(
          (res) => {
            localStorage.setItem('portalTabs', JSON.stringify(res));
            store.dispatch({
              type: 'SET_PORTAL_TABS',
              payload: res,
            });
          },
          (error) => {
            console.log(error);
          }
        );
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
        dispatchAlert(error, true);
      }
    );
  };

  const handleMove = (id, direction, parentIndex) => {
    let position = null;

    const validate = async () => {
      if (position < 0 && parentIndex == null) {
        throw new Error('Given item not found.');
      } else if (
        (direction === UP && position === 0) ||
        (direction === DOWN && position === tabs.length - 1)
      ) {
        return; // canot move outside of array
      } else {
        getTabs();
      }
    };

    if (parentIndex == null) {
      position = tabs.findIndex((i) => i.index === id);
      validate();
    } else {
      position = tabs[parentIndex].tabChildren.findIndex((i) => i.index === id);
      validate();
    }
    return true;
  };

  const handleOnDelete = (val) => {
    if (val) getTabs();
  };

  return (
    <div className={classes.root}>
      <div className="view-title">Tabs</div>
      <div>
        <Button onClick={handleTabEditClickOpen}>Add New Tab</Button>
      </div>
      <TabItems
        tabs={tabs}
        setTabs={setTabs}
        onMove={handleMove}
        onSucceedAction={handleOnDelete}
      />
      {openTabEditDiablog && (
        <FormDialog
          open={openTabEditDiablog}
          onClose={handleTabEditClickClose}
          formMode="new"
          tab={''}
        />
      )}
    </div>
  );
};

export default TabsPage;
