import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReportIcon from '@mui/icons-material/BarChart';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { useHistory } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';

import { store } from '../../../store';
import { isNumeric } from '../../../helpers';
import { Link } from '@mui/material';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    color: 'inherit',
    fontSize: 12,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: ({ $priColor, $isChild, $style }) => ({
    height: '40px',
    padding: '0px',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
    paddingLeft: $style == 'icons' ? '0xp' : $isChild ? '40px' : '0px',

    '& a': {
      textDecoration: 'none',
      width: '100%',
      height: 'inherit',
      zIndex: 10,
      color: theme.palette.nsLeftMenuTextColor,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
    },

    '& .MuiTypography-root': {
      color: theme.palette.mode == 'light' ? 'black' : '#90a4ae',
      textTransform: 'uppercase',
      fontSize: '0.85rem',
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.mode == 'light' ? '#606060db' : 'lightslategray',
      width: '24px',
      height: 'inherit',
    },

    '& .MuiListItemText-root': {
      paddingLeft: '17px',
    },

    '&.Mui-selected': {
      '& a': {
        paddingLeft: '15px',
      },

      borderLeft: `5px solid ${theme.palette.primary.main} `,
      backgroundColor: theme.palette.nsAppBG.main,

      '& .svg-inline--fa, .MuiSvgIcon-root': {
        left: '15px',
        color:
          theme.palette.mode == 'light' ? theme.palette.primary.main : 'white',
      },

      '& .MuiTypography-root': {
        textShadow: '0 0 black',
      },
    },
  }),
}));

// import { ReactComponent as DashboardIcon } from '../../../assets/images/system/menu/24/dashboard.svg';
// import { ReactComponent as SigningIcon } from '../../../assets/images/system/menu/24/signing.svg';
// import { ReactComponent as ManageRequestIcon } from '../../../assets/images/system/menu/36/manage-request.svg';
// import { ReactComponent as ContactManagerIcon } from '../../../assets/images/system/menu/40/contact-manager.svg';
// import { ReactComponent as NotaryDeskIcon } from '../../../assets/images/system/menu/40/notary-desk.svg';
// import { ReactComponent as BusinessSettingsIcon } from '../../../assets/images/system/menu/40/business-settings.svg';
// import { ReactComponent as AgentPaymentIcon } from '../../../assets/images/system/menu/40/agent-payment.svg';
// import { ReactComponent as ReportIcon } from '../../../assets/images/system/menu/40/report.svg';
// import { ReactComponent as UserAdminIcon } from '../../../assets/images/system/menu/40/user-admin.svg';

const Item = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { viewport, tabName, routeName, isChild, style } = props;

  const currentActiveTabID = useSelector(
    (state) => state.reducer.activeTabID || 0
  );
  const { customColor: appColor } = useSelector(
    (state) => state.reducer.themeAppearance
  );
  const classes = useStyles({
    $priColor: appColor,
    $isChild: isChild,
    $style: style,
  });

  const tabIcon = () => {
    switch (routeName) {
      case 'home':
      case 'dashboard':
        return <DashboardOutlinedIcon />;
      // return <SvgIcon component={DashboardIcon} />;
      case 'signing-request':
        return <FeaturedPlayListOutlinedIcon />;
      // return <SvgIcon component={SigningIcon} inheritViewBox />;
      case 'management':
        return <BusinessCenterOutlinedIcon />;
      // return <SvgIcon component={SigningIcon} inheritViewBox />;
      case 'manage-request':
        return <ViewListOutlinedIcon />;
      // return <SvgIcon component={ManageRequestIcon} inheritViewBox />;
      case 'contact-manager':
        return <ContactPageOutlinedIcon />;
      // return <SvgIcon component={ContactManagerIcon} inheritViewBox />;
      case 'notary-desk':
        return <DoneAllIcon />;
      // return <SvgIcon component={NotaryDeskIcon} inheritViewBox />;
      case 'business-settings':
        return <SettingsOutlinedIcon />;
      // return <SvgIcon component={BusinessSettingsIcon} inheritViewBox />;
      case 'user-admin':
        return <ManageAccountsIcon />;
      // return <SvgIcon component={UserAdminIcon} inheritViewBox />;
      case 'reports':
        return <ReportIcon />;
      // return <SvgIcon component={ReportIcon} inheritViewBox />;
      case 'agent-payment-mgt':
        return <LocalAtmOutlinedIcon />;
      // return <SvgIcon component={AgentPaymentIcon} inheritViewBox />;
      default:
        break;
    }
  };

  const handleMenuClose = () => {
    if (['xs', 'sm'].includes(viewport)) {
      store.dispatch({ type: 'CLEAR_ALERTS' });
      dispatch({
        type: 'SET_MENU_STATE',
        payload: 'hidden',
      });
    }
  };

  const resolvePathname = () => {
    if (isNumeric(routeName)) {
      history.push(`/tab/${routeName}`);
    } else {
      history.push(`/${routeName}`);
    }
    handleMenuClose();
  };

  return (
    <ListItem
      button
      className={`${classes.root} ${style}`}
      selected={currentActiveTabID === props.tabID}
    >
      {style !== 'icons' && (
        <Fragment>
          <Link onClick={() => resolvePathname()}>
            {tabIcon()}
            <ListItemText primary={tabName} />
          </Link>
        </Fragment>
      )}
      {style === 'icons' && (
        <LightTooltip title={tabName} arrow placement="right">
          <Link onClick={() => resolvePathname()}>{tabIcon()}</Link>
        </LightTooltip>
      )}
    </ListItem>
  );
});

export default Item;
