import React, { useState } from 'react';
import { Box, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';

import AppDialog from '../common/others/AppDialog';
import { dispatchAlert, dispatchRelativeAlert } from '../../helpers';
import Loading from '../common/others/Loading';
import { userService } from '../../services';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HtmlTooltip } from '../../Style';

const UserPasswordUpdateDialog = (props) => {
  const { open, onClose, state, buttonTrueText, buttonFalseText } = props;
  const [onSubmiting, setOnSubmiting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState(state);
  const [errors, setErrors] = useState([]);

  const updateHandler = (e) => {
    e.preventDefault();

    if (!validate()) return;

    setErrors([]);

    if (state.title == 'Update Username') {
      setOnSubmiting(true);
      userService.updateUsername(state.profileId, formValues.userName).then(
        (res) => {
          setOnSubmiting(false);
          dispatchAlert('Username was updated successfully.');
          onClose(formValues.userName);
        },
        (error) => {
          setOnSubmiting(false);
          dispatchRelativeAlert(error + '.', true);
        }
      );
    } else if (state.title == 'Change Password') {
      setOnSubmiting(true);
      userService.updateUserPassword(state.profileId, formValues.password).then(
        (res) => {
          setOnSubmiting(false);
          dispatchAlert('Password was updated successfully.');
          onClose(false);
        },
        (error) => {
          setOnSubmiting(false);
          dispatchRelativeAlert(error + '.', true);
        }
      );
    }
  };

  const validate = () => {
    // { error: 'Please enter your password.', isValid: false },
    // { error: `Passwords don't match.`, isValid: false },
    // { error: `Passwords don't match.`, isValid: false },
    // { error: `Passwords don't match.`, isValid: false },
    let errors = [];
    let isValid = true;

    if (state.title == 'Update Username') {
      if (formValues['userName']?.length < 8) {
        isValid = false;
        errors.push({
          error: 'Username must be at least 8 characters long.',
          isValid: false,
        });
      } else {
        errors.push({
          error: 'Username must be at least 8 characters long.',
          isValid: true,
        });
      }
      // if (typeof formValues['userName'] !== 'undefined') {
      //   var pattern = new RegExp(
      //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      //   );
      //   if (!pattern.test(formValues['userName'])) {
      //     isValid = false;
      //     errors.push({
      //       error: 'Please enter valid email address.',
      //       isValid: false,
      //     });
      //   } else
      //     errors.push({
      //       error: 'Please enter valid email address.',
      //       isValid: true,
      //     });
      // }
    } else if (state.title == 'Change Password') {
      if (!formValues['password']) {
        isValid = false;
        errors.push({ error: 'Please enter your password.', isValid: false });
      } else errors.push({ error: 'Please enter your password.', isValid: true });

      if (!formValues['confirmPassword']) {
        isValid = false;
        errors.push({
          error: 'Please enter your confirm password.',
          isValid: false,
        });
      } else {
        errors.push({
          error: 'Please enter your confirm password.',
          isValid: true,
        });
      }

      if (typeof formValues['password'] !== 'undefined' && typeof formValues['confirmPassword'] !== 'undefined') {
        if (formValues['password'] != formValues['confirmPassword']) {
          isValid = false;
          errors.push({ error: `Passwords don't match.`, isValid: false });
        } else {
          errors.push({ error: `Passwords don't match.`, isValid: true });
        }
      }

      if (formValues['password']?.length < 8 || formValues['confirmPassword']?.length < 8) {
        isValid = false;
        errors.push({
          error: 'Please enter at least 8 characters.',
          isValid: false,
        });
      } else {
        errors.push({
          error: 'Please enter at least 8 characters.',
          isValid: true,
        });
      }
    }

    setErrors(errors);

    return isValid;
  };

  const updateDisableHandler = () => {
    if (state.title == 'Update Username') {
      return state.userName == formValues.userName;
    } else if (state.title == 'Change Password') {
      return !formValues.password;
    }
  };

  return (
    <AppDialog
      open={open}
      mainTitle={'Login Security'}
      complementaryData={{
        title: (
          <>
            {state.title}
            {state.title == 'Change Password' && (
              <>
                <HtmlTooltip
                  arrow
                  title={`Use at least 8 characters.\nUse at least one UpperCase.\nUse at least one LowerCase.\nUse only the following special character: *&#@.\nUse at least one number.`}
                >
                  <IconButton>
                    <InfoOutlinedIcon color="info" />
                  </IconButton>
                </HtmlTooltip>
              </>
            )}
          </>
        ),
      }}
      maxWidth="sm"
    >
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" onSubmit={updateHandler}>
          {errors.map((item, index) => (
            <FormGroup key={index}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '0.85rem' } }}
                control={<CheckIcon color={item.isValid ? 'success' : 'error'} />}
                label={item.error}
              />
            </FormGroup>
          ))}
          {errors.length > 0 && <br />}
          {state.title == 'Update Username' && (
            <TextField
              name="userName"
              value={formValues.userName || ''}
              onChange={(e) => setFormValues({ ...formValues, userName: e.target.value })}
            />
          )}
          {state.title == 'Change Password' && (
            <>
              <TextField
                inputProps={{ autoComplete: 'new-password' }}
                type={showPassword ? 'text' : 'password'}
                label="Password"
                name="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formValues.password || ''}
                onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
              />
              <TextField
                inputProps={{ autoComplete: 'new-password' }}
                type={showPassword ? 'text' : 'password'}
                label="Confirm Password"
                name="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formValues.confirmPassword || ''}
                onChange={(e) => setFormValues({ ...formValues, confirmPassword: e.target.value })}
              />
            </>
          )}
          <DialogActions sx={{ padding: '0px', paddingTop: '10px' }}>
            <Button color="default" onClick={() => onClose(false)}>
              {buttonFalseText}
            </Button>
            <Button type="submit" disabled={updateDisableHandler()} variant="contained">
              {buttonTrueText} {onSubmiting && <Loading lpad forButton />}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </AppDialog>
  );
};

export default UserPasswordUpdateDialog;
