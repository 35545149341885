import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, TextField, DialogContent, DialogActions } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import AppDialog from '../../common/others/AppDialog';
import { moduleTabService as service } from '../../../services/module-tab.service';
import { store } from '../../../store';
import Loading from '../../common/others/Loading';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import { dispatchAlert, dispatchRelativeAlert } from '../../../helpers';
import { portalService } from '../../../services';

const StyledModuleMgtToolBar = styled(Card)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: block;
      margin: 5px !important;
      border-radius: 5px !important;
    }

    z-index: 2;
    position: relative;
    width: 100%;
    border-radius: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255,255,255,0.12)'} !important;
    margin: 0px;
    padding: 15px;
    display: flex;
    justify-content: space-between;

    & div:nth-child(1) {
      align-self: center;
      flex-grow: 1;
    }

    & div:nth-child(2) {
      align-self: center;
      flex-grow: 3;
    }

    & div:nth-child(3) {
      align-self: center;
      flex-grow: 1;
      text-align: end;
    }
  `
);

const filterModuleOptions = createFilterOptions();

const ModuleMgtToolBar = (props) => {
  let mounted = React.useRef(true);
  const dispatch = useDispatch();
  const { portalID, reloadView } = props;
  const { currentPortalID = -1, activeTabID = -1 } = store.getState().reducer;

  const [open, setOpen] = React.useState(false);
  const [requestStarted, setRequestStarted] = useState({
    delete: false,
    update: false,
  });
  const [desktopModules, setDesktopModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [confirmationHandler, setConfirmationHandler] = useState(false);
  const [newModuleToogleOpen, setNewModuleToogleOpen] = useState(false);
  const [onSubmiting, setOnSubmiting] = useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    desktopModuleName: '',
    moduleTitleName: '',
    reactComponentRoute: '',
  });

  useEffect(() => {
    if (!open) return;
    service.getPortalModulesByPortal(portalID).then(
      (res) => {
        if (mounted.current) setDesktopModules(res);
        mounted.current = false;
      },
      (error) => {}
    );
    return () => {
      mounted.current = false;
    };
  }, [open]);

  const handleModuleUpdate = () => {
    setRequestStarted({ ...requestStarted, update: true });
    service.assignModuleToTab(selectedModule.desktopModuleID).then(
      (res) => {
        setRequestStarted({ ...requestStarted, update: false });
        setSelectedModule(null);
        dispatchAlert('Module was added successfully.');
        portalService.getPortalTabs(portalID).then(
          (res) => {
            localStorage.setItem('portalTabs', JSON.stringify(res));
            store.dispatch({ type: 'SET_PORTAL_TABS', payload: res });
          },
          (error) => console.log(error)
        );
        reloadView();
      },
      (error) => {
        setRequestStarted({ ...requestStarted, update: false });
        dispatchAlert(error + '.', true);
      }
    );
  };

  const handleConfirmation = (event, value) => {
    if (value) {
      if (parseInt(activeTabID) > 0) {
        setRequestStarted({ ...requestStarted, delete: true });
        service.deleteAllModuleFromTab(activeTabID).then(
          (res) => {
            setRequestStarted({ ...requestStarted, delete: false });
            setSelectedModule(null);
            dispatchAlert('Modules were deleted successfully.');
            reloadView();
          },
          (error) => {
            setRequestStarted({ ...requestStarted, delete: false });
            dispatchAlert(error + '.', true);
          }
        );
      }
    }
    setConfirmationHandler(false);
  };

  const handleDesktopModuleDDLChange = (newValue) => {
    if (typeof newValue === 'number') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        setNewModuleToogleOpen(true);
        setDialogValue({
          desktopModuleName: newValue,
          moduleTitleName: newValue,
          reactComponentRoute: '',
        });
      });
    } else if (newValue && newValue.inputValue) {
      setNewModuleToogleOpen(true);
      setDialogValue({
        desktopModuleName: newValue.inputValue,
        moduleTitleName: newValue.inputValue,
        reactComponentRoute: '',
      });
    } else {
      setSelectedModule(newValue);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await service.addNewModuleForAssignment(dialogValue).then(
      (res) => {
        setOnSubmiting(true);
        dispatchAlert('Deskptop Module was added successfully.');
        service.getPortalModulesByPortal(portalID).then((res) => {
          setDesktopModules(res);
          setNewModuleToogleOpen(false);
        });
      },
      (error) => {
        dispatchRelativeAlert(error + '.', true);
      }
    );
    setOnSubmiting(false);
  };

  return (
    parseInt(currentPortalID) >= 0 &&
    parseInt(activeTabID) > 0 && (
      <StyledModuleMgtToolBar id="moduleMgtToolBar">
        <div>
          <span>Module Management</span>
        </div>
        <div>
          <Autocomplete
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onOpen={() => setOpen(true)}
            onClose={(e) => setOpen(false)}
            options={desktopModules}
            defaultValue="Select Module"
            value={selectedModule}
            isOptionEqualToValue={(option, value) => option.desktopModuleID === value}
            getOptionLabel={(option) => {
              if (typeof option === 'number') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.friendlyName;
            }}
            filterOptions={(options, params) => {
              const filtered = filterModuleOptions(options, params);
              if (params.inputValue !== '') {
                filtered.push({
                  create: true,
                  inputValue: params.inputValue,
                  friendlyName: `Create Module: "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            renderOption={(props, option) =>
              option.create ? (
                <Button color="default" {...props} style={{ margin: '0 auto' }}>
                  {option.friendlyName}
                </Button>
              ) : (
                <li {...props}>{option.friendlyName}</li>
              )
            }
            onChange={(e, val) => {
              handleDesktopModuleDDLChange(val);
            }}
            renderInput={(params) => <TextField {...params} label="Modules" />}
          />
          {newModuleToogleOpen && (
            <AppDialog open={newModuleToogleOpen} mainTitle={'Create New Desktop Module'} maxWidth="sm">
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    autoFocus
                    label="Desktop Module Name"
                    value={dialogValue.desktopModuleName}
                    onChange={(e) =>
                      setDialogValue({
                        ...dialogValue,
                        desktopModuleName: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Module Title"
                    value={dialogValue.moduleTitleName}
                    onChange={(e) =>
                      setDialogValue({
                        ...dialogValue,
                        moduleTitleName: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="App Route Name"
                    value={dialogValue.reactComponentRoute}
                    onChange={(e) =>
                      setDialogValue({
                        ...dialogValue,
                        reactComponentRoute: e.target.value,
                      })
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="text" color="default" onClick={() => setNewModuleToogleOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={onSubmiting}>
                    Create
                    {onSubmiting && <Loading forButton lpad />}
                  </Button>
                </DialogActions>
              </form>
            </AppDialog>
          )}
        </div>
        <div>
          <Button
            disabled={requestStarted.delete}
            color="error"
            onClick={(e) =>
              setConfirmationHandler({
                event: e,
                title: 'Confirm Deletion',
                msg: 'Are you sure you wish to delete all modules from current page?',
              })
            }
          >
            Delete All
            {requestStarted.delete && <Loading forButton lpad />}
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            disabled={!selectedModule || requestStarted.update}
            onClick={handleModuleUpdate}
          >
            Add {requestStarted.update && <Loading forButton lpad />}
          </Button>
        </div>
        {confirmationHandler && (
          <AppConfirmationDialog
            open={confirmationHandler !== false ? true : false}
            title={confirmationHandler.title}
            content={confirmationHandler.msg}
            buttonTrueText="Delete All"
            color="error"
            onClose={() => {}}
            confirmCallback={(val) => handleConfirmation(confirmationHandler.event, val)}
          />
        )}
      </StyledModuleMgtToolBar>
    )
  );
};

export default ModuleMgtToolBar;
