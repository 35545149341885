import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import ModalAlert from './ModalAlert';
import { useModalStore } from '../../../context/modal.store';
import { useModalAlert } from '../../../helpers/hooks/useModals';
import { makeStyles, useTheme } from '@mui/styles';
import Show from '../others/Show';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme, context) => ({
  closeButton: {
    position: 'absolute',
    right: '5px',
    top: '2px',
  },
}));

export const ModalTitle = ({ children, handleClose, closeable, ...props }) => {
  const alerts = useModalStore((state) => state.alerts);
  const { removeAlert } = useModalAlert();

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <DialogTitle {...props} sx={{ position: 'relative' }}>
      {children}
      <Show when={closeable}>
        <span className={classes.closeButton}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon fontSize="medium" />
          </IconButton>
        </span>
      </Show>
      <ModalAlert alerts={alerts} handleClose={removeAlert} />
    </DialogTitle>
  );
};

export default ModalTitle;
