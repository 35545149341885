import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Divider,
  FormControlLabel,
  Checkbox,
  Stack,
  Alert,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

// Custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main, // Change to your desired color
    color: 'white',
  },
  cell: {
    fontSize: '0.875rem', // Smaller text
    padding: '8px', // Smaller padding
  },
  row: {
    height: '40px', // Smaller row height
  },
  title: {
    fontSize: '14px', // Smaller text
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '12px', // Smaller text
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
}));

import Show from '../common/others/Show';
import Typography from '@mui/material/Typography';
import { useAgentProfileFormStore } from './hooks/useAgentProfileFormStore';
import Chip from '@mui/material/Chip';
import { Link as RouterLink } from 'react-router-dom';

const AgentProfileTable = ({ agents }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  const disableSubmit = useAgentProfileFormStore((state) => state.disableSubmit);
  const restoreSubmit = useAgentProfileFormStore((state) => state.restoreSubmit);

  const handleCheckChange = (e) => {
    const value = e.target.checked;
    setChecked(value);
    if (value) {
      restoreSubmit(true);
    } else {
      disableSubmit(false);
    }
  };

  useEffect(() => {
    if (agents.length > 0) {
      setChecked(false);
      disableSubmit(false);
    }
    return () => {
      restoreSubmit(false);
    };
  }, [agents.length]);

  return (
    <Show when={agents.length > 0} animated>
      {/* Title and Subtitle */}
      <Typography variant="h6" className={classes.title}>
        Your Entry Matches Existing Profiles
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Please Verify You Are Not Entering a Duplicate Profile
      </Typography>

      <Divider sx={{ width: '100%', my: 1 }} />
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Agent Name</TableCell>
              <TableCell className={classes.header}>Work Email</TableCell>
              <TableCell className={classes.header}>Profile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agents.map((agent) => (
              <TableRow key={agent.PublicID} className={classes.row}>
                <TableCell className={classes.cell}>{agent.FullName}</TableCell>
                <TableCell className={classes.cell}>{agent.Email}</TableCell>
                <TableCell className={classes.cell}>
                  <Link
                    component={RouterLink}
                    color="primary"
                    underline="hover"
                    to={`/contact-manager/profile/agent/${agent.PublicID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Profile
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" sx={{ justifyContent: 'flex-end', my: 2, py: 0 }}>
        <Alert icon={false} severity={checked ? 'success' : 'error'} sx={{ borderRadius: '30px', mx: '0', py: '0' }}>
          <FormControlLabel
            label="I want to add a new profile"
            labelPlacement="start"
            sx={{ marginRight: '0' }}
            control={<Checkbox checked={checked} onChange={handleCheckChange} />}
          />
        </Alert>
      </Stack>
      {/* Add separator */}
      <Divider sx={{ width: '100%', my: 1 }}>
        <Chip label="Agent Information" size="small" variant="outlined" />
      </Divider>
    </Show>
  );
};

export default AgentProfileTable;
