import React, { Fragment, useState, useEffect, memo } from 'react';
import { Route, useParams } from 'react-router-dom';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

import Loading from './components/common/others/Loading';
import { portalService } from './services';
import { store } from './store';
import DashboardWelcome from './components/dashboard/DashboardWelcome';
import DashboardLinks from './components/dashboard/DashboardLinks';
import DashboardFacts from './components/dashboard/DashboardFacts';
import DashboardWeather from './components/dashboard/DashboardWeather';
import DashboardAgentDownloads from './components/dashboard/DashboardAgentDownloads';
import Login from './views/Login';
import Registration from './views/Registration';
import AgentRegistration from './views/AgentRegistration';
import NewSigningRequest from './views/NewSigningRequest';
import NotaryDesk from './views/NotaryDesk';
import ManageRequest from './views/ManageRequest';
import TabsPage from './views/TabsPage';
import UserAdmin from './views/UserAdmin';
import BusinessSettings from './views/BusinessSettings';
import Reports from './views/Reports';
import ModuleNotFound from './components/common/others/ModuleNotFound';
import NotAuthorized from './components/common/others/NotAuthorized';
import ContactManager from './views/ContactManager';
import ModuleOptions from './components/host/modules/ModuleOptions';
import UserProfile from './views/UserProfile';
import ForgotAgentPassword from './views/ForgotAgentPassword';
import ForgotCustomerPassword from './views/ForgotCustomerPassword';
import { AgentPaymentManagement } from './views/AgentPaymentManagement';
import { connect } from 'react-redux';
import { TechTools } from './components/admin/TechTools/Index';
import { AgentSigningAgreement } from './views/AgentSigningAgreement';
import { useHistory } from 'react-router-dom';
// import DashboardAgentDocs from './components/dashboard/DashboardAgentDocs';

const ModuleWapper = styled('div')(
  ({ theme, $withView, $withMarginTop }) => css`
    position: relative;
    margin: ${$withView ? 'auto' : `${$withMarginTop ? '10px' : '0px'} auto`};
    margin-bottom: 10px;

    .edit-module {
      position: relative;
    }

    .full-width {
      width: 100%;
    }

    /* ${theme.breakpoints.up('sm')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('lg')} {
      width: ${$withView ? '100%' : '85%'};
    }
    ${theme.breakpoints.between('1200', '1400')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.between('1401', '1500')} {
      width: ${$withView ? '100%' : '90%'} !important;
    } */
    ${theme.breakpoints.down('xl')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('xl')} {
      width: ${$withView ? '100%' : '95%'};
    }
    ${theme.breakpoints.up('1800')} {
      width: ${$withView ? '100%' : '1400px'};
    }
  `
);

const components = {
  // special logic for the below routes
  login: Login,
  registration: Registration,
  forgot_agent_password: ForgotAgentPassword,
  forgot_customer_password: ForgotCustomerPassword,
  'agent-registration': AgentRegistration,

  // Standard components for routes for authenticated users
  moduleNotFound: ModuleNotFound,
  notAuthorized: NotAuthorized,
  'dashboard-welcome': DashboardWelcome,
  'dashboard-links': DashboardLinks,
  'dashboard-fun-facts': DashboardFacts,
  'dashboard-weather': DashboardWeather,
  'dashboard-agent-downloads': DashboardAgentDownloads,
  'signing-request': NewSigningRequest,
  'manage-request': ManageRequest,
  tabs: TabsPage,
  'user-admin': UserAdmin,
  'business-settings': BusinessSettings,
  reports: Reports,
  'contact-manager': ContactManager,
  'notary-desk': NotaryDesk,
  'user-profile': UserProfile,
  'agent-payment-mgt': AgentPaymentManagement,
  'agent-agreement': AgentSigningAgreement,

  // ADMIN COMPONENTS
  'tech-tools': TechTools,
};

// Iterate through each element in the
// first array and if some of them
// include the elements in the second
// array then return true.
function findCommonsInArrays(arr1 = [], arr2 = []) {
  return arr1.some((item) => arr2.includes(item));
}

const isTabAuthorized = (tabInfo, currentUser) => {
  const { superUserId } = store.getState().reducer.global;

  if (!tabInfo) {
    return false;
  } else {
    let tabAuthRoles = tabInfo.authorizedRolesAllString.split(',') || [];
    if (
      (!currentUser?.userHasRoles && tabAuthRoles.includes('Unauthenticated Users')) ||
      tabAuthRoles.includes('All Users') ||
      findCommonsInArrays(tabAuthRoles, currentUser.userRoles) ||
      currentUser?.detail.userID === superUserId
    ) {
      return true;
    } else {
      return false;
    }
  }
};

const findRoute = (array = [], routeName = 'dashboard', urlTabID = null) => {
  for (const item of array) {
    if (urlTabID == null) {
      const result = item.routeName == routeName ? item : findRoute(item.tabChildren, routeName);
      if (result) return result;
    } else {
      const result = item.tabID == urlTabID ? item : findRoute(item.tabChildren, urlTabID);
      if (result) return result;
    }
  }
};

const findTabIdInTabs = (portalTabs, routeName, urlTabID = null) => {
  let tabObject = findRoute(portalTabs, routeName, urlTabID);
  let tabID = tabObject?.tabID || 0;

  // IMPORTANT: Here we set the activeTabID so we can fire the api to load alls modules that belong to the current Tab
  localStorage.setItem('NSActiveTabID', tabID);
  store.dispatch({ type: 'SET_ACTIVE_TAB_ID', payload: tabID });

  return tabObject;
};

/*************************************
----------- RouterHandler ------------
**************************************/

const RouteHandler = ({ store: store, dispatch, ...props }) => {
  const history = useHistory();
  const { global, portalTabs, currentUser, accessToken, needServiceAgreementSigned } = store;
  // console.log("################## RouteHandler #############################");
  //  console.log(currentUser);
  // console.log("##############################################");

  let urlTabID = useParams()?.tabID;
  const [loadingModules, setLoadingModules] = useState(false);
  const [modules, setModules] = useState([]);
  const [isSuperUser, setIsSuperUser] = useState(currentUser.userRoles.includes('SuperUser') ? true : false);

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    if (
      props.routeName !== 'login' &&
      props.routeName !== 'registration' &&
      props.routeName !== 'agent-registration' &&
      props.routeName !== 'forgot_agent_password' &&
      props.routeName !== 'forgot_customer_password'
    ) {
      if (portalTabs.length > 0) {
        dispatch({ type: 'CLEAR_ALERTS' });
        resolveModuleLoad(portalTabs);
      } else {
        if (accessToken.token) {
          portalService.getPortalTabs(global.portalID).then(
            (res) => {
              localStorage.setItem('portalTabs', JSON.stringify(res));
              dispatch({ type: 'SET_PORTAL_TABS', payload: res });
              dispatch({ type: 'CLEAR_ALERTS' });
              resolveModuleLoad(res);
            },
            (error) => console.log(error)
          );
        }
      }
    } else {
      dispatch({ type: 'CLEAR_ALERTS' });
      switch (props.routeName) {
        case 'login':
          setModules([{ Component: components['login'] }]);
          break;
        case 'registration':
          setModules([{ Component: components['registration'] }]);
          break;
        case 'agent-registration':
          setModules([{ Component: components['agent-registration'] }]);
          break;
        case 'forgot_agent_password':
          setModules([{ Component: components['forgot_agent_password'] }]);
          break;
        case 'forgot_customer_password':
          setModules([{ Component: components['forgot_customer_password'] }]);
          break;
        default:
          setModules([{ Component: components['login'] }]);
          break;
      }
    }

    return () => {
      controller.abort();
    };
  }, [props.routeName]);

  const resolveModuleLoad = (tabs = portalTabs) => {
    if (!props.routeName) urlTabID = urlTabID ? urlTabID : null;
    let tabObject = findTabIdInTabs(tabs, props.routeName, urlTabID);

    // check if user is authoriszed to see this page
    if (tabObject && isTabAuthorized(tabObject, currentUser)) {
      setLoadingModules(true);
      portalService
        .getPortalModulesByTab(signal, global.portalID, tabObject?.tabID)
        .then((res) => {
          if (res.length > 0) {
            res.forEach((element) => {
              // this will raise an error when module is not mapped to an reactComponentName in the ModuleDefinition table
              // DON'T REMOVE THIS LINE YET
              // element.Component = components[element.reactComponentName];

              // this line below replace the above
              element.Component = element.reactComponentName
                ? components[element.reactComponentName]
                : components['moduleNotFound'];
            });
            setModules([...res]);
          } else {
            setModules([{ Component: components['moduleNotFound'] }]);
          }
          setLoadingModules(false);
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            console.log('AbortError: Fetch request aborted');
          }
        });
    } else {
      setModules([{ Component: components['notAuthorized'] }]);
    }
  };

  const renderModulesInView = (props) => {
    return !loadingModules && modules ? (
      <ModuleWapper id="moduleWrapper" $withView={props.withView} $withMarginTop={!isSuperUser ? true : false}>
        {/* <div style={{ display: props.withView ? 'flex' : 'block' }}> */}
        {modules.map((item, index) =>
          isSuperUser && item.moduleID ? (
            <item.Component key={index} {...props}>
              <ModuleOptions module={item} reloadView={resolveModuleLoad} />
            </item.Component>
          ) : (
            <item.Component key={index} {...props} global={global} accessToken={accessToken}></item.Component>
          )
        )}
        {/* </div> */}
      </ModuleWapper>
    ) : (
      <Loading forPage />
    );
  };

  useEffect(() => {
    if (needServiceAgreementSigned) {
      history.push('/agent-agreement');
    }
  }, [needServiceAgreementSigned]);

  return (
    <Route
      render={(props) => {
        return <Fragment>{renderModulesInView(props)}</Fragment>;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  store: state.reducer,
  dispatch: state.dispatch,
});

export const RouteModuleHandler = connect(mapStateToProps)(memo(RouteHandler));
