import React, { useContext, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import NoteAddOutline from '@mui/icons-material/NoteAddOutlined';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import AssignStaffDialog from '../AssignStaffDialog';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import AppointmentDialog from '../../manage-request/AppointmentDialog';
import { Link as MuiLink, Card, Badge, Tooltip, Divider, Stack, Button, Typography } from '@mui/material';
import { CreateNewFolder, Folder } from '@mui/icons-material';
import { ManageRequestContext } from '../../../context/manage-request.context';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { store } from '../../../store';
import UploadFilesDialog from '../../common/file-uploader/upload-files-dialog';
import { parseStringTime } from '../../../helpers';
import { FileCounter, HtmlTooltip, RequestStatus } from '../../../Style';
import FileUploadProvider from '../../common/file-uploader/FileUploadContext';
import MoreOptions from './MoreOptions';
import AppDialog from '../../common/others/AppDialog';
import ScanBackUploader from '../../common/file-uploader/scan-backs';
import AgentConfirmation from '../signing-request/AgentConfirmation';

const RootDiv = styled(Card)(
  ({ theme }) => css`
    &.list-container {
      display: flex;
      justify-content: space-between;
      border-radius: 0;
      box-shadow: none;
      border-top: 0px;
      border-bottom: 0px;
      border-left: ${theme.palette.mode == 'dark' ? '0px' : 'initial'};
      border-right: ${theme.palette.mode == 'dark' ? '0px' : 'initial'};

      .list-column {
        flex: 1;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 5px;
        padding-left: 15px;
        font-size: 12px;

        .list-item {
          .icon-color {
            color: ${theme.palette.mode === 'dark' && '#c7c4c4'};
          }

          .icon-item {
            color: #76a6d5;
            cursor: pointer;
          }

          .staff {
            display: block;
            height: 24px;
            cursor: pointer;
            text-align: center;
          }

          .item-column-without-agent {
            display: none;
          }

          /* .email {
            float: left;
            color: ${theme.palette.primary.default};
          } */

          .email:hover {
            overflow: visible;
          }

          p {
            padding: 0px 0px 0px 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p:hover {
            overflow: visible;
          }

          .bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            height: 40px;
            align-items: center;
            font-size: small;
          }

          .bottom-right {
            display: flex;
            flex-direction: row;
            padding: 5px;
            align-items: center;
          }

          .bottom-left {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 5px;
            align-items: center;
          }

          .bottom-label {
            margin-left: 15px;
          }

          .bottom-items {
            display: flex;
            flex-direction: row;
            padding: 2px;
            margin-left: 15px;
            align-items: center;
          }

          .req {
            display: block !important;
            text-decoration: auto;

            h6 {
              font-weight: 500;
            }
          }

          .link2 {
            color: #f90;
            text-decoration: none;
            cursor: pointer;
            align-items: center;
          }

          .link2-disabled {
            color: #8e6016;
            cursor: default;
            text-decoration: none;
          }

          .link2:hover {
            text-decoration: underline;
          }

          .check {
            margin-bottom: 10px;
          }

          .req-docs {
            display: flex;
            height: 20px;
            width: 120px;
            justify-content: end;
            z-index: 0;

            .badge-file-container {
              display: flex;
              align-items: center;
              cursor: pointer;

              .MuiBadge-badge {
                font-size: 10px;
                margin-right: 6px;
                zoom: 85%;
              }
            }

            .scan-back {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 5px;
            }
          }

          .launch-profile {
          }

          [class*='item-'][class*='-tooltip'] {
            display: none;
          }
        }

        .list-item.req-log {
          display: flex;
          flex-wrap: wrap;
          padding-right: 20px;
          flex: 1.5;
          justify-content: space-between;
        }

        .list-item.when {
          flex: 2.75;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .appt-confirmed {
            display: flex;
            margin-top: 2px;
            flex-basis: 100%;
            gap: 5px;
            color: ${theme.palette.text.success};
          }
        }

        .list-item.hover-trigger {
          flex: 3;
          display: flex;
          align-items: center;
        }

        .list-item.hover-trigger:hover {
          [class*='item-'][class*='-tooltip'] {
            display: flex;
            padding: 2px;
          }
        }

        .list-item.btn-group-with-docs {
          padding-top: 10px;

          .req-docs {
            .badge-file-container {
              padding-right: 10px;
            }
          }
        }
      }
    }

    :nth-child(odd) {
      background-color: ${theme.palette.mode == 'dark' ? '#0b1318' : 'white'};
    }

    :nth-child(even) {
      background-color: ${theme.palette.mode == 'dark' ? '#1c2429' : 'rgba(241, 241, 241, 0.64)'};
    }

    :hover {
      background-color: ${theme.palette.mode == 'dark' ? theme.palette.nsTableRowHoverBG.main : 'aliceblue'};
    }
  `
);

const SigningRequestItem = ({ request }) => {
  const history = useHistory();
  const userRoles = store.getState().reducer.currentUser.userRoles;

  // const [openContactDialog, setOpenContactDialog] = useState(false);
  const [appointmentDialog, setAppointmentDialog] = useState(false);
  const [assignStaffDialog, setAssignStaffDialog] = useState(false);
  const [scanBacksUploader, setScanBacksUploader] = useState(false);
  const [signingUploader, setSigningUploader] = useState(false);

  const {
    filterPayLoad,
    controlSetting,
    getGeneralRequests,
    assignStaffToRequest,
    parseSendResponse,
    quickSigningUpdate,
  } = useContext(ManageRequestContext);

  const handleCloseAssignAgent = (value) => {
    if (value === 'cancel') {
      setAssignStaffDialog(false);
    } else {
      setAssignStaffDialog(false);
      assignStaffToRequest(request.requestID, value);
    }
  };

  const handleRequestActionPerform = (response, redirectTo) => {
    setAppointmentDialog();
    if (response) parseSendResponse(response);
    if (redirectTo) history.push(redirectTo);
    else getGeneralRequests({ ...filterPayLoad, pageSize: filterPayLoad.pageSize });
  };

  const isCustomerOrAgent = () => {
    return userRoles.includes('Customer') || userRoles.includes('NotaryAgent');
  };

  const assignNotaryLink = (linkName) => {
    let isCust = userRoles.includes('Customer');
    let showAgentInfo = controlSetting.chkShowAgentInfoToCustomer;
    let disableAssignNotary = controlSetting.disableAssignNotary;

    if (isCust && linkName == 'Assign Notary') linkName = 'Unassigned';

    return (
      ((isCust && showAgentInfo) || !isCust) && (
        <>
          {disableAssignNotary ? (
            <p>{linkName}</p>
          ) : (
            <div
              style={{
                marginLeft: !request.assignedAgentPublicID ? '40px' : '',
              }}
            >
              {!request.assignedAgentPublicID && (
                <div>
                  {request.agentAvailabilityRequestCount > 0 && (
                    <>
                      <span>Availability:</span>
                      <Badge
                        badgeContent={request.agentAvailabilityRequestCount}
                        color="info"
                        sx={{ zoom: '85%', marginLeft: '5px' }}
                      >
                        <MailOutlinedIcon color="action" fontSize="small" />
                      </Badge>
                    </>
                  )}
                </div>
              )}
              <MuiLink
                variant="subtitle2"
                color="text.primary"
                onClick={() => {
                  history.push({
                    pathname: `/manage-request/assign-notary/${request.requestID}`,
                    state: {
                      fromRequestList: true,
                      zip: request.signingZip,
                      assignedAgent: request.assignedAgentPublicID,
                    },
                  });
                }}
                // to={{
                //   pathname: `/manage-request/assign-notary/${request.requestID}`,
                //   state: {
                //     fromRequestList: true,
                //     zip: request.signingZip,
                //     assignedAgent: request.assignedAgentPublicID,
                //   },
                // }}
              >
                {linkName}
              </MuiLink>
            </div>
          )}
        </>
      )
    );
  };

  const scanBackToolTipTitle = () => {
    if (userRoles.includes('NotaryAgent') && request.signingStatusID > 3 && request.scanbacksDocsIn)
      return {
        title: 'Scan Back completed. Contact site owner for additional information.',
        disabled: true,
      };
    else if (userRoles.includes('NotaryAgent') && (request.signingStatusID < 4 || !request.scanbacksDocsIn))
      return {
        title: 'Please use the Notary Desk to upload your Scan Backs documents.',
        disabled: false,
      };
    else return { title: 'Scan Backs.', disabled: false };
  };

  const renderTooltipInfo = (htmlFor, title, disableInteractive = true, arrowmargin) => (
    <HtmlTooltip
      disableInteractive={disableInteractive}
      arrow
      arrowmargin={'-50px'}
      minwidth="150px"
      maxwidth="350px"
      title={title}
    >
      <IconButton className={`item-${htmlFor}-tooltip`}>
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
    </HtmlTooltip>
  );

  const enableContactBtn = () => {
    if (userRoles.includes('Customer')) return false;
    return true;
  };

  const parseHudDocsFlag = (hudDocsFlag) => {
    let stringArray = hudDocsFlag.split('');
    return (
      <>
        {stringArray[0] == '1' && (
          <Badge badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>L</FileCounter>
          </Badge>
        )}
        {stringArray[1] == '1' && (
          <Badge badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>T</FileCounter>
          </Badge>
        )}
      </>
    );
  };

  const getSchedulingNotesTitle = () => {
    if (!request.showUndefinedSigningTime && (request.schedulingNotes || !parseStringTime(request.signingTime))) {
      return (
        <>
          <p>{request.schedulingNotes && <span>Scheduling Notes: {request.schedulingNotes}</span>}</p>
          {request.signingTime !== request.schedulingNotes ? (
            <>{!parseStringTime(request.signingTime) && <p>{request.signingTime}</p>}</>
          ) : (
            ''
          )}
        </>
      );
    } else return '';
  };

  const signingTimeCalendarColor = () => {
    return getSchedulingNotesTitle() && !(parseStringTime(request.signingTime) || request.showUndefinedSigningTime);
  };

  return (
    <RootDiv variant="outlined" className="list-container">
      <div className="list-column">
        <div className="list-item req-log">
          <div style={{ textAlign: 'left' }}>
            <Link className="req" to={`/manage-request/detail/${request.requestID}`}>
              <Typography variant="subtitle1" color="primary">
                {request.requestSeqID}
              </Typography>
            </Link>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Link className="icon-item" to={`/manage-request/detail/${request.requestID}/log`}>
              {request.hasLogNotes === 'N' ? (
                <CreateNewFolder fontSize="medium" color="inherit" />
              ) : (
                <Folder fontSize="medium" color="inherit" />
              )}
            </Link>
          </div>
          <RequestStatus
            style={{
              flexBasis: '100%',
              width: 0,
              height: '100%',
              lineHeight: 'normal',
            }}
            $colorBase={request.statusColor}
          >
            {request.statusNameShort}
          </RequestStatus>
        </div>
        <div className="list-item" style={{ flex: 3, alignItems: 'center' }}>
          <p>
            <strong style={{ fontSize: '0.85rem' }}>{request.borrowerLastName}</strong>
            {request.borrowerFirstName && <span>{`, ${request.borrowerFirstName}`}</span>}
          </p>
          {(request.coBorrowerLastName || request.coBorrowerFirstName) && (
            <>
              <strong>{request.coBorrowerLastName}</strong>
              {request.coBorrowerFirstName && <span>{`, ${request.coBorrowerFirstName}`}</span>}
            </>
          )}
          <p>{request.cityState}</p>
        </div>
        <div className="list-item when hover-trigger">
          <div style={{ flex: 1, maxWidth: '90px' }}>
            <HtmlTooltip
              arrow
              disableInteractive={true}
              minwidth="150px"
              maxwidth="350px"
              title={getSchedulingNotesTitle()}
            >
              <div>
                <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                  <CalendarMonthOutlinedIcon
                    sx={{ fontSize: '1rem' }}
                    color={`${signingTimeCalendarColor() ? 'primary' : 'default'}`}
                  />
                  {format(parseISO(request.signingDate), 'MM/dd E')}
                </p>
                {(parseStringTime(request.signingTime) || request.showUndefinedSigningTime) && (
                  <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    <AccessTimeOutlinedIcon
                      sx={{ fontSize: '1rem' }}
                      color={`${getSchedulingNotesTitle() ? 'primary' : 'default'}`}
                    />
                    {parseStringTime(request.signingTime) ? request.signingTime : <b>{request.schedulingNotes}</b>}
                  </p>
                )}
              </div>
            </HtmlTooltip>
          </div>
          <div style={{ flex: 1, maxWidth: '30px', alignSelf: 'center' }}>
            <AgentConfirmation
              vertical
              confirmed={request.agentAppointmentConfirmed}
              onUpdate={(value) => {
                let data = {
                  updateType: 'AgentApptConfirm',
                  requestID: request.requestID,
                  requestSeqID: request.requestSeqID,
                  agentAppointmentConfirmed: value,
                };
                quickSigningUpdate(request.requestID, data).then((res) => {
                  if (res) request.agentAppointmentConfirmed = value;
                  else request.agentAppointmentConfirmed = false;
                });
              }}
            />
          </div>
          {request.agentAppointmentConfirmed && (
            <div className="appt-confirmed">
              <CheckBoxOutlinedIcon sx={{ fontSize: '1rem', color: 'inherit' }} />
              <Typography component="span" sx={{ fontSize: '1em', fontWeight: 600, color: 'inherit' }}>
                Appt. Confirmed
              </Typography>
            </div>
          )}
        </div>
        <div className="list-item hover-trigger">
          <div>
            <p>{request.contactInfo}</p>
            <p>{request.loanOfficerCompany}</p>
          </div>
          <div style={{ alignSelf: 'center' }}>
            {renderTooltipInfo(
              'customer',
              <>
                <p>{request.contactPhone}</p>
                <p
                  style={{
                    overflowWrap: 'anywhere',
                    lineHeight: '100%',
                  }}
                >
                  <MuiLink
                    onClick={(e) => e.stopPropagation()}
                    href={`mailto:${request.contactEmail}`}
                    color="text.primary"
                  >
                    <Typography className="email" variant="body2">
                      <u>{request.contactEmail}</u>
                    </Typography>
                  </MuiLink>
                </p>
              </>,
              false
            )}
          </div>
        </div>
        <div
          style={{ flex: 2.5 }}
          className={
            userRoles.includes('Customer') && controlSetting.chkShowAgentInfoToCustomer === false
              ? 'list-item item-column-without-agent hover-trigger agent'
              : 'list-item hover-trigger agent'
          }
        >
          {request.assignedAgentName == '(Assign Notary)' ? (
            assignNotaryLink('Assign Notary')
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {enableContactBtn() && (
                <IconButton
                  className="launch-profile"
                  onClick={(e) => {
                    history.push(`/manage-request/contact/agent/${request.assignedAgentPublicID}`);
                    // setOpenContactDialog({
                    //   userType: 'agent',
                    //   v2PublicID: request.assignedAgentPublicID,
                    //   fullName: request.assignedAgentName,
                    // });
                  }}
                >
                  <PersonPinCircleOutlinedIcon fontSize="medium" />
                </IconButton>
              )}
              {assignNotaryLink(request.assignedAgentName)}{' '}
              {renderTooltipInfo(
                'agent',
                <>
                  <p>{`Pref. Phone: ${request.agentPreferredPhone}`}</p>
                  <MuiLink
                    onClick={(e) => e.stopPropagation()}
                    href={`mailto:${request.agentMainEmail}`}
                    color="text.primary"
                  >
                    <Typography className="email" variant="body2">
                      <u>{request.agentMainEmail}</u>
                    </Typography>
                  </MuiLink>
                </>,
                false
              )}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1.5,
            alignItems: 'center',
          }}
          className={request.fileCount < 1 ? 'list-item btn-group-without-docs' : 'list-item btn-group-with-docs'}
        >
          <div className="req-docs">
            {request.fileCount < 1 ? (
              <div className="badge-file-container">
                {parseHudDocsFlag(request.hudDocsFlag)}
                <IconButton onClick={() => setSigningUploader(request)}>
                  <NoteAddOutline />
                </IconButton>
              </div>
            ) : (
              <Tooltip title={'Total files: ' + request.fileCount}>
                <div className="badge-file-container" onClick={() => setSigningUploader(request)}>
                  {request.fileLoanCount >= 1 && (
                    <Badge badgeContent={request.fileLoanCount} color="primary">
                      <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>L</FileCounter>
                    </Badge>
                  )}
                  {request.fileTitleCount >= 1 && (
                    <Badge badgeContent={request.fileTitleCount} color="primary">
                      <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>T</FileCounter>
                    </Badge>
                  )}
                  {request.fileOthersCount >= 1 && (
                    <Badge badgeContent={request.fileOthersCount} color="primary">
                      <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>O</FileCounter>
                    </Badge>
                  )}
                </div>
              </Tooltip>
            )}
            {request.scanBackRequired ? (
              <>
                <Divider sx={{ m: 0 }} orientation="vertical" variant="middle" flexItem />
                <Tooltip title={`${scanBackToolTipTitle().title} Total files: ${request.scanBacksFileCount}`}>
                  <Stack className="scan-back">
                    <IconButton
                      sx={{ color: 'coral' }}
                      disabled={scanBackToolTipTitle().disabled}
                      onClick={() => {
                        if (userRoles.includes('NotaryAgent'))
                          history.push({
                            pathname: `/notary-desk`,
                            state: {
                              defaultPanelTab: 3,
                              requestID: request.requestID,
                            },
                          });
                        else setScanBacksUploader(request);
                      }}
                    >
                      <Badge
                        sx={{
                          '.MuiBadge-badge': {
                            fontSize: '10px',
                            zoom: '85%',
                          },
                        }}
                        size="small"
                        color="primary"
                        showZero={false}
                        badgeContent={request.scanBacksFileCount}
                      >
                        <DocumentScannerOutlinedIcon />
                      </Badge>
                    </IconButton>
                  </Stack>
                </Tooltip>
              </>
            ) : (
              <div style={{ width: '34px' }}></div>
            )}
          </div>
        </div>
        {!(userRoles.length === 1 && isCustomerOrAgent()) && (
          <div style={{ flex: 1, textAlign: 'center' }} className="list-item staff">
            {request.staff === '***' ? (
              <IconButton onClick={() => setAssignStaffDialog(true)}>
                <AddCircleOutline />
              </IconButton>
            ) : (
              <Button variant="text" onClick={() => setAssignStaffDialog(true)}>
                <span style={{ fontSize: '0.8rem' }}>{request.staff}</span>
              </Button>
            )}
          </div>
        )}
        <div className="list-item" style={{ textAlign: 'end', flex: 1 }}>
          <MoreOptions
            horizontal
            request={request}
            settings={controlSetting}
            appointmentDialog={(e) => setAppointmentDialog(e)}
            // signingUploader={(req) => setSigningUploader(req)}
            // scanBacksUploader={(e) => scanBacksUploader(request)}
            // assignStaffDialog={(req) => setAssignStaffDialog(req)}
          />
        </div>
      </div>
      {appointmentDialog && (
        <AppointmentDialog
          open={appointmentDialog ? true : false}
          content={appointmentDialog}
          settings={controlSetting}
          ignoreDetailContext={true}
          request={appointmentDialog.request}
          confirmCallback={handleRequestActionPerform}
        />
      )}
      {signingUploader && (
        <FileUploadProvider
          context={{
            uploadDocType: 'SigningFiles',
            requestID: request.requestID,
          }}
        >
          <UploadFilesDialog
            open={signingUploader ? true : false}
            title={'Documents Upload'}
            buttonTrueText={'Save'}
            buttonFalseText={'Close'}
            documentData={{
              title:
                'Request #' +
                signingUploader.requestSeqID +
                ' - ' +
                [signingUploader.borrowerFirstName, signingUploader.borrowerLastName].join(' ') +
                ' ' +
                format(parseISO(signingUploader.signingDate), 'MM/dd/yyyy'),
            }}
            onClose={(e) => {
              getGeneralRequests({
                ...filterPayLoad,
                pageSize: filterPayLoad.pageSize,
              });
              setSigningUploader(false);
            }}
          />
        </FileUploadProvider>
      )}
      {scanBacksUploader && (
        <FileUploadProvider
          context={{
            uploadDocType: 'ScanBacks',
            requestID: scanBacksUploader.requestID,
            requestSeqID: scanBacksUploader.requestSeqID,
            customerID: scanBacksUploader.userID,
          }}
        >
          <AppDialog
            open={scanBacksUploader ? true : false}
            mainTitle="Scan Backs"
            complementaryData={{
              title:
                'Request #' +
                scanBacksUploader.requestSeqID +
                ' - ' +
                [scanBacksUploader.borrowerFirstName, scanBacksUploader.borrowerLastName].join(' ') +
                ' ' +
                format(parseISO(scanBacksUploader.signingDate), 'MM/dd/yyyy'),
            }}
          >
            <ScanBackUploader
              dialog
              open={scanBacksUploader ? true : false}
              data={scanBacksUploader}
              onClose={() => {
                getGeneralRequests(filterPayLoad);
                setScanBacksUploader(false);
              }}
            />
          </AppDialog>
        </FileUploadProvider>
      )}
      {assignStaffDialog && (
        <AssignStaffDialog open={assignStaffDialog} request={request} onClose={handleCloseAssignAgent} />
      )}
    </RootDiv>
  );
};

export default SigningRequestItem;
