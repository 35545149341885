import React, { useContext } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import NoteAddOutline from '@mui/icons-material/NoteAddOutlined';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Divider from '@mui/material/Divider';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { Badge, Button, ButtonGroup, Card, Stack, Tooltip, Typography } from '@mui/material';
import { CreateNewFolder, Folder } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { store } from '../../../store';
import { ManageRequestContext } from '../../../context/manage-request.context';
import { parseStringTime } from '../../../helpers';
import { FileCounter, HtmlTooltip, RequestStatus } from '../../../Style';
import AssignAgentCard from './AssignAgentCard';
import AgentConfirmation from '../signing-request/AgentConfirmation';

const StyledItemDesktop = styled('div')(
  ({ theme }) => css`
    display: flex;
    border-radius: 5px;
    width: 100%;
    display: flex;
    margin-bottom: 7px;
    gap: 1px;

    #left {
      width: 120px;
      margin-right: 1px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;

      .inner-wrapper {
        display: grid;
        gap: 5px;
        justify-items: center;
      }

      .icon-item {
        color: #76a6d5;
        cursor: pointer;
      }

      .left-item {
        display: flex;
        justify-content: center;
        cursor: pointer;
        white-space: pre-wrap;

        .item-link {
          text-decoration: none;
          color: ${theme.palette.primary.main};

          span {
            font-weight: bold;
          }
        }
      }

      .left-item:nth-child(n + 2) {
        margin-top: 5px;
      }
    }

    #right {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;

      & .chip {
        display: flex;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 0.75rem;
        height: 21px;
        border-radius: 16px;
        white-space: nowrap;
        margin-left: 5px;
        border: 1px solid #c0c0c0;
        width: 75px;

        & .chip-label {
          padding-right: 5px;
          font-weight: 500;
          display: none;
        }

        & .avatar {
          border-radius: 20px !important;
          width: 100%;
          font-weight: 500;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          height: 19px;
          font-size: 0.7rem;
          padding-top: 1px;
          color: #545458;
        }
      }

      & .item-columns {
        display: flex;
        flex-direction: row no-wrap;
        justify-content: space-around;
        align-items: flex-start;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px 0px 7px;
        font-size: 12px !important;
        gap: 10px;
      }

      & .item-columns .item-column:nth-of-type(1) {
        flex: 1;
      }

      & .item-columns .item-column:nth-of-type(2) {
        flex: 1;
      }

      & .item-columns .item-column:nth-child(3) {
        flex: 1.5;
      }

      & .item-columns .item-column:nth-child(4) {
        flex: 1.5;
      }

      & .item-columns .item-column:nth-child(5) {
        flex: 2;

        .doc-bill-notify {
          max-width: 300px;
        }
      }

      & .item-column {
        display: inline-block;
        text-align: left;
        min-height: 105px;
        position: relative;
        overflow-wrap: anywhere;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .item-column:not(.when) {
        padding-top: 5px;
      }

      & .item-column.when {
        .appt-confirmed {
          display: flex;
          flex-basis: 100%;
          gap: 5px;
          color: ${theme.palette.text.success};
        }
      }

      .agent {
        min-width: 50%;
      }

      & .item-column-without-agent {
        display: none;
      }

      & .btn-group {
        display: flex;
        align-items: center;

        .btn-group-label {
          font-weight: 500;
          margin-right: 5px;
          width: 50px;
        }

        .btn-group-items {
          display: flex;
          gap: 5px;
        }

        .btn-group-buttons {
          display: flex;
          gap: 15px;

          .req-docs,
          .req-staff {
            display: flex;
            align-items: center;
          }

          .req-staff {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;

            .bold {
              min-width: 35px;
            }
          }

          .fill-bkg-color {
          }

          .MuiButton-root {
            line-height: 100%;
            box-shadow: none;
            font-size: 0.6rem;
            text-transform: capitalize !important;

            .MuiTypography-root {
              font-size: 0.6rem;
            }

            .MuiSvgIcon-root {
              width: 1rem;
              height: 1rem;
            }
          }

          .MuiButton-root:nth-child(1) {
            width: 60px;
          }

          .MuiButton-root:nth-child(2) {
            width: 100px;
          }

          .MuiButton-root:nth-child(3) {
            width: 80px;
          }

          .scan-back {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
          }
        }
      }

      .btn-group-billing {
        top: 0;

        .billing {
          border: 1px solid rgba(112, 112, 113, 0.5);
        }
      }

      .btn-group-notify {
        top: 5px;
        margin-bottom: 0px;

        .notify {
          border: 1px solid rgba(112, 112, 113, 0.5);
        }
      }

      .billing.with-gradient,
      .notify.with-gradient {
        background: ${`linear-gradient(${theme.palette.primary2.main}, ${theme.palette.clearx2.main})`};
        font-weight: bold;
      }

      .billing.without-gradient,
      .notify.without-gradient {
        background: ${theme.palette.clear.main};
      }

      .billing.text,
      .notify.text {
        background: transparent;
      }

      @media screen and (min-width: 1550px) {
        & .btn-group-billing {
          top: 35px !important;
        }

        & .btn-group-notify {
          top: 70px !important;
        }
      }

      & .btn-group-without-docs {
        margin-top: 0px;
        margin-bottom: 0px;
        height: 30px;
      }

      & .btn-group-with-docs {
        height: 35px;

        & .badge-file-container {
          padding-right: 10px;
        }
      }

      & .badge-file-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .MuiBadge-badge {
          font-size: 10px;
          margin-right: 6px;
          zoom: 85%;
        }
      }

      .chip:hover {
        width: 115px;
        padding-left: 8px;

        & .chip-label {
          display: flex;
        }

        & .avatar {
          flex-shrink: 1;
          border-left: solid 1px rgba(0, 0, 0, 0.07);
        }
      }

      & .staff {
        display: block;
        width: 24px;
        height: 24px;
        text-align: center;
        cursor: pointer;
      }

      & .email {
        width: calc(100%);
        float: left;
        color: ${theme.palette.primary.default};
      }

      & .email:hover {
        overflow: visible;
      }

      & p {
        padding: 0px 0px 0px 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 40px;
        align-items: center;
        font-size: small;
        justify-content: space-around;
      }

      & .bold {
        font-weight: 500;
      }

      & .bold.p.m {
        margin-bottom: 5.5px;
      }

      > div {
        flex: 1 1 0px;
      }

      /* No less than 400px, no greater than 900px */
      @media (min-width: 1200px) and (max-width: 1550px) {
        & .item-columns .item-column:nth-child(4) {
          flex: 2;

          .customer {
            width: auto;
          }

          .agent {
            max-width: 90%;
          }
        }
      }

      @media screen and (min-width: 1200px) {
        & .btn-group {
          .btn-group-buttons {
            .MuiButtonGroup-root {
              height: 30px;
              margin-bottom: 5px;
            }
          }
        }
      }

      @media only screen and (max-width: 1370px) and (min-width: 1201px) {
        & .btn-group {
          .btn-group-buttons {
            .MuiButtonGroup-root {
              max-width: 200px;
            }
          }
        }
      }

      @media screen and (min-width: 1551px) {
        & .item-columns .item-column:nth-child(3) {
          flex: 1.25;
        }

        & .item-columns .item-column:nth-child(5) {
          .doc-bill-notify {
            position: absolute;
            right: 10px;
          }
        }
      }
    }
  `
);

const ItemDesktop = ({ request, appointmentDialog, signingUploader, scanBacksUploader, assignStaffDialog }) => {
  const history = useHistory();
  const userRoles = store.getState().reducer.currentUser.userRoles;
  const { controlSetting, quickSigningUpdate } = useContext(ManageRequestContext);

  const isCustomerOrAgent = () => {
    return userRoles.includes('Customer') || userRoles.includes('NotaryAgent');
  };

  const billingButtons = [
    <Tooltip
      key="1"
      title={
        request.invoiceCreated
          ? request.invoiced
            ? `Invoiced${!userRoles.includes('NotaryAgent') ? ': $' + request.customerInvoiceTotal : ''}`
            : `Invoice Created${!userRoles.includes('NotaryAgent') ? ': $' + request.customerInvoiceTotal : ''}`
          : 'To Invoice'
      }
      // disableHoverListener={userRoles.includes('Customer') ? true : false}
      // disableFocusListener={userRoles.includes('Customer') ? true : false}
    >
      <Button
        className={`billing ${
          request.invoiceCreated ? (request.invoiced ? 'with-gradient' : 'without-gradient') : 'transparent'
        }`}
        color={`${request.invoiceCreated ? 'primary' : 'text'}`}
        sx={{
          cursor:
            userRoles.includes('NotaryAgent') || (userRoles.includes('Customer') && !request.invoiced)
              ? 'not-allowed'
              : 'pointer',
        }}
        onClick={(e) => {
          if (!(userRoles.includes('NotaryAgent') || (userRoles.includes('Customer') && !request.invoiced))) {
            history.push(`/manage-request/invoice/customer/${request.requestID}`);
          }
        }}
      >
        Invoice
      </Button>
    </Tooltip>,
    <Tooltip
      key="2"
      sx={{ cursor: 'pointer' }}
      title={request.customerPaid ? 'Invoice Paid' : 'Record Invoice Payment'}
      disableHoverListener={userRoles.includes('Customer') ? true : false}
      disableFocusListener={userRoles.includes('Customer') ? true : false}
    >
      <Button
        className={`billing ${request.customerPaid ? 'with-gradient' : 'transparent'}`}
        color={`${request.customerPaid ? 'primary' : 'text'}`}
        sx={{
          cursor: !isCustomerOrAgent() ? 'pointer' : 'not-allowed',
        }}
        onClick={(e) => {
          if (!isCustomerOrAgent()) history.push(`/manage-request/invoice/customer/payment/${request.requestID}`);
        }}
      >
        Receive Payment
      </Button>
    </Tooltip>,
    <Tooltip
      key="3"
      title={
        request.notaryPaid ? `Paid Agent $${request.notaryInvoiceTotal}` : `Pay Agent $${request.notaryInvoiceTotal}`
      }
      disableHoverListener={userRoles.includes('Customer') ? true : false}
      disableFocusListener={userRoles.includes('Customer') ? true : false}
    >
      <Button
        className={`billing ${request.notaryPaid ? 'with-gradient' : 'transparent'}`}
        color={`${request.notaryPaid ? 'primary' : 'text'}`}
        sx={{
          cursor: !isCustomerOrAgent() ? 'pointer' : 'not-allowed',
        }}
        onClick={(e) => {
          if (!isCustomerOrAgent()) history.push(`/manage-request/invoice/agent/payment/${request.requestID}`);
        }}
      >
        Pay Agent
      </Button>
    </Tooltip>,
  ];

  const quickConfirmations = [
    <Button
      key="1"
      className={`notify ${request.appointmentConfirmed ? 'with-gradient' : 'transparent'}`}
      color={`${request.appointmentConfirmed ? 'primary' : 'text'}`}
      onClick={(e) => {
        appointmentDialog({
          type: 'Confirm',
          icon: <SendOutlinedIcon className="rotate" />,
          request: request,
        });
      }}
    >
      Confirm
    </Button>,
    <Button
      key="2"
      className={`notify ${request.appointmentComplete ? 'with-gradient' : 'transparent'}`}
      color={`${request.appointmentComplete ? 'primary' : 'text'}`}
      sx={{}}
      onClick={(e) => {
        appointmentDialog({
          type: 'Complete',
          icon: <PlaylistAddCheckOutlinedIcon />,
          request: request,
          moreOnSend: ['Send and Invoice'],
        });
      }}
    >
      Mark Complete
    </Button>,
    <Button
      key="3"
      className={`notify ${request.appointmentNotaryNote ? 'with-gradient' : 'transparent'}`}
      color={`${request.appointmentNotaryNote ? 'primary' : 'text'}`}
      sx={{}}
      onClick={(e) => {
        appointmentDialog({
          type: 'NotaryNote',
          icon: <EventNoteOutlinedIcon />,
          request: request,
        });
      }}
    >
      Notary Note
    </Button>,
  ];

  const scanBackToolTipTitle = () => {
    if (userRoles.includes('NotaryAgent') && request.signingStatusID > 3 && request.scanbacksDocsIn)
      return {
        title: 'Scan Back completed. Contact site owner for additional information.',
        disabled: true,
      };
    else if (userRoles.includes('NotaryAgent') && (request.signingStatusID < 4 || !request.scanbacksDocsIn))
      return {
        title: 'Please use the Notary Desk to upload your Scan Backs documents.',
        disabled: false,
      };
    else return { title: 'Scan Backs.', disabled: false };
  };

  const parseHudDocsFlag = (hudDocsFlag) => {
    let stringArray = hudDocsFlag.split('');
    return (
      <>
        {stringArray[0] == '1' && (
          <Badge badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>L</FileCounter>
          </Badge>
        )}
        {stringArray[1] == '1' && (
          <Badge badgeContent={0} color="primary">
            <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>T</FileCounter>
          </Badge>
        )}
      </>
    );
  };

  return (
    <StyledItemDesktop>
      <Card id="left">
        <div className="inner-wrapper">
          <div className="left-item item-1">
            <div>Req: </div>
            <Link className="item-link" to={`/manage-request/detail/${request.requestID}`}>
              <b>{request.requestSeqID}</b>
            </Link>
          </div>
          <div className="left-item item-2">
            <div>Log: </div>
            <Link className="item-link" to={`/manage-request/detail/${request.requestID}/log`}>
              {request.hasLogNotes === 'N' ? (
                <CreateNewFolder fontSize="small" className="icon-item" />
              ) : (
                <Folder fontSize="small" className="icon-item" />
              )}
            </Link>
          </div>
          <RequestStatus style={{ marginTop: '5px' }} $colorBase={request.statusColor}>
            {request.statusNameShort}
          </RequestStatus>
        </div>
      </Card>
      <Card id="right">
        <div className="item-columns">
          <div className="item-column">
            <p className="bold p m">Signer(s):</p>
            <Tooltip
              sx={{ cursor: 'pointer' }}
              title={
                <>
                  <strong style={{ fontSize: '0.85rem' }}>{request.borrowerLastName}</strong>
                  {request.borrowerFirstName && <span>{`, ${request.borrowerFirstName}`}</span>}
                </>
              }
            >
              <p>
                <strong style={{ fontSize: '0.85rem' }}>{request.borrowerLastName}</strong>
                {request.borrowerFirstName && <span>{`, ${request.borrowerFirstName}`}</span>}
              </p>
            </Tooltip>

            {(request.coBorrowerLastName || request.coBorrowerFirstName) && (
              <>
                <strong>{request.coBorrowerLastName}</strong>
                {request.coBorrowerFirstName && <span>{`, ${request.coBorrowerFirstName}`}</span>}
              </>
            )}
            <p>{request.cityState}</p>
          </div>
          <div className="item-column when">
            <p className="bold p">
              When:{'  '}
              <AgentConfirmation
                confirmed={request.agentAppointmentConfirmed}
                onUpdate={(value) => {
                  let data = {
                    updateType: 'AgentApptConfirm',
                    requestID: request.requestID,
                    requestSeqID: request.requestSeqID,
                    agentAppointmentConfirmed: value,
                  };
                  quickSigningUpdate(request.requestID, data).then((res) => {
                    if (res) request.agentAppointmentConfirmed = value;
                    else request.agentAppointmentConfirmed = false;
                  });
                }}
              />
            </p>
            <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              <>
                <CalendarMonthOutlinedIcon sx={{ fontSize: '1rem' }} color="default" />
                {format(parseISO(request.signingDate), 'MM/dd/yy E')}
              </>
            </p>
            {(parseStringTime(request.signingTime) || request.showUndefinedSigningTime) && (
              <p style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                <AccessTimeOutlinedIcon sx={{ fontSize: '1rem' }} color="default" />
                {parseStringTime(request.signingTime) ? request.signingTime : <b>{request.schedulingNotes}</b>}
              </p>
            )}
            {!request.showUndefinedSigningTime &&
              (request.schedulingNotes || !parseStringTime(request.signingTime)) && (
                <p style={{ display: 'flex' }}>
                  <HtmlTooltip
                    arrow
                    placement="bottom-start"
                    title={
                      <>
                        {request.schedulingNotes ? request.schedulingNotes : ''}
                        {!parseStringTime(request.signingTime) && (
                          <>
                            <br />
                            {request.signingTime}
                          </>
                        )}
                      </>
                    }
                  >
                    <Typography variant="body2" component="span" color="primary">
                      <u>See Notes</u>
                    </Typography>
                  </HtmlTooltip>
                </p>
              )}
            {request.agentAppointmentConfirmed && (
              <div className="appt-confirmed">
                <CheckBoxOutlinedIcon sx={{ fontSize: '1rem' }} color="inherit" />
                <Typography component="span" color="inherit" sx={{ fontSize: '1em', fontWeight: 600 }}>
                  Appt. Confirmed
                </Typography>
              </div>
            )}
          </div>
          <div className="item-column">
            <div className="customer">
              <p className="bold p m">Customer:</p>
              <p>{request.contactInfo}</p>
              <p>{request.loanOfficerCompany}</p>
              <p>{request.contactPhone}</p>
              <p>
                <a className=" email" href={`mailto:${request.contactEmail}`}>
                  {request.contactEmail}
                </a>
              </p>
            </div>
          </div>
          <div className="item-column">
            <div
              className={
                userRoles.includes('Customer') && controlSetting.chkShowAgentInfoToCustomer === false
                  ? 'agent item-column-without-agent'
                  : 'agent'
              }
            >
              <AssignAgentCard request={request} setting={controlSetting} />
            </div>
          </div>
          <div className="item-column">
            <div className="doc-bill-notify">
              <div
                className={request.fileCount < 1 ? 'btn-group btn-group-without-docs' : 'btn-group btn-group-with-docs'}
              >
                <div className="btn-group-buttons">
                  <div className="req-docs">
                    <div className="btn-group-label"> Docs:</div>
                    <div className="btn-group-items">
                      {request.fileCount < 1 ? (
                        <div className="badge-file-container">
                          {parseHudDocsFlag(request.hudDocsFlag)}
                          <IconButton onClick={() => signingUploader(request)}>
                            <NoteAddOutline />
                          </IconButton>
                        </div>
                      ) : (
                        <Tooltip title={'Total files: ' + request.fileCount}>
                          <div className="badge-file-container" onClick={() => signingUploader(request)}>
                            {request.fileLoanCount >= 1 && (
                              <Badge badgeContent={request.fileLoanCount} color="primary">
                                <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>L</FileCounter>
                              </Badge>
                            )}
                            {request.fileTitleCount >= 1 && (
                              <Badge badgeContent={request.fileTitleCount} color="primary">
                                <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>T</FileCounter>
                              </Badge>
                            )}
                            {request.fileOthersCount >= 1 && (
                              <Badge badgeContent={request.fileOthersCount} color="primary">
                                <FileCounter $colorBase={'rgb(0 0 0 / 15%)'}>O</FileCounter>
                              </Badge>
                            )}
                          </div>
                        </Tooltip>
                      )}
                      {request.scanBackRequired && (
                        <>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Tooltip title={`${scanBackToolTipTitle().title} Total files: ${request.scanBacksFileCount}`}>
                            <Stack className="scan-back">
                              <IconButton
                                sx={{ color: 'coral' }}
                                disabled={scanBackToolTipTitle().disabled}
                                onClick={() => {
                                  if (userRoles.includes('NotaryAgent'))
                                    history.push({
                                      pathname: `/notary-desk`,
                                      state: {
                                        defaultPanelTab: 3,
                                        requestID: request.requestID,
                                      },
                                    });
                                  else scanBacksUploader(request);
                                }}
                              >
                                <Badge
                                  sx={{
                                    '.MuiBadge-badge': {
                                      fontSize: '10px',
                                      zoom: '85%',
                                    },
                                  }}
                                  size="small"
                                  color="primary"
                                  badgeContent={request.scanBacksFileCount}
                                >
                                  <DocumentScannerOutlinedIcon />
                                </Badge>
                              </IconButton>
                            </Stack>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                  {!(userRoles.length === 1 && isCustomerOrAgent()) && (
                    <div className="req-staff">
                      <div className="bold">Staff:</div>
                      <div>
                        {request.staff === '***' ? (
                          <IconButton onClick={() => assignStaffDialog(request)}>
                            <AddCircleOutline />
                          </IconButton>
                        ) : (
                          <Button variant="text" onClick={() => assignStaffDialog(request)}>
                            <span style={{ fontSize: '0.8rem' }}>{request.staff}</span>
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="btn-group btn-group-billing">
                <div className="btn-group-label">Billing:</div>
                <div className="btn-group-buttons">
                  <ButtonGroup disableElevation color="default" size="small">
                    {billingButtons}
                  </ButtonGroup>
                </div>
              </div>
              {!isCustomerOrAgent() && (
                <div className="btn-group btn-group-notify">
                  <div className="btn-group-label">Notify:</div>
                  <div className="btn-group-buttons">
                    <ButtonGroup disableElevation color="default" size="small">
                      {quickConfirmations}
                    </ButtonGroup>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </StyledItemDesktop>
  );
};

export default ItemDesktop;
