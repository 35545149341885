import React, { memo, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { store } from '../../../store';

export const states = [
  { value: '', label: 'Select State' },
  { value: 'AA', label: 'AA - Military' },
  { value: 'AE', label: 'AE - Military' },
  { value: 'AK', label: 'AK - Alaska' },
  { value: 'AL', label: 'AL - Alabama' },
  { value: 'AP', label: 'AP - Military' },
  { value: 'AR', label: 'AR - Arkansas' },
  { value: 'AS', label: 'AS - American Samoa' },
  { value: 'AZ', label: 'AZ - Arizona' },
  { value: 'CA', label: 'CA - California' },
  { value: 'CO', label: 'CO - Colorado' },
  { value: 'CT', label: 'CT - Connecticut' },
  { value: 'DC', label: 'DC - Washington DC' },
  { value: 'DE', label: 'DE - Delaware' },
  { value: 'FL', label: 'FL - Florida' },
  { value: 'FM', label: 'FM - Micronesia' },
  { value: 'GA', label: 'GA - Georgia' },
  { value: 'GU', label: 'GU - Guam' },
  { value: 'HI', label: 'HI - Hawaii' },
  { value: 'IA', label: 'IA - Iowa' },
  { value: 'ID', label: 'ID - Idaho' },
  { value: 'IL', label: 'IL - Illinois' },
  { value: 'IN', label: 'IN - Indiana' },
  { value: 'KS', label: 'KS - Kansas' },
  { value: 'KY', label: 'KY - Kentucky' },
  { value: 'LA', label: 'LA - Louisiana' },
  { value: 'MA', label: 'MA - Massachusetts' },
  { value: 'MD', label: 'MD - Maryland' },
  { value: 'ME', label: 'ME - Maine' },
  { value: 'MH', label: 'MH - Marshall Isl' },
  { value: 'MI', label: 'MI - Michigan' },
  { value: 'MN', label: 'MN - Minnesota' },
  { value: 'MO', label: 'MO - Missouri' },
  { value: 'MP', label: 'MP - N. Mariana Isl' },
  { value: 'MS', label: 'MS - Mississippi' },
  { value: 'MT', label: 'MT - Montana' },
  { value: 'NC', label: 'NC - North Carolina' },
  { value: 'ND', label: 'ND - North Dakota' },
  { value: 'NE', label: 'NE - Nebraska' },
  { value: 'NH', label: 'NH - New Hampshire' },
  { value: 'NJ', label: 'NJ - New Jersey' },
  { value: 'NM', label: 'NM - New Mexico' },
  { value: 'NV', label: 'NV - Nevada' },
  { value: 'NY', label: 'NY - New York' },
  { value: 'OH', label: 'OH - Ohio' },
  { value: 'OK', label: 'OK - Oklahoma' },
  { value: 'OR', label: 'OR - Oregon' },
  { value: 'PA', label: 'PA - Pennsylvania' },
  { value: 'PR', label: 'PR - Puerto Rico' },
  { value: 'PW', label: 'PW - Palau' },
  { value: 'RI', label: 'RI - Rhode Island' },
  { value: 'SC', label: 'SC - South Carolina' },
  { value: 'SD', label: 'SD - South Dakota' },
  { value: 'TN', label: 'TN - Tennessee' },
  { value: 'TX', label: 'TX - Texas' },
  { value: 'UT', label: 'UT - Utah' },
  { value: 'VA', label: 'VA - Virginia' },
  { value: 'VI', label: 'VI - Virgin Islands' },
  { value: 'VT', label: 'VT - Vermont' },
  { value: 'WA', label: 'WA - Washington' },
  { value: 'WI', label: 'WI - Wisconsin' },
  { value: 'WV', label: 'WV - West Virginia' },
  { value: 'WY', label: 'WY - Wyoming' },
];

const States = (props) => {
  const { t, i18n } = useTranslation();
  const browserName = store.getState().reducer.browser;
  const {
    disabled = false,
    required = false,
    showError = false,
    value = '',
    withHelper = false,
    helperText = '',
    handleState,
    noLabel = false,
    sx = {},
    className = '',
    shrink = null,
  } = props;

  const [selectedState, setSelectedState] = useState(value);
  const [inputType, setInputType] = useState(browserName === 'Chrome' ? 'password' : 'new-password');

  const findStateObj = (val) => {
    let stateObj = states.filter((i) => i.value === val)[0];
    return stateObj?.label;
  };

  const textField = (params) => {
    params = {
      ...params,
      InputLabelProps: shrink ? { ...params.InputLabelProps, shrink: shrink, notched: 'true' } : params.InputLabelProps,
    };

    return (
      <>
        {withHelper ? (
          <TextField
            {...params}
            id="ns__state"
            sx={{ input: { ...sx } }}
            type={params.inputProps.value ? 'text' : inputType}
            label={noLabel ? '' : `${t('global.ns__state')}${required ? ' *' : ''}`}
            helperText={helperText}
            error={showError && !value ? true : false}
          />
        ) : (
          <TextField
            {...params}
            id="ns__state"
            sx={{ input: { ...sx } }}
            size={'small'}
            type={params.inputProps.value ? 'text' : inputType}
            label={noLabel ? '' : `${t('global.ns__state')}`}
            required={required}
            error={showError && required && !value ? true : false}
          />
        )}
      </>
    );
  };

  return (
    <Autocomplete
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.value === value}
      getOptionLabel={(option) => option.label || findStateObj(option) || ''}
      options={states}
      defaultValue={selectedState}
      blurOnSelect
      value={value}
      className={className}
      onChange={(e, val) => {
        setInputType('text');
        setSelectedState(val ? val.value : '');
        handleState(val ? val.value : '');
      }}
      renderInput={(params) => textField(params)}
    />
  );
};

export const CountryStates = memo(States);
