import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { HtmlTooltip } from '../../../Style';

export const InfoTooltip = (props) => (
  <HtmlTooltip arrow maxwidth="400px" {...props}>
    <IconButton>
      <InfoOutlinedIcon color="info" fontSize="small" />
    </IconButton>
  </HtmlTooltip>
);

export default InfoTooltip;
