import React from 'react';
import { TextField, MenuItem, Button, Divider, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { RequestLogContext as contextLog } from '../../../context/request-log.context';
import { HeaderWrapper } from './Style';

const Header = (props) => {
  const context = React.useContext(contextLog);
  const {
    logHeader,
    totalLogs,
    sortOptions,
    logInEditMode,
    addNewLog,
    sortLogsBy,
    selectedSort,
    setSelectedSort,
  } = context;

  return (
    <HeaderWrapper>
      <div className="header">
        <span>{logHeader.headerInfo}</span>
        <Chip label={`Log Total: ${totalLogs}`} size="small" />
      </div>
      <Divider />
      <div className="logs">
        <div>
          {logHeader.showAddNew && (
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              disabled={logInEditMode ? true : false}
              onClick={addNewLog}
            >
              Add New Entry
            </Button>
          )}
        </div>
        <div>
          <span>Sort By</span>
          <TextField
            id="state"
            select
            value={selectedSort}
            onChange={(e) => {
              setSelectedSort(e.target.value);
              sortLogsBy(e.target.value);
            }}
            SelectProps={{
              renderValue: (option) => option.label,
            }}
          >
            {sortOptions.map((option) => (
              <MenuItem key={option.key} value={option}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
