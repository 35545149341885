import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const agentPaymentMgtService = {
  /** Agent Payment Management Methods **/

  getRegCustomLabels: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = `/api/agentPaymentMgt/getRegCustomLabels`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  GetAgentPaymentsManagement: async function (paramObj) {
    paramObj.regInfo = paramObj.regInfo == '' ? {} : paramObj.regInfo;
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(paramObj),
    };
    const params = {};
    const url = `/api/agentPaymentMgt/NotaryPmtsAgentsToPayGet`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  createNewBatch: async function (payments) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(payments),
    };
    const params = {};
    const url = `/api/AgentPaymentMgt/createNewBatch`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  postApproved: async function (batchId) {
    const options = { headers: await authHeader() };
    const params = { batchId: batchId };
    const url = `/api/AgentPaymentMgt/postApproved`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  undoLastBatch: async function (batchId) {
    const options = { headers: await authHeader() };
    const params = { batchId: batchId };
    const url = `/api/AgentPaymentMgt/undoLastBatch`;
    return Fetch.remove(url, params, options).then(handleResponse);
  },

  undoCurrentBatch: async function (batchId) {
    const options = { headers: await authHeader() };
    const params = { batchId: batchId };
    const url = `/api/AgentPaymentMgt/UndoCurrentBatch`;
    return Fetch.remove(url, params, options).then(handleResponse);
  },

  getAgentPaymentReport: async function (batchId, format, byAgent) {
    const options = { headers: await authHeader() };
    const params = { batchId, format, byAgent };
    const url = `/api/AgentPaymentMgt/getAgentPaymentReport`;
    return Fetch.get(url, params, options);
  },
};

export { agentPaymentMgtService };
