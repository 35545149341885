import React, { Fragment, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AgentPaymentMgtFilter } from '../components/manage-request/Invoice/filter/AgentPaymentMgtFilter';
import { AgentPaymentMgtActions } from '../components/manage-request/Invoice/AgentPaymentMgtActions';
import { AgentPaymentMgtList } from '../components/manage-request/Invoice/AgentPaymentMgtList';
import AgentPaymentMgtProvider, { AgentPaymentMgtContext } from '../context/agent-payment-mgt.context';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';
import { AgentPaymentListOptions } from '../components/manage-request/Invoice/AgentPaymentListOptions';
import { InvoicePaymentsCss } from '../components/manage-request/Invoice/style';
import { AgentInvoicePayment } from '../components/manage-request/Invoice/AgentInvoicePayment';
import AgentPmtBreadcrumb from '../components/manage-request/Invoice/AgentPmtBreadcrumb';

export const AgentPaymentManagement = (props) => {
  useDocumentTitle('Agent Payment Management'); // Set page title

  const [breadcrumbState, setBreadcrumbState] = useState();

  return (
    <Route
      path="/agent-payment-mgt"
      render={({ match: { path } }) => (
        <AgentPaymentMgtProvider>
          <InvoicePaymentsCss>
            {props.history.location.pathname !== path && <AgentPmtBreadcrumb breadCrumbEvent={setBreadcrumbState} />}
            <Switch>
              <Route exact path={path + '/'}>
                <AgentPaymentMgtContext.Consumer>
                  {(context) => {
                    return (
                      <Fragment>
                        <AgentPaymentMgtActions />
                        <AgentPaymentMgtFilter style={{ margin: '7px 0px 10px' }} />
                        <AgentPaymentListOptions />
                        <AgentPaymentMgtList />
                      </Fragment>
                    );
                  }}
                </AgentPaymentMgtContext.Consumer>
              </Route>
              <Route path={`${path}/invoice/agent/payment/:requestID`}>
                <AgentInvoicePayment />
              </Route>
            </Switch>
          </InvoicePaymentsCss>
        </AgentPaymentMgtProvider>
      )}
    />
  );
};
