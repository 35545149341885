import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const PaginationRoot = styled('div')(
  ({ theme }) => css`
    ${theme.breakpoints.down('400')} {
      .MuiButtonBase-root {
        min-width: 20px;
        height: 20px;
      }
    }
  `
);

const PaginationCompact = (props) => {
  const { pageNumber, totalPages, handlePageNumber } = props;

  return (
    <PaginationRoot>
      <Stack spacing={2}>
        <Pagination
          size="small"
          count={totalPages}
          page={parseInt(pageNumber)}
          siblingCount={0}
          boundaryCount={1}
          onChange={(e, val) => handlePageNumber(val)}
        />
      </Stack>
    </PaginationRoot>
  );
};

export default PaginationCompact;
