export const agreedUponColumns = [
  {
    id: 1,
    field: 'id',
    headerName: '#',
    type: 'increment',
    hidden: true,
    editable: false,
    flex: 1,
  },
  {
    id: 1,
    field: 'description',
    headerName: 'Description',
    placeHolder: 'Enter Description...',
    editable: true,
    type: 'text',
    flex: 3,
  },
  {
    id: 2,
    field: 'fee',
    headerName: 'Amount',
    type: 'currency',
    editable: true,
    flex: 2,
  },
];

export const backgroundChecksColumns = [
  {
    id: 1,
    type: 'lookup',
    field: 'lookupName',
    headerName: 'Provider',
    lookups: [], // passed from the component
    editable: true,
    flex: 2,
  },
  {
    id: 2,
    field: 'conductedOn',
    headerName: 'Conducted',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'expiresOn',
    headerName: 'Expiration',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 4,
    field: 'referenceId',
    headerName: 'Ref',
    type: 'text',
    editable: true,
    flex: 2,
  },
];

export const licenseColumns = [
  {
    id: 1,
    field: 'state',
    headerName: 'State',
    type: 'text',
    editable: true,
    flex: 1,
  },
  {
    id: 2,
    field: 'licenseNumber',
    headerName: 'Comm',
    type: 'text',
    editable: true,
    flex: 3,
  },
  {
    id: 3,
    field: 'expiration',
    headerName: 'Expiration',
    type: 'text',
    editable: true,
    flex: 2,
  },
];

export const insuranceColumns = [
  {
    id: 1,
    field: 'carrierAndAmount',
    headerName: 'Carrier and Amount',
    type: 'text',
    editable: true,
    flex: 2,
  },
];

export const linksColumns = [
  {
    id: 1,
    type: 'lookup',
    field: 'lookupName',
    headerName: 'Type',
    editable: true,
    lookups: [], // passed from the component
    flex: 2,
  },
  {
    id: 2,
    field: 'name',
    headerName: 'Name',
    type: 'link',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'url',
    headerName: 'Url',
    type: 'linkUrl',
    editable: true,
    flex: 2,
  },
];

export const phoneNumbers = [
  {
    id: 1,
    field: 'number',
    headerName: 'Number',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 2,
    type: 'lookup',
    field: 'lookupName',
    headerName: 'Type',
    editable: true,
    lookups: [], // passed from the component
    flex: 2,
  },
  {
    id: 3,
    field: 'isDefault',
    headerName: 'Preferred',
    type: 'boolean',
    radio: true,
    editable: true,
    flex: 2,
  },
  {
    id: 4,
    field: 'canText',
    headerName: 'Text',
    type: 'boolean',
    switch: true,
    editable: false,
    flex: 1,
  },
];

export const customerPhoneNumbers = [
  {
    id: 1,
    field: 'number',
    headerName: 'Number',
    type: 'text',
    editable: true,
    flex: 3,
  },
  {
    id: 2,
    type: 'lookup',
    field: 'lookupName',
    headerName: 'Type',
    editable: true,
    lookups: [], // passed from the component
    updateFielsOnChange: ['phoneTypeId'],
    flex: 2,
  },
  {
    id: 3,
    field: 'isDefault',
    headerName: 'Preferred',
    type: 'boolean',
    radio: true,
    editable: true,
    flex: 1,
  },
  {
    id: 4,
    field: 'canText',
    headerName: 'Text',
    type: 'boolean',
    switch: true,
    editable: false,
    hidden: true,
    flex: 1,
  },
];

export const emailColumns = [
  {
    id: 1,
    type: 'lookup',
    field: 'lookupName',
    headerName: 'Type',
    editable: true,
    lookups: [], // passed from the component
    flex: 1,
  },
  {
    id: 2,
    field: 'address',
    headerName: 'Email Address',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'isDefault',
    headerName: 'Preferred',
    type: 'boolean',
    radio: true,
    editable: false,
    flex: 1,
  },
];

export const customerEmailColumns = [
  {
    id: 1,
    type: 'lookup',
    field: 'lookupName',
    headerName: 'Type',
    editable: true,
    lookups: [], // passed from the component
    updateFielsOnChange: ['emailTypeId'],
    flex: 1,
  },
  {
    id: 2,
    field: 'address',
    headerName: 'Email Address',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'isDefault',
    headerName: 'Preferred',
    type: 'boolean',
    radio: true,
    hidden: true,
    editable: false,
    flex: 1,
  },
];

export const invoiceContactEmail = [
  {
    id: 1,
    field: 'id',
    headerName: '#',
    type: 'increment',
    editable: false,
    flex: 1,
  },
  {
    id: 2,
    field: 'value',
    headerName: 'Email Address',
    type: 'text',
    editable: true,
    flex: 3,
  },
];

export const pricingColumns = [
  {
    id: 1,
    field: 'id',
    headerName: '#',
    type: 'increment',
    hidden: true,
    editable: false,
    flex: 2,
  },
  {
    id: 2,
    field: 'description',
    headerName: 'Service',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'amount',
    headerName: 'Price',
    type: 'currency',
    editable: true,
    flex: 1,
  },
];

export const additionalInfoColumns = [
  {
    id: 1,
    field: 'id',
    headerName: '#',
    type: 'increment',
    editable: false,
    hidden: true,
    flex: 1,
  },
  {
    id: 2,
    field: 'text',
    headerName: 'Additional Information',
    type: 'text',
    editable: true,
    flex: 2,
  },
];

export const customerLoanOfficerDefaults = [
  {
    id: 1,
    field: 'officerNum',
    headerName: '#',
    type: 'increment',
    editable: false,
    flex: 1,
  },
  {
    id: 2,
    field: 'officerName',
    headerName: 'Name',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 3,
    field: 'officerPhone',
    headerName: 'Phone',
    type: 'text',
    editable: true,
    flex: 2,
  },
  {
    id: 4,
    field: 'officerEmail',
    headerName: 'Email',
    type: 'text',
    editable: true,
    flex: 3,
  },
];
