import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TimeCountDown from './TimeCountDown';
import Loading from './Loading';
import { useTheme } from '@mui/styles';

function AppConfirmationDialog({ setConfirm = () => {}, confirmCallback = () => {}, ...props }) {
  const theme = useTheme();

  const {
    title = 'Confirmation',
    content = '',
    contentHtml = '',
    buttonTrueText = 'OK',
    buttonFalseText = 'Cancel',
    switchBtn = false, // True event on the right by default
    open = false,
    onClose,
    addTokenTimer = false,
    value = '',
    color = 'primary',
    onlyButtonClose = false,
    ...other
  } = props;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    return () => {
      setDisabled(false);
    };
  }, []);

  function allowTransition() {
    setConfirm(false);
    confirmCallback(true);
  }

  function blockTransition() {
    setConfirm(false);
    confirmCallback(false);
  }

  const handleFalse = (value) => {
    blockTransition();
    onClose(value);
  };

  const handleTrue = (value) => {
    allowTransition();
    onClose(true);
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      fullWidth
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ padding: '15px !important' }} dividers>
        {contentHtml ? contentHtml : <>{content}</>}
        {addTokenTimer && (
          <div style={{ fontSize: '35px' }}>
            <TimeCountDown timeInSecondsLef={value.remainingTime} setTimeCountDown={handleFalse} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {switchBtn ? (
          <>
            {!onlyButtonClose && (
              <Button
                autoFocus
                variant="text"
                color="default"
                disabled={disabled}
                onClick={() => {
                  setDisabled(true);
                  handleTrue(true);
                }}
              >
                {disabled ? (
                  <>
                    {buttonTrueText} <Loading forButton lpad />
                  </>
                ) : (
                  <>{buttonTrueText}</>
                )}
              </Button>
            )}
            <Button color={color} variant="contained" autoFocus onClick={() => handleFalse(false)}>
              {buttonFalseText}
            </Button>
          </>
        ) : (
          <>
            <Button color="default" variant="text" autoFocus onClick={() => handleFalse(false)}>
              {buttonFalseText}
            </Button>
            {!onlyButtonClose && (
              <Button
                autoFocus
                variant="contained"
                disabled={disabled}
                color={color}
                onClick={() => {
                  setDisabled(true);
                  handleTrue(true);
                }}
              >
                {disabled ? (
                  <>
                    {buttonTrueText} <Loading forButton lpad />
                  </>
                ) : (
                  <>{buttonTrueText}</>
                )}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

AppConfirmationDialog.propTypes = {
  // onClose: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
  // value: PropTypes.object.isRequired,
};

export default AppConfirmationDialog;
