import React, { useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { AppBar, IconButton, Button, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import MeNavBarDisplay from '../common/others/MeNavBarDisplay';
import { useDispatch } from 'react-redux';
import { store } from '../../store';
import { isServiceOwner } from '../../helpers';
import NotificationCenterProvider, { NotificationCenterContext } from '../../context/notification-center.context';
import NotificationDrawer from './notification/NotificationDrawer';

const StyledAppTopBar = styled('div')(
  ({ theme }) => css`
    display: flex;

    .MuiAppBar-root {
      position: fixed;
      border-bottom: ${theme.palette.mode == 'dark'
        ? '1px solid rgba(255,255,255,0.12)'
        : '1px solid rgba(0,0,0,0.12)'};
      padding: 0;
      padding-right: 0 !important;

      .MuiToolbar-root {
        justify-content: space-between;

        .toolbar-left {
          flex-grow: 1;
          display: flex;
          align-items: center;

          div:first-child {
            margin-right: 15px;
          }
        }

        .toolbar-right {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  `
);

const AppTopBar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { global, currentUser, showLoginBar, menuState, appViewport } = store.getState().reducer;

  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);

  return (
    <StyledAppTopBar>
      {currentUser.detail ? (
        <AppBar elevation={0} color="inherit">
          <Toolbar variant="dense">
            <div className="toolbar-left">
              <div>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={() =>
                    dispatch({
                      type: 'SET_MENU_STATE',
                      payload: menuState.current === 'expanded' ? 'icons' : 'expanded',
                    })
                  }
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
                {menuState.current === 'icons' && <span>{global.portalName} </span>}
                {['development', 'staging'].includes(BUILD_ENV) && <strong>[{appViewport}]</strong>}
              </div>
            </div>
            <div className="toolbar-right">
              <div className="notification-alert">
                <NotificationCenterProvider>
                  <NotificationCenterContext.Consumer>
                    {(context) => {
                      return (
                        <>
                          {isServiceOwner() && (
                            <>
                              <IconButton
                                aria-label="app alarm"
                                onClick={() => setOpenNotificationDrawer((prev) => !prev)}
                              >
                                {context.onNewRelease.seen ? (
                                  <NotificationsOutlinedIcon fontSize="medium" color="default" />
                                ) : (
                                  <NotificationsActiveIcon fontSize="medium" color="error" />
                                )}
                              </IconButton>
                              <NotificationDrawer
                                open={openNotificationDrawer}
                                onClose={() => setOpenNotificationDrawer(false)}
                              />
                            </>
                          )}
                        </>
                      );
                    }}
                  </NotificationCenterContext.Consumer>
                </NotificationCenterProvider>
              </div>
              <MeNavBarDisplay setTheme={(event) => props.setter(event)} />
            </div>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          {showLoginBar && (
            <AppBar position="fixed">
              <Toolbar variant="dense" style={{ justifyContent: 'flex-end' }}>
                <Button
                  color="inherit"
                  onClick={() => {
                    history.push('/login');
                    history.go('/login');
                  }}
                >
                  Login
                </Button>
              </Toolbar>
            </AppBar>
          )}
        </>
      )}
    </StyledAppTopBar>
  );
};

export default AppTopBar;
