import React from 'react';
import Slide from '@mui/material/Slide';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import ModalDialog from './ModalDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Modal = (props) => {
  const { open, onClose, useFullScreen, children, ...others } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ModalDialog
      open={open}
      handleCloseEvent={onClose}
      TransitionComponent={Transition}
      fullScreen={useFullScreen && fullScreen}
      {...others}
    >
      {children}
    </ModalDialog>
  );
};
