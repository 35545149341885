import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';
import { useLoadingStore } from '../../context/loading.store';
import { useCloseModal, useModal, useModalAlert } from '../../helpers/hooks/useModals';
import AgentProfileFormModal from './AgentProfileFormModal';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

export const useModals = () => {
  const loading = useLoadingStore((s) => s.loading);

  const { showModal: showFormModal } = useModal({
    template: <AgentProfileFormModal />,
    PaperProps: {
      elevation: 1,
    },
    useFullScreen: true,
    maxWidth: 'sm',
    disableEscapeKeyDown: true,
    preventCloseOnBackdropClick: true,
  });

  return {
    showFormModal,
  };
};

export const AgentProfileFormOpenBtn = ({}) => {
  const { showFormModal } = useModals();
  const { closeLatestModal, resetModals } = useCloseModal();
  const { pushAlert } = useModalAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = (e) => {
    pushAlert({
      type: 'success',
      message: 'Agent Profile Form submitted successfully',
    });
    e.stopPropagation();
  };

  const handleModalOpen = () => {
    showFormModal({
      handleSubmit,
      handleClose: () => {
        closeLatestModal();
        resetModals();
      },
    });
  };

  return (
    <Button
      id="add-new-agent"
      variant="contained"
      sx={{ minWidth: '35px', padding: '4px 6px', mb: '5px' }}
      endIcon={!isMobile && <AddIcon />}
      onClick={handleModalOpen}
    >
      New Agent
    </Button>
  );
};

export default AgentProfileFormOpenBtn;
