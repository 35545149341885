import React, { Fragment, useEffect, useRef, useState, Component } from 'react';
import { CardHeader, CardContent, Badge } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';

import { store } from '../../store';
import { DashboardCard } from './style';
import { dashboardService } from '../../services/dashboard.service';
import { useTheme } from 'styled-components';

const DashboardWelcome = (props) => {
  const mountedRef = useRef(true);
  const theme = useTheme();
  const [stateName, setStateName] = useState('Welcome');
  const [welcome, setWelcome] = useState({ showIcon: false });

  const { detail, userRoles } = store.getState().reducer.currentUser;

  const showName = (companyName) => {
    if (userRoles.includes('Customer')) {
      setStateName('Welcome ' + companyName);
    } else {
      setStateName('Welcome ' + detail.firstName + ' ' + detail.lastName);
    }
  };

  const getWelcomeByRoleName = () => {
    dashboardService.getWelcomeByRoleName().then(
      (res) => {
        if (!mountedRef.current) return null;
        setWelcome({ ...res, showIcon: true });
        showName(res.companyName);
      },
      (error) => {
        setWelcome({ showIcon: false });
      }
    );
  };

  useEffect(() => {
    getWelcomeByRoleName();
    return () => {
      mountedRef.current = false;
      setWelcome({ showIcon: false });
    };
  }, []);

  const renderItemAvatar = (item) => (
    <ListItemAvatar>
      {item.itemShortCode == 'OpenOrders' && (
        <Avatar>
          <PendingActionsOutlinedIcon fontSize="medium" />
        </Avatar>
      )}
      {item.itemShortCode == 'ScanBacks' && (
        <Avatar
          sx={{
            backgroundColor:
              theme.palette.mode == 'dark'
                ? theme.palette.nsTileHeaderBG.main
                : theme.palette.clear.main + ' !important',
          }}
        >
          <DocumentScannerOutlinedIcon sx={{ color: 'coral !important' }} fontSize="medium" />
        </Avatar>
      )}
    </ListItemAvatar>
  );

  const insertComponentsIntoText = (str, replacements = { [key]: React.ReactNode }) => {
    const splitRegex = new RegExp(/\[\[(\w*)\]\]/g);
    const parts = str.split(splitRegex);
    return parts.map((part, index) => {
      if (replacements.hasOwnProperty(part)) {
        return replacements[part];
      }
      return part;
    });
  };

  const renderItemText = (item) => {
    let args = JSON.parse(item.jsonArguments);
    return (
      <ListItemText
        primary={<Typography variant="h6">{item.primaryText}</Typography>}
        secondary={
          <Typography sx={{ display: 'inline' }} component="span" variant="body1" color="text.primary">
            {insertComponentsIntoText(item.secondaryText, {
              openOrdersCount: (
                <Badge
                  key="openOrdersCount"
                  showZero
                  badgeContent={args['openOrdersCount']}
                  color="error"
                  className="icon-welcome"
                >
                  <AssignmentOutlinedIcon color="action" />
                </Badge>
              ),
              scanBacksCount: <code key="scanBacksCount">{args['scanBacksCount']}</code>,
            })}
          </Typography>
        }
      />
    );
  };

  const getPropVal = (item, prop) => {
    let args = JSON.parse(item.jsonArguments);
    return args[prop];
  };

  return (
    <DashboardCard>
      {props.children} {/* this is for adding ModuleOptions */}
      <CardHeader className="card-header" title={<div className="title">{stateName}</div>}></CardHeader>
      <CardContent className="card-content message-welcome">
        <List sx={{ width: '100%', p: 0 }}>
          {welcome.messsages?.map((item, index) => (
            <Fragment key={index}>
              <ListItem alignItems="flex-start" disableGutters>
                {renderItemAvatar(item)}
                <ListItemButton
                  sx={{ p: 0, pl: '5px' }}
                  component={item.link ? Link : 'span'}
                  to={{
                    pathname: item.link,
                    state: {
                      fromDashboard: {
                        priorToToday: item.itemShortCode == 'OpenOrders' && getPropVal(item, 'priorToToday'),
                        hasScanBacks: item.itemShortCode == 'ScanBacks',
                      },
                    },
                  }}
                >
                  {renderItemText(item)}
                </ListItemButton>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          ))}
        </List>
      </CardContent>
    </DashboardCard>
  );
};

export default DashboardWelcome;
