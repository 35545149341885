import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { store } from '../../../store';
import { moduleTabService as service, portalService } from '../../../services';
import AppDialog from '../../common/others/AppDialog';
import { useDispatch } from 'react-redux';
import { dispatchRelativeAlert } from '../../../helpers';

const ModuleSettingsDialog = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, title, buttonTrueText, buttonFalseText } = props;
  const { portalID } = store.getState().reducer.global;

  const [onSubmiting, setOnSubmiting] = useState(false);
  const [module, setModule] = useState(props.module);
  const [portalRoles, setPortalRoles] = useState([]);
  const [defaultRoleValue, setDefaultRoleValue] = useState([]);

  useEffect(() => {
    portalService.getPortalRoles(portalID).then(
      (res) => {
        setPortalRoles(res);
        setDefaultRoleValue(
          res.filter((role) =>
            module.authorizedViewRoles
              .split(';')
              .includes(role.roleId.toString())
          )
        );
      },
      (error) => {
        // console.log(error);
      }
    );
  }, []);

  const rolesChangeHandler = (value) => {
    let newRoleIds = value.map((a) => a.roleId);
    setModule({
      ...module,
      authorizedViewRoles: newRoleIds.join(';') + ';',
    });
  };

  const updateTabModule = () => {
    setOnSubmiting(true);
    service.updateTabModule(JSON.stringify(module)).then(
      (res) => {
        setOnSubmiting(false);
        dispatchRelativeAlert('Module setting was updated successfully.');
        onClose(module);
      },
      (error) => {
        setOnSubmiting(false);
        dispatchRelativeAlert(error + '.', true);
      }
    );
  };

  return (
    <AppDialog open={open} mainTitle={title} maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        Roles that can View Content
      </DialogTitle>
      <DialogContent>
        <form className={''} action="">
          <Autocomplete
            id="asynchronous-demo"
            multiple
            size="small"
            margin="dense"
            options={portalRoles.map((option) => option)}
            value={defaultRoleValue}
            getOptionLabel={(option) => (option ? option.roleName : '')}
            onChange={(event, newValue) => {
              setDefaultRoleValue(newValue);
              rolesChangeHandler(newValue);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  label={option.roleName}
                  key={index}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Authorized View Roles"
                placeholder="Assign Others"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={() => onClose()}>
          {buttonFalseText}
        </Button>
        <Button variant="contained" onClick={() => updateTabModule(module)}>
          {buttonTrueText}
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

export default ModuleSettingsDialog;
