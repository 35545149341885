// import './wdyr'; // <--- first import
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from '@mui/material';

import { appThemes } from './theme/AppThemes';

localStorage.removeItem('NSCurrentPortalID');
localStorage.removeItem('NSPortalContext');

const storedTheme = store.getState().reducer.themeAppearance;
createTheme({ ...appThemes[storedTheme.customTheme] });

const container = document.getElementById('app');
const root = createRoot(container);

export const appReleaseNotes = BUILD_ENV == 'development' ? APP_RELEASE_NOTES : JSON.parse(APP_RELEASE_NOTES);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
