import React, { useState } from 'react';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const StyledView = styled('div')(
  ({ theme }) => css`
    overflow-y: hidden;
  `
);

const Dashboard = (props) => {
  useDocumentTitle('Dashboard'); // Set page title
  const [state, setState] = useState({ viewName: 'dashboard' });

  return <StyledView>{props.children}</StyledView>;
};

export default Dashboard;
