import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';

import { manageRequestService as service } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    '&.agentDDL': {
      display: 'flex',
      width: 'inherit',

      '& .MuiOutlinedInput-marginDense': {
        paddingTop: props.isWizard ? '' : '1.8px',
        paddingBottom: props.isWizard ? '' : '1.8px',
        top: props.isWizard ? '' : '1.5px',
      },
    },
  }),
}));

export default function StaffDDL(props) {
  const classes = useStyles(props);
  const { disabled, ddlType, value = '', onChange, disableClearable } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (!open) return;
      setLoading(true);
      await service.getStaffForDDL(ddlType).then(
        (res) => {
          setOptions(res);
        },
        (error) => console.log(error)
      );
      setLoading(false);
    })();

    return () => {};
  }, [open]);

  const findStaffObj = (val) => {
    let staffObj = options.filter((i) => i.staffID === val)[0];
    return staffObj;
  };

  React.useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id="select-agent"
      className={`${classes.root} agentDDL`}
      disabled={disabled}
      disableClearable={disableClearable}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(e) => setOpen(false)}
      value={value || ''}
      onChange={(e, newVal) => onChange(newVal)}
      isOptionEqualToValue={(option, value) => option.staffID === value.staffID}
      getOptionLabel={(option) =>
        findStaffObj(option)?.staffName || option.staffName || ''
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Staff"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
