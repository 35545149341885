import React, { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const TitleTextField = styled(TextField)(
  ({ theme }) => css`
    & .MuiInputBase-formControl {
      border: none;
      font-size: inherit;
      font-weight: 500;
    }
    & .MuiOutlinedInput-input {
      padding-left: 0px;
      padding-right: 0px;
    }
    & .MuiInputBase-input {
      border: none;

      & .Mui-disabled {
        -webkit-text-fill-color: ${theme.palette.text.primary};
      }
    }
    & .MuiOutlinedInput-input {
      font-size: inherit;
      padding: 4px;
    }

    & .MuiOutlinedInput-notchedOutline {
      /* border: none; */
    }

    & .MuiFormControl-root {
      padding: 0px !important;
      margin-top: 0px !important;
    }
  `
);

const InfoTextField = styled(TextField)(
  ({ theme }) => css`
    align-items: center;

    & .MuiInputBase-formControl {
      border: none;
      font-size: inherit;
      margin-right: 0px !important;
    }

    & .MuiOutlinedInput-input {
      font-size: inherit;
      padding: 8.5px;
    }

    & .MuiInputBase-input {
      &.Mui-disabled {
        -webkit-text-fill-color: ${theme.palette.text.primary};
      }
    }

    & .MuiFormControl-root {
      padding: 0px;
      margin-top: 0px;
      height: 20px;
    }
  `
);

const ProfileTextField = (props) => {
  const {
    sx,
    type = 'text',
    className,
    edit,
    title,
    required,
    error,
    disabled,
    helperText,
    value,
    onChange,
    multiline,
    rows,
    maxRows,
    maxLength,
  } = props;

  const [state, setState] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <>
      {title ? (
        <TitleTextField
          sx={{ input: { padding: '0px', ...sx } }}
          className={'info'}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
          }}
          value={value}
          variant={edit ? 'outlined' : 'standard'}
        />
      ) : (
        <InfoTextField
          type={showPassword ? 'text' : type}
          sx={{ input: { ...sx } }}
          className={className || 'info'}
          disabled={disabled}
          multiline={multiline}
          minRows={multiline ? rows : 1}
          maxRows={multiline ? maxRows : 1}
          required={required}
          error={error}
          helperText={helperText}
          value={state}
          onChange={(e) => setState(e.target.value)}
          onBlur={onChange}
          variant={edit ? 'outlined' : 'standard'}
          InputProps={
            edit
              ? {
                  maxLength: maxLength,
                  endAdornment: (
                    <>
                      {required ? (
                        <InputAdornment position="end">
                          <span style={{ color: error ? 'red' : 'inherit' }}>
                            *
                          </span>
                        </InputAdornment>
                      ) : (
                        ''
                      )}
                      {type == 'password' && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }
              : { disableUnderline: true }
          }
        />
      )}
    </>
  );
};

export default ProfileTextField;
