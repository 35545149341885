import React from 'react';
import { Button, LinearProgress, MenuItem, TextField } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useBusinessSettings } from '../../context/business-settings.context';
import { StyledAccordion } from '../../Style';

const timeZones = [
  { value: 'Hawaii', label: 'Hawaii Time' },
  { value: 'Alaska', label: 'Alaska Time' },
  { value: 'Pacific', label: 'Pacific Time' },
  { value: 'Mountain', label: 'Mountain Time' },
  { value: 'Central', label: 'Central Time' },
  { value: 'Eastern', label: 'Eastern Time' },
];

const dayLightSavingTimeOptions = [
  { value: 'D', label: 'Use Time Zone Default' },
  { value: 'Y', label: 'Use Daylight Savings' },
  { value: 'N', label: 'Do Not Use Daylight Savings' },
];

const ViewSettings = ({ callBack = () => {} }) => {
  const panelKey = 'viewSettings';

  const {
    dataIsLoading,
    expanded,
    setExpanded,
    currentSetting,
    setCurrentSetting,
    updateBusSettings,
    hasDirtyChanges,
    progressType,
  } = useBusinessSettings();

  const handleNavigationCallback = (e) => {
    if (hasDirtyChanges) {
      callBack({ confirmation: false, accordion: panelKey });
      e.stopPropagation();
    } else {
      if (expanded !== panelKey) {
        callBack({ confirmation: true, accordion: panelKey });
      } else {
        setExpanded(false);
      }
    }
  };

  return (
    <>
      <StyledAccordion
        id={`${panelKey}-Accordion`}
        expanded={expanded === panelKey}
        onChange={(e) => handleNavigationCallback(e)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="viewSettingsPanel-content"
          id="viewSettingsPanel-header"
          className={expanded === panelKey ? 'activePanel' : ''}
        >
          <Typography className="heading">View Settings</Typography>
          <Typography className={`secondaryHeading ${expanded === panelKey ? 'activePanel' : ''}`}>
            Default # of rows and more...
          </Typography>
        </AccordionSummary>
        {currentSetting && progressType == panelKey && (
          <>
            <AccordionDetails>
              <div className="rows">
                <div>
                  <div className="setting-label">Default # of Rows to Show - Contact List</div>
                  <div>
                    <TextField
                      id="defaulRows-contactList"
                      select
                      value={currentSetting.defaultRows_Contacts || ''}
                      onChange={(e) => {
                        setCurrentSetting({
                          ...currentSetting,
                          defaultRows_Contacts: e.target.value,
                        });
                      }}
                    >
                      {['5', '10', '15', '25', '50'].map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div>
                  <div className="setting-label">Default # of Rows to Show - Request Mgt</div>
                  <div>
                    <TextField
                      id="defaulRows-Requests"
                      select
                      value={currentSetting.defaultRows_Requests || ''}
                      onChange={(e) => {
                        setCurrentSetting({
                          ...currentSetting,
                          defaultRows_Requests: e.target.value,
                        });
                      }}
                    >
                      {['5', '10', '15', '25', '50'].map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div>
                  <div className="setting-label">Default Time Zone</div>
                  <div>
                    <TextField
                      id="default-timeZone"
                      select
                      value={currentSetting.ddlTimeZone || ''}
                      onChange={(e) => {
                        setCurrentSetting({
                          ...currentSetting,
                          ddlTimeZone: e.target.value,
                        });
                      }}
                    >
                      {timeZones.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div>
                  <div className="setting-label">Daylight Savings Time Option</div>
                  <div>
                    <TextField
                      id="default-timeZone"
                      select
                      value={currentSetting.ddlDaylightSavings || ''}
                      onChange={(e) => {
                        setCurrentSetting({
                          ...currentSetting,
                          ddlDaylightSavings: e.target.value,
                        });
                      }}
                    >
                      {dayLightSavingTimeOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </AccordionDetails>
            <div className="action-section">
              <Button disabled={dataIsLoading} variant="contained" onClick={() => updateBusSettings(panelKey, 'View')}>
                Update
              </Button>
            </div>
          </>
        )}
      </StyledAccordion>
      {dataIsLoading && progressType == panelKey && <LinearProgress sx={{ top: '-10px' }} color="inherit" />}
    </>
  );
};

export default ViewSettings;
