import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CardHeader, CardContent, Link, IconButton } from '@mui/material';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import { DashboardCard } from './style';
import { dashboardService } from '../../services/dashboard.service';
import { sanitizeHTML } from '../../helpers';

const DashboardWelcome = (props) => {
  const mountedRef = useRef(true);
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');

  const getTrivia = () => {
    dashboardService
      .getTrivia()
      .then((res) => {
        if (res.results.length > 0) {
          setQuestion(res.results[0].question);
          setAnswers([
            ...res.results[0].incorrect_answers,
            res.results[0].correct_answer,
          ]);
          setCorrectAnswer(res.results[0].correct_answer);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getTrivia();
    return () => {
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, []);

  return (
    <DashboardCard>
      {props.children} {/* this is for adding ModuleOptions */}
      <CardHeader
        className="card-header"
        title={
          <Fragment>
            <div className="title">
              Today's Trivia
              <IconButton
                className="refresh"
                onClick={() => {
                  setOpenAnswer(false);
                  getTrivia();
                }}
              >
                <LoopOutlinedIcon />
              </IconButton>
            </div>
          </Fragment>
        }
      ></CardHeader>
      <CardContent className="card-content">
        <div style={{}} dangerouslySetInnerHTML={sanitizeHTML(question)} />
        <ol>
          {answers.sort().map((value, index) => (
            <li key={index}>
              <div dangerouslySetInnerHTML={sanitizeHTML(value)} />
            </li>
          ))}
        </ol>
        <Link onClick={() => setOpenAnswer(!openAnswer)} className="link">
          See answer
        </Link>
        <br />
        <div hidden={!openAnswer} className="answer">
          <div dangerouslySetInnerHTML={sanitizeHTML(correctAnswer)} />
        </div>
      </CardContent>
    </DashboardCard>
  );
};

export default DashboardWelcome;
