import React, { useEffect, useRef, useState } from 'react';
import { CardHeader, CardContent, Avatar, ListItemAvatar } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

import { DashboardCard } from './style';
import { dashboardService } from '../../services/dashboard.service';
import { store } from '../../store';

const DashboardLinks = (props) => {
  const mountedRef = useRef(true);
  const [state, setState] = useState([]);
  const { currentUser } = store.getState().reducer;

  const getLinksByRoleName = () => {
    dashboardService.getLinksByRoleName().then(
      (res) => {
        let profileId = currentUser.userRoles.includes('NotaryAgent')
          ? currentUser.detail.publicID
          : currentUser.detail.userID;

        res.map((item) => {
          item.url = item.url.replace('{profileId}', profileId);
        });

        setState(res);
      },
      (error) => {}
    );
  };

  const avatarIcon = (iconName) => {
    switch (iconName) {
      case 'ManageAccountsOutlinedIcon':
        return (
          <Avatar>
            <ManageAccountsOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'DoneAllOutlinedIcon':
        return (
          <Avatar>
            <DoneAllOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'ViewListOutlinedIcon':
        return (
          <Avatar>
            <ViewListOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'FactCheckOutlinedIcon':
        return (
          <Avatar>
            <FactCheckOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'FolderSharedOutlinedIcon':
        return (
          <Avatar>
            <FolderSharedOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'ContactPageOutlinedIcon':
        return (
          <Avatar>
            <ContactPageOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'AssignmentIndOutlinedIcon':
        return (
          <Avatar>
            <AssignmentIndOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      case 'FeaturedPlayListOutlinedIcon':
        return (
          <Avatar>
            <FeaturedPlayListOutlinedIcon fontSize="medium" />
          </Avatar>
        );
      default:
        return (
          <Avatar>
            <CheckBoxOutlineBlankOutlinedIcon fontSize="medium" />
          </Avatar>
        );
    }
  };

  useEffect(() => {
    getLinksByRoleName();
    return () => {
      setState([]);
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, []);

  return (
    <DashboardCard>
      {props.children} {/* this is for adding ModuleOptions */}
      <CardHeader
        className="card-header"
        title={<div className="title">What would you like to do?</div>}
      ></CardHeader>
      <CardContent className="card-content">
        <List sx={{ width: '100%', p: 0 }}>
          {state?.map((item, index) => (
            <ListItem key={index} alignItems="flex-start" disableGutters>
              <ListItemAvatar>{avatarIcon(item.icon)}</ListItemAvatar>
              <ListItemButton
                component={Link}
                to={{
                  pathname: item.url,
                  state: { fromDashboard: true },
                }}
              >
                <ListItemText primary={item.displayName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </DashboardCard>
  );
};

export default DashboardLinks;
