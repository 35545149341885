import { store } from '../store';
import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';
import { isIOS, isMobile } from 'react-device-detect';

export const userService = {
  getAll,
  getById,
  logout,
  refreshToken,
  checkForRefreshToken,
  tokenTimeRemaining,
  updateUsername,
  updateUserPassword,
  customerRegistration,
  forgotCustomerPassword,
  getBusSettingsAnonymusInfo,
  forgotAgentPassword,
  userProfileAdminUpdate,
};

async function refreshToken() {
  const { accessToken, global } = store.getState().reducer;
  store.dispatch({ type: 'SET_ACCESS_TOKEN', payload: { isRefreshing: true } });

  let resStatus = 0;
  let endPoint = '';
  let body = '';

  if (isIOS && isMobile) {
    endPoint = `${SERVICE_URL}/users/authenticateWithLocalStorage?tokenActionType=REFRESH`;
    body = {
      currentJwtToken: '~token~',
      currentRefreshToken: localStorage.getItem('NSRefreshToken'),
      userID: localStorage.getItem('NSUserID'),
    };
  } else {
    endPoint = `${SERVICE_URL}/users/portal/${global.portalAliasHashed}/authenticate?tokenActionType=REFRESH`;
    body = { currentJwtToken: '~token~' };
  }

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken?.token}`,
    },
    body: JSON.stringify(body),
  };

  return await fetch(endPoint, requestOptions)
    .then((response) => {
      resStatus = response.status;
      if ([401, 400, 403].includes(response.status)) {
        // auto logout if any error happens
        store.dispatch({ type: 'LOGOUT', payload: false });
        location.reload();
      } else if (response.status) {
        if (isIOS && isMobile) {
          try {
            localStorage.setItem('NSRefreshToken', response.headers.get('X-Refresh-Token') || null);
            localStorage.setItem('NSUserID', response.headers.get('X-User-Id') || null);
          } catch (error) {
            store.dispatch({ type: 'LOGOUT', payload: false });
            location.reload();
          }
        }
      }
      return response.json();
    })
    .then((response) => {
      if (resStatus == 200) {
        store.dispatch({
          type: 'SET_ACCESS_TOKEN',
          payload: {
            token: response.token,
            lastRefreshTokenDate: Date.now(),
            onLogin: false,
            isRefreshing: false,
          },
        });

        store.dispatch({
          type: 'SET_SIGNED_AGREEMENT',
          payload: {
            needServiceAgreementSigned: response.needServiceAgreementSigned,
          },
        })
        return response;
      }
    })
    .catch((error) => {
      store.dispatch({ type: 'LOGOUT', payload: false });
      location.reload();
    });
  // .finally(() => {});
}

async function logout() {
  const { accessToken, global } = store.getState().reducer;
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: await authHeader(false, true),
    body: JSON.stringify({ currentJwtToken: accessToken?.token }),
  };

  if (accessToken?.token) {
    return fetch(
      `${SERVICE_URL}/users/portal/${global.portalAliasHashed}/authenticate?tokenActionType=REVOKE`,
      requestOptions
    )
      .then(() => {
        store.dispatch({ type: 'LOGOUT', payload: false });
      })
      .catch((error) => {
        store.dispatch({ type: 'LOGOUT', payload: false });
      });
  }
}

async function getAll() {
  const options = { headers: await authHeader() };
  const params = {};
  const url = `/users`;
  return Fetch.get(url, params, options).then(handleResponse);
}

async function getById(id) {
  const options = { headers: await authHeader() };
  const params = {};
  const url = `/users/${id}`;
  return Fetch.get(url, params, options).then(handleResponse);
}

function checkForRefreshToken() {
  const { accessToken } = store.getState().reducer;
  if (accessToken?.token) {
    // parse json object from base64 encoded jwt token
    const jwtObj = JSON.parse(window.atob(accessToken.token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    // console.log(currentTime);
    // console.log(jwtObj.exp - 130);

    // I have rounded to substract 100 seconds from the expiration token.
    // The reason to do this is because I am setting up a ~1 minute window before the token expired _
    // in order to refresh the token.
    // if (currentTime > jwtObj.exp - 100) refreshToken();
    if (currentTime > jwtObj.exp) refreshToken();
  }
}

function tokenTimeRemaining() {
  const { accessToken } = store.getState().reducer;
  if (accessToken?.token) {
    // parse json object from base64 encoded jwt token
    const jwtObj = JSON.parse(window.atob(accessToken.token.split('.')[1]));
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtObj.exp * 1000);
    // 60000 = 1 minute offset to allow revoke endpoint to get a valid non expired token
    // const timeout = expires.getTime() - 60000 - Date.now();
    const timeout = expires.getTime() - Date.now();
    if (parseInt(timeout) > 0) {
      return parseInt(timeout / 1000);
    }
  }

  return 0;
}

async function updateUsername(profileId, userName) {
  const options = {
    headers: await authHeader(),
    body: JSON.stringify(userName),
  };
  const params = { userID: profileId };
  const url = `/users/updateUsername`;
  return Fetch.update(url, params, options).then(handleResponse);
}

async function userProfileAdminUpdate(entityType, profileId, data) {
  const options = {
    headers: await authHeader(),
    body: JSON.stringify(data),
  };
  const params = { entityType, profileId };
  const url = `/users/UserProfileSettingsUpdate`;
  return Fetch.update(url, params, options).then(handleResponse);
}

async function updateUserPassword(profileId, password) {
  const options = {
    headers: await authHeader(),
    body: JSON.stringify(password),
  };
  const params = { userID: profileId };
  const url = `/users/updateUserPassword`;
  return Fetch.update(url, params, options).then(handleResponse);
}

async function customerRegistration(customerRegistration) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    body: JSON.stringify(customerRegistration),
  };

  return fetch(`${SERVICE_URL}/users/customer-registration`, requestOptions).then(handleResponse);
}

async function forgotCustomerPassword(forgotPssword) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    body: JSON.stringify(forgotPssword),
  };

  return fetch(`${SERVICE_URL}/users/forgot-customer-password`, requestOptions).then(handleResponse);
}

async function forgotAgentPassword(forgotPssword) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    body: JSON.stringify(forgotPssword),
  };

  return fetch(`${SERVICE_URL}/users/forgot-agent-password`, requestOptions).then(handleResponse);
}

async function getBusSettingsAnonymusInfo() {
  const options = { headers: await authHeader() };
  const params = {};
  const url = `/bussettings/getBusSettingsAnonymusInfo`;
  return Fetch.get(url, params, options).then(handleResponse);
}
