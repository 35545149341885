import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardContent, IconButton, TextField } from '@mui/material';
import Loading from '../../common/others/Loading';
import { EditableTable } from '../../common/others/EditableTable';
import { dispatchAlert, isValidEmail } from '../../../helpers';
import NumberFormat from 'react-number-format';
import { useParams, useHistory } from 'react-router-dom';
import { store } from '../../../store';
import { invoiceService as service } from '../../../services';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import { InvoiceCss } from './style';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { NSDatePicker } from '../../common/pickers/NSDatePicker';
import useDocumentTitle from '../../../helpers/hooks/useDocumentTitle';
import { useTheme } from 'styled-components';

const initialState = () => {
  return {
    invoiceStatus: 'NEW',

    invoiceNumOverride: '',
    revisionNum: '',
    invoiceDate: null,
    refInfo: '',
    companyAddress: '',
    companyEmails: [],

    customerAddress: '',
    customerEmails: [],

    items: [],

    terms: '',
    subtotal: 0,
    otherCharges: 0,
    total: 0,

    invoiceComments: '',
    borrowerInfo: '',

    requestID: 0,
    invoiceID: 0,
    portalID: 0,
    invoiceSeqID: 0,
    paidDate: null,
    customerID: 0,
    invoiceLastSentDate: '',
  };
};

const submittingInitialState = () => {
  return {
    save: false,
    postSend: false,
    post: false,
    preview: false,
  };
};

const invoiceCols = [
  {
    id: 1,
    field: 'itemDescription',
    headerName: 'Description',
    type: 'text',
    editable: true,
    flex: 4,
  },
  {
    id: 2,
    field: 'unitPrice',
    headerName: 'Unit Price',
    type: 'currency',
    editable: true,
    flex: 1,
  },
  {
    id: 3,
    field: 'quantity',
    headerName: 'Qty',
    type: 'number',
    editable: true,
    allowNegative: false,
    flex: 1,
  },
  {
    id: 4,
    field: 'extended',
    headerName: 'Amount',
    type: 'decimal-calculated',
    editable: false,
    flex: 1,
    calcColumns: ['quantity', 'unitPrice'],
  },
];

export const CustomerInvoice = () => {
  useDocumentTitle('Customer Invoice'); // Set page title
  const theme = useTheme();

  const { global } = store.getState().reducer;
  const [state, setState] = useState(initialState());
  const [isSubmitting, setIsSubmitting] = useState(submittingInitialState());
  const [isDisableForm, setIsDisableForm] = useState({ check: true });
  const [doActions, setDoActions] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({
    msg: '',
    open: false,
    handler: null,
  });
  const history = useHistory();
  const requestID = useParams().requestID;

  useEffect(() => {
    service.getInvoiceDetails(requestID).then(
      (res) => {
        setDoActions(!res.controlSetting.check);
        if (
          ((global.portalID == 1 || global.portalID == 0) &&
            !(res.invoice.portalID == 1 || res.invoice.portalID == 0)) ||
          global.portalID != res.invoice.portalID
        ) {
          setIsDisableForm({
            input: true,
            saveButton: true,
            cancelButton: true,
            previewButton: true,
            editButton: true,
            sendButton: true,
            sendNoEmailButton: true,
            deleteButton: true,
          });
          dispatchAlert('You do not have rights to view this invoice.', true);
        } else setInvoiceDetails(res.invoice, res.controlSetting);
      },
      (error) => {
        setDoActions(false);
        setIsDisableForm({
          input: true,
          saveButton: true,
          cancelButton: true,
          previewButton: true,
          editButton: true,
          sendButton: true,
          sendNoEmailButton: true,
          deleteButton: true,
        });
        dispatchAlert(error, true);
      }
    );

    return () => {};
  }, []);

  const setInvoiceDetails = (invoice, controlSetting) => {
    if (invoice.customerEmails.length == 0) {
      invoice.customerEmails.push('');
    }

    if (invoice.companyEmails.length == 0) {
      invoice.companyEmails.push('');
    }

    if (invoice.invoiceID == 0) {
      setIsDisableForm({
        input: false,
        saveButton: false,
        cancelButton: false,
        previewButton: true,
        editButton: true,
        sendButton: true,
        sendNoEmailButton: true,
        deleteButton: true,
        check: controlSetting?.check,
      });
    } else {
      setIsDisableForm({
        input: true,
        saveButton: true,
        cancelButton: true,
        previewButton: false,
        editButton: controlSetting && !controlSetting?.btnEditVisible ? true : false,
        sendButton: controlSetting && !controlSetting?.btnSendVisible ? true : false,
        sendNoEmailButton: controlSetting && !controlSetting?.btnSendNoEmailVisible ? true : false,
        deleteButton: controlSetting && !controlSetting?.btnDeleteVisible ? true : false,
        check: controlSetting?.check,
      });
    }

    setState((values) => ({
      ...values,
      ...invoice,
    }));
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;

    setState((values) => ({
      ...values,
      [fieldName]: event.target.value,
      [fieldName + 'Error']: '',
    }));
  };

  const handleEditTableUpdate = (items) => {
    let details = [...items.state];

    const subtotal = details
      .map((item, index) => {
        item.unitPrice = Number(item.unitPrice || 0);
        item.quantity = Number(item.quantity || 0);
        return Number(item.quantity) * Number(item.unitPrice);
      })
      .reduce((prev, curr) => prev + curr, 0);

    setState((values) => ({
      ...values,
      subtotal: subtotal,
      items: [...details],
      total: Number(subtotal) + Number(state.otherCharges || 0),
    }));
  };

  const isValid = () => {
    let isValid = true;
    let invalidCustomerEmail = state.customerEmails.filter((email) => email && !isValidEmail(email));

    let invalidCompanyEmail = state.companyEmails.filter((email) => email && !isValidEmail(email));

    if (invalidCustomerEmail.length > 0) {
      isValid = false;
      dispatchAlert(`There are ${invalidCustomerEmail.length} invalid customer email(s).`, true);
    }

    if (invalidCompanyEmail.length > 0) {
      isValid = false;
      dispatchAlert(`There are ${invalidCompanyEmail.length} invalid company email(s).`, true);
    }

    if (state.customerID == 0 && state.invoiceID == 0) {
      isValid = false;
      dispatchAlert(
        `Unable to save Invoice. Please assign a customer to the signing before creating an invoice.`,
        true
      );
    }

    if (state.invoiceDate == '' || state.invoiceDate == null) {
      isValid = false;
      dispatchAlert(`Unable to save Invoice. Please ensure the invoice date is valid.`, true);
    }

    return isValid;
  };

  const saveInvoice = () => {
    if (!isValid()) return;

    const asyncsaveInvoice = async () => {
      setIsSubmitting((v) => ({ ...v, save: true }));

      await service.saveInvoice(state).then(
        (res) => {
          setInvoiceDetails(res);
          dispatchAlert(`Invoice was saved. The Signing Request category has been set to 'Complete'.`);
        },
        (error) => dispatchAlert(error, true)
      );

      setIsSubmitting((v) => ({ ...v, save: false }));
    };

    asyncsaveInvoice();
  };

  const cancelInvoice = () => {
    if (state.invoiceID) {
      setIsDisableForm({
        input: true,
        saveButton: true,
        cancelButton: true,
        previewButton: false,
        editButton: false,
        sendButton: false,
        sendNoEmailButton: false,
        deleteButton: false,
      });
    } else {
      history.goBack();
    }
  };

  const PreviewPDFInvoice = () => {
    service.previewInvoicePDF(state.invoiceID);
  };

  const editInvoice = () => {
    setIsDisableForm({
      input: false,
      saveButton: false,
      cancelButton: false,
      previewButton: true,
      editButton: true,
      sendButton: true,
      sendNoEmailButton: true,
      deleteButton: true,
    });
  };

  const sendInvoice = () => {
    if (
      (state.companyEmails.length == 0 || state.companyEmails[0] == '') &&
      (state.customerEmails.length == 0 || state.customerEmails[0] == '')
    ) {
      dispatchAlert(`Unable to send invoice. Please enter an email for both the company and the customer.`, true);
    } else if (
      (state.companyEmails.length > 0 || state.companyEmails[0] != '') &&
      (state.customerEmails.length == 0 || state.customerEmails[0] == '')
    ) {
      dispatchAlert(`Unable to send invoice. Please enter an email for the customer.`, true);
    } else if (
      (state.companyEmails.length == 0 || state.companyEmails[0] == '') &&
      (state.customerEmails.length > 0 || state.customerEmails[0] != '')
    ) {
      dispatchAlert(`Unable to send invoice. Please enter an email for the company.`, true);
    } else {
      setOpenConfirmation((values) => ({
        msg: 'Are you sure you want to post and send this invoice?',
        open: true,
        handler: (val) => handleSendInvoiceConfirmation(val),
      }));
    }
  };

  const sendInvoiceNoEmail = () => {
    setOpenConfirmation((values) => ({
      msg: 'Are you sure you want to post this invoice?',
      open: true,
      handler: (val) => handleSendInvoiceNoEmailConfirmation(val),
    }));
  };

  const handleSendInvoiceConfirmation = (value) => {
    if (value) {
      const asyncSendInvoice = async () => {
        setIsSubmitting((v) => ({ ...v, postSend: true }));
        await service.sendInvoice(state).then(
          (res) => {
            if (res) {
              dispatchAlert(
                `Invoice Posted and Sent Successfully. The Signing Request category has been set to 'Invoiced'.`
              );
            } else {
              dispatchAlert(`Unable to send an email. Please email tech support at support@notarysoftware.com.`, true);
            }
          },
          (error) => dispatchAlert(error, true)
        );
        setIsSubmitting((v) => ({ ...v, postSend: false }));
      };

      asyncSendInvoice();
    }
    setOpenConfirmation({ msg: '', open: false, handler: null });
  };

  const handleSendInvoiceNoEmailConfirmation = (value) => {
    if (value) {
      const asyncSend = async () => {
        setIsSubmitting((v) => ({ ...v, post: true }));
        await service.sendInvoiceNoEmail(state.requestID, state.invoiceID).then(
          (res) => {
            dispatchAlert(`Invoice was posted successfully. The Signing Request category has been set to 'Invoiced'.`);
          },
          (error) => dispatchAlert(error, true)
        );
        setIsSubmitting((v) => ({ ...v, post: false }));
      };

      asyncSend();
    }
    setOpenConfirmation({ msg: '', open: false, handler: null });
  };

  const deleteInvoice = () => {
    setOpenConfirmation((values) => ({
      msg: 'Are you sure you want to delete this invoice?  The Signing Request category will be set to Complete.',
      open: true,
      handler: (val) => handleDeleteConfirmation(val),
    }));
  };

  const handleDeleteConfirmation = (value) => {
    if (value) {
      const asyncDelete = async () => {
        setIsSubmitting((v) => ({ ...v, delete: true }));
        await service.deleteInvoice(state.requestID, state.invoiceID).then(
          (res) => history.push('/manage-request'),
          (error) => dispatchAlert(error, true)
        );
        setIsSubmitting((v) => ({ ...v, delete: false }));
      };

      asyncDelete();
    }
    setOpenConfirmation({ msg: '', open: false, handler: null });
  };

  const addCustomerEmail = () => {
    if (state.customerEmails.length < 3) {
      const customerEmail = [...state.customerEmails, ''];

      setState((values) => ({
        ...values,
        customerEmails: customerEmail,
      }));
    }
  };

  const addCompanyEmail = () => {
    if (state.companyEmails.length < 3) {
      const companyEmails = [...state.companyEmails, ''];

      setState((values) => ({
        ...values,
        companyEmails: companyEmails,
      }));
    }
  };

  return (
    <InvoiceCss>
      {openConfirmation.open && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={openConfirmation.open}
          title={'Confirmation'}
          content={openConfirmation.msg}
          buttonTrueOnLeft={true}
          onClose={() => {}}
          confirmCallback={(val) => openConfirmation.handler(val)}
        />
      )}
      <Card className="card card-title">
        <div className="invoice-title">Invoice for Signing Engagement</div>
        {state.requestID > 0 && (
          <div className="buttons">
            {!isDisableForm.previewButton && (
              <Button className="button" onClick={PreviewPDFInvoice}>
                Preview PDF Invoice {isSubmitting.preview && <Loading forButton lpad />}
              </Button>
            )}

            {!isDisableForm.saveButton && (
              <Button variant="contained" className="button" onClick={saveInvoice}>
                Save {isSubmitting.save && <Loading forButton lpad />}
              </Button>
            )}

            {!isDisableForm.editButton && (
              <Button className="button" onClick={editInvoice}>
                Edit
              </Button>
            )}

            {!isDisableForm.sendButton && (
              <Button className="button" variant="contained" color="info" onClick={sendInvoice}>
                Post and Send {isSubmitting.postSend && <Loading forButton lpad />}
              </Button>
            )}

            {!isDisableForm.sendNoEmailButton && (
              <Button className="button" color="info" variant="contained" onClick={sendInvoiceNoEmail}>
                Post only {isSubmitting.post && <Loading forButton lpad />}
              </Button>
            )}

            {!isDisableForm.deleteButton && (
              <Button className="button" color="error" onClick={deleteInvoice}>
                Delete {isSubmitting.delete && <Loading forButton lpad />}
              </Button>
            )}

            {!isDisableForm.cancelButton && (
              <Button className="button" onClick={cancelInvoice}>
                Cancel
              </Button>
            )}
          </div>
        )}
      </Card>

      {state.invoiceLastSentDate && (
        <Alert
          sx={{ mb: 1, border: `1px solid ${theme.palette.mode == 'dark' ? '#1f495b' : '#99d0e7'}` }}
          variant="standard"
          severity="info"
        >
          <label>Invoice was last sent on: </label>
          <b>{state.invoiceLastSentDate}</b>
        </Alert>
      )}

      <Card className="card">
        <CardContent>
          <div className="control-group">
            <TextField
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: `${theme.palette.mode == 'dark' ? '#000000' : '#e5f6fd'}`,
                  fontWeight: 'bold',
                  '-webkit-text-fill-color': `${theme.palette.mode == 'dark' ? 'white' : 'black'}`,
                },
              }}
              InputLabelProps={{ shrink: true }}
              label="Invoice #"
              name="invoiceStatus"
              disabled={true}
              value={state.invoiceStatus}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Invoice # Override"
              name="invoiceNumOverride"
              onChange={handleChange}
              disabled={isDisableForm.input}
              value={state.invoiceNumOverride}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Revision #"
              name="revisionNum"
              onChange={handleChange}
              disabled={isDisableForm.input}
              value={state.revisionNum}
            />
            <NSDatePicker
              style={{
                '& .MuiInputBase-input': {
                  fontWeight: 'bold',
                  '-webkit-text-fill-color': `${theme.palette.mode == 'dark' ? 'white' : 'black'}`,
                },
                '& .MuiInputBase-root': {
                  backgroundColor: `${theme.palette.mode == 'dark' ? '#000000' : '#e5f6fd'}`,
                },
              }}
              label="Invoice Date"
              disabled={isDisableForm.input}
              onChangeValidation
              date={state.invoiceDate}
              emitDate={(date) =>
                setState((values) => ({
                  ...values,
                  invoiceDate: date,
                }))
              }
            />
          </div>
          <div className="">
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Reference Information"
              name="refInfo"
              onChange={handleChange}
              disabled={isDisableForm.input}
              value={state.refInfo}
            />
          </div>
        </CardContent>
      </Card>

      <div className="flex-container">
        <Card className="card card-company">
          <CardContent>
            <div className="card-sub-title">Invoice Company Address</div>
            <TextField
              InputLabelProps={{ shrink: true }}
              multiline
              rows={4}
              name="companyAddress"
              onChange={handleChange}
              disabled={isDisableForm.input}
              value={state.companyAddress}
            />
            <div className="card-sub-title">Emails to send copy and use as Reply Email</div>
            <div className="flex-email-container">
              <div className="emails-container">
                {state.companyEmails.map((companyEmail, index) => (
                  <TextField
                    key={'co' + (index + 1)}
                    InputLabelProps={{ shrink: true }}
                    label="Email"
                    name={'companyEmail' + (index + 1)}
                    disabled={isDisableForm.input}
                    onChange={(element) => {
                      let emails = state.companyEmails;
                      emails[index] = element.target.value;
                      setState((values) => ({
                        ...values,
                        companyEmails: emails,
                      }));
                    }}
                    value={state.companyEmails[index]}
                  />
                ))}
                {!doActions && (
                  <TextField InputLabelProps={{ shrink: true }} label="Email" name={'companyEmail'} disabled={true} />
                )}
              </div>
              <div className="emails-add">
                <IconButton
                  color="primary"
                  aria-label="add email"
                  onClick={addCompanyEmail}
                  disabled={isDisableForm.input}
                >
                  <AddCircleOutlinedIcon />
                </IconButton>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="card card-customer">
          <CardContent>
            <div className="card-sub-title">Customer Address</div>

            <TextField
              InputLabelProps={{ shrink: true }}
              multiline
              rows={4}
              name="customerAddress"
              onChange={handleChange}
              disabled={isDisableForm.input}
              value={state.customerAddress}
            />
            <div className="card-sub-title">Customer Emails to send Invoice</div>
            <div className="flex-email-container">
              <div className="emails-container">
                {state.customerEmails.map((customerEmail, index) => (
                  <TextField
                    key={'cu' + (index + 1)}
                    InputLabelProps={{ shrink: true }}
                    label="Email"
                    name={'customerEmail' + (index + 1)}
                    disabled={isDisableForm.input}
                    onChange={(element) => {
                      let emails = state.customerEmails;
                      emails[index] = element.target.value;
                      setState((values) => ({
                        ...values,
                        customerEmails: emails,
                      }));
                    }}
                    value={state.customerEmails[index]}
                  />
                ))}
                {!doActions && (
                  <TextField InputLabelProps={{ shrink: true }} label="Email" name={'customerEmail'} disabled={true} />
                )}
              </div>
              <div className="emails-add">
                <IconButton
                  color="primary"
                  aria-label="add email"
                  onClick={addCustomerEmail}
                  disabled={isDisableForm.input}
                >
                  <AddCircleOutlinedIcon />
                </IconButton>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card className="card">
        <CardContent>
          <div className="card-sub-title">Invoice Body</div>
          <div style={{ flexGrow: 1 }}>
            <EditableTable
              addNew
              edit={!isDisableForm.input}
              columns={invoiceCols}
              rows={state.items}
              emitNewRows={(val) => handleEditTableUpdate(val)}
            />
            <div style={{ display: 'flex' }}>
              <div className="terms-container">
                <div>
                  <TextField
                    className="terms"
                    InputLabelProps={{ shrink: true }}
                    label="Terms"
                    name="terms"
                    onChange={handleChange}
                    disabled={isDisableForm.input}
                    value={state.terms}
                  />
                </div>
              </div>
              <div className="totals-container">
                <div>
                  <NumberFormat
                    className="totals"
                    InputLabelProps={{ shrink: true }}
                    label="Subtotal"
                    name="subtotal"
                    disabled={isDisableForm.input}
                    value={state.subtotal}
                    prefix="$"
                    decimalSeparator="."
                    customInput={TextField}
                    thousandSeparator={true}
                    allowNegative={true}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </div>
                <div>
                  <NumberFormat
                    className="totals"
                    InputLabelProps={{ shrink: true }}
                    label="Other Charges"
                    name="otherCharges"
                    disabled={isDisableForm.input}
                    onValueChange={(val) => {
                      const total = Number(state.subtotal || 0) + Number(val.value || 0);

                      setState((values) => ({
                        ...values,
                        otherCharges: Number(val.value || 0),
                        total: total,
                      }));
                    }}
                    value={state.otherCharges}
                    prefix="$"
                    decimalSeparator="."
                    customInput={TextField}
                    type="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </div>
                <div>
                  <NumberFormat
                    className="totals"
                    InputLabelProps={{ shrink: true }}
                    label="Total"
                    name="total"
                    disabled={isDisableForm.input}
                    value={state.total}
                    prefix="$"
                    decimalSeparator="."
                    customInput={TextField}
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="card">
        <CardContent>
          <div className="flex-container">
            <div className="invoice-comments">
              <div className="card-sub-title">Invoice Comments</div>
              <TextField
                InputLabelProps={{ shrink: true }}
                multiline
                rows={6}
                name="invoiceComments"
                onChange={handleChange}
                disabled={isDisableForm.input}
                value={state.invoiceComments}
              />
            </div>
            <div className="borrower-info">
              <div className="card-sub-title">Borrower Information</div>
              <TextField
                InputLabelProps={{ shrink: true }}
                multiline
                rows={6}
                name="borrowerInfo"
                onChange={handleChange}
                disabled={isDisableForm.input}
                value={state.borrowerInfo}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </InvoiceCss>
  );
};
