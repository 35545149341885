import React from 'react';
import { useSelector } from 'react-redux';
import { userService } from '../../../services';

function TimeCountDown(props) {
  const { setTimeCountDown, timeInSecondsLef } = props;

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  const [minutes, setMinutes] = React.useState();
  const [seconds, setSeconds] = React.useState();

  const { token } = useSelector((state) => state.reducer.accessToken);

  React.useEffect(() => {
    if (token) {
      setSeconds(secondsToTime(userService.tokenTimeRemaining()).s);
      setMinutes(secondsToTime(userService.tokenTimeRemaining()).m);
    } else {
      setSeconds(secondsToTime(timeInSecondsLef).s);
      setMinutes(secondsToTime(timeInSecondsLef).m);
    }
    return () => {};
  }, [token]);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          setTimeCountDown('timerIsDone');
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      <b>
        {minutes === 0 && seconds === 0 ? (
          'Sining out...'
        ) : (
          <span>
            {' '}
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        )}
      </b>
    </div>
  );
}

export default TimeCountDown;
