import { store } from '../store';

/// By default this function will show mark the alert by "replaceable" which means the next alert will close this current one,
const dispatchAlert = (message, hasError = false, withRedirect = undefined, keepOpen = false) => {
  message = keepOpen ? message.replace('[Replace]', '') : message;

  store.dispatch({
    type: 'PUSH_ALERT',
    payload: {
      id: _.uniqueId(),
      keepOpen: keepOpen,
      open: true,
      color: !hasError ? 'success' : 'error',
      message: message,
      withRedirect: withRedirect,
    },
  });
};

const dispatchWarningAlert = (message, keepOpen = false) => {
  message = keepOpen ? message.replace('[Replace]', '') : message;

  store.dispatch({
    type: 'PUSH_ALERT',
    payload: {
      id: _.uniqueId(),
      keepOpen: keepOpen,
      open: true,
      color: 'warning',
      message: message,
    },
  });
};

const dispatchRelativeAlert = (message, hasError = false, withRedirect = undefined, keepOpen = false) => {
  message = keepOpen ? message.replace('[Replace]', '') : message;

  store.dispatch({
    type: 'PUSH_ALERT',
    payload: {
      id: _.uniqueId(),
      isRelative: true,
      keepOpen: keepOpen,
      open: true,
      color: !hasError ? 'success' : 'error',
      message: message,
      withRedirect: withRedirect,
    },
  });
};

// Use for one specific intance. FileUploader
const nsRelativeAlert = (message, msgType = 1, withRedirect = undefined, keepOpen = false) => {
  message = keepOpen ? message.replace('[Replace]', '') : message;

  const color = msgType == 1 ? 'success' : msgType == 2 ? 'warning' : 'error';

  store.dispatch({
    type: 'PUSH_ALERT',
    payload: {
      id: _.uniqueId(),
      isRelative: true,
      keepOpen: keepOpen,
      open: true,
      color: color,
      message: message,
      withRedirect: withRedirect,
    },
  });
};

/// Keep Open = true: this function will keep the alert always visible until user close it manually
const dispatchAlertKeepOpen = (message, hasError = false, withRedirect = undefined) => {
  dispatchAlert(message, hasError, withRedirect, true);
};

const dispatchAlertDialogKeepOpen = (message, hasError = false, withRedirect = undefined) => {
  dispatchRelativeAlert(message, hasError, withRedirect, true);
};

const parseFeeTypesForArrayLookup = (
  feeTypes, // this could be any array that come from you api
  arrayToMap, // this are columns
  propInPlay, // this string should match with the column field
  apiFieldToMap // this string should match with property name in you api response
) => {
  let newArray = [];
  feeTypes.map((item, index) => {
    let newObj = {};
    newObj['id'] = item.id || index + 1; // id must exits in the api response in case you need to relay on it
    newObj['label'] = item[propInPlay] || item[apiFieldToMap];
    arrayToMap.map((col) => {
      switch (col.type) {
        case 'number':
          newObj[col.field] = parseInt(item[col.field]);
          break;
        case 'boolean':
          newObj[col.field] = Boolean(item[col.field]);
          break;
        default:
          newObj[col.field] = item[col.field];
          break;
      }
    });
    newArray.push(newObj);
  });

  // assign new array with new body
  const newList = arrayToMap.map((col) => {
    if (col.field === propInPlay) {
      return { ...col, lookups: newArray };
    }
    return col;
  });

  return newList;
};

const getUrlEncryptedParameters = (name = false, decode = true) => {
  const queryParams = location.search
    .substr(1)
    .split('&')
    .reduce((obj, keyVal) => {
      const [key, value] = keyVal.split('=');
      obj[key] = decode ? decodeURIComponent(value.replace(/\+/g, ' ')) : value;
      return obj;
    }, {});

  return name ? queryParams[name] : queryParams;
};

const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&');
};

const compareVersions = (version1, version2) => {
  // Split the version strings into arrays of numbers
  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  // Determine the maximum length of the two version arrays
  const maxLength = Math.max(v1.length, v2.length);

  // Compare each part of the versions
  for (let i = 0; i < maxLength; i++) {
    // Get the current part of each version, defaulting to 0 if the part is undefined
    const num1 = v1[i] || 0;
    const num2 = v2[i] || 0;

    // Compare the current parts
    if (num1 > num2) {
      return 1; // version1 is greater
    }
    if (num1 < num2) {
      return -1; // version2 is greater
    }
  }

  return 0; // versions are equal
};

const isServiceOwner = () => {
  const userRoles = store.getState().reducer.currentUser.userRoles;
  return userRoles.includes('SuperUser') || userRoles.includes('ServiceOwner');
};

export {
  isServiceOwner,
  dispatchAlert,
  dispatchWarningAlert,
  dispatchRelativeAlert,
  dispatchAlertKeepOpen,
  dispatchAlertDialogKeepOpen,
  parseFeeTypesForArrayLookup,
  getUrlEncryptedParameters,
  nsRelativeAlert,
  objectToQueryString,
  compareVersions,
};
