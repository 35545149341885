import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { UserAdminService as service } from '../../services';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UserAdminContext } from '../../context/user-admin.context';
import { dispatchAlert, dispatchRelativeAlert } from '../../helpers';
import AppDialog from '../common/others/AppDialog';

function formatDate(string) {
  var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Date(string).toLocaleDateString([], options);
}

const DialogDiv = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
  `
);

function UserDialog({ ...props }) {
  const { open = false, onClose } = props;
  const context = useContext(UserAdminContext);
  const {
    currUser,
    setCurrUser,
    pageMode,
    isNewUser,
    setIsNewUser,
    initialUser,
    getUserAdminMembers,
  } = context;
  const [showPassword, setShowPassword] = useState(false);
  const [firstNameHint, setFirstNameHint] = useState(false);
  const [lastNameHint, setLastNameHint] = useState(false);
  const [userNameHint, setUserNameHint] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    return () => {
      setCurrUser(initialUser);
      setIsNewUser(false);
      setFirstNameHint(false);
      setLastNameHint(false);
      setUserNameHint(false);
      setPasswordHint(false);
    };
  }, []);

  useEffect(() => {
    parseError(isError);
    return () => {
      setFirstNameHint(false);
      setLastNameHint(false);
      setUserNameHint(false);
      setPasswordHint(false);
    };
  }, [isError]);

  const addOrUpdateUserAdminMember = (currUser) => {
    service.addOrUpadateUserAdminMember(isNewUser, currUser).then(
      (res) => {
        setIsError(false);
        getUserAdminMembers();
        onClose(false);
        dispatchAlert(
          `[Replace]Successfully Added ${currUser.firstName} ${currUser.lastName}`
        );
      },
      (error) => {
        dispatchRelativeAlert(error, true);
        setIsError(error);
        onClose(true);
      }
    );
  };

  const parseError = (errorMsg) => {
    if (errorMsg) {
      const splitArray = errorMsg.split('.');
      for (let i = 0; i < splitArray.length; i++) {
        if (splitArray[i].includes('First Name')) {
          setFirstNameHint(true);
        } else if (splitArray[i].includes('Last Name')) {
          setLastNameHint(true);
        } else if (splitArray[i].includes('Username')) {
          setUserNameHint(true);
        } else {
          setPasswordHint(true);
        }
      }
    }
  };

  const handleClose = (e) => {
    let btnEvent = e.target.firstChild.data;
    switch (btnEvent) {
      case 'Cancel':
        setIsError(false);
        onClose(false);
        return;
      case 'Update':
        addOrUpdateUserAdminMember(currUser);
        return;
      case 'Delete':
        deleteUserAdminMember(currUser.userID);
        onClose(false);
        return;
      case 'Create User':
        addOrUpdateUserAdminMember(currUser);
        return;
      default:
    }
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AppDialog
      open={open}
      maxWidth="md"
      mainTitle={
        pageMode === 'edit'
          ? `Update ${currUser.firstName} ${currUser.lastName}`
          : 'Create new user'
      }
    >
      {/* <form action=""> */}
      <DialogContent dividers>
        <DialogDiv>
          <TextField
            required
            error={firstNameHint}
            label="First Name"
            value={currUser.firstName}
            helperText={firstNameHint ? 'First Name is required' : ''}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                firstName: e.target.value,
              })
            }
          />
          <TextField
            required
            error={lastNameHint ? true : false}
            label="Last Name"
            value={currUser.lastName}
            helperText={lastNameHint ? 'Last Name is required' : ''}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                lastName: e.target.value,
              })
            }
          />
        </DialogDiv>
        <DialogDiv>
          <TextField
            label="Username"
            error={userNameHint}
            value={currUser.userName}
            helperText={userNameHint ? 'Username is required' : ''}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                userName: e.target.value,
              })
            }
          />
          <TextField
            label="Password"
            error={passwordHint}
            minLength="8"
            type={showPassword ? 'text' : 'password'}
            helperText={
              passwordHint
                ? 'Password must have at least 8 characters,1 uppercase,1 lowercase,1 number, special characters allowed: *&#@!$%'
                : ''
            }
            value={currUser.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                password: e.target.value,
              })
            }
          />
        </DialogDiv>
        <DialogDiv>
          <TextField
            label="Email"
            type="email"
            value={currUser.email}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                email: e.target.value,
              })
            }
          />
          <TextField
            label="Phone"
            type="tel"
            value={currUser.telephone}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                telephone: e.target.value,
              })
            }
          />
        </DialogDiv>
        {/* {pageMode == 'edit' && (
          <DialogDiv>
            <TextField
              disabled
              label="Last Login"
              value={formatDate(currUser.lastLoginDate)}
              onChange={(e) =>
                setCurrUser({
                  ...currUser,
                  lastLoginDate: e.target.value,
                })
              }
            />
          </DialogDiv>
        )} */}
        <DialogDiv>
          <TextField
            sx={{ width: '20%' }}
            label="Initials"
            value={currUser.initials}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                initials: e.target.value,
              })
            }
          />
          <TextField
            sx={{ width: '20%' }}
            label="Order"
            value={String(currUser.order)}
            onChange={(e) =>
              setCurrUser({
                ...currUser,
                order: parseInt(e.target.value) || '',
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currUser.authorized}
                onChange={(e) =>
                  setCurrUser({
                    ...currUser,
                    authorized: e.target.checked,
                  })
                }
              />
            }
            label="Authorized"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currUser.staff}
                onChange={(e) =>
                  setCurrUser({
                    ...currUser,
                    staff: e.target.checked,
                  })
                }
              />
            }
            label="Staff"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currUser.admin}
                onChange={(e) =>
                  setCurrUser({
                    ...currUser,
                    admin: e.target.checked,
                  })
                }
              />
            }
            label="Admin"
          />
        </DialogDiv>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="default" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          style={{ color: 'white' }}
          variant="contained"
          onClick={handleClose}
        >
          {pageMode === 'edit' ? 'Update' : 'Create User'}
        </Button>
      </DialogActions>
      {/* </form> */}
    </AppDialog>
  );
}

export default UserDialog;
