import { css } from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import styled from 'styled-components';
import { Card, DialogContent } from '@mui/material';
import { appColors } from '../../../theme/AppThemes';

export const UploadFilesCss = styledMui('div')(
  ({ theme, $noGragDrop }) => css`
    .notes-instructions {
      margin-bottom: 15px;
      display: flex;
      gap: 10px;

      > div:nth-child(1) {
        flex: 1;
      }

      textarea {
        width: 100% !important;
        max-width: 100%;
        min-width: 100%;
      }

      @media screen and (max-width: 600px) {
        margin-bottom: 5px;
      }
    }

    .tab-panel {
      padding: 10px !important;
    }

    .dropzone {
      position: relative;
      ${theme.breakpoints.up('sm')} {
        min-height: 100px;
      }
      text-align: center;
      border-width: 2px;
      border-radius: 5px;
      cursor: default;
      border-style: dashed;
      outline: none;
      transition: border 0.24s ease-in-out;
    }

    .dropzone.no-border {
      border: none;
    }

    .tab-container {
      .MuiTabs-root {
        & .MuiTab-root {
          text-transform: none;
        }

        .new-files-tab {
          display: flex;
          align-items: inherit;

          img {
            display: ${$noGragDrop ? 'none' : 'block'};
            height: 40px;
            position: absolute;
          }

          span {
            margin-left: ${$noGragDrop ? '0px' : '50px'};
          }
        }
      }
    }
  `
);

export const StyledSavedFiles = styledMui('div')(
  ({ theme, $priColor, $scanback }) => css`
    .MuiCard-root {
      padding: 10px;
      background-color: ${theme.palette.mode == 'light' ? '#fff4df' : '#695837'};
    }

    .table-header,
    .table-body {
      display: flex;
      gap: 5px;
      align-items: center;
      line-height: 100%;

      .link {
        overflow-wrap: anywhere;
        text-align: left;
      }
    }

    .table-header {
      font-weight: 500;
      margin-bottom: 5px;
      padding: 5px;
      border-bottom: 1px solid;
      border-color: ${theme.palette.mode == 'light' ? 'rgba(224, 224, 224, 1)' : 'inherit'};
      color: ${theme.palette.mode == 'dark' && '#e9e9e9'};
    }

    .table-body .MuiLink-root {
      color: ${theme.palette.mode == 'dark' && appColors[$priColor].palette.primary3.main};
    }

    .table-body:not(:last-of-type) {
      border-bottom: 1px solid;
      border-color: ${theme.palette.mode == 'light' ? 'rgba(224, 224, 224, 1)' : 'inherit'};
    }

    .table-body .col:last-of-type {
      text-align: right;
    }

    .MuiCheckbox-root {
      color: ${theme.palette.mode === 'dark' && 'white'};
    }
  `
);

export const StyledSelectedFiles = styledMui('div')(
  ({ theme }) => css`
    .table-header {
      display: flex;
      text-align: justify;
      align-items: center;
      line-height: 100%;
      font-weight: 500;
      margin-bottom: 5px;
      padding: 10px 5px;
      border-bottom: 1px solid;
      border-color: ${theme.palette.mode == 'light' ? 'rgba(224, 224, 224, 1)' : 'inherit'};
    }

    .table-body {
      .row {
        display: flex;
        gap: 5px;
        align-items: center;
        text-align: justify;
        align-items: center;
        line-height: 100%;
        border-bottom: 1px solid;
        border-color: ${theme.palette.mode == 'light' ? 'rgba(224, 224, 224, 1)' : 'inherit'};

        .col .progress-name {
          display: flex;
          align-items: center;
          padding-left: 5px;
          overflow-wrap: anywhere;
          gap: 5px;
        }
      }
    }

    .table-body .col:last-of-type {
      text-align: right;
    }
  `
);

export const ItemMobileCss = styledMui(Card)(
  ({ theme, $scanbacks }) => css`
    position: relative;
    text-align: left;
    padding: 10px 10px ${$scanbacks ? '10px' : '0px'} !important;
    margin-top: 10px;

    .link {
      text-decoration: none;
      cursor: pointer;
      text-align: left;
      top: -5px;
    }

    .item-columns-top.selected.input-description {
      margin-top: 0px;

      label + .MuiInput-root {
        margin-top: 20px;
      }
    }

    .item-columns-top.saved .input-description {
      margin-top: 5px;
    }

    .progress-name {
      display: flex;
      align-items: center;
    }

    .text-padding-left {
      padding-left: 10px;
    }

    .item-columns-top {
    }

    .item-columns-bottom {
      display: inline-flex;

      > div {
        display: flex;

        hr {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .item-columns {
      display: flex;
      flex-direction: row no-wrap;
      justify-content: space-between;
      line-height: 20px !important;
      align-items: start;
    }

    span {
      font-size: 0.75rem;
      margin-top: -1px;
    }

    input[type='text'] {
      height: 15px;
    }

    .checkboxes {
      margin: 0;
    }

    .progressWithLabel {
      display: flex;
      position: relative;
      right: 5px;
      top: -2px;
      .MuiCircularProgress-root {
        width: 30px !important;
        height: 30px !important;
        place-self: center;
      }
      .MuiTypography-caption {
        font-size: 0.6rem;
      }
    }
  `
);

export const StyledExternalFiles = styledMui('div')(
  ({ theme }) => css`
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;

    .MuiCard-root {
      padding: 5px !important;
    }

    .table-header,
    .table-body {
      display: flex;
      gap: 5px;
      align-items: center;
      line-height: 100%;
    }

    .table-header {
      font-weight: 500;
      margin-bottom: 5px;
      padding: 5px;
      border-bottom: 1px solid;
      border-color: ${theme.palette.mode == 'light' ? 'rgba(224, 224, 224, 1)' : 'inherit'};
    }

    .table-body:not(:last-of-type) {
      border-bottom: 1px solid;
      border-color: ${theme.palette.mode == 'light' ? 'rgba(224, 224, 224, 1)' : 'inherit'};
    }

    .table-body .col:last-of-type {
      text-align: right;
    }

    .icon-color {
      color: ${theme.palette.mode === 'dark' && '#c7c4c4'};
    }

    .check-error {
      border: 1px solid #e53935;
      border-radius: 4px;
    }
  `
);

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#bbbbbb';
};

export const StyledDropzoneContainer = styled.div`
  border-color: ${(props) => getColor(props)};
  outline: none;
  transition: border 0.24s ease-in-out;

  .no-files {
    padding: 5px;

    .MuiSvgIcon-root {
      font-size: 50px;
      top: 5px;
      position: relative;
    }

    p {
      font-size: 20px;
    }

    .MuiDivider-root {
      width: 20%;
      margin: auto;
    }

    .btn-browse {
      width: 25%;
      position: relative;
      margin-bottom: 10px;
    }
  }

  .btn-browse.mobile,
  .btn-browse.tablet,
  .btn-browse.with-files {
    margin: 5px;
    margin-top: 20px;
    width: Calc(100% - 10px);
    line-height: 3;
  }
`;

export const DialogContentCss = styledMui(DialogContent)(
  ({ theme }) => css`
    padding: 16px;

    @media screen and (max-width: 600px) {
      padding: 5px 10px;
    }
  `
);
