import React from 'react';
import { Pagination, MenuItem, Select, FormControl, Typography, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Div } from '../../../Style';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    borderRadius: '5px',
    padding: '0',

    '@media screen and (min-width: 400px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'small',
      fontWeight: 600,
    },

    '@media screen and (max-width: 400px)': {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& .navigation': {
      '@media screen and (min-width: 400px)': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
      },
      '@media screen and (max-width: 400px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '5px',
        textAlign: 'center',
      },
    },

    '& .pageSizeOptions': {
      '@media screen and (min-width: 400px)': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
      },
      '@media screen and (max-width: 400px)': {
        textAlign: 'center',
      },
    },

    '& .pageSizeOptions .MuiSelect-root': {
      fontSize: '0.8125rem',
      textTransform: 'none',
    },

    '& .navigation .MuiButton-root': {
      fontSize: '0.8125rem',
      textTransform: 'none',
    },

    '& .navigation .MuiInputBase-input': {
      paddingTop: '7.5px',
      paddingBottom: '7px',
      fontSize: '0.8125rem',
    },

    '& .button ': {
      color: theme.palette.primary.default,
    },

    '& .text-page': {
      [theme.breakpoints.down('1100')]: {
        display: 'none',
      },
    },
  }),
}));

const CustomPagination = (props) => {
  const classes = useStyles({ props });

  const { pageSizeOptions, pageSize, pageNumber, totalPages, handlePageSize, handlePageNumber } = props;

  return (
    <Div style={{ ...props.style }} className={classes.root}>
      <div className="pageSizeOptions">
        <FormControl>
          <Select size="small" value={pageSize} onChange={handlePageSize} style={{ marginTop: '5px', height: '30px' }}>
            {pageSizeOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          style={{ marginTop: '5px', marginLeft: '5px', minWidth: '100px' }}
          variant="body2"
          className="text-page"
        >
          per page
        </Typography>
      </div>
      <div style={{ marginLeft: '15px' }}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={totalPages}
          page={pageNumber}
          siblingCount={0}
          boundaryCount={1}
          onChange={(e, val) => handlePageNumber(val)}
        />
      </div>
    </Div>
  );
};

export default CustomPagination;
