import React, { useContext, useRef, useState, useEffect, Fragment } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { SigningRequestContext } from '../../context/signing-request.context';
import { manageRequestService as service } from '../../services';
import EmailSection from '../common/fancy-email/EmailSection';
import FileUpload from '../common/others/FileUpload';
import { dispatchAlertKeepOpen, dispatchRelativeAlert, IsValidJSON } from '../../helpers';
import Loading from '../common/others/Loading';
import { store } from '../../store';
import ComponentAlertRelative from '../common/others/ComponentAlertRelative';

const StyledAppointmentDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiSvgIcon-root.rotate {
      transform: rotateZ(-62deg);
    }

    .MuiDialogContent-root {
      .emailContainer {
        @media screen and (max-width: 600px) {
          padding: 10px;
        }

        .emailSection:not(:last-child) {
          margin-bottom: 20px;
        }

        .emailSection {
          .sectionTitle {
            padding: 5px;
            width: 100%;
            text-align: center;
            border-radius: 5px;
            align-items: center;
            position: relative;
            font-weight: bold;

            .emailtitle {
              @media screen and (max-width: 600px) {
                text-align: left;
              }
            }

            > div:nth-child(2) {
              position: absolute;
              top: 4px;
              right: 0;

              .MuiButtonBase-root {
                padding: 0;
                color: #ff7524;
              }

              .MuiTypography-body1 {
                font-size: 13px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .MuiDialogActions-root {
      padding: 8px 20px 8px 20px;
    }
  `
);

function AppointmentDialog({ confirmCallback = () => {}, ...props }) {
  const { title = '?', content = '', open = false } = props;
  let requestID = null;
  let settings = null;
  let signingRequest = null;

  if (props.ignoreDetailContext) {
    requestID = props.request?.requestID;
    settings = props.settings;
    signingRequest = props.request;
  } else {
    const context = useContext(SigningRequestContext);
    requestID = context.requestID;
    settings = context.settings;
    signingRequest = context.signingRequest;
  }

  const alertWrapperRef = useRef(null);
  const viewport = store.getState().reducer.appViewport;

  const [alert, setAlert] = useState(false);
  const [keepDialogOpen, setKeepDialogOpen] = useState(false);
  const [omitCustEmail, setOmitCustEmail] = useState(false);
  const [omitAgentEmail, setOmitAgentEmail] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [mailData, setMailData] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [apiResult, setApiResult] = useState();

  // button group states for sending options
  const [btnOptions, setBtnOptions] = React.useState(['Send']);
  const [openBtnOptions, setOpenBtnOptions] = React.useState(false);
  const btnOptionsAnchorRef = React.useRef(null);
  const [selectedBtnOptionIndex, setSelectedBtnOptionIndex] = React.useState(0);

  useEffect(() => {
    const getRequestAppointmentData = async () => {
      await service
        .getRequestAppointmentData(
          content.type,
          signingRequest.userID,
          signingRequest.assignedAgentID,
          signingRequest.requestID,
          settings.pnl_BillingInfo_Visible,
          settings.pnl_AlternateLocation_Visible
        )
        .then(
          (res) => {
            setMailData(res);
            setKeepDialogOpen(res.keepConfirmationEmailDialogOpen);
          },
          (error) => {
            confirmCallback();
            dispatchAlertKeepOpen(error, true);
          }
        );
    };

    if (content.type) getRequestAppointmentData();

    return () => {
      setMailData();
      setKeepDialogOpen(false);
    };
  }, []);

  useEffect(() => {
    if (content.moreOnSend?.length > 0) {
      setBtnOptions((prev) => [...prev, ...content.moreOnSend]);
    }

    return () => {};
  }, [content.moreOnSend]);

  const handleSetMailData = (section, data) => {
    if (section == 'customer') setMailData({ ...mailData, customer: data });
    if (section == 'agent') setMailData({ ...mailData, agent: data });
    if (section == 'agentFiles') {
      setMailData({
        ...mailData,
        agent: { ...mailData.agent, attachemnts: data },
      });
      setSelectedFile(data);
    }
  };

  const handleSend = async (event, withRedirect) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('data', JSON.stringify(mailData));

    for (var i = 0; i < selectedFile.length; i++) {
      formData.append('files', selectedFile[i]);
    }

    if (mailData.agent?.attachemnts?.length > 0) {
      const docIDs = mailData.agent.attachemnts
        .filter((item) => item['docID'] > 0)
        .map(function (item) {
          return item['docID'];
        });
      formData.append('docIDs', JSON.stringify(docIDs));
    } else formData.append('docIDs', JSON.stringify([]));

    let success = false;
    const sendEmail = async () => {
      setSendingEmail(true);
      return service
        .notifyRequestApptEmail(requestID, content.type, formData, omitCustEmail, omitAgentEmail, signingRequest.userID)
        .then(
          (response) => {
            success = true;
            setSendingEmail(false);
            if (!keepDialogOpen) confirmCallback(response, withRedirect);
            else parseResponseWhenKeepOpen(response);
          },
          (error) => {
            success = false;
            setSendingEmail(false);
            if (IsValidJSON(JSON.stringify(error))) {
              setApiResult(error);
              setAlert(true);
              setTimeout(() => {
                setAlert(false);
              }, 5000);
            } else {
              if (!keepDialogOpen) confirmCallback(null, withRedirect);
            }
          }
        );
    };

    await sendEmail();
    return success;
  };

  const parseResponseWhenKeepOpen = (response) => {
    if (response) {
      let custResp = response?.Customer;
      let agentResp = response?.Agent;

      let errors = [];
      let succsessMsgs = [];

      if (custResp?.includes('error') || agentResp?.includes('error')) {
        if (custResp?.includes('error')) errors.push(custResp.replace('[error]', ''));
        if (agentResp?.includes('error')) errors.push(agentResp.replace('[error]', ''));
      }

      if (custResp?.includes('success') || agentResp?.includes('success')) {
        if (custResp?.includes('success')) succsessMsgs.push(custResp.replace('[success]', ''));
        if (agentResp?.includes('success')) succsessMsgs.push(agentResp.replace('[success]', ''));
      }

      if (errors.length > 0) {
        errors.forEach((error) => dispatchRelativeAlert(error, true));
      } else {
        succsessMsgs.forEach((msg) => dispatchRelativeAlert(msg));
      }
    }
  };

  const parseResponse = (section) => {
    let jsonRes = apiResult ? apiResult : '';

    if (jsonRes) {
      let responseBySection = {
        toEmails: section === 'cust' ? jsonRes.Errors?.cust_toEmails : jsonRes.Errors?.agent_toEmails,
        ccEmails: section === 'cust' ? jsonRes.Errors?.cust_ccEmails : jsonRes.Errors?.agent_ccEmails,
        bccEmails: section === 'cust' ? jsonRes.Errors?.cust_bccEmails : jsonRes.Errors?.agent_bccEmails,
        fromEmail: section === 'cust' ? jsonRes.Errors?.cust_fromEmail : jsonRes.Errors?.agent_fromEmail,
        subject: section === 'cust' ? jsonRes.Errors?.cust_subject : jsonRes.Errors?.agent_subject,
        body: section === 'cust' ? jsonRes.Errors?.cust_body : jsonRes.Errors?.agent_body,
      };
      return responseBySection;
    } else {
      return { Message: apiResult };
    }
  };

  const handleBtnOptionClick = async (event) => {
    switch (btnOptions[selectedBtnOptionIndex]) {
      case 'Send':
        handleSend(event);
        break;
      case 'Send and Invoice':
        await handleSend(event, `/manage-request/invoice/customer/${requestID}`);
        break;
      default:
        break;
    }
  };

  const handleBtnOptionMenuItemClick = (event, index) => {
    setSelectedBtnOptionIndex(index);
    setOpenBtnOptions(false);
  };

  const handleBtnOptionsToggle = () => {
    setOpenBtnOptions((prevOpen) => !prevOpen);
  };

  const handleBtnOptionClose = (event) => {
    if (btnOptionsAnchorRef.current && btnOptionsAnchorRef.current.contains(event.target)) {
      return;
    }

    setOpenBtnOptions(false);
  };

  const splitButton = (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={btnOptionsAnchorRef} aria-label="Button group with a nested menu">
        <Button onClick={handleBtnOptionClick}>
          {sendingEmail ? (
            <>
              {btnOptions[selectedBtnOptionIndex]} <Loading forButton lpad />
            </>
          ) : (
            <>{btnOptions[selectedBtnOptionIndex]}</>
          )}
        </Button>
        <Button
          size="small"
          aria-controls={openBtnOptions ? 'split-button-menu' : undefined}
          aria-expanded={openBtnOptions ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleBtnOptionsToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={openBtnOptions}
        anchorEl={btnOptionsAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleBtnOptionClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {btnOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedBtnOptionIndex}
                      onClick={(event) => handleBtnOptionMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );

  return (
    <>
      <StyledAppointmentDialog
        aria-labelledby="appointment-dialog"
        fullWidth
        disableEscapeKeyDown
        maxWidth="md"
        open={open}
        fullScreen={['xs'].includes(viewport)}
      >
        {mailData ? (
          <Fragment>
            <DialogTitle id="appointment-dialog">
              {mailData.actionTitle ? content.icon : ''}
              {mailData.actionTitle} <br />
              <p style={{ fontSize: 'small' }}>{mailData.actionSendNote}</p>
            </DialogTitle>

            <div id="alert-relative" ref={alertWrapperRef}>
              <ComponentAlertRelative wrapperRef={alertWrapperRef} />
            </div>

            {apiResult && (
              <Box sx={{ width: '100%' }}>
                <Collapse in={alert}>
                  <Alert
                    variant="filled"
                    severity="error"
                    action={
                      <IconButton color="inherit" size="small" onClick={() => setAlert(false)}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ borderRadius: 0 }}
                  >
                    {apiResult.Message}
                  </Alert>
                </Collapse>
              </Box>
            )}
            <DialogContent dividers>
              <div className={'emailContainer'}>
                {!['NotaryNote'].includes(content.type) && (
                  <div className={'emailSection'}>
                    <div className={'sectionTitle'}>
                      <div className="emailTitle">Send Email to Customer</div>
                      <div>
                        {['Confirm', 'Cancel', 'Complete'].includes(content.type) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={omitCustEmail}
                                onChange={(e) => setOmitCustEmail(e.target.checked)}
                                size="small"
                              />
                            }
                            label="Omit Email"
                          />
                        )}
                      </div>
                    </div>
                    <EmailSection
                      showFrom
                      toolbarId={'custToolbar'}
                      editorHeight={['Confirm'].includes(content.type) ? '250px' : ''}
                      validations={apiResult ? parseResponse('cust') : ''}
                      dataSection={mailData.customer}
                      setDataSection={(e) => handleSetMailData('customer', e)}
                    />
                  </div>
                )}
                {!['Changes', 'Decline'].includes(content.type) && (
                  <div className={'emailSection'}>
                    <div className={'sectionTitle'}>
                      <div className="emailTitle">Send Email to Agent</div>
                      <div>
                        {['Confirm', 'Cancel', 'Complete'].includes(content.type) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={omitAgentEmail}
                                onChange={(e) => setOmitAgentEmail(e.target.checked)}
                                size="small"
                              />
                            }
                            label="Omit Email"
                          />
                        )}
                      </div>
                    </div>
                    <EmailSection
                      showFrom
                      toolbarId={'agentToolbar'}
                      editorHeight={['Confirm'].includes(content.type) ? '500px' : ''}
                      validations={apiResult ? parseResponse('agent') : ''}
                      dataSection={mailData.agent}
                      setDataSection={(e) => handleSetMailData('agent', e)}
                    />
                    <br />
                    <FileUpload
                      files={mailData.agent.attachemnts}
                      handleAttachments={(files) => handleSetMailData('agentFiles', files)}
                      customerID={signingRequest.userID}
                    />
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="default" variant="text" autoFocus onClick={() => confirmCallback()}>
                Cancel
              </Button>
              {content.moreOnSend?.length > 0 ? (
                <>{splitButton}</>
              ) : (
                <Button
                  autoFocus
                  disabled={(omitCustEmail && omitAgentEmail) || sendingEmail}
                  variant="contained"
                  onClick={(e) => handleSend(e)}
                >
                  {sendingEmail ? (
                    <>
                      Send <Loading forButton lpad />
                    </>
                  ) : (
                    'Send'
                  )}
                </Button>
              )}
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle id="appointment-dialog">{content.type}</DialogTitle>
            <DialogContent style={{ padding: '20px' }}>Getting email information...</DialogContent>
          </Fragment>
        )}
      </StyledAppointmentDialog>
    </>
  );
}

AppointmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default AppointmentDialog;
