import { create } from 'zustand/index';
import { devtools } from 'zustand/middleware';

const initialState = {
  isDirty: false,
  syncDisabled: false,
};

const devToolsConfig = {
  name: 'DirtyChangesStore',
  enabled: true,
  store: 'DirtyChangesStore',
};

export const useDirtyChangesStore = create(
  devtools(
    (set, get) => ({
      ...initialState,
      setDirtyState: (v) => {
        set((s) => {
          if (s.syncDisabled) {
            return s;
          }

          return { ...s, isDirty: v };
        });
      },
      disableDirtyChangesSync: () => set((s) => ({ ...s, syncDisabled: true })),
      enableDirtyChangesSync: () => set((s) => ({ ...s, syncDisabled: false })),
      reset: () => set((state) => ({ ...initialState })),
    }),
    devToolsConfig
  )
);
