import {
  Button,
  ButtonGroup,
  Card,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { InvoicePaymentsCss } from './style';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { AgentPaymentMgtContext } from '../../../context/agent-payment-mgt.context';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import { dispatchAlert } from '../../../helpers';
import Loading from '../../common/others/Loading';
import { HtmlTooltip } from '../../../Style';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { Fragment } from 'react';

export const AgentPaymentMgtActions = () => {
  const context = useContext(AgentPaymentMgtContext);
  const {
    agentPayments,
    agentPaymentsInBatch,
    createNewBatch,
    undoLastBatch,
    postApproved,
    comparePayments,
    isCreating,
    undoCurrentBatch,
    getAgentPaymentReport,
    filterPayLoad,
  } = context;

  const [listStylerAnchorEl, setListStylerAnchorEl] = useState(null);
  const listStyleMenuOpen = Boolean(listStylerAnchorEl);

  const [openConfirmation, setOpenConfirmation] = useState({});

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.down('sm'));

  const doPostApproved = () => {
    if (!agentPayments.batchId)
      dispatchAlert('The payment batch could not be undone. Please email tech support.', true);
    else if (!agentPayments.wasExported) {
      setOpenConfirmation((values) => ({
        msg: (
          <Fragment>
            A report must be seen before posting a batch. <br />
            <ul style={{ marginTop: '10px' }}>
              <li type="disc" style={{ marginTop: '5px' }}>
                <Link onClick={() => getPdfReport('pdf')} className="link">
                  Summary Rpt
                </Link>
              </li>
              <li type="disc" style={{ marginTop: '5px' }}>
                <Link onClick={() => getPdfReport('pdf', true)} className="link">
                  By Notary Rpt
                </Link>
              </li>
              <li type="disc" style={{ marginTop: '5px' }}>
                <Link onClick={() => getPdfReport('csv')} className="link">
                  Download CSV
                </Link>
              </li>
            </ul>
          </Fragment>
        ),
        contentHtml: true,
        open: true,
        onlyButtonClose: true,
        handler: (val) => postApprovedHanler(val),
      }));
    } else
      setOpenConfirmation((values) => ({
        msg: 'Are you sure you want to post the approved items?',
        open: true,
        handler: (val) => postApprovedHanler(val),
      }));
  };

  const postApprovedHanler = (value) => {
    if (value) {
      if (comparePayments()) postApproved();
      else
        dispatchAlert(
          'You made changes to the payment approvals since you created the batch, please re-create the batch.',
          true
        );
    }
    setOpenConfirmation({
      msg: '',
      open: false,
      handler: null,
    });
  };

  const undoLastPost = () => {
    setListStylerAnchorEl(null);
    if (!agentPayments.lastBatchId)
      dispatchAlert('The payment batch could not be undone. Please email tech support.', true);
    else {
      const date = agentPayments.lastBatchDate ? `on ${moment(agentPayments.lastBatchDate).format('MM/DD/YYYY')}` : '';
      setOpenConfirmation((values) => ({
        msg: `Are you sure you want to undo the last payment batch from ${date}?`,
        open: true,
        handler: (val) => undoLastBatchHanler(val),
      }));
    }
  };

  const undoLastBatchHanler = (value) => {
    if (value) undoLastBatch();
    setOpenConfirmation({
      msg: '',
      open: false,
      handler: null,
    });
  };

  const undoActiveBatchHanler = (value) => {
    if (value) undoCurrentBatch();
    setOpenConfirmation({
      msg: '',
      open: false,
      handler: null,
    });
  };

  const undoActiveBatch = () => {
    setListStylerAnchorEl(null);
    if (!agentPayments.batchId)
      dispatchAlert('The current payment batch could not be undone. Please email tech support.', true);
    else
      setOpenConfirmation((values) => ({
        msg: `Are you sure you want to remove the current payment batch?`,
        open: true,
        handler: (val) => undoActiveBatchHanler(val),
      }));
  };

  const getPdfReport = (format, byAgent = false) => {
    setOpen(false);
    getAgentPaymentReport(format, byAgent);
    setOpenConfirmation({
      msg: '',
      open: false,
      handler: null,
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <InvoicePaymentsCss>
      {openConfirmation.open && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={openConfirmation.open}
          title={openConfirmation.onlyButtonClose ? 'Report Download' : 'Confirmation'}
          content={openConfirmation.msg}
          contentHtml={openConfirmation.contentHtml}
          onlyButtonClose={openConfirmation.onlyButtonClose}
          buttonFalseText={openConfirmation.onlyButtonClose}
          onClose={() => {}}
          confirmCallback={(val) => openConfirmation.handler(val)}
        />
      )}
      <Card className="card frozen">
        <div className="actions">
          <div className="left">
            <div style={{ display: 'inherit' }}>
              <HtmlTooltip
                arrow
                title={
                  <div className="notes">
                    <span className="bold">Note: </span>
                    <span>
                      It is essential you do the posting step each time you use this process. If not, these items will
                      build up and may exceed the 500 record limit that can be shown below. When this occurs, you must
                      filter on a specific date range to see all the applicable items.
                    </span>
                  </div>
                }
                minwidth="300px"
              >
                <InfoOutlinedIcon color="info" className="mobile-icon" />
              </HtmlTooltip>
            </div>
            <Divider orientation="vertical" />
            <div>
              <Button
                className="bold"
                color="primary"
                size="small"
                onClick={createNewBatch}
                disabled={agentPaymentsInBatch.length <= 0}
              >
                {agentPayments.batchId == 0 ? 'Create Batch' : 'Update Batch'}{' '}
                {isCreating && <Loading forButton lpad />}
              </Button>
            </div>
            <Divider orientation="vertical" />
            <div className="rpt-buttons-mobile">
              <ButtonGroup
                variant="outlined"
                ref={anchorRef}
                aria-label="split button"
                disabled={agentPayments.batchId == 0}
              >
                <Button color="primary" size="small" variant="contained" onClick={handleToggle} disableElevation>
                  Export
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  disableElevation
                >
                  <ArrowDropDownOutlinedIcon />
                </Button>
              </ButtonGroup>
              <Popper
                // sx={{ zIndex: 99 }}
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          <MenuItem onClick={() => getPdfReport('pdf')}>Summary Rpt</MenuItem>
                          <MenuItem onClick={() => getPdfReport('pdf', true)}>By Notary Rpt</MenuItem>
                          <MenuItem onClick={() => getPdfReport('csv')}>Download CSV</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <Divider orientation="vertical" className="rpt-buttons-mobile" />
            <div>
              <Button
                size="small"
                className="bold"
                variant="contained"
                onClick={doPostApproved}
                disabled={filterPayLoad.showPaid || agentPayments.batchId == 0 || (!smUp && !agentPayments.wasExported)}
                disableElevation
              >
                Post Batch
              </Button>
            </div>
          </div>
          <div className="right">
            <div>
              <IconButton
                id="contact-list-style"
                color="default"
                variant="text"
                aria-expanded={listStyleMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setListStylerAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <Menu
                id="contact-menu-list-style"
                anchorEl={listStylerAnchorEl}
                open={listStyleMenuOpen}
                onClose={() => setListStylerAnchorEl(null)}
              >
                {smUp && (
                  <MenuItem
                    className="rpt-menu-mobile"
                    onClick={(e) => setListStylerAnchorEl(null)}
                    disabled={agentPayments.batchId == 0}
                  >
                    Summary Rpt
                  </MenuItem>
                )}
                {smUp && (
                  <MenuItem
                    hidden={true}
                    className="rpt-menu-mobile"
                    onClick={(e) => setListStylerAnchorEl(null)}
                    disabled={agentPayments.batchId == 0}
                  >
                    By Notary Rpt
                  </MenuItem>
                )}
                {smUp && <Divider key="0" className="rpt-menu-mobile" />}
                <MenuItem
                  onClick={(e) => undoActiveBatch()}
                  disabled={agentPayments.batchId == null || agentPayments.batchId == 0}
                >
                  Remove Active Batch
                </MenuItem>
                <MenuItem
                  onClick={(e) => undoLastPost()}
                  disabled={agentPayments.lastBatchId == 0 || agentPayments.batchId > 0}
                >
                  Undo Last Post
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </Card>
    </InvoicePaymentsCss>
  );
};
