import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const notaryDeskService = {
  getCompletionReports: async function (agentID) {
    const options = { headers: await authHeader() };
    const params = { agentID: agentID };
    const url = `/api/CompletionReport/GetCompletionReportList`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  getCompletionReportDetail: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/api/CompletionReport/GetCompletionReportDetail`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  notifyCompletionReport: async function (request) {
    const options = { headers: await authHeader(), body: request };
    const params = {};
    const url = `/api/CompletionReport/NotifyCompletionReport`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  updateCompletionReport: async function (request) {
    const options = { headers: await authHeader(), body: request };
    const params = {};
    const url = `/api/CompletionReport/UpdateCompletionReport`;
    return Fetch.update(url, params, options).then(handleResponse);
  },
};

export { notaryDeskService };
