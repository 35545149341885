export function checkIfObjectHasChanges(obj1, obj2) {
  if (!_.isEqual(obj1, obj2)) {
    return true;
  }
  return false;
}

export const mergeProps = (...args) => {
  return args.reduce((acc, obj) => {
    return { ...acc, ...obj };
  }, {});
};

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
