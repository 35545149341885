import { authHeader, handleResponse } from '../helpers';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import Fetch from '../helpers/Fetch';

const signingRequestService = {
  getSigningRequestDetail: async function (fromManageRequest, copyFromID, requestID, customerID = 0) {
    let url;
    if (fromManageRequest) {
      url = copyFromID
        ? `/managerequest/detail/copy/${copyFromID}`
        : requestID === 0
        ? `/managerequest/detail/new?customerId=${customerID}`
        : `/managerequest/detail/${requestID}`;
    } else {
      url = copyFromID ? `/newsigningrequest/copy/${copyFromID}` : '/newsigningrequest';
    }

    return fetch(`${SERVICE_URL}` + url, {
      headers: await authHeader(),
      credentials: 'include',
    }).then(handleResponse);
  },

  getRequestSummaryForAssignment: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = {};
    const url = `/manage-request/assign-notary/request/${requestID}`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  assignAgentToRequest: async function (requestID, agentPublicID, action) {
    const options = { headers: await authHeader() };
    const params = { agentPublicID: agentPublicID, action: action };
    const url = `/manage-request/assign-notary/request/${requestID}`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  getAgentAvailRequestEmailTemplate: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/manage-request/assign-notary/get-availability-request`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  sendAvailabilityRequest: async function (requestID, data) {
    const options = { headers: await authHeader(), body: JSON.stringify(data) };
    const params = { requestID: requestID };
    const url = `/manage-request/assign-notary/send-availability-request`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  validateAvailabilityRequestToken: async function (publicId, encryption) {
    const options = { headers: await authHeader() };
    const params = { publicId: publicId, encryption: encryption };
    const url = `/manage-request/assign-notary/validate-availability-request-token`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  saveAvailabilityRequestResponse: async function (publicId, encryption, responseTypeId) {
    const options = { headers: await authHeader() };
    const params = {
      publicId: publicId,
      encryption: encryption,
      responseTypeId: responseTypeId,
    };
    const url = `/manage-request/assign-notary/save-availability-request-response`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  saveCustomInfoOnNotaryAssign: async function (requestID, feesAndCustom) {
    const options = { headers: await authHeader(), body: feesAndCustom };
    const params = { requestID: requestID };
    const url = `/manage-request/assign-notary/request/custom-update`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  submitSigningRequest: async function (fromManageRequest, data) {
    const options = { headers: await authHeader(), body: data };
    const params = {};
    const url = fromManageRequest ? '/managerequest/detail' : '/newsigningrequest';
    return Fetch.post(url, params, options).then(handleResponse);
  },

  saveSigningRequest: async function (fromManageRequest, requestID, data) {
    const options = { headers: await authHeader(), body: data };
    const params = {};
    const url = fromManageRequest ? `/managerequest/detail/${requestID}` : '/newsigningrequest';
    if (requestID > 0) return Fetch.update(url, params, options).then(handleResponse);
    else return Fetch.post(url, params, options).then(handleResponse);
  },

  deleteSigningRequest: async function (fromManageRequest, requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = fromManageRequest ? `/managerequest/deleteRequest` : '/';
    return Fetch.update(url, params, options).then(handleResponse);
  },

  notifyAfterRequestSubmit: async function (requestID, billingVisible, altLocVisible) {
    const options = { headers: await authHeader() };
    let params = {
      requestID: requestID,
      billingVisible: billingVisible,
      altLocVisible: altLocVisible,
    };
    const url = `/newsigningrequest/notifyUserWithEmail`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  /********************************************************************
   *****   End Points that don't need refresh-token after response ****
   ********************************************************************/
  // Complementary end points

  getOptionsSendDocVia: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = '/shared/senddocoptions';
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getCustomersForDDL: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/managerequest/getCustomersForDDL`;
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getOfficerList: async function (fromManageRequest, customerID) {
    const options = { headers: await authHeader() };
    let params = { customerID: customerID };
    const url = fromManageRequest ? `/managerequest/getOfficers` : `/newsigningrequest/getOfficers`;
    if (!fromManageRequest) params = {};
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getRequestLoanTypes: async function (fromManageRequest, includeInactive) {
    const options = { headers: await authHeader() };
    const params = { includeInactive: includeInactive };
    const url = fromManageRequest ? `/managerequest/getLoanTypes` : `/newsigningrequest/getLoanTypes`;
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getProposedSigningFees: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = `/manage-request/get-proposed-fees`;
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getCustomerDefaults: async function (fromManageRequest, customerID, pnlBillVisble) {
    const options = { headers: await authHeader() };
    const params = { customerID: customerID, pnlBillVisble: pnlBillVisble };
    const url = `/managerequest/getCustomerDefaults`;
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getShippingCompanies: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = '/shared/shippingcompanies';
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getPreferedLanguages: async function () {
    const options = { headers: await authHeader() };
    const params = {};
    const url = '/shared/prefered-languages';
    return Fetch.get(url, params, options).then((res) => res.json());
  },
};

export { signingRequestService };
