import React from 'react';
import TextField from '@mui/material/TextField';

const formatPhoneNumber = (value) => {
  // Remove all non-digit characters
  const cleaned = ('' + value).replace(/\D/g, '');

  // Match the cleaned input with the phone number pattern
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    const part1 = match[1] ? `(${match[1]}` : '';
    const part2 = match[2] ? `) ${match[2]}` : '';
    const part3 = match[3] ? `-${match[3]}` : '';
    return [part1, part2, part3].join('').trim();
  }

  return value;
};

const PhoneNumberField = React.forwardRef(({ value, onChange, ...props }, ref) => {
  const handleInputChange = (event) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    onChange(formattedValue);
  };

  return (
    <TextField
      ref={ref}
      {...props}
      value={value}
      onChange={handleInputChange}
      inputProps={{ maxLength: 14, autoComplete: '--off--' }}
      variant="outlined"
    />
  );
});

export default PhoneNumberField;
