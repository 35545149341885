import React, { useState, useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { contactManagerService as service } from '../../services';
import Loading from '../common/others/Loading';
import AppDialog from '../common/others/AppDialog';
import { dispatchAlert, dispatchRelativeAlert } from '../../helpers';
import { ContactManagerContext } from '../../context/contact-manager.context';
import _ from 'lodash';
import BasicTable from '../admin/TechTools/DataTable';

function Dialog({ title, onOpen, onClose }) {
  const { filterPayLoad } = useContext(ContactManagerContext);

  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    let filterPayLoadCloned = _.cloneDeep(filterPayLoad);
    getContacts({ ...filterPayLoadCloned, getContactsForEport: true, pageSize: 5000 });
    return () => {};
  }, []);

  const getContacts = async (payload) => {
    setLoading(true);

    let newController = new AbortController();
    let signal = newController.signal;
    await service.getContactList(payload, signal).then(
      (res) => {
        setContactList(res.contactListExcel);
      },
      (error) => console.log(error)
    );

    setLoading(false);
  };

  return (
    <AppDialog open={onOpen} mainTitle={title} maxWidth="lg">
      <DialogContent dividers sx={{ padding: '15px !important', height: '100vh' }}>
        {loading && <Loading forList />}
        {contactList?.length > 0 && <BasicTable data={contactList} />}
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="text" color="default" onClick={() => onClose(true)}>
          Close
        </Button>
      </DialogActions>
    </AppDialog>
  );
}

Dialog.propTypes = {
  onOpen: PropTypes.bool.isRequired,
};

export const ContactExport = memo(Dialog);
