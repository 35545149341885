export const TRANSLATIONS_EN = {
  global: {
    ns__state: 'State',
  },
  signingRequest: {
    ns__borrowerFirstName: 'Signer First Name',
    ns__borrowerLastName: 'Signer Last Name',
    ns__coBorrowerFirstName: 'Co-Signer First Name',
    ns__coBorrowerLastName: 'Co-Signer Last Name',
    ns__signingAddress1: 'Property Address Line 1',
    ns__signingAddress2: 'Property Address Line 2',
    ns__signingCity: 'City',
    ns__signingState: 'State',
    ns__signingZipCode: 'Zip Code',
    ns__dayPhone: 'Day Phone',
    ns__eveningPhone: 'Evening Phone',
    ns__otherPhone: 'Cell Phone',
    ns__otherContactInfo: 'Other Contact Info - 500 char limit',
    ns__otherContactInfoCharLimit: '500 character limit',

    ns__altInvoiceCompany: 'Company Name',
    ns__altInvoiceAddress: 'Company Address - 500 char limit',
    ns__invoiceEmail_1: 'Invoice Email #1',
    ns__invoiceEmail_2: 'Invoice Email #2',
    ns__invoiceEmail_3: 'Invoice Email #3',
  },
  businessSettings: {
    ns__bCompanyName: 'Company Name',
    ns__bAddress1: 'Address 1',
    ns__bAddress2: 'Address 2',
    ns__bCity: 'City',
    ns__bState: 'State',
    ns__bZip: 'Zip',
    ns__bPhone: 'Phone',
    ns__bFax: 'Fax',
    ns__bTerms: 'Billing Terms',
  },
  emailAddressSettings: {
    ns__mainEmail_1: 'Main Email #1',
    ns__mainEmail_2: 'Main Email #2',
    ns__mainEmail_3: 'Main Email #3',
    ns__mobileEmail_1: 'Mobile Email #1',
    ns__mobileEmail_2: 'Mobile Email #2',
    ns__mobileEmail_3: 'Mobile Email #3',
    ns__txtInvoiceEmail1: 'Email#1',
    ns__txtInvoiceEmail2: 'Email#2',
    ns__txtAgentCompleteEmail1: 'Email#1',
    ns__txtAgentCompleteEmail2: 'Email#2',
    ns__txtRegEmail1: 'Email #1',
    ns__txtRegEmail2: 'Email #2',
    ns__txtDocsEmail_1: 'Email #1',
    ns__txtDocsEmail_2: 'Email #2',
    ns__txtConfirmEmail_1: 'Email #1',
    ns__txtConfirmEmail_2: 'Email #2',
  },
  reports: {
    reportType: 'Report',
    rs_AgentFirstName: 'First',
    rs_AgentLastName: 'Last',
    rs_BorrowerLastName: 'Borrower Last Name',
    rs_Status: 'Status',
    rs_CustomerList: 'Customer List',
  },
};

// xx_[signingRequestPage]_borrowerFirstName_db[whateverthenamefromthedb]_xx
