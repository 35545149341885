import purify from 'dompurify';

export const isValidEmail = (val) => {
  if (typeof val !== 'undefined') {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(val)) {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidEmailOr = (val) => {
  if (val?.length > 0) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(val)) {
      return false;
    } else {
      return true;
    }
  } else return true;
};

export const isValidUrl = (string) => {
  try {
    console.log('====>> isValidUrl');
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const validateURL = (url) => {
  if (url != '') {
    const parsed = new URL(url);
    return ['https:', 'http:'].includes(parsed.protocol);
  } else return false;
};

export const getValidURLOr = (url) => {
  if (url != '') {
    if (['https:', 'http:'].includes(url)) {
      try {
        const parsed = new URL(url);
        console.log(parsed);
        return parsed;
      } catch (error) {
        return url;
      }
    }
  } else return url;
};

export const sanitizeHTML = (value) => {
  return { __html: purify.sanitize(value) };
};

export const triggerWhenCallback = (func, ...params) => {
  return typeof func === 'function' ? func(...params) : null;
};
