import React, { createContext, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { manageRequestService as service } from '../services';
import { store } from '../store';
import { sortBy } from '../helpers';

export const RequestLogContext = createContext();

const sortOptions = [
  { label: 'Date', key: 'logDateTime', property: 'logDateTime', desc: false },
  {
    label: 'Date  (Desc)',
    key: 'logDateDesc',
    property: 'logDateTime',
    desc: true,
  },
  { label: 'Type', key: 'logType', property: 'logType', desc: false },
  { label: 'Type (Desc)', key: 'logTypeDesc', property: 'logType', desc: true },
];

const RequestLogProvider = (props) => {
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const { detailID } = useParams();
  const { portalID } = store.getState().reducer.global;

  const [loadingLogs, setLoadingLogs] = useState(false);
  const [performingCrud, setPerformingCrud] = useState(false);
  const [logHeader, setLogHeader] = useState('');
  const [logInEditMode, setLogInEditMode] = useState(false);
  const [logInNewMode, setLogInNewMode] = useState(false);
  const [selectedSort, setSelectedSort] = useState(sortOptions[1]);
  const [sortedLogs, setSortedLogs] = useState([]);

  const totalLogs = sortedLogs.length;

  useEffect(() => {
    dispatch({ type: 'CLEAR_ALERTS' });
    getRequestsLogs();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const notifyUserOnScreen = (resType, msg) => {
    dispatch({
      type: 'PUSH_ALERT',
      payload: {
        open: true,
        color: resType,
        message: msg,
      },
    });
  };

  const refreshLogsList = () => {
    return new Promise((resolve, reject) => {
      service.getRequestLogs(detailID).then((res) => {
        setSortedLogs(res.requestLogs);
        setSelectedSort(sortOptions[1]);
        setLogInNewMode(false);
        setLogInEditMode(false);
        resolve();
      });
    });
  };

  const getRequestsLogs = async () => {
    if (!mountedRef.current) return null;
    setLoadingLogs(true);
    await service
      .getRequestLogs(detailID)
      .then((res) => {
        setLogHeader({
          showAddNew: res.addNewEntry_Visible,
          headerInfo: res.headerInfo,
        });
        setSortedLogs(res.requestLogs);
        setLoadingLogs(false);
      })
      .catch((error) => {
        notifyUserOnScreen('error', '[Replace]' + error);
        setSortedLogs([]);
        setLoadingLogs(false);
      });
  };

  const saveRequestLog = async (log) => {
    if (!mountedRef.current) return null;
    setPerformingCrud({ save: true });
    await service
      .saveRequestLog(log)
      .then(async () => {
        await refreshLogsList();
        notifyUserOnScreen('success', '[Replace]Request Log was added.');
      })
      .catch((error) => {
        notifyUserOnScreen('error', '[Replace]' + error);
      });
    setPerformingCrud({ save: false });
  };

  const updateRequestLog = async (log) => {
    if (!mountedRef.current) return null;
    setPerformingCrud({ update: true });
    await service
      .updateRequestLog(log)
      .then(async () => {
        await refreshLogsList();
        notifyUserOnScreen('success', '[Replace]Request Log was updated.');
      })
      .catch((error) => {
        notifyUserOnScreen('error', '[Replace]' + error);
      });
    setPerformingCrud({ update: false });
  };

  const deleteRequestLog = async (log) => {
    if (!mountedRef.current) return null;
    setPerformingCrud({ delete: true });
    await service
      .deleteRequestLog(log.requestLogID, log.isLegacy)
      .then(async () => {
        await refreshLogsList();
        notifyUserOnScreen('success', '[Replace]Request Log was deleted.');
      })
      .catch((error) => {
        notifyUserOnScreen('error', '[Replace]' + error);
      });
    setPerformingCrud({ delete: false });
  };

  const addNewLog = () => {
    setLogInNewMode(true);
    setLogInEditMode(true);
    let newLog = {
      logSequence: 0,
      requestID: parseInt(detailID),
      portalID: parseInt(portalID),
      logType: 'Note',
      viewByCustomer: false,
      viewByAgent: false,
      logText: '',
    };
    setSortedLogs([{ ...newLog }, ...sortedLogs]);
  };

  const sortLogsBy = (sortObj) => {
    let newSortedArray = sortBy(sortedLogs, {
      prop: sortObj.property,
      desc: sortObj.desc,
    });
    setSortedLogs([...newSortedArray]);
  };

  return (
    <RequestLogContext.Provider
      value={{
        loadingLogs,
        performingCrud,
        totalLogs,
        logHeader,
        sortedLogs,
        logInEditMode,
        logInNewMode,
        sortOptions,
        selectedSort,
        setLogInEditMode,
        setLogInNewMode,
        setSortedLogs,
        getRequestsLogs,
        saveRequestLog,
        addNewLog,
        updateRequestLog,
        setPerformingCrud,
        deleteRequestLog,
        sortLogsBy,
        setSelectedSort,
      }}
    >
      {props.children}
    </RequestLogContext.Provider>
  );
};

export default RequestLogProvider;
