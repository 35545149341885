import React, { useState, useContext, Fragment } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { UserAdminContext } from '../../context/user-admin.context';
import UserDialog from './UserDialog';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import EditOutlined from '@mui/icons-material/EditOutlined';
import AppConfirmationDialog from '../common/others/AppConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { isAfter, isValid, parseISO } from 'date-fns';
import { Card } from '@mui/material';

function formatDate(string) {
  var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return isAfter(parseISO(string), parseISO('2000-01-01'))
    ? new Date(string).toLocaleDateString([], options)
    : 'Never Looged In.';
}

const StyledDiv = styled(Card)(
  ({ theme }) => css`
    margin-bottom: 5px;

    :hover {
      background-color: ${theme.palette.nsTableRowHoverBG.main};
    }

    & .user-row {
      display: flex;
      flex-direction: row nowrap;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      margin-bottom: 5px;
      border-radius: 5px;
      line-height: normal !important;
      padding: 5px;
    }

    & .order-item {
      width: 18px;
    }

    & .icon-item {
      cursor: pointer;
    }

    & .last-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 45%;
      align-items: center;
    }

    & .item-login {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 12px;
      width: 13%;
      text-align: center;
    }

    & .email {
      width: 25%;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${theme.palette.primary.default};
    }

    & .email:hover {
      overflow: visible;
    }

    & .name {
      display: flex;
      flex-direction: row;
      text-align: center;
      width: 50%;
    }

    & .start-items {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 25%;
    }
  `
);

const UserAdminItem = ({ user }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmationHandler, setConfirmationHandler] = useState(false);
  const context = useContext(UserAdminContext);
  const { setCurrUser, setPageMode, setIsNewUser, deleteUserAdminMember } = context;

  const handleConfirmation = (event, value) => {
    if (value) handleDialogActions('delete');
    setConfirmationHandler(false);
  };

  const handleOpenDialog = () => {
    setPageMode('edit');
    setCurrUser(user);
    setIsNewUser(false);
    setOpenDialog(true);
  };

  const handleDialogActions = (btnType) => {
    switch (btnType) {
      case 'update':
        setOpenDialog(false);
        return;
      case 'cancel':
        setOpenDialog(false);
        return;
      case 'delete':
        deleteUserAdminMember(user.userID);
      default:
    }
  };

  return (
    <>
      <StyledDiv className="split mobile-table">
        <div className="user-row">
          <div className="start-items">
            <div className="name">
              <p>{user.firstName}</p>
            </div>
            <div className="name">
              <p>{user.lastName}</p>
            </div>
          </div>
          <div className="email">
            <p>{user.userName}</p>
          </div>
          <div className="last-items">
            <div className="item-login">
              <p>{formatDate(user.lastLoginDate)}</p>
            </div>
            <div className="order-item">
              <p>{user.order}</p>
            </div>
            {user.authorized ? <Check size="small" color="primary" /> : <Clear style={{ color: 'grey' }} />}
            {user.staff ? <Check size="small" color="primary" /> : <Clear style={{ color: 'grey' }} />}
            {user.admin ? <Check size="small" color="primary" /> : <Clear style={{ color: 'grey' }} />}
            <EditOutlined className="icon-item" variant="outlined" onClick={() => handleOpenDialog('edit')} />
            <DeleteIcon
              className="icon-item"
              onClick={(e) =>
                setConfirmationHandler({
                  event: e,
                  title: 'Confirm Deletion',
                  msg: `Do you really want to delete ${user.firstName} ${user.lastName}?`,
                })
              }
            />
          </div>
        </div>
      </StyledDiv>
      <UserDialog user={user} open={openDialog} onClose={setOpenDialog} />
      {confirmationHandler && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={confirmationHandler !== false ? true : false}
          title={confirmationHandler.title}
          content={confirmationHandler.msg}
          buttonTrueText="Delete"
          color="error"
          onClose={() => {}}
          confirmCallback={(val) => handleConfirmation(confirmationHandler.event, val)}
        />
      )}
    </>
  );
};

export default UserAdminItem;
