import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { TextField, MenuItem, Card } from '@mui/material';
import '@mui/lab';
import { useTranslation } from 'react-i18next';
import _, { constant, head } from 'lodash';
import { CSVLink } from 'react-csv';

import CustomerDDL from '../components/manage-request/signing-request/CustomerDDL.js';
import { reportService } from '../services';
import { store } from '../store';
import { dispatchAlert, sanitizeStringDate } from '../helpers';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle.js';
import { NSDatePicker } from '../components/common/pickers/NSDatePicker.js';
import { parse } from 'date-fns';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',

    '& .form-inputs': {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gridGap: '20px',
      fontSize: '15px',

      '& .main-info': {
        zIndex: '0',
        fontSize: '15px',
        gridGap: '5px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: `"borrowerfirst borrowerlast"
        "coborrowerfirst coborrowerlast"
        "areaDate areaDate"
        "area2 area2"
        "city city"`,
      },

      '& .areaDate': {
        gridArea: 'areaDate',
      },
      '& .area2': {
        gridArea: 'area2',
      },
      '& .city': {
        gridArea: 'city',
      },
    },
    '& .rows': {
      '@media screen and (max-width:600px)': {
        display: 'none',
      },
    },
    '& .cols': {
      '@media screen and (max-width:600px)': {
        display: 'flex',
        flexDirection: 'column',
      },
      '@media screen and (min-width:600px)': {
        display: 'none',
      },
    },

    '& label': {
      fontSize: 'inherit',
    },

    [theme.breakpoints.up('sm')]: {
      '& .form-inputs': {
        display: 'block',
      },
    },
  },
}));

const Reports = (props) => {
  useDocumentTitle('Reports'); // Set page title

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const reportOptions = [
    { value: '0', select: true, label: '-- Select a report --' },
    { value: '1', label: 'Accounts Receivable' },
    { value: '5', label: 'Customer AR Detail' },
    { value: '6', label: 'Customer Invoices' },
    { value: '7', label: "Customer Payments & Adj's" },
    { value: '8', label: "Notary Payments & Adj's" },
    { value: '2', label: 'Signing Request Status' },
    { value: '3', label: 'Signing Request Counts' },
    { value: '4', label: 'Signing Requests Detail' },
    { value: '12', label: 'Notary W-9 Report Detail' },
    { value: '11', label: 'Notary W-9 Report Summary' },
  ];

  const statusList = [
    { value: '1', label: 'Open: New' },
    { value: '2', label: 'Open: Pending' },
    { value: '3', label: 'Open: Appt Confirmed' },
    { value: '4', label: 'Billing: Appt Complete. To Invoice' },
    { value: '5', label: 'Billing: Invoiced. Awaiting Pmt' },
    { value: '6', label: 'Closed: Paid' },
    { value: '123', selected: true, label: "- All 'Open' -" },
    { value: '45', label: "- All 'Billing' -" },
    { value: '123456', label: '- All Items (Excl. Dec/Canc) -' },
    { value: '1234569', label: '- All Items (Inc. Dec/Canc) -' },
    { value: '9', label: 'Declined' },
    { value: '10', label: 'Canceled' },
  ];

  const [reportParameterSetting, setReportParameterSetting] = useState('');
  const [reportSetting, setReportSetting] = useState('');
  const [selectedReportId, setSelectedReportId] = useState('');

  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [csvFileName, setCsvFileName] = useState('');

  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [state, setState] = useState('');

  useEffect(() => {
    if (props.custWizard && settings) {
      hangleCustomerDDLChange(props.custWizard);
    }
  }, [props.custWizard]);

  const hangleCustomerDDLChange = (event) => {
    if (event !== null) {
      const payloadToSet = {
        userID: event.userID,
        customerString: event.customer,
        customerDDLSort: event.theSort,
      };
      setState({
        ...state,
        ...payloadToSet,
      });
      setReportParameterSetting({
        ...reportParameterSetting,
        userID: event.userID,
      });
    }
  };

  const resetCustomerDDLChange = () => {
    const payloadToSet = {
      userID: 0,
      customerString: 'All Customers',
      customerDDLSort: 0,
    };
    setState({
      ...state,
      ...payloadToSet,
    });
    setReportParameterSetting({
      ...reportParameterSetting,
      userID: 0,
    });
  };

  // const parseDateString = (date) => {
  //   const now = new Date();
  //   return parse(date, 'MM/dd/yyyy', now);
  // };

  const parseDateString = (date, range) => {
    const now = new Date();
    if (range == 'from' && !date) {
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      return parse(firstDayOfMonth.toLocaleDateString(), 'MM/dd/yyyy', now);
    } else if (range == 'to' && !date) {
      return parse(now.toLocaleDateString(), 'MM/dd/yyyy', now);
    }

    return parse(date, 'MM/dd/yyyy', now);
  };

  //setting up initial values for date parameters from server.
  const setInitialValues = (res) => {
    resetCustomerDDLChange();
    setReportParameterSetting({
      ...reportParameterSetting,
      signingDateFrom: parseDateString(res.reportCriteriaSettings.dbSigningDateFromText, 'from'),
      signingDateTo: parseDateString(res.reportCriteriaSettings.dbSigningDateToText, 'to'),
      requestDateFrom: parseDateString(res.reportCriteriaSettings.dbRequestDateFromText, 'from'),
      requestDateTo: parseDateString(res.reportCriteriaSettings.dbRequestDateToText, 'to'),
      borrowerLastName: '',
      customerId: '0',
      agentFirstName: '',
      agentLastName: '',
      status: '1234569',
    });
  };

  const rptParams = {
    reportId: null,
    reportType: null,
    signingDateFrom: null,
    signingDateTo: null,
    requestDateFrom: null,
    requestDateTo: null,
    borrowerLastName: '',
    customerId: '0',
    agentFirstName: '',
    agentLastName: '',
    statusId: '1234569',
  };

  const getReportSettings = (reportId) => {
    return new Promise((resolve, reject) => {
      reportService
        .getReportSettings(reportId)
        .then((res) => {
          setSelectedReportId(reportId);
          setReportSetting(res);
          setInitialValues(res);
        })
        .catch((error) => {
          setDataIsLoading(false);
        });
      resolve();
    });
  };

  const setParameters = (reportId, reportType) => {
    rptParams.reportId = reportId;
    rptParams.reportType = reportType;
    rptParams.signingDateFrom = reportParameterSetting.signingDateFrom || null;
    rptParams.signingDateTo = reportParameterSetting.signingDateTo || null;
    rptParams.requestDateFrom = reportParameterSetting.requestDateFrom || null;
    rptParams.requestDateTo = reportParameterSetting.requestDateTo || null;
    rptParams.borrowerLastName = reportParameterSetting.borrowerLastName;
    rptParams.customerId = reportParameterSetting.customerId.toString();
    rptParams.agentFirstName = reportParameterSetting.agentFirstName;
    rptParams.agentLastName = reportParameterSetting.agentLastName;
    rptParams.statusId = reportParameterSetting.status;
  };

  const getReport = (reportType) => {
    setParameters(selectedReportId, reportType);
    let filename = '';
    reportService
      .getReport(JSON.stringify(rptParams))
      .then((response) => {
        if (response.ok) {
          const header = response.headers.get('Content-Disposition');
          const parts = header?.split(';');
          filename = parts[1].split('=')[1];
          return response.blob();
        } else {
          throw new Error('Error occurred while downloading the file.');
        }
      })
      .then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatchAlert('Report downloaded successfully!');
      })
      .catch((error) => dispatchAlert(error.message, true));
  };

  const getDevExpressReport = () => {
    return reportService.getDevExpressReport();
  };

  return (
    <>
      <div className={classes.root}>
        <div className="view-title">Reports</div>
        <div className="cols">
          <Grid container direction={'column'} spacing={2}>
            <Grid item xs={6}>
              <Item>
                <div className="comp-title">To Run Report</div>
                <Card>
                  <p
                    style={{
                      width: '100%',
                      paddingLeft: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Step 1 - Select Report under 'Report Choice'
                  </p>
                  <p
                    style={{
                      width: '100%',
                      paddingLeft: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Step 2 - Select Criteria under 'Report Criteria'
                  </p>
                  <p
                    style={{
                      width: '100%',
                      paddingLeft: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Step 3 - Click on the report buttons that will appear below
                  </p>
                </Card>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="comp-title">Report Choice</div>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <TextField
                        id="reportList"
                        label={`${t('reports.reportType')}`}
                        select
                        value={selectedReportId || ''}
                        onChange={(e) => {
                          getReportSettings(e.target.value);
                        }}
                      >
                        {reportOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Item>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <div className="comp-title">Report Criteria</div>
                <div style={{ display: selectedReportId <= 0 ? '' : 'none' }}>Choose a report to show criteria.</div>
                <Card className={classes.root} style={{ display: selectedReportId > 0 ? '' : 'none' }}>
                  {reportSetting?.reportCriteriaSettings?.pnlSigningDateVisible ? (
                    <div>
                      <div className="comp-sub-title" style={{ overflow: 'hidden', float: 'left' }}>
                        {reportSetting?.reportCriteriaSettings?.lblSigningDateText}
                      </div>
                      {reportSetting?.reportCriteriaSettings?.dbSigningDateFromVisible ? (
                        <NSDatePicker
                          label="From"
                          getValidISODate
                          date={reportParameterSetting.signingDateFrom}
                          emitDate={(date) => {
                            setReportParameterSetting({
                              ...reportParameterSetting,
                              signingDateFrom: date,
                            });
                          }}
                        />
                      ) : null}
                      <NSDatePicker
                        label="To"
                        getValidISODate
                        date={reportParameterSetting.signingDateTo}
                        emitDate={(date) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            signingDateTo: date,
                          });
                        }}
                      />
                    </div>
                  ) : null}
                  {reportSetting?.reportCriteriaSettings?.pnlRequestDateVisible ? (
                    <div>
                      <div className="comp-sub-title" style={{ overflow: 'hidden', float: 'left' }}>
                        {reportSetting?.reportCriteriaSettings?.lblRequestDateText}
                      </div>
                      <NSDatePicker
                        label="From"
                        getValidISODate
                        date={reportParameterSetting.requestDateFrom}
                        emitDate={(date) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            requestDateFrom: date,
                          });
                        }}
                      />
                      <NSDatePicker
                        label="customerInvoice"
                        getValidISODate
                        date={reportParameterSetting.requestDateTo}
                        emitDate={(date) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            requestDateTo: date,
                          });
                        }}
                      />
                    </div>
                  ) : null}

                  {reportSetting?.reportCriteriaSettings?.pnlStatusVisible ? (
                    <TextField
                      id="status"
                      label={`${t('reports.rs_Status')}`}
                      select
                      value={reportParameterSetting.status || ''}
                      onChange={(e) => {
                        setReportParameterSetting({
                          ...reportParameterSetting,
                          status: e.target.value,
                        });
                      }}
                    >
                      {statusList.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}

                  {reportSetting?.reportCriteriaSettings?.pnlBorrowerLastNameVisible ? (
                    <TextField
                      fullWidth
                      label={`${t('reports.rs_BorrowerLastName')}`}
                      value={reportParameterSetting.borrowerLastName || ''}
                      onChange={(e) => {
                        setReportParameterSetting({
                          ...reportParameterSetting,
                          borrowerLastName: e.target.value,
                        });
                      }}
                    />
                  ) : null}
                  {reportSetting?.reportCriteriaSettings?.pnlCustomerListVisible ? (
                    <CustomerDDL
                      value={{
                        userID: state.userID,
                        customer: state.customerString,
                        theSort: state.customerDDLSort,
                      }}
                      onChange={(e) => {
                        hangleCustomerDDLChange(e);
                        setReportParameterSetting({
                          ...reportParameterSetting,
                          customerId: e.userID,
                        });
                      }}
                      useAllCustomerAsDefault
                    />
                  ) : null}

                  {reportSetting?.reportCriteriaSettings?.pnlAgentLastNameVisible ? (
                    <div>
                      <div className="comp-sub-title" style={{ overflow: 'hidden', float: 'left' }}>
                        Agent Name
                      </div>
                      <TextField
                        fullWidth
                        label={`${t('reports.rs_AgentFirstName')}`}
                        value={reportParameterSetting.agentFirstName || ''}
                        onChange={(e) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            agentFirstName: e.target.value,
                          });
                        }}
                      />

                      <TextField
                        fullWidth
                        label={`${t('reports.rs_AgentLastName')}`}
                        value={reportParameterSetting.agentLastName || ''}
                        onChange={(e) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            agentLastName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </Card>
              </Item>
            </Grid>
            <Grid item xs={6} style={{ display: selectedReportId > 0 ? '' : 'none' }}>
              <Item>
                {reportSetting?.reportButtonSettings?.pdfReportButtonVisible ? (
                  <Button
                    className="searchNow"
                    type="submit"
                    onClick={(e) => {
                      getReport('pdf');
                    }}
                    style={{
                      marginRight: '4px',
                      marginTop: '4px',
                    }}
                  >
                    Run PDF Report
                  </Button>
                ) : null}
                {reportSetting?.reportButtonSettings?.csvReportButtonVisible ? (
                  <Button
                    className="searchNow"
                    type="submit"
                    onClick={(e) => {
                      getReport('text');
                    }}
                    style={{
                      marginRight: '4px',
                      marginTop: '4px',
                    }}
                  >
                    Run CSV/Excel Report
                  </Button>
                ) : null}
                <CSVLink
                  id="csvlink"
                  data={csvData}
                  headers={csvHeader}
                  filename={csvFileName}
                  style={{ display: 'none', marginTop: '4px' }}
                >
                  Download me
                </CSVLink>
              </Item>
            </Grid>
          </Grid>
        </div>
        <div className="rows">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="comp-title">Report Choice</div>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <TextField
                        id="reportList"
                        label={`${t('reports.reportType')}`}
                        select
                        value={selectedReportId || ''}
                        onChange={(e) => {
                          getReportSettings(e.target.value);
                        }}
                      >
                        {reportOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Item>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <div className="comp-title">To Run Report</div>
                <Card>
                  <p
                    style={{
                      width: '100%',
                      paddingLeft: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Step 1 - Select Report under 'Report Choice'
                  </p>
                  <p
                    style={{
                      width: '100%',
                      paddingLeft: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Step 2 - Select Criteria under 'Report Criteria'
                  </p>
                  <p
                    style={{
                      width: '100%',
                      paddingLeft: '10px',
                      textAlign: 'left',
                    }}
                  >
                    Step 3 - Click on the report buttons that will appear below
                  </p>
                </Card>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <div className="comp-title">Report Criteria</div>
                <div style={{ display: selectedReportId <= 0 ? '' : 'none' }}>Choose a report to show criteria.</div>
                <Card className={classes.root} style={{ display: selectedReportId > 0 ? '' : 'none' }}>
                  {reportSetting?.reportCriteriaSettings?.pnlSigningDateVisible ? (
                    <div>
                      <div className="comp-sub-title" style={{ overflow: 'hidden', float: 'left' }}>
                        {reportSetting?.reportCriteriaSettings?.lblSigningDateText}
                      </div>
                      {reportSetting?.reportCriteriaSettings?.dbSigningDateFromVisible ? (
                        <NSDatePicker
                          label="From"
                          getValidISODate
                          date={reportParameterSetting.signingDateFrom}
                          emitDate={(date) => {
                            setReportParameterSetting({
                              ...reportParameterSetting,
                              signingDateFrom: date,
                            });
                          }}
                        />
                      ) : null}

                      <NSDatePicker
                        label="To"
                        getValidISODate
                        date={reportParameterSetting.signingDateTo}
                        emitDate={(date) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            signingDateTo: date,
                          });
                        }}
                      />
                    </div>
                  ) : null}
                  {reportSetting?.reportCriteriaSettings?.pnlRequestDateVisible ? (
                    <div>
                      <div className="comp-sub-title" style={{ overflow: 'hidden', float: 'left' }}>
                        {reportSetting?.reportCriteriaSettings?.lblRequestDateText}
                      </div>
                      <NSDatePicker
                        label="From"
                        getValidISODate
                        date={reportParameterSetting.requestDateFrom}
                        emitDate={(date) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            requestDateFrom: date,
                          });
                        }}
                      />
                      <NSDatePicker
                        label="To"
                        getValidISODate
                        date={reportParameterSetting.requestDateTo}
                        emitDate={(date) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            requestDateTo: date,
                          });
                        }}
                      />
                    </div>
                  ) : null}

                  {reportSetting?.reportCriteriaSettings?.pnlStatusVisible ? (
                    <TextField
                      id="status"
                      label={`${t('reports.rs_Status')}`}
                      select
                      value={reportParameterSetting.status || ''}
                      onChange={(e) => {
                        setReportParameterSetting({
                          ...reportParameterSetting,
                          status: e.target.value,
                        });
                      }}
                    >
                      {statusList.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}

                  {reportSetting?.reportCriteriaSettings?.pnlBorrowerLastNameVisible ? (
                    <TextField
                      fullWidth
                      label={`${t('reports.rs_BorrowerLastName')}`}
                      value={reportParameterSetting.borrowerLastName || ''}
                      onChange={(e) => {
                        setReportParameterSetting({
                          ...reportParameterSetting,
                          borrowerLastName: e.target.value,
                        });
                      }}
                    />
                  ) : null}
                  {reportSetting?.reportCriteriaSettings?.pnlCustomerListVisible ? (
                    <CustomerDDL
                      value={{
                        userID: state.userID,
                        customer: state.customerString,
                        theSort: state.customerDDLSort,
                      }}
                      onChange={(e) => {
                        hangleCustomerDDLChange(e);
                        setReportParameterSetting({
                          ...reportParameterSetting,
                          customerId: e.userID,
                        });
                      }}
                      useAllCustomerAsDefault
                    />
                  ) : null}

                  {reportSetting?.reportCriteriaSettings?.pnlAgentLastNameVisible ? (
                    <div>
                      <div className="comp-sub-title" style={{ overflow: 'hidden', float: 'left' }}>
                        Agent Name
                      </div>
                      <TextField
                        fullWidth
                        label={`${t('reports.rs_AgentFirstName')}`}
                        value={reportParameterSetting.agentFirstName || ''}
                        onChange={(e) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            agentFirstName: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        fullWidth
                        label={`${t('reports.rs_AgentLastName')}`}
                        value={reportParameterSetting.agentLastName || ''}
                        onChange={(e) => {
                          setReportParameterSetting({
                            ...reportParameterSetting,
                            agentLastName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </Card>
              </Item>
            </Grid>
            <Grid item xs={6} style={{ display: selectedReportId > 0 ? '' : 'none' }}>
              <Item>
                {reportSetting?.reportButtonSettings?.pdfReportButtonVisible ? (
                  <Button
                    className="searchNow"
                    type="submit"
                    onClick={(e) => {
                      getReport('pdf');
                    }}
                    style={{
                      marginRight: '4px',
                      marginTop: '4px',
                    }}
                  >
                    Run PDF Report
                  </Button>
                ) : null}
                {reportSetting?.reportButtonSettings?.csvReportButtonVisible ? (
                  <Button
                    className="searchNow"
                    type="submit"
                    onClick={(e) => {
                      getReport('text');
                    }}
                    style={{
                      marginRight: '4px',
                      marginTop: '4px',
                    }}
                  >
                    Run CSV/Excel Report
                  </Button>
                ) : null}
                <CSVLink
                  id="csvlink"
                  data={csvData}
                  headers={csvHeader}
                  filename={csvFileName}
                  style={{ display: 'none', marginTop: '4px' }}
                >
                  Download me
                </CSVLink>
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
      <br />
      {/* <div>
        <Button
          className="searchNow"
          type="submit"
          onClick={(e) => getDevExpressReport()}
        >
          DevExpress Report
        </Button>
        <br />
        <br />
        <iframe
          src="http://localhost:60461/MailMerge/SpreadsheetDocumentTemplatePreview"
          width={900}
          height={500}
        ></iframe>
      </div> */}
    </>
  );
};

export default Reports;
