import React, { useContext } from 'react';
import { Chip, Divider, IconButton, Stack } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { currencyFormat } from '../../../helpers/currency-format';
import { AgentPaymentMgtContext } from '../../../context/agent-payment-mgt.context';
import TopListPagination from '../../common/others/TopListPagination';
import { HtmlTooltip } from '../../../Style';

export const AgentPaymentListOptions = (props) => {
  const {
    agentPayments,
    pageSizesForList,
    pagination,
    totalPages,
    handlePageSize,
    handlePageNumber,
    filterPayLoad,
    setFilterPayLoad,
    getAgentPaymentsList: getData,
  } = useContext(AgentPaymentMgtContext);

  const handleListOption = (event, param = '') => {
    if (param.includes('remove-canceled')) {
      setFilterPayLoad({
        ...filterPayLoad,
        hideCanceled: !filterPayLoad.hideCanceled,
      });
      getData({ ...filterPayLoad, hideCanceled: !filterPayLoad.hideCanceled });
    }
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} className="list-options">
      <div className="options">
        <span>
          Approved Total: <b>{currencyFormat(agentPayments.totalApproved)}</b>
          <HtmlTooltip
            arrow
            title={
              'Total represents selected requests that may not be shown when filtering or on subsequent pages. To clear all selected requests, select and unselect the all items checkbox on each page.'
            }
          >
            <IconButton>
              <InfoOutlinedIcon color="info" fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        </span>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
        <div className="option-chips">
          <Chip
            size="small"
            color={filterPayLoad.hideCanceled ? 'primary' : 'default'}
            variant="outlined"
            label={'Hide Canceled'}
            clickable
            icon={filterPayLoad.hideCanceled ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
            onClick={(e) => handleListOption(e, 'remove-canceled')}
          />
        </div>
      </div>
      <TopListPagination
        pageSizeOptions={pageSizesForList}
        pageSize={parseInt(pagination.pageSize)}
        pageSizesForList={pageSizesForList}
        pageNumber={pagination.pageNumber}
        totalPages={totalPages}
        handlePageSize={handlePageSize}
        handlePageNumber={handlePageNumber}
      />
    </Stack>
  );
};
