import React, { useState, useEffect, createContext, useRef } from 'react';
import { getProfileLookupsForDDL } from './contact-manager.context';
import { useHistory, useParams } from 'react-router-dom';

import { profileService as service } from '../services';
import { dispatchAlert } from '../helpers';

export const UserProfileContext = createContext();

const UserProfileProvider = (props) => {
  const history = useHistory();
  const mountedRef = useRef(true);
  const profileRef = useRef();
  const { profileType, profileId } = useParams();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [ownerData, setOwnerData] = useState({});
  const [serviceEditRights, setServiceEditRights] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [lookupList, setLookupList] = useState([]);
  const [formErrors, setFormErrors] = useState();

  useEffect(() => {
    getProfileLookupsForDDL().then((res) => {
      // setLookupList(res);
      getFullProfile(res);
    });
    return () => {
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
      profileRef.current = false;
    };
  }, []);

  const checkIfStateHasChanges = () => {
    if ((typeof profileRef.current !== 'undefined') & !_.isEqual(profileRef.current, profileInfo)) {
      return true;
    }
    return false;
  };

  const getListName = (lookupList, propertyList, idName) => {
    let list = [...propertyList];
    if (propertyList !== undefined) {
      list.map((element) => {
        lookupList?.find((item) => {
          if (element[idName] === item.index) {
            element.lookupName = item.value;
          }
        });
      });
    }
    return list;
  };

  const getFullProfile = async (lookupList) => {
    setLoading(true);
    return await service.getFullProfile(profileType, profileId, props.fromManager).then(
      (res) => {
        if (!mountedRef.current) return null;
        setLoading(false);
        setServiceEditRights(res.serviceEditRights);
        if (profileType === 'agent') {
          setLookupList(lookupList);
          let notaryCafe = res.notaryCafeProfile;
          if (!(Object.keys(notaryCafe).length === 0)) {
            notaryCafe.Capabilities = getListName(
              lookupList.SelectCapabilityTypes,
              notaryCafe.Capabilities,
              'capabilityTypeId'
            );
            notaryCafe.Links = getListName(lookupList.SelectLinkTypes, notaryCafe.Links, 'linkTypeId');
            notaryCafe.Emails = getListName(lookupList.SelectEmailTypes, notaryCafe.Emails, 'emailTypeId');
            notaryCafe.BackgroundChecks = getListName(
              lookupList.SelectProviderTypes,
              notaryCafe.BackgroundChecks,
              'providerId'
            );
            notaryCafe.Languages = getListName(lookupList.SelectLanguageTypes, notaryCafe.Languages, 'languageTypeId');
            notaryCafe.PhoneNumbers = getListName(lookupList.SelectPhoneTypes, notaryCafe.PhoneNumbers, 'phoneTypeId');
          }
          setProfileInfo(notaryCafe);
          setOwnerData(res.legacyProfile);
          profileRef.current = notaryCafe;
        } else {
          setLookupList(lookupList);
          let legacy = res.legacyProfile;

          if (legacy.PhoneNumbers) {
            legacy.PhoneNumbers = getListName(lookupList.SelectPhoneTypes, legacy.PhoneNumbers, 'phoneTypeId');
          }

          if (legacy.Emails) {
            legacy.Emails = getListName(lookupList.SelectEmailTypes, legacy.Emails, 'emailTypeId');
          }

          setProfileInfo(legacy);
          setOwnerData(res.legacyProfile);
        }
      },
      (error) => {
        dispatchAlert(error + ' Redirected to main list.', true);
        setLoading(false);
        history.push(`/contact-manager`);
      }
    );
  };

  const createProfile = (data = updatedProfile) => {
    let body = { legacyProfile: data };
    return service.createProfile(profileType, body, props.fromManager).then(
      (res) => {
        if (!mountedRef.current) return null;
        dispatchAlert('Profile was created successfully.');
        // setProfileInfo(res);
        setUpdatedProfile({});
        setServiceEditRights(res.serviceEditRights);

        history.push({
          pathname: `/contact-manager/profile/customer/${res.profileId}`,
          state: { removePrevBread: true },
        });

        return true;
      },
      (error) => {
        if (error?.Errors) {
          var errors = Object.keys(error.Errors).reduce(function (res, v) {
            return res.concat(error.Errors[v]);
          }, []);

          dispatchAlert('[Replace]' + error?.Message + errors.join('\n'), true);
          setFormErrors(error?.Errors);
          return false;
        } else dispatchAlert(error, true);

        return false;
      }
    );
  };

  const updateProfile = (data = updatedProfile) => {
    let body = { legacyProfile: data };
    return service.updateProfile(profileType, profileId, body, props.fromManager).then(
      (res) => {
        if (!mountedRef.current) return null;
        // if the lookupName gets passed with the response...
        // delete everything and put the following
        // setProfileInfo(res.userProfile)
        //***********//
        // setProfileInfo(res);
        dispatchAlert('Profile was updated successfully.');
        setUpdatedProfile({});
        setFormErrors();
        return true;
      },
      (error) => {
        if (error?.Errors) {
          var errors = Object.keys(error.Errors).reduce(function (res, v) {
            return res.concat(error.Errors[v]);
          }, []);

          dispatchAlert('[Replace]' + error?.Message + errors.join('\n'), true);
          setFormErrors(error?.Errors);
          return false;
        } else dispatchAlert(error, true);

        return false;
      }
    );
  };

  const deleteProfile = () => {
    return service.deleteProfile(profileType, profileId, props.fromManager).then(
      (res) => {
        if (!mountedRef.current) return null;
        dispatchAlert('Profile was deleted successfully.');
        return true;
      },
      (error) => {
        dispatchAlert('[Replace]' + error, true);
        return false;
      }
    );
  };

  const toggleAgentInPersonalList = async (isFavorite) => {
    return await service.toggleAgentInPersonalList(profileId, isFavorite).then(
      (res) => {
        if (!mountedRef.current) return null;
        if (isFavorite) dispatchAlert('Agent was added to your personal list');
        else dispatchAlert('Agent was removed from your personal list');
      },
      (error) => {
        // console.log(error);
      }
    );
  };

  const mapElementFreeText = (elementId, getOwner = false) => {
    if (getOwner) return ownerData.ElementFreeTexts?.find((el) => el.element == elementId);
    else return profileInfo.ElementFreeTexts?.find((el) => el.element == elementId);
  };

  return (
    <UserProfileContext.Provider
      value={{
        fromManager: props.fromManager,
        loading,
        profileType,
        profileId,
        getFullProfile,
        createProfile,
        updateProfile,
        deleteProfile,
        updatedProfile,
        setUpdatedProfile,
        ownerData,
        setOwnerData,
        lookupList,
        profileInfo,
        setProfileInfo,
        checkIfStateHasChanges,
        serviceEditRights,
        toggleAgentInPersonalList,
        formErrors,
        setFormErrors,
        mapElementFreeText,
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
