import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardContent, Link } from '@mui/material';
import { EditableTable } from '../../common/others/EditableTable';
import { invoiceService as service } from '../../../services';
import { InvoicePaymentsCss } from './style';
import { useHistory, useParams } from 'react-router-dom';

import { dispatchAlert, parseFeeTypesForArrayLookup } from '../../../helpers';
import Loading from '../../common/others/Loading';
import { currencyFormat } from '../../../helpers/currency-format';
import useDocumentTitle from '../../../helpers/hooks/useDocumentTitle';

const initialState = () => {
  return {
    InvoiceSeqID: 0,
    borrowerLastName: '',
    status: '',
    agentName: '',
    feeComments: '',
    invoiceTotal: 0,
    paymentTotal: 0,
    balance: 0,
    oldBalance: 0,
    fees: [],
    payments: [],
    paymentTypes: [],
    colorLink: '',
    linkText: '',
    newStatus: 0,
  };
};

const controlSettingInitialState = () => {
  return {
    check: true,
  };
};

const submittingInitialState = () => {
  return {
    save: false,
    savePayments: false,
  };
};

const _paymentCols = (amountDefault = 0) => {
  return [
    {
      id: 1,
      field: 'paymentDate',
      headerName: 'Date',
      type: 'date',
      editable: true,
      defaultValue: new Date().toISOString(),
      flex: 2,
    },
    {
      id: 2,
      type: 'lookup',
      field: 'pmtTypeDesc',
      headerName: 'Trans Type',
      editable: true,
      defaultValue: 'Customer Payment',
      lookups: [],
      flex: 2,
    },
    {
      id: 3,
      field: 'amount',
      headerName: 'Amount',
      type: 'currency',
      editable: true,
      defaultValue: amountDefault,
      flex: 1,
    },
    {
      id: 4,
      field: 'notes',
      headerName: 'Notes',
      type: 'text',
      editable: true,
      flex: 1,
    },
  ];
};

export const CustomerInvoicePayment = () => {
  useDocumentTitle('Customer Payments'); // Set page title

  const [state, setState] = useState(initialState());
  const history = useHistory();
  const [controlSetting, setControlSetting] = useState(controlSettingInitialState());
  const [isSubmitting, setIsSubmitting] = useState(submittingInitialState());
  const [paymentCols, setPaymentCols] = useState(_paymentCols());

  const requestID = useParams().requestID;

  useEffect(() => {
    service.getCustomerInvoicePayments(requestID).then(
      (res) => {
        setControlSetting(res.controlSetting);
        if (res.controlSetting.check) {
          setResponse(res.invoice);
        } else {
          dispatchAlert(`Sorry. You don't have access to this information.`, true);
        }
      },
      (error) => {
        setControlSetting(controlSettingInitialState());
        dispatchAlert(`There was an error while getting your information.`, true);
      }
    );
    return () => {};
  }, []);

  const setResponse = (invoice) => {
    setState((values) => ({
      ...values,
      ...invoice,
    }));
    setPaymentCols(
      parseFeeTypesForArrayLookup(invoice.paymentTypes, _paymentCols(invoice.balance), 'pmtTypeDesc', 'pmtTypeDesc')
    );
  };

  const savePayments = () => {
    const asyncsavePayments = async () => {
      setIsSubmitting((values) => ({
        ...values,
        savePayments: true,
      }));

      await service.saveCustomerPayments(state).then(
        (res) => {
          dispatchAlert(`Customer Payments was saved.`);
          setState((values) => ({
            ...values,
            ...res,
          }));
          setPaymentCols(
            parseFeeTypesForArrayLookup(state.paymentTypes, _paymentCols(state.balance), 'pmtTypeDesc', 'pmtTypeDesc')
          );
          setIsSubmitting((values) => ({
            ...values,
            savePayments: false,
          }));
        },
        (error) => dispatchAlert(error, true)
      );

      setIsSubmitting((values) => ({
        ...values,
        savePayments: false,
      }));
    };

    asyncsavePayments();
  };

  const handleEditTablePayments = (items) => {
    let details = [...items.state];

    const subtotal = details
      .map((item, index) => {
        let payment = state.paymentTypes.find((x) => x.pmtTypeDesc === item.pmtTypeDesc);
        item.paymentTypeID = payment?.id;
        item.amount = Number(item.amount);
        return Number(item.amount);
      })
      .reduce((prev, curr) => prev + curr, 0);

    const balance = Number(state.invoiceTotal - Number(subtotal || 0) || 0);

    setState((values) => ({
      ...values,
      payments: [...details],
      balance: balance,
      paymentTotal: Number(subtotal || 0),
    }));

    setPaymentCols(
      parseFeeTypesForArrayLookup(state.paymentTypes, _paymentCols(balance), 'pmtTypeDesc', 'pmtTypeDesc')
    );
  };

  const goToInvoice = () => {
    history.push(`/manage-request/invoice/customer/${requestID}`);
  };

  const updateRequestStatus = () => {
    const asyncUpdateRequestStatus = async () => {
      await service.updateRequestStatus(state.requestID, state.newStatus).then(
        (res) => {
          setControlSetting(res.controlSetting);
          if (res.controlSetting.check) {
            setResponse(res.invoice);
            if (res.invoice.statusID == 6) dispatchAlert(`The signing request status was changed to Closed`);
            else if (res.invoice.statusID == 5) dispatchAlert(`The signing request status was changed to Invoiced`);
          } else {
            dispatchAlert(`Sorry. You don't have access to this information.`, true);
          }
        },
        (error) => dispatchAlert(error, true)
      );
    };

    asyncUpdateRequestStatus();
  };

  return (
    <InvoicePaymentsCss>
      <Card className="card card-agent-title">
        <div className="agent-invoice-title">
          Signing Request# {state.requestSeqID} - Borrower: {state.borrowerLastName} - Status: '{state.status}'
        </div>
      </Card>
      {state.invoiceSeqID != 0 && (
        <Card className="card card-title">
          <div className="invoice-title">Payments For Invoice # {state.invoiceSeqID}</div>

          {controlSetting.check && (
            <div className="buttons">
              <Button className="button" onClick={goToInvoice}>
                Go to Invoice
              </Button>
              <Button className="button" variant="contained" onClick={savePayments} disabled={!controlSetting.check}>
                Save Payments {isSubmitting.savePayments && <Loading forButton lpad />}
              </Button>
            </div>
          )}
        </Card>
      )}
      <Card>
        <CardContent>
          {state.invoiceSeqID != 0 && (
            <div className="totals">
              Invoice Total: {currencyFormat(state.invoiceTotal)} Payments: {currencyFormat(state.paymentTotal)}{' '}
              Balance: {currencyFormat(state.balance)}
            </div>
          )}
          {state.invoiceSeqID == 0 && (
            <div className="totals">
              <span className="bold">There is no invoice for signing request # {state.requestSeqID}</span>
              &nbsp; Please make an invoice first.
            </div>
          )}
          <div style={{ flexGrow: 1 }}>
            <EditableTable
              addNew
              needRender
              edit={controlSetting.check && state.invoiceSeqID != 0}
              columns={paymentCols}
              rows={state.payments}
              emitNewRows={(val) => handleEditTablePayments(val)}
            />
            {controlSetting.check && state.linkText && (
              <div className="link-status">
                <Link onClick={() => updateRequestStatus()} style={{ color: state.colorLink }} className="link">
                  {state.linkText}
                </Link>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </InvoicePaymentsCss>
  );
};
