import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

export const portalService = {
  getPortalTabs,
  getPortalModulesByTab,
  getPortalRoles,
  getBusSettings,
  updateBusSettings,
};

// This is an Anonymous end point
async function getPortalTabs(portalID) {
  const options = { headers: await authHeader(false) };
  const params = { portalID: portalID };
  const url = '/portal/getportaltabs';
  return Fetch.get(url, params, options).then(handleResponse);
}

// This is an Anonymous end point
async function getPortalModulesByTab(signal, portalID, tabID) {
  const options = { headers: await authHeader(), signal: signal };
  const params = { portalID: portalID, tabID: tabID };
  const url = '/module/getModulesByTab';
  return Fetch.get(url, params, options).then(handleResponse);
}

async function getPortalRoles(portalID) {
  const options = { headers: await authHeader() };
  const params = { portalID: portalID };
  const url = `/portal/getportalroles`;
  return Fetch.get(url, params, options).then(handleResponse);
}

async function getBusSettings(sectionName) {
  let header = await authHeader();
  const options = { headers: header };
  const params = { sectionName: sectionName };
  const url = '/bussettings/getBusSettings';
  return Fetch.get(url, params, options).then(handleResponse);
}

async function updateBusSettings(sectionName, body) {
  const options = { headers: await authHeader(), body: body };
  const params = { sectionName: sectionName };
  const url = `/bussettings/saveBusSettings`;
  return Fetch.update(url, params, options).then(handleResponse);
}
