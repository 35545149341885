import React, { useRef, useState } from 'react';
import { store } from '../store';
import { Button, TextField, Card, CardContent } from '@mui/material';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Loading from '../components/common/others/Loading';
import ReCAPTCHA from 'react-google-recaptcha';
import { userService } from '../services';
import { dispatchAlert } from '../helpers';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const bgNotaryCafe = require(`../assets/images/bg_header.jpg`);
const notaryCafe = require(`../assets/images/logo_notary_cafe.png`);

const ForgotPassAgentCss = styled('div')(
  ({ theme }) => css`
    .root {
      max-width: 545px;
      display: inline-block;
    }

    .card-content {
      padding: 30px;
    }

    .portalLogo {
      padding: 20px 20px;
      width: 100%;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .form-inputs {
      font-size: 15px;
    }

    .main-info {
      font-size: 15px;
    }

    label {
      font-size: inherit;
    }

    .error {
      color: #e53935;
      text-align: left;
      display: block;
      font-size: 0.75rem;
    }

    ${theme.breakpoints.down('sm')} {
      .form-inputs {
        display: 'block';
      }
    }
    .link {
      color: ${theme.palette.primary.main};
      text-decoration: none;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .view-title-forgot {
      font-size: 0.8rem;
      padding: 0px 30px;
      @media screen and (max-width: 960px) {
        padding: 0px 0px !important;
      }
    }

    .header-notary-cafe {
      background-image: url(${bgNotaryCafe});
      height: 100px;
    }
    .logo-notary-cafe {
      margin-top: 25px;
    }
  `
);

const initialState = () => {
  return {
    username: '',
    usernameError: '',
  };
};

const ForgotAgentPassword = (props) => {
  useDocumentTitle('Forgot Password'); // Set page title
  const { global } = store.getState().reducer;
  const captchaRef = useRef();

  const [isSubmit, setIsSubmit] = useState(false);
  const [state, setState] = useState(initialState());

  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  const onChange = (value) => {
    setState((values) => ({
      ...values,
      verified: true,
      verifiedError: '',
    }));
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;

    setState((values) => ({
      ...values,
      [fieldName]: event.target.value,
      [fieldName + 'Error']: '',
    }));
  };

  const isFormValid = () => {
    let isValid = true;

    if (BUILD_ENV !== 'development') {
      if (!state.verified) {
        isValid = false;
        setState((values) => ({
          ...values,
          verifiedError: 'ReCAPTCHA is required.',
        }));
      }
    }

    if (state.username == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        emailError: 'Username field is required.',
      }));
    }

    return isValid;
  };

  const verifyCaptcha = async () => {
    if (process.env.NODE_ENV !== 'development') {
      try {
        const captchaValue = captchaRef.current.getValue();
        if (!captchaValue) return false;
        else {
          const res = await fetch(`${SERVICE_URL}/portal/verifyCaptcha`, {
            method: 'POST',
            body: JSON.stringify(captchaValue),
            headers: { 'content-type': 'application/json' },
          });
          const data = await res.json();
          if (data.success) return true;
          else return false;
        }
      } catch (error) {
        return false;
      }
    } else return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmit(true);

    if (isFormValid()) {
      if (!(await verifyCaptcha())) {
        dispatchAlert('Sorry. Unable to verify re-captcha.', true);
        setIsSubmit(false);
        captchaRef.current?.reset();
        return;
      }

      userService.forgotAgentPassword(state).then(
        (res) => {
          dispatchAlert('A password reset email was sent to the email on file for this account.', false, {
            to: '/login',
            text: 'Click here to login',
            isButton: false,
          });
          setState(initialState());
          setIsSubmit(false);
          if (BUILD_ENV !== 'development') {
            window.grecaptcha.reset();
          }
        },
        (error) => {
          dispatchAlert('An account with that username was not found.', true);
          setIsSubmit(false);
        }
      );
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <ForgotPassAgentCss>
      <div style={{ textAlign: 'center', justifyContent: 'center' }}>
        <div className="root">
          <div className="portalLogo">
            {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
          </div>

          <Card>
            <div className="header-notary-cafe">
              <img className="logo-notary-cafe" src={notaryCafe} />
            </div>
            <CardContent className="card-content">
              <div className="view-title-forgot">
                If an account with this Username exists, we will send you a password reset link to the email address
                associated with this account.
              </div>
              <div className="view-title-forgot">
                If you need additional assistance please email <b>Support@notarycafe.com</b>
              </div>
              <br />

              <div className="form-inputs">
                <div className="main-info">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <TextField
                      label="Notary Café Username"
                      name="username"
                      value={state.username}
                      onChange={handleChange}
                      helperText={state.usernameError}
                      error={state.usernameError != ''}
                    />
                    {BUILD_ENV !== 'development' && (
                      <ReCAPTCHA
                        ref={captchaRef}
                        name="verified"
                        sitekey={RECAPTCHA_KEY}
                        style={{ marginTop: '10px' }}
                        value={state.verified}
                        onChange={onChange}
                      />
                    )}
                    <span className="error">{state.verifiedError}</span>
                    <br />
                    <div style={{ textAlign: 'end' }}>
                      <div style={{ float: 'left', paddingTop: '7px' }}>
                        <Link
                          className="link"
                          to={{
                            pathname: '/login',
                          }}
                          style={{
                            marginRight: '20px',
                            verticalAlign: 'bottom',
                          }}
                        >
                          {'Return to login page'}
                        </Link>
                      </div>
                      <Button type="submit" style={{ float: 'right' }} variant="contained" disabled={isSubmit}>
                        Reset Password
                        {isSubmit && <Loading forButton lpad />}
                      </Button>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </ForgotPassAgentCss>
  );
};
export default ForgotAgentPassword;
