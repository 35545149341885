import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, TextField, MenuItem, Box, OutlinedInput, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import { isIOS, isMobile } from 'react-device-detect';
import FormHelperText from '@mui/material/FormHelperText';

import { useInputValue } from '../helpers/hooks/useInputValue';
import { store } from '../store';
import { handleResponse } from '../helpers';
import Loading from '../components/common/others/Loading';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const useStyles = makeStyles((theme, context) => ({
  root: {
    padding: '5px',
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },

    [theme.breakpoints.only('xs')]: {
      '& h2': {
        position: 'relative',
        top: '-10px',
      },
    },

    '& .portalLogo': {
      // padding: '20px 20px',
      width: '100%',
      textAlign: 'center',

      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },

    '& .MuiCard-root': {
      padding: '20px',
    },

    '& .separator': {
      marginRight: '10px',
      marginLeft: '10px',
    },

    '& .link': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    '& .forgot-password': {
      marginTop: '20px',
      textAlign: 'center',
    },
    '& .need-register': {
      marginTop: '0px',
      textAlign: 'center',
    },
  },
}));

const logingForTestingOnly = BUILD_ENV === 'development' ? WEBSITE_USER_PASSWORDS : [];

const initialState = () => {
  return {
    isSubmitting: false,
    username: '',
    password: '',
    portalID: -1,
    error: false,
    userError: false,
    passError: false,
  };
};

const Login = (props) => {
  useDocumentTitle('Login'); // Set page title

  const mountedRef = useRef(true);
  const classes = useStyles();
  const { global, currentUser } = store.getState().reducer;
  const [currentLogin, setCurrentLogin] = useState('');
  const [state, setState] = useState(initialState);

  let username = useInputValue(state.username, state.userError);
  let password = useInputValue(state.password, state.passError);

  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  useEffect(() => {
    props.history.push('/login');
    store.dispatch({ type: 'SET_SHOW_LOGIN', payload: false });

    if (!mountedRef.current) return null;
    setState({ ...state, portalID: props.portalID });
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleTestLoging = (e) => {
    let login = logingForTestingOnly.filter((item) => item.username === e.username)[0];
    username.onChange({ target: { value: e.username } });
    password.onChange({ target: { value: e.password } });
    setCurrentLogin(login);
  };

  const loginUser = async (loginPayload) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginPayload),
    };

    let endPoint =
      isIOS && isMobile
        ? `${SERVICE_URL}/users/authenticateWithLocalStorage`
        : `${SERVICE_URL}/users/portal/${global.portalAliasHashed}/authenticate`;

    return await fetch(endPoint, requestOptions)
      .then((res) => {
        if (res.ok && isIOS && isMobile) {
          localStorage.setItem('NSRefreshToken', res.headers.get('X-Refresh-Token') || null);
          localStorage.setItem('NSUserID', res.headers.get('X-User-Id') || null);
        }
        return res;
      })
      .then(handleResponse)
      .then(({ currentUser: loggedUser, token, needServiceAgreementSigned }) => {
        store.dispatch({
          type: 'PUSH_ALERT',
          payload: {
            open: true,
            color: 'success',
            message: 'Login was successful!',
            onLogin: true,
          },
        });
        return { loggedUser, token, needServiceAgreementSigned };
      })
      .catch((error) => {
        let errorMsg = error.title || error.message || error;
        store.dispatch({
          type: 'PUSH_ALERT',
          payload: {
            open: true,
            color: 'error',
            message: '[Replace]' + errorMsg,
            keepOpen: true,
          },
        });
        setState({
          ...state,
          userError: error.errors?.Username,
          passError: error.errors?.Password,
          isSubmitting: false,
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({ ...state, isSubmitting: true });
    const { isSubmitting, ...payload } = { ...state };
    payload.username = username.value;
    payload.password = password.value;

    try {
      //loginUser action makes the request and handles all the neccessary state changes
      let response = await loginUser({
        username: payload.username,
        password: payload.password,
      });

      if (!response) return;

      setTimeout(() => {
        store.dispatch({ type: 'CLEAR_ALERTS' });

        if (response?.needServiceAgreementSigned) {
          props.history.push('/agent-agreement');
        } else {
          props.history.push('/dashboard');
        }

        localStorage.setItem('NSUserInfo', JSON.stringify(response.loggedUser));

        store.dispatch({
          type: 'LOGIN_SUCCESS',
          payload: { ...response.loggedUser, needServiceAgreementSigned: response?.needServiceAgreementSigned },
          onLogin: true,
        });
        store.dispatch({
          type: 'SET_GLOBALS',
          payload: {
            isAuthenticated: true,
            portalID: global.portalID,
            superUserId: global.superUserId,
            portalName: global.portalName,
            email: global.email,
          },
        });
        store.dispatch({
          type: 'SET_ACCESS_TOKEN',
          payload: { token: response.token, onLogin: true },
        });
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        {!currentUser.detail && (
          <React.Fragment>
            <div className="portalLogo">
              {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
            </div>
            <Card>
              <Typography variant="h4">Login</Typography>
              {BUILD_ENV === 'development' && (
                <div>
                  <FormControl>
                    <InputLabel id="logins">Login (For Testing Only)</InputLabel>
                    <Select
                      id="logins"
                      label="User Login"
                      value={currentLogin}
                      onChange={(e) => handleTestLoging(e.target.value)}
                    >
                      {logingForTestingOnly.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <Box component="form" onSubmit={handleSubmit}>
                <FormControl error={username.error ? true : false}>
                  <InputLabel htmlFor="username-input">Username</InputLabel>
                  <OutlinedInput id="username-input" label="Username" {...username} />
                  <FormHelperText id="username-helper-text">{username.error ? state.userError[0] : ''}</FormHelperText>
                </FormControl>
                <FormControl error={password.error ? true : false}>
                  <InputLabel htmlFor="password-input">Password</InputLabel>
                  <OutlinedInput type="password" id="password-input" label="Password" {...password} />
                  <FormHelperText id="password-helper-text">{password.error ? state.passError[0] : ''}</FormHelperText>
                </FormControl>
                <br />
                <Button size="large" fullWidth variant="contained" type="submit" disabled={state.isSubmitting}>
                  Login {state.isSubmitting && <Loading forButton lpad />}
                </Button>
                <br />
                <div className="forgot-password">
                  <div>Forgot Password?</div>
                  <Link className="link" to={{ pathname: '/forgot_agent_password' }}>
                    {'I am an agent'}
                  </Link>
                  <label className="separator">|</label>
                  <Link className="link" to={{ pathname: '/forgot_customer_password' }}>
                    {'I am a customer'}
                  </Link>
                </div>
              </Box>
            </Card>
            <br />
            <Card style={{ textAlign: 'center' }}>
              <div className="need-register">Need to Register?</div>
              <Link
                className="link"
                to={{
                  pathname: '/agent-registration',
                }}
              >
                {'Agent registration'}
              </Link>

              <label className="separator">|</label>

              <Link
                className="link"
                to={{
                  pathname: '/customer-registration',
                }}
              >
                {'Customer registration'}
              </Link>
            </Card>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Login;
