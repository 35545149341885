import React, { useContext, useEffect } from 'react';
import { Card } from '@mui/material';
import { useSelector } from 'react-redux';

import { UploadFilesItem } from './upload-files-item';
import { UploadFilesItemMobile } from './upload-files-item-mobile';
import { StyledSavedFiles } from './style';
import { EmptyList } from '../others/EmptyList';
import Loading from '../others/Loading';
import { FileUploadContext } from './FileUploadContext';
import { store } from '../../../store';

const cols = [
  {
    id: 1,
    field: 'name',
    name: 'Name',
    type: 'Link',
    flex: 3,
  },
  {
    id: 2,
    field: 'description',
    name: 'Description',
    type: 'TextField',
    flex: 3,
  },
  {
    id: 3,
    field: 'isLoan',
    name: 'Loan',
    type: 'Checkbox',
    shared: true,
    flex: 0.5,
  },
  {
    id: 4,
    field: 'isTitle',
    name: 'Title',
    type: 'Checkbox',
    shared: true,
    flex: 0.5,
  },
  {
    id: 5,
    field: 'isOthers',
    name: 'Other',
    type: 'Checkbox',
    shared: true,
    flex: 0.5,
  },
  {
    id: 6,
    field: 'lastModified',
    name: 'Uploaded Date',
    type: 'DateTime',
    shared: true,
    flex: 1.5,
  },
];

export const UploadFilesDialogSaved = ({ savedFiles }) => {
  const { isGettingFiles, canEdit, fileContext } = useContext(FileUploadContext);
  const viewport = store.getState().reducer.appViewport;
  const { customColor: appColor } = useSelector((state) => state.reducer.themeAppearance);

  const getColumns = () => {
    if (fileContext.uploadDocType == 'SigningFiles') {
      return cols;
    } else if (fileContext.uploadDocType == 'ScanBacks') {
      const excludes = ['description', 'isLoan', 'isTitle', 'isOthers', 'size'];
      return cols.filter((item) => !excludes.includes(item.field));
    } else {
      const excludes = ['description', 'isLoan', 'isTitle', 'isOthers', 'size'];
      return cols.filter((item) => !excludes.includes(item.field));
    }
  };

  return (
    <StyledSavedFiles
      id="savedFiles"
      $priColor={appColor}
      $scanback={fileContext.uploadDocType == 'ScanBacks' ? true : false}
    >
      {['xs', 'sm'].includes(viewport) ? (
        <>
          {isGettingFiles ? (
            <Loading forList msg={'Getting Files...'} />
          ) : (
            savedFiles?.length == 0 && <EmptyList msg={'No files were found.'} />
          )}
          {savedFiles?.map((row, index) => (
            <UploadFilesItemMobile key={row.docId} canEdit={canEdit} columns={getColumns()} row={row} />
          ))}
        </>
      ) : (
        <Card elevation={1}>
          <div>
            <div className="table-header">
              {getColumns().map((column, index) => (
                <div key={index} style={{ flex: column.flex, display: column.display }} className="col">
                  <label>{column.name}</label>
                </div>
              ))}
              <div style={{ flex: 0.5 }} className="col">
                <label>{}</label>
              </div>
            </div>
            {savedFiles?.map((row, index) => (
              <div key={row.docId || row.id} className="table-body">
                <UploadFilesItem canEdit={canEdit} columns={getColumns()} row={row} />
              </div>
            ))}
          </div>
          {isGettingFiles ? (
            <Loading forList msg={'Getting Files...'} />
          ) : (
            savedFiles?.length == 0 && <EmptyList msg={'No files were found.'} />
          )}
        </Card>
      )}
    </StyledSavedFiles>
  );
};
