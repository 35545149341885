import React, { Fragment, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import ContactManagerProvider, { ContactManagerContext } from '../context/contact-manager.context';
import ListOptions from '../components/contact-manager/list/ListOptions';
import DataList from '../components/contact-manager/list/DataList';
import UserProfile from './UserProfile';
import Breadcrumb from '../components/contact-manager/Breadcrumb';
import Filters from '../components/contact-manager/filter/Filters';
import Show from '../components/common/others/Show';

function AgentProfileFomOpenBtn() {
  return null;
}

const ContactManager = (props) => {
  const [breadcrumbState, setBreadcrumbState] = useState();

  return (
    <Route
      path="/contact-manager"
      render={({ match: { path } }) => (
        <ContactManagerProvider fromContactManager>
          {props.history.location.pathname !== '/contact-manager' && (
            <Breadcrumb breadCrumbEvent={setBreadcrumbState} />
          )}
          <Switch>
            <Route exact path={path + '/'}>
              <ContactManagerContext.Consumer>
                {(context) => {
                  return (
                    <Fragment>
                      <Filters context={context} disableListRefrehOnProximity style={{ margin: '7px 0px 10px' }} />
                      <Show when={context.filterPayLoad.listSelection == 'Customer'}>
                        <Button
                          sx={{ display: { xs: 'flex', sm: 'none' }, mt: 1 }}
                          id="add-new-user"
                          fullWidth
                          variant="contained"
                          endIcon={<AddIcon />}
                          onClick={(e) => {
                            props.history.push(`/contact-manager/profile/customer/new`);
                            e.stopPropagation();
                          }}
                        >
                          Add New Customer
                        </Button>
                      </Show>

                      <ListOptions {...props} />
                      <DataList reload={breadcrumbState?.stopDataRefresh ? false : true} />
                    </Fragment>
                  );
                }}
              </ContactManagerContext.Consumer>
            </Route>
            <Route path={`${path}/profile/:profileType/:profileId`}>
              <UserProfile history={props.history} fromManager />
            </Route>
          </Switch>
        </ContactManagerProvider>
      )}
    />
  );
};

export default ContactManager;
