import React, { useRef } from 'react';
import { F } from '../../../helpers/functions';
import { isMobile } from 'react-device-detect';

const ScrollableIframe = ({ src, onScrollNearEnd = F, threshold = 100, ...props }) => {
  const iframeRef = useRef(null);

  const mapToValidUrl = (url) => {
    if (isMobile) {
      const encodedUrl = encodeURIComponent(url);
      return `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
    }

    return url;
  };

  return (
    <iframe
      ref={iframeRef}
      src={mapToValidUrl(src)}
      style={{ width: '100%', height: '100%', border: 'none' }}
      {...props}
    />
  );
};

export default ScrollableIframe;
