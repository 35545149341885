import React from 'react';
import { IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';

import { LogWrapper, LogChip } from './Style';
import { RequestLogContext as contextLog } from '../../../context/request-log.context';
import { sanitizeHTML } from '../../../helpers';

const Log = ({ log }) => {
  const context = React.useContext(contextLog);
  const { logInEditMode, logInNewMode, setLogInEditMode } = context;

  const [expanded, setExpanded] = React.useState(false);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEdit = () => {
    handlePanelChange(`panel${log.logSequence}`);
    setLogInEditMode(log.logSequence);
  };

  return (
    <LogWrapper>
      <Accordion
        expanded={expanded === `panel${log.logSequence}`}
        onChange={handlePanelChange(`panel${log.logSequence}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${log.logSequence}bh-content`}
          id={`panel${log.logSequence}bh-header`}
        >
          <div
            className={`summaryWrapper ${
              expanded === `panel${log.logSequence}` ? 'expanded' : ''
            }`}
          >
            <div className={'labelAndTimeAgo'}>
              <LogChip
                className={log.logType}
                label={log.logType}
                size="small"
              />
              {expanded === `panel${log.logSequence}` ? (
                <div>
                  <p>
                    {moment(log.logDateTime).format(
                      'MM/DD/YYYY h:mm:ss A (ddd)'
                    )}
                  </p>
                </div>
              ) : (
                <div>{moment(log.logDateTime).fromNow()}</div>
              )}
            </div>
            <div className="heading">
              <p>
                {expanded !== `panel${log.logSequence}` && <>{log.logText} </>}
                {log.byUser && (
                  <i
                    style={{
                      fontSize: '.75rem',
                      top: '-1px',
                      position: 'relative',
                    }}
                    className="logByUser"
                  >{`(By ${log.byUser})`}</i>
                )}
              </p>
            </div>
            <div className="edit">
              {!log.isLegacy && log.canEdit && (
                <IconButton
                  id="fade-button"
                  size="small"
                  disabled={logInEditMode || logInNewMode ? true : false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit();
                  }}
                >
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ whiteSpace: 'pre-line' }}>
            {log.isLegacy ? (
              <div dangerouslySetInnerHTML={sanitizeHTML(log.logText)} />
            ) : (
              <>{log.logText}</>
            )}
            {log.withHtml && (
              <div dangerouslySetInnerHTML={sanitizeHTML(log.logHtml)} />
            )}
          </div>
          <div>
            <div>
              <div>
                <b>View By Customer: </b>
              </div>
              <div>{log.viewByCustomer ? ' Yes' : ' No'}</div>
            </div>
            <div>
              <div>
                <b>View By Agent: </b>
              </div>
              <div>{log.viewByAgent ? ' Yes' : ' No'}</div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </LogWrapper>
  );
};

Log.propTypes = {};

export default Log;
