import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { F } from '../../../helpers/functions';

export default function ModalDialog({ children, open = false, handleCloseEvent = F, ...others }) {
  const propsSkipCustomHandlers = Object.fromEntries(
    Object.entries(others).filter(([key]) => !key.startsWith('handle'))
  );

  return (
    <Dialog
      onClose={handleCloseEvent}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...propsSkipCustomHandlers}
    >
      {children}
    </Dialog>
  );
}
