import React, { useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Card, DialogContent, DialogTitle, Divider } from '@mui/material';
import PatternFormat from 'react-number-format';
import { adminToolsService as service } from '../../../services';
import Loading from '../../common/others/Loading';
import { dispatchAlert, dispatchWarningAlert } from '../../../helpers';
import BasicTable from './DataTable';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import { NSDatePicker } from '../../common/pickers/NSDatePicker';

const StyledCard = styled(Card)(
  ({ theme }) => css`
    flex-grow: 1;
    display: flex;
    /* height: 600px; */

    .tab-panel-content {
      padding: 15px;
      flex: 2;

      .input-group {
        margin-bottom: 10px;
        max-width: 300px;

        p {
          font-weight: 500;
        }
      }
    }
  `
);

function TabPanel(props) {
  const { children, value, index, tool, ...other } = props;

  return (
    <div
      className="tab-panel-content"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="h6">{tool?.toolName}</Typography>
          <Divider />
          {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    className: 'tab-panel-button',
    key: index,
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function TechTools() {
  const isMounted = useRef(true);
  const [toolTabs, setToolTabs] = useState([]);
  const [currentForm, setCurrentForm] = useState({});
  const [value, setValue] = useState(0);
  const [apiLoading, setApiLoading] = useState(false);
  const [showDialogWithData, setShowDialogWithData] = useState(false);

  useEffect(() => {
    service.getTechTools().then((res) => {
      setToolTabs(res.techTools);
      handleChange(null, 0);
    });

    return () => {};
  }, []);

  const handleChange = (event, newValue) => {
    isMounted.current = true;
    setValue(newValue);
    setApiLoading(false);
    setShowDialogWithData(false);

    switch (newValue) {
      case 0:
        setCurrentForm({ copyFromPortalId: null, replaceToPortalId: null });
        break;
      case 1:
        setCurrentForm({ portalID: null });
        break;
      case 2:
        setCurrentForm({ portalID: null });
        break;
      case 3:
        setCurrentForm({ portalID: null });
        break;
      case 4:
        setCurrentForm({ requestNumber: null, portalID: null });
        break;
      case 5:
        setCurrentForm({ searchMonth: null });
        break;
      case 6:
        setCurrentForm({ dateInTheMth: null });
        break;
      case 7:
        setCurrentForm({ userID: null, status: null });
        break;
      case 8:
        setCurrentForm({ portalID: null, userID: null });
        break;
      case 9:
        setCurrentForm({ portalID: null, userID: null });
        break;
      // case 10:
      //   setCurrentForm({ null });
      //   break;
      // case 11:
      //   setCurrentForm({ null });
      //   break;
      case 12:
        setCurrentForm({ userLoginName: null });
        break;
      case 13:
        setCurrentForm({ userIdentifier: null });
        break;
      default:
        setCurrentForm({});
        break;
    }
  };

  const formValidator = (tool, property) => {
    let isValid = currentForm[property]?.length > 0 ? true : false;

    return {
      required: true,
      error: isValid ? false : true,
      helperText: isValid ? '' : 'Input is required.',
    };
  };

  const handleSubmit = async (event, getTable, tool) => {
    event.preventDefault();
    const formElement = event.target;
    const isValid = formElement.checkValidity();
    if (!isValid) return;

    setCurrentForm((prevState, props) => ({ ...prevState })); // this will force re-render

    setApiLoading(true);
    let apiBody = { ...tool, toolParams: currentForm };
    await service
      .runTechTool(getTable, apiBody)
      .then((res) => {
        if (res) {
          setShowDialogWithData(res);
          dispatchAlert('[Replace]The tool has completed successfully.');
        } else {
          if (getTable) {
            dispatchWarningAlert('[Replace]The tool has completed successfully with no response.');
          } else dispatchAlert('[Replace]The tool has completed successfully.');
        }
      })
      .catch((error) => {
        dispatchAlert('[Replace]' + error, true);
      });
    setApiLoading(false);
  };

  if (toolTabs.length == 0) return <Loading forPage />;

  const dataTableDialog = (tool) => {
    let showDialog = showDialogWithData ? true : false;
    return (
      showDialog && (
        <Dialog
          disableEscapeKeyDown
          fullScreen={Object.keys(showDialogWithData[0]).length > 20 ? true : false}
          maxWidth={Object.keys(showDialogWithData[0]).length < 10 ? 'lg' : 'xl'}
          open={showDialog}
          onClose={(event, reason) => {
            if (reason == 'backdropClick') return;
            setApiLoading(false);
          }}
          TransitionComponent={Transition}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: '10px 20px !important' }}>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {tool?.toolName}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => setShowDialogWithData(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: '15px !important', height: '100vh' }}>
            <BasicTable data={showDialogWithData} />
          </DialogContent>
        </Dialog>
      )
    );
  };

  const submitButtom = (
    <LoadingButton
      type="submit"
      variant="contained"
      endIcon={<SubdirectoryArrowRightOutlinedIcon />}
      loadingPosition="end"
      loading={apiLoading}
    >
      <span>Run</span>
    </LoadingButton>
  );

  // Copy Portal Defaults
  const renderTool_0 = () => {
    let index = 0;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, false, tool)}
        >
          <div className="input-group">
            <TextField
              label="Copy From: PortalID"
              {...formValidator(tool, 'copyFromPortalId')}
              value={currentForm.copyFromPortalId}
              onChange={(e) => setCurrentForm({ ...currentForm, copyFromPortalId: e.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              label="Replace To: PortalID"
              {...formValidator(tool, 'replaceToPortalId')}
              value={currentForm.replaceToPortalId}
              onChange={(e) => setCurrentForm({ ...currentForm, replaceToPortalId: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
      </TabPanel>
    );
  };

  // New Site Prep - ServiceOwner
  const renderTool_1 = () => {
    let index = 1;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, false, tool)}
        >
          <div className="input-group">
            <TextField
              label="PortalID"
              {...formValidator(tool, 'portalID')}
              value={currentForm.portalID}
              onChange={(e) => setCurrentForm({ ...currentForm, portalID: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
      </TabPanel>
    );
  };

  // Portal Tabs Setup
  const renderTool_2 = () => {
    let index = 2;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, false, tool)}
        >
          <div className="input-group">
            <TextField
              label="Portal ID"
              {...formValidator(tool, 'portalID')}
              value={currentForm.portalID}
              onChange={(e) => setCurrentForm({ ...currentForm, portalID: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
        {dataTableDialog(tool)}
      </TabPanel>
    );
  };

  // Database Find Deleted Requests
  const renderTool_3 = () => {
    let index = 3;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <TextField
              label="Portal ID"
              {...formValidator(tool, 'portalID')}
              value={currentForm.portalID}
              onChange={(e) => setCurrentForm({ ...currentForm, portalID: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
        {dataTableDialog(tool)}
      </TabPanel>
    );
  };

  // Database UnDelete Request
  const renderTool_4 = () => {
    let index = 4;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, false, tool)}
        >
          <div className="input-group">
            <TextField
              label="Client Request Number"
              {...formValidator(tool, 'requestNumber')}
              value={currentForm.requestNumber}
              onChange={(e) => setCurrentForm({ ...currentForm, requestNumber: e.target.value })}
            />
            <TextField
              label="Portal ID"
              {...formValidator(tool, 'portalID')}
              value={currentForm.portalID}
              onChange={(e) => setCurrentForm({ ...currentForm, portalID: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
      </TabPanel>
    );
  };

  // Get Expiring Memberships
  const renderTool_5 = () => {
    let index = 5;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <PatternFormat
              aria-invalid={false}
              label={'Month (08/2009)'}
              {...formValidator(tool, 'searchMonth')}
              customInput={TextField}
              format="##/####"
              // allowEmptyFormatting
              mask="_"
              value={currentForm.searchMonth}
              onValueChange={({ formattedValue, value, floatValue }) => {
                setCurrentForm({ ...currentForm, searchMonth: formattedValue });
              }}
            />
          </div>
          {submitButtom}
        </Box>
        {dataTableDialog(tool)}
      </TabPanel>
    );
  };

  // Invoice List For Mth
  const renderTool_6 = () => {
    let index = 6;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <NSDatePicker
              label="Date in the Month"
              {...formValidator(tool, 'dateInTheMth')}
              date={currentForm.dateInTheMth}
              emitDate={(date) => {
                setCurrentForm({ ...currentForm, dateInTheMth: date });
              }}
            />
          </div>
          {submitButtom}
        </Box>
        {dataTableDialog(tool)}
      </TabPanel>
    );
  };

  // Profile Activate/Deactive User
  const renderTool_7 = () => {
    let index = 7;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '30ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <TextField
              label="UserID"
              {...formValidator(tool, 'userID')}
              value={currentForm.userID}
              onChange={(e) => setCurrentForm({ ...currentForm, userID: e.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              label="1 (deactivate) or 0 (activate)"
              {...formValidator(tool, 'status')}
              value={currentForm.status}
              onChange={(e) => setCurrentForm({ ...currentForm, status: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
      </TabPanel>
    );
  };

  // Profile Authorize User
  const renderTool_8 = () => {
    let index = 8;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <TextField
              label="PortalID"
              {...formValidator(tool, 'portalID')}
              value={currentForm.portalID}
              onChange={(e) => setCurrentForm({ ...currentForm, portalID: e.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              label="UserID"
              {...formValidator(tool, 'userID')}
              value={currentForm.userID}
              onChange={(e) => setCurrentForm({ ...currentForm, userID: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
      </TabPanel>
    );
  };

  // Profile DeAuthorize User
  const renderTool_9 = () => {
    let index = 9;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <TextField
              label="PortalID"
              {...formValidator(tool, 'portalID')}
              value={currentForm.portalID}
              onChange={(e) => setCurrentForm({ ...currentForm, portalID: e.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              label="UserID"
              {...formValidator(tool, 'userID')}
              value={currentForm.userID}
              onChange={(e) => setCurrentForm({ ...currentForm, userID: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
      </TabPanel>
    );
  };

  // Profile Find Dups User
  const renderTool_10 = () => {
    let index = 10;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            endIcon={<SubdirectoryArrowRightOutlinedIcon />}
            loadingPosition="end"
            loading={apiLoading}
          >
            <span>Pull Data</span>
          </LoadingButton>
          {dataTableDialog(tool)}
        </Box>
      </TabPanel>
    );
  };

  // Profile Find Invalid Data
  const renderTool_11 = () => {
    let index = 11;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            endIcon={<SubdirectoryArrowRightOutlinedIcon />}
            loadingPosition="end"
            loading={apiLoading}
          >
            <span>Pull Data</span>
          </LoadingButton>
          {dataTableDialog(tool)}
        </Box>
      </TabPanel>
    );
  };

  // Profile Find User By Login
  const renderTool_12 = () => {
    let index = 12;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '25ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <TextField
              label="Login Used"
              {...formValidator(tool, 'userLoginName')}
              value={currentForm.userLoginName}
              onChange={(e) => setCurrentForm({ ...currentForm, userLoginName: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
        {dataTableDialog(tool)}
      </TabPanel>
    );
  };

  // Profile User Role Review
  const renderTool_13 = () => {
    let index = 13;
    let tool = toolTabs[index];
    return (
      <TabPanel value={value} index={index} tool={tool}>
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { width: '45ch' } }}
          noValidate
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e, true, tool)}
        >
          <div className="input-group">
            <TextField
              label="First or Last Name, Username, UserID or Email:"
              {...formValidator(tool, 'userIdentifier')}
              value={currentForm.userIdentifier}
              onChange={(e) => setCurrentForm({ ...currentForm, userIdentifier: e.target.value })}
            />
          </div>
          {submitButtom}
        </Box>
        {dataTableDialog(tool)}
      </TabPanel>
    );
  };

  return (
    <StyledCard>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {toolTabs.map((item, index) => (
          <Tab sx={{ alignItems: 'flex-start' }} label={item.toolName} {...a11yProps(item.sortOrder)} />
        ))}
      </Tabs>
      {renderTool_0()}
      {renderTool_1()}
      {renderTool_2()}
      {renderTool_3()}
      {renderTool_4()}
      {renderTool_5()}
      {renderTool_6()}
      {renderTool_7()}
      {renderTool_8()}
      {renderTool_9()}
      {renderTool_10()}
      {renderTool_11()}
      {renderTool_12()}
      {renderTool_13()}
    </StyledCard>
  );
}
