import React from 'react';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { sharedService } from './services';

const StyledErrorBoundery = styled(Stack)(
  ({ theme, $appRoot }) => css`
    width: 100%;
    /* margin-top: ${$appRoot ? '50px' : '10px'}; */
    padding: ${$appRoot ? '10px' : '0px'};

    .MuiAlert-root {
      width: 100%;

      .MuiAlert-message {
        width: inherit;
      }
    }
  `
);

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // const userRoles = store.getState().reducer.currentUser.userRoles;
    // let isSuperUser = userRoles.includes('SuperUser') ? true : false
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      isSuperUser: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // You can also log error messages to an error reporting service here
    if (this.state.hasError) {
      let errorLog = `${error?.message}\n${errorInfo?.componentStack}`;
      // console.log(errorLog);
      try {
        sharedService.logClientError(errorLog);
      } catch (error) {
        // console.log(error);
      }
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <StyledErrorBoundery spacing={2} $appRoot={this.props.appRoot}>
          <Alert severity="error">
            <AlertTitle>
              <b>Something went wrong</b>
            </AlertTitle>
            <p>Try refreshing the page. If the problem persists, contact your administrator.</p>
            {this.props.appRoot && (
              <p style={{ marginTop: '15px' }}>
                <Button color="inherit" size="small" onClick={() => window.location.reload(false)}>
                  Refresh
                </Button>
              </p>
            )}

            {['development'].includes(BUILD_ENV) && (
              <>
                <br />
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error?.message}
                  {this.state.errorInfo?.componentStack}
                  <br />
                  {/* {this.state.error && this.state.error.toString()} */}
                  {/* {this.state.error?.message} */}
                  {/* {this.state.errorInfo?.componentStack} */}
                </details>
              </>
            )}
          </Alert>
          {/* <div style={{ fontWeight: 600, color: 'darkred' }}></div>
          <div style={{ fontSize: '0.8rem' }}>
            <i>
              Try refreshing the page. If the problem persists, contact your
              administrator.
            </i>
          </div>
          <div></div> */}
        </StyledErrorBoundery>
      );
    }

    return this.props.children;
  }
}
