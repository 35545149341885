import React, { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import UserAdminList from '../components/user-admin/UserAdminList';
import UserAdminProvider from '../context/user-admin.context';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const UserAdmin = (props) => {
  useDocumentTitle('User Administration'); // Set page title
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <div className="view-title">User Administation</div>
        <UserAdminProvider>
          <UserAdminList {...props} />
        </UserAdminProvider>
      </div>
    </Fragment>
  );
};

export default UserAdmin;
