import React, { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';

const StyledSimpleInput = styled(Card)(
  ({ theme }) => css`
    padding: 15px;

    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    .actions {
      margin-top: 10px;
      justify-content: start;
      display: flex;

      .cancel {
        min-height: 30px;
        width: 50%;
      }

      .apply {
        min-height: 30px;
        margin-left: 5px;
        width: 100%;
      }
    }

    .rating {
      display: flex;
      justify-content: center;
    }
  `
);

const Simple = (props) => {
  const [state, setState] = useState({
    id: props.id,
    label: props.label,
    type: props.type,
    value: props.value,
    dataType: props.dataType,
    inputProps: props.inputProps,
  });

  useEffect(() => {
    setState({
      id: props.id,
      label: props.label,
      type: props.type,
      value: props.value,
      dataType: props.dataType,
      inputProps: props.inputProps,
    });
    return () => {};
  }, [props]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.applyFilter(state);
  };

  const handleChange = (e) => {
    let chatLimitOr = state.inputProps?.maxLength || 100;
    if (e.target.value.toString().length <= chatLimitOr) {
      setState({ ...state, value: e.target.value });
    }
  };

  return (
    <StyledSimpleInput>
      <form onSubmit={onSubmit}>
        {/* <FormControl variant="outlined">
          <InputLabel htmlFor="simple-input">{props.label}</InputLabel>
          <OutlinedInput
            id="simple-input"
            aria-describedby=""
            inputProps={{
              autoComplete: 'off',
              form: {
                autoComplete: 'off',
              },
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13) onSubmit(e);
            }}
            autoFocus
            label={props.label}
            type={state.dataType || 'text'}
            value={state.value || ''}
            onChange={handleChange}
          />
        </FormControl> */}
        <TextField
          autoFocus
          inputProps={{
            autoComplete: 'off',
            form: {
              autoComplete: 'off',
            },
          }}
          onKeyDown={(e) => {
            if (e.keyCode == 13) onSubmit(e);
          }}
          label={props.label}
          type={state.dataType || 'text'}
          value={state.value || ''}
          onChange={handleChange}
        />
        <br />
        <div className="actions">
          <Button
            className="cancel"
            color="default"
            onClick={() => props.applyFilter(false)}
          >
            Cancel
          </Button>
          <Button
            className="apply"
            type="submit"
            onClick={() => props.applyFilter(state)}
          >
            Apply Filter
          </Button>
        </div>
      </form>
    </StyledSimpleInput>
  );
};

export default Simple;
