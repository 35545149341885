import React, { useCallback, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Button, Menu, Box, Avatar, ListItemIcon, ButtonGroup, ListItemText } from '@mui/material';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';

import { css, useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';
import { store } from '../../../store';
import { userService } from '../../../services';
import { useDispatch } from 'react-redux';
import { appThemes, appColors } from '../../../theme/AppThemes';
import { useDirtyChangesPrompt } from '../../../helpers/hooks/useDirtyChangesPrompt';
import { useDirtyChangesStore } from '../../../context/dirty-changes.store';

const StyledMenu = styled(Menu)(
  ({ theme }) => css`
    & .menuItem {
      .MuiButtonGroup-grouped {
        min-width: 30px;
      }

      .activeBtnInGroup {
        color: ${theme.palette.primary.main};
      }

      .MuiMenuItem-root {
        justify-content: space-between;
      }

      .menuItemLabel {
        display: flex;
        min-width: 150px;
        align-items: center;
      }

      .MuiListItemIcon-root {
        min-width: 30px;
      }

      .menu-list-box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  `
);

const MeNavBarDisplay = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const currentAppThemeFullName = theme.palette.typeFullName;
  const currentPrimaryAppColor = theme.palette.primary.main;

  const { mixedRoute, currentUser } = store.getState().reducer;
  const [anchorEl, setAnchorEl] = useState(null);
  const [localize, setLocalize] = useState('English');

  const isDirty = useDirtyChangesStore((s) => s.isDirty);
  const { promptDirtyChanges } = useDirtyChangesPrompt();

  const openMenu = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    promptDirtyChanges(
      {},
      () => {
        userService.logout().then((res) => {
          if (!mixedRoute) history.push('/login');
        });
      },
      isDirty
    );
  }, [isDirty]);

  const handleProfile = useCallback(() => {
    promptDirtyChanges(
      {},
      () => {
        history.push(
          currentUser.userRoles.includes('Customer')
            ? `/profile/customer/${currentUser.detail.userID}`
            : `/profile/agent/${currentUser.detail.publicID}`
        );
      },
      isDirty
    );
  }, [isDirty]);

  const languageButtons = [
    <Button
      color={'default'}
      key="en"
      onClick={(e) => {
        setLocalize('English');
        i18n.changeLanguage('en');
      }}
    >
      <span className={`${localize === 'English' ? 'activeBtnInGroup' : ''}`}>EN</span>
    </Button>,
    <Button
      color={'default'}
      key="es"
      onClick={(e) => {
        setLocalize('Spanish');
        i18n.changeLanguage('es');
      }}
    >
      <span className={`${localize === 'Spanish' ? 'activeBtnInGroup' : ''}`}>ES</span>
    </Button>,
  ];

  const profileImage = `https://notarycafe.com/Content/profile/${currentUser.detail.publicID}_1.png`;

  return (
    <React.Fragment>
      <Box>
        <Button
          color="inherit"
          onClick={handleMenu}
          startIcon={
            currentUser.userRoles.includes('NotaryAgent') && (
              <Avatar sx={{ width: 30, height: 30 }} variant="rounded" src={profileImage} />
            )
          }
        >
          {currentUser.detail.firstName + ' ' + currentUser.detail.lastName}
        </Button>
      </Box>
      <StyledMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose} onClick={handleClose} variant="menu">
        <div className="menuItem">
          {!(currentUser.userRoles.includes('SuperUser') || currentUser.userRoles.includes('ServiceOwner')) && (
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <SettingsOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile Settings</ListItemText>
            </MenuItem>
          )}
          <MenuItem>
            <div className="menuItemLabel">
              <ListItemIcon>
                <TranslateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Language</ListItemText>
            </div>
            <Box className="menu-list-box">
              <ButtonGroup disableElevation size="small">
                {languageButtons}
              </ButtonGroup>
            </Box>
          </MenuItem>
          <MenuItem>
            <div className="menuItemLabel">
              <ListItemText>Dark/Light Theme</ListItemText>
            </div>
            <Box className="menu-list-box">
              <ButtonGroup disableElevation size="small">
                {Object.keys(appThemes).map((theme, index) => {
                  let defaultActiveColor = currentAppThemeFullName === theme ? 'primary' : 'default';
                  return (
                    <Button
                      key={index}
                      color="default"
                      onClick={(e) => {
                        dispatch({
                          type: 'SET_CURRENT_THEME',
                          payload: { customTheme: theme },
                        });
                      }}
                    >
                      {theme === 'darkTheme2' && <DarkModeOutlinedIcon fontSize="small" color={defaultActiveColor} />}
                      {theme === 'lightTheme' && <LightModeOutlinedIcon fontSize="small" color={defaultActiveColor} />}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          </MenuItem>
          <MenuItem>
            <div className="menuItemLabel">
              <ListItemText>Appearance</ListItemText>
            </div>
            <Box className="menu-list-box">
              <ButtonGroup disableElevation size="small" sx={{ minWidth: '30px' }}>
                {Object.keys(appColors).map((color, index) => {
                  return (
                    <Button
                      key={index}
                      color="default"
                      onClick={(e) => {
                        dispatch({
                          type: 'SET_CURRENT_THEME',
                          payload: { customColor: color },
                        });
                      }}
                      sx={{ paddingLeft: '2px', paddingRight: '2px' }}
                    >
                      {currentPrimaryAppColor === theme.palette.primary.main ? (
                        <CircleIcon
                          sx={{
                            width: '0.85em',
                            height: '0.87em',
                            color: appColors[color].palette.primary.main,
                            border: `1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`,
                            borderRadius: '50%',
                          }}
                        />
                      ) : (
                        <CircleIcon
                          sx={{
                            width: '0.8em',
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>
          </MenuItem>
          {/* <MenuItem>
            <ListItemIcon>
              <SettingsOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </div>
      </StyledMenu>
    </React.Fragment>
  );
};

export default MeNavBarDisplay;
