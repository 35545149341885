import React, { memo, useEffect, useMemo, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, isBefore, parseISO, isValid } from 'date-fns';
import { CalendarIcon, ClearIcon, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import _isEqual from 'lodash/isEqual';

const Picker = ({
  style,
  margin,
  label,
  disabled,
  required,
  error,
  helperText,
  shrink = false,
  renderMobile = false,
  onChangeValidation,
  date,
  emitDate,
  getValidISODate,
  // timezone = 'system',
}) => {
  const [open, setOpen] = useState();
  const [state, setState] = useState(null);
  const [showError, setShowError] = useState(error);
  const [shrinkState, setShrinkState] = useState();

  useEffect(() => {
    if (date) {
      if (isValid(date)) {
        let _formatted = format(date, 'yyyy-MM-dd');
        setState(parseISO(_formatted));
        setShrinkState(true);
      } else {
        setState(parseISO(date));
      }
    }
  }, [date]);

  useEffect(() => {
    setShowError(error);
  }, [error]);

  const parseToValidDate = (val) => {
    let isInvalid = isValid(val) ? isBefore(val, parseISO('2000-01-01')) : true;
    let date = !isInvalid ? format(val, 'yyyy-MM-dd') : null;

    if (date == null && val !== '-1' && onChangeValidation) {
      setShowError(true);
    } else if (date == null && showError && !onChangeValidation) {
      setShowError(true);
    } else setShowError(false);

    return date;
  };

  // console.log('NSDatePicker');

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {renderMobile ? (
        <MobileDatePicker
          sx={style}
          label={label}
          disabled={disabled}
          slotProps={{
            actionBar: {
              actions: ['clear', 'cancel', 'accept'],
            },
            textField: {
              margin: margin,
              helperText: helperText,
              required: required,
              error: showError,
            },
          }}
          value={state}
          onChange={(date) => {
            setState(date);
            debouncedChangeHandler(date);
          }}
        />
      ) : (
        <DesktopDatePicker
          sx={style}
          label={label}
          disabled={disabled}
          // timezone={timezone}
          open={open}
          onClose={() => setOpen(false)}
          onAccept={(e) => setShrinkState(isValid(e))}
          slots={{ openPickerIcon: CalendarIcon, clearIcon: ClearIcon }}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => console.log('clearing date...'),
            },
            textField: {
              margin: margin,
              helperText: helperText,
              required: required,
              error: showError,
              onClick: () => setShrinkState(true),
              onBlur: () => setShrinkState(isValid(state) || state == 'Invalid Date'),
              InputLabelProps: { shrink: shrink || shrinkState },
            },
            popper: {
              onBlur: () => setOpen(false),
            },
          }}
          value={state}
          onChange={(date) => {
            setState(date);
            if (getValidISODate) return emitDate(date);
            else return emitDate(parseToValidDate(date));
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export const NSDatePicker = memo(Picker);
