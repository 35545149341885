import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import styled, { css } from 'styled-components';
import { NeutralButton } from '../../../Style';

const StyledButton = styled.div`
  ${(props) => css`
    display: inline-flex;

    button {
      min-width: auto;
    }
  `}
`;

const FilterBuilder = (props) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  return (
    <StyledButton id="addFilterRoot" ref={ref} style={props.style}>
      {/* <NeutralButton
        id="composition-button"
        color="btnNeutral"
        variant="contained"
        className="addFilter"
        aria-haspopup="true"
        startIcon={<FilterAltOutlinedIcon />}
        onClick={() => setShowOptions(true)}
      >
        {props.children}
      </NeutralButton> */}
      <Button
        id="composition-button"
        className="addFilter"
        aria-haspopup="true"
        // startIcon={<FilterAltOutlinedIcon />}
        // startIcon={<FilterListIcon />}
        onClick={() => setShowOptions(true)}
      >
        {props.children}
      </Button>
      {showOptions &&
        props.onShowOptions({ ref, setShowOptions, setShowOptions })}
    </StyledButton>
  );
};

export default FilterBuilder;
