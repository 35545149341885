export function plainErrorMsgHandler(obj) {
  const detailLine = `<p>${obj.detail}</p>`;
  const length = obj?.errors?.length;
  if (!length || length === 0) {
    // Return just the detail line if there are no errors.
    return detailLine;
  }

  const listItems = obj.errors.map((err) => `<li>${err.errorMessage || err.name}</li>`).join('');
  return `${detailLine}<ul style="padding-left: 25px;">${listItems}</ul>`;
}
