import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const keyOpencagedata = '103fda4edfee402fa23d1d1e4d742132';
const keyOpenweathermap = '1b825daf58d455d1161c0dd1607a1aec';

const requestOptions = {
  method: 'GET',
};

const getAddressOfCoordinates = (lat, lng) => {
  return fetch(
    `https://api.opencagedata.com/geocode/v1/json?key=${keyOpencagedata}&language=en&q=${lat}+${lng}`,
    requestOptions
  ).then(handleResponse);
};

const getCoordinatesOfAddress = (address) => {
  return fetch(
    `https://api.opencagedata.com/geocode/v1/json?key=${keyOpencagedata}&language=en&q=${address}`,
    requestOptions
  ).then(handleResponse);
};

const getWeatherAndForecast = (coordinates) => {
  return fetch(
    `https://api.openweathermap.org/data/2.5/onecall?lat=${coordinates.lat}&lon=${coordinates.lng}&exclude=minutely,hourly,alerts&units=imperial&appid=${keyOpenweathermap}`,
    requestOptions
  ).then(handleResponse);
};

const getTrivia = () => {
  return fetch(
    `https://opentdb.com/api.php?amount=1&difficulty=easy`,
    requestOptions
  ).then(handleResponse);
};

const getLinksByRoleName = async () => {
  const options = { headers: await authHeader() };
  const params = {};
  const url = `/dashboard/getLinksByRoleName`;
  return Fetch.get(url, params, options).then(handleResponse);
};

const getWelcomeByRoleName = async () => {
  const options = { headers: await authHeader() };
  const params = {};
  const url = `/dashboard/getWelcomeByRoleName`;
  return Fetch.get(url, params, options).then(handleResponse);
};

const getAgentAgreements = async () => {
  const options = { headers: await authHeader() };
  const params = {};
  const url = `/dashboard/getAgentAgreements`;
  return Fetch.get(url, params, options).then(handleResponse);
};

export const dashboardService = {
  getWeatherAndForecast,
  getAddressOfCoordinates,
  getCoordinatesOfAddress,
  getTrivia,
  getLinksByRoleName,
  getWelcomeByRoleName,
  getAgentAgreements,
};
