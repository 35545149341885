import React, { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Autocomplete, Button, Card, Divider, Stack, TextField, Typography } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import FilterApplyForm from '../../common/fancy-filter/FilterApplyForm';
import FilterMenuList from '../../common/fancy-filter/FilterMenuList';
import MainSearchInput from '../../common/fancy-filter/MainSearchInput';
import FilterBuilder from '../../common/fancy-filter/FilterBuilder';
import { AntSwitch, Div } from '../../../Style';

import AppliedFilters from '../../common/fancy-filter/AppliedFilters';
import _ from 'lodash';
import RegInforForm from './RegInfoForm';
import moment from 'moment';

const StyledFilters = styled(Div)(
  ({ theme, $withFilterApplied }) => css`
    padding: 20px 15px 15px;

    .addFilter,
    .btn {
      min-height: 36.5px;
    }

    .btn {
      min-width: 100px;
      max-height: 100px;
    }

    .switch-stack {
      position: relative;
      align-items: center;
      background-color: ${theme.palette.mode == 'light' ? '#e1e1ff' : ''};
      border-radius: 5px;
      width: 80px;
      min-width: 80px;

      > span {
        top: 4px;
        position: relative;
      }
    }

    .flex {
      width: 100%;
      display: flex;
      gap: ${$withFilterApplied ? 'initial' : '10px'};
    }

    .inputs {
      flex: 1;
      display: flex;
      gap: 10px;
      flex-basis: 100%;
      flex-wrap: wrap;

      .first-group {
        display: flex;
        width: 100%;
      }

      .main-input {
        width: 100%;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .search.sm,
    .search.md {
      display: none;
    }

    .search.xs {
      margin-top: 10px;
    }

    ${theme.breakpoints.up('sm')} {
      .search.xs {
        display: none;
      }

      .flex.sm {
        .buttons {
          flex-wrap: nowrap;
          gap: 10px;
        }

        .search {
          flex-basis: 100%;
        }

        .search.sm {
          display: block;
        }
      }
    }

    ${theme.breakpoints.up('md')} {
      .flex.sm {
        .search {
          flex-basis: auto;
          width: 100%;
          text-align: end;
        }

        .search.sm {
          display: none;
        }
      }

      .inputs {
        flex-wrap: nowrap;

        .first-group,
        .MuiAutocomplete-root {
          max-width: 370px;
        }

        .main-input {
          min-width: 215px;
        }
      }

      .search.md {
        display: block;
      }
    }

    ${theme.breakpoints.up('1200')} {
      .inputs {
        .main-input {
          min-width: 300px;
          max-width: 300px;
        }
      }
    }
  `
);

const initialFancyFilter = (input, filters) => {
  return {
    input: input, //this is the main input text
    filters: filters,
  };
};

const Filters = (props) => {
  const {
    fromAssignNotary,
    loadingDataList,
    setListMode,
    appliedFilters,
    setAppliedFilters,
    filterPayLoad,
    setFilterPayLoad,
    getContactList,
    contactMgtDDLOptions,
    proximityToggle,
    initialFilterPayload,
  } = props.context;

  let mainInput = filterPayLoad.onProximity ? filterPayLoad.zipSearch : filterPayLoad.multiSearch;

  const [fancyFilterData, setFancyFilterData] = useState(initialFancyFilter(mainInput, appliedFilters));

  useEffect(() => {
    setFancyFilterData({ ...fancyFilterData, input: mainInput, filters: appliedFilters });
    return () => {};
  }, [appliedFilters]);

  const getContactListHandler = (fancyFilters = []) => {
    let payload = { ...filterPayLoad };

    fancyFilters.map((filter) => {
      if (filter.value && filter.backendValProp && typeof filter.value === 'object') {
        return (payload[filter.id] = filter.value[filter.backendValProp]);
      } else if (filter.value) {
        return (payload[filter.id] = filter.value);
      } else {
        return (payload[filter.id] = initialFilterPayload[filter.id]);
      }
    });
    setFilterPayLoad(payload);
    getContactList(payload);
  };

  const fancyFilterEventHandler = async (data, type) => {
    if (type === 'filter' || type === 'delete') {
      let _filtersCopy = _.cloneDeep(fancyFilterData.filters);
      _filtersCopy.map((item) =>
        item.id == data.id
          ? ((item.value = data.value),
            (item.valueLabel = data.valueLabel),
            (item.lastEnteredTimestamp = moment().format('x')))
          : item
      );
      let filterData = { ...fancyFilterData, filters: _filtersCopy };
      setFancyFilterData(filterData);
      setAppliedFilters(filterData.filters);
      getContactListHandler(filterData.filters);
    } else {
      setFancyFilterData({ ...fancyFilterData, input: data });
      if (filterPayLoad.onProximity) {
        setFilterPayLoad({ ...filterPayLoad, zipSearch: data ? data : '', zipSearchHolder: data ? data : '' });
      } else {
        setFilterPayLoad({ ...filterPayLoad, multiSearch: data, multiSearchHolder: data });
      }
    }
  };

  const getCurrentAppliedFilter = () => {
    if (appliedFilters) {
      return appliedFilters.filter((item) =>
        item.useCustomForm ? item.value : (item.multiple && item.value.length) || (!item.multiple && item.value)
      );
    } else return [];
  };

  const getOptionsForDDL = () => {
    return contactMgtDDLOptions.filter((i) =>
      fromAssignNotary || filterPayLoad?.onAssignNotary ? i.id !== 8 : i.id !== 2
    );
  };

  const filterBuilder = (
    <FilterBuilder
      onShowOptions={({ ref, setShowOptions }) => (
        <FilterMenuList
          dense
          builderRef={ref}
          options={fancyFilterData.filters.filter(
            (item) =>
              !(item.multiple && (item.useCustomForm ? item.value : item.value.length)) &&
              !(!item.multiple && item.value)
          )}
          onClose={setShowOptions}
          onSelectCustomForm={(props) => {
            return (
              <RegInforForm
                menuRef={props.menuRef}
                form={props.openCustomForm}
                onClose={props.setOpenCustomForm}
                applyFilter={(data) => {
                  setShowOptions(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
          onSelectForm={(props) => {
            return (
              <FilterApplyForm
                menuRef={props.menuRef}
                form={props.openForm}
                onClose={props.setOpenForm}
                onChange={(data) => {
                  setShowOptions(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
        />
      )}
    >
      {getCurrentAppliedFilter().length ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
    </FilterBuilder>
  );

  return (
    <>
      <StyledFilters $withFilterApplied={getCurrentAppliedFilter().length > 0}>
        <div className="default-filters">
          <div className="inputs">
            <div className="first-group">
              <Stack className="switch-stack">
                <AntSwitch
                  inputProps={{ 'aria-label': 'ant design' }}
                  checked={filterPayLoad.onProximity}
                  onChange={(event) => {
                    setListMode('contacts');
                    let theZip = fromAssignNotary ? filterPayLoad.zipSearchHolder || filterPayLoad.zipSearch : '';
                    let mainInput = event.target.checked
                      ? theZip
                      : filterPayLoad.multiSearchHolder || filterPayLoad.multiSearch || '';

                    proximityToggle(
                      event.target.checked,
                      mainInput,
                      props.disableListRefrehOnProximity && event.target.checked && mainInput == '' ? false : true
                    );
                  }}
                />
                <Typography variant="body2">Proximity</Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
              <Autocomplete
                id="defaultFilteredGroup"
                options={getOptionsForDDL()}
                disableClearable
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => <li {...props}>{option.label}</li>}
                value={contactMgtDDLOptions.find((el) => el.value === filterPayLoad.listSelection)}
                onChange={(event, val) => setFilterPayLoad({ ...filterPayLoad, listSelection: val.value })}
                renderInput={(params) => <TextField {...params} margin="none" label="" placeholder="" />}
              />
            </div>
            <div className="flex sm">
              <div className="main-input">
                <MainSearchInput
                  margin="none"
                  inputType={filterPayLoad.onProximity ? 'numberAsString' : 'string'}
                  value={fancyFilterData.input}
                  placeHolder={`${
                    filterPayLoad.onProximity ? 'Zip Code' : 'First, Last, Last First, Email or Company...'
                  }`}
                  moreInfoText={`The single search box will return any results for Name, Email, or Company that are included in the searched text. Partials can be used with the exception of the full name which must include the full last name but may include a partial first name.  Example: Smith J may be used but Smi J may not.

Use the following formats when searching: 

First Name, Last Name, Last Name First Name, Email Address, Company Name.`}
                  onChange={fancyFilterEventHandler}
                  getContactList={() => getContactList()}
                />
              </div>
              <div className="buttons">
                {getCurrentAppliedFilter().length == 0 && <>{filterBuilder}</>}
                <div className="search sm">
                  <Button className="btn" onClick={() => getContactList()}>
                    {!loadingDataList ? 'Search Now' : 'Searching...'}
                  </Button>
                </div>
              </div>
              <div className="search md">
                <Button className="btn" onClick={() => getContactList()}>
                  {!loadingDataList ? 'Search Now' : 'Searching...'}
                </Button>
              </div>
            </div>
          </div>
          <div className="search xs">
            <Button className="btn" onClick={() => getContactList()}>
              {!loadingDataList ? 'Search Now' : 'Searching...'}
            </Button>
          </div>
        </div>
      </StyledFilters>
      {getCurrentAppliedFilter().length > 0 && (
        <AppliedFilters
          style={props.style}
          filters={getCurrentAppliedFilter()}
          onDelete={(data) => fancyFilterEventHandler(data, 'delete')}
          onUpdate={(data) => fancyFilterEventHandler(data, 'filter')}
          onSelectCustomForm={(props) => {
            return (
              <RegInforForm
                editFilter
                menuRef={props.menuRef}
                form={props.form}
                onClose={() => props.setForm(false)}
                applyFilter={(data) => {
                  props.setForm(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
          onSelectForm={(props) => {
            return (
              <FilterApplyForm
                editFilter
                menuRef={props.menuRef}
                form={props.form}
                onClose={() => props.setForm(false)}
                onChange={(data) => fancyFilterEventHandler(data, 'filter')}
              />
            );
          }}
        >
          {filterBuilder}
        </AppliedFilters>
      )}
    </>
  );
};

export default Filters;
