import React, { useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const StyledTextarea = styled('div')(
  ({ theme, $editMode, $showBorder }) => css`
    width: 100%;
    display: block !important;

    .MuiInputBase-root {
      padding: ${$editMode ? '8.5px' : 0};
      border: ${$editMode ? '1px' : 'none'};

      ${$showBorder &&
      !$editMode && {
        '.MuiInputBase-input': {
          border: `1px solid ${
            theme.palette.mode == 'light' ? '#e0e0e0' : 'rgba(255,255,255,0.23)'
          }`,
          borderRadius: '4px',
          padding: '8.5px',
        },
      }}

      ${!$editMode && {
        fieldset: {
          border: 'inherit !important',
        },
      }}

      .MuiInputBase-input {
        margin-bottom: ${$editMode ? '22px' : '0px'};
      }
    }
  `
);

const Textarea = ({
  edit,
  disabled,
  showBorder,
  label,
  placeholder,
  minRows = 4,
  maxRows = 10,
  maxLength = 500,
  value,
  onChange,
}) => {
  const [state, setState] = useState(value);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    setState(value);
    return () => {};
  }, [value]);

  useEffect(() => {
    setCharacterCount(value?.length);
    return () => {};
  }, [edit]);

  const changeHandler = (val) => onChange(val);
  const debouncedUpdateHandler = useMemo(
    () => debounce(changeHandler, 150), // 150 nmiliseconds
    [value, onChange]
  );

  return (
    <StyledTextarea $editMode={edit} $showBorder={showBorder}>
      <TextField
        label={label}
        placeholder={placeholder}
        multiline
        minRows={minRows}
        maxRows={maxRows}
        variant="outlined"
        InputProps={
          (edit ? { maxLength: maxLength } : { disableUnderline: true },
          { readOnly: disabled })
        }
        value={state || ''}
        onChange={(e) => {
          setState(e.target.value || '');
          setCharacterCount(e.target.value.length || 0);
          debouncedUpdateHandler(e.target.value || '');
        }}
      />
      {edit && (
        <div
          style={{
            marginTop: '-22px',
            paddingRight: '8px',
            fontSize: '0.73rem',
            color: '#b5b5b5',
            textAlign: 'end',
          }}
        >
          <span>{`Character Count: ${characterCount} / ${maxLength}`}</span>
        </div>
      )}
    </StyledTextarea>
  );
};

export const AppTextarea = memo(Textarea);
