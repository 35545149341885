function FilterOption(filter, index) {
  filter.isIncluded = filter.isIncluded ?? true;
  this.id = filter.id;
  this.label = filter.label;
  this.type = filter.type;
  this.multiple = false;
  this.dataType = filter.dataType;
  this.backendValProp = filter.backendValProp;
  this.inputProps = filter.inputProps;
  this.disableClearable = filter.disableClearable;

  // this below will help to SORT the applied filter by the moment it gets added
  if (filter.permanent) this['lastEnteredTimestamp'] = index;

  switch (filter.id) {
    case 'regInfo':
      this.multiple = true;
      this.value = filter.useCustomForm ? filter.value : filter.value || {};
      this.options = [];
      this.otherOptions = filter.otherOptions;
      this.useCustomForm = filter.useCustomForm;
      break;
    default:
      this.value = filter.value;
      break;
  }
}

export const GetInitialFilterOptions = (defaultList = []) => {
  return defaultList.map(
    (item, index) => (item = new FilterOption(item, index))
  );
};
