import React from 'react';
import { ItemsWrapper } from './Style';

const Items = (props) => {
  const renderFunc = props.children || props.render;

  return (
    <ItemsWrapper>
      {props.loadingLogs && props.onLoading()}
      {!props.loadingLogs && !props.totalLogs && props.onEmptyLogs()}
      {!props.loadingLogs && props.sortedLogs.map(renderFunc)}
    </ItemsWrapper>
  );
};

export default Items;
