import * as React from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

import Alert from '@mui/material/Alert';

const AlertRoot = styled(Alert)(
  ({ theme }) => css`
    & .MuiAlert-standardInfo {
      border: 1px solid ${theme.palette.mode === 'dark' ? '#506374' : '#c8e6ff'};
    }
  `
);

function EmptyList() {
  return (
    <AlertRoot severity="info">There are not logs for this request</AlertRoot>
  );
}

export { EmptyList };
