import React, { Fragment, useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { css } from 'styled-components';
import { TextField, MenuItem, useMediaQuery, IconButton, Stack, Typography, Divider, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { store } from '../../../../store';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { ManageRequestContext } from '../../../../context/manage-request.context';
import { NSDatePicker } from '../../../common/pickers/NSDatePicker';
import useDocumentTitle from '../../../../helpers/hooks/useDocumentTitle';
import FilterBuilder from '../../../common/fancy-filter/FilterBuilder';
import FilterMenuList from '../../../common/fancy-filter/FilterMenuList';
import FilterApplyForm from '../../../common/fancy-filter/FilterApplyForm';
import AppliedFilters from '../../../common/fancy-filter/AppliedFilters';
import CustomForm from './CustomForms';
import moment from 'moment';
import { AntSwitch, Div, HtmlTooltip, RequestStatus } from '../../../../Style';
import { GetInitialFilterOptions } from './models';
import DateCalendarServerRequest from './Calendar';

const RequestFilterStyled = styled(Div)(
  ({ theme, $calendarview }) => css`
    padding: 15px 10px;

    .default-filters {
      align-items: ${$calendarview ? 'center' : ''};
      align-items: flex-start;
    }

    .calendar-quick-view {
      .week {
        position: relative;
        text-align: center;

        .MuiTabs-root {
          zoom: 85%;

          .MuiTabs-scrollButtons.Mui-disabled {
            opacity: 0.3;
          }

          .MuiTab-root {
            min-width: fit-content;
            padding: 8;
            padding-bottom: 0px;
            height: 65px;

            .day-count {
              span {
                background-color: ${theme.palette.mode == 'dark' ? '#373737' : 'white'};
                border: 1px solid ${theme.palette.mode == 'dark' ? '#575757' : 'lightgray'};
                color: ${theme.palette.mode == 'dark' ? 'white' : 'black'};
              }
            }
          }

          .MuiDivider-root {
            margin: '15px 0px';
          }
        }
      }
    }

    .expand-calendar .MuiIconButton-root {
      .bounce-icon {
        animation-duration: 5s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
        animation-name: bounce;
        animation-timing-function: ease;
      }

      .bounce-icon:hover {
        animation-name: none;
      }

      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(-7px);
        }
        30% {
          transform: translateY(0px);
        }
        40% {
          transform: translateY(-5px);
        }
        50% {
          transform: translateY(0px);
        }
        60% {
          transform: translateY(-3px);
        }
        70% {
          transform: translateY(0px);
        }
        100% {
          transform: translateY(-2px);
        }
        100% {
          transform: translateY(-1px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }

    /* .expand-calendar .MuiIconButton-root:hover {
      background-color: darkgray;
    } */

    .switch-stack {
      display: inline-block;
      align-items: start;
      position: relative;
      top: ${$calendarview ? '4px' : '4px'};
      align-items: center;
      max-width: 60px;
      height: 42px;
      background-color: ${theme.palette.mode == 'light' ? '#e1e1ff' : ''};
      border-radius: 5px;
      padding: 5px;
      text-align: -webkit-center;

      > p {
        font-weight: bold;
        color: #4c4c93;
        line-height: 1;
        top: 1px;
        position: relative;
      }
    }

    .addFilter,
    .search,
    .search button,
    .search-btn {
      min-height: 36.5px;
      max-height: 36.5px !important;
    }

    .inputs {
      flex: 1 1 auto;
      min-width: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
    }

    #status-ddl {
      display: inline-block;
      vertical-align: bottom;
      flex-basis: 240px;
      flex-grow: 1;
      width: auto !important;

      .MuiInputBase-root {
        width: 100%;
      }
    }

    .pickers {
      position: relative;
      display: inline-block;
      min-width: 325px;
    }

    .dynamic-input-1,
    .dynamic-input-2 {
      display: inline-block;
      width: 20%;
      max-width: 240px;
      vertical-align: bottom;
      flex: 1;
      min-width: 144px;
    }

    .search-btn {
      min-width: 100px;
      max-height: 100px;
    }

    .search {
      flex-basis: fit-content;
      flex-grow: 1;
      justify-content: flex-end;
      right: -10px;
    }

    .dynamic-search {
      margin: 8px 0;
    }

    ${theme.breakpoints.down('sm')} {
      .default-filters {
        width: 100%;
        display: inline-block !important;

        .search > button {
          display: none;
        }

        .search.mobile {
          display: flex !important;
          gap: 10px;
        }

        .row-gap-mobile {
          padding-top: 10px;
        }
      }
    }

    ${theme.breakpoints.up('sm')} {
      .default-filters {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;

        .search {
          position: relative;
          top: 7px;
          gap: 10px;
          display: flex;
        }

        .search.mobile {
          display: none;
        }
      }
    }

    ${theme.breakpoints.down('600')} {
      #status-ddl {
        width: calc(100% - 77px) !important;
        margin-right: 0;
      }

      .search {
        position: relative;
        top: 7px;
        gap: 10px;
        display: flex;
        flex-grow: 0;
      }
    }

    ${theme.breakpoints.down('1240')} {
      #status-ddl {
        width: calc(100% - 100px);
      }
    }

    ${theme.breakpoints.up('1240')} {
      #status-ddl {
        width: 35%;
        min-width: 230px;
        max-width: 300px;
      }
    }
  `
);

const RequestFilter = (props) => {
  useDocumentTitle('Manage Request'); // Set page title

  const {
    loading,
    requestStatusesDDL,
    getGeneralRequests,
    filterPayLoad,
    setFilterPayLoad,
    appliedFilters,
    setAppliedFilters,
    controlSetting,
    initialFilterPayload,
    fancyFilterData,
    setFancyFilterData,
    calendarViewDefault,
    setRequestList,
    setPagination,
  } = useContext(ManageRequestContext);

  const theme = useTheme();
  const userRoles = store.getState().reducer.currentUser.userRoles;
  const matchMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));

  // const [showCalendarView, setShowCalendarView] = useState(false);
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const [quickCalendarValue, setQuickCalendarValue] = useState(0);

  const handleClearFilter = () => {
    let _resetPayload = {
      ...initialFilterPayload,
      requestStatus: filterPayLoad.requestStatus,
      staffSearchID: filterPayLoad.staffSearchID,
    };
    setFilterPayLoad(_resetPayload);

    let _appliedFilterCopy = _.cloneDeep(appliedFilters);
    let currentStaffObj = _appliedFilterCopy.find((obj) => obj.id == 'staffSearchID');

    const _resetFancyInit = GetInitialFilterOptions(controlSetting).map((obj) =>
      obj.id == 'staffSearchID' ? { ...obj, ...currentStaffObj } : obj
    );
    setAppliedFilters(_resetFancyInit);

    // Pull data
    getGeneralRequests(_resetPayload);
  };

  const handleApplyFilter = (e) => {
    e.preventDefault();
    getGeneralRequests(filterPayLoad);
  };

  const handleStatusSelection = async (e) => {
    setFilterPayLoad({ ...filterPayLoad, requestStatus: e.target.value, pageNumber: 1 });
    getGeneralRequests({ ...filterPayLoad, requestStatus: e.target.value, pageNumber: 1 });
  };

  const getGeneralRequestsHandler = (fancyFilters = []) => {
    let payload = { ...filterPayLoad };

    fancyFilters.map((filter) => {
      if (!filter.mdBreakpointDown || (filter.mdBreakpointDown && matchMediumAndDown)) {
        if (filter.value && filter.backendValProp && typeof filter.value === 'object') {
          return (payload[filter.id] = filter.value[filter.backendValProp]);
        } else if (filter.value) {
          return (payload[filter.id] = filter.value);
        } else {
          return (payload[filter.id] = initialFilterPayload[filter.id]);
        }
      }
    });

    setFilterPayLoad(payload);
    getGeneralRequests(payload);
  };

  const fancyFilterEventHandler = async (data, type) => {
    if (type === 'filter' || type === 'delete') {
      let _filtersCopy = _.cloneDeep(fancyFilterData.filters);
      _filtersCopy.map((item) =>
        item.id == data.id
          ? ((item.value = data.value),
            (item.defaultValue = data.defaultValue),
            (item.valueLabel = data.valueLabel),
            (item.lastEnteredTimestamp = moment().format('x')))
          : item
      );

      let filterData = { ...fancyFilterData, filters: _filtersCopy };
      setFancyFilterData(filterData);
      setAppliedFilters(filterData.filters);
      getGeneralRequestsHandler(filterData.filters);
    }
  };

  var showStaffDll = userRoles.includes('ServiceOwner') || userRoles.includes('SuperUser');
  var showAgentDll =
    (userRoles.includes('Customer') && controlSetting?.ddlSearchAgentID_Enable) ||
    (!userRoles.includes('Customer') && !userRoles.includes('NotaryAgent'));

  const getCurrentAppliedFilter = () => {
    if (appliedFilters) {
      return appliedFilters.filter((item) => {
        if (item.id == 'staffSearchID' && !showStaffDll) return;
        else if (item.id == 'agentID' && !showAgentDll) return;
        else
          return item.useCustomForm
            ? item.value
            : (item.multiple && item.value.length) || (!item.multiple && item.value);
      });
    } else return [];
  };

  const filterMenuList = () => {
    return fancyFilterData.filters.filter((item) => {
      if (item.id == 'staffSearchID' && !showStaffDll) return;
      else if (item.id == 'agentID' && !showAgentDll) return;
      else {
        if (item.mdBreakpointDown && !matchMediumAndDown) return;
        else
          return (
            !(item.multiple && (item.useCustomForm ? item.value : item.value.length)) && !(!item.multiple && item.value)
          );
      }
    });
  };

  const filterBuilder = (
    <FilterBuilder
      onShowOptions={({ ref, setShowOptions }) => (
        <FilterMenuList
          dense
          builderRef={ref}
          options={filterMenuList()}
          onClose={setShowOptions}
          onSelectCustomForm={(props) => {
            return (
              <CustomForm
                requestStatus={filterPayLoad.requestStatus}
                menuRef={props.menuRef}
                form={props.openCustomForm}
                onClose={props.setOpenCustomForm}
                applyFilter={(data) => {
                  setShowOptions(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
          onSelectForm={(props) => {
            return (
              <FilterApplyForm
                menuRef={props.menuRef}
                form={props.openForm}
                onClose={props.setOpenForm}
                onChange={(data) => {
                  setShowOptions(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
        />
      )}
    >
      {getCurrentAppliedFilter().length ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
    </FilterBuilder>
  );

  const filteredStatusDLL = () => {
    return requestStatusesDDL.filter(
      (el) => (!controlSetting.enableScanBacks && el.value !== '4-SB') || controlSetting.enableScanBacks
    );
  };

  const calendarSwitch = (
    <Stack className="switch-stack">
      <AntSwitch
        inputProps={{ 'aria-label': 'ant design' }}
        disabled={loading}
        checked={filterPayLoad.onCalendarView ? true : false}
        onChange={(event) => {
          let filterCopy = { ...initialFilterPayload };
          filterCopy.onCalendarView = event.target.checked;
          setFilterPayLoad(filterCopy);
          if (!event.target.checked) {
            getGeneralRequests(initialFilterPayload);
          } else {
            setRequestList([]);
            setPagination({ pageNumber: 1, pageSize: 5, totalRecords: 0 });
          }
        }}
      />
      <Typography variant="body2">Calendar</Typography>
    </Stack>
  );

  const quickCalendarValueHandler = (event, newValue) => {
    setQuickCalendarValue(newValue);
  };

  const calendarDateChangeHandler = async (date) => {
    let newPayload = { ...filterPayLoad };
    newPayload.requestStatus = '1234569';
    newPayload.dateFrom = date;
    newPayload.dateTo = date;
    await getGeneralRequests(newPayload);
  };

  return (
    <>
      <RequestFilterStyled $calendarview={filterPayLoad.onCalendarView ? true : false}>
        <form onSubmit={handleApplyFilter}>
          {filterPayLoad.onCalendarView ? (
            <div className="default-filters">
              {calendarSwitch}
              {!showFullCalendar ? ( // let always show the full calendar for now. Default is False
                <DateCalendarServerRequest
                  filterPayload={filterPayLoad}
                  defaultCalendar={calendarViewDefault}
                  onDateChange={calendarDateChangeHandler}
                />
              ) : (
                <div className="calendar-quick-view">
                  <Box
                    className="week"
                    sx={{
                      maxWidth: { xs: 320, sm: 600 },
                      bgcolor: 'background.paper',
                    }}
                  >
                    <Typography variant="caption">December</Typography>
                    <Tabs
                      value={quickCalendarValue}
                      onChange={quickCalendarValueHandler}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example"
                    >
                      {calendarViewDefault?.calendar?.map((item) => (
                        <Tab
                          key={item.SigningDate}
                          label={
                            <>
                              <div>
                                {item.Counts.map((count, index) => (
                                  <Fragment key={index}>
                                    {index == 0 && count.OpenCount > 0 && (
                                      <Badge className="day-count" badgeContent={count.OpenCount}>
                                        <RequestStatus $fullradius={true} $colorBase={calendarViewDefault.openColor}>
                                          {'OP'}
                                        </RequestStatus>
                                      </Badge>
                                    )}
                                    {index == 1 && count.BillingCount > 0 && (
                                      <Badge className="day-count" badgeContent={count.BillingCount}>
                                        <RequestStatus $fullradius={true} $colorBase={calendarViewDefault.billingColor}>
                                          {'BI'}
                                        </RequestStatus>
                                      </Badge>
                                    )}
                                    {index == 2 && count.ClosedCount > 0 && (
                                      <Badge className="day-count" badgeContent={count.ClosedCount}>
                                        <RequestStatus $fullradius={true} $colorBase={calendarViewDefault.closedColor}>
                                          {'CL'}
                                        </RequestStatus>
                                      </Badge>
                                    )}
                                  </Fragment>
                                ))}
                              </div>
                              <Typography sx={{ mt: '5px' }} variant="subtitle2">
                                {moment(item.SigningDate).format('ddd D')}
                              </Typography>
                            </>
                          }
                        />
                      ))}
                    </Tabs>
                  </Box>
                </div>
              )}
              {/* <div className="expand-calendar">
                <Tooltip title="Show full calendar">
                  <IconButton
                    size="medium"
                    aria-label="expand calendar"
                    onClick={() => {
                      setShowFullCalendar((prev) => !prev);
                      if (!showFullCalendar) setRequestList([]);
                    }}
                  >
                    <KeyboardDoubleArrowDownIcon className="bounce-icon" />
                  </IconButton>
                </Tooltip>
              </div> */}
            </div>
          ) : (
            <div className="default-filters">
              <div className="inputs">
                {calendarSwitch}
                <FormControl id="status-ddl">
                  <InputLabel id="statusInputLabel">Select Status</InputLabel>
                  <Select
                    label="Select Status"
                    value={filterPayLoad.requestStatus}
                    onChange={(e) => handleStatusSelection(e)}
                  >
                    {filteredStatusDLL().map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!matchMediumAndDown && (
                  <>
                    <div className="pickers">
                      <NSDatePicker
                        style={{ width: '165px', verticalAlign: 'bottom' }}
                        label="Start Date"
                        date={filterPayLoad.dateFrom}
                        emitDate={(date) => setFilterPayLoad({ ...filterPayLoad, dateFrom: date })}
                      />
                      <HtmlTooltip
                        arrow
                        maxwidth="400px"
                        title={
                          'If only the end date is entered the search will only look back 1 year. Use both the start and end date to look back further.'
                        }
                      >
                        <IconButton>
                          <InfoOutlinedIcon color="info" fontSize="small" />
                        </IconButton>
                      </HtmlTooltip>
                      <NSDatePicker
                        style={{ width: '165px', verticalAlign: 'bottom' }}
                        label="End Date"
                        date={filterPayLoad.dateTo}
                        emitDate={(date) => setFilterPayLoad({ ...filterPayLoad, dateTo: date })}
                      />
                    </div>
                    <TextField
                      className="dynamic-input-1"
                      label="Signer Last Name"
                      value={filterPayLoad.borrowerLastName}
                      onChange={(e) => {
                        setFilterPayLoad({
                          ...filterPayLoad,
                          borrowerLastName: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      className="dynamic-input-2"
                      label="Loan Number"
                      value={filterPayLoad.loanNumber}
                      onChange={(e) => {
                        setFilterPayLoad({
                          ...filterPayLoad,
                          loanNumber: e.target.value,
                        });
                      }}
                    />
                    <span className="dynamic-search">
                      {getCurrentAppliedFilter().length == 0 && <>{filterBuilder}</>}
                    </span>
                  </>
                )}
                <div className="search">
                  {matchMediumAndDown && getCurrentAppliedFilter().length == 0 && <>{filterBuilder}</>}
                  <Button
                    sx={{ '& span': { mr: 0 } }}
                    variant="outlined"
                    startIcon={<ClearOutlinedIcon />}
                    onClick={handleClearFilter}
                  >
                    Clear
                  </Button>
                  <Button className="search-btn" type="submit">
                    {!loading ? 'Search Now' : 'Searching...'}
                  </Button>
                </div>
                <div className="row-gap-mobile">
                  <div className="search mobile">
                    <Button
                      sx={{ '& span': { mr: 0 } }}
                      variant="outlined"
                      startIcon={<ClearOutlinedIcon />}
                      onClick={handleClearFilter}
                    >
                      Clear
                    </Button>
                    <Button className="search-btn" type="submit">
                      {!loading ? 'Search Now' : 'Searching...'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </RequestFilterStyled>
      {getCurrentAppliedFilter().length > 0 && !filterPayLoad.onCalendarView && (
        <AppliedFilters
          style={props.style}
          filters={getCurrentAppliedFilter()}
          onDelete={(data) => fancyFilterEventHandler(data, 'delete')}
          onUpdate={(data) => fancyFilterEventHandler(data, 'filter')}
          onSelectCustomForm={(props) => {
            return (
              <CustomForm
                editFilter
                requestStatus={filterPayLoad.requestStatus}
                menuRef={props.menuRef}
                form={props.form}
                onClose={() => props.setForm(false)}
                applyFilter={(data) => {
                  props.setForm(false);
                  fancyFilterEventHandler(data, 'filter');
                }}
              />
            );
          }}
          onSelectForm={(props) => {
            return (
              <FilterApplyForm
                editFilter
                menuRef={props.menuRef}
                form={props.form}
                onClose={() => props.setForm(false)}
                onChange={(data) => fancyFilterEventHandler(data, 'filter')}
              />
            );
          }}
        >
          {filterBuilder}
        </AppliedFilters>
      )}
    </>
  );
};

export default RequestFilter;
