import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { useEffect, useState } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ProfileDDL = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    handleSelected(props.selectedItems);
    return () => {};
  }, []);

  const handleSelected = (selectedVals) => {
    if (props.options.length > 0) {
      let selectedList = _.cloneDeep(props.options);
      selectedList = selectedList.filter((element) => {
        return selectedVals.some((selected) => {
          return props.label === 'Capabilities'
            ? element.index === selected.ncLookupId &&
                selected.isCapable === true
            : element.index === selected.ncLookupId;
        });
      });
      setSelectedValues(selectedList);
    }
  };

  const handleChange = (vals) => {
    let returnVal = [];
    switch (props.label) {
      case 'Capabilities':
        returnVal = vals.map((item) => {
          return {
            isCapable: true,
            lookupName: item.value,
            ncLookupId: item.index,
            sortOrder: item.sortOrder,
          };
        });
      case 'Spoken Languages':
        returnVal = vals.map((item, index) => {
          return {
            isSpoken: true,
            lookupName: item.value,
            ncLookupId: item.index,
            sortOrder: item.sortOrder,
          };
        });
    }
    props.onChange(returnVal);
  };

  return (
    props.options.length > 0 && (
      <Autocomplete
        id="Profile DDL"
        multiple
        size="small"
        disableCloseOnSelect
        options={props.options}
        value={selectedValues || []}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, value) => {
          setSelectedValues(value);
          handleChange(value);
        }}
        // props.onChange(value)}
        renderOption={(props, option, { selected }) => (
          <li style={{ fontSize: '12.5px', padding: '0px' }} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
    )
  );
};

export default ProfileDDL;
