import React, { Fragment, useContext } from 'react';
import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { currencyFormat } from '../../../helpers/currency-format';
import { AgentPaymentMgtContext } from '../../../context/agent-payment-mgt.context';
import { RequestStatus } from '../../../Style';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const AgentPaymentMgtChild = ({
  childItem: data,
  summaryItem: summaryData,
}) => {
  const { selectRow } = useContext(AgentPaymentMgtContext);

  const getStatus = (statusColor, statusNameShort) => (
    <RequestStatus $colorBase={statusColor}>{statusNameShort}</RequestStatus>
  );

  return (
    <ListItem>
      <ListItemText
        className="body-name-cell"
        primary={
          <Fragment>
            <div className="body-name-check">
              <div>
                <Checkbox
                  size="small"
                  className="check-all"
                  checked={data.selected}
                  onChange={() => selectRow(data, summaryData)}
                />
              </div>
            </div>
            <div className="body-name body-detail">
              {getStatus(data.statusColor, data.statusNameShort)}
              <Link
                className="link"
                to={{
                  pathname: `/agent-payment-mgt/invoice/agent/payment/${data.requestID}`,
                }}
              >
                {'Req# ' +
                  data.requestSeqID +
                  ' - ' +
                  data.borrowerLastName +
                  ' ' +
                  moment(data.signingDate).format('MM/DD/YYYY')}
              </Link>
            </div>
          </Fragment>
        }
      />
      <ListItemText
        className="body-total-cell col"
        primary={
          <div className="body-detail body-detail-total">
            {currencyFormat(data.totalPayable)}
          </div>
        }
      />
      <ListItemText
        className="body-total-cell col"
        primary={
          <div className="body-detail body-detail-paid">
            {currencyFormat(data.totalPaid)}
          </div>
        }
      />
      <ListItemText
        className="body-total-cell"
        primary={
          <div className="body-detail due">
            {currencyFormat(data.totalBalance)}
          </div>
        }
      />
    </ListItem>
  );
};
