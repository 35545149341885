import React, { useRef } from 'react';
import { Prompt } from 'react-router';
import { useBusinessSettings } from '../context/business-settings.context';
import ViewSettings from '../components/business-settings/ViewSettings';
import WebSiteSettings from '../components/business-settings/WebSiteSettings';
import ConfirmRequest from '../components/business-settings/ConfirmRequest';
import ChangeRequest from '../components/business-settings/ChangeRequest';
import DeclineRequest from '../components/business-settings/DeclineRequest';
import CancelRequest from '../components/business-settings/CancelRequest';
import MarkCompleteRequest from '../components/business-settings/MarkCompleteRequest';
import AgentNote from '../components/business-settings/AgentNote';
import BillingDefaults from '../components/business-settings/BillingDefaults';
import InvoiceMessage from '../components/business-settings/InvoiceMessage';
import EmailAddresses from '../components/business-settings/EmailAddresses';
import { StyledAccordion } from '../Style';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';
import { useDirtyChangesPrompt } from '../helpers/hooks/useDirtyChangesPrompt';

const BusinessSettings = (props) => {
  useDocumentTitle('Business Settings'); // Set page title
  const accordionRef = useRef();
  const classes = StyledAccordion;
  const { promptDirtyChanges } = useDirtyChangesPrompt();
  const { hasDirtyChanges, getBusSettings } = useBusinessSettings();

  const handleCallback = async (event = null) => {
    if (event.confirmation) {
      await getBusSettings(event.accordion);
      const scrollToView = document.getElementById(`${event.accordion}-Accordion`);
      setTimeout(() => {
        window.scrollTo({
          top: scrollToView?.offsetTop ? scrollToView.offsetTop : 0,
          behavior: 'smooth',
        });
      }, 500);
    } else {
      promptDirtyChanges(
        {
          buttonTrueText: 'Discard',
          buttonTextFalse: 'Cancel',
          content: 'Are you sure you want to discard your changes?',
          title: 'You have unsaved changes',
        },
        (isConfirmed) => {
          event.confirmation = isConfirmed;
          handleCallback({ confirmation: event, accordion: event.accordion });
        },
        hasDirtyChanges
      );
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root} ref={accordionRef}>
        <div className="view-title">Business Settings</div>
        <Prompt
          when={hasDirtyChanges}
          message={JSON.stringify({
            open: true,
            msg: 'You have unsaved changes, are you sure you want to leave?',
          })}
        />
        <ViewSettings callBack={handleCallback} />
        <WebSiteSettings callBack={handleCallback} />
        <br />
        <div className="comp-title">Email Settings</div>
        <EmailAddresses callBack={handleCallback} />
        <ConfirmRequest callBack={handleCallback} />
        <ChangeRequest callBack={handleCallback} />
        <DeclineRequest callBack={handleCallback} />
        <CancelRequest callBack={handleCallback} />
        <MarkCompleteRequest callBack={handleCallback} />
        <AgentNote callBack={handleCallback} />
        <br />
        <div className="comp-title">Invoice Settings</div>
        <BillingDefaults callBack={handleCallback} />
        <InvoiceMessage callBack={handleCallback} />
      </div>
    </React.Fragment>
  );
};

export default BusinessSettings;
