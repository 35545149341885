import React, { useEffect, useReducer, useRef, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Alert, AlertTitle, Button, Link, TextField } from '@mui/material';
import { store } from '../../store';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signingRequestService as srService } from '../../services';
import { dispatchAlert, getUrlEncryptedParameters } from '../../helpers';
import Loading from '../../components/common/others/Loading';
import { NeutralButton } from '../../Style';

const StyledDiv = styled('div')(
  ({ theme }) => css`
    max-width: 600px;
    text-align: center;
    margin: 0 auto;

    p {
      font-size: 16px;
      margin-top: 20px;
    }

    button {
      min-width: 350px;
    }

    .MuiFormControl-root {
      margin-bottom: 20px;
      align-items: center;

      .MuiFormGroup-root {
        margin-top: 15px;
        margin-bottom: 15px;
        border: 1px solid #d2d2d2;
        padding: 10px;
        border-radius: 5px;
        min-width: 350px;

        span {
        }
      }
    }
  `
);

function reducer(state, action) {
  switch (action.type) {
    case 'onSubmit':
      return { ...state, onSubmit: action.onSubmit };
    case 'onSuccess':
      return { ...state, onSuccess: action.onSuccess };
    default:
      '';
  }
}

const AvailabilityRequest = (props) => {
  let history = useHistory();
  let theme = useTheme();
  const dispatch = useDispatch();

  const [domStates, setDomStates] = useReducer(reducer, {
    onSubmit: false,
    onSuccess: false,
  });
  const [option, setOption] = useState('');
  const [validatingLink, setValidatingLink] = useState(false);
  const [invalidLinkToken, setInvalidLinkToken] = useState(false);

  const search = useLocation().search;
  const { accessToken, global } = store.getState().reducer;
  const publicId = new URLSearchParams(search).get('publicId');
  const encryption = getUrlEncryptedParameters('encryption', false);

  // THIS ASYNC WORKD. NEEDS TO BE WRAPPED IN A ASYNC FUNCTION
  useEffect(() => {
    // SET_MIXED_ROUTE to let app that this view could be for anonymous or authenticated users
    dispatch({ type: 'SET_MIXED_ROUTE', payload: true });

    const callServiceToValidate = async () => {
      setValidatingLink(true);
      await srService.validateAvailabilityRequestToken(publicId, encryption).then(
        (res) => setInvalidLinkToken(false),
        (error) => {
          dispatchAlert(error, true);
          setInvalidLinkToken(' ' + error);
        }
      );
      setValidatingLink(false);
    };

    callServiceToValidate();

    return () => {
      dispatch({ type: 'SET_MIXED_ROUTE', payload: false });
    };
  }, []);

  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const handleSubmitResponse = () => {
    setDomStates({ type: 'onSubmit', onSubmit: true });
    const callServiceToSaveResponse = async () => {
      await srService.saveAvailabilityRequestResponse(publicId, encryption, option).then(
        (res) => {
          setDomStates({ type: 'onSuccess', onSuccess: true });
          dispatchAlert('Response was submitted successfully');
        },
        (error) => dispatchAlert(error, true)
      );
      setDomStates({ type: 'onSubmit', onSubmit: false });
    };

    callServiceToSaveResponse();
  };

  return (
    <>
      {!accessToken.token && (
        <div style={{ padding: '20px', textAlign: 'center' }} className="top-content-logo">
          {global.logoFile && (
            <div className="portalLogo">
              {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
            </div>
          )}
        </div>
      )}
      {!validatingLink && !invalidLinkToken ? (
        <StyledDiv>
          <>
            {domStates?.onSuccess ? (
              <Alert severity="success" sx={{ textAlign: 'left' }}>
                <AlertTitle>
                  <strong>Thank you for your response!</strong>
                </AlertTitle>
                You will receive a confirmation email if assigned to this request.{' '}
                {accessToken.token ? (
                  <Link sx={{ cursor: 'pointer' }} onClick={() => props.history.push('/dashboard')} underline="always">
                    {'Return to Dashboard.'}
                  </Link>
                ) : (
                  <Link sx={{ cursor: 'pointer' }} onClick={() => props.history.push('/login')} underline="always">
                    {'Go to Login.'}
                  </Link>
                )}
              </Alert>
            ) : (
              <>
                <FormControl>
                  <p>Please select from the options below to submit your response.</p>
                  <RadioGroup value={option} onChange={handleChange}>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Yes, I am available." // green
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="No, I am unavailable." // red
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="No, Fee too low." // orange
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="No, Scheduling Conflict." // orange
                    />
                  </RadioGroup>
                </FormControl>
                <div>
                  <Button disabled={!option ? true : false} variant="contained" onClick={handleSubmitResponse}>
                    Submit Response {domStates?.onSubmit && <Loading forButton lpad rpad />}
                  </Button>
                </div>
                <br />
                <div>
                  {theme.palette && (
                    <NeutralButton
                      variant="contained"
                      onClick={(e) => history.push(accessToken.token ? '/dashboard' : '/login')}
                    >
                      Cancel
                    </NeutralButton>
                  )}
                </div>
              </>
            )}
          </>
        </StyledDiv>
      ) : (
        <>
          {validatingLink ? (
            <Loading forPage msg="Validating Link..." />
          ) : (
            invalidLinkToken && (
              <div style={{ maxWidth: '500px', margin: '10px auto' }}>
                <Alert severity="warning">
                  <AlertTitle>
                    <strong>Uh-oh!</strong>
                  </AlertTitle>
                  {invalidLinkToken}
                </Alert>
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export default AvailabilityRequest;
