import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import { moduleTabService as service, portalService } from '../../../services';
import FormDialog from './FormDialog';
import { dispatchAlert } from '../../../helpers';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import { store } from '../../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      fontSize: '1.15rem',
    },

    '& .Mui-selected': {
      backgroundColor: '#eee2e214',
    },

    '&.nested': {
      '& .MuiSvgIcon-root': {
        fontSize: '1.15rem',
      },
    },
  },
}));

const TabControls = (props) => {
  const classes = useStyles();
  const [openTabEditDiablog, setOpenTabEditDiablog] = useState(false);
  const [openConfirmationDiablog, setOpenConfirmationDiablog] = useState(false);
  const { portalID } = store.getState().reducer.global;

  const refreshMenu = () => {
    portalService.getPortalTabs(portalID).then(
      (res) => {
        localStorage.setItem('portalTabs', JSON.stringify(res));
        store.dispatch({ type: 'SET_PORTAL_TABS', payload: res });
      },
      (error) => console.log(error)
    );
  };

  const updateTab = async (tabToUpdate, action = 'update') => {
    await service.updateTab(JSON.stringify(tabToUpdate)).then(
      (response) => {
        dispatchAlert('Tab was updated successfully.');
        setOpenTabEditDiablog(false);
        setOpenConfirmationDiablog(false);
        props.onSucceedAction(true);
        refreshMenu();
      },
      (error) => dispatchAlert(error, true)
    );
  };

  const handleUpAndDown = (direction) => {
    updatePortalTabOrder(props, direction);
  };

  const updatePortalTabOrder = async (tab, yDirection) => {
    await service.updatePortalTabOrder(JSON.stringify(tab), yDirection).then(
      (response) => {
        dispatchAlert('Tab was updated successfully.');
        props.onMove(tab.index, yDirection);
        props.onSucceedAction(true);
        refreshMenu();
      },
      (error) => dispatchAlert(error, true)
    );
  };

  const handleTabEditClickClose = (newValue) => {
    if (newValue) updateTab(newValue);
    else setOpenTabEditDiablog(false);
  };

  const handleDeleteConfirmation = (confirmed) => {
    if (confirmed) {
      let updatedTab = JSON.parse(JSON.stringify(props));
      updatedTab.isDeleted = true;
      updateTab(updatedTab);
    } else {
      setOpenConfirmationDiablog(false);
    }
  };

  return (
    <div className={[classes.root + ' ' + `${props.isNested ? 'nested' : ''}`]}>
      <IconButton
        size="small"
        aria-label="arrowUp"
        onClick={() => handleUpAndDown('up')}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <IconButton
        size="small"
        aria-label="arrowDown"
        onClick={() => handleUpAndDown('down')}
      >
        <ArrowDownwardIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => setOpenConfirmationDiablog(true)}
        aria-label="delete"
      >
        <DeleteForeverIcon />
      </IconButton>
      <IconButton
        size="small"
        aria-label="edit"
        onClick={() => setOpenTabEditDiablog(true)}
      >
        <EditIcon />
      </IconButton>
      {openTabEditDiablog && (
        <FormDialog
          open={openTabEditDiablog}
          onClose={handleTabEditClickClose}
          formMode="edit"
          tab={props}
        />
      )}
      {openConfirmationDiablog && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={openConfirmationDiablog}
          content={'Are you sure you want to delete this Tab?'}
          onClose={() => {}}
          confirmCallback={handleDeleteConfirmation}
        />
      )}
    </div>
  );
};

export default TabControls;
