import React, { useState, useEffect, Fragment, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';

import { isValidEmail } from '../../../helpers';
import { AutocompleteWithChip } from '../../../Style';

const EmailFormControl = (props) => {
  const { fake, id, label, inputControl, emails, error, dispatchEvent, disableEdit } = props;

  const [inputValue, setInputValue] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (error) setShowError(true);
    return () => {};
  }, [error]);

  const cleanErrors = (onChange) => {
    const invalidEmails = emails.filter(
      (email) => !isValidEmail(email.replace('to:', '').replace('cc:', '').replace('bcc:', ''))
    );

    if (invalidEmails.length && onChange) setShowError(true);
    else {
      setShowError(false);
    }
  };

  const handleKeyDown = (event) => {
    let val = event.target.value;
    if (['Tab', 'Enter', ',', ';'].includes(event.key) && val) {
      dispatchEvent({
        type: 'updateEmail',
        value: emails.concat(val.replace(',', '').replace(';', '')),
      });
      cleanErrors(true);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    const options = newInputValue.split(/[ ,;]+/);
    if (options.length > 1) event.persist();
    else setInputValue(newInputValue);
  };

  return (
    <AutocompleteWithChip
      id={id}
      multiple
      freeSolo
      disableClearable
      forcePopupIcon={false}
      clearOnBlur={true}
      limitTags={3}
      disabled={disableEdit}
      options={[]}
      open={false}
      onOpen={() => {}}
      value={emails}
      onChange={(event, newValue) => {
        event.stopPropagation();
        dispatchEvent({ type: 'updateEmail', value: newValue });
      }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          let withoutPrefix = option.replace('bcc:', '').replace('cc:', '').replace('to:', '');
          return (
            <Chip
              size="small"
              clickable={false}
              label={withoutPrefix}
              key={index}
              color={`${isValidEmail(withoutPrefix) ? 'default' : 'error'}`}
              variant="outlined"
              deleteIcon={<CloseIcon />}
              onDelete={(e) =>
                dispatchEvent({
                  type: 'updateEmail',
                  value: emails.filter((i) => i !== option),
                })
              }
              {...getTagProps}
            />
          );
        })
      }
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        return (
          <TextField
            sx={{ '.MuiFormLabel-root': { color: 'black' } }}
            id={'new-password'}
            name={'new-password'}
            {...params}
            autoFocus={!inputControl.disableFocus}
            label={label}
            error={error[id] ? showError : false}
            helperText={showError ? error[id] : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {label == 'To' && inputControl.showCCsBtn && (
                    <Fragment>
                      <InputAdornment position="end">
                        <IconButton
                          style={{ fontSize: '15px' }}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatchEvent({
                              type: 'toogleCCs',
                              value: 'showCCInput',
                            });
                          }}
                          edge="end"
                        >
                          Cc
                        </IconButton>
                      </InputAdornment>
                      <InputAdornment position="end">
                        <IconButton
                          style={{ fontSize: '15px' }}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatchEvent({
                              type: 'toogleCCs',
                              value: 'showBCCInput',
                            });
                          }}
                          edge="end"
                        >
                          Bcc
                        </IconButton>
                      </InputAdornment>
                    </Fragment>
                  )}
                  {label == 'Bcc' && !inputControl.showCCInput && (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ fontSize: '15px' }}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatchEvent({
                            type: 'toogleCCs',
                            value: 'showCCInput',
                          });
                        }}
                        edge="end"
                      >
                        Cc
                      </IconButton>
                    </InputAdornment>
                  )}
                  {label == 'Cc' && !inputControl.showBCCInput && (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ fontSize: '15px' }}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatchEvent({
                            type: 'toogleCCs',
                            value: 'showBCCInput',
                          });
                        }}
                        edge="end"
                      >
                        Bcc
                      </IconButton>
                    </InputAdornment>
                  )}
                </Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default EmailFormControl;
