import React, { useState, useRef, Fragment, createRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Chip } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { sortBy } from '../../../helpers';
import { Div } from '../../../Style';

const AppliedFilters = (props) => {
  const { filters = [], onDelete, onUpdate } = props;
  const [openForm, setOpenForm] = useState(false);
  const [openCustomForm, setOpenCustomForm] = useState(false);

  const filterChipRefs = useRef([]);

  const displayStatus = (filter) => {
    if (['regInfo', 'agentRegDocs'].includes(filter.id)) {
      let regStatus = filter.value['regStatus'];
      regStatus = regStatus ? regStatus : 'ALL';

      switch (regStatus) {
        case 'ALL':
          if (filter.id == 'agentRegDocs') return <></>;
          else return <>{' | ALL'}</>;
        case 'Approved':
          return (
            <>
              {' | '} <CheckOutlinedIcon sx={{ marginBottom: '-5px', color: '#12E83D' }} />
            </>
          );
        case 'Declined':
          return (
            <>
              {' | '} <CloseOutlinedIcon sx={{ marginBottom: '-5px', color: '#EE3636' }} />
            </>
          );
        case 'Pending':
          return (
            <>
              {' | '} <MoreHorizOutlinedIcon sx={{ marginBottom: '-5px', color: '#F09A04' }} />
            </>
          );
      }
    }
  };

  const displayAction = (filter) => {
    if (['regInfo', 'agentRegDocs'].includes(filter.id)) {
      let action = filter.value['action'];
      action = action ? action : 'Include';

      switch (action) {
        case 'Include':
        case 'Include Expired':
          return <> | Inc </>;
        case 'Exclude':
        case 'Exclude Expired':
          return <> | Exc </>;
      }
    }
  };

  const labelForChip = (filter) => {
    // useCustomForm assumes the filter object has multiple values in different html elements
    return (
      <Fragment>
        <span style={{ fontWeight: 500 }}>{filter.label}</span>
        {': '}
        {filter.useCustomForm && !filter.multiple && <>{filter.defaultValue || filter.valueLabel || filter.value}</>}
        {filter.useCustomForm && filter.multiple && (
          <>
            {filter.value[filter.id] &&
              filter.value[filter.id].map((el) => (el ? el.label || el.value : '')).join(', ')}
            {displayStatus(filter)}
            {displayAction(filter)}
          </>
        )}
        {!filter.useCustomForm && (
          <>
            {filter.multiple && filter.value.length > 0
              ? filter.value.map((el) => (el ? el.label || el.value : '')).join(', ')
              : typeof filter.value === 'object'
              ? filter.value.label || filter.value.value
              : filter.valueLabel || filter.value}
          </>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    filterChipRefs.current = filters.map((_, i) => filterChipRefs.current[i] ?? createRef());

    return () => {};
  }, [filters]);

  const handleDelete = (item) => {
    if (item.permanent) return null;
    switch (item.dataType) {
      // case 'multiple':
      //   return onDelete({ ...item, value: [] });
      // case 'dropdown':
      //   return onDelete({ ...item, value: '' });
      case 'array':
        return onDelete({ ...item, value: [] });
      case 'number':
        return onDelete({ ...item, value: 0 });
      default:
        return onDelete({ ...item, value: '' });
    }
    // setCurrentForm(false); ???
  };

  return (
    <Div
      style={{
        ...props.style,
        padding: '10px',
        gap: '10px',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {sortBy(filters, { prop: 'lastEnteredTimestamp' }).map((filter, index) => (
        <Fragment key={filter.id}>
          <Chip
            style={{ borderRadius: '5px' }}
            ref={(ref) => (filterChipRefs.current[index] = ref)}
            variant="outlined"
            clickable={true}
            label={labelForChip(filter)}
            deleteIcon={filter.permanent ? <LockOutlinedIcon fontSize="small" /> : <CloseIcon />}
            onDelete={(e) => {
              e.stopPropagation();
              handleDelete(filter);
            }}
            onClick={(e) => {
              setOpenForm(false);
              setOpenCustomForm(false);
              setTimeout(() => {
                filter.useCustomForm
                  ? setOpenCustomForm({ ...filter, refIndex: index })
                  : setOpenForm({ ...filter, refIndex: index });
              }, 300);
            }}
          />
        </Fragment>
      ))}
      {props.children}
      {openCustomForm &&
        props.onSelectCustomForm({
          menuRef: filterChipRefs.current[openCustomForm.refIndex],
          form: openCustomForm,
          setForm: setOpenCustomForm,
        })}
      {openForm &&
        props.onSelectForm({
          menuRef: filterChipRefs.current[openForm.refIndex],
          form: openForm,
          setForm: setOpenForm,
        })}
    </Div>
  );
};

export default AppliedFilters;
