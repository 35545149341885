import React, { useState, Fragment } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { UserAdminContext } from '../../context/user-admin.context';
import LinearProgress from '@mui/material/LinearProgress';
import UserAdminItem from './UserAdminItem';
import UserAdminTableFooter from './UserAdminTableFooter';
import UserDialog from './UserDialog';
import _, { set } from 'lodash';

const StyledDiv = styled('div')(
  ({ theme }) => css`
    padding: 2px;

    & .MuiLinearProgress-root {
      top: -14px;
    }

    & .request-columns {
      display: flex;
      flex-direction: row no-wrap;
      justify-content: space-between;
      line-height: 60px;
      font-weight: 500;
      font-size: small;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: 0 5px 3px -3px ${theme.palette.mode == 'dark' ? 'black' : 'gray'};
      position: sticky;
      top: 50px;
    }

    & .name-header {
      display: flex;
      flex-direction: row;
      padding-left: 5px;
    }

    & .email-header {
      width: 23%;
      overflow: hidden;
      padding-left: 44px;
      text-overflow: ellipsis;
      color: ${theme.palette.primary.default};
    }

    & .no-results {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      height: 100px;
      align-items: center;
    }

    & .last-headers {
      display: flex;
      flex-direction: row;
      margin-right: 5px;
      justify-content: space-between;
      width: 46%;
      align-items: center;
    }

    & .link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: ${theme.palette.primary.main};
      text-decoration: none;
      cursor: pointer;
      align-items: center;
    }

    & .sort-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: ${theme.palette.primary.default};
      text-decoration: none;
      cursor: pointer;
      align-items: center;
    }

    & .sort-header:hover {
      text-decoration: underline;
    }

    & .link:hover {
      text-decoration: underline;
    }
  `
);

const UserAdminList = (props) => {
  const context = React.useContext(UserAdminContext);
  const [openDialog, setOpenDialog] = useState(false);
  const { userAdminMembers, loading, setIsNewUser, setCurrUser, initialUser, setPageMode } = context;

  const handleOpenDialog = (dialogType) => {
    setCurrUser(initialUser);
    setPageMode('new user');
    setIsNewUser(true);
    setOpenDialog(true);
  };

  return (
    <StyledDiv>
      <Card className="request-columns header mobile-table">
        <div className="name-header sort-header" onClick={() => handleSort('Date')}>
          First Name
        </div>
        <div className="name-header sort-header" onClick={() => handleSort('Date')}>
          Last Name
        </div>
        <div className="email-header sort-header" onClick={() => handleSort('FileSize')}>
          Username
        </div>
        <div className="last-headers">
          <div onClick={() => handleSort('StaffOrder')} className=" request-column sort-header">
            Last Login
          </div>
          <div className="request-column sort-header" onClick={() => handleSort('Status')}>
            Order
          </div>
          <div className="request-column ">
            <div className="sort-header" onClick={() => handleSort('FileSize')}>
              Auth
            </div>
          </div>
          <div className="request-column ">
            <div className="sort-header" onClick={() => handleSort('FileSize')}>
              Staff
            </div>
          </div>
          <div className="request-column ">
            <div className="sort-header" onClick={() => handleSort('FileSize')}>
              Admin
            </div>
          </div>
          <div className="request-column ">Edit</div>
          <div className="request-column ">Delete</div>
        </div>
      </Card>
      {loading && <LinearProgress color="inherit" />}
      {userAdminMembers.length > 0 && userAdminMembers.map((user, index) => <UserAdminItem key={index} user={user} />)}
      <br />
      <UserAdminTableFooter onClick={handleOpenDialog} />
      <UserDialog open={openDialog} onClose={setOpenDialog} />
    </StyledDiv>
  );
};

export default UserAdminList;
