import React, { useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTheme } from 'styled-components';
import { deepmerge } from '@mui/utils';

import SigningRequestForm from './signing-request/SigningRequestForm';
import AppConfirmationDialog from '../common/others/AppConfirmationDialog';
import { SigningRequestContext } from '../../context/signing-request.context';
import useDocumentTitle from '../../helpers/hooks/useDocumentTitle';
import LazyFallbackForm from './signing-request/LazyFallbackForm';

const inputCustomTheme = {
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
        inputProps: {
          autoComplete: '--off--',
          form: {
            autoComplete: 'off',
          },
        },
      },
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },

          marginBottom: 0,

          '.MuiOutlinedInput-input': {
            height: '15px',
            lineHeight: '15px',
          },
        },
      },
    },
  },
};

const RequestDetail = ({ editOn, docTitle }) => {
  useDocumentTitle(docTitle || 'Signing Request Detail'); // Set page title

  const mainTheme = useTheme();
  const theme = createTheme(deepmerge(mainTheme, inputCustomTheme));

  const { gettingData, confirmationHandler, handleConfirmation } = useContext(SigningRequestContext);

  return (
    <ThemeProvider theme={theme}>
      <LazyFallbackForm>
        <SigningRequestForm
          editOn={editOn}
          gettingData={gettingData}
          newSigningRequest={false}
          showTitle={'Signing Request'}
        />
      </LazyFallbackForm>
      {confirmationHandler && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={confirmationHandler !== false ? true : false}
          title={confirmationHandler.title}
          content={confirmationHandler.msg}
          buttonTrueText={`${confirmationHandler.event === 'Delete' ? 'Delete' : 'Ok'}`}
          color={`${confirmationHandler.event === 'Delete' ? 'error' : 'primary'}`}
          onClose={() => {}}
          confirmCallback={(val) => handleConfirmation(confirmationHandler.event, val)}
        />
      )}
    </ThemeProvider>
  );
};

export default RequestDetail;
