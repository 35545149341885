import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import CustomPagination from '../../common/others/Pagination';
import SigningRequestItem from './SigningRequestItem';
import LinearProgress from '@mui/material/LinearProgress';
import { ManageRequestContext } from '../../../context/manage-request.context';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { store } from '../../../store';
import { EmptyList } from '../../common/others/EmptyList';
import { scanBackFilterObject } from './filter/models';
import { Div } from '../../../Style';

const RootDiv = styled('div')(
  ({ theme }) => css`
    padding: 2px;
    z-index: 0;
    position: relative;

    & .MuiLinearProgress-root {
      /* top: -9px; */
    }

    & .request-columns {
      display: flex;
      flex-direction: row no-wrap;
      justify-content: space-around;
      line-height: 50px;
      font-weight: 500;
      font-size: small;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: 2px 10px 5px 10px;
      gap: 10px;
      border-left: 1px;
      border-right: 1px;
      z-index: 1;
    }

    & .request-column-without-agent {
      display: none;
    }

    & .sort-header {
      display: flex;
      flex-direction: row;
      justify-content: left;
      color: ${theme.palette.primary.default};
      text-decoration: none;
      cursor: pointer;
      align-items: center;
    }

    & .sort-header:hover {
      text-decoration: underline;
    }
  `
);

const SigningRequestList = (props) => {
  const userRoles = store.getState().reducer.currentUser.userRoles;
  const {
    loading,
    getGeneralRequests,
    controlSetting,
    requestList,
    pageSizesForList,
    filterPayLoad,
    handlePageSize,
    handlePageNumber,
    totalPages,
    handleSort,
    currentPaginationPageSize,
    appliedFilters,
    setAppliedFilters,
    breadcrumbState,
  } = useContext(ManageRequestContext);

  const requestLitInitRef = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (requestLitInitRef.current) {
      if (breadcrumbState?.forceListReload) {
        getGeneralRequests(filterPayLoad);
      } else {
        // Get parameter from other locations. ex: Dashboard
        let hasScanBacks = location.state?.fromDashboard?.hasScanBacks || false;
        let priorToToday = location.state?.fromDashboard?.priorToToday || false;

        if (controlSetting) {
          if (hasScanBacks) {
            let _filtersCopy = _.cloneDeep(appliedFilters);
            let objIndex = _filtersCopy.findIndex((obj) => obj.id == 'requestDocs');
            _filtersCopy[objIndex].value = [scanBackFilterObject];
            _filtersCopy[objIndex].lastEnteredTimestamp = 1;
            setAppliedFilters(_filtersCopy);
          }

          let momentDate = null;
          if (priorToToday) momentDate = moment().add(-1, 'd').toISOString();

          getGeneralRequests({
            ...filterPayLoad,
            requestStatus: hasScanBacks ? '1234569' : filterPayLoad.requestStatus,
            dateTo: priorToToday ? momentDate : filterPayLoad.dateTo,
            requestDocs: hasScanBacks ? [scanBackFilterObject] : [],
          });
        }
      }
      requestLitInitRef.current = false;
    }

    return () => {};
  }, [controlSetting]);

  return (
    <RootDiv>
      <Div className="request-columns">
        <div style={{ flex: 1.5 }} className="sort-header" onClick={() => handleSort('Request')}>
          Req# / Log
        </div>
        <div style={{ flex: 2.5 }} className="sort-header" onClick={() => handleSort('Borrower')}>
          Signer(s)
        </div>
        <div style={{ flex: 2 }} className="sort-header" onClick={() => handleSort('Date')}>
          When
        </div>
        <div style={{ flex: 2.5 }} className="sort-header" onClick={() => handleSort('ContactInfo')}>
          Customer
        </div>
        <div
          style={{ flex: 1 }}
          className={
            userRoles.includes('Customer') && controlSetting.chkShowAgentInfoToCustomer === false
              ? 'request-column-without-agent'
              : 'sort-header'
          }
          onClick={() => handleSort('Agent')}
        >
          Agent
        </div>
        {!(userRoles.includes('Customer') || userRoles.includes('NotaryAgent')) ? (
          <Fragment>
            <div
              style={{ flex: 1.5, justifyContent: 'end' }}
              className="sort-header"
              onClick={() => handleSort('FileSize')}
            >
              Docs
            </div>
            <div style={{ flex: 1, textAlign: 'end' }}>
              <div style={{ justifyContent: 'end' }} onClick={() => handleSort('StaffOrder')} className="sort-header">
                Staff
              </div>
            </div>
          </Fragment>
        ) : (
          <div style={{ flex: 2 }}>
            <div style={{ justifyContent: 'end' }} className="sort-header" onClick={() => handleSort('FileSize')}>
              Docs
            </div>
          </div>
        )}
        <div style={{ flex: 1, textAlign: 'center' }}></div>
      </Div>
      {loading && <LinearProgress color="inherit" />}
      {requestList?.length > 0 ? (
        requestList.map((request, index) => (
          <SigningRequestItem key={index} request={request} history={props.history} />
        ))
      ) : (
        <EmptyList msg="No Results Found" />
      )}
      {requestList?.length > 0 && currentPaginationPageSize() && (
        <CustomPagination
          style={{
            border: 'none',
            marginTop: '5px',
            borderTopLeftRadius: '0 !important',
            borderTopRightRadius: '0 !important',
          }}
          pageSizeOptions={pageSizesForList}
          pageSize={currentPaginationPageSize()}
          pageNumber={filterPayLoad.pageNumber}
          totalPages={totalPages}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
        />
      )}
    </RootDiv>
  );
};

export default SigningRequestList;
