import React, { useEffect, useRef, useState } from 'react';
import { store } from '../store';
import { Button, TextField, Card } from '@mui/material';
import NumberFormat from 'react-number-format';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { userService } from '../services';
import Loading from '../components/common/others/Loading';
import { dispatchAlert, isValidEmail } from '../helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';

const ForgotPassCustomerCss = styled('div')(
  ({ theme }) => css`
    .root {
      max-width: 545px;
      display: inline-block;
    }

    .MuiCard-root:nth-child(n + 1) {
      padding: 30px;
    }

    .portalLogo {
      padding: 20px 20px;
      width: 100%;
      text-align: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .form-inputs {
      font-size: 15px;
    }

    .main-info {
      font-size: 15px;
    }

    label {
      font-size: inherit;
    }

    .error {
      color: #e53935;
      text-align: left;
      display: block;
      font-size: 0.75rem;
    }

    ${theme.breakpoints.down('sm')} {
      .form-inputs {
        display: 'block';
      }
    }
    .link {
      color: ${theme.palette.primary.main};
      text-decoration: none;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .view-title-forgot {
      font-size: 0.8rem;
      padding: 0px 30px;
      @media screen and (max-width: 960px) {
        padding: 0px 0px !important;
      }
    }
  `
);

const initialState = () => {
  return {
    names: '',
    companyName: '',
    email: '',
    phone: '',
    error: false,
    namesError: '',
    companyNameError: '',
    emailError: '',
    phoneError: '',
  };
};

const ForgotCustomerPassword = (props) => {
  useDocumentTitle('Forgot Password'); // Set page title
  const { global } = store.getState().reducer;
  const captchaRef = useRef();

  const [state, setState] = useState(initialState());
  const [adminEmail, setAdminEmail] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  useEffect(() => {
    getBusSettingsEmailAddresses();
    return () => {};
  }, []);

  const getBusSettingsEmailAddresses = () => {
    userService.getBusSettingsAnonymusInfo().then((res) => {
      setAdminEmail(res.mainEmail);
    });
  };

  const onChange = (value) => {
    setState((values) => ({
      ...values,
      verified: true,
      verifiedError: '',
    }));
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;

    setState((values) => ({
      ...values,
      [fieldName]: event.target.value,
      [fieldName + 'Error']: '',
    }));
  };

  const isFormValid = () => {
    let isValid = true;

    if (BUILD_ENV !== 'development') {
      if (!state.verified) {
        isValid = false;
        setState((values) => ({
          ...values,
          verifiedError: 'ReCAPTCHA is required.',
        }));
      }
    }

    if (state.names == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        namesError: 'Name field is required.',
      }));
    } else {
      if (state.names.length < 2) {
        isValid = false;
        setState((values) => ({
          ...values,
          namesError: 'Name field should be a valid Name.',
        }));
      }
    }

    if (state.companyName == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        companyNameError: 'Business Name field is required.',
      }));
    }

    if (state.email == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        emailError: 'Email Address field is required.',
      }));
    } else {
      if (!isValidEmail(state.email)) {
        isValid = false;
        setState((values) => ({
          ...values,
          emailError: 'Email Address field is not a valid email address.',
        }));
      }
    }

    if (state.phone == '') {
      isValid = false;
      setState((values) => ({
        ...values,
        phoneError: 'Phone Number field is required.',
      }));
    }

    return isValid;
  };

  const verifyCaptcha = async () => {
    if (process.env.NODE_ENV !== 'development') {
      try {
        const captchaValue = captchaRef.current.getValue();
        if (!captchaValue) return false;
        else {
          const res = await fetch(`${SERVICE_URL}/portal/verifyCaptcha`, {
            method: 'POST',
            body: JSON.stringify(captchaValue),
            headers: { 'content-type': 'application/json' },
          });
          const data = await res.json();
          if (data.success) return true;
          else return false;
        }
      } catch (error) {
        return false;
      }
    } else return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmit(true);

    if (isFormValid()) {
      if (!(await verifyCaptcha())) {
        dispatchAlert('Sorry. Unable to verify re-captcha.', true);
        setIsSubmit(false);
        captchaRef.current?.reset();
        return;
      }

      userService.forgotCustomerPassword(state).then(
        (res) => {
          dispatchAlert('An email was sent requesting assistance with logging into this website.', false, {
            to: '/login',
            text: 'Click here to login',
            isButton: false,
          });
          setState(initialState());
          setIsSubmit(false);
          if (BUILD_ENV !== 'development') {
            window.grecaptcha.reset();
          }
        },
        (error) => {
          dispatchAlert('There was an error while submiting your information.', true);
          setIsSubmit(false);
        }
      );
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <ForgotPassCustomerCss>
      <div style={{ textAlign: 'center', justifyContent: 'center' }}>
        <div className="root">
          <div className="portalLogo">
            {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
          </div>
          {adminEmail ? (
            <Card>
              <div className="view-title-forgot">We are sorry your are having difficulty logging into our website.</div>
              <div className="view-title-forgot">
                Please provide your information below and we will get back to you shortly with your login information.
              </div>
              <div className="view-title-forgot">
                If you need immidiate assistance please email <b>{adminEmail}</b>
              </div>
              <br />
              <div className="form-inputs">
                <div className="main-info">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <TextField
                      label="Name"
                      name="names"
                      value={state.names}
                      helperText={state.namesError}
                      onChange={handleChange}
                      error={state.namesError != ''}
                    />

                    <TextField
                      label="Email Address"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      helperText={state.emailError}
                      error={state.emailError != ''}
                    />
                    <TextField
                      label="Business name"
                      name="companyName"
                      value={state.companyName}
                      helperText={state.companyNameError}
                      onChange={handleChange}
                      error={state.companyNameError != ''}
                    />
                    <NumberFormat
                      customInput={TextField}
                      format="(###) ###-####"
                      mask="_"
                      label="Phone Number"
                      name="phone"
                      value={state.phone}
                      onChange={handleChange}
                      helperText={state.phoneError}
                      error={state.phoneError != ''}
                    />
                    {BUILD_ENV !== 'development' && (
                      <ReCAPTCHA
                        ref={captchaRef}
                        name="verified"
                        sitekey={RECAPTCHA_KEY}
                        style={{ marginTop: '10px' }}
                        value={state.verified}
                        onChange={onChange}
                      />
                    )}
                    <span className="error">{state.verifiedError}</span>
                    <br />
                    <div style={{ textAlign: 'end' }}>
                      <div style={{ float: 'left', paddingTop: '7px' }}>
                        <Link
                          className="link"
                          to={{
                            pathname: '/login',
                          }}
                          style={{
                            marginRight: '20px',
                            verticalAlign: 'bottom',
                          }}
                        >
                          {'Return to login page'}
                        </Link>
                      </div>
                      <Button type="submit" style={{ float: 'right' }} variant="contained" disabled={isSubmit}>
                        Send {isSubmit && <Loading forButton lpad />}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Card>
          ) : (
            <Loading apiRequest />
          )}
        </div>
      </div>
    </ForgotPassCustomerCss>
  );
};

export default ForgotCustomerPassword;
