import React, { useEffect, useContext, Fragment, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionWrapper } from '../../Style';
import { Checkbox, Chip, FormControl, FormControlLabel, Typography } from '@mui/material';
import { UserProfileContext } from '../../context/user-profile.context';
import ProfileTextField from './ProfileTextField';
import {
  additionalInfoColumns,
  backgroundChecksColumns,
  emailColumns,
  insuranceColumns,
  licenseColumns,
  linksColumns,
  phoneNumbers,
  pricingColumns,
} from './ProfileContent/profileInfo';
import { EditableTable } from '../common/others/EditableTable';
import ProfileDDL from './ProfileDDL';
import { CountryStates } from '../common/ddls/CountryStates';
import { store } from '../../store';
import { AppTextarea } from '../common/others/NSTextarea';
import { getValidURLOr, isValidUrl, validateURLOr } from '../../helpers';

function formatDate(string) {
  var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  let returnDate = new Date(string).toLocaleDateString([], options);
  return returnDate == 'Invalid Date' ? '' : returnDate;
}

const StyledSection = styled('div')(
  ({ theme, $editMode, $canEdit, $withMenu }) => css`
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.between('md', 'lg')} {
      & {
        display: ${$withMenu ? 'block' : 'flex'};
      }
    }

    .col:nth-child(1) {
      margin-right: 10px;
      flex: 1;
    }

    .col:nth-child(2) {
      /* margin-left: 10px; */
      flex: 1;
    }

    .profile-row {
      display: flex;
      align-items: center;
      padding-right: 0px !important;
      height: ${!$editMode || !$canEdit ? '25px' : 'initial'};
      width: 100%;
      margin-bottom: 7px;

      > div:nth-child(1) {
        flex: 1;
      }

      > div:nth-child(2) {
        flex: 3;
      }

      .split {
        > div:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .same-as-billing {
      right: 20px;
      align-items: end;
      width: 165px;
      position: absolute;
      top: 0;
      line-height: 50px;
    }

    ${theme.breakpoints.down('md')} {
      & {
        display: block;
      }

      & .col:nth-child(1) {
        margin-right: 0px;
      }

      & .col:nth-child(2) {
        margin-left: 0px;
      }
    }

    .agent-row {
      width: 100%;
      margin-bottom: 15px;
    }

    & .availability,
    .capability,
    .languages {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 5px;
      flex-wrap: wrap;

      ${theme.breakpoints.between('md', 'lg')} {
        & {
          display: ${$withMenu ? 'block' : 'flex'};
        }
      }
    }
  `
);

const AvailabilityChip = styled(Chip)(
  ({ theme }) => css`
    opacity: 1 !important;
  `
);

function ProfileAccordion(props) {
  const { index, title, children, expandAll, editMode, canEdit } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [_expandAll, _setExpandAll] = React.useState(expandAll);

  useEffect(() => {
    setExpanded(!expanded);
    _setExpandAll(expandAll);
    return () => {};
  }, [expandAll]);

  const handleChange = (panel) => (event, isExpanded) => {
    _setExpandAll(false);
    setExpanded(isExpanded ? panel : false);
  };

  const setOpacity = () => {
    return editMode ? (canEdit ? 1 : 0.8) : 1;
  };

  return (
    <AccordionWrapper mb={'10px'}>
      <Accordion
        // sx={{ opacity: setOpacity() }}
        expanded={expanded === `panel${index}` || _expandAll}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          id={`panel${index}bh-header`}
          sx={{ color: '#266b7b' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
        >
          <div className={`summaryWrapper ${expanded === `panel${index}` ? 'expanded' : ''}`}>
            <strong>{title}</strong>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start !important',
            marginRight: '0px !important',
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  );
}

const AgentProfile = (props) => {
  const theme = useTheme();
  const context = useContext(UserProfileContext);
  const {
    updatedProfile,
    setUpdatedProfile,
    ownerData,
    setOwnerData,
    profileInfo,
    setProfileInfo,
    lookupList,
    mapElementFreeText,
  } = context;

  const { canEdit, editMode, disabled } = props;
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const { current: menuState } = store.getState().reducer.menuState;

  const billingAddress = profileInfo.Addresses?.find((address) => {
    return address.name?.toLowerCase().includes('billing');
  });

  const shippingAddress = profileInfo.Addresses?.find((address) => {
    return address.name?.toLowerCase().includes('shipping');
  });

  useEffect(() => {
    setSameAsBilling(billingAddress?.isShipping);
    return () => {};
  }, [billingAddress]);

  // update functions for when it's time to add them to the Agent profile
  const profileTextfieldUpdate = (value, profileObj, profileItem) => {
    if (profileObj == {}) {
      setUpdatedProfile({ ...updatedProfile, [profileObj]: value });
    } else {
      setUpdatedProfile((updatedProfile) => {
        return {
          ...updatedProfile,
          [profileObj]: { ...profileInfo[profileObj], [profileItem]: value },
        };
      });
    }
    setProfileInfo((profileInfo) => {
      return {
        ...profileInfo,
        [profileObj]: {
          ...profileInfo[profileObj],
          [profileItem]: value,
        },
      };
    });
  };

  return (
    <StyledSection $editMode={editMode} $canEdit={canEdit} $withMenu={menuState == 'expanded' ? true : false}>
      <div className="col">
        <ProfileAccordion title={'Name & Company'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="profile-row">
            <ProfileTextField title value={'Name:'} />
            {editMode && canEdit ? (
              <div className="split" style={{ display: 'flex' }}>
                <ProfileTextField
                  edit={canEdit}
                  disabled={disabled}
                  value={profileInfo.Entities?.[0].firstName}
                  onChange={(e) => profileTextfieldUpdate(e.target.value, 'nameAndCompany', 'firstName')}
                />
                <ProfileTextField
                  edit={canEdit}
                  disabled={disabled}
                  value={profileInfo.Entities?.[0].lastName}
                  onChange={(e) => profileTextfieldUpdate(e.target.value, 'nameAndCompany', 'lastName')}
                />
              </div>
            ) : (
              <ProfileTextField
                edit={canEdit}
                disabled={disabled}
                value={`${profileInfo.Entities?.[0].firstName} ${profileInfo.Entities?.[0].lastName}` || ''}
              />
            )}
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Company:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={profileInfo.Entities?.[0].parentName || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'nameAndCompany', 'company')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Est.'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={formatDate(profileInfo.Attributes?.[0]?.value) || ''}
            />
          </div>
        </ProfileAccordion>

        <ProfileAccordion title={'Billing'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="profile-row">
            <ProfileTextField title value={'Address:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={billingAddress?.street || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'billingAddress', 'street')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Address2:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={billingAddress?.line2 || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'billingAddress', 'line2')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'City:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={billingAddress?.city || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'billingAddress', 'city')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'State:'} />
            {/* <CountryStates
              disabled={edit ? true : false}
              value={profileInfo.billingAddress?.region}
              handleChange={
              }}
            /> */}
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={billingAddress?.state || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'billingAddress', 'state')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Zip:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled}
              value={billingAddress?.zip || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'billingAddress', 'postalCode')}
            />
          </div>
        </ProfileAccordion>

        <ProfileAccordion title={'Shipping'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="same-as-billing">
            <FormControlLabel
              label={'Same as Billing'}
              disabled={!canEdit}
              checked={sameAsBilling}
              onChange={(e) => {
                setSameAsBilling(e.target.checked);
                profileTextfieldUpdate(e.target.checked, 'billingAddress', 'isShipping');
              }}
              control={<Checkbox size="small" />}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Address:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled || sameAsBilling}
              value={sameAsBilling ? billingAddress?.street : shippingAddress?.street || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'shippingAddress', 'street')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Address2:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled || sameAsBilling}
              value={sameAsBilling ? billingAddress?.line2 : shippingAddress?.line2 || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'shippingAddress', 'line2')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'City:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled || sameAsBilling}
              value={sameAsBilling ? billingAddress?.city : shippingAddress?.city || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'shippingAddress', 'city')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'State:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled || sameAsBilling}
              value={sameAsBilling ? billingAddress?.state : shippingAddress?.state || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'shippingAddress', 'state')}
            />
          </div>
          <div className="profile-row">
            <ProfileTextField title value={'Zip:'} />
            <ProfileTextField
              edit={canEdit}
              disabled={disabled || sameAsBilling}
              value={sameAsBilling ? billingAddress?.zip : shippingAddress?.zip || ''}
              onChange={(e) => profileTextfieldUpdate(e.target.value, 'shippingAddress', 'zip')}
            />
          </div>
        </ProfileAccordion>

        <ProfileAccordion
          title={'Pricing Information'}
          editMode={editMode}
          canEdit={canEdit}
          expandAll={props.expandAll}
        >
          <EditableTable
            addNew
            maxRows={5}
            edit={canEdit}
            columns={pricingColumns}
            rows={profileInfo.Pricings_v1Schema || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                pricingList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                pricingList: e.state,
              });
            }}
          />
          <br />
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Other Pricing Notes:
          </Typography>
          <AppTextarea
            minRows={2}
            maxRows={4}
            maxLength={500}
            disabled={disabled}
            edit={canEdit}
            value={mapElementFreeText(8)?.value}
            onChange={(val) => {}}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Spoken Languages'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="languages">
            {canEdit ? (
              <ProfileDDL
                options={lookupList.SelectLanguageTypes || []}
                selectedItems={profileInfo.Languages || []}
                label="Spoken Languages"
                onChange={(value) => {
                  setProfileInfo({
                    ...profileInfo,
                    languageList: value,
                  });
                }}
              />
            ) : (
              profileInfo.Languages?.map((language, index) => <Chip key={language.id} label={language.lookupName} />)
            )}
          </div>
        </ProfileAccordion>

        <ProfileAccordion title={'Websites'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="agent-row">
            {profileInfo.Websites?.map((website) => (
              <ul style={{ padding: 0 }} key={website.id}>
                <li>
                  <a
                    style={{ color: `${theme.palette.mode == 'dark' ? 'white' : 'black'}` }}
                    href={`//${website.url}`}
                    target="_blank"
                  >
                    {website.url}
                  </a>
                </li>
              </ul>
            ))}
          </div>
        </ProfileAccordion>

        <ProfileAccordion
          title={'Additional Information'}
          editMode={editMode}
          canEdit={canEdit}
          expandAll={props.expandAll}
        >
          <EditableTable
            addNew
            edit={canEdit}
            columns={additionalInfoColumns}
            rows={profileInfo.CustomFields || []}
            emitNewRows={(e) => {}}
          />
        </ProfileAccordion>
      </div>

      <div className="col">
        <ProfileAccordion title={'Phone Numbers'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <EditableTable
            addNew
            edit={canEdit}
            lookups={lookupList.SelectPhoneTypes || []}
            columns={phoneNumbers}
            rows={profileInfo.PhoneNumbers || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                phoneNumberList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                phoneNumberList: e.state,
              });
            }}
          />
          <br />
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Other Contact Info:
          </Typography>
          <AppTextarea
            minRows={2}
            maxRows={4}
            maxLength={500}
            disabled={disabled}
            edit={canEdit}
            value={mapElementFreeText(7)?.value}
            onChange={(val) => {}}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Email Addresses'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <EditableTable
            edit={canEdit}
            lookups={lookupList.SelectEmailTypes || []}
            columns={emailColumns}
            rows={profileInfo.Emails || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                pricingList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                pricingList: e.state,
              });
            }}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Document Links'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <EditableTable
            hideUrl
            edit={canEdit}
            lookups={lookupList.SelectLinkTypes || []}
            columns={linksColumns}
            rows={profileInfo.Links || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                pricingList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                pricingList: e.state,
              });
            }}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'State Licenses'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <EditableTable
            edit={canEdit}
            columns={licenseColumns}
            rows={profileInfo.Licenses || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                licenseList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                licenseList: e.state,
              });
            }}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Insurances'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <EditableTable
            edit={canEdit}
            columns={insuranceColumns}
            rows={profileInfo.Insurances || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                insuranceList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                insuranceList: e.state,
              });
            }}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Background Checks'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <EditableTable
            edit={canEdit}
            lookups={lookupList.SelectProviderTypes || []}
            columns={backgroundChecksColumns}
            rows={profileInfo.BackgroundChecks || []}
            emitNewRows={(e) => {
              setUpdatedProfile({
                ...updatedProfile,
                backgroundChecksList: e.state,
              });
              setProfileInfo({
                ...profileInfo,
                backgroundChecksList: e.state,
              });
            }}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Capabilities'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="capability">
            {canEdit ? (
              <ProfileDDL
                label="Capabilities"
                options={lookupList.SelectCapabilityTypes || []}
                selectedItems={profileInfo.Capabilities || []}
                onChange={(value) => {
                  setProfileInfo({
                    ...profileInfo,
                    capabilityList: value,
                  });
                }}
              />
            ) : (
              profileInfo.Capabilities?.map((item, index) => (
                <Fragment key={item.capabilityTypeId}>{item.isCapable && <Chip label={item.lookupName} />}</Fragment>
              ))
            )}
          </div>
          <br />
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Other Capability Information:
          </Typography>
          <AppTextarea
            minRows={2}
            maxRows={4}
            maxLength={500}
            disabled={disabled}
            edit={canEdit}
            value={mapElementFreeText(2)?.value}
            onChange={(val) => {}}
          />
        </ProfileAccordion>

        <ProfileAccordion title={'Availability'} editMode={editMode} canEdit={canEdit} expandAll={props.expandAll}>
          <div className="availability">
            {profileInfo.Availabilities?.map((item, index) => (
              <Fragment key={item.id}>
                {(canEdit || item.available_Mon) && (
                  <AvailabilityChip
                    label="Monday"
                    size="small"
                    color={item.available_Mon ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Mon = !item.available_Mon;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                {(canEdit || item.available_Tue) && (
                  <AvailabilityChip
                    label="Tuesday"
                    size="small"
                    color={item.available_Tue ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Tue = !item.available_Tue;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                {(canEdit || item.available_Wed) && (
                  <AvailabilityChip
                    label="Wednesday"
                    size="small"
                    color={item.available_Wed ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Wed = !item.available_Wed;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                {(canEdit || item.available_Thu) && (
                  <AvailabilityChip
                    size="small"
                    label="Thursday"
                    color={item.available_Thu ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Thu = !item.available_Thu;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                {(canEdit || item.available_Fri) && (
                  <AvailabilityChip
                    size="small"
                    label="Friday"
                    color={item.available_Fri ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Fri = !item.available_Fri;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                {(canEdit || item.available_Sat) && (
                  <AvailabilityChip
                    size="small"
                    label="Saturday"
                    color={item.available_Sat ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Sat = !item.available_Sat;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                {(canEdit || item.available_Sun) && (
                  <AvailabilityChip
                    label="Sunday"
                    size="small"
                    color={item.available_Sun ? 'secondary' : 'default'}
                    disabled={!canEdit}
                    onClick={() => {
                      let availability = _.cloneDeep(profileInfo.availabilityList);
                      availability[0].available_Sun = !item.available_Sun;
                      setProfileInfo({
                        ...profileInfo,
                        availabilityList: availability,
                      });
                    }}
                  />
                )}
                <FormControl className="availability-row" disabled={!canEdit}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.available_AM}
                        onChange={(e) => {
                          let availability = _.cloneDeep(profileInfo.availabilityList);
                          availability[0].available_AM = Boolean(e.target.checked);
                          setProfileInfo({
                            ...profileInfo,
                            availabilityList: availability,
                          });
                        }}
                      />
                    }
                    label="AM"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.available_PM}
                        onChange={(e) => {
                          let availability = _.cloneDeep(profileInfo.availabilityList);
                          availability[0].available_PM = e.target.checked;
                          setProfileInfo({
                            ...profileInfo,
                            availabilityList: availability,
                          });
                        }}
                      />
                    }
                    label="PM"
                  />
                </FormControl>
              </Fragment>
            ))}
          </div>
          <br />
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Other Availability Information:
          </Typography>
          <AppTextarea
            minRows={2}
            maxRows={4}
            maxLength={500}
            disabled={disabled}
            edit={canEdit}
            value={mapElementFreeText(13)?.value}
            onChange={(val) => {}}
          />
        </ProfileAccordion>
      </div>
    </StyledSection>
  );
};

export default AgentProfile;
