import React, { Fragment, useContext, useState } from 'react';
import Card from '@mui/material/Card';
import { IconButton, Menu, MenuItem, ListItemIcon, Divider, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { PersonOutline } from '@mui/icons-material';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import CardHeader from '@mui/material/CardHeader';
import { SigningRequestContext } from '../../../context/signing-request.context';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';
import AppointmentDialog from '../../manage-request/AppointmentDialog';
import UploadFilesDialog from '../../common/file-uploader/upload-files-dialog';
import FileUploadProvider from '../../common/file-uploader/FileUploadContext';
import AssignStaffDialog from '../../manage-request/AssignStaffDialog';
import { StyledMenu, StyledBadge } from '../../../Style';
import { format, parseISO } from 'date-fns';
import { store } from '../../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '5px',

    '& .MuiCardHeader-root': {
      padding: '10px',
      borderBottom: theme.palette.mode === 'dark' ? '1px solid rgb(48, 48, 48)' : 0,
      textAlign: 'center',
      fontWeight: 'bold',
    },

    '& .fancy-line': {
      border: 0,
      height: '1px',
      position: 'relative',
      margin: '0.5em 0',
    },

    '& .fancy-line:before': {
      top: '-0.5em',
      height: '1em',
    },

    '& .fancy-line:after': {
      height: '0.5em',
      top: 'calc(-0.5em + 1px)' /* adjusted this */,
    },

    '& .fancy-line:before, .fancy-line:after': {
      content: '""',
      position: theme.palette.mode === 'dark' ? 'absolute' : '',
      width: '100%',
    },

    '& .fancy-line, .fancy-line:before': {
      background: 'radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%)',
    },

    '& .fancy-line:after': {
      background: '#f4f4f4',
    },

    '& .MuiSvgIcon-root': {
      marginRight: '3px',
      color: '#0068a9',
    },

    [theme.breakpoints.down('xl')]: {
      '& .hideAcctions': {
        display: 'none !important',
      },
      '& .showMoreAcctionBtn': {
        display: 'flex !important',

        '& .MuiTypography-body2': {
          textTransform: 'capitalize',
        },
      },
    },

    [theme.breakpoints.up('lg')]: {
      '& .hideAcctions': {
        display: 'contents !important',
        '& > div': {
          justifyContent: 'space-evenly',
        },
      },
      '& .showMoreAcctionBtn': {
        display: 'none !important',
      },
    },
  },

  links: {
    display: 'flex',
    position: 'relative',

    '& > div:nth-child(1)': {
      flexGrow: 1,
      fontSize: '13px',
      margin: '5px',
      textAlign: 'right',
      minWidth: '100px',
    },

    [theme.breakpoints.up('lg')]: {
      '& > div:nth-child(1)': {
        minWidth: '125px',
        maxWidth: '125px',
      },
    },

    '& > div:nth-child(2)': {
      flexGrow: 6,
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      position: 'relative',
      marginLeft: '15px',

      '& > div:not(.fancy-line)': {
        display: 'flex',
        marginRight: '20px',
        borderRadius: '10px',
        alignItems: 'center',

        '& .MuiTypography-body2': {
          fontSize: '12px',
          // textTransform: 'uppercase',
        },
      },

      '& div.hideAcctions': {
        '& > div': {
          // flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '5px',
          borderRadius: '10px',
        },
      },

      '& > div.hideAcctions': {
        display: 'contents',
      },
    },

    '& .MuiDivider-root': {
      height: '15px',
      alignSelf: 'center',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0), 0px 1px 4px 0px rgb(0 0 0 / 58%), 0px -1px 8px -1px rgb(0 0 0 / 40%)',
    },
  },
}));

export function CustomizedBadges(docs) {
  return (
    <div size="small" aria-label="cart" style={{ marginRight: parseInt(docs) > 0 ? '10px' : '0px' }}>
      <StyledBadge badgeContent={docs} color="primary">
        <AttachFileOutlinedIcon sx={{ color: '#76a6d5' }} />
      </StyledBadge>
    </div>
  );
}

const RequestActions = ({ forMobile = false }) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    pageMode,
    requestID,
    gettingData,
    getSigningRequestDetail,
    signingRequest,
    setSigningRequest,
    assignStaffToRequest,
    parseSendResponse,
  } = useContext(SigningRequestContext);

  const [apptTypeDialog, setApptTypeDialog] = React.useState();
  const [openAssignStaff, setOpenAssignStaff] = React.useState(false);
  const [confirmationHandler, setConfirmationHandler] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [documentData, setDocumentData] = React.useState(null);
  const [openDocumentUploadDialog, setOpenDocumentUploadDialog] = React.useState(false);
  const [moreOptionAnchorEl, setMoreOptionAnchorEl] = React.useState(null);
  const moreOptionOpen = Boolean(moreOptionAnchorEl);

  const userRoles = store.getState().reducer.currentUser.userRoles;
  const ownerOrSuperUser = userRoles.indexOf('SuperUser') >= 0 || userRoles.indexOf('ServiceOwner') >= 0;

  if (!signingRequest || parseInt(signingRequest?.requestID) == 0 || !ownerOrSuperUser) {
    return <React.Fragment></React.Fragment>;
  }

  const callOpenDocumentUploadDialog = () => {
    setDocumentData({
      title:
        'Request #' +
        signingRequest.requestSeqID +
        ' - ' +
        [signingRequest.borrower.borrowerFirstName, signingRequest.borrower.borrowerLastName].join(' ') +
        ' ' +
        format(parseISO(signingRequest.signingDate), 'MM/dd/yyyy'),
      requestID: signingRequest.requestID,
      requestSeqID: signingRequest.requestSeqID,
    });

    setOpenDocumentUploadDialog(true);
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleConfirmation = (event, value) => {
    if (value) handleRequestActionPerform(event);
    setConfirmationHandler(false);
  };

  const handleOpenAssignStaff = () => {
    setOpenAssignStaff(true);
    setMoreOptionAnchorEl(null);
  };

  const handleCloseAssignStaff = (value) => {
    if (value === 'cancel') {
      setOpenAssignStaff(false);
    } else {
      setOpenAssignStaff(false);
      setSigningRequest({
        ...signingRequest,
        assignedStaffID: value.staffID,
        assignedStaffName: value.staffName,
      });
      assignStaffToRequest(requestID, value);
    }
  };

  const handleRequestActionPerform = (response, redirectTo) => {
    setApptTypeDialog();
    if (response) parseSendResponse(response);
    if (redirectTo) history.push(redirectTo);
    else getSigningRequestDetail();
  };

  const navigateTo = (to) => {
    if (to === 'log') {
      history.push(`/manage-request/detail/${requestID}/log`);
    }
  };

  const getProfileLink = () => {
    if (userRoles.includes('Customer') || !signingRequest.assignedAgentPublicID) return '';
    return `/manage-request/contact/agent/${signingRequest.assignedAgentPublicID}`;
  };

  return (
    <Fragment>
      {forMobile ? (
        <Fragment>
          <IconButton
            size="small"
            aria-expanded={moreOptionOpen ? 'true' : undefined}
            aria-haspopup="true"
            style={{ float: 'right', margin: '0px 0px 10px 0px' }}
            onClick={(event) => setMoreOptionAnchorEl(event.currentTarget)}
          >
            <MoreVertOutlinedIcon fontSize="small" />
          </IconButton>
          <Menu
            id="request-mobile-menu-item"
            sx={{
              '.MuiMenuItem-root:not(.Mui-disabled,.dont-inherit)': {
                paddingLeft: '35px',
              },
            }}
            PaperProps={{ style: { width: '25ch' } }}
            MenuListProps={{
              style: {},
            }}
            anchorEl={moreOptionAnchorEl}
            open={moreOptionOpen}
            onClose={() => setMoreOptionAnchorEl(null)}
          >
            <MenuItem className="dont-inherit">
              <div style={{ fontWeight: '600' }}>Appointment</div>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                setApptTypeDialog({
                  type: 'Confirm',
                  icon: <SendOutlinedIcon className="rotate" />,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <SendOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Confirm</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                setApptTypeDialog({
                  type: 'Changes',
                  icon: <HelpOutlineIcon />,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <HelpOutlineIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Changes</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                setApptTypeDialog({
                  type: 'Decline',
                  icon: <CancelScheduleSendOutlinedIcon />,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <CancelScheduleSendOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Decline</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                setApptTypeDialog({
                  type: 'Cancel',
                  icon: <CancelOutlinedIcon />,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <CancelOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Cancel</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                setApptTypeDialog({
                  type: 'Complete',
                  icon: <PlaylistAddCheckOutlinedIcon />,
                  moreOnSend: ['Send and Invoice'],
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <PlaylistAddCheckOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Mark Complete</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                setApptTypeDialog({
                  type: 'NotaryNote',
                  icon: <EventNoteOutlinedIcon />,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <EventNoteOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Notary Note</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem className="dont-inherit">
              <div style={{ fontWeight: '600' }}>Billing</div>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                history.push({
                  pathname: `/manage-request/invoice/customer/${requestID}`,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <ReceiptOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Invoice</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                history.push({
                  pathname: `/manage-request/invoice/customer/payment/${requestID}`,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <MonetizationOnOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Record Payment</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                history.push({
                  pathname: `/manage-request/invoice/agent/payment/${requestID}`,
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <MoneyOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Pay Notary</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem className="dont-inherit">
              <div style={{ fontWeight: '600' }}>Other</div>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                navigateTo('log');
              }}
            >
              <ListItemIcon>
                <FolderOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>Request Log</ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                history.push({
                  pathname: `/manage-request/assign-notary/${requestID}`,
                  search: '',
                  state: {
                    fromRequestList: false,
                    zip: signingRequest.signingZipForProximity,
                    assignedAgent: signingRequest.assignedAgentPublicID,
                  },
                });
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <AssignmentIndOutlinedIcon fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>
                {signingRequest.assignedAgentPublicID
                  ? `Assigned: ${signingRequest.assignedAgentName}`
                  : 'Assign Notary'}
              </ListItemText>
            </MenuItem>
            <MenuItem className="dont-inherit" onClick={handleOpenAssignStaff}>
              <ListItemIcon>
                <PersonOutline fontSize="small" sx={{ color: '#76a6d5' }} />
              </ListItemIcon>
              <ListItemText>
                {signingRequest.assignedStaffID > 1
                  ? `Staff :${signingRequest.assignedStaffName?.substr(5)}`
                  : 'Assign Staff'}
              </ListItemText>
            </MenuItem>
            <MenuItem
              className="dont-inherit"
              onClick={(e) => {
                callOpenDocumentUploadDialog();
                setMoreOptionAnchorEl(null);
              }}
            >
              <ListItemIcon>{CustomizedBadges(signingRequest.totalDocs)}</ListItemIcon>
              <ListItemText>{'   '} Documents</ListItemText>
            </MenuItem>
          </Menu>
        </Fragment>
      ) : (
        <Card className={`${classes.root}`}>
          <CardHeader
            disableTypography={true}
            title="Request Actions"
            action={
              gettingData || pageMode == 'edit' ? <LockOutlinedIcon sx={{ color: 'darkgray !important' }} /> : <></>
            }
          />
          <div className={`${classes.links}`}>
            <div>
              <b>Appointment</b>
            </div>
            <div>
              <div>
                <SendOutlinedIcon color="info" />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    setApptTypeDialog({
                      type: 'Confirm',
                      icon: <SendOutlinedIcon className="rotate" />,
                    });
                  }}
                >
                  Confirm
                </Link>
                {signingRequest.appointmentConfirmed && <CheckOutlinedIcon color="success" />}
              </div>
              <div>
                <HelpOutlineIcon />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    setApptTypeDialog({
                      type: 'Changes',
                      icon: <HelpOutlineIcon />,
                    });
                  }}
                >
                  Changes
                </Link>
              </div>
              <div>
                <CancelScheduleSendOutlinedIcon />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    setApptTypeDialog({
                      type: 'Decline',
                      icon: <CancelScheduleSendOutlinedIcon />,
                    });
                  }}
                >
                  Decline
                </Link>
              </div>
              <div>
                <CancelOutlinedIcon />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    setApptTypeDialog({
                      type: 'Cancel',
                      icon: <CancelOutlinedIcon />,
                    });
                  }}
                >
                  Cancel
                </Link>
              </div>
              <div className="hideAcctions">
                <div>
                  <PlaylistAddCheckOutlinedIcon />
                  <Link
                    variant="body2"
                    component="button"
                    color="inherit"
                    disabled={gettingData || pageMode == 'edit'}
                    onClick={(e) => {
                      setApptTypeDialog({
                        type: 'Complete',
                        icon: <PlaylistAddCheckOutlinedIcon />,
                        moreOnSend: ['Send and Invoice'],
                      });
                    }}
                  >
                    Mark Complete
                  </Link>
                  {signingRequest.appointmentComplete && <CheckOutlinedIcon color="success" />}
                </div>
                <div>
                  <EventNoteOutlinedIcon />
                  <Link
                    variant="body2"
                    component="button"
                    color="inherit"
                    disabled={gettingData || pageMode == 'edit'}
                    onClick={(e) => {
                      setApptTypeDialog({
                        type: 'NotaryNote',
                        icon: <EventNoteOutlinedIcon />,
                      });
                    }}
                  >
                    Notary Note
                  </Link>
                  {signingRequest.appointmentNotaryNote && <CheckOutlinedIcon color="success" />}
                </div>
              </div>
              <div className="showMoreAcctionBtn">
                <Link
                  color="inherit"
                  aria-controls="show-more"
                  aria-haspopup="true"
                  variant="body2"
                  component="button"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={handleClick}
                >
                  More ...
                </Link>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={(e) => {
                      setApptTypeDialog({
                        type: 'Complete',
                        icon: <PlaylistAddCheckOutlinedIcon />,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <PlaylistAddCheckOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText color="default" primary="Mark Complete" />
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setApptTypeDialog({
                        type: 'NotaryNote',
                        icon: <EventNoteOutlinedIcon />,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <EventNoteOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    EventNoteOutlinedIcon
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
          </div>
          <div className="fancy-line"></div>
          <div className={`${classes.links}`}>
            <div>
              <b>Billing</b>
            </div>
            <div>
              <div>
                <ReceiptOutlinedIcon color="default" />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    history.push({
                      pathname: `/manage-request/invoice/customer/${requestID}`,
                    });
                  }}
                >
                  Invoice
                </Link>
                {signingRequest.invoiced && <CheckOutlinedIcon color="success" />}
              </div>
              <div>
                <MonetizationOnOutlinedIcon color="default" />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    //handleRequestActionPerform(e);
                    history.push({
                      pathname: `/manage-request/invoice/customer/payment/${requestID}`,
                    });
                  }}
                >
                  Record Payment
                </Link>
                {signingRequest.invoicePaid && <CheckOutlinedIcon color="success" />}
              </div>
              <div>
                <MoneyOutlinedIcon color="default" />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    history.push({
                      pathname: `/manage-request/invoice/agent/payment/${requestID}`,
                    });
                  }}
                >
                  Pay Notary
                </Link>
                {signingRequest.notaryPaid && <CheckOutlinedIcon color="success" />}
              </div>
            </div>
          </div>
          <div className="fancy-line"></div>
          <div className={`${classes.links}`}>
            <div>
              <b>Other</b>
            </div>
            <div>
              <div>
                <FolderOutlinedIcon />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={() => navigateTo('log')}
                >
                  Request Log
                </Link>
              </div>
              <div>
                <IconButton
                  disableRipple={signingRequest.assignedAgentPublicID ? false : true}
                  size="small"
                  onClick={(e) => {
                    signingRequest.assignedAgentPublicID ? history.push(getProfileLink()) : e.stopPropagation();
                  }}
                >
                  <PersonPinCircleOutlinedIcon />
                </IconButton>
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => {
                    history.push({
                      pathname: `/manage-request/assign-notary/${requestID}`,
                      search: '',
                      state: {
                        fromRequestList: false,
                        zip: signingRequest.signingZipForProximity, // this is a copy of the borrower zipcode
                        assignedAgent: signingRequest.assignedAgentPublicID,
                      },
                    });
                  }}
                >
                  {signingRequest.assignedAgentPublicID
                    ? `Assigned: ${signingRequest.assignedAgentName}`
                    : 'Assign Notary'}
                </Link>
              </div>
              <div>
                <PersonOutline />
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={handleOpenAssignStaff}
                >
                  {signingRequest.assignedStaffID > 1
                    ? `Staff :${signingRequest.assignedStaffName?.substr(5)}`
                    : 'Assign Staff'}
                </Link>
              </div>
              <div>
                {CustomizedBadges(signingRequest.totalDocs)}
                <Link
                  variant="body2"
                  component="button"
                  color="inherit"
                  disabled={gettingData || pageMode == 'edit'}
                  onClick={(e) => callOpenDocumentUploadDialog()}
                >
                  {'   '} Documents
                </Link>
              </div>
            </div>
          </div>
        </Card>
      )}
      {apptTypeDialog && (
        <AppointmentDialog
          open={apptTypeDialog ? true : false}
          content={apptTypeDialog}
          confirmCallback={handleRequestActionPerform}
        />
      )}
      <AppConfirmationDialog
        id="appConfirmationDialog"
        open={confirmationHandler !== false ? true : false}
        title={'Confirmation'}
        content={confirmationHandler.msg}
        buttonTrueOnLeft={true}
        onClose={() => {}}
        confirmCallback={(val) => handleConfirmation(confirmationHandler.event, val)}
      />
      {openAssignStaff && (
        <AssignStaffDialog open={openAssignStaff} request={signingRequest} onClose={handleCloseAssignStaff} />
      )}
      {openDocumentUploadDialog && (
        <FileUploadProvider
          context={{
            uploadDocType: 'SigningFiles',
            requestID: signingRequest.requestID,
          }}
        >
          <UploadFilesDialog
            open={openDocumentUploadDialog}
            title={'Documents Upload'}
            buttonTrueText={'Save'}
            buttonFalseText={'Close'}
            documentData={documentData}
            onClose={(e) => setOpenDocumentUploadDialog(false)}
          />
        </FileUploadProvider>
      )}
    </Fragment>
  );
};

export default RequestActions;
