import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Card,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
} from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { ContactManagerContext } from '../../../context/contact-manager.context';
import { signingRequestService as reqService } from '../../../services';
import Loading from '../../common/others/Loading';
import { dispatchAlert, getJsonOr, parseFeeTypesForArrayLookup } from '../../../helpers';
import { EditableTable } from '../../common/others/EditableTable';
import { store } from '../../../store';
import { HtmlTooltip } from '../../../Style';
import AppointmentDialog from '../../manage-request/AppointmentDialog';
import { useHistory, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../helpers/hooks/useDocumentTitle';

const StyledAssignNotary = styled(Card)(
  ({ theme, $menuState }) => css`
    padding: 10px;
    margin-bottom: 5px;
    display: grid;
    row-gap: 3px;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'requestWrapper agreedFeesWrapper'
      'proposedFeesWrapper actionsWrapper';

    #requestWrapper {
      grid-area: requestWrapper;
    }

    #agreedFeesWrapper {
      grid-area: agreedFeesWrapper;
    }

    #proposedFeesWrapper {
      grid-area: proposedFeesWrapper;
    }

    #actionsWrapper {
      grid-area: actionsWrapper;
      text-align: end;
      text-align: center;
      /* align-self: center; */

      .buttons {
        margin-top: 10px;
        overflow: hidden;
      }

      .buttons {
        button:nth-child(1) {
          float: left;
          display: flex;
        }

        button:nth-child(2) {
          float: right;
        }
      }
    }

    /* ${theme.breakpoints.only('sm')} {
      grid-template-areas:
        'requestWrapper actionsWrapper'
        'agreedFeesWrapper proposedFeesWrapper';
    } */

    /* ${theme.breakpoints.up('md')} {
      grid-template-areas:
        'requestWrapper actionsWrapper'
        'agreedFeesWrapper proposedFeesWrapper';
    } */

    /* ${theme.breakpoints.up('1200')} {
      grid-template-columns: 1.5fr 1fr;
      grid-template-areas:
        'requestWrapper actionsWrapper'
        'agreedFeesWrapper proposedFeesWrapper';
    }

    ${theme.breakpoints.up('1300')} {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'requestWrapper actionsWrapper'
        'proposedFeesWrapper agreedFeesWrapper';
    } */

    ${theme.breakpoints.down('md')} {
      grid-template-columns: 1fr;
      grid-template-areas:
        'requestWrapper'
        'proposedFeesWrapper'
        'agreedFeesWrapper'
        'actionsWrapper';
    }

    #requestWrapper {
      .request-info-header {
        display: flex;
        padding: 0 2px 0 10px;
        font-size: 1rem;
        min-height: 40px;
        background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#fbfbfb'};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};

        .assigned-name {
          display: flex;
          flex-grow: 1;
          align-self: center;

          > div:nth-child(1) {
            min-width: 105px;
            font-size: 0.85rem;

            font-weight: 500;
            margin-right: 10px;
            line-height: 30px;
          }

          .MuiSvgIcon-root {
            color: #c1a20f;
            align-self: center;
          }

          span:nth-child(3) {
            color: #1e87ab;
            vertical-align: middle;
            align-self: center;
            margin-left: 10px;
            margin-top: 3px;
          }
        }

        .assigned-name.agent-was-assgined {
          > div:nth-child(2) {
            .MuiSvgIcon-root:nth-child(1) {
              font-size: 20px !important;
              color: ${theme.palette.success.main};
            }

            .MuiSvgIcon-root:nth-child(3) {
              font-size: 20px !important;
              color: ${theme.palette.danger.main};
            }
          }
        }
      }

      .request-info-body {
        min-height: 140px;
        font-size: 0.72rem;
        background-color: ${theme.palette.mode === 'dark' ? '#19252a' : '#f3f6f9'};
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid;
        border-color: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0e0e0'};
        padding: 7px;

        > div {
          display: flex;

          div:first-child {
            min-width: 100px;
            font-weight: 500;
          }
        }
      }
    }
  `
);

const StyledAgentChip = styled(Chip)(
  ({ theme }) => css`
    background-color: #f1fff03d;
    border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#02410142'};
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    height: 33px;
    box-shadow: 0px 0px 2.5px #244a1830;

    & .MuiChip-label {
      font-weight: 500;
      color: #1e87ab;
    }
  `
);

const renderRequestInfo = (request = '') => {
  let requestObj = getJsonOr(request.requestInfo2);
  let loanNames = requestObj?.loanTypes?.map((item) => item.name);

  return (
    <div className="request-info-body">
      {request ? (
        <Fragment>
          <div>
            <div>Request#: </div>
            <div>{requestObj.reqNum}</div>
          </div>
          <div>
            <div>Signer: </div>
            <div>{requestObj.borrower}</div>
          </div>
          <div>
            <div>Appt: </div>
            <div>{requestObj.appt}</div>
          </div>
          <div>
            <div>Scheduling Notes: </div>
            <div>{requestObj.schedulingNotes}</div>
          </div>
          <div>
            <div>Location: </div>
            <div>{requestObj.location}</div>
          </div>
          <div>
            <div>Signing Type: </div>
            <div>{loanNames?.join(', ')}</div>
          </div>
          <div>
            <div>Qty Of Loans: </div>
            <div>{requestObj.qtyOfLoans}</div>
          </div>
        </Fragment>
      ) : (
        <span style={{ zoom: '75%' }}>
          <Loading forList msg="Getting request info..." />
        </span>
      )}
    </div>
  );
};

const agreedFeesColumns = [
  {
    id: 1,
    field: 'note',
    placeHolder: 'Enter description...',
    headerName: 'Description',
    type: 'text',
    editable: true,
    flex: 5,
  },
  {
    id: 2,
    field: 'quantity',
    headerName: 'Qty',
    type: 'number',
    editable: true,
    flex: 1,
  },
  {
    id: 3,
    field: 'amount',
    headerName: 'Fee($)',
    type: 'number',
    editable: true,
    flex: 1,
  },
];

const _proposedFeeCols = [
  {
    id: 1,
    type: 'lookup',
    field: 'name',
    placeHolder: 'Enter description...',
    headerName: 'Description',
    editable: true,
    lookups: [],
    updateFielsOnChange: ['fee'],
    flex: 5,
  },
  {
    id: 2,
    field: 'fee',
    headerName: 'Fee($)',
    type: 'number',
    editable: true,
    flex: 1,
  },
];

const AssignNotary = (props) => {
  const history = useHistory();
  useDocumentTitle('Assign Agent'); // Set page title

  const {
    setRequestID,
    assignNotaryState,
    setAssignNotaryState,
    assignAgentToRequest,
    setListMode,
    setSelectedEmailCount,
    setAgentEmailRecipients,
    agreedFees,
    assignNotaryReducer,
    setAssignNotaryReducer,
  } = useContext(ContactManagerContext);

  let { requestID } = useParams();
  const requestInfo = useRef();
  const menuState = store.getState().reducer.menuState;

  const [proposedFeeCols, setProposedFeeCols] = useState(_proposedFeeCols);
  const [proposedFees, setProposedFees] = useState([]);
  const [customInfo, setCustomInfo] = useState();
  const [showOrigAgreedFees, setShowOrigAgreedFees] = useState(true);

  useEffect(() => {
    setRequestID(requestID);
    reqService.getProposedSigningFees().then((res) => {
      setProposedFeeCols(parseFeeTypesForArrayLookup(res, proposedFeeCols, 'name', 'description'));
    });

    setAssignNotaryReducer({
      type: 'setLoading',
      payload: { propFeesIsLoading: true, agreedFeesIsLoading: true },
    });

    reqService.getRequestSummaryForAssignment(requestID).then(
      (res) => {
        requestInfo.current = res;
        let agreedFeesJson = getJsonOr(res.agentAgreedFees);
        agreedFees.current = agreedFeesJson;

        // emit values to Contact Manager
        setAssignNotaryState({
          signingZip: res.signingZip,
          publicId: res.assignedAgentPublicId,
          name: res.notaryNameAndCompany,
          agreedFees: agreedFeesJson,
          languagePref: res.chkUseLanguage == 'True' ? res.languagePref : '',
        });
        //////////

        let proposedFeesJson = getJsonOr(res.agentProposedFees);
        setProposedFees(proposedFeesJson);
        setCustomInfo({
          proposedFees: JSON.stringify(proposedFeesJson),
          agreedFees: JSON.stringify(agreedFeesJson),
          agentEmailOverride: res.agentEmailOverride,
        });
        setAssignNotaryReducer({
          type: 'setLoading',
          payload: { propFeesIsLoading: false, agreedFeesIsLoading: false },
        });
      },
      (error) => {
        dispatchAlert(error, true);
        history.push('/manage-request');
      }
    );

    return () => {};
  }, []);

  useEffect(() => {
    if (assignNotaryState?.agreedFees) {
      setCustomInfo({
        ...customInfo,
        agreedFees: JSON.stringify(assignNotaryState.agreedFees),
      });
    }
    return () => {};
  }, [assignNotaryState]);

  const saveCustomInfo = async () => {
    setAssignNotaryReducer({
      type: 'setCustomChanges',
      payload: { savingCustomChanges: true },
    });

    setListMode('contacts');
    setSelectedEmailCount(0);
    setAgentEmailRecipients([]);
    await reqService.saveCustomInfoOnNotaryAssign(requestID, JSON.stringify(customInfo)).then(
      (res) => dispatchAlert('Fees were saved successfully'),
      (error) => dispatchAlert(error, true)
    );
    setAssignNotaryReducer({
      type: 'setCustomChanges',
      payload: { savingCustomChanges: false },
    });
  };

  const moveProposedToAgreedFees = (event) => {
    if (event.target.innerText == 'Cancel') {
      setCustomInfo({
        ...customInfo,
        agreedFees: JSON.stringify(agreedFees.current),
      });
      setAssignNotaryState({
        ...assignNotaryState,
        agreedFees: agreedFees.current,
      });
      setShowOrigAgreedFees(true);
    } else {
      let propFees = getJsonOr(customInfo?.proposedFees);
      let agreedFees = [];
      propFees.map((item) => {
        agreedFees.push({
          note: item ? item.name : '',
          quantity: item ? 1 : 0,
          amount: item ? item.fee : 0,
        });
      });

      setCustomInfo({ ...customInfo, agreedFees: JSON.stringify(agreedFees) });
      setAssignNotaryState({ ...assignNotaryState, agreedFees: agreedFees });
      setShowOrigAgreedFees(false);
    }
  };

  const handleConfirmationEmail = (event) => {
    event.preventDefault();
    setAssignNotaryReducer({
      type: 'setApptDialog',
      payload: { ...assignNotaryReducer, apptDialog_onLoading: true },
    });

    reqService.getSigningRequestDetail(true, 0, requestID).then(
      (res) => {
        setAssignNotaryReducer({
          type: 'setApptDialog',
          payload: {
            ...assignNotaryReducer,
            apptDialog_onResponse: {
              request: res.signingRequest,
              settings: res.signingRequestSettings,
            },
          },
        });
      },
      (error) => {
        dispatchAlert(error, true);
        setAssignNotaryReducer({
          type: 'setApptDialog',
          payload: {
            ...assignNotaryReducer,
            apptDialog_onResponse: null,
            apptDialog_onLoading: false,
          },
        });
      }
    );
  };

  const parseSendResponse = (response) => {
    if (response) {
      let custResp = response?.Customer;
      let agentResp = response?.Agent;

      let errors = [];
      let succsessMsgs = [];

      if (custResp?.includes('error') || agentResp?.includes('error')) {
        if (custResp?.includes('error')) errors.push(custResp.replace('[error]', ''));
        if (agentResp?.includes('error')) errors.push(agentResp.replace('[error]', ''));
      }

      if (custResp?.includes('success') || agentResp?.includes('success')) {
        if (custResp?.includes('success')) succsessMsgs.push(custResp.replace('[success]', ''));
        if (agentResp?.includes('success')) succsessMsgs.push(agentResp.replace('[success]', ''));
      }

      if (errors.length > 0) {
        errors.forEach((error) => dispatchAlert(error, true));
      } else {
        succsessMsgs.forEach((msg) => dispatchAlert(msg));
      }
    }
  };

  const handleRequestActionPerform = (response) => {
    if (response) parseSendResponse(response);
    setAssignNotaryReducer({
      type: 'setApptDialog',
      payload: {
        ...assignNotaryReducer,
        apptDialog_onResponse: null,
      },
    });
  };

  const profileImage = `https://notarycafe.com/Content/profile/${assignNotaryState?.publicId}_1.png`;

  return (
    <StyledAssignNotary $menuState={menuState}>
      <div id="requestWrapper">
        <div className="request-info-header">
          <div className={`assigned-name ${assignNotaryState?.name ? 'agent-was-assgined' : ''}`}>
            <div>Assigned Agent: </div>
            {assignNotaryState?.name ? (
              <StyledAgentChip
                variant="contained"
                avatar={
                  <Avatar
                    sx={{
                      marginLeft: '2px !important',
                      width: '30px !important',
                      height: '29px !important',
                    }}
                    src={profileImage}
                    variant="rounded"
                  />
                }
                deleteIcon={<CloseIcon />}
                onDelete={() => assignAgentToRequest('')}
                label={assignNotaryState?.name || 'Unassigned'}
              />
            ) : (
              <>
                <DoubleArrowRoundedIcon fontSize="small" />
                <span>Unassigned</span>
              </>
            )}
          </div>
        </div>
        {renderRequestInfo(requestInfo.current)}
      </div>
      <div id="proposedFeesWrapper">
        <div className="comp-sub-title">Proposed Signing Fee</div>
        <EditableTable
          style={{ headerRowHeight: '25px', bodyRowHeight: '25px' }}
          addNew
          edit
          loading={assignNotaryReducer.propFeesIsLoading}
          columns={proposedFeeCols}
          rows={proposedFees}
          emitNewRows={(val) => {
            setProposedFees(val.state);
            setCustomInfo({
              ...customInfo,
              proposedFees: JSON.stringify(val.state),
            });
          }}
        />
      </div>
      <div id="agreedFeesWrapper">
        <div className="comp-sub-title">
          <div style={{ display: 'flex', gap: '5px' }}>
            Agreed Upon Fees for Agent
            <HtmlTooltip arrow title={'Fees will be enabled once an agent is assigned to the request.'}>
              <IconButton sx={{ p: 0 }}>
                <InfoOutlinedIcon color="info" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          </div>
          <div>
            {assignNotaryState?.name && (
              <Link component="button" underline="always" variant="caption" onClick={moveProposedToAgreedFees}>
                {showOrigAgreedFees ? 'Copy Proposed Fees' : 'Cancel'}
              </Link>
            )}
          </div>
        </div>
        <div id="uponFee">
          <EditableTable
            style={{ headerRowHeight: '25px', bodyRowHeight: '25px' }}
            minRows={5}
            loading={assignNotaryReducer.agreedFeesIsLoading}
            edit={assignNotaryState?.name ? true : false}
            columns={agreedFeesColumns}
            rows={assignNotaryState?.agreedFees}
            emitNewRows={(val) => {
              setAssignNotaryState({
                ...assignNotaryState,
                agreedFees: val.state,
              });
              setCustomInfo({
                ...customInfo,
                agreedFees: JSON.stringify(val.state),
              });
            }}
          />
        </div>
      </div>
      <div id="actionsWrapper">
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="override-email">Override Agent Email</InputLabel>
            <OutlinedInput
              id="override-email"
              disabled={assignNotaryState?.name ? false : true}
              value={customInfo?.agentEmailOverride || ''}
              onChange={(e) =>
                setCustomInfo({
                  ...customInfo,
                  agentEmailOverride: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position="end">
                  <HtmlTooltip
                    arrow
                    title={
                      <>
                        <p>The above email can be used if the email for the agent is incorrect.</p>
                        <p>This is for the assigned request only.</p>
                      </>
                    }
                  >
                    <IconButton>
                      <InfoOutlinedIcon color="info" fontSize="small" />
                    </IconButton>
                  </HtmlTooltip>
                </InputAdornment>
              }
              label="Override Agent Email"
            />
          </FormControl>
          <div className="buttons">
            <Button
              disabled={assignNotaryReducer.apptDialog_onLoading}
              startIcon={<SendOutlinedIcon className="rotate" />}
              onClick={handleConfirmationEmail}
            >
              <>Send Confirmation Email {assignNotaryReducer.apptDialog_onLoading && <Loading forButton lpad />}</>
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveOutlined />}
              disabled={assignNotaryReducer.savingCustomChanges}
              onClick={saveCustomInfo}
            >
              Save Changes {assignNotaryReducer.savingCustomChanges && <Loading forButton lpad />}
            </Button>
          </div>
        </div>
      </div>
      {assignNotaryReducer.apptDialog_onResponse && (
        <AppointmentDialog
          open={true}
          content={{
            type: assignNotaryReducer.type,
            icon: assignNotaryReducer.icon,
          }}
          ignoreDetailContext={true}
          settings={assignNotaryReducer.apptDialog_onResponse.settings}
          request={assignNotaryReducer.apptDialog_onResponse.request}
          confirmCallback={(e) => handleRequestActionPerform(e)}
        />
      )}
    </StyledAssignNotary>
  );
};

export default AssignNotary;
