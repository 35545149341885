import React, { useState, useEffect, Fragment, useId, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { store } from '../../../store';
import { sanitizeHTML } from '../../../helpers';
import { Collapse } from '@mui/material';
import { F } from '../../../helpers/functions';

export const StyledRelativeAlert = styled('div')(
  ({ theme }) => css`
    z-index: 30;

    ${[theme.breakpoints.down('md')]} {
      width: 100%;
    }

    .MuiCollapse-root {
      margin-bottom: 1px;
    }

    .MuiAlert-root {
      border-radius: 0;
    }

    ul {
      list-style: disc !important;
    }
  `
);

export const AlertItem = (props) => {
  const { alert, handleClose = F } = props;

  const [parsedMessage, setparsedMessage] = useState('');
  const [parsedErrors, setParsedErrors] = useState([]);

  useEffect(() => {
    if (!alert.keepOpen) {
      setTimeout(() => {
        handleClose(alert);
      }, alert.timeOut || 5000);
    }
    return () => {};
  }, []);

  useEffect(() => {
    let parsedOBj = {};
    let errors = [];
    let warnings = [];

    if (alert.message?.traceId && (alert.message?.title || alert.message?.errors?.length > 0)) {
      let _errors = alert.message?.errors || [];
      Object.getOwnPropertyNames(_errors).forEach(function (val) {
        errors.push(_errors[val]);
      });

      setparsedMessage(alert.message?.title || '');
      setParsedErrors(errors);
    } else {
      if (IsValidJSONString(alert.message)) {
        parsedOBj = JSON.parse(alert.message);
        Object.getOwnPropertyNames(parsedOBj.Errors).forEach(function (val) {
          errors.push(parsedOBj.Errors[val]);
        });

        setparsedMessage(parsedOBj.Message);
        setParsedErrors(errors);
      } else {
        parsedOBj = alert.message;
        setparsedMessage(parsedOBj);
      }
    }

    return () => {};
  }, [alert]);

  function IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <Collapse in={alert.open}>
      <Alert variant="filled" onClose={(e) => handleClose(alert)} severity={alert.color}>
        <div dangerouslySetInnerHTML={sanitizeHTML(parsedMessage)} />
        <ul>
          {parsedErrors.map((option, index) => (
            <li key={index} value={option}>
              {option}
            </li>
          ))}
        </ul>
      </Alert>
    </Collapse>
    // <Fragment>
    //   <Slide
    //     direction="down"
    //     in={alert.open}
    //     mountOnEnter
    //     unmountOnExit
    //     {...(alert.open ? { timeout: 250 } : {})}
    //   >
    //     <Alert
    //       variant="filled"
    //       onClose={(e) => handleClose(alert)}
    //       severity={alert.color}
    //     >
    //       <div dangerouslySetInnerHTML={sanitizeHTML(parsedMessage)} />
    //       <ul>
    //         {parsedErrors.map((option, index) => (
    //           <li key={index} value={option}>
    //             {option}
    //           </li>
    //         ))}
    //       </ul>
    //     </Alert>
    //   </Slide>
    // </Fragment>
  );
};

const AlertItemProvider = (props) => {
  const dispatch = useDispatch();

  const handleClose = (alert) => {
    dispatch({ type: 'REMOVE_ALERT', payload: alert });
  };

  return <AlertItem {...props} handleClose={handleClose} />;
};

const ComponentAlertRelative = ({
  wrapperRef,
  // menuState,
}) => {
  const alertsRef = useRef();

  // const { token } = store.getState().reducer.accessToken;
  const alerts = useSelector((state) => state.reducer.appAlerts || []);
  // const onLoginSuccess = alerts[0]?.onLogin ? true : false;

  // const classes = useStyles({
  //   alertCount: alerts.length || 0,
  //   menuIsShown: onLoginSuccess ? false : menuState,
  //   useLogged: token ? true : false,
  // });

  return (
    <StyledRelativeAlert ref={alertsRef}>
      {![undefined, null].includes(wrapperRef?.current) && wrapperRef.current.contains(alertsRef.current) && (
        <>{alerts.map((alert, index) => alert.isRelative && <AlertItemProvider key={index} alert={alert} />)}</>
      )}
    </StyledRelativeAlert>
  );
};

export default ComponentAlertRelative;
