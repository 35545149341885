import React, { useState, useEffect, memo } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { signingRequestService as service } from '../../../services/signing-request.service';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gridGap: '15px',
    width: '100%',

    '& div:nth-child(1)': {
      width: '100%',
      minWidth: '43%',
    },
    '& div:nth-child(2)': {
      minWidth: 'calc(58% - 19px)',
    },
  },
}));

const ShippingCompany = ({ shrink = false, disabled, required, error, value, ddlOnChange }) => {
  // console.log('ShippingCompany render');
  const classes = styles();

  const [shrinkState, setShrinkState] = useState();
  const [selectedShippingCompany, setSelectedShippingCompany] = useState(value);
  const [otherShippingInfo, setOtherShippingInfo] = useState('');
  const [shippingCompanies, setShippingCompanies] = useState([
    { value: '', label: 'Select One' },
    { value: 'Fedex', label: 'Fedex' },
    { value: 'UPS', label: 'UPS' },
    { value: 'DHL', label: 'DHL' },
    { value: 'USPS', label: 'USPS' },
    { value: 'Other', label: 'Other' },
  ]);

  useEffect(() => {
    let selected = Boolean(shippingCompanies.filter((item) => item.value === value).length);
    setSelectedShippingCompany(selected ? value : 'Other');
    if (selected && value) setShrinkState(true);
    if (!selected) setOtherShippingInfo(value);
  }, [value]);

  const getShippingCompanies = async () => {
    await service.getShippingCompanies().then(
      (res) => {
        setShippingCompanies(res);
      },
      (error) => {
        // console.log(error);
      }
    );
  };

  return (
    <div className={classes.root}>
      <FormControl required={required} error={error}>
        <InputLabel shrink={shrink || shrinkState} id="return-shipping-company">
          {'Shipping Company'}
        </InputLabel>
        <Select
          id="return-shipping-company"
          label="Shipping Company"
          notched={shrink || shrinkState}
          disabled={disabled}
          onOpen={getShippingCompanies}
          defaultValue={selectedShippingCompany}
          value={selectedShippingCompany}
          onChange={(e) => {
            if (e.target.value) setShrinkState(true);
            else setShrinkState(false);

            ddlOnChange(e.target.value);
            setSelectedShippingCompany(e.target.value);
          }}
        >
          {shippingCompanies.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedShippingCompany === 'Other' && (
        <TextField
          sx={{ input: { height: 'auto !important', lineHeight: 'inherit !important' } }}
          id="otherShippingInfo"
          label="Other Shipping Co."
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          value={otherShippingInfo}
          onChange={(e) => setOtherShippingInfo(e.target.value)}
          onBlur={(e) => ddlOnChange(e.target.value)}
        />
      )}
    </div>
  );
};

export default memo(ShippingCompany);
