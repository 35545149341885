import React, { createContext, useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useContext } from 'react';
import _ from 'lodash';

import { ManageRequestContext } from './manage-request.context';
import { signingRequestService as service } from '../services/signing-request.service';
import { notaryDeskService as ndService } from '../services/notary-desk.service';
import { manageRequestService } from '../services';
import { useDispatch } from 'react-redux';
import { dispatchAlert, dispatchAlertKeepOpen, dispatchRelativeAlert } from '../helpers';

export const SigningRequestContext = createContext(null);

const statusOptions = [
  { value: '0', text: '' },
  { value: '1', text: 'New' },
  { value: '2', text: 'Pending' },
  { value: '3', text: 'Confirmed' },
  { value: '4', text: 'Complete' },
  { value: '5', text: 'Invoiced' },
  { value: '6', text: 'Closed' },
  { value: '9', text: 'Declined' },
  { value: '10', text: 'Canceled' },
];

const getSigningAltLocationSection = (data) => {
  return {
    altLoc_Name: data.altLoc_Name,
    altLoc_Add1: data.altLoc_Add1,
    altLoc_Add2: data.altLoc_Add2,
    altLoc_City: data.altLoc_City,
    altLoc_State: data.altLoc_State,
    altLoc_Zip: data.altLoc_Zip,
  };
};

const getSigningAppointmentSection = (data) => {
  return {
    userID: data.userID,
    customerString: data.customerString,
    customerDDLSort: data.customerDDLSort,
    loanTypesJSON: data.loanTypesJSON,
    //
    numOfLoans: data.numOfLoans,
    signingDate: data.signingDate,
    signingTime: data.signingTime,
    schedulingNotes: data.schedulingNotes,
    loanNumber: data.loanNumber,
    loanNumber2: data.loanNumber2,
    languagePref: data.languagePref,
    loanOfficerCompany: data.loanOfficerCompany,
    loanOfficerEmail: data.loanOfficerEmail,
    loanOfficerID: data.loanOfficerID,
    loanOfficerName: data.loanOfficerName,
    loanOfficerPhone: data.loanOfficerPhone,
    contactName: data.contactName,
    contactPhone: data.contactPhone,
    contactCell: data.contactCell,
    contactFax: data.contactFax,
    contactEmail: data.contactEmail,
    additionalContactInfoJSON: data.additionalContactInfoJSON,
    //
    willSendDocsVia: data.willSendDocsVia,
    requestShipperInfo: data.requestShipperInfo,
    requestTrackingInfo: data.requestTrackingInfo,
    chkDocsIn: data.chkDocsIn,
    chkHudIn: data.chkHudIn,
    shipperName: data.shipperName,
    shipperAccount: data.shipperAccount,
    shippingInstructions: data.shippingInstructions,
    otherComments: data.otherComments,
  };
};

const getInvoiceContactSection = (data) => {
  return {
    altInvoiceCompany: data.altInvoiceCompany,
    altInvoiceAddress: data.altInvoiceAddress,
    invoiceEmail_1: data.invoiceEmail_1,
    invoiceEmail_2: data.invoiceEmail_2,
    invoiceEmail_3: data.invoiceEmail_3,
  };
};

const getCompletionInfoSection = (data) => {
  return {
    completionDate: data.completionDate,
    completionTime: data.completionTime,
    completionReturnedDate: data.completionReturnedDate,
    completionReturnedVia: data.completionReturnedVia,
    completionTrackingNumber: data.completionTrackingNumber,
    completionReturnedVia2: data.completionReturnedVia2,
    completionTrackingNumber2: data.completionTrackingNumber2,
    completionComments: data.completionComments,
    completionOptions: data.completionOptions,
  };
};

const getBillingSection = (data) => {
  return {
    customerPricing: data.customerPricing,
    agentPricing: data.agentPricing,
  };
};

const getUndefinedSection = (data) => {
  return {
    portalID: data.portalID,
    requestID: data.requestID,
    requestSeqID: data.requestSeqID,
    signingStatusID: data.signingStatusID,
    signingRequestStatus: data.signingRequestStatus,
    statusNameShort: data.statusNameShort,
    addDateTime: data.addDateTime,
    appointmentComplete: data.appointmentComplete,
    appointmentConfirmed: data.appointmentConfirmed,
    appointmentNotaryNote: data.appointmentNotaryNote,
    assignedAgentID: data.assignedAgentID,
    assignedAgentName: data.assignedAgentName,
    assignedAgentPublicID: data.assignedAgentPublicID,
    assignedStaff: data.assignedStaff,
    assignedStaffID: data.assignedStaffID,
    assignedStaffName: data.assignedStaffName,
    disableAutoDeleteDocs: data.disableAutoDeleteDocs,
    emailAddress: data.emailAddress,
    fileSSLActivate: data.fileSSLActivate,
    hasFeedback: data.hasFeedback,
    hudDocsFlag: data.hudDocsFlag,
    hudDocsFlagDesc: data.hudDocsFlagDesc,
    inactiveDate: data.inactiveDate,
    includeOfficerInEmails: data.includeOfficerInEmails,
    invoiceCreated: data.invoiceCreated,
    invoicePaid: data.invoicePaid,
    invoiced: data.invoiced,
    lenderID: data.lenderID,
    mapLink: data.mapLink,
    notaryFeeConfirm: data.notaryFeeConfirm,
    notaryPaid: data.notaryPaid,
    otherConfirmEmail: data.otherConfirmEmail,
    requestType: data.requestType,
    scanBackRequired: data.scanBackRequired,
    scanbacksDocsIn: data.scanbacksDocsIn,
    sendDocsNotice: data.sendDocsNotice,
    signingZipForProximity: data.signingZipForProximity,
    sys_ConfirmEmail_To: data.sys_ConfirmEmail_To,
    sys_LongEmail_To: data.sys_LongEmail_To,
    sys_ShortEmail_To: data.sys_ShortEmail_To,
    totalDocs: data.totalDocs,
  };
};

const SigningRequestProvider = (props) => {
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const { breadCrumb = [], setBreadCrumb = () => {} } = useContext(ManageRequestContext) || {};
  const { fromManageRequest = false, context, notifyAlertMsg = () => {} } = props;

  const { copyFrom, detailID } = useParams();
  let requestID = null;
  if (props.requestID) {
    requestID = props.requestID;
  } else if (copyFrom || useParams().hasOwnProperty('newRequest')) {
    requestID = 0;
  } else if (detailID) {
    requestID = detailID;
  } else {
    requestID = fromManageRequest === false ? 0 : -1;
  }

  const [requiredFields, setRequiredFields] = useState('');
  const [gettingData, setGettingData] = useState();
  const [savingData, setSavingData] = useState(null);
  const [pageMode, setPageMode] = useState('view');
  const [settings, setSettings] = useState('');

  /* Signing Form States For Sections */
  const [signingRequest, setSigningRequest] = useState();
  const [borrowerSection, setBorrowerSection] = useState();
  const [altLocationSection, setAltLocationSection] = useState();
  const [appointmentSection, setAppointmentSection] = useState();
  const [invoiceContactSection, setInvoiceContactSection] = useState();
  const [completionInfoSection, setCompletionInfoSection] = useState();
  const [billingSection, setBillingSection] = useState();
  // const [undefinedSection, setUndefinedSection] = useState();
  /* End */

  const [completionOptions, setCompletionOptions] = useState([]);
  const [editModeAboutToExpire, setEditModeAboutToExpire] = useState(false);
  const [confirmationHandler, setConfirmationHandler] = useState(false);
  const [confirmationObj, setConfirmationObj] = useState('');

  useEffect(() => {
    if (!props.custWizard && parseInt(requestID) >= 0) {
      if (context === 'notaryDesk') getRequestDetailND(requestID);
      else getSigningRequestDetail(copyFrom);
    } else if (props.custWizard != null) {
      getSigningRequestDetail(copyFrom, props.custWizard.userID);
    }
    return () => {
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, [props.custWizard]);

  useEffect(() => {
    dispatch({ type: 'SET_PAGE_IN_EDIT_MODE', payload: pageMode === 'edit' ? true : false });

    return () => {
      // IMPORTANT: dispatch SET_PAGE_IN_EDIT_MODE will re-enable the token timer in the overall app.
      dispatch({ type: 'SET_PAGE_IN_EDIT_MODE', payload: false });
    };
  }, [pageMode]);

  const handleConfirmation = (event, value) => {
    // if value = true then perform the acction
    // value = tue ===>>> means user accept confirmation dialog
    if (value) {
      setConfirmationObj({ event: event, confirmed: true });
      setConfirmationHandler(false);
    } else {
      setConfirmationHandler(false);
      setConfirmationObj(null);
    }
  };

  const parseSendResponse = (response) => {
    if (response) {
      let custResp = response?.Customer;
      let agentResp = response?.Agent;

      let errors = [];
      let succsessMsgs = [];

      if (custResp?.includes('error') || agentResp?.includes('error')) {
        if (custResp?.includes('error')) errors.push(custResp.replace('[error]', ''));
        if (agentResp?.includes('error')) errors.push(agentResp.replace('[error]', ''));
      }

      if (custResp?.includes('success') || agentResp?.includes('success')) {
        if (custResp?.includes('success')) succsessMsgs.push(custResp.replace('[success]', ''));
        if (agentResp?.includes('success')) succsessMsgs.push(agentResp.replace('[success]', ''));
      }

      if (errors.length > 0) {
        errors.forEach((error) => dispatchAlert(error, true));
      } else {
        succsessMsgs.forEach((msg) => dispatchAlert(msg));
      }
    }
  };

  const getSigningRequestDetail = async (copyFromID, customerID = 0) => {
    setGettingData(true);
    await service.getSigningRequestDetail(fromManageRequest, copyFromID, requestID, customerID).then(
      (res) => {
        if (!mountedRef.current) return null;
        setSettings(res.signingRequestSettings);
        setRequiredFields(res.requiredFields);
        /* Signing Form States Assign */
        // setRequired(res.requiredFields);
        setSigningRequest(res.signingRequest);
        setBorrowerSection(res.signingRequest.borrower);
        setAltLocationSection(getSigningAltLocationSection(res.signingRequest));
        setAppointmentSection(getSigningAppointmentSection(res.signingRequest));
        setInvoiceContactSection(getInvoiceContactSection(res.signingRequest));
        setCompletionInfoSection(getCompletionInfoSection(res.signingRequest));
        setBillingSection(getBillingSection(res.signingRequest));
        // setUndefinedSection(getUndefinedSection(res.signingRequest)); // TODO: Review more...
        /* End */

        setCompletionOptions(res.signingRequest.completionOptions);
        setPageMode(
          fromManageRequest && requestID > 0
            ? 'view'
            : res.signingRequestSettings.btn_SubmitSigningRequest_Enable
            ? 'edit'
            : 'view'
        );
        setGettingData(false);
      },
      (error) => {
        if (!mountedRef.current) return null;
        setPageMode('error');
        dispatchAlertKeepOpen(error, true);
        setGettingData(false);
      }
    );
  };

  const submitSigningRequest = async (data) => {
    setSavingData(true);
    let wasSucceeded = false;
    await service.submitSigningRequest(fromManageRequest, JSON.stringify(data)).then(
      (response) => {
        if (!mountedRef.current) return null;
        setSigningRequest(response);
        setPageMode('view');
        setSettings({
          ...settings,
          btn_Save_Visible: false,
          btn_Cancel_Visible: false,
          btn_EditRequest_Visible: true,
          btn_CopyToNew_Visible: true,
          btn_DeleteRequest_Visible: true,
        });
        setSavingData(false);
        dispatchAlert('[Replace]The signing request was saved.');
        notifyAfterRequestSubmit(response.requestID);
        wasSucceeded = true;
      },
      (error) => {
        if (!mountedRef.current) return null;
        dispatchAlert(error, true);
        setSavingData(false);
        wasSucceeded = false;
      }
    );
    return wasSucceeded;
  };

  const saveSigningRequest = async (data) => {
    setSavingData(true);
    let wasSucceeded = false;
    await service.saveSigningRequest(fromManageRequest, requestID, JSON.stringify(data)).then(
      (response) => {
        if (!mountedRef.current) return null;
        dispatch({ type: 'CLEAR_ALERTS' });
        setSavingData(false);
        setPageMode('view');
        dispatchAlert(parseInt(requestID) === 0 ? '[Replace]The signing request was saved' : '[Replace]' + response);
        setTimeout(() => {
          if (parseInt(requestID) === 0) {
            let crumbClone = _.cloneDeep(breadCrumb);
            let rootCrumbIdx = crumbClone.lastIndexOf('/manage-request');
            crumbClone.splice(rootCrumbIdx + 1, breadCrumb.length);
            setBreadCrumb(crumbClone);
            history.push(`/manage-request/detail/${response.requestID}`);
          } else {
            setSettings({
              ...settings,
              btn_Save_Visible: false,
              btn_Cancel_Visible: false,
              btn_EditRequest_Visible: true,
              btn_CopyToNew_Visible: true,
              btn_DeleteRequest_Visible: true,
            });
          }
        }, 500);
        wasSucceeded = true;
      },
      (error) => {
        if (!mountedRef.current) return null;
        dispatchAlert('[Replace]' + error, true);
        setSavingData(false);
        wasSucceeded = false;
      }
    );
    return wasSucceeded;
  };

  const deleteSigningRequest = async () => {
    await service.deleteSigningRequest(fromManageRequest, requestID).then(
      (response) => {
        if (!mountedRef.current) return null;
        dispatchAlert(response);
        setTimeout(() => {
          history.push('/manage-request');
        }, 500);
      },
      (error) => {
        if (!mountedRef.current) return null;
        dispatchAlert('[Replace]' + error, true);
      }
    );
  };

  const assignStaffToRequest = (requestID, value) => {
    dispatch({ type: 'CLEAR_ALERTS' });
    manageRequestService.assignStaffToRequest(requestID, value.staffID).then(
      (res) => {
        dispatchAlert(`[Replace]Successfully Changed Staff to ${value.staffName.substr(5)}`);
        getSigningRequestDetail();
      },
      (error) => {
        dispatchAlert('[Replace]' + error, true);
      }
    );
  };

  const notifyAfterRequestSubmit = async (requestID) => {
    await service
      .notifyAfterRequestSubmit(requestID, settings.pnl_BillingInfo_Visible, settings.pnl_AlternateLocation_Visible)
      .then(
        (response) => {
          if (!mountedRef.current) return null;
          if (response.length > 0) {
            response.forEach((item) => {
              dispatchAlertKeepOpen(item);
            });
          } else {
            dispatchAlertKeepOpen(response);
          }
        },
        (error) => {
          if (!mountedRef.current) return null;
          dispatchAlertKeepOpen(error, true);
        }
      );
  };

  //**********************************
  //**********************************
  //*******NotaryDesk functions*******
  //**********************************
  //**********************************

  const getRequestDetailND = async (requestID) => {
    setGettingData(true);
    await ndService.getCompletionReportDetail(requestID).then(
      (res) => {
        setPageMode('edit');
        setSigningRequest(res);
        setCompletionOptions(res.completionOptions);
        setGettingData(false);
      },
      (error) => {
        if (!mountedRef.current) return null;
        setPageMode('error');
        dispatchAlert(error, true);
        setGettingData(false);
      }
    );
  };

  const updateCompletionReport = async () => {
    setSavingData(true);
    await ndService.updateCompletionReport(JSON.stringify(signingRequest)).then(
      (response) => {
        notifyCompletionReport();
        dispatchRelativeAlert(response);
      },
      (error) => dispatchAlert(error, true)
    );

    let reloadList = false;
    // GET Request ITEM
    await ndService.getCompletionReportDetail(requestID).then(
      (res) => {
        setSigningRequest(res);
        if (!res.scanbacksDocsIn && res.scanBackRequired)
          notifyAlertMsg(
            'Thank you for submitting your completion report. Your request will not be completed until you upload your Scanned documents.'
          );
        else reloadList = 'reload';
      },
      (error) => dispatchAlert(error, true)
    );

    return reloadList;
  };

  const notifyCompletionReport = async () => {
    await ndService.notifyCompletionReport(JSON.stringify(signingRequest)).then(
      (response) => {
        dispatchAlert(response.Customer || response.Agent);
      },
      (error) => {
        if (!mountedRef.current) return null;
        dispatchAlert(error, true);
      }
    );
  };

  return (
    <SigningRequestContext.Provider
      value={{
        requestID,
        gettingData,
        savingData,
        pageMode,
        statusOptions,
        settings,
        signingRequest,
        editModeAboutToExpire,
        fromManageRequest,
        confirmationHandler,
        confirmationObj,
        completionOptions,

        /* Form Section States */
        requiredFields,
        borrowerSection,
        altLocationSection,
        appointmentSection,
        invoiceContactSection,
        completionInfoSection,
        billingSection,
        // undefinedSection,
        // setUndefinedSection,
        /* End */

        parseSendResponse,
        setCompletionOptions,
        assignStaffToRequest,
        setConfirmationObj,
        setConfirmationHandler,
        handleConfirmation,
        setSettings,
        setGettingData,
        setSavingData,
        setEditModeAboutToExpire,
        setPageMode,
        setSigningRequest,
        getSigningRequestDetail,
        saveSigningRequest,
        submitSigningRequest,
        deleteSigningRequest,
        updateCompletionReport,
      }}
    >
      {props.children}
    </SigningRequestContext.Provider>
  );
};

export default SigningRequestProvider;
