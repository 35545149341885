import { Card, Checkbox, Divider, IconButton, ListItem } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CustomPagination from '../../common/others/Pagination';
import { AgentPaymentMgtContext } from '../../../context/agent-payment-mgt.context';
import { EmptyList } from '../../common/others/EmptyList';
import Loading from '../../common/others/Loading';
import { AgentPaymentMgtParent } from './AgentPaymentMgtParent';
import { AgentPaymentMgtChild } from './AgentPaymentMgtChild';

const DataListRender = (props) => {
  const renderFunc = props.children || props.render;

  return (
    <div style={{ position: 'relative', minHeight: '80px' }}>
      {props.loadingdata && props.onLoading()}
      {!props.loadingdata &&
        !props.initialLoad &&
        !props.dataList.length &&
        props.onEmptyDataList()}
      {props.dataList.map(renderFunc)}
    </div>
  );
};

export const AgentPaymentMgtList = () => {
  const {
    agentPayments,
    pageSizesForList,
    pagination,
    totalPages,
    loadingDataList,
    selectAllRow,
    openAllChild,
    handlePageSize,
    handlePageNumber,
  } = useContext(AgentPaymentMgtContext);

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    return () => {
      setInitialLoad(agentPayments.data?.length > 0 ? false : true);
    };
  }, [loadingDataList]);

  return (
    <Fragment>
      <Card className="card">
        <div className="card-content-List">
          <List component="div" aria-labelledby="nested-list-subheader">
            <ListItem>
              <ListItemText
                className="header-name"
                primary={
                  <Fragment>
                    <div className="header-title-flex ">
                      <div className="check-all-container">
                        <Checkbox
                          size="small"
                          className="check-all"
                          checked={agentPayments.selected}
                          onChange={() => selectAllRow()}
                        />
                      </div>
                      <div className="title-container">
                        Notary Agent / Signing Request
                      </div>
                    </div>
                  </Fragment>
                }
              />
              <ListItemText
                className="header-total col"
                primary={<div className="header-title">Total</div>}
              />
              <ListItemText
                className="header-total col"
                primary={<div className="header-title">Paid to Date</div>}
              />
              <ListItemText className="header-total" primary="Due" />
              <ListItemText
                primary={
                  <div className="header-toggle">
                    <IconButton onClick={() => openAllChild()}>
                      {agentPayments.open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                }
                className="header-toggle"
              />
            </ListItem>
            <Divider />
            <DataListRender
              initialLoad={initialLoad}
              loadingdata={loadingDataList}
              dataList={agentPayments.data}
              onLoading={() => <Loading bringFront msg="Getting Payments..." />}
              onEmptyDataList={() => <EmptyList brt msg="No Payments found." />}
            >
              {(summaryItem, index) => (
                <div
                  key={'f' + summaryItem.tableID}
                  style={{ opacity: `${loadingDataList ? 0.25 : 1}` }}
                >
                  <AgentPaymentMgtParent summaryItem={summaryItem} />
                  <Collapse
                    in={summaryItem.open}
                    timeout="auto"
                    unmountOnExit
                    className="detail"
                  >
                    <List
                      sx={{ bgcolor: 'background.paper' }}
                      className="detail-content"
                      component="div"
                      aria-labelledby="nested-list-subheader"
                      disablePadding
                    >
                      {summaryItem.data &&
                        summaryItem.data.map((childItem, i) => (
                          <Fragment key={'f' + childItem.tableID}>
                            <AgentPaymentMgtChild
                              childItem={childItem}
                              summaryItem={summaryItem}
                            />
                            {summaryItem.data.length > i + 1 && <Divider />}
                          </Fragment>
                        ))}
                    </List>
                  </Collapse>
                  <Divider />
                </div>
              )}
            </DataListRender>
          </List>
        </div>
      </Card>
      {agentPayments.data && agentPayments.data.length > 0 && (
        <CustomPagination
          pageSizeOptions={pageSizesForList}
          pageSize={pagination.pageSize}
          pageNumber={pagination.pageNumber}
          totalPages={totalPages}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
        />
      )}
    </Fragment>
  );
};
