import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useBusinessSettings } from '../../context/business-settings.context';
import { HtmlTooltip, StyledAccordion } from '../../Style';
import { EditableTable } from '../common/others/EditableTable';
import { portalService, signingRequestService as reqService, UploadFilesService } from '../../services';
import { getJsonOr } from '../../helpers';
import FileStorageOptions from '../common/file-storage/FileStorageOptions';
import FileUploadProvider from '../common/file-uploader/FileUploadContext';
import UploadFilesDialog from '../common/file-uploader/upload-files-dialog';
import FileUploadV2 from '../common/others/FileUploadv2';
import { agreementFileDataMapper } from '../../helpers/data-mappers/contract-agreement-dataMapper';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useTheme } from '@mui/styles';

const proposedFeeCols = [
  {
    id: 1,
    field: 'description',
    type: 'lookup',
    headerName: 'Loan/Fee Type',
    editable: true,
    lookups: [],
    flex: 4,
  },
  {
    id: 2,
    field: 'fee',
    headerName: 'Agent Fee($)',
    type: 'number',
    editable: true,
    flex: 1,
  },
];

const customRegItems = [
  {
    id: 1,
    field: 'id',
    headerName: '#',
    type: 'increment',
    editable: false,
    hidden: true,
    flex: 1,
  },
  {
    id: 2,
    field: 'referenceField',
    headerName: 'RefName',
    type: 'text',
    editable: false,
    hidden: true,
    flex: 1,
  },
  {
    id: 3,
    field: 'theData',
    type: 'text',
    headerName: 'Reg. Name',
    editable: true,
    flex: 2,
  },
  {
    id: 4,
    field: 'theLabel',
    type: 'text',
    headerName: 'Reg. Initial',
    editable: true,
    flex: 1,
  },
];

const agreementCustomColumns = [
  {
    id: 1,
    field: 'id',
    headerName: '#',
    type: 'increment',
    hidden: true,
    flex: 1,
  },
  {
    id: 2,
    field: 'createdDate',
    type: 'date',
    headerName: 'Date',
    disabled: true,
    flex: 1,
  },
  {
    id: 3,
    field: 'fileName',
    headerName: 'File Name',
    type: 'callbackLink',
    callback: (row) => {
      UploadFilesService.downloadFile(
        {
          uploadDocType: 'ServiceAgreementForAgent',
        },
        row['id']
      );
    },
    flex: 2,
  },
  {
    id: 4,
    field: 'displayName',
    type: 'text',
    editable: true,
    headerName: 'Display Name',
    info: 'If left blank agreement name will default to filename',
    flex: 2,
  },
  {
    id: 5,
    field: 'status',
    type: 'text',
    headerName: 'Status',
    flex: 1,
  },
  {
    id: 6,
    field: 'comments',
    type: 'textarea',
    editable: true,
    headerName: 'Comments',
    flex: 3,
    props: {
      maxLength: 150,
    },
  },
];

const WebSiteSettings = ({ callBack = () => {} }) => {
  const panelKey = 'webSiteSettings';
  const mountedRef = useRef(true);
  const {
    dataIsLoading,
    expanded,
    setExpanded,
    currentSetting,
    originalSettings,
    setOriginalSettings,
    setCurrentSetting,
    updateBusSettings,
    hasDirtyChanges,
    progressType,
  } = useBusinessSettings();

  const [proposedFeeLookups, setPoposedFeeLookups] = useState([]);
  const [openStorageOptions, setOpenStorageOptions] = useState(false);
  const [customStorageInfo, setCustomStorageInfo] = useState();
  const [openFileUploader, setOpenFileUploader] = useState(false);
  const [openAgreementUploader, setOpenAgreementUploader] = useState(false);
  const fileStorageRef = useRef(currentSetting?.signingFileStorage);
  const [invocieTemplate, setInvocieTemplate] = useState([]);
  const [signingAgreementTemplate, setSigningAgreementTemplate] = useState([]);
  const [blockUpdate, setBlockUpdate] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    return () => {
      //console.log('Does it need cleanup?');
      setCustomStorageInfo(false);
      setOpenStorageOptions(false);
    };
  }, [dataIsLoading]);

  useEffect(() => {
    let storageJson = getJsonOr(currentSetting?.signingFileStorage);
    setOpenStorageOptions(storageJson ? true : false);
    setCustomStorageInfo(storageJson);
    return () => {
      //console.log('Does it need cleanup?');
    };
  }, [currentSetting?.signingFileStorage]);

  const handleNavigationCallback = (e) => {
    if (hasDirtyChanges) {
      callBack({ confirmation: false, accordion: panelKey });
      e.stopPropagation();
    } else {
      if (expanded !== panelKey) {
        callBack({ confirmation: true, accordion: panelKey });
      } else {
        setExpanded(false);
      }
    }
  };

  const parseFeeTypesBS = (feeTypes) => {
    let newArray = [];
    feeTypes.map((item, index) => {
      let newObj = {};
      newObj['index'] = item.id || index + 1; // id must exits in the api response in case you need to relay on it
      newObj['value'] = item['friendlyName'];
      newArray.push(newObj);
    });

    return newArray;
  };

  useEffect(() => {
    setInvocieTemplate([getJsonOr(currentSetting?.custInvoiceTemplateFile)]);
    return () => (mountedRef.current = false);
  }, [currentSetting?.custInvoiceTemplateFile]);

  useEffect(() => {
    if (currentSetting?.currentAgreement) {
      setSigningAgreementTemplate([agreementFileDataMapper(currentSetting?.currentAgreement)]);
    }
  }, [currentSetting?.contractAgreements]);

  useEffect(() => {
    if (mountedRef.current) {
      reqService.getRequestLoanTypes(true, false).then((res) => {
        setPoposedFeeLookups(parseFeeTypesBS(res, 'lookupName', 'friendlyName'));
      });
    }

    return () => {
      //console.log('Does it need cleanup?');
      mountedRef.current = false;
    };
  }, [currentSetting]);

  const handleEditTableUpdate = (event) => {
    const checkIfUserInputInLookup = (field, row) => {
      let columnInPlay = proposedFeeCols.find((col) => col.field == field);
      let lookupInPlay = columnInPlay?.lookups.find((item) => item.label == row[field]);
      return lookupInPlay ? true : false;
    };

    const computeNewRows = event.state.map((row) => {
      if (!checkIfUserInputInLookup('description', row)) {
        return { ...row, isCustom: true };
      } else {
        return { ...row, isCustom: false };
      }
    });

    setCurrentSetting({
      ...currentSetting,
      proposedFees: JSON.stringify(computeNewRows),
    });
  };

  const handleCustomRegItemUpdate = (event) => {
    const computeNewRows = event.state.map((row) => {
      return { ...row, referenceField: '@RegCustom' + row.id };
    });

    setCurrentSetting({
      ...currentSetting,
      customRegistrationItems: JSON.stringify(computeNewRows),
    });
  };

  const handleAgreementRegItemUpdate = (event) => {
    const clone = { ...currentSetting };
    const setting = _set(clone, 'contractAgreements', event.state);

    setCurrentSetting(setting);
  };

  const reloadSettingProperty = async (prop) => {
    setBlockUpdate(true);

    await portalService.getBusSettings('websitesettings').then((res) => {
      if (prop == 'custInvoiceTemplateFileID') {
        setInvocieTemplate([getJsonOr(res.custInvoiceTemplateFile)]);
      }

      if ((prop = 'contractAgreements')) {
        const file = _get(res, 'currentAgreement', {});
        const newSettings = { ...currentSetting, [prop]: res[prop], currentAgreement: agreementFileDataMapper(file) };
        const originalSettingsUpdated = {
          ...JSON.parse(originalSettings),
          [prop]: res[prop],
          currentAgreement: agreementFileDataMapper(file),
        };
        // save the original state to prevent further modifications compare vs an invalid state
        setOriginalSettings(JSON.stringify(originalSettingsUpdated));
        // if dirty changes to previous value, skip modifications to affect the dirty state
        setCurrentSetting(newSettings, () => hasDirtyChanges);

        return;
      }

      setCurrentSetting({ ...currentSetting, [prop]: res[prop] });
    });
    setBlockUpdate(false);
  };

  const renderTooltipInfo = (title) => (
    <HtmlTooltip arrow maxwidth="400px" title={title}>
      <IconButton>
        <InfoOutlinedIcon color="info" fontSize="small" />
      </IconButton>
    </HtmlTooltip>
  );

  return (
    <>
      <StyledAccordion
        id={`${panelKey}-Accordion`}
        expanded={expanded === panelKey}
        onChange={(e) => handleNavigationCallback(e)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="webSiteSettingsPanel-content"
          id="webSiteSettingsPanel-header"
          className={expanded ? 'activePanel' : ''}
        >
          <Typography className="heading">Website Settings</Typography>
          <Typography className={`secondaryHeading ${expanded === panelKey ? 'activePanel' : ''}`}>
            General settings for the site, pages and more...
          </Typography>
        </AccordionSummary>
        {currentSetting && progressType == panelKey && (
          <>
            <AccordionDetails>
              <div className="rows">
                <div>
                  <div className="setting-label">Remove Upload Docs Link</div>
                  <div>
                    <TextField
                      id="remove-upload"
                      select
                      value={currentSetting.ddlRemoveUploadDocsLink || ''}
                      onChange={(e) => {
                        setCurrentSetting({
                          ...currentSetting,
                          ddlRemoveUploadDocsLink: e.target.value,
                        });
                      }}
                    >
                      {['Yes', 'No'].map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div>
                  <div className="setting-label">Show Alternate Signing Location Section</div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkShowAlternateLocation"
                          checked={currentSetting.chkShowAlternateLocation || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkShowAlternateLocation: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">Show Billing Section</div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkShowBillingSection"
                          checked={currentSetting.chkShowBillingSection || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkShowBillingSection: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">Show Agent Info For All Customers In Manage Request</div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkShowAgentInfoToCustomer"
                          checked={currentSetting.chkShowAgentInfoToCustomer || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkShowAgentInfoToCustomer: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">
                    Agent Docs Notice On Open{''}
                    {renderTooltipInfo(
                      `Check to allow notifying the Signing Service every time an Agent opens the Docs Uploader to view documents.`
                    )}
                  </div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkAgentOpensDocsNotice"
                          checked={currentSetting.chkAgentOpensDocsNotice || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkAgentOpensDocsNotice: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">
                    Docs Upload Notice{' '}
                    {renderTooltipInfo(
                      `Check to automatically set each new signing to send an email notice when a customer uploads documents. You can also keep this unchecked and turn this setting on for specific signings. Documents scanned and uploaded by the notary follow the same notification criteria.`
                    )}
                  </div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkSendDocsNotice"
                          checked={currentSetting.chkSendDocsNotice || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkSendDocsNotice: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">
                    Enable Scan Backs{' '}
                    {renderTooltipInfo(
                      `When enabled, the agent will be required to upload documents in order to complete the signing. On the Signing Request form, the Scan Back Req'd checkmark must be selected on requests where the Scan Back is required. Scan Back uploads live for 10 days in the system and are then removed automatically.`
                    )}
                  </div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkEnableScanBacks"
                          checked={currentSetting.chkEnableScanBacks || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkEnableScanBacks: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">
                    Auto Enter Language in Agent Search{' '}
                    {renderTooltipInfo(
                      `Check to automatically use the language entered in the request as part of the search for the agent when assigning an agent. The language will automatically be entered in the language search box, but the word 'English' is ignored since that is assumed.`
                    )}
                  </div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkUseLanguage"
                          checked={currentSetting.chkUseLanguage || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkUseLanguage: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="setting-label">Keep confirmation window open after email send</div>
                  <div>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          name="chkKeepConfirmationEmailDialogOpen"
                          checked={currentSetting.chkKeepConfirmationEmailDialogOpen || false}
                          onChange={(e) => {
                            setCurrentSetting({
                              ...currentSetting,
                              chkKeepConfirmationEmailDialogOpen: e.target.checked,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div style={{ display: 'block' }}>
                  <div className="setting-label">Add'l Required Fields For Request</div>
                  <br />
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: 'end',
                        paddingRight: '15px',
                      }}
                    >
                      Co-Borrower First and Last Name
                    </div>
                    <div style={{ flexGrow: 0 }}>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            name="chkReqCoBorrower"
                            checked={currentSetting.chkReqCoBorrower || false}
                            onChange={(e) => {
                              setCurrentSetting({
                                ...currentSetting,
                                chkReqCoBorrower: e.target.checked,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: 'end',
                        paddingRight: '15px',
                      }}
                    >
                      Quantity of Loans to Sign
                    </div>
                    <div style={{ flexGrow: 0 }}>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            name="chkReqQuantityOfLoans"
                            checked={currentSetting.chkReqQuantityOfLoans || false}
                            onChange={(e) => {
                              setCurrentSetting({
                                ...currentSetting,
                                chkReqQuantityOfLoans: e.target.checked,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: 'end',
                        paddingRight: '15px',
                      }}
                    >
                      Check All That Apply' Section
                    </div>
                    <div style={{ flexGrow: 0 }}>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            name="chkReqAllThatApply"
                            checked={currentSetting.chkReqAllThatApply || false}
                            onChange={(e) => {
                              setCurrentSetting({
                                ...currentSetting,
                                chkReqAllThatApply: e.target.checked,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ alignSelf: 'start', flexGrow: 3 }} className="setting-label">
                    Proposed Fees($)
                  </div>
                  <br />
                  <div style={{ flexGrow: 1 }}>
                    <EditableTable
                      addNew
                      edit
                      lookups={proposedFeeLookups}
                      columns={proposedFeeCols}
                      rows={getJsonOr(currentSetting.proposedFees)}
                      emitNewRows={(val) => handleEditTableUpdate(val)}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ alignSelf: 'start', flexGrow: 2 }} className="setting-label">
                    Upload Invoice Template
                  </div>
                  <div>
                    <FileUploadV2 files={invocieTemplate} openUploader={() => setOpenFileUploader(true)} />
                  </div>
                  {openFileUploader && (
                    <FileUploadProvider
                      context={{
                        uploadDocType: 'CustInvoiceTemplate',
                        settingType: 'InvoiceTemplate',
                      }}
                    >
                      <UploadFilesDialog
                        open={openFileUploader}
                        title={'Customer Invoice Template'}
                        buttonTrueText={'Upload'}
                        buttonFalseText={'Close'}
                        documentData={{
                          title: '',
                          notes: 'Please ensure that your template adheres to the basics.',
                          maxSize: 100000,
                        }}
                        onClose={(val) => {
                          if (val?.length) {
                            reloadSettingProperty('custInvoiceTemplateFileID');
                          }
                          setOpenFileUploader(false);
                        }}
                      />
                    </FileUploadProvider>
                  )}
                </div>
                <div>
                  <div className="setting-label">Signing Documents File Storage</div>
                  <div>
                    <FormControlLabel
                      label="Use Custom File Storage"
                      control={
                        <Checkbox
                          name="chkShowAlternateLocation"
                          checked={openStorageOptions}
                          onChange={(e) => {
                            setOpenStorageOptions(e.target.checked);
                            if (!fileStorageRef.current && !e.target.checked) {
                              setCurrentSetting({
                                ...currentSetting,
                                signingFileStorage: '',
                              });
                            }
                          }}
                        />
                      }
                    />
                  </div>
                  {openStorageOptions && (
                    <div style={{ flexBasis: '100%', textAlign: 'center' }}>
                      <FileStorageOptions
                        values={customStorageInfo}
                        emitNewValues={(data) => {
                          setCurrentSetting({
                            ...currentSetting,
                            signingFileStorage: JSON.stringify(data),
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ alignSelf: 'start', flexGrow: 3 }} className="setting-label">
                    Custom Registration Items
                  </div>
                  <br />
                  <div style={{ flexGrow: 1 }}>
                    <EditableTable
                      addNew
                      edit
                      columns={customRegItems}
                      rows={getJsonOr(currentSetting.customRegistrationItems)}
                      emitNewRows={(val) => handleCustomRegItemUpdate(val)}
                    />
                  </div>
                </div>

                {/* =========================                       ===========================*/}
                {/* =========================   Signing Agreement  ===========================*/}
                {/* =========================                       ===========================*/}

                <div data-testid="signing-aggreament" style={{ display: 'flex' }}>
                  <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction={{ xs: 'col', sm: 'row' }}
                    useFlexGap
                    sx={{ flexWrap: 'wrap', width: '100%' }}
                  >
                    <Stack
                      direction={{ xs: 'col', sm: 'row' }}
                      useFlexGap
                      sx={{ justifyContent: 'space-between', width: '100%' }}
                    >
                      <div style={{ alignSelf: 'start', flexGrow: 2 }} className="setting-label">
                        Agent Signing Agreement
                        {renderTooltipInfo(
                          `Upload your User Agreement as a PDF. The most recent upload will be the current agreement that notaries will see. Prior agreements will be retained in the table below with links to those agreements. When you are ready to activate the agreement on your website, select the Activate Agreement checkbox to the right and then the update button at the bottom of this section.`
                        )}
                      </div>
                      <div style={{ display: 'flex', marginLeft: 'auto' }}>
                        <div
                          style={{
                            flexGrow: 1,
                            textAlign: 'end',
                            paddingRight: '15px',
                          }}
                        >
                          {renderTooltipInfo(
                            `Check to make the current agreement visible to agents upon login. This will require the agent to agree or decline your agent agreement in order to be able to navigate your website.`
                          )}
                          Activate Agreement
                        </div>
                        <div style={{ flexGrow: 0 }}>
                          <FormControlLabel
                            label=""
                            control={
                              <Checkbox
                                name="chkEnableContractAgreement"
                                checked={currentSetting.chkEnableContractAgreement || false}
                                onChange={(e) => {
                                  setCurrentSetting({
                                    ...currentSetting,
                                    chkEnableContractAgreement: e.target.checked,
                                  });
                                }}
                              />
                            }
                          />
                        </div>
                      </div>
                    </Stack>

                    <div
                      style={{
                        width: isMobile ? '100%' : '300px',
                        minWidth: '300px',
                        flexGrow: isMobile ? 1 : 0,
                        marginLeft: 'auto',
                      }}
                    >
                      {/*<FileUploadV2 files={signingAgreementTemplate} openUploader={() => setOpenAgreementUploader(true)} />*/}
                      <FileUploadV2
                        files={signingAgreementTemplate}
                        openUploader={() => setOpenAgreementUploader(true)}
                      />
                    </div>

                    <EditableTable
                      editable
                      edit
                      columns={agreementCustomColumns}
                      rows={currentSetting?.contractAgreements || []}
                      emitNewRows={(val) => handleAgreementRegItemUpdate(val)}
                    />
                  </Stack>

                  {openAgreementUploader && (
                    <FileUploadProvider
                      context={{
                        uploadDocType: 'ServiceAgreementForAgent',
                      }}
                    >
                      <UploadFilesDialog
                        open={openAgreementUploader}
                        title={'Agents Signing Agreement'}
                        buttonTrueText={'Upload'}
                        buttonFalseText={'Close'}
                        documentData={{
                          title: '',
                          notes: 'Please ensure that your template adheres to the basics.',
                          maxSize: 3145728, // 3 megabytes
                        }}
                        onClose={(val) => {
                          if (val?.length) {
                            reloadSettingProperty('contractAgreements');
                          }

                          setOpenAgreementUploader(false);
                        }}
                      />
                    </FileUploadProvider>
                  )}
                </div>
              </div>
            </AccordionDetails>
            <div className="action-section">
              <Button
                disabled={dataIsLoading || blockUpdate}
                variant="contained"
                onClick={() => updateBusSettings(panelKey, 'WebSite')}
              >
                Update
              </Button>
            </div>
          </>
        )}
      </StyledAccordion>
      {dataIsLoading && progressType == panelKey && <LinearProgress sx={{ top: '-5px' }} color="inherit" />}
    </>
  );
};

export default WebSiteSettings;

//
