import React, { Fragment, memo, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Card, Chip, Divider, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import LinearProgress from '@mui/material/LinearProgress';
import UpdateIcon from '@mui/icons-material/Update';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

// import ProfileDialogNew from './ProfileDialogNew';
import { HtmlTooltip } from '../../../Style';
import { appColors } from '../../../theme/AppThemes';
import { useSelector } from 'react-redux';
import AgentAvailRequestInfo from './AgentAvailRequestInfo';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Show from '../../common/others/Show';
import { isEmptyObject } from '../../../helpers';

const proMemberIcon = require(`../../../assets/images/ico_proStatus.png`);

const SmallAvatar = styled(Avatar)(
  ({ theme }) => css`
    width: 50px;
    height: 22px;
    border-radius: 7px;
    border: 2.5px solid ${theme.palette.background.paper} !important;
    zoom: 90%;

    img {
      object-fit: cover;
      top: 0.5px;
    }
  `
);

const StyledDataItemRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin-bottom: 7px;

    .MuiCard-root {
      padding: 5px;
      font-size: 0.75rem;
      place-content: space-around;
    }

    :hover {
      #rowLeft {
        > div:nth-child(2) {
          .MuiSvgIcon-root {
            color: ${theme.palette.success.main};
            animation: left_to_right 1s linear infinite;
          }
          @keyframes left_to_right {
            from {
              margin-left: 30%;
            }
            to {
              margin-left: 70%;
            }
          }
        }
      }
    }
  `
);

const StyledDataItemAssign = styled(Card)(
  ({ theme }) => css`
    width: 70px;
    min-height: 100px !important;
    text-align: center;
    text-align: center;
    margin-right: 1px;
    padding: 0px !important;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    &.agent-selected {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#f1fff03d'};

      > div:nth-child(2) {
        .MuiSvgIcon-root {
          color: green;
        }
      }
    }

    :hover {
      > div:nth-child(2) {
        .MuiSvgIcon-root {
          animation: none !important;
        }
      }
    }

    height: 100%;
    position: relative;

    > div {
      height: 33%;
      position: relative;
      justify-content: center;
      align-content: center;
    }

    > div:first-child {
      place-items: center;
    }

    > div:nth-child(1) {
      .MuiSvgIcon-root {
        font-size: 1.6rem;
        width: 0.76em;
        top: 3px;
        position: relative;
      }
    }

    > div:nth-child(2) {
      button {
        padding: 3px;

        .MuiSvgIcon-root {
          color: #c1a20f;
          font-size: 1.6rem;
        }
      }
    }

    .MuiChip-root {
      border-radius: 5px;
      font-weight: 500;
      font-size: 0.7rem;
      height: 20px;
      color: #03a9f4;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  `
);

const StyledDataItem = styled(Card)(
  ({ theme, $showLeftSection, $priColor }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    position: relative;
    border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    > div {
      flex: 1 1 0px;
      align-self: center;
    }

    .strong-label {
      font-weight: 500;
    }

    &.agent-selected {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#f1fff03d'};

      .names {
        h3 {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : '#005e74'};
        }
      }
    }

    @media screen and (max-width: 1000px) {
      max-height: 155px;
    }

    .outer-div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .mobile-show {
      @media screen and (min-width: 700px) {
        display: flex;
      }
    }

    .soComments {
      position: absolute;
      top: 5px;
      right: -25px;
    }

    :hover {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};
      clip-path: none;

      .names {
        h3,
        .MuiSvgIcon-root {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : ''};
        }
      }

      .stats-others {
        .profile-completion {
          .percentage {
            background: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};

            span: {
              font-weight: 600 !important;
            }
          }
        }
      }
    }

    .email-checkBox {
      flex-grow: 0.1;
      span {
        margin-left: -10px;
      }
    }

    .avatar {
      flex-grow: 0;

      .MuiAvatar-root {
        border: 2px solid ${theme.palette.mode == 'dark' ? theme.palette.border.main : theme.palette.primary.main};
        color: ${theme.palette.primary.main};
        background-color: ${theme.palette.mode == 'dark'
          ? theme.palette.background.paper
          : appColors[$priColor].palette.border.main};
      }

      @media screen and (max-width: 700px) {
        margin-right: 20px;
      }
    }

    .names {
      flex: 1;
      margin-left: 20px;

      > div {
        display: flex;
        width: fit-content;
        cursor: pointer;

        .MuiSvgIcon-root.launch-icon {
          font-size: 1.1rem;
          top: 2px;
          position: relative;
          color: #1e879f;
        }
      }

      h3 {
        color: #1e879f;
      }

      p {
        max-width: 175px;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }
    }

    .alt-names {
      display: flex;
      flex-direction: column;
      margin-left: 4px;

      h3 {
        color: #1e879f !important;
      }

      p {
        margin-right: 4px;
      }
    }

    .contact {
      min-height: 70px;
      flex: 1;

      > div {
        overflow-wrap: break-word;
        display: flex;
        padding-left: 8px;
        gap: 5px;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;

        .MuiSvgIcon-root {
          font-size: 1rem;
          align-self: center;
        }
      }

      span {
        word-break: break-word;
        line-height: 100%;
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
        align-items: center;
      }
    }

    .stats-others {
      min-height: 70px;
      flex: 1;

      > div {
        display: flex;
        gap: 10px;

        > div:nth-child(1) {
          flex: 1;
          min-width: 75px;
          text-align: right;
        }

        > div:nth-child(2) > div:first-child {
          flex: 1;
        }
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
      }

      .profile-completion {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: start;

        > div:first-child {
          position: relative;
        }

        .percentage {
          position: absolute;
          left: 15%;
          top: -8px;
          font-weight: bolder;
          background: ${theme.palette.background.paper};
          border-radius: 15%;
          padding: 0px 2px 0px;
        }
      }
    }

    .features {
      min-height: 70px;
      flex: 1.5;

      > div {
        display: flex;
        align-items: center;

        > div:nth-child(1) {
          flex: 1;
          min-width: 90px;
          text-align: right;
        }

        > div:nth-child(2) {
          flex: 1;
        }
      }

      > div:not(:last-child) {
        margin-bottom: 5px;
      }

      .label-description {
        display: flex;
        word-break: break-word;
        /* line-height: 100%; */

        span {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .more-icons {
      position: absolute;
      bottom: 8px;
      right: 15px;
      text-align: right;
      display: grid;

      .miles {
        border-radius: 5px;
        font-weight: 500;
        font-size: 0.7rem;
        height: 20px;
        color: #03a9f4;
        width: fit-content;
        justify-self: end;
        margin-bottom: 5px;
      }
    }

    .profile-updated {
      height: auto;
      border-radius: 5px;

      .MuiChip-icon {
        margin-left: 2.5px;
      }

      .MuiChip-label {
        width: 30px;
        padding: 0px;
        padding-right: 4px;
        font-size: 0.7rem;
        text-align: end;
      }
    }

    .more-icons.favorite {
      display: flex;
      gap: 5px;
      height: 20px;
      line-height: 20px;
    }
  `,

  ({ withnotes, $showLeftSection }) =>
    withnotes === 'true' &&
    css`
      position: relative;
      border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 102%);

      ::after {
        content: '';
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        background-color: #e1e1f2;
        top: 0;
        right: 0;
        border-bottom-left-radius: 20px;
        border-bottom-style: groove;
        text-align: center;
        z-index: 5;
      }

      :hover {
        .soComments {
          right: 5px;
          z-index: 5;
          color: blueviolet;
        }

        ::after {
          font-family: 'Material Icons';
          font-size: 1.25rem;
          color: blueviolet;
          z-index: 0;
          width: 35px;
          height: 35px;
        }
      }
    `
);

const ratingLabels = {
  0: 'Unrated',
  1: 'Useless',
  2: 'Poor',
  3: 'Ok',
  4: 'Good',
  5: 'Excellent',
};

const Item = ({
  setting,
  listMode,
  contact,
  requestID,
  fromContactManager,
  profileLookups,
  assignedclassName,
  onProximitySearch,
  agentEmailRecipients,
  emitSelectedEmail,
  assignAgent = () => {},
}) => {
  const theme = useTheme();
  const history = useHistory();
  const currentRoute = history.location.pathname.replaceAll('/', '');
  const showLeftSection = currentRoute == 'contact-manager' ? false : true;
  const { customColor: appColor } = useSelector((state) => state.reducer.themeAppearance);

  const checkIfWasSelected = () => {
    if (agentEmailRecipients.length > 0) {
      let obj = agentEmailRecipients.filter((i) => i.publicId == contact.v2PublicID)[0];
      if (obj) return true;
      else return false;
    }
  };

  // const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = useState(() => checkIfWasSelected());

  const profileImage = `https://notarycafe.com/Content/profile/${contact.v2PublicID}_1.png`;

  useEffect(() => {
    if (listMode != 'email') setChecked(false);
    return () => {};
  }, [listMode]);

  const proMemberIconRender = (event) => {
    return contact.isMember ? <SmallAvatar alt="pro" src={proMemberIcon} /> : '';
  };

  const handleEmailCheckBox = (event, contactEmail) => {
    if (!checked) {
      let obj = { publicId: contact.v2PublicID, email: contactEmail };
      if (emitSelectedEmail(obj)) setChecked(true);
    } else {
      emitSelectedEmail({
        publicId: contact.v2PublicID,
        remove: true,
      });
      setChecked(false);
    }
  };

  const testIconClick = (event) => {
    event.stopPropagation();
  };

  const getNamesFromLookups = (type, withCommas) => {
    const invalidProfiles = !profileLookups || isEmptyObject(profileLookups);
    if (invalidProfiles) return [];

    const getNameById = (ncLookupId) => {
      switch (type) {
        case 'BGC':
          let obj = profileLookups['SelectProviderTypes']?.find((item) => item.index == ncLookupId);
          return obj?.value || '';
          break;
        default:
          break;
      }
    };

    if (withCommas) {
      return contact.backgroundCheckList?.map((bck) => (bck.lookupName = getNameById(bck.ncLookupId))) || [];
    } else {
      return (
        contact.backgroundCheckList?.map((bck) => {
          return { ...bck, lookupName: getNameById(bck.ncLookupId) };
        }) || []
      );
    }
  };

  const backgroundCheckTooltip = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Background Checks:</b>
        </div>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        {getNamesFromLookups('BGC').map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <strong>
                #{index + 1}&nbsp;&nbsp;
                {item.lookupName}.&nbsp;&nbsp;
              </strong>
            </div>
            <div style={{ flex: 1, textAlign: 'end' }}>
              <i>
                Expires On: <b>{item.expiresOn}</b>
              </i>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  const regDocumentTooltipContent = () => {
    return (
      <Fragment>
        <div className={'with-header'}>
          <b>Registered Documents:</b>
        </div>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        {contact.regDocumentList?.map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 2 }}>
              <strong>#{index + 1}&nbsp;&nbsp;</strong>
              {item.name} ({item.isCustom ? item.initials : item.code}
              ).&nbsp;&nbsp;
            </div>
            {item.expirationDate && (
              <div style={{ textAlign: 'end' }}>
                <i>
                  Expires On: <b>{item.expirationDate}</b>
                </i>
              </div>
            )}
          </div>
        ))}
      </Fragment>
    );
  };

  const profileUpdateTimeAgo = () => (
    <>
      {contact.updatedStatus == 'Red' && (
        <HtmlTooltip arrow title="Profile updated over 180 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'red !important' }} />}
            label="180"
          />
        </HtmlTooltip>
      )}
      {contact.updatedStatus == 'Yellow' && (
        <HtmlTooltip arrow title="Profile updated within the last 120 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'orange !important' }} />}
            label="120"
          />
        </HtmlTooltip>
      )}
      {contact.updatedStatus == 'Green' && (
        <HtmlTooltip arrow title="Profile updated within the last 60 days.">
          <Chip
            className="profile-updated"
            size="small"
            icon={<UpdateIcon sx={{ color: 'green !important' }} />}
            label="60"
          />
        </HtmlTooltip>
      )}
    </>
  );

  return (
    <div style={{ display: 'flex' }}>
      {listMode === 'email' && (
        <div className="email-checkBox" style={{ alignSelf: 'center' }}>
          <Checkbox
            color={'info'}
            checkedIcon={<MailOutlinedIcon />}
            checked={checked}
            onChange={(e) => handleEmailCheckBox(e, contact.mainEmail)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
      <StyledDataItemRow sx={{ boxShadow: assignedclassName ? 3 : 0, borderRadius: '10px' }}>
        {showLeftSection && (
          <StyledDataItemAssign id="rowLeft" className={assignedclassName}>
            <AgentAvailRequestInfo availRequest={contact.agentAvailabilityInfo} />
            <div>
              <IconButton onClick={() => assignAgent(contact.v2PublicID)}>
                <DoubleArrowRoundedIcon fontSize="small" />
              </IconButton>
            </div>
            {onProximitySearch && (
              <div>
                <Chip
                  onClick={(e) => {
                    if (contact.mapLink) window.open(contact.mapLink, '_blank');
                    else e.stopPropagation();
                  }}
                  color="default"
                  size="small"
                  label={contact.miles ? contact.miles : '0'}
                />
              </div>
            )}
          </StyledDataItemAssign>
        )}
        <StyledDataItem
          id="rowRight"
          className={assignedclassName}
          $showLeftSection={showLeftSection}
          $priColor={appColor}
          withnotes={contact.sO_Comments ? 'true' : 'false'}
          // onClick={() => setOpenDialog(true)}
        >
          <div className="avatar">
            <Show when={contact?.notSynced}>
              <HtmlTooltip
                style={{ display: 'flex' }}
                arrow
                followCursor
                title={'Profile is out of sync with Notary Cafe.'}
              >
                <SyncProblemIcon
                  sx={{
                    width: 24,
                    height: 24,
                    top: 5,
                    left: 5,
                    position: 'absolute',
                    zIndex: 1,
                    borderRadius: '50%',
                    color: theme.palette.warning.main,
                    background: theme.palette.nsTableHeaderBG.main,
                  }}
                />
              </HtmlTooltip>
            </Show>
            <Stack direction="row" spacing={2}>
              {proMemberIconRender() ? (
                <Badge
                  sx={{ '.MuiBadge-badge': { p: 0 } }}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={proMemberIconRender()}
                >
                  <Avatar sx={{ width: 70, height: 70 }} src={profileImage} />
                </Badge>
              ) : (
                <Avatar sx={{ width: 70, height: 70 }} src={profileImage} />
              )}
            </Stack>
          </div>
          <div className="names">
            <div
              onClick={(e) => {
                let partialUrl = '';
                if (fromContactManager) {
                  partialUrl = `/contact-manager/profile`;
                } else {
                  partialUrl = `/manage-request/assign-notary/${requestID}/contact`;
                }
                history.push({
                  pathname:
                    partialUrl + `/agent/${contact.userTypeInitial === 'a' ? contact.v2PublicID : contact.userID}`,
                  state: {
                    fullName: contact.nameAndCompany.fullName,
                    company: contact.nameAndCompany.company,
                  },
                });
                e.stopPropagation();
              }}
            >
              <h3>{contact.nameAndCompany.fullName}</h3>
              {onProximitySearch && <LaunchOutlinedIcon className="launch-icon" fontSize="small" />}
            </div>
            <p>{contact.nameAndCompany.company.replace('<br>', '')}</p>
            <div className="stats">
              <p style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                <strong>12m:</strong>
                {contact.signings_12mths} {` | `} <strong>My: </strong>
                {contact.signings_MyPortal} {` | `} <FavoriteBorderOutlinedIcon style={{ fontSize: '1.2em' }} />
                {contact.preferredCount}
              </p>
            </div>
          </div>
          <div className="contact">
            <div className="">
              <LocationOnOutlinedIcon fontSize="small" />{' '}
              <span>
                {contact.billingAddress?.city} {contact.billingAddress?.state} {contact.billingAddress?.zipCode}
              </span>
            </div>
            <div className="">
              <AlternateEmailOutlinedIcon fontSize="small" />{' '}
              <span onClick={(e) => e.stopPropagation()}>{contact.mainEmail.toLowerCase()}</span>
            </div>
            <div className="">
              <PhoneIphoneOutlinedIcon fontSize="small" />{' '}
              <HtmlTooltip disableFocusListener arrow followCursor maxwidth="350px" title={contact.allPhoneInfo}>
                <span>{contact.preferredPhone}</span>
              </HtmlTooltip>
              <StarBorderOutlinedIcon sx={{ fontSize: '1rem' }} fontSize="small" color="default" />
            </div>
          </div>
          <div className="stats-others">
            <div>
              <div className="strong-label">Complete:</div>
              <Box className="profile-completion">
                <div>
                  <Box sx={{ mr: 1 }}>
                    <LinearProgress variant="determinate" value={contact.profileScore} />
                  </Box>
                  <Box className="percentage">
                    <Typography variant="caption" color="text.secondary">
                      {`${contact.profileScore}%`}
                    </Typography>
                  </Box>
                </div>
                <div className="">{profileUpdateTimeAgo()}</div>
              </Box>
            </div>
            <div style={{}}>
              <div className="strong-label">Rating:</div>
              <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <Rating
                    sx={{ '> label': { marginRight: '5px' } }}
                    readOnly
                    size="small"
                    max={5}
                    value={contact.sO_Rating < 0 ? 0 : contact.sO_Rating}
                  />
                </div>
              </Box>
            </div>
          </div>
          <div className="features">
            <div style={{ display: 'flex', gap: '5px' }}>
              <div className="strong-label">BGC:</div>
              <div className="label-description" style={{ display: 'flex' }}>
                {getNamesFromLookups('BGC').length ? (
                  <HtmlTooltip arrow followCursor title={backgroundCheckTooltip()} minwidth="350px">
                    <span className="bgc-value">{getNamesFromLookups('BGC', true).join(', ')}</span>
                  </HtmlTooltip>
                ) : (
                  <i style={{ color: 'darkgrey' }}>No Background Checks</i>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div className="strong-label">Agent Docs:</div>
              <div className="label-description" style={{ display: 'flex' }}>
                {contact.regDocumentList?.length ? (
                  <HtmlTooltip disableFocusListener arrow followCursor title={regDocumentTooltipContent()}>
                    <span>{contact.regDocumentList.map((d) => (d.isCustom ? d.initials : d.code)).join(', ')}</span>
                  </HtmlTooltip>
                ) : (
                  <i style={{ color: 'darkgrey' }}>No Documents</i>
                )}
              </div>
            </div>
          </div>
          {contact.sO_Comments && (
            <div className="soComments">
              <HtmlTooltip arrow disableFocusListener title={contact.sO_Comments}>
                <StickyNote2OutlinedIcon fontSize="small" />
              </HtmlTooltip>
            </div>
          )}
          <div className="more-icons favorite">
            {contact.inPersonalList && (
              <div>
                <FavoriteIcon style={{ color: 'red', width: '0.9rem' }} />
              </div>
            )}
            {!showLeftSection && setting.showProximityEnable && (
              <Chip
                className="miles"
                onClick={(e) => testIconClick(e)}
                color="default"
                size="small"
                label={contact.miles ? contact.miles : '0'}
              />
            )}
          </div>
        </StyledDataItem>
      </StyledDataItemRow>
      {/* {openDialog && (
        <ProfileDialogNew
          open={openDialog}
          contact={contact}
          onClose={setOpenDialog}
        />
      )} */}
    </div>
  );
};

// function areEqual(prevProps, nextProps) {
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */
//   return _.isEqual(nextProps, prevProps);
// }

export const DataItemDesktop = memo(Item);
