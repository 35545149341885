import React, { useState, memo } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { TextField, Autocomplete, Checkbox, Box } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { sortBy } from '../../../../helpers';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledAutoComplete = styled('div')(
  ({ theme, $withoutCard }) => css`
    padding: ${$withoutCard ? '0px' : '10px'};

    .actions {
      margin-top: 10px;
      justify-content: start;
      display: flex;

      .cancel {
        min-height: 30px;
        width: 50%;
      }

      .apply {
        min-height: 30px;
        margin-left: 5px;
        width: 100%;
      }
    }
  `
);

const Combo = (props) => {
  const [state, setState] = useState({
    id: props.id || '',
    label: props.label || '',
    valueLabel: props.valueLabel || '',
    value: props.value || '',
    backendValProp: props.backendValProp || '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    props.applyFilter(state);
  };

  return (
    <StyledAutoComplete $withoutCard={props.withoutCard}>
      <Box onSubmit={onSubmit} component={props.hideActions ? '' : 'form'}>
        {props.multiple ? (
          <Autocomplete
            id="fancy-filter-autocomplete"
            multiple
            size="small"
            limitTags={3}
            disableClearable={props.disableClearable}
            disableCloseOnSelect
            options={
              props.sortBy
                ? sortBy(props.options, { prop: props.sortBy })
                : props.options || []
            }
            getOptionLabel={(option) =>
              option?.label || option?.value || option
            }
            isOptionEqualToValue={(option, value) =>
              option?.label
                ? option?.label === value.label
                : option?.value === value.value
            }
            renderOption={(props, option, { selected }) => (
              <li style={{ fontSize: '12.5px', padding: '0px' }} {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.label || option?.value}
              </li>
            )}
            value={state.valueLabel || state.value || []}
            onChange={(e, val) => {
              e.stopPropagation();
              setState({ ...state, value: val || [] });
              if (props.hideActions) {
                props.applyFilter({ ...state, value: val || [] });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label={props.label} />
            )}
          />
        ) : (
          <Autocomplete
            id="checkboxes-tags-demo"
            size="small"
            limitTags={3}
            disableClearable={props.disableClearable}
            options={
              props.sortBy
                ? sortBy(props.options, { prop: props.sortBy })
                : props.options || []
            }
            getOptionLabel={(option) =>
              option?.label || option?.value || option
            }
            isOptionEqualToValue={(option, value) =>
              option?.label
                ? option?.label === value.label
                : option?.value === value.value
            }
            renderOption={(props, option, { selected }) => (
              <li style={{ fontSize: '12.5px' }} {...props}>
                {option?.label || option?.value || option}
              </li>
            )}
            value={state.valueLabel || state.value || ''}
            onChange={(e, val) => {
              e.stopPropagation();
              setState({ ...state, value: val });
              if (props.hideActions) {
                props.applyFilter({ ...state, value: val });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label={props.label} />
            )}
            renderTags={() => null}
          />
        )}
        {!props.hideActions && (
          <div className="actions">
            <Button
              className="cancel"
              color="default"
              onClick={() => props.applyFilter(false)}
            >
              Cancel
            </Button>
            <Button
              className="apply"
              type="submit"
              onClick={() => props.applyFilter(state)}
            >
              Apply Filter
            </Button>
          </div>
        )}
      </Box>
    </StyledAutoComplete>
  );
};

export const DropDown = memo(Combo);
