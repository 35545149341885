import React from 'react';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles, useTheme } from '@mui/styles';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { F } from '../../helpers/functions';
import ModalTitle from '../common/modal-service/ModalTitle';
import Show from '../common/others/Show';
import AgentProfileForm from './AgentProfileForm';
import { useLoadingStore } from '../../context/loading.store';
import { useAgentProfileFormStore } from './hooks/useAgentProfileFormStore';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme, context) => ({
  footer: {
    '& button': {
      maxWidth: '320px',
    },
    '& .ns-agreement-company': {
      color: theme.palette.primary.main,
    },
  },

  header: {
    display: 'block !important',
    '& h1': {
      fontWeight: '600',
    },
    '& .ns-agreement-company': {
      color: theme.palette.primary.main,
    },
  },
}));

export const AgentProfileFormModal = ({
  open,
  preventDecline = false,
  handleClose = F,
  handleSubmit = F,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const loading = useLoadingStore((s) => s.loading);
  const disabledActions = useAgentProfileFormStore((s) => s.blockButtonActions);
  const resetStore = useAgentProfileFormStore((s) => s.cleanDupState);
  const disabledSubmitWhen = loading || disabledActions;
  const disabledCancelWhen = loading && disabledActions;

  const handleCloseModal = () => {
    resetStore();
    handleClose();
  };

  return (
    <>
      <ModalTitle className={classes.header} closeable handleClose={handleCloseModal}>
        <Stack direction={'row'} justifyContent="flex-start" alignItems={'center'}>
          <Show when={!isMobile}>
            <AccountCircleIcon sx={{ fontSize: '48px' }} />
          </Show>
          <Box component="header" sx={{ px: 2 }}>
            <Typography
              variant="h1"
              align="left"
              sx={{ fontSize: isMobile ? '16px' : '18px', maxWidth: '100%', marginRight: '20px', pb: isMobile ? 1 : 0 }}
            >
              Create Agent Profile
            </Typography>
          </Box>
        </Stack>
      </ModalTitle>
      <DialogContent sx={{ padding: isMobile ? '0 !important' : '10px', paddingBottom: 0 }}>
        <AgentProfileForm isMobile={isMobile} />
      </DialogContent>
      <DialogActions className={classes.footer} sx={{ flexDirection: 'column', padding: '8px 16px' }}>
        <Stack direction="row" spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button variant="outlined" disabled={disabledCancelWhen} color="text" onClick={handleCloseModal} focusRipple>
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            form="AgentProfileForm"
            disabled={disabledSubmitWhen}
            endIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Create Agent
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default AgentProfileFormModal;
