import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const contactManagerService = {
  getContactList: async function (paramObj, signal) {
    paramObj.agentRegDocs = paramObj.agentRegDocs || null;
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(paramObj),
      signal: signal,
    };
    const params = {};
    const url = `/api/ContactManager/getContactList`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  getContactQuickView: async function (profileType, profileId) {
    const options = { headers: await authHeader() };
    const params = { userType: profileType, profileId: profileId };
    const url = `/api/ContactManager/getContactDetailQuickView`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  checkUserNameAvailability: async function (username, profileType = 'agent') {
    const options = { headers: await authHeader() };
    const url = `/api/ContactManager/${profileType}/validate-username`;
    return Fetch.get(url, { username }, options).then((res) => res.json());
  },

  getNewProfile: async function (id, signal) {
    const options = { headers: await authHeader(), signal };
    const params = {
      identityId: id,
    };
    const url = `/api/ContactManager/agent/get-new-profile`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  submitAgentProfileForm: async function (params, body, signal, myResponseHandler) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(body),
      signal: signal,
    };
    const url = `/api/ContactManager/agent/new-profile`;

    return Fetch.post(url, params, options).then((resp) => handleResponse(resp, myResponseHandler));
  },
};

export { contactManagerService };
