import React, { useState, useEffect, useContext } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { store } from '../../../store';
import { moduleTabService as service, portalService } from '../../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-root': {
      padding: '20px',
    },
  },
  form: {
    '& .MuiFormControl-marginDense': {
      marginBottom: '15px',
    },
  },
}));

const FormDialog = (props) => {
  const { open, onClose, formMode } = props;
  const classes = useStyles();
  const { portalID } = store.getState().reducer.global;
  const [tab, setTab] = useState(props.tab);
  const [portalRoles, setPortalRoles] = useState([]);
  const [defaultRoleValue, setDefaultRoleValue] = useState([]);
  const [tabsForDDL, setabsForDDL] = useState([]);
  const [parentTabInDDL, setParentTabInDDL] = useState('');

  useEffect(() => {
    service.getPortalTabsForDDL(portalID).then(
      (res) => {
        setabsForDDL(res);
        if (tab) {
          setParentTabInDDL(
            res.filter((item) => item.tabID === tab.parentId)[0]
          );
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    portalService.getPortalRoles(portalID).then(
      (res) => {
        setPortalRoles(res);
        if (tab.authorizedRolesAllString) {
          setDefaultRoleValue(
            res.filter((role) =>
              tab.authorizedRolesAllString.split(',').includes(role.roleName)
            )
          );
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const handleUpdate = () => {
    if (formMode === 'new') {
      tab.tabID = -1;
      tab.tabOrder = 0;
      tab.parentId = tab?.parentId > 0 ? tab.parentId : -1;
    }
    onClose(tab);
  };

  const handleAuthRolesUpdate = (value) => {
    let newRoleIds = value.map((a) => a.roleId);
    let newRoleNames = value.map((a) => a.roleName);
    setTab({
      ...tab,
      authorizedRoles: newRoleIds.join(';') + ';',
      authorizedRolesAllString: newRoleNames.join(';') + ';',
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Tab</DialogTitle>
        <DialogContent>
          <form className={classes.form} action="">
            <TextField
              label="Tab Name"
              required
              name="tabName"
              value={tab.tabName || ''}
              onChange={(e) => {
                setTab({
                  ...tab,
                  tabName: e.target.value,
                });
              }}
            />
            <TextField
              label="Tab Title"
              name="tabTitle"
              value={tab.title || ''}
              onChange={(e) => {
                setTab({
                  ...tab,
                  title: e.target.value,
                });
              }}
            />
            <TextField
              label="Description"
              multiline
              rows={3}
              value={tab.description || ''}
              onChange={(e) => {
                setTab({
                  ...tab,
                  description: e.target.value || '',
                });
              }}
            />
            <TextField
              id="prentTabDDL"
              select
              label="Parent Tab"
              value={parentTabInDDL}
              onChange={(e) => {
                setParentTabInDDL(e.target.value);
                setTab({ ...tab, parentId: e.target.value.tabID });
              }}
            >
              {tabsForDDL.map((option) => (
                <MenuItem key={option.tabID} value={option}>
                  {option.tabName}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={
                <Checkbox
                  name="isVissible"
                  size="small"
                  checked={tab.isVisible || false}
                  onChange={(e) => {
                    setTab({
                      ...tab,
                      isVisible: e.target.checked,
                    });
                  }}
                />
              }
              label="Hidden?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="isDisabled"
                  size="small"
                  checked={tab.isDisabled || false}
                  onChange={(e) => {
                    setTab({
                      ...tab,
                      isDisabled: e.target.checked,
                    });
                  }}
                />
              }
              label="Disabled?"
            />
            <br />
            <br />
            <Autocomplete
              id="asynchronous-demo"
              multiple
              size="small"
              margin="dense"
              options={portalRoles.map((option) => option)}
              value={defaultRoleValue}
              getOptionLabel={(option) => (option ? option.roleName : '')}
              onChange={(event, newValue) => {
                setDefaultRoleValue(newValue);
                handleAuthRolesUpdate(newValue);
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option.roleName}
                    key={index}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Authorized Roles"
                  placeholder="Assign Others"
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleUpdate} disabled={!tab}>
            {formMode === 'edit' ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
