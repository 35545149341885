import React, { Fragment, useContext, useEffect, useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Link, Box, CircularProgress, Divider, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import CircularProgressWithLabel from '../others/CircularProgressWithLabel';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { ItemMobileCss } from './style';
import { FileUploadContext } from './FileUploadContext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { UploadFilesService as service } from '../../../services';
import { dispatchRelativeAlert, nsRelativeAlert } from '../../../helpers';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import AppConfirmationDialog from '../others/AppConfirmationDialog';

export const UploadFilesItemMobile = ({
  scanBacks,
  newFileSelected,
  externalFiles,
  columns,
  row,
  editCallback,
  deleteCallback,
}) => {
  const {
    state,
    setState,
    signalController,
    startUploadFromChild,
    counterDispatch,
    collectFileError,
    setCollectFileError,
    uploadFiles,
    uploadFilesWithProgress,
    filesForLogging,
    setFilesForLogging,
    fileContext,
    canEdit,
    setIsSubmitting,
    setStartUploadFromChild,
  } = useContext(FileUploadContext);

  const [file, setFile] = useState(row);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, serError] = useState('');
  const debounceFn = useCallback(debounce(handleDebounceFn, 1800), []);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  useEffect(() => {
    setFile(row);
    return () => {};
  }, []);

  useEffect(() => {
    if (startUploadFromChild && newFileSelected) itemUploadFile();
    return () => {};
  }, [startUploadFromChild]);

  function handleDebounceFn(file) {
    if (fileContext.uploadDocType == 'SigningFiles') {
      if (!file.isLoan && !file.isTitle && !file.isOthers) dispatchRelativeAlert('Docs Types are required.', true);
      else UpdateMetaFile(file);
    } else {
      UpdateMetaFile(file);
    }
  }

  const UpdateMetaFile = (file) => {
    service.UpdateMetaFile(fileContext.uploadDocType, fileContext.requestID, file).then(
      (res) => {
        dispatchRelativeAlert('Document information was updated successfully.');
      },
      (error) => {
        dispatchRelativeAlert(error, true);
      }
    );
  };

  const validateSavedFile = (file) => {
    debounceFn(file);
  };

  const displayField = (col) => {
    if (!col.context || col.context == fileContext.uploadDocType)
      switch (col.type) {
        case 'Link':
          return (
            <div style={{ texAlign: 'left' }}>
              {row.isExternalPath ? (
                <Link href={row.path} underline="hover" target="_blank" rel="noopener">
                  {row?.displayName || row[col.field]}
                </Link>
              ) : (
                <Link
                  className="link"
                  underline="hover"
                  component="button"
                  onClick={() => service.downloadFile(fileContext, row.docId)}
                >
                  {row?.displayName || row[col.field]}
                </Link>
              )}
            </div>
          );

        case 'TextField':
          return (
            <Fragment>
              <TextField
                label={col.name}
                InputLabelProps={{ shrink: true }}
                className="input-description"
                value={file[col.field]}
                disabled={!canEdit || !state.settings.enableFileEdition}
                onChange={(e) => {
                  file[col.field] = e.target.value;
                  setFile((values) => ({
                    ...values,
                    [col.field]: e.target.value,
                  }));
                  editCallback ? editCallback(file) : validateSavedFile(file);
                }}
              />
            </Fragment>
          );

        case 'Checkbox':
          return (
            <div>
              <FormControlLabel
                className="checkboxes"
                control={
                  <Checkbox
                    checked={file[col.field] || false}
                    disabled={!canEdit || !state.settings.enableFileEdition}
                    onChange={(e) => {
                      file[col.field] = e.target.checked;
                      setFile({
                        ...file,
                        [col.field]: e.target.checked,
                      });
                      editCallback ? editCallback(file) : validateSavedFile(file);
                    }}
                    name={col.field.toString()}
                    size="small"
                  />
                }
                label={<label>{col.name.toString()}</label>}
                labelPlacement="start"
              />
              <Divider
                sx={{
                  margin: '3px',
                  position: 'relative',
                  left: '-5px',
                }}
                orientation="vertical"
                flexItem
              ></Divider>
            </div>
          );

        case 'DateTime':
          return <div>{moment(file[col.field]).format(newFileSelected ? 'h:mm A' : 'MM/DD/YY h:mm A')}</div>;

        case 'Size':
          return <Fragment>{file[col.field] > 0 ? '' : <LinkOutlinedIcon />}</Fragment>;

        case 'LabelName':
          return (
            <div className="progress-name">
              {isUploading ? (
                progress >= 0 &&
                progress <= 100 && (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgressWithLabel value={progress} />
                  </Box>
                )
              ) : (
                <>
                  {progress == 100 && <CheckIcon color="success" />}
                  {progress == -1 && (
                    <Tooltip title={error}>
                      <CloseIcon color="error" />
                    </Tooltip>
                  )}
                </>
              )}
              <span className={progress > 0 ? 'text-padding-left' : ''}>{file[col.field]}</span>
            </div>
          );

        default:
          return <Fragment>{file[col.field]}</Fragment>;
      }
  };

  const itemUploadFile = async () => {
    if (signalController) signalController.abort();

    let newController = new AbortController();
    let signal = newController.signal;
    let _fileMeta = _.cloneDeep(file);
    delete _fileMeta.file;
    delete _fileMeta.lastModified;

    let _file = {
      uploadedFilesMeta: [...new Array(), _fileMeta],
      externalFiles: [],
      uploadedFiles: [...new Array(), file.file],
    };

    setIsUploading(true);

    const handleProggress = (progress) => setProgress(progress);

    if (['SigningFiles', 'ScanBacks'].includes(fileContext.uploadDocType)) {
      try {
        await uploadFilesWithProgress(signal, _file, handleProggress).then(
          (res) => {
            row.hasError = false;
            setFilesForLogging((current) => [...current, _fileMeta?.name]);
            counterDispatch({ type: newFileSelected ? 'setNewUploadCounter' : 'setExternalCounter' });
          },
          (error) => {
            row.hasError = true;
            counterDispatch({ type: newFileSelected ? 'setNewUploadErrorCounter' : 'setExternalErrorCounter' });
            setCollectFileError([...collectFileError, error]);
            nsRelativeAlert(error, 3);
            serError(error);
          }
        );
      } catch (error) {
        // console.log(error);
        setCollectFileError([...collectFileError, error]);
        nsRelativeAlert(error, 3);
        serError(error);
      }
    } else {
      await uploadFiles(signal, _file).then(
        (res) => {
          row.hasError = false;
          dispatchRelativeAlert('Document was uploaded successfully.');
        },
        (error) => {
          dispatchRelativeAlert(error, true);
          row.hasError = true;
          setCollectFileError([...collectFileError, error.message]);
        }
      );
    }

    setStartUploadFromChild(false);
    setIsUploading(false);
    setIsSubmitting(false);
  };

  const itemDeleteFile = async () => {
    setIsDeleting(true);
    await service.deleteFile(fileContext, file.docId).then(
      (res) => {
        const newSavedFiles = state.savedFiles.filter(function (value) {
          return value.docId !== file.docId;
        });
        setState((values) => ({
          ...values,
          savedFiles: [...newSavedFiles],
        }));
        dispatchRelativeAlert('Document was deleted successfully.');
      },
      (error) => {
        dispatchRelativeAlert(error, true);
      }
    );
    setIsDeleting(false);
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteConfirmation = (val) => {
    if (val) {
      itemDeleteFile();
    } else {
      setOpenDeleteConfirmation(false);
    }
  };

  return (
    <ItemMobileCss variant="outlined" $scanbacks={scanBacks}>
      {openDeleteConfirmation && (
        <AppConfirmationDialog
          id="appDeleteConfirmationDialog"
          open={openDeleteConfirmation}
          title={'Delete uploaded documents'}
          content={'Do you really want to delete this file?'}
          onClose={() => {}}
          confirmCallback={(val) => handleDeleteConfirmation(val)}
        />
      )}
      {columns.map((column, index) => (
        <Fragment key={index}>
          {newFileSelected ? (
            <>
              {column.field == 'description' && (
                <TextField
                  className="item-columns-top selected input-description"
                  label={file?.name || file[column.field]}
                  variant={newFileSelected ? 'standard' : 'outlined'}
                  InputLabelProps={{ shrink: true }}
                  value={file[column.field]}
                  disabled={!canEdit || !state.settings.enableFileEdition}
                  onChange={(e) => {
                    file[column.field] = e.target.value;
                    setFile((values) => ({
                      ...values,
                      [column.field]: e.target.value,
                    }));
                    editCallback ? editCallback(file) : validateSavedFile(file);
                  }}
                />
              )}
              {column.field !== 'description' && !column.mobileHidden && (
                <div className="item-columns-bottom">{displayField(column)}</div>
              )}
            </>
          ) : (
            <>
              {externalFiles ? (
                !column.mobileHidden && (
                  <div className={`${column.type == 'Checkbox' ? 'item-columns-bottom' : ''}`}>
                    {displayField(column)}
                  </div>
                )
              ) : (
                <>
                  {(column.field == 'description' || column.field == 'name') && (
                    <div className="item-columns-top saved">{displayField(column)}</div>
                  )}
                  {column.field !== 'description' && column.field !== 'name' && (
                    <div className="item-columns-bottom saved">{displayField(column)}</div>
                  )}
                </>
              )}
            </>
          )}
        </Fragment>
      ))}
      <div
        style={{
          position: 'absolute',
          top: `${(!newFileSelected && !externalFiles) || scanBacks ? '0px' : ''}`,
          bottom: `${newFileSelected || externalFiles ? '0px' : ''}`,
          right: '0px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {!isDeleting ? (
          <IconButton
            onClick={() => {
              deleteCallback ? deleteCallback(file) : setOpenDeleteConfirmation(true);
            }}
            disabled={!canEdit || !state.settings.enableFileDeletion}
          >
            <DeleteIcon fontSize="medium" className="icon-color" />
          </IconButton>
        ) : (
          <Box>
            <CircularProgress size={'1rem'} />
          </Box>
        )}
      </div>
    </ItemMobileCss>
  );
};
