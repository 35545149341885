import React from 'react';
import { Alert, Typography } from '@mui/material';
import moment from 'moment/moment';
import Show from '../common/others/Show';

export const CompletionInfoAlert = ({ report, context, spacing = { marginBottom: '16px' } }) => {
  return (
    <div className="completion-info" style={spacing}>
      <Alert sx={{ width: '100%' }} severity="info">
        <div className="completion-info">
          <Typography variant="subtitle2">Name:</Typography>
          <span>{report.fullName}</span>
        </div>
        <div className="completion-info mobile-hide">
          <Typography variant="subtitle2">Date:</Typography>
          <span>
            {moment(report.signingDateTime).format(
              `MM/DD/YYYY ${moment(report.signingTime, 'hh:mm A', true).isValid() ? 'hh:mm A' : ''}`
            )}
          </span>
        </div>
        <div className="completion-info mobile-hide">
          <Typography variant="subtitle2">Loan #:</Typography>
          <span>
            <p>{report.loanNumber}</p>
          </span>
        </div>
      </Alert>
      <Show
        when={
          context?.signingRequest?.signingStatusID === 4 || (!context?.signingRequest && report?.signingStatusID === 4)
        }
      >
        <Alert sx={{ width: '100%' }} severity="warning">
          {`Report was submitted. Your request will not be completed until you upload your Scanned documents.`}
        </Alert>
      </Show>
    </div>
  );
};

export default React.memo(CompletionInfoAlert);
