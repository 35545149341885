import React from 'react';
import { css } from 'styled-components';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { MenuItem, Menu, Switch, Autocomplete, Button, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { blue, yellow, indigo, green, brown, teal, grey, red, orange } from '@mui/material/colors';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import PropTypes from 'prop-types';
import { appColors, appThemes } from './theme/AppThemes';
import { store } from './store';

const Div = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.div.main,
  padding: theme.spacing(1),
  borderRadius: '5px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 2px 2px -15px, rgba(0, 0, 0, 0.12) 0px 16px 15px -25px;',
}));

const Input = styled('input')({
  display: 'none',
});

const NavBarTopLink = styled(Link)`
  color: #767676;
  text-decoration: none;
`;

const FileChip = styled(Chip)(
  ({ theme, docid }) => css`
    background-color: ${docid ? 'transparent' : ''};
    border: 1px solid ${docid ? (theme.mode === 'dark' ? 'mediumpurple' : '#ae86ff') : '#c5c9c9'};
    color: ${docid ? (theme.mode === 'dark' ? 'white' : 'mediumpurple') : '#767676'};

    &:hover {
      background-color: ${docid ? (theme.mode === 'dark' ? 'mediumpurple' : '#f9e2ff') : ''};
    }
  `
);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid inherit',
  },
})((props) => (
  <Menu
    PaperProps={{
      variant: 'outlined',
    }}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    margin-bottom: 5px;

    & .MuiAccordion-root {
      margin-bottom: 3px;
    }

    &.MuiAccordion-root::before {
      background-color: initial;
    }

    & .MuiAccordionSummary-root,
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 40px;
      padding: 10px;

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    & .MuiAccordionDetails-root {
      display: block;
    }

    .row-wrapper {
      background-color: ${theme.palette.mode === 'light' ? '#f3f3f3' : ''};
      padding: 8px 16px;

      .rows.no-border {
        background-color: ${theme.palette.mode === 'light' ? 'white' : theme.palette.nsAppBG.main};
        padding: 16px;
        border-radius: 10px;
      }
    }

    & .rows {
      width: 100%;

      & .setting-label {
      }

      & > div {
        padding: 5px;
        display: flex;
        /* margin-bottom: 8px;
        border-bottom: 1px dashed #cdcdcd5e; */
        flex-wrap: wrap;

        & div:nth-child(1) {
          flex-grow: 1;
          align-self: center;
        }

        & div:nth-child(2) {
          flex-grow: 0;
          align-self: center;

          & .MuiFormControlLabel-root {
            margin-right: 0;
          }
        }
      }

      & > div:last-child {
        border-bottom: none;
      }
    }

    & .action-section {
      padding: 8px;
      border-top: 1px solid #8080802b;
      text-align: right;
    }

    & .heading {
      font-size: ${theme.typography.pxToRem(15)};
      flex-basis: 33.33%;
      flex-shrink: 0;
      font-weight: bold;
      align-self: center;
    }

    & .secondaryheading {
      font-size: ${theme.typography.pxToRem(15)};
      color: ${theme.palette.text.secondary};
    }

    & .rows.no-border {
      width: 100%;

      & .setting-label {
      }

      & > div {
        padding: 0px;
        display: flex;

        & div:nth-child(1) {
          flex-grow: 1;
          align-self: center;
        }

        & div:nth-child(2) {
          flex-grow: 0;
          align-self: center;

          & .MuiFormControlLabel-root {
            margin-right: 0;
          }
        }
      }

      /* & > div:not(:last-of-type) {
        margin-bottom: 10px;
      } */
    }
  `
);

const AntSwitch = styled(Switch)(
  ({ theme, disabled }) => css`
    width: 28px;
    height: 16px;
    padding: 0px;
    display: flex;

    .Typography-caption {
      line-height: 0;
    }

    &:active {
      & .MuiSwitch-thumb {
        width: 15px;
      }

      & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(9px);
      }
    }

    & .MuiSwitch-switchBase {
      padding: 2px;

      &.Mui-checked {
        transform: translateX(12px);
        color: #fff;

        & + .MuiSwitch-track {
          opacity: ${disabled ? 0.12 : 1};
          background-color: ${theme.palette.primary.main};
        }
      }
    }

    & .MuiSwitch-thumb {
      box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
      width: 12px;
      height: 12px;
      border-radius: 6px;
      transition: ${theme.transitions.create(['width'], {
        duration: 200,
      })};
    }

    & .MuiSwitch-track {
      border-radius: 16 / 2;
      background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)}'};
      box-sizing: border-box;
    }
  `
);

const AccordionWrapper = styled('div')(
  ({ theme, mb, height }) => css`
    height: ${height};
    & .MuiAccordion-root {
      margin-bottom: ${mb} !important;
    }

    & .MuiAccordionSummary-root,
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 50px;
      height: 50px;
    }

    & .MuiAccordionSummary-root {
      display: inline-flex;
      width: 100%;
      padding: 0 0 0 10px;

      .MuiAccordionSummary-expandIconWrapper {
        padding: 15px;
      }

      .MuiAccordionSummary-content {
        align-items: baseline;

        .summaryWrapper {
          display: flex;
          align-items: center;
          text-align: center;
          width: 100%;
          justify-content: space-between;

          div {
            padding: 5px;
            margin-right: 12px;
          }

          ${theme.breakpoints.only('xs')} {
            .heading {
              display: none !important;
            }
          }

          ${theme.breakpoints.up('xs')} {
            .heading {
              width: 100%;
              display: inline-grid;
              text-align: left;

              div {
                padding: 5px;
              }

              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }

    & .MuiAccordionDetails-root {
      display: flex;
      align-items: center;
      padding: 10px 16px 10px;

      > div:nth-child(1) {
        flex-grow: 1;
        text-align: left;
      }

      > div:nth-child(2) {
        > div {
          display: flex;

          div:nth-child(1) {
            text-align: right;
            margin-right: 5px;
            min-width: 140px;
          }
        }
      }
    }

    & .action-section {
      border-top: 1px solid #8080802b;
      display: flex;

      > div:nth-child(1) {
        flex-grow: 1;
        text-align: left;

        b {
          padding-right: 25px;
        }

        .MuiButtonBase-root {
          padding: 0;
        }
      }

      > div:nth-child(2) {
        button {
          margin-left: 15px;
        }
      }
    }
  `
);

const AutocompleteWithChip = styled(Autocomplete)(
  ({ theme }) => css`
    .MuiOutlinedInput-root {
      padding: 8px !important;
    }

    .MuiChip-root {
      margin-right: 5px;
    }
  `
);

const NSUpdateButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.nsButton.main,
    '&': {
      color: theme.palette.nsButton.contrastText,
    },

    '&:hover': {
      backgroundColor: theme.palette.nsButton.hover,
    },
  },
}))(Button);

const NSDeleteButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.nsDangerColor.main,
    '&': {
      color: theme.palette.nsDangerColor.contrastText,
    },

    '&:hover': {
      backgroundColor: theme.palette.nsDangerColor.hover,
    },
  },
}))(Button);

const NeutralButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.palette.btnNeutral?.main};
    color: ${theme.palette.btnNeutral?.contrastText};
    border: 1px solid ${theme.palette.mode === 'light' ? '#c4c4c4' : '#7a7a7a'};
    box-shadow: none;

    &:hover {
      background-color: ${theme.palette.btnNeutral?.hover};
      box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 0%),
        0px 1px 10px 0px rgb(0 0 0 / 10%);
    }
  `
);

export const requestStatusColors = {
  blue: {
    bg: blue[50],
    cl: blue[600],
    br: blue[100],
    dot: blue[200],
  },
  yellow: {
    bg: yellow[50],
    cl: yellow[600],
    br: yellow[100],
  },
  indigo: {
    bg: indigo[50],
    cl: indigo[600],
    br: indigo[100],
  },
  green: {
    bg: green[50],
    cl: green[600],
    br: green[100],
  },
  brown: {
    bg: brown[50],
    cl: brown[600],
    br: brown[100],
    dot: brown[200],
  },
  teal: {
    bg: teal[50],
    cl: teal[600],
    br: teal[100],
  },
  grey: {
    bg: grey[50],
    cl: grey[600],
    br: grey[100],
  },
  red: {
    bg: red[50],
    cl: red[600],
    br: red[100],
    dot: red[200],
  },
};

const RequestStatus = styled('div')(
  ({ theme, $colorBase, $fullradius = false }) => css`
    text-align: center;
    text-transform: uppercase;
    padding: 2px;
    background-color: ${theme.palette.mode === 'light' ? requestStatusColors[$colorBase].bg : ''};
    color: ${requestStatusColors[$colorBase].cl};
    border: 1px solid ${theme.palette.mode === 'light' ? requestStatusColors[$colorBase].br : 'rgb(83 83 83 / 42%)'};
    font-weight: ${$fullradius ? 'bolder' : '500'};
    border-radius: ${$fullradius ? '50%' : '3px'};
    font-size: ${$fullradius ? '0.8rem' : '0.7rem'};
    width: ${$fullradius ? '25px' : '75px'};
    height: ${$fullradius ? '25px' : '20px'};
    line-height: ${$fullradius ? '25px' : ''};
  `
);

const FileCounter = styled('div')(
  ({ theme, $colorBase }) => css`
    width: 20px;
    font-weight: 500;
    font-size: 0.7rem;
    text-align: center;
    text-transform: uppercase;
    /* padding: 2px; */
    margin-right: 5px;
    background-color: ${$colorBase};
    border: 1px solid ${theme.palette.mode === 'light' ? $colorBase : '#7a7a7a'};
    border-radius: 50%;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
  `
);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} sx={{ top: '-2px', cursor: 'pointer' }} />
))(({ theme, $priColor, minwidth, maxwidth, arrowmargin }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    marginLeft: arrowmargin ? arrowmargin : '',
    '&::before': {
      boxSizing: 'border-box',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    '& .with-header': {
      fontSize: '0.85rem',
    },
    maxWidth: maxwidth || 'fit-content',
    whiteSpace: 'pre-wrap',
    minWidth: minwidth || 'fit-content',
  },
}));

const StyledRouteLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none !important;
    justify-content: inherit !important;
    align-items: inherit !important;
    color: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
  `
);

const RouterMuiLink = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

RouterMuiLink.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

export {
  Div,
  StyledRouteLink,
  NavBarTopLink,
  FileChip,
  Input,
  StyledMenuItem,
  StyledAccordion,
  AntSwitch,
  AccordionWrapper,
  AutocompleteWithChip,
  NeutralButton,
  RequestStatus,
  FileCounter,
  HtmlTooltip,
  RouterMuiLink,
  NSUpdateButton,
  NSDeleteButton,
  StyledMenu,
  StyledBadge,
};
