import React, { useState, useContext, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import { LogWrapper, LogChip } from './Style';
import { RequestLogContext as contextLog } from '../../../context/request-log.context';
import Loading from '../../common/others/Loading';
import AppConfirmationDialog from '../../common/others/AppConfirmationDialog';

const LogEdit = (props) => {
  const { log } = props;
  const context = useContext(contextLog);
  const {
    logHeader,
    setLogInEditMode,
    performingCrud,
    updateRequestLog,
    deleteRequestLog,
  } = context;

  const [expanded, setExpanded] = useState(false);
  const [localLog, setLocalLog] = useState(log);
  const [confirmationHandler, setConfirmationHandler] = useState(false);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(`panel${localLog.logSequence}`);
    return () => {};
  }, []);

  const handleEventButton = (event) => {
    event.preventDefault();
    let btnEvent = event.target.textContent;
    if (btnEvent === 'Cancel') {
      setLogInEditMode(false);
      handlePanelChange(`panel${localLog.logSequence}`);
    } else if (btnEvent === 'Update') {
      updateRequestLog(localLog);
    } else if (btnEvent === 'Delete') {
      deleteRequestLog(localLog);
    }
  };

  const handleConfirmation = (event, value) => {
    if (value) handleEventButton(event);
    setConfirmationHandler(false);
  };

  return (
    <LogWrapper>
      <Accordion
        expanded={expanded === `panel${localLog.logSequence}`}
        onChange={handlePanelChange(`panel${localLog.logSequence}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${localLog.logSequence}bh-content`}
          id={`panel${localLog.logSequence}bh-header`}
        >
          <div className={'summaryWrapper expanded'}>
            <div className={'labelAndTimeAgo'}>
              <LogChip
                className={log.logType}
                label={localLog.logType}
                size="small"
              />
              <div>
                <p>
                  {moment(localLog.logDateTime).format(
                    'MM/DD/YYYY h:mm:ss A (ddd)'
                  )}
                </p>
              </div>
            </div>
            <div className="heading">
              <p></p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {localLog.logType === 'Note' ? (
            <TextField
              autoComplete="-off-"
              label="Log Description"
              multiline
              rows={3}
              value={localLog.logText}
              onChange={(e) => {
                setLocalLog({ ...localLog, logText: e.target.value });
              }}
            />
          ) : (
            <Typography>{localLog.logText}</Typography>
          )}
        </AccordionDetails>
        <AccordionActions className="action-section">
          <div>
            <b>View By:</b>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localLog.viewByCustomer}
                  onChange={(e) =>
                    setLocalLog({
                      ...localLog,
                      viewByCustomer: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Customer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localLog.viewByAgent}
                  onChange={(e) =>
                    setLocalLog({
                      ...localLog,
                      viewByAgent: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Agent"
            />
          </div>
          <React.Fragment>
            <Button color="default" variant="text" onClick={handleEventButton}>
              Cancel
            </Button>
            {localLog.canDelete && (
              <Button
                color="error"
                variant="contained"
                disabled={performingCrud.delete}
                onClick={(e) =>
                  setConfirmationHandler({
                    event: e,
                    title: 'Confirm Deletion',
                    msg: 'Do you really want to delete this log?',
                  })
                }
              >
                {performingCrud.delete ? (
                  <>
                    Deleting... <Loading forButton lpad />
                  </>
                ) : (
                  'Delete'
                )}
              </Button>
            )}
            {localLog.canEdit && (
              <Button
                variant="contained"
                disabled={performingCrud.update || !localLog.logText}
                onClick={handleEventButton}
              >
                {performingCrud.update ? (
                  <>
                    Saving... <Loading forButton lpad />
                  </>
                ) : (
                  'Update'
                )}
              </Button>
            )}
          </React.Fragment>
        </AccordionActions>
      </Accordion>
      <AppConfirmationDialog
        id="appConfirmationDialog"
        open={confirmationHandler !== false ? true : false}
        title={'Confirmation'}
        content={confirmationHandler.msg}
        buttonTrueText="Delete"
        color="error"
        onClose={() => {}}
        confirmCallback={(val) =>
          handleConfirmation(confirmationHandler.event, val)
        }
      />
    </LogWrapper>
  );
};

LogEdit.propTypes = {};

export default LogEdit;
