import { useRef, useEffect } from 'react';
import { store } from '../../store';

function generateFavicon(iconUrl) {
  const color = 'transparent';
  // const color = 'white';

  // set up a little favicon-sized canvas and fill it with the chosen color
  const canvas = document.createElement('canvas');
  canvas.setAttribute('id', 'faviconCanvas');
  canvas.width = 32;
  canvas.height = 32;
  const context = canvas.getContext('2d');
  context.fillStyle = color;
  context.fillRect(0, 0, 32, 32);

  const draw = (context) => {
    const img = new Image(48, 48);
    img.src = iconUrl;
    img.onload = () => {
      context.drawImage(img, 0, 0);
    };
  };

  draw(context);

  setTimeout(() => {
    // get a base64 image URL from the canvas content
    const imageUrl = canvas.toDataURL();

    // remove existing favicon links
    document.querySelectorAll("[rel~='icon']").forEach((el) => el.remove());

    // create a new one
    const link = document.createElement('link');
    link.setAttribute('rel', 'icon');
    link.setAttribute('id', 'favicon');
    document.querySelector('head').appendChild(link);

    link.type = 'image/x-icon';
    // point the favicon link at the generated URL
    link.href = imageUrl;
  }, 300);
}

function useDocumentTitle(title, prevailOnUnmount = false) {
  const { portalName } = store.getState().reducer.global;
  const defaultTitle = useRef(portalName);
  generateFavicon(require('../../assets/images/favicons/favicon.png'));

  useEffect(() => {
    document.title = portalName ? title + ' | ' + portalName : title;

    // switch (title) {
    //   case 'Dashboard':
    //     generateFavicon(require('../../assets/images/favicons/dashboard.png'));
    //     break;
    //   case 'Manage Request':
    //     generateFavicon(
    //       require('../../assets/images/favicons/manage-request.png')
    //     );
    //     break;
    //   default:
    //     // favicon.href = defaultFavicon;
    //     generateFavicon(require('../../assets/images/favicons/favicon.png'));
    //     break;
    // }
  }, [title, portalName]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
}

export default useDocumentTitle;
