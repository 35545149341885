import React, { useState, useRef, useEffect, createContext } from 'react';
import { UserAdminService as service } from '../services';
import { useDispatch } from 'react-redux';
import { dispatchAlert } from '../helpers';

export const UserAdminContext = createContext();

const initialUser = {
  userID: parseInt(0),
  initials: '',
  order: parseInt(0),
  firstName: '',
  lastName: '',
  userName: '',
  password: '',
  email: '',
  telephone: '',
  lastLoginDate: undefined,
  authorized: false,
  serviceOwner: true,
  staff: false,
  admin: false,
  authorizedImg: 'UnChecked',
  serviceOwnerImg: 'Checked',
  staffImg: 'UnChecked',
  adminImg: 'UnChecked',
};

const UserAdminProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [userAdminMembers, setUserAdminMembers] = useState([]);
  const [currUser, setCurrUser] = useState(initialUser);
  const [pageMode, setPageMode] = useState('view');
  const [isNewUser, setIsNewUser] = useState(false);
  const [controlSetting, setControlSetting] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    getUserAdminMembers();
    return () => {};
  }, []);

  const getUserAdminMembers = async () => {
    dispatch({ type: 'CLEAR_ALERTS' });
    setLoading(true);
    return await service.getUserAdminMembers().then(
      (res) => {
        setUserAdminMembers(res);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        dispatchAlert('[Replace]' + error, true);
      }
    );
  };

  const deleteUserAdminMember = async (userID) => {
    dispatch({ type: 'CLEAR_ALERTS' });
    return await service.deleteUserAdminMember(userID).then(
      (res) => {
        getUserAdminMembers();
        dispatchAlert(
          `[Replace]Successfully removed ${currUser.firstName} ${currUser.lastName}`
        );
      },

      (error) => {
        setLoading(false);
        dispatchAlert('[Replace]' + error, true);
      }
    );
  };

  return (
    <UserAdminContext.Provider
      value={{
        loading,
        controlSetting,
        userAdminMembers,
        getUserAdminMembers,
        isNewUser,
        setIsNewUser,
        currUser,
        setCurrUser,
        pageMode,
        setPageMode,
        initialUser,
        deleteUserAdminMember,
      }}
    >
      {props.children}
    </UserAdminContext.Provider>
  );
};

export default UserAdminProvider;
