import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import _isEqual from 'lodash/isEqual';

import { signingRequestService as service } from '../../../services';
import { MemoizedTextField } from '../../common/Memoized/TextField';

const useStyles = makeStyles((theme) => ({
  root: ({ isWizard }) => ({
    '&.customerDDL': {
      display: 'flex',
      minWidth: 300,
      [theme.breakpoints.down('1100')]: {
        minWidth: '210px',
      },

      '& .MuiOutlinedInput-marginDense': {
        paddingTop: isWizard ? '' : '1.8px',
        paddingBottom: isWizard ? '' : '1.8px',
        top: isWizard ? '' : '1.5px',
      },
    },
  }),
}));

const CustomerDDL = ({ isWizard, disabled, requestID, value, onChange, useAllCustomerAsDefault }) => {
  // console.log('CustomerDDL');

  const classes = useStyles(isWizard);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      await service.getCustomersForDDL(requestID || 0).then(
        (res) => {
          if (active) {
            res.map((x) => {
              if (x.userID == 0 && useAllCustomerAsDefault) {
                x.customer = 'All Customers';
              }
            });
            setOptions(res);
          }
        },
        (error) => console.log(error)
      );
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id="select-customer"
      className={`${classes.root} customerDDL`}
      disabled={disabled}
      size="small"
      margin="dense"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(e, v) => onChange(v)}
      isOptionEqualToValue={(option, value) => option.userID === value.userID}
      getOptionLabel={(option) => option.customer || ''}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <MemoizedTextField
          {...params}
          label="Customer"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default memo(CustomerDDL);
