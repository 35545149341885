import { useState, useEffect, useRef } from 'react';
import { F } from '../functions';

const useSyncTasks = ({ syncInterval = 5000, notifier = F, callback = F, maxCallbackRerun = 5 }) => {
  const [tasks, setTasks] = useState([]);
  const taskStatusesRef = useRef({});

  useEffect(() => {
    tasks.forEach((task) => {
      if (!taskStatusesRef.current[task.id]?.isLoading) {
        refreshTaskInterval(task);
      }
    });

    return () => {
      Object.values(taskStatusesRef.current).forEach(({ intervalId }) => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      });
    };
  }, [syncInterval]);

  const getCurrentTask = (taskId) => {
    if (!taskStatusesRef.current[taskId]) {
      taskStatusesRef.current[taskId] = {};
    }
    return taskStatusesRef.current[taskId];
  };

  const removeTaskStatuses = (taskId) => {
    delete taskStatusesRef?.current[taskId];
  };

  const startTaskInterval = (task, customCallback) => {
    const intervalId = setInterval(() => {
      syncTaskWithServer(task, customCallback);
    }, syncInterval);

    const currentTask = getCurrentTask(task.id);
    currentTask.runCount = 0;
    currentTask.intervalId = intervalId;
  };

  const syncTaskWithServer = async (task, customCallback) => {
    const currentTask = getCurrentTask(task.id);
    if (currentTask.isLoading) return;

    currentTask.isLoading = true;

    const runCount = currentTask?.runCount;
    try {
      const response = await (customCallback || callback)(task);
      currentTask.isLoading = false;
      if (response.success) {
        clearTaskInterval(task.id);
      } else if (runCount >= maxCallbackRerun) {
        handleError(task.id, new Error('UNABLE_TO_SYNC'));
      } else {
        currentTask.runCount += 1;
      }
    } catch (error) {
      handleError(task.id, new Error('UNABLE_TO_SYNC'));
      currentTask.isLoading = false;
    }
  };

  const clearTaskInterval = (taskId) => {
    const { intervalId } = taskStatusesRef.current[taskId];
    if (intervalId) {
      clearInterval(intervalId);
      removeTaskStatuses(taskId);
      setTasks(tasks.filter((task) => task.id !== taskId));
    }
  };

  const handleError = (taskId, error) => {
    console.error(error);
    clearTaskInterval(taskId);
    notifier(error);
  };

  const addTask = (task, customCallback) => {
    startTaskInterval(task, customCallback);
    setTasks((prevTasks) => [...prevTasks, task]);
  };

  const refreshTaskInterval = (taskId) => {
    const task = tasks.find((task) => task.id === taskId);
    if (task && !taskStatusesRef.current[taskId]?.isResolved) {
      clearTaskInterval(taskId);
      startTaskInterval(task);
    }
  };

  return { addTask, refreshTaskInterval, taskStatusesRef, runningTasksCount: tasks.length };
};

// Fake server sync function
export const fakeServerSync = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: Math.random() > 0.2 }); // 80% chance to resolve successfully
    }, 10000);
  });

export default useSyncTasks;
