import React, { memo, useCallback, useEffect, useLayoutEffect, useState, useTransition } from 'react';
import { connect } from 'react-redux';
import usePortalTabs from '../helpers/hooks/usePortalTabs';
import { useCloseModal, useModal, useModalAlert } from '../helpers/hooks/useModals';
import AgentAgreementModal, { useAgreementStore } from '../components/agent-agreement/AgentAgreementModal';
import DeclineAgreementModal from '../components/agent-agreement/DeclineAgreementModal';
import ConfirmAgreementModal from '../components/agent-agreement/ConfirmAgreementModal';
import useFetch from '../helpers/hooks/useFetch';
import { useHistory } from 'react-router-dom';
import { useModalStore } from '../context/modal.store';
import { useLoadingStore } from '../context/loading.store';

export const MESSAGES = {
  AGREE: 'You have successfully agreed to the service agreement',
  DECLINE: 'You have declined the service agreement',
  ERROR: 'An error occurred while processing your request. Please try again later.',
};

export const useAgreementModals = (store) => {
  const { global } = store;
  const loading = useLoadingStore((s) => s.loading);

  const { showModal: showAgreementModal } = useModal({
    template: <AgentAgreementModal />,
    PaperProps: {
      elevation: 1,
      sx: {
        // Additional custom styles can go here
        height: '100%',
      },
    },
    useFullScreen: true,
    fullWidth: true,
    maxWidth: 'lg',
    companyName: global?.portalName,
    disableEscapeKeyDown: true,
    // preventCloseOnBackdropClick: true,
  });

  const { showModal: showConfirmModal } = useModal({
    template: <ConfirmAgreementModal />,
    PaperProps: {
      elevation: 2,
    },
    loading,
    preventCloseOnBackdropClick: true,
  });

  const { showModal: showDeclineModal } = useModal({
    template: <DeclineAgreementModal />,
    PaperProps: {
      elevation: 2,
    },
    loading,
    preventCloseOnBackdropClick: true,
  });

  return {
    showAgreementModal,
    showConfirmModal,
    showDeclineModal,
  };
};

export const useAgreementServices = (props) => {
  const {
    loading: ldSave,
    error: signAgreementError,
    fetchData: UpdateSignAgreementSelection,
  } = useFetch('/users/signServiceAgreement', true);
  const {
    data: agreementId,
    loading: ldGet,
    error: getAgreementError,
    fetchData: GetActiveAgreementId,
  } = useFetch('/portal/getLatestAgreement', true);

  const setLoadingState = useLoadingStore((state) => state.setLoadingState);

  useEffect(() => {
    setLoadingState(ldSave || ldGet);
  }, [ldSave, ldGet]);

  return {
    UpdateSignAgreementSelection,
    GetActiveAgreementId,
    getAgreementError,
    signAgreementError,
    agreementId,
  };
};

export const AgentSigningAgreement = ({ store, dispatch, ...props }) => {
  const [isPending, startTransition] = useTransition();
  const disableButtonActions = useAgreementStore((state) => {
    return state.disableButtonActions;
  });

  const resetButtonActions = useAgreementStore((state) => {
    return state.reset;
  });

  const setAgreementDocId = useAgreementStore((state) => {
    return state.setAgreementDocId;
  });

  const [open, setOpen] = useState(false);
  const history = useHistory();

  usePortalTabs({ store, dispatch });

  const {
    UpdateSignAgreementSelection,
    GetActiveAgreementId,
    loading,
    getAgreementError,
    signAgreementError,
    agreementId,
  } = useAgreementServices(props);

  const { showAgreementModal, showConfirmModal, showDeclineModal } = useAgreementModals(store);
  const { closeLatestModal, resetModals } = useCloseModal();
  const { pushAlert } = useModalAlert();

  const handleClose = () => {
    closeLatestModal();
  };

  const notify = (type, message) => {
    pushAlert({
      open: true,
      color: type,
      message,
    });
  };

  const redirectToHome = () => {
    setTimeout(() => {
      dispatch({
        type: 'SET_SIGNED_AGREEMENT',
        payload: {
          needServiceAgreementSigned: false,
        },
      });
      history.replace('/dashboard');
      resetModals();
      resetButtonActions();
    }, 3000);
  };

  const handleDecline = useCallback(
    (choice) => {
      UpdateSignAgreementSelection(
        'PUT',
        {},
        {
          id: agreementId,
          status: 2,
          comment: choice,
        }
      )
        .then(() => {
          disableButtonActions();
          notify('info', MESSAGES.DECLINE);
          redirectToHome();
        })
        .catch((error) => {
          notify('error', MESSAGES.ERROR);
        })
        .finally(handleClose);
    },
    [agreementId]
  );

  const handleConfirm = useCallback(() => {
    UpdateSignAgreementSelection(
      'PUT',
      {},
      {
        id: agreementId,
        status: 1,
      }
    )
      .then(() => {
        disableButtonActions();
        notify('success', MESSAGES.AGREE);
        redirectToHome();
      })
      .catch((error) => {
        notify('error', MESSAGES.ERROR);
      })
      .finally(handleClose);
  }, [agreementId]);

  const handleIAgree = useCallback(() => {
    showConfirmModal({
      handleConfirm,
      handleClose,
    });
  }, [agreementId]);

  const handleIDecline = useCallback(() => {
    showDeclineModal({
      handleDecline,
      handleClose,
    });
  }, [agreementId]);

  useLayoutEffect(() => {
    if (!store?.needServiceAgreementSigned) {
      history.push('/dashboard');
    }
    GetActiveAgreementId();
  }, []);

  useLayoutEffect(() => {
    setAgreementDocId(agreementId);

    !loading &&
      agreementId &&
      showAgreementModal({
        handleIAgree,
        handleIDecline,
        handleClose,
      });
  }, [agreementId]);

  return null;
};

const mapStateToProps = (state) => ({
  store: state.reducer,
  dispatch: state.dispatch,
});

export default connect(mapStateToProps)(memo(AgentSigningAgreement));
