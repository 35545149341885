import React from 'react';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import { LogWrapper, LogChip } from './Style';
import { RequestLogContext as contextLog } from '../../../context/request-log.context';
import Loading from '../../common/others/Loading';

const LogNew = ({ log }) => {
  const context = React.useContext(contextLog);
  const {
    setLogInNewMode,
    setLogInEditMode,
    saveRequestLog,
    sortedLogs,
    setSortedLogs,
    performingCrud,
  } = context;

  const [localLog, setLocalLog] = React.useState(log);

  const handleEventButton = (event) => {
    event.preventDefault();
    let btnEvent = event.target.textContent;
    if (btnEvent === 'Cancel') {
      let newLogs = sortedLogs.filter((i) => i.logSequence > 0);
      setSortedLogs(newLogs);
      setLogInNewMode(false);
      setLogInEditMode(false);
    } else if (btnEvent === 'Save') {
      saveRequestLog(localLog);
    }
  };

  return (
    <LogWrapper>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: 'gray' }} disabled={true} />
          }
        >
          <div className={'summaryWrapper expanded'}>
            <div className={'labelAndTimeAgo'}>
              <LogChip
                className={localLog.logType}
                label={localLog.logType}
                size="small"
              />
              <div>
                <p>
                  {moment(localLog.logDateTime).format(
                    'MM/DD/YYYY h:mm:ss A (ddd)'
                  )}
                </p>
              </div>
            </div>
            <div className="heading">
              <p></p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {localLog.logType === 'Note' ? (
            <TextField
              autoComplete="-off-"
              label="Log Description"
              multiline
              rows={3}
              value={localLog.logText}
              onChange={(e) => {
                setLocalLog({ ...localLog, logText: e.target.value });
              }}
            />
          ) : (
            <Typography>{localLog.logText}</Typography>
          )}
        </AccordionDetails>
        <AccordionActions className="action-section">
          <div>
            <b>View By:</b>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localLog.viewByCustomer}
                  onChange={(e) =>
                    setLocalLog({
                      ...localLog,
                      viewByCustomer: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Customer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localLog.viewByAgent}
                  onChange={(e) =>
                    setLocalLog({
                      ...localLog,
                      viewByAgent: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Agent"
            />
          </div>
          <React.Fragment>
            <Button color="default" variant="text" onClick={handleEventButton}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={performingCrud.save || !localLog.logText}
              onClick={handleEventButton}
            >
              {performingCrud.save ? (
                <>
                  Saving... <Loading forButton lpad />
                </>
              ) : (
                'Save'
              )}
            </Button>
          </React.Fragment>
        </AccordionActions>
      </Accordion>
    </LogWrapper>
  );
};

LogNew.propTypes = {};

export default LogNew;
