import React, { useState, useEffect, useRef } from 'react';
import { manageRequestService as service } from '../services/manage-request.service';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { dispatchAlert, getJsonOr } from '../helpers';
import { GetInitialFilterOptions, initialFilterPayload } from '../components/manage-request/RequestList/filter/models';

export const ManageRequestContext = React.createContext();

const pageSizesForList = [
  { value: 0, label: '' },
  { value: 5, label: '5 rows' },
  { value: 10, label: '10 rows' },
  { value: 15, label: '15 rows' },
  { value: 25, label: '25 rows' },
  { value: 50, label: '50 rows' },
];

const requestStatusesDDL = [
  { value: '1', label: 'Open: New' },
  { value: '2', label: 'Open: Pending' },
  { value: '3', label: 'Open: Appt Confirmed' },
  { value: '4-SB', label: 'Billing: Appt Complete. Awaiting Scans' },
  { value: '4', label: 'Billing: Appt Complete. To Invoice' },
  { value: '5', label: 'Billing: Invoiced. Awaiting Pmt' },
  { value: '6', label: 'Closed: Paid' },
  { value: '123', selected: true, label: "- All 'Open' -" },
  { value: '45', label: "- All 'Billing' -" },
  { value: '123456', label: '- All Items (Excl. Dec/Canc) -' },
  { value: '1234569', label: '- All Items (Inc. Dec/Canc) -' },
  { value: '9', label: 'Declined' },
  { value: '10', label: 'Canceled' },
];

const initialFancyFilter = (requestStatus, dateFrom, dateTo, borrowerLastName, filters) => {
  return {
    requestStatus: requestStatus,
    dateFrom: dateFrom,
    dateTo: dateTo,
    borrowerLastName: borrowerLastName,
    filters: filters,
  };
};

const ManageRequestProvider = (props) => {
  const { fromManageRequest = true } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [requestController, setRequestController] = useState();
  const [loading, setLoading] = useState(false);
  const [filterPayLoad, setFilterPayLoad] = useState(initialFilterPayload);
  const [controlSetting, setControlSetting] = useState('');
  const [requestList, setRequestList] = useState(null);
  const [pagination, setPagination] = useState('');
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [breadcrumbState, setBreadcrumbState] = useState();
  const [documentData, setDocumentData] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [calendarViewDefault, setCalendarViewDefault] = useState({});

  const [fancyFilterData, setFancyFilterData] = useState(
    initialFancyFilter(
      filterPayLoad.requestStatus,
      filterPayLoad.dateFrom,
      filterPayLoad.dateTo,
      filterPayLoad.borrowerLastName,
      appliedFilters
    )
  );

  useEffect(() => {
    setLoading(true);
    const fetchApiForSettings = async () => {
      await service.getGeneralRequests({ ...filterPayLoad, getOnlySettings: true }).then(
        (res) => {
          setAppliedFilters(GetInitialFilterOptions(res.controlSetting));
          setControlSetting(res.controlSetting);
          setCalendarViewDefault({
            calendar: getJsonOr(res.controlSetting.calendarViewDefaults) || [],
            openColor: res.controlSetting.calendarColor_OpenCount,
            billingColor: res.controlSetting.calendarColor_BillingCount,
            closedColor: res.controlSetting.calendarColor_ClosedCount,
          });
        },
        (error) => dispatchAlert('[Replace]' + error, true)
      );

      setLoading(false);
    };
    fetchApiForSettings();

    return () => {
      setBreadcrumbState({ ...breadcrumbState, stopDataRefresh: true });
    };
  }, []);

  useEffect(() => {
    setBreadCrumb([...breadCrumb, location.pathname]);
    return () => {
      setBreadCrumb([]);
    };
  }, [location]);

  useEffect(() => {
    setFancyFilterData({
      ...fancyFilterData,
      requestStatus: filterPayLoad.requestStatus,
      dateFrom: filterPayLoad.dateFrom,
      dateTo: filterPayLoad.dateTo,
      borrowerLastName: filterPayLoad.borrowerLastName,
      filters: appliedFilters,
    });
    return () => {};
  }, [appliedFilters]);

  const parseSendResponse = (response, relative = false) => {
    if (response) {
      let custResp = response?.Customer;
      let agentResp = response?.Agent;

      let errors = [];
      let succsessMsgs = [];

      if (custResp?.includes('error') || agentResp?.includes('error')) {
        if (custResp?.includes('error')) errors.push(custResp.replace('[error]', ''));
        if (agentResp?.includes('error')) errors.push(agentResp.replace('[error]', ''));
      }

      if (custResp?.includes('success') || agentResp?.includes('success')) {
        if (custResp?.includes('success')) succsessMsgs.push(custResp.replace('[success]', ''));
        if (agentResp?.includes('success')) succsessMsgs.push(agentResp.replace('[success]', ''));
      }

      if (relative) {
      } else {
        if (errors.length > 0) {
          errors.forEach((error) => {
            dispatch({
              type: 'PUSH_ALERT',
              payload: { open: true, color: 'error', message: error },
            });
          });
        } else {
          succsessMsgs.forEach((msg) => {
            dispatch({
              type: 'PUSH_ALERT',
              payload: { open: true, color: 'success', message: msg },
            });
          });
        }
      }
    }
  };

  const getGeneralRequests = async (_filterPayLoad) => {
    //cancel the previous request
    if (requestController) requestController.abort();
    let newController = new AbortController();
    let signal = newController.signal;
    //set a new AbortController instance to the reducer
    setRequestController(newController);

    setFilterPayLoad(_filterPayLoad);
    if (!_filterPayLoad.getOnlySettings) setLoading(true);
    await service.getGeneralRequests(_filterPayLoad, signal).then(
      (res) => {
        setRequestList(res.requestList);
        setPagination(res.pagination);
        setControlSetting(res.controlSetting);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (typeof error === 'string') {
          if (!error?.toString().includes('aborted')) {
            dispatchAlert('[Replace]' + error, true);
          }
        }
      }
    );
  };

  const currentPaginationPageSize = () => {
    let defaultRowNumber = controlSetting?.defaultRows_Requests || 0;
    return filterPayLoad.pageSize ? filterPayLoad.pageSize : parseInt(defaultRowNumber);
  };

  const handlePageSize = (e) => {
    let val = e.target?.value ? e.target.value : e;
    if (filterPayLoad.pageNumber > Math.ceil(pagination?.totalRecords / val)) {
      getGeneralRequests({
        ...filterPayLoad,
        pageSize: val,
        pageNumber: 1,
      });
    } else {
      getGeneralRequests({
        ...filterPayLoad,
        pageSize: val,
      });
    }
  };

  const handlePageNumber = (e) => {
    let val = 1;
    if (typeof e === 'number') val = e;
    else val = e.target?.value ? e.target.value : e;
    getGeneralRequests({ ...filterPayLoad, pageNumber: val });
  };

  const handleSort = (sorter) => {
    getGeneralRequests({
      ...filterPayLoad,
      sortField: sorter,
    });
  };

  const assignStaffToRequest = (requestID, value) => {
    dispatch({ type: 'CLEAR_ALERTS' });
    service.assignStaffToRequest(requestID, value.staffID).then(
      (res) => {
        getGeneralRequests(filterPayLoad);
        dispatch({
          type: 'PUSH_ALERT',
          payload: {
            open: true,
            color: 'success',
            message: `[Replace]Successfully Changed Staff to ${value.staffName.substr(5)}`,
          },
        });
      },
      (error) => {
        setLoading(false);
        dispatch({
          type: 'PUSH_ALERT',
          payload: {
            open: true,
            color: 'error',
            message: '[Replace]' + error,
          },
        });
      }
    );
  };

  const quickSigningUpdate = async (requestID, data) => {
    let result = false;
    await service.quickSigningUpdate(requestID, data).then(
      (res) => {
        let msgFalse = 'Appointment successfully unconfirmed.';
        let msgTrue = 'Appointment successfully confirmed.';
        dispatchAlert(data.agentAppointmentConfirmed ? msgTrue : msgFalse);
        result = res;
      },
      (error) => {
        dispatchAlert('[Replace]' + error, true);
        result = false;
      }
    );
    return result;
  };

  const totalPages = Math.ceil(pagination?.totalRecords / currentPaginationPageSize()) || 1;

  return (
    <ManageRequestContext.Provider
      value={{
        loading,
        fromManageRequest,
        initialFilterPayload,
        totalPages,
        controlSetting,
        pagination,
        pageSizesForList,
        requestStatusesDDL,
        breadCrumb,
        requestList,
        filterPayLoad,
        documentData,
        appliedFilters,
        fancyFilterData,
        breadcrumbState,
        calendarViewDefault,
        setPagination,
        setCalendarViewDefault,
        setLoading,
        setBreadcrumbState,
        setFancyFilterData,
        setAppliedFilters,
        setDocumentData,
        setBreadCrumb,
        setRequestList,
        setFilterPayLoad,
        currentPaginationPageSize,
        getGeneralRequests,
        handlePageSize,
        handlePageNumber,
        handleSort,
        assignStaffToRequest,
        parseSendResponse,
        quickSigningUpdate,
      }}
    >
      {props.children}
    </ManageRequestContext.Provider>
  );
};

export default ManageRequestProvider;
