import { Button, Card, Grow, Paper, Popper } from '@mui/material';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DropDown } from '../../common/fancy-filter/forms/DropDown';

const StyledRegInfo = styled('div')(
  ({ theme }) => css`
    padding: 10px;

    .actions {
      margin-top: 10px;
      justify-content: start;
      display: flex;

      .cancel {
        min-height: 30px;
        width: 50%;
      }

      .apply {
        min-height: 30px;
        margin-left: 5px;
        width: 100%;
      }
    }
  `
);

const RegInforForm = (props) => {
  const { menuRef, form, onClose, applyFilter, editFilter } = props;

  const [state, setState] = useState({
    id: 'agentRegDocs',
    value: form.value,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    applyFilter(state);
  };

  return (
    <Popper
      sx={{ zIndex: 1 }}
      transition
      open={true}
      anchorEl={menuRef?.current || menuRef}
      // placement={editFilter ? 'bottom' : 'left-start'}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={{ width: '300px' }}>
            <StyledRegInfo>
              <form onSubmit={onSubmit}>
                <DropDown
                  label="Agent Docs"
                  hideActions
                  withoutCard
                  multiple
                  value={form.value?.agentRegDocs}
                  options={form.options}
                  applyFilter={(e) => {
                    setState({
                      ...state,
                      value: { ...state.value, agentRegDocs: e.value },
                    });
                  }}
                />
                <DropDown
                  label="Options"
                  value={form.value?.action || 'Include Expired'}
                  options={form.otherOptions.expiration}
                  hideActions
                  withoutCard
                  disableClearable
                  applyFilter={(e) => {
                    setState({
                      ...state,
                      value: {
                        ...state.value,
                        action: e.value.value,
                        excludeExpired: e.value.value,
                      },
                    });
                  }}
                />
                <div className="actions">
                  <Button
                    className="cancel"
                    color="default"
                    onClick={() => props.onClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="apply"
                    type="submit"
                    onClick={() => props.applyFilter(state)}
                  >
                    Apply Filter
                  </Button>
                </div>
              </form>
            </StyledRegInfo>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default RegInforForm;
