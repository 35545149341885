import React, { useEffect } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Down from './assets/images/down.gif';

const Container = styled('div')(
  ({ theme }) => css`
    padding: 20px;

    & img {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    ${theme.breakpoints.up('sm')} {
      & img {
        width: 380px;
        height: 300px;
      }
    }

    ${theme.breakpoints.up('md')} {
      & img {
        width: 500px;
        height: 400px;
      }
    }
  `
);

function AppError(props) {
  return (
    <Container>
      <img src={Down} alt="system under maintenence..." />
    </Container>
  );
}

export default AppError;
