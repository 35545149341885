import { userService } from '../services';
import { store } from '../store';

export async function authHeader(isMultiPart = false, skipRefresh = false) {
  const { accessToken } = store.getState().reducer;
  let bearerToken = accessToken?.token;

  if (!skipRefresh && bearerToken && userService.tokenTimeRemaining() == 0) {
    await userService.refreshToken().then((res) => {
      bearerToken = res.token;
    });
  }

  if (bearerToken) {
    if (isMultiPart) {
      return {
        // 'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${bearerToken}`,
      };
    }
    return {
      'Content-type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    };
  } else {
    return { 'Content-type': 'application/json' };
  }
}
