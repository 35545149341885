import React, { Fragment, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { store } from '../../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root:nth-child(n+1)': {
      padding: '20px',
      opacity: 0.6,
    },
  },

  BorrowerInfo: {
    '& .form-inputs': {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gridGap: '20px',
      fontSize: '15px',

      '& .main-info': {
        zIndex: '0',
        fontSize: '15px',
        gridGap: '5px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: `"borrowerfirst borrowerlast"
        "coborrowerfirst coborrowerlast"
        "addr1 addr1"
        "addr2 addr2"
        "city city"`,
      },

      '& .addr1': {
        gridArea: 'addr1',
      },
      '& .addr2': {
        gridArea: 'addr2',
      },
      '& .city': {
        gridArea: 'city',
      },
    },

    '& label': {
      fontSize: 'inherit',
    },

    [theme.breakpoints.down('sm')]: {
      '& .form-inputs': {
        display: 'block',
      },
    },
  },

  SigningAltLocation: {
    '& .form-inputs': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '15px',
      fontSize: '15px',
    },

    '& label': {
      fontSize: 'inherit',
    },

    [theme.breakpoints.down('sm')]: {
      '& .form-inputs': {
        display: 'block',
      },
    },
  },

  SigningInfo: {
    '& .loan-types': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    '& .left-right-wrapper': {
      display: 'grid',
      gridGap: '5px',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `"left-section right-section"`,
      marginTop: '15px',

      '& .left-section': {
        gridArea: 'left-section',
        border: '1px solid #e6e0e0',
        borderRadius: '10px',
        padding: '10px',

        '& .MuiFormControl-root': {
          marginBottom: '13px',
        },
      },

      '& .right-section': {
        gridArea: 'right-section',
        border: '1px solid #e6e0e0',
        borderRadius: '10px',
        padding: '10px',

        '& .MuiFormControl-root': {
          marginBottom: '13px',
        },
      },
    },

    '& .shipperName': {
      display: 'flex',
      gridGap: '15px',
      width: '100%',

      '& div:nth-child(1)': {
        minWidth: '43%',
      },
      '& div:nth-child(2)': {
        minWidth: 'calc(58% - 19px)',
        fontSize: '15px',
      },
    },
  },

  InvoiceConctactInfo: {
    '& .wrapper-section': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '20px',
    },
  },
}));

const SigningRequestFormSkeleton = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { currentUser } = store.getState().reducer;
  const [hidePageRoleRelated, setHidePageRoleRelated] = useState(true);

  useEffect(() => {
    const { roles: userRoles = [] } = currentUser.detail;
    let roleFound = userRoles.indexOf('Administrators') !== -1;
    if (roleFound) {
      setHidePageRoleRelated(true);
    } else {
      setHidePageRoleRelated(false);
    }
    return () => {
      //console.log('Does it need cleanup?');
    };
  }, []);

  return (
    <div className={classes.root}>
      {hidePageRoleRelated && (
        <Fragment>
          <Alert severity="warning">
            This module is intended to be used by your customers. Please use the{' '}
            <strong>Manage Requests</strong> area to add a new request.
          </Alert>
          <br />
        </Fragment>
      )}
      <Card className={classes.BorrowerInfo}>
        <div className="comp-title">Borrower Information</div>
        <div className="form-inputs">
          <div className="main-info">
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__borrowerFirstName')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__borrowerLastName')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__coBorrowerFirstName')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__coBorrowerLastName')}`}
            />
            <TextField
              className="addr1"
              disabled={true}
              label={`${t('signingRequest.ns__signingAddress1')}`}
            />
            <TextField
              className="addr2"
              disabled={true}
              label={`${t('signingRequest.ns__signingAddress2')}`}
            />
            <TextField
              className="city"
              disabled={true}
              label={`${t('signingRequest.ns__signingCity')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__signingState')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__signingZipCode')}`}
            />
          </div>
          <div className="phones-other">
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__dayPhone')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__eveningPhone')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__otherPhone')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__otherContactInfo')}`}
              multiline
              rows={8}
            />
          </div>
        </div>
      </Card>
      <br />
      <Card className={classes.SigningAltLocation}>
        <div className="comp-title">
          Signing Location (If different than above)
        </div>
        <p className="comp-notes">
          Use the below section to specify the location of the signing if
          different than the above address.
        </p>
        <div className="form-inputs">
          <TextField disabled={true} label="Company Name (if applicable)" />
          <TextField disabled={true} label="Signing Address Line 1" />
          <TextField disabled={true} label="Signing Address Line 2" />
          <TextField disabled={true} label="City" />
          <TextField disabled={true} label="State" />
          <TextField disabled={true} label="Zip Code" />
        </div>
        <p style={{ textAlign: 'center' }} className="comp-notes">
          Note: If any of the address is entered in this section, the zip code
          will be required to be input as well.
        </p>
      </Card>
      <br />
      <Card className={classes.SigningInfo}>
        <div className="comp-title">Signing Information</div>
        <div className="left-right-wrapper">
          <div className="left-section">
            <TextField disabled={true} label="Quantity of Loans To Sign" />
            <TextField
              label="Signing Date"
              placeholder="MM/dd/yyyy"
              disabled={true}
            />
            <TextField label="Signing Time" placeholder="" disabled={true} />
            <TextField disabled={true} label="Scheduling Notes" />

            <TextField disabled={true} label="Loan/Escrow Number 1" />
            <TextField disabled={true} label="Loan/Escrow Number 2" />
            <TextField
              disabled={true}
              label="Language Preference (Other than English)"
            />
            <div className="comp-sub-title">Loan Documents Information</div>
            <TextField disabled={true} label="Will Send Docs Via" />
            <p className="comp-input-notes">Package Tracking (if applicable)</p>
            <div className="shipperName">
              <TextField disabled={true} label="Shipping Company" />
            </div>
            <TextField disabled={true} label="Tracking Number" />
            <div className="comp-sub-title">
              Loan/Escrow Company Information
            </div>
            <TextField disabled={true} label="Loan/Escrow Company" />
            <TextField disabled={true} label="Officers On File" />
            <TextField disabled={true} label="Officer Name" />
            <TextField disabled={true} label="Officer Phone" />
            <TextField disabled={true} label="Officer Email" />
            <TextField disabled={true} label="Contact Name" />
            <TextField disabled={true} label="Contact Phone" />
            <TextField disabled={true} label="Contact Cell" />
            <TextField disabled={true} label="Contact Fax" />
            <TextField disabled={true} label="Contact Email" />
          </div>
          <div className="right-section">
            <div className="comp-sub-title">Return Shipping Information</div>
            <div className="shipperName">
              <TextField disabled={true} label="Shipping Company" />
            </div>
            <TextField disabled={true} label="Shipping Acct#" />
            <div className="comp-sub-title">Return Shipping Instructions</div>
            <TextField
              disabled={true}
              label="500 character limit"
              multiline
              rows={10}
            />
            <div className="comp-sub-title">Signing Instructions</div>
            <TextField
              disabled={true}
              id="otherComments"
              label="2000 character limit"
              multiline
              rows={20}
            />
          </div>
        </div>
      </Card>
      <br />

      <Card className={classes.InvoiceConctactInfo}>
        <div className="comp-title">Invoice Contact Information</div>
        <p className="comp-notes">
          Use the below section to specify where the invoice is to be sent for
          this signing (if different than above).
        </p>
        <div className="wrapper-section">
          <div className="left-section">
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__altInvoiceCompany')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__altInvoiceAddress')}`}
              multiline
              rows={6}
            />
          </div>
          <div className="right-section">
            <p className="comp-sub-title">Emails to Send Invoice To</p>
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__invoiceEmail_1')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__invoiceEmail_2')}`}
            />
            <TextField
              disabled={true}
              label={`${t('signingRequest.ns__invoiceEmail_3')}`}
            />
          </div>
        </div>
      </Card>
      <br />
      {/* <CompletionInfo /> */}
      {/* <BillingRequest /> */}
    </div>
  );
};

export default SigningRequestFormSkeleton;
