import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteModuleHandler } from './RouteModuleHandler';
import AppReactDemos from './views/AppReactDemos';
import AvailabilityRequest from './views/FromEmail/AvailabilityRequest';

import Dashboard from './views/Dashboard';
import UserProfile from './views/UserProfile';
import { connect } from 'react-redux';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
import AgentSigningAgreement from './views/AgentSigningAgreement';

const AppRoutes = ({ store: store, ...props }) => {
  const { accessToken, currentUser } = store;

  const commonRoutes = [
    <Route key="terms" path="/terms" render={(route) => <Terms {...route} />} />,
    <Route key="privacy" path="/privacy" render={(route) => <Privacy {...route} />} />,
  ];

  return accessToken.token ? (
    <Switch>
      {commonRoutes.map((route) => route)}
      <Route path="/app-react-demo" render={(route) => <AppReactDemos {...route} />} />
      <Route path="/availability-request" render={(route) => <AvailabilityRequest {...route} />} />
      <Route
        path="/dashboard"
        render={(route) => (
          <Dashboard>
            <RouteModuleHandler withView routeName="dashboard" />
          </Dashboard>
        )}
      />
      <Route path="/agent-payment-mgt" render={(route) => <RouteModuleHandler routeName="agent-payment-mgt" />} />
      <Route exact path="/signing-request" render={(route) => <RouteModuleHandler routeName="signing-request" />} />
      <Route path="/signing-request/(copy-from)?/:copyFrom?">
        <Route
          path="/signing-request/copy/:copyFrom"
          render={(route) => <RouteModuleHandler routeName="signing-request" />}
        />
      </Route>
      <Route path="/manage-request" render={(route) => <RouteModuleHandler routeName="manage-request" />} />
      <Route path="/business-settings" render={(route) => <RouteModuleHandler routeName="business-settings" />} />
      <Route path="/reports" render={(route) => <RouteModuleHandler routeName="reports" />} />
      <Route path="/tabs" render={(route) => <RouteModuleHandler routeName="tabs" />} />
      <Route path="/user-admin" render={(route) => <RouteModuleHandler routeName="user-admin" />} />
      <Route path="/contact-manager" render={(route) => <RouteModuleHandler routeName="contact-manager" />} />
      <Route path="/profile/:profileType/:profileId" exact render={(route) => <UserProfile {...route} />} />
      <Route path="/notary-desk" render={(route) => <RouteModuleHandler routeName="notary-desk" />} />
      <Route path="/tech-tools" render={(route) => <RouteModuleHandler routeName="tech-tools" />} />
      <Route exact path="/tab/:tabID" render={(route) => <RouteModuleHandler />} />
      <Route path="/agent-agreement" render={(route) => <AgentSigningAgreement {...route} />} />
      <Route render={(route) => <RouteModuleHandler view={Dashboard} routeName="dashboard" />} />
    </Switch>
  ) : (
    !currentUser.detail && (
      <Switch>
        {commonRoutes.map((route) => route)}
        <Route path="/app-react-demo" render={(route) => <AppReactDemos {...route} />} />
        <Route path="/availability-request" render={(route) => <AvailabilityRequest {...route} />} />
        <Route path="/agent-registration" render={(route) => <RouteModuleHandler routeName="agent-registration" />} />
        <Route path="/customer-registration" render={(route) => <RouteModuleHandler routeName="registration" />} />
        <Route
          path="/forgot_agent_password"
          render={(route) => <RouteModuleHandler routeName="forgot_agent_password" />}
        />
        <Route
          path="/forgot_customer_password"
          render={(route) => <RouteModuleHandler routeName="forgot_customer_password" />}
        />
        <Route render={(route) => <RouteModuleHandler routeName="login" />} />
      </Switch>
    )
  );
};

const mapStateToProps = (state) => ({ store: state.reducer });
export default connect(mapStateToProps)(AppRoutes);
