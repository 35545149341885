import React, { Fragment, useState, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import {
  Badge,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import Loading from '../components/common/others/Loading';
import RequestFilter from '../components/manage-request/RequestList/filter';
import SigningRequestListCard from '../components/manage-request/RequestList/index';
import SigningRequestList from '../components/manage-request/RequestList/SigningRequestList';
import RequestDetail from '../components/manage-request/RequestDetail';
import RequestLog from '../components/manage-request/RequestLog';
import SigningRequestProvider, { SigningRequestContext } from '../context/signing-request.context';
import RequestWizard from '../components/manage-request/signing-request/RequestWizard';
import RequestLogProvider from '../context/request-log.context';
import PaginationCompact from '../components/common/others/PaginationCompact';
import ManageRequestProvider, { ManageRequestContext } from '../context/manage-request.context';
import ContactManagerProvider, { ContactManagerContext } from '../context/contact-manager.context';
import DataList from '../components/contact-manager/list/DataList';
import AssignNotary from '../components/manage-request/AssignNotary/AssignNotary';
import ListOptions from '../components/contact-manager/list/ListOptions';
import { css } from 'styled-components';
import { CustomerInvoice } from '../components/manage-request/Invoice/CustomerInvoice';
import { AgentInvoicePayment } from '../components/manage-request/Invoice/AgentInvoicePayment';
import { CustomerInvoicePayment } from '../components/manage-request/Invoice/CustomerInvoicePayment';
import UserProfile from './UserProfile';
import Breadcrumb from '../components/manage-request/Breadcrumb';
import { store } from '../store';
import Filters from '../components/contact-manager/filter/Filters';
import PaginationMobile from '../components/common/others/PaginationMobile';

const ManageRequestStyles = styled('div')(
  ({ theme, $lockViewOnScroll }) => css`
    .display-mobile {
      ${theme.breakpoints.down('991')} {
        display: none;
      }
    }

    .options {
      height: inherit;
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .button-new-request {
      ${theme.breakpoints.down('991')} {
        width: 100%;
      }
    }

    .records-count {
      height: inherit;
      line-height: 36px;
      font-size: 0.8rem;
      font-weight: 500;
    }

    @media screen and (max-width: 850px) {
      .records-count {
        display: none;
      }
    }

    .listControl {
      margin: 10px 0 5px;
      position: ${$lockViewOnScroll ? 'sticky' : 'initial'};
      top: ${$lockViewOnScroll ? '60px' : 'initial'};
      z-index: 1;
      background-color: ${theme.palette.nsAppBG.fixedBG};
      box-shadow:
        0px -12px 0px 17px ${theme.palette.nsAppBG.fixedBG},
        0px 1px 1px 0px ${theme.palette.nsAppBG.fixedBG},
        0px 1px 3px 2px ${theme.palette.nsAppBG.fixedBG};

      & .row-options {
        height: inherit;

        ${theme.breakpoints.up('991')} {
          display: flex;
        }
      }

      & .row-options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      ${theme.breakpoints.down('lg')} {
        max-width: 100% !important;
        padding-left: 3px !important;
        padding-right: 0px !important;
        left: 0px;
      }
    }
  `
);

const PaginationDiv = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 850px) {
      &.non-mobile {
        display: none;
      }
    }

    @media screen and (min-width: 851px) {
      &.mobile {
        display: none;
      }
    }
  `
);

const sortOptions = [
  { id: 1, label: 'Request', value: 'Request' },
  { id: 2, label: 'Status', value: 'Status' },
  { id: 3, label: 'Signers', value: 'Borrower' },
  { id: 4, label: 'When', value: 'Date' },
  { id: 5, label: 'Location', value: 'Location' },
  { id: 6, label: 'Customers', value: 'ContactInfo' },
  { id: 7, label: 'Agent', value: 'Agent' },
];

const ManageRequest = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const minWidth500 = useMediaQuery('(min-width:500px)');
  const userRoles = store.getState().reducer.currentUser.userRoles;
  const isAdmin = userRoles.includes('ServiceOwner') || userRoles.includes('SuperUser');
  const viewport = store.getState().reducer.appViewport;
  const { mgrReqStorage } = useSelector((state) => state.reducer.portalStorage);

  const [openWizard, setOpenWizard] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [view, setView] = useState(0);
  const [activeSortMenu, setActiveSortMenu] = useState(false);
  const [listSortAnchor, setListSortAnchor] = useState(null);

  const [listAppearanceAnchorEl, setListAppearanceAnchorEl] = useState(null);
  const listAppearanceOpen = Boolean(listAppearanceAnchorEl);
  const handleListAppearanceClick = (event) => setListAppearanceAnchorEl(event.currentTarget);
  const handleListAppearanceMenuItemClick = (val) => {
    if (val == 'alternate-color') {
      dispatch({ type: 'SET_PORTAL_STORAGE', payload: { altCardColor: !mgrReqStorage.altCardColor } });
    } else {
      setView(val == 'on' ? 2 : 1);
      dispatch({ type: 'SET_PORTAL_STORAGE', payload: { compactViewOn: val } });
    }
    setListAppearanceAnchorEl(null);
  };

  useEffect(() => {
    if (mgrReqStorage.compactViewOn == 'on') setView(2);
    else setView(1);

    return () => {};
  }, [view]);

  const handleClickOpenWizard = () => {
    setOpenWizard(true);
  };

  const handleWizardClose = (value) => {
    if (value === 'cancel') {
      setSelectedCustomer(null);
      setOpenWizard(false);
      history.push('/manage-request');
    } else {
      if (value.userID !== 0) {
        setSelectedCustomer(value);
        setOpenWizard(false);
        history.push('/manage-request/new');
      }
    }
  };

  const canBeOpen = open && Boolean(listSortAnchor);
  const id = canBeOpen ? 'spring-popper' : undefined;

  const handleListSortClose = (event) => {
    setListSortAnchor(event.currentTarget);
    setActiveSortMenu((previousOpen) => !previousOpen);
  };

  const renderSortList = (context) => (
    <div className="sort-list-menu">
      <Button
        sx={{ '.MuiButton-startIcon': { mr: '2px' } }}
        aria-describedby={id}
        variant="text"
        color="text"
        onClick={handleListSortClose}
        startIcon={<SortIcon fontSize="small" color="default" />}
      >
        <Typography variant="body2" sx={{ fontWeight: '500', textTransform: 'capitalize' }}>
          {minWidth500 ? `Sorted by ${context.filterPayLoad.sortField}` : 'Sort'}
        </Typography>
      </Button>
      {activeSortMenu && (
        <Popper sx={{ zIndex: 1 }} id={id} transition open={activeSortMenu ? true : false} anchorEl={listSortAnchor}>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleListSortClose}>
              <Grow {...TransitionProps}>
                <Paper>
                  <MenuList dense>
                    {sortOptions.map((item) => (
                      <MenuItem
                        key={item.id}
                        onClick={(e) => {
                          context.handleSort(item.value);
                          setActiveSortMenu(false);
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </div>
  );

  const renderFixedSectionForList = (context) => (
    <div className={'listControl'}>
      <RequestFilter style={{ margin: '7px 0px 10px' }} setter={setFilterIsActive} />
      <div style={{ marginTop: context.filterPayLoad.onCalendarView ? '10px' : '' }} className={'row-options'}>
        {context.controlSetting.hypAddRequest_Visible && (
          <div>
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              onClick={handleClickOpenWizard}
              className="button-new-request"
            >
              {['xs', 'xsm', 'sm'].includes(viewport) ? 'New Request' : 'Add New Request'}
            </Button>
          </div>
        )}
        <div style={{ justifyContent: isAdmin ? 'center' : 'flex-start' }} className="options">
          {['lg', 'xl'].includes(viewport) && (
            <>
              <div className="display-mobile">
                <Tooltip title="Page Settings">
                  <IconButton
                    onClick={handleListAppearanceClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={listAppearanceOpen ? 'list-appearance' : undefined}
                    aria-haspopup="true"
                    aria-expanded={listAppearanceOpen ? 'true' : undefined}
                  >
                    <Badge
                      color="secondary"
                      variant={'dot'}
                      invisible={!(mgrReqStorage.compactViewOn == 'on' || mgrReqStorage.altCardColor)}
                    >
                      <SettingsOutlinedIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={listAppearanceAnchorEl}
                  id="list-appearance"
                  open={listAppearanceOpen}
                  onClose={() => setListAppearanceAnchorEl(null)}
                  onClick={() => setListAppearanceAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuItem onClick={() => handleListAppearanceMenuItemClick('off')} selected={view == 1}>
                    <ListItemIcon>
                      <AutoAwesomeMosaicOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Card View
                  </MenuItem>
                  <MenuItem onClick={() => handleListAppearanceMenuItemClick('on')} selected={view == 2}>
                    <ListItemIcon>
                      <TableChartOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Compact View
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() =>
                      dispatch({
                        type: 'SET_PORTAL_STORAGE',
                        payload: { lockViewOnScroll: !mgrReqStorage.lockViewOnScroll },
                      })
                    }
                  >
                    <ListItemIcon>
                      {mgrReqStorage.lockViewOnScroll ? (
                        <CheckBoxOutlinedIcon fontSize="small" color="success" />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    Lock View On Scroll
                  </MenuItem>
                  {view == 1 && (
                    <div>
                      <MenuItem onClick={() => handleListAppearanceMenuItemClick('alternate-color')}>
                        <ListItemIcon>
                          {mgrReqStorage.altCardColor ? (
                            <CheckBoxOutlinedIcon fontSize="small" color="success" />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                          )}
                        </ListItemIcon>
                        Alternate Card Color
                      </MenuItem>
                    </div>
                  )}
                </Menu>
              </div>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} className="display-mobile" />
            </>
          )}
          {renderSortList(context)}
          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} className="display-mobile" />
          <div className="records-count">{`${context.pagination?.totalRecords || 0} Records Found`}</div>
        </div>
        {context.requestList?.length > 0 && context.currentPaginationPageSize() && (
          <>
            <PaginationDiv className="non-mobile">
              <PaginationCompact
                pageSizeOptions={context.pageSizesForList}
                pageSize={context.currentPaginationPageSize()}
                pageNumber={context.filterPayLoad.pageNumber}
                totalPages={context.totalPages}
                handlePageSize={context.handlePageSize}
                handlePageNumber={context.handlePageNumber}
              />
            </PaginationDiv>
            <PaginationDiv className="mobile">
              <PaginationMobile
                count={context.totalPages}
                totalRows={context.pagination?.totalRecords}
                pageNumber={context.filterPayLoad.pageNumber}
                rowsPerPage={context.currentPaginationPageSize()}
                onChange={context.handlePageNumber}
              />
            </PaginationDiv>
          </>
        )}
      </div>
    </div>
  );

  const location = useLocation();

  return (
    <Fragment>
      {openWizard && <RequestWizard open={openWizard} onClose={handleWizardClose} />}
      <Switch>
        <ManageRequestProvider>
          <ManageRequestContext.Consumer>
            {(mrContext) => {
              return (
                <>
                  {history.location.pathname !== '/manage-request' && (
                    <Breadcrumb breadCrumbEvent={mrContext.setBreadcrumbState} />
                  )}
                  <Route exact path="/manage-request">
                    <ManageRequestStyles $lockViewOnScroll={mgrReqStorage.lockViewOnScroll}>
                      {mrContext.controlSetting && (
                        <>
                          <Fragment>{renderFixedSectionForList(mrContext)}</Fragment>
                          {view == 2 && ['lg', 'xl'].includes(viewport) ? (
                            <SigningRequestList sort="Request" {...props} />
                          ) : (
                            <SigningRequestListCard sort="Request" />
                          )}
                        </>
                      )}
                    </ManageRequestStyles>
                  </Route>
                  <Route exact path="/manage-request/detail/:detailID">
                    <SigningRequestProvider fromManageRequest={true}>
                      <SigningRequestContext.Consumer>
                        {(context) => {
                          return parseInt(context.requestID) <= 0 && selectedCustomer === null ? (
                            <RequestWizard open={true} onClose={handleWizardClose} />
                          ) : (
                            <RequestDetail />
                          );
                        }}
                      </SigningRequestContext.Consumer>
                    </SigningRequestProvider>
                  </Route>
                  <Route exact path="/manage-request/detail/:detailID/log">
                    <RequestLogProvider history={props.history}>
                      <RequestLog {...props} />
                    </RequestLogProvider>
                  </Route>
                  <Route path="/manage-request/copy/:copyFrom">
                    <SigningRequestProvider history={props.history} fromManageRequest={true}>
                      <RequestDetail {...props} editOn={true} docTitle="Signing Request Copy" />
                    </SigningRequestProvider>
                  </Route>
                  <Route exact path="/manage-request/new/:newRequest?">
                    {location.pathname == '/manage-request/new' ? (
                      <>
                        {!selectedCustomer?.userID ? (
                          <RequestWizard open={true} onClose={handleWizardClose} />
                        ) : (
                          <SigningRequestProvider fromManageRequest={true} custWizard={selectedCustomer}>
                            <RequestDetail editOn={true} docTitle="New Signing Request" />
                          </SigningRequestProvider>
                        )}
                      </>
                    ) : (
                      <SigningRequestProvider fromManageRequest={true}>
                        <SigningRequestContext.Consumer>
                          {(context) =>
                            parseInt(context.requestID) <= 0 && selectedCustomer === null ? (
                              <RequestWizard open={true} onClose={(event) => context.setSelectedCustomer(event)} />
                            ) : context.settings ? (
                              <RequestDetail editOn={true} docTitle="New Signing Request" />
                            ) : (
                              <Loading forPage />
                            )
                          }
                        </SigningRequestContext.Consumer>
                      </SigningRequestProvider>
                    )}
                  </Route>
                  <Route exact path="/manage-request/invoice/customer/:requestID">
                    <CustomerInvoice />
                  </Route>
                  <Route exact path="/manage-request/invoice/customer/payment/:requestID">
                    <CustomerInvoicePayment />
                  </Route>
                  <Route exact path="/manage-request/invoice/agent/payment/:requestID">
                    <AgentInvoicePayment />
                  </Route>
                  <Route
                    path="/manage-request/assign-notary/:requestID"
                    render={({ match: { path } }) => (
                      <ContactManagerProvider fromAssignNotary>
                        <Switch>
                          <Route exact path={path + '/'}>
                            <ContactManagerContext.Consumer>
                              {(context) => {
                                let reloadList = mrContext.breadcrumbState?.stopDataRefresh || false;
                                return (
                                  <Fragment>
                                    <AssignNotary {...props} />
                                    <Filters style={{ margin: '7px 0px 10px' }} context={context} />
                                    <ListOptions {...props} />
                                    <DataList {...props} reload={reloadList} />
                                  </Fragment>
                                );
                              }}
                            </ContactManagerContext.Consumer>
                          </Route>
                          <Route path={`${path}/contact/:profileType/:profileId`}>
                            <UserProfile history={props.history} fromManager />
                          </Route>
                        </Switch>
                      </ContactManagerProvider>
                    )}
                  />
                  <Route
                    path="/manage-request/contact"
                    render={({ match: { path } }) => (
                      <Route path={`${path}/:profileType/:profileId`}>
                        <UserProfile history={props.history} fromManager />
                      </Route>
                    )}
                  />
                </>
              );
            }}
          </ManageRequestContext.Consumer>
        </ManageRequestProvider>
      </Switch>
    </Fragment>
  );
};

export default ManageRequest;
