import { useEffect } from "react";
import { portalService } from "../../services";

const usePortalTabs = ({ store, dispatch }) => {

  const { global, accessToken } = store;

  useEffect(() => {
    if (accessToken.token) {
      portalService.getPortalTabs(global.portalID).then(
        (res) => {
          localStorage.setItem('portalTabs', JSON.stringify(res));
          dispatch({ type: 'SET_PORTAL_TABS', payload: res });
          dispatch({ type: 'CLEAR_ALERTS' });
        },
        (error) => console.log(error)
      );
    }

  }, []);
}

export default usePortalTabs;
