import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import ComponentAlertRelative from './ComponentAlertRelative';
import { DialogTitle, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';

function AppDialog(props) {
  const { open = false, mainTitle = '', complementaryData = '', maxWidth = 'md' } = props;

  const alertWrapperRef = useRef();
  const theme = useTheme();

  const fullScreenDefault = useMediaQuery(theme.breakpoints.between('0', '700'));

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      aria-labelledby="app-dialog"
      maxWidth={fullScreenDefault ? '' : maxWidth}
      open={open}
      fullScreen={fullScreenDefault}
    >
      <DialogTitle id="app-dialog" sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ flex: 1 }} component="div">
          {mainTitle}
        </Typography>
        {complementaryData && (
          <Typography sx={{ flex: 1 }} component="div" style={{ textAlign: 'right' }}>
            {complementaryData.title}
          </Typography>
        )}
      </DialogTitle>
      <div ref={alertWrapperRef} id={`alert-wrapper-dialog`}>
        <ComponentAlertRelative wrapperRef={alertWrapperRef} />
      </div>
      {props.children}
    </Dialog>
  );
}

AppDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default AppDialog;
