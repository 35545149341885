import React from 'react';
import ScrollableIframe from '../scrollable-iframe/ScrollableIframe';

const PdfPreviewer = ({ pdfUrl, error = false }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {pdfUrl ? (
        <ScrollableIframe
          src={pdfUrl}
          title="PDF Viewer"
          style={{ width: '100%', height: '100%', border: 'none' }}
          allowFullScreen
          threshold={100}
        />
      ) : error ? (
        <p>Error loading agreement document</p>
      ) : (
        <p>Loading Contract Agreement...</p>
      )}
    </div>
  );
};

export default PdfPreviewer;
