import React, { Fragment } from 'react';
import Header from './Header';
import { RequestLogContext as contextLog } from '../../../context/request-log.context';
import Loading from '../../common/others/Loading';
import Logs from './Logs';
import Log from './Log';
import LogEdit from './LogEdit';
import LogNew from './LogNew';
import { EmptyList } from './EmptyList';
import useDocumentTitle from '../../../helpers/hooks/useDocumentTitle';

const RequestLog = (props) => {
  useDocumentTitle('Signing Request Logs'); // Set page title

  const context = React.useContext(contextLog);
  const { loadingLogs, totalLogs, sortedLogs, logInEditMode } = context;

  return (
    <Fragment>
      <div className="view-title">Signing Request Log</div>
      <Header />
      <br />
      <Logs
        loadingLogs={loadingLogs}
        totalLogs={totalLogs}
        sortedLogs={sortedLogs}
        onLoading={() => <Loading forList msg="Getting logs..." />}
        onEmptyLogs={() => <EmptyList />}
      >
        {(log) =>
          logInEditMode === log.logSequence ? (
            <LogEdit key={log.logSequence} log={log} />
          ) : log.logSequence === 0 ? (
            <LogNew key={log.logSequence} log={log} />
          ) : (
            <Log key={log.logSequence} log={log} />
          )
        }
      </Logs>
    </Fragment>
  );
};

RequestLog.propTypes = {};

export default RequestLog;
