import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const reportService = {
  getReportSettings: async function (paramObj) {
    const options = { headers: await authHeader() };
    const params = { reportId: paramObj };
    const url = `/reports/getReportSettings`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  getReport: async function (reportParameter) {
    const options = { headers: await authHeader(), body: reportParameter };
    const params = {};
    const url = `/reports/getReport`;
    return Fetch.post(url, params, options);
  },

  getDevExpressReport: async function () {
    const options = { headers: await authHeader(), body: 1 };
    const params = {};
    const url = `/reports/getDexExpressReport`;
    return Fetch.post(url, params, options)
      .then(async (response) => {
        const fileName = response?.headers
          ?.get('content-disposition')
          ?.split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace(/^"(.*)"$/, '$1');
        const blob = await response.blob();
        return { fileName, blob };
      })
      .then((res) => {
        let url = window.URL.createObjectURL(res.blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = res.fileName;
        a.click();
      });
  },
};

export { reportService };
