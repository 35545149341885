import React, { memo, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseStringTime } from '../../../helpers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ClearIcon, DesktopTimePicker, MobileTimePicker, TimeIcon } from '@mui/x-date-pickers';
import moment from 'moment';

const Picker = ({
  style,
  label,
  margin,
  disabled,
  required,
  error,
  helperText,
  shrink = false,
  renderMobile = false,
  time,
  emitTime,
  getShortTime,
}) => {
  // console.log('Date Picker render');

  const [open, setOpen] = useState();
  const [state, setState] = useState(parseStringTime(time));
  const [showError, setShowError] = useState(error);
  const [shrinkState, setShrinkState] = useState();

  useEffect(() => {
    if (time) {
      if (moment(parseStringTime(time)).isValid()) {
        setState(parseStringTime(time));
        setShrinkState(true);
      } else {
        setState(parseStringTime(time));
      }
    }
  }, [time]);

  useEffect(() => {
    setShowError(error);
  }, [error]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {renderMobile ? (
        <MobileTimePicker
          sx={style}
          label={label}
          disabled={disabled}
          slotProps={{
            actionBar: {
              actions: ['clear', 'cancel', 'accept'],
            },
            textField: {
              margin: margin,
              helperText: helperText,
              required: required,
              error: showError,
            },
          }}
          value={state}
          onChange={(time) => {
            setState(time);
            if (!moment(time).isValid()) return emitTime(null);
            if (getShortTime) emitTime(time.format('hh:mm A'));
            else emitTime(time);
          }}
        />
      ) : (
        <DesktopTimePicker
          sx={style}
          label={label}
          disabled={disabled}
          open={open}
          onClose={() => setOpen(false)}
          onAccept={(e) => {
            setShrinkState(moment(e).isValid());
          }}
          slots={{ openPickerIcon: TimeIcon, clearIcon: ClearIcon }}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => console.log('clearing time...'),
            },
            textField: {
              margin: margin,
              helperText: helperText,
              required: required,
              error: showError,
              onClick: () => setShrinkState(true),
              onBlur: () => setShrinkState(moment(state).isValid()),
              InputLabelProps: { shrink: shrink || shrinkState },
            },
            popper: {
              onBlur: () => setOpen(false),
            },
          }}
          value={state}
          onChange={(time) => {
            setState(time);
            if (!moment(time).isValid()) return emitTime(null);
            if (getShortTime) emitTime(time.format('hh:mm A'));
            else emitTime(time);
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export const NSTimePicker = memo(Picker);
