import React, { useState, useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';

import { contactManagerService as service } from '../../services';
import Loading from '../common/others/Loading';
import AppDialog from '../common/others/AppDialog';
import { dispatchAlert, dispatchRelativeAlert } from '../../helpers';
import { ContactManagerContext } from '../../context/contact-manager.context';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';

function Dialog({ title, onOpen, onClose }) {
  const { filterPayLoad } = useContext(ContactManagerContext);

  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [clipboard, setClipboard] = useState();

  useEffect(() => {
    getEmails({ ...filterPayLoad, getEmailList: true });

    return () => {};
  }, []);

  const getEmails = async (payload) => {
    setLoading(true);

    let newController = new AbortController();
    let signal = newController.signal;
    await service.getContactList(payload, signal).then(
      (res) => {
        setEmailList(res.contactList);
      },
      (error) => console.log(error)
    );

    setLoading(false);
  };

  async function copyContent(emails) {
    try {
      await navigator.clipboard.writeText(emails);
      console.log('Content copied to clipboard');
      /* Resolved - text copied to clipboard successfully */
    } catch (err) {
      console.error('Failed to copy: ', err);
      /* Rejected - text failed to copy to the clipboard */
    }
  }

  const handleChange = async (event) => {
    setCopied(event.target.checked);
    await copyContent(_.map(emailList, 'mainEmail').join(';'));
  };

  return (
    <AppDialog open={onOpen} mainTitle={title} maxWidth="md">
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom>
          Just copy the below emails to your email client (e.g. MS Outlook) and paste into an email 'BCC' textbox to
          send large group emails. We suggest using the 'BCC' box instead of the 'TO' box since the 'TO' box shares the
          emails with all the recipients. The emails are grouped in parapraphs of 200 emails.
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <b>{`${emailList.length || 0} Emails Reported:`}</b>
          </div>
          <FormControlLabel
            sx={{
              marginRight: '0px',
              '.MuiFormControlLabel-label': { fontSize: '0.8rem', color: copied ? 'green' : '' },
            }}
            label={`${copied ? 'Copied!' : 'Copy all emails'}`}
            control={
              <Checkbox
                checked={copied}
                onChange={handleChange}
                icon={<ContentCopyOutlinedIcon color="default" />}
                checkedIcon={<LibraryAddCheckOutlinedIcon color="success" />}
              />
            }
          />
        </div>
        <Box
          component="span"
          sx={{
            display: 'block',
            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
            border: '1px solid',
            borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
            borderRadius: 2,
            fontSize: '0.8rem',
          }}
        >
          {loading && <Loading forList />}
          {emailList.length > 0 && (
            <ol style={{ maxHeight: '200px', overflow: 'scroll', overflowX: 'hidden' }}>
              {emailList.map((item) => (
                <li>{item.nameAndCompany?.fullName}</li>
              ))}
            </ol>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="text" color="default" onClick={() => onClose(true)}>
          Close
        </Button>
      </DialogActions>
    </AppDialog>
  );
}

Dialog.propTypes = {
  onOpen: PropTypes.bool.isRequired,
};

export const ViewEmailsDialog = memo(Dialog);
