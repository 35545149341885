import { deepPurple, green, indigo, lightBlue, purple } from '@mui/material/colors';

const appThemes = {
  lightTheme: {
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 1,
          // variant: 'outlined',
        },
        styleOverrides: {
          root: {},
        },
      },
      MuiPopper: {
        defaultProps: {},
        styleOverrides: {
          root: {
            // zIndex: '10000 !important',
            '.MuiAutocomplete-listbox': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {},
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .sectionTitle': {
              background: '#e5e5e5',
              border: '1px solid #cfcfcf',
            },

            '& .MuiDialogTitle-root': {
              padding: '10px',
              backgroundColor: '#373737',
              color: 'white',
            },

            '& .MuiDialogTitle-root + .MuiDialogContent-root': {
              paddingTop: '16px',
              padding: '16px',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          size: 'small',
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontSize: '0.75rem',
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
        },
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
        },
        styleOverrides: {
          root: {
            minHeight: '0px',
            lineHeight: '20px',
          },
        },
      },
      MuiLink: {
        defaultProps: {
          variant: 'body3',
        },
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            '&::before': {
              backgroundColor: 'black',
            },
          },
          tooltip: {
            fontSize: 13,
            padding: '8px',
            backgroundColor: 'black',
            '.MuiDivider-root': {
              borderColor: '#a8a8a8',
            },

            '& .email': {
              color: 'white',
            },
          },
        },
        defaultProps: {
          disableInteractive: true,
          enterTouchDelay: 0,
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px rgba(240, 248, 255, 0.81) inset',
                boxShadow: '0 0 0 100px rgba(240, 248, 255, 0.81) inset',
                '-webkit-box-shadow': '0 0 0 100px rgba(240, 248, 255, 0.81) inset',
              },
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'small',
        },
        styleOverrides: {
          root: {},
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          loadingPosition: 'end',
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            '.MuiSvgIcon-root': {
              marginTop: '-4px',
            },
            '.MuiLoadingButton-loadingIndicator': {
              marginTop: '-4px',
            },
          },
        },
      },
      MuiBadge: {
        defaultProps: {
          showZero: false,
        },
      },
      MuiChip: {
        defaultProps: {},
        styleOverrides: {
          root: {
            borderRadius: '5px',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      typeFullName: 'lightTheme',
      // background: {
      //   paper: '#1D272C',
      //   default: '#263238',
      // },
      text: { main: '#000000', primary: '#000000', success: '#2e7d32' },
      iconColor: {
        main: '#76a6d5',
      },
      default: {
        main: '#707071',
        contrastText: '#fff',
      },
      primary: {
        main: '#15d683',
        default: '#484848',
        contrastText: '#fff',
      },
      secondary: {
        main: '#1976d2',
        light: '#4791db',
        dark: '#115293',
      },
      error: {
        main: '#d32f2f',
        light: '#ef5350',
        dark: '#c62828',
        contrastText: '#fff',
      },
      div: {
        main: 'white',
      },
      warning: {
        main: '#ed6c02',
        light: '#ff9800',
        dark: '#e65100',
        contrastText: '#fff',
      },
      info: {
        main: '#0288d1',
        light: '#03a9f4',
        dark: '#01579b',
        contrastText: '#fff',
      },
      success: {
        main: '#2e7d32',
        light: '#4caf50',
        dark: '#1b5e20',
        contrastText: '#fff',
      },
      danger: {
        main: '#C62828',
      },
      secondary2: {
        main: '#1976d2',
        light: '#4791db',
        dark: '#115293',
        contrastText: '#fff',
      },
      btnNeutral: {
        main: '#0000000a',
        hover: '#e7e7e7',
        contrastText: '#5f5f5f',
      },
      nsAppBG: {
        main: '#E7EAEC',
        fixedBG: '#E7EAEC',
      },
      nsAppDialogHeaderBG: {
        main: '#373737',
      },
      nsAppHeaderBG: {
        main: '#e9e9e9',
      },
      nsTileHeaderBG: {
        main: 'rgb(90, 146, 201)',
      },
      nsLeftMenuTextColor: '#767676',
      nsDangerColor: {
        main: '#C62828',
        hover: '#8c1c1c',
        contrastText: '#ffffff',
      },
      nsTableRowUpload: {
        main: 'beige',
      },
      nsTableHeaderBG: {
        main: '#fbfbfb',
      },
      nsTableBodyBG: {
        main: '#f3f6f9',
        tabColor: '#f5f5f5',
      },
      nsTableRowHoverBG: {
        main: '#e6f2ffa3',
      },
      nsTableRowBorder: {
        main: 'black',
      },
      nsPanelInfoBG: {
        main: 'beige',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2, // changes can be viewed when hover over a button
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: 13,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      text: {
        info: '#0288d1',
        success: '#2e7d32',
        warning: '#ed6c02',
        error: '#d32f2f',
      },
      subtitle3: {
        fontSize: '0.87rem',
        fontWeight: 500,
      },
    },
    mixins: {
      toolbar: {
        minHeight: '50px',
      },
    },
  },

  darkTheme2: {
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 1,
        },
        styleOverrides: {
          root: {
            backgroundColor: '#12191d',
          },
        },
      },
      MuiPopper: {
        defaultProps: {},
        styleOverrides: {
          root: {
            // zIndex: '10000 !important',
            '.MuiAutocomplete-listbox': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {},
        },
      },
      MuiDialog: {
        defaultProps: {
          elevation: 0,
          // variant: 'outlined',
        },
        styleOverrides: {
          root: {
            '& .sectionTitle': {
              background: '#0d1316',
              border: '1px solid #243138',
            },

            '& .MuiDialogTitle-root': {
              padding: '10px',
              display: 'flex',
              backgroundColor: '#373737',
              color: 'white',
            },

            '& .MuiDialogTitle-root + .MuiDialogContent-root': {
              paddingTop: '16px',
              padding: '16px',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          size: 'small',
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontSize: '0.75rem',
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            '.notify.with-gradient, .billing.with-gradient': {
              color: '#ebebeb',
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
        },
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
        },
        styleOverrides: {
          root: {
            minHeight: '0px',
            lineHeight: '20px',
          },
        },
      },
      MuiLink: {
        defaultProps: {
          variant: 'body3',
        },
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            '&::before': {
              backgroundColor: '#545454',
            },
          },
          tooltip: {
            fontSize: 13,
            padding: '8px',
            backgroundColor: '#545454',
            '.MuiDivider-root': {
              borderColor: '#a8a8a8',
            },

            '& .email': {
              color: 'white',
            },
          },
        },
        defaultProps: {
          disableInteractive: true,
          enterTouchDelay: 0,
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            input: {
              '&:-webkit-autofill': {
                '-webkit-background-clip': 'text',
                WebkitBoxShadow: 'none',
                boxShadow: 'none',
                '-webkit-box-shadow': 'none',
              },
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
          margin: 'dense',
          variant: 'outlined',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            '.MuiInputBase-root, .MuiOutlinedInput-input': {
              fontSize: '0.85rem',
            },
          },
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'small',
        },
        styleOverrides: {
          root: {},
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          loadingPosition: 'end',
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            '.MuiSvgIcon-root': {
              marginTop: '-4px',
            },
            '.MuiLoadingButton-loadingIndicator': {
              marginTop: '-4px',
            },
          },
        },
      },
      MuiBadge: {
        defaultProps: {
          showZero: false,
        },
      },
      MuiChip: {
        defaultProps: {},
        styleOverrides: {
          root: {
            borderRadius: '5px',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === 'filled' && {
              color: '#fff',
            }),
          }),
        },
      },
    },
    palette: {
      mode: 'dark',
      typeFullName: 'darkTheme2',
      // background: {
      //   paper: 'yellow',
      //   default: 'blue',
      //   content: 'green',
      // },
      text: {
        main: '#c7c4c4',
        primary: '#c7c4c4',
        contrastText: '#fff',
        success: '#2e7d32',
      },
      iconColor: {
        main: '#6b7691',
      },
      default: {
        main: '#aeb0b1',
        contrastText: '#fff',
      },
      primary: {
        main: '#15d683',
        default: '#c7c4c4',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#1976d2',
        light: '#4791db',
        dark: '#115293',
        contrastText: '#fff',
      },
      danger: {
        main: '#C62828',
      },
      error: {
        main: '#E53935',
      },
      div: {
        main: '#0b1318',
      },
      btnNeutral: {
        main: '#263238',
        contrastText: '#fff',
        hover: '#19252a',
      },
      nsLeftMenuTextColor: '#90a4ae',
      nsAppBG: {
        main: '#263238',
        fixedBG: '#263238',
      },
      nsAppDialogHeaderBG: {
        main: '#121212',
      },
      nsTileHeaderBG: {
        main: '#101418',
      },
      nsAppHeaderBG: {
        main: '',
      },
      nsDangerColor: {
        main: '#C62828',
        hover: '#8c1c1c',
        contrastText: '#ffffff',
      },
      nsTableRowUpload: {
        main: '#6b6e6e',
      },
      nsTableHeaderBG: {
        main: '#0b1318',
      },
      nsTableBodyBG: {
        main: '#19252a',
        tabColor: '#333f44',
      },
      nsTableRowHoverBG: {
        // main: '#0b1318',
        main: 'rgb(11 19 24 / 16%)',
      },
      nsPanelInfoBG: {
        main: '',
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: 13,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      text: {
        info: '#0288d1',
        success: '#2e7d32',
        warning: '#ed6c02',
        error: '#d32f2f',
      },
    },
    mixins: {
      toolbar: {
        minHeight: '50px',
      },
    },
  },
};

const appColors = {
  purple: {
    palette: {
      primary: {
        main: purple[600],
        // dark: purple['A100'],
        contrastText: '#fff',
      },
      primary2: {
        main: purple[200],
        contrastText: '#fff',
      },
      primary3: {
        main: purple[100],
        contrastText: '#fff',
      },
      clear: {
        main: purple[50],
        contrastText: '#000000',
      },
      clearx2: {
        main: '#f3e5f52e',
        contrastText: '#000000',
      },
      border: {
        main: purple[100],
        contrastText: '#fff',
      },
    },
  },
  deepPurple: {
    palette: {
      primary: {
        main: deepPurple[600],
        // dark: deepPurple['A100'],
        contrastText: '#fff',
      },
      primary2: {
        main: deepPurple[200],
        contrastText: '#fff',
      },
      primary3: {
        main: deepPurple[100],
        contrastText: '#fff',
      },
      clear: {
        main: deepPurple[50],
        contrastText: '#000000',
      },
      clearx2: {
        main: '#ede7f638',
        contrastText: '#000000',
      },
      border: {
        main: deepPurple[100],
        contrastText: '#fff',
      },
    },
  },
  indigo: {
    palette: {
      primary: {
        main: indigo[600],
        // dark: indigo['A100'],
        contrastText: '#fff',
      },
      primary2: {
        main: indigo[200],
        contrastText: '#fff',
      },
      primary3: {
        main: indigo[100],
        contrastText: '#fff',
      },
      clear: {
        main: indigo[50],
        contrastText: '#000000',
      },
      clearx2: {
        main: '#e8eaf638',
        contrastText: '#000000',
      },
      border: {
        main: indigo[100],
        contrastText: '#fff',
      },
    },
  },
  green: {
    palette: {
      primary: {
        main: green[600],
        // dark: green['A100'],
        contrastText: '#fff',
      },
      primary2: {
        main: green[200],
        contrastText: '#fff',
      },
      primary3: {
        main: green[100],
        contrastText: '#fff',
      },
      clear: {
        main: green[50],
        contrastText: '#000000',
      },
      clearx2: {
        main: '#e8f5e921',
        contrastText: '#000000',
      },
      border: {
        main: green[100],
        contrastText: '#fff',
      },
    },
  },
  lightBlue: {
    palette: {
      primary: {
        main: lightBlue[600],
        contrastText: '#fff',
      },
      primary2: {
        main: lightBlue[200],
        contrastText: '#fff',
      },
      primary3: {
        main: lightBlue[100],
        contrastText: '#fff',
      },
      clear: {
        main: lightBlue[50],
        contrastText: '#000000',
      },
      clearx2: {
        main: '#e1f5fe36',
        contrastText: '#000000',
      },
      border: {
        main: lightBlue[100],
        contrastText: '#000000',
      },
    },
  },
};

export { appThemes, appColors };
