import React, { Fragment, memo, useContext, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Chip, Divider, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Typography from '@mui/material/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import moment from 'moment';

import { HtmlTooltip } from '../../../Style';
import { appColors } from '../../../theme/AppThemes';
import { useSelector } from 'react-redux';
import { ContactManagerContext } from '../../../context/contact-manager.context';

const StyledDataItemRow = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin-bottom: 7px;

    .MuiCard-root {
      padding: 10px;
    }

    :hover {
      #rowLeft {
        > div:nth-child(2) {
          .MuiSvgIcon-root {
            color: ${theme.palette.success.main};
            animation: left_to_right 1s linear infinite;
          }
          @keyframes left_to_right {
            from {
              margin-left: 30%;
            }
            to {
              margin-left: 70%;
            }
          }
        }
      }
    }
  `
);

const StyledDataItem = styled(Card)(
  ({ theme, $showLeftSection, $priColor }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    /* cursor: pointer; */
    position: relative;
    border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    &.agent-selected {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#f1fff03d'};

      .names {
        h3 {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : '#005e74'};
        }
      }
    }

    @media screen and (max-width: 1000px) {
      max-height: 155px;
    }

    .icon-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      svg:first-child {
        position: relative;
        bottom: 2px;
      }
    }

    .outer-div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .mobile-hide {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    .desktop-hide {
      @media screen and (min-width: 700px) {
        display: none !important;
      }
    }

    .mobile-show {
      @media screen and (min-width: 700px) {
        display: flex;
      }
    }

    .background-check {
      font-size: 12.5px;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .soComments {
      position: absolute;
      top: 5px;
      right: -25px;
    }

    :hover {
      background-color: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};
      clip-path: none;

      .names {
        h3,
        .MuiSvgIcon-root {
          color: ${theme.palette.mode === 'dark' ? '#29c4e9' : ''};
        }
      }

      .stats-others {
        .profile-completion {
          .percentage {
            background: ${theme.palette.mode === 'dark' ? '#0b1318' : '#e6f2ffa3'};

            span: {
              font-weight: 600 !important;
            }
          }
        }
      }
    }

    .email-checkBox {
      flex-grow: 0.1;
      span {
        margin-left: -10px;
      }
    }

    > div {
      flex: 1 1 0px;
    }

    .avatar {
      flex-grow: 0;

      .MuiAvatar-root {
        border: 2px solid ${theme.palette.mode == 'dark' ? theme.palette.border.main : theme.palette.primary.main};
        color: ${theme.palette.primary.main};
        background-color: ${theme.palette.mode == 'dark'
          ? theme.palette.background.default
          : appColors[$priColor].palette.border.main};
      }

      @media screen and (max-width: 700px) {
        margin-right: 20px;
      }
    }

    .names {
      flex-grow: 1;
      margin-left: 20px;

      > div {
        display: flex;
        width: fit-content;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 1.1rem;
          top: 2px;
          position: relative;
          color: #1e879f;
        }
      }

      h3 {
        color: #1e879f;
      }

      p {
        font-size: 0.8rem;
        max-width: 175px;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      .mobile-hide {
        @media screen and (max-width: 700px) {
          display: none;
        }
      }
    }

    .alt-names {
      display: flex;
      flex-direction: column;
      margin-left: 4px;
      h3 {
        color: #1e879f !important;
      }
      p {
        margin-right: 4px;
      }
    }

    .contact {
      > div {
        overflow-wrap: break-word;
        padding-left: 8px;

        .MuiSvgIcon-root {
          font-size: 1rem;
          align-self: center;
        }

        span {
          max-width: 180px;
          font-size: 0.8rem;
        }
      }

      > div:nth-child(2) {
        span {
          line-height: 100%;
        }
      }
    }

    ${theme.breakpoints.up('md')} {
      .contact {
        > div {
          overflow-wrap: break-word;

          span {
            min-width: 230px;
          }
        }
      }
    }

    .stats-others {
      flex-grow: 0.85;
      margin-right: 10px;
      font-size: 0.8rem;

      > div {
        display: flex;

        > div:nth-child(1) {
          width: 100px;
        }

        > div:nth-child(2) > div:first-child {
          width: calc(100% - 100px);
        }
      }

      .profile-completion {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: start;

        > div:first-child {
          position: relative;
        }

        .percentage {
          position: absolute;
          left: 15%;
          top: -8px;
          font-weight: bolder;
          background: ${theme.palette.background.paper};
          border-radius: 15%;
          padding: 0px 2px 0px;
        }
      }
    }

    .more-icons {
      position: absolute;
      bottom: 8px;
      right: 15px;
      text-align: right;
      display: grid;

      .miles {
        border-radius: 5px;
        font-weight: 500;
        font-size: 0.7rem;
        height: 20px;
        color: #03a9f4;
        width: fit-content;
        justify-self: end;
        margin-bottom: 5px;
      }
    }

    .profile-updated {
      height: auto;
      border-radius: 5px;

      .MuiChip-icon {
        margin-left: 2.5px;
      }

      .MuiChip-label {
        width: 30px;
        padding: 0px;
        padding-right: 4px;
        font-size: 0.7rem;
        text-align: end;
      }
    }

    .more-icons.favorite {
      display: flex;
      gap: 5px;
      height: 20px;
      line-height: 20px;
    }
  `,

  ({ withnotes, $showLeftSection }) =>
    withnotes === 'true' &&
    css`
      position: relative;
      border-bottom-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      border-top-left-radius: ${!$showLeftSection ? '10px' : '0px'};
      clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 102%);

      ::after {
        content: '';
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        background-color: #e1e1f2;
        top: 0;
        right: 0;
        border-bottom-left-radius: 20px;
        border-bottom-style: groove;
        text-align: center;
        z-index: 5;
      }

      :hover {
        .soComments {
          right: 5px;
          z-index: 5;
          color: blueviolet;
        }

        ::after {
          font-family: 'Material Icons';
          font-size: 1.25rem;
          color: blueviolet;
          z-index: 0;
          width: 35px;
          height: 35px;
        }
      }
    `
);

const Item = ({ contact }) => {
  const history = useHistory();

  const { customColor: appColor } = useSelector((state) => state.reducer.themeAppearance);

  return (
    <div style={{ display: 'flex' }}>
      <StyledDataItemRow sx={{ boxShadow: 0, borderRadius: '10px' }}>
        <StyledDataItem
          id="rowRight"
          $showLeftSection={false}
          $priColor={appColor}
          withnotes={contact.sO_Comments ? 'true' : 'false'}
        >
          <div className="names mobile-hide">
            <div
              onClick={(e) => {
                history.push({
                  pathname: `/contact-manager/profile/customer/${contact.userID}`,
                  state: {
                    fullName: contact.nameAndCompany.fullName,
                    company: contact.nameAndCompany.company,
                  },
                });
                e.stopPropagation();
              }}
            >
              <h3>{contact.nameAndCompany.fullName}</h3>
              {<LaunchOutlinedIcon fontSize="small" />}
            </div>
            <p>{contact.nameAndCompany.company.replace('<br>', '')}</p>
          </div>
          <div className="contact">
            <div className="alt-names desktop-hide">
              <div
                onClick={(e) => {
                  history.push({
                    pathname: `/contact-manager/profile/customer/${contact.userID}`,
                    state: {
                      fullName: contact.nameAndCompany.fullName,
                      company: contact.nameAndCompany.company,
                    },
                  });
                  e.stopPropagation();
                }}
              >
                <h3>
                  {contact.nameAndCompany.fullName}
                  {<LaunchOutlinedIcon fontSize="small" />}
                </h3>
              </div>
              <div>
                <Typography fontWeight={'bold'} variant={'subtitle2'}>
                  {contact.nameAndCompany.company.replace('<br>', '')}
                </Typography>
              </div>
            </div>
            <div className="icon-item">
              <LocationOnOutlinedIcon fontSize="small" />{' '}
              <span>
                {contact.billingAddress?.city} {contact.billingAddress?.state} {contact.billingAddress?.zipCode}
              </span>
            </div>
            <div className="icon-item">
              <AlternateEmailOutlinedIcon fontSize="small" />{' '}
              <span onClick={(e) => e.stopPropagation()}>{contact.mainEmail.toLowerCase()}</span>
            </div>
            <div className="icon-item">
              <PhoneIphoneOutlinedIcon fontSize="small" />{' '}
              <span onClick={(e) => e.stopPropagation()}>
                {contact.preferredPhone.replace(/^\D+/g, '').replace(')', '-')}
              </span>
            </div>
          </div>
          {contact.sO_Comments && (
            <div className="soComments">
              <HtmlTooltip arrow disableFocusListener title={contact.sO_Comments}>
                <StickyNote2OutlinedIcon fontSize="small" />
              </HtmlTooltip>
            </div>
          )}
        </StyledDataItem>
      </StyledDataItemRow>
    </div>
  );
};

export const DataItemCustomer = memo(Item);
