import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { Card, Chip } from '@mui/material';

const HeaderWrapper = styled('div')(
  ({ theme }) => css`
    & .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      align-items: end;
    }

    & .logs {
      margin-top: 15px;
      margin-bottom: -15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div:nth-child(2) {
        display: flex;

        & span {
          align-self: center;
          width: 80px;
          text-align: right;
          padding-right: 5px;
        }

        & .MuiFormControl-root {
          margin-top: 0;
          width: 150px;
        }
      }
    }
  `
);

const CardRoot = styled(Card)(
  ({ theme }) => css`
    padding: 15px;

    ${theme.breakpoints.down('sm')} {
      padding: 10px !important;
    }

    & .wrapper-section {
      display: flex;

      & .left-section {
        ${theme.breakpoints.up('sm')} {
          padding-right: 20px;
        }
      }

      & .right-section {
        padding-left: 20px;
      }

      & .left-section,
      .right-section {
        flex-grow: 1;

        & .row {
          display: flex;
          align-items: center;

          & .col:nth-child(1) {
            flex-grow: 6;
          }
          & .col:nth-child(2) {
            flex-grow: 1;
          }
          & .col:nth-child(3) {
            flex-grow: 1;
            margin-right: 7px;
          }
          & .col:nth-child(4) {
            flex-grow: 2;
          }
        }
      }

      & .row .col input {
        width: 0;
        min-width: calc(100% - 25px);
        border: none;
        outline: none;
        overflow: hidden;
        background-color: inherit;
        color: inherit;
      }
      & input:hover {
      }

      & .row .header {
        text-align: center;
      }
    }
  `
);

const ItemsWrapper = styled('div')(
  ({ theme }) => css`
    width: 100%;
    & > div {
      margin-bottom: 3px;
    }
  `
);

const LogWrapper = styled('div')(
  ({ theme }) => css`
    & .MuiAccordionSummary-root,
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 50px;
      height: 50px;
    }

    & .MuiAccordionSummary-root {
      flex-flow: row-reverse;
      display: inline-flex;
      width: 100%;
      padding: 0;

      .MuiAccordionSummary-expandIconWrapper {
        padding: 15px;
      }

      .MuiAccordionSummary-content {
        align-items: baseline;

        .summaryWrapper {
          display: flex;
          align-items: center;
          text-align: center;
          width: 100%;
          justify-content: space-between;

          div {
            padding: 5px;
            margin-right: 12px;
          }

          .labelAndTimeAgo {
            display: flex;
            align-items: center;

            div:nth-child(1) {
              width: 90px;
            }

            div:nth-child(2) {
              width: 150px;
            }
          }

          ${theme.breakpoints.only('xs')} {
            .heading {
              display: none !important;
            }
          }

          ${theme.breakpoints.up('xs')} {
            .heading {
              width: 100%;
              display: inline-grid;
              text-align: left;

              div {
                padding: 5px;
              }

              p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .summaryWrapper.expanded {
          .labelAndTimeAgo {
            width: 319px;

            div:nth-child(1) {
              width: 95px;
            }
            div:nth-child(2) {
              min-width: 210px;
            }
          }
        }
      }
    }

    & .MuiAccordionDetails-root {
      display: flex;
      align-items: center;
      padding: 10px 16px 10px;

      > div:nth-child(1) {
        flex-grow: 1;
        text-align: left;
        padding-right: 20px;

        .logByUser {
          color: #647278;
          padding-left: 15px;
        }
      }

      > div:nth-child(2) {
        div {
          display: flex;

          div:nth-child(1) {
            text-align: right;
            margin-right: 5px;
            min-width: 140px;
          }
        }
      }
    }

    & .action-section {
      border-top: 1px solid #8080802b;
      display: flex;

      div:nth-child(1) {
        flex-grow: 1;
        text-align: left;

        b {
          padding-right: 25px;
        }

        .MuiButtonBase-root {
          padding: 0;
        }
      }

      div:nth-child(2) {
        button {
          margin-left: 15px;
        }
      }
    }
  `
);

const LogChip = styled(Chip)(
  ({ theme }) => css`
    font-weight: bold;
    font-family: monospace;
    color: white;

    &.Event {
      background-color: peru;
    }
    &.Note {
      background-color: dodgerblue;
    }
    &.Email {
      background-color: #a267cf;
    }
    &.File {
      background-color: lightseagreen;
    }
  `
);

export { HeaderWrapper, CardRoot, ItemsWrapper, LogWrapper, LogChip };
