import React, { createContext, useState, useEffect, useReducer } from 'react';
import { dispatchRelativeAlert, nsRelativeAlert, objectToQueryString } from '../../../helpers';
import { useDispatch } from 'react-redux';

import { manageRequestService as mrService, UploadFilesService as service } from '../../../services';
import { store } from '../../../store';

export const FileUploadContext = createContext();

const initialState = () => {
  return {
    savedFiles: [],
    externalFiles: [],
    selectedFiles: [],
    notes: '',
    settings: {},
  };
};

const counterInitialState = {
  isProcessing: false,
  totalUpload: 0,
  newFileUploadCounter: 0,
  newFileUploadErrorCounter: 0,
  externalUploadCounter: 0,
  externalUploadErrorCounter: 0,
  message: {
    success: 'Documents were uploaded successfully.',
    warning: '{total} out of {completed} files were uploaded successfully',
    error: 'Unable to upload documents. Please contact support.',
  },
};

function counterReducer(state, action) {
  let data = action.payload;
  switch (action.type) {
    case 'setProcessState':
      return { ...state, isProcessing: true };
    case 'resetCounter':
      return { ...counterInitialState, isProcessing: false };
    case 'setUploadTotal':
      return {
        ...state,
        totalUpload: data.totalUpload || 0,
      };
    case 'setUploadTypeTotal':
      let total = data.newFileUploadTotal + data.externalUploadTotal;
      return {
        ...state,
        totalUpload: parseInt(total || 0),
        newFileUploadTotal: data.newFileUploadTotal || 0,
        externalUploadTotal: data.externalUploadTotal || 0,
      };
    case 'setNewUploadCounter':
      return {
        ...state,
        newFileUploadCounter: state.newFileUploadCounter + 1,
      };
    case 'setNewUploadErrorCounter':
      return {
        ...state,
        newFileUploadErrorCounter: state.newFileUploadErrorCounter + 1,
      };
    case 'setExternalCounter':
      return {
        ...state,
        externalUploadCounter: parseInt(data.externalUploadCounter || 0),
      };
    case 'setExternalErrorCounter':
      return {
        ...state,
        externalUploadErrorCounter: parseInt(data.externalUploadErrorCounter || 0),
      };
    default:
      return state;
  }
}

const FileUploadProvider = ({ context, children, notifyAlertMsg = () => {} }) => {
  const dispatch = useDispatch();
  const userRoles = store.getState().reducer.currentUser.userRoles;
  const [fileContext, setFileContext] = useState(context);

  const getCanEdit = () => {
    switch (context.uploadDocType) {
      case 'CustInvoiceTemplate':
      case 'ServiceAgreementForAgent':
        return userRoles.includes('ServiceOwner') || userRoles.includes('SuperUser');
      case 'SigningFiles':
      case 'CustomerDefault':
        return userRoles.includes('Customer') || userRoles.includes('ServiceOwner') || userRoles.includes('SuperUser');
      case 'ScanBacks':
        return (
          userRoles.includes('NotaryAgent') || userRoles.includes('ServiceOwner') || userRoles.includes('SuperUser')
        );
      default:
        return false;
    }
  };

  const [state, setState] = useState(initialState());
  const [canEdit, setCanEdit] = useState(getCanEdit());
  const [signalController, setSignalController] = useState();
  const [uploadSingleRequest, setUploadSingleRequest] = useState(false);
  const [startUploadFromChild, setStartUploadFromChild] = useState(false);
  const [isGettingFiles, setIsGettingFiles] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [collectFileError, setCollectFileError] = useState([]);
  const [filesForLogging, setFilesForLogging] = useState([]);
  const [secondaryButtonText, setSecondaryButtonText] = useState('');
  const [counterState, counterDispatch] = useReducer(counterReducer, counterInitialState);
  const [showNavigateAwayAlert, setShowNavigateAwayAlert] = useState(false);

  const getParamsForApi = (params) => {
    return (
      '?' +
      objectToQueryString({
        ...params,
      })
    );
  };

  useEffect(() => {
    let isAgent = userRoles.includes('NotaryAgent') || false;
    if (isAgent && context.uploadDocType == 'SigningFiles') {
      mrService.sendAgentPageOpenNotice(fileContext.requestID);
    }

    return () => {
      dispatch({ type: 'SET_ROUTE_PROMPT_STATE', payload: '' });
    };
  }, []);

  // clean states once process is done
  useEffect(() => {
    let checkForEndInterval;
    if (counterState.totalUpload && counterState.isProcessing) {
      checkForEndInterval = setInterval(() => {
        let newUploadsTotals =
          parseInt(counterState.newFileUploadCounter || 0) + parseInt(counterState.newFileUploadErrorCounter || 0);

        let externalsTotals =
          parseInt(counterState.externalUploadCounter || 0) + parseInt(counterState.externalUploadErrorCounter || 0);

        if (counterState.totalUpload == newUploadsTotals + externalsTotals) {
          counterDispatch({ type: 'resetCounter' });
          setStartUploadFromChild(false);
          setIsSubmitting(false);
          setSecondaryButtonText('Close');
          // setCollectFileError([]);
          displayUploadMessage();
          notifyAlertMsg(true);
          dispatch({ type: 'SET_ROUTE_PROMPT_STATE', payload: 'destroyed' });

          if (filesForLogging?.length > 0) {
            let skipEmailNotice = !state.settings.overrideSendDocsNotice;
            service.logUploadedFiles(fileContext, filesForLogging, skipEmailNotice);
            setFilesForLogging([]);
            setShowNavigateAwayAlert(false);
          }

          // state.externalFiles.splice(0, externalFiles.length);
          const errors = state.selectedFiles.filter(function (value) {
            return value.hasError;
          });

          setState((values) => ({
            ...values,
            externalFiles: [],
            selectedFiles: errors,
          }));
          getFiles(); // get uploaded files from DB
          clearInterval(checkForEndInterval);
        }
      }, 1000);
    }

    return () => clearInterval(checkForEndInterval);
  });

  const displayUploadMessage = () => {
    const newFileUploadCounter = counterState.newFileUploadCounter;
    const newFileUploadErrorCounter = counterState.newFileUploadErrorCounter;

    const externalUploadCounter = counterState.externalUploadCounter;
    const externalUploadErrorCounter = counterState.externalUploadErrorCounter;

    let msg = '';
    let msgType = 0;
    if (newFileUploadErrorCounter + externalUploadErrorCounter == 0) {
      msg = 'Documents were uploaded successfully.';
      msgType = 1;
    } else if (newFileUploadCounter + externalUploadCounter == 0) {
      // msg = collectFileError.join('\n');
      // msgType = 3;
    } else {
      msg = `${newFileUploadCounter + externalUploadCounter} out of ${
        counterState.totalUpload
      } files were uploaded successfully`;
      msgType = 2;
    }

    if (msg) {
      nsRelativeAlert(msg, msgType, undefined, msgType == 2 ? true : false);
    }
  };

  const getFiles = async () => {
    setIsGettingFiles(true);
    await service.getFiles(fileContext).then(
      (res) => {
        if (res) {
          setState((values) => ({
            ...values,
            savedFiles: res.files,
            notes: res.notes,
            settings: res.uploadSettings,
          }));
        }
      },
      (error) => {
        dispatchRelativeAlert(error, true);
      },
    );
    setIsGettingFiles(false);
  };

  const uploadFiles = async (signal, files) => {
    await service.uploadFiles(signal, fileContext, files).then(
      (res) => {
        getFiles(); // get uploaded files from DB
        setState((values) => ({
          ...values,
          selectedFiles: [],
        }));
      },
      (error) => {
        throw error;
      },
    );
  };

  const uploadFilesWithProgress = async (signal, files, handleProggress) => {
    const onProgress = (progress) => handleProggress(parseInt(progress));
    await service.uploadFilesWithProgress(signal, getParamsForApi(fileContext), files, onProgress).then(
      (res) => {
        return res;
      },
      (error) => {
        throw error;
      },
    );
  };

  return (
    <FileUploadContext.Provider
      value={{
        uploadSingleRequest,
        signalController,
        startUploadFromChild,
        fileContext,
        state,
        isSubmitting,
        counterState,
        collectFileError,
        isGettingFiles,
        filesForLogging,
        canEdit,
        secondaryButtonText,
        showNavigateAwayAlert,
        setShowNavigateAwayAlert,
        setFilesForLogging,
        getParamsForApi,
        setIsGettingFiles,
        setFileContext,
        counterDispatch,
        setCollectFileError,
        setSignalController,
        setUploadSingleRequest,
        setStartUploadFromChild,
        setIsSubmitting,
        setState,
        getFiles,
        uploadFiles,
        uploadFilesWithProgress,
        setSecondaryButtonText,
        setCanEdit,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

export default FileUploadProvider;
