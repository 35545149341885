import { plainErrorMsgHandler } from './handle-fetch-errors';

export async function handleResponse(response, withErrorHandler = plainErrorMsgHandler) {
  let withCustomHandler = typeof withErrorHandler === 'function' && withErrorHandler?.name !== 'plainErrorMsgHandler';

  return response.text().then((text) => {
    let data;

    if (IsValidJSONString(text)) data = text && JSON.parse(text);
    else data = text;

    const handlers = {
      /**************************************************************
       The 401 (Unauthorized) status code indicates that the request
       has not been applied because it lacks valid authentication
       credentials for the target resource. The server generating a 401
       response MUST send a WWW-Authenticate header field (Section 4.1)
       containing at least one challenge applicable to the target resource.
       **************************************************************/
      401: (data) => data,
      403: () => 'You are not authorized to see this content.',
      500: (data) => (data?.detail && data.type === 'ServerError' ? plainErrorMsgHandler(data) : data),
      400: (data) => {
        switch (data.type) {
          case 'ValidationFailure':
            if (withCustomHandler) return data;
            else return withErrorHandler(data);
          default:
            return data;
        }
      },
      404: () => 'The request URL was not found on this server.',
    };

    if (!response.ok) {
      const handler = handlers[response?.status];
      if (handler) {
        return Promise.reject(handler(data));
      }
      const error = (data && data.message) || response.statusText || data?.detail;
      return Promise.reject(error);
    }
    return data;
  });
}

function IsValidJSONString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
