import React, { Fragment, useEffect } from 'react';
import { store } from '../store';
import { Button, Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { validateURL } from '../helpers';
import useDocumentTitle from '../helpers/hooks/useDocumentTitle';
const coffeeIcon = require(`../assets/images/ico_lenta.png`);
const checkBlackIcon = require(`../assets/images/check_black.png`);
const checkOrangeIcon = require(`../assets/images/check_orange.png`);

const AgentRegistrationStyle = styled('div')(
  ({ theme }) => css`
    .link {
      color: ${theme.palette.primary.main};
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
      h3 {
        margin-left: 12px;
      }
    }

    .cardTable {
      @media screen and (max-width: 1000px) {
        padding: 0px !important;
      }
    }

    .buttons {
      float: right;
      @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 10px;
      }
    }

    .button {
      float: right;
      background-color: #ef6c00;
      color: white;
      margin-top: 12px;
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }

    .MuiCard-root:nth-child(n + 1) {
      padding: 30px;
    }

    .portalLogo {
      padding: 20px 20px;
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .view-title-agent {
      font-size: 25px;
      font-weight: 500;
      padding: 5px 0;
      margin-bottom: 15px;
      text-align: center;
    }

    .card {
      text-align: center;
      padding: 0px !important;
    }

    p {
      margin-bottom: 15px;
    }

    .title-color {
      color: white;
    }

    .background-color-header {
      background-color: ${theme.palette.mode === 'light' ? '#eff0f3' : ' #9ba19f'};
    }

    .view-subtitle-agent {
      font-size: 12px;
      font-weight: 100;
      padding: 5px 0;
      margin-bottom: 15px;
      text-align: center;
    }

    .card-year {
      padding: 12px !important;
      margin-bottom: 20px !important;
      margin-top: 20px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      width: 120px !important;
      display: inline-block !important;
    }

    .card-padding {
      padding: 30px !important;
    }

    .card-padding-pro {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .card-actions {
      text-align: center;
      display: block;
      height: 70px;
    }
    .card-button-free {
      color: white;
      margin-top: 12px;
      background-color: black;
    }
    .card-button-pro {
      color: white;
      margin-top: 12px;
      background-color: #ef6c00;
    }

    label {
      font-size: inherit;
    }

    [${theme.breakpoints.down('sm')}] {
      .form-inputs {
        display: block;
      }
    }

    .table {
      min-width: 100;
      .MuiTableCell-root {
        border-left: 1px solid rgba(224, 224, 224, 1);
        font-size: 11px;
      }
      img {
        width: 25px;
      }
    }

    .orange-label-dollar {
      color: #ef6c00;
      font-size: 30px;
    }

    .orange-label {
      color: #ef6c00;
      font-weight: bold;
    }
    .label-cell {
      font-size: 16px;
    }
    .label-cell-bold {
      font-size: 16px;
      font-weight: bold;
    }
    .text-white {
      color: ${theme.palette.mode === 'dark' && 'white'};
    }
    .font-link {
      font-size: 15px;
      vertical-align: bottom;
    }
  `
);

const AgentRegistration = (props) => {
  useDocumentTitle('Agent Registration'); // Set page title
  const RegisterfreePage = 'https://notarycafe.com/register';
  const RegisterPage = 'https://notarycafe.com/register/pro';

  const { global } = store.getState().reducer;
  let portalLogoSRC;
  if (global.logoFile) {
    try {
      portalLogoSRC = require(`../assets/portals/${global.portalID}/${global.logoFile}`);
    } catch (error) {
      portalLogoSRC = '';
    }
  }

  return (
    <Fragment>
      <AgentRegistrationStyle>
        <div className="portalLogo">
          {portalLogoSRC ? <img src={portalLogoSRC} alt="Logo" /> : <h3>{global.portalName}</h3>}
        </div>
        <div>
          <label
            className="font-link"
            style={{
              marginRight: '10px',
            }}
          >
            {'Already have an account?'}
          </label>

          <Link
            className="link font-link"
            to={{
              pathname: '/login',
            }}
            style={{
              marginRight: '20px',
            }}
          >
            {'Click here to login'}
          </Link>
        </div>

        <Card style={{ backgroundColor: '#276b7b' }}>
          <div className="view-title-agent title-color">Become a Notary Café Member Today!</div>
          <div className="view-subtitle-agent title-color">
            Join the Notary Café community today and get started on your path to increased business!
          </div>
          <br />
          <Grid
            container
            style={{ gap: 100 }}
            direction="row"
            alignItems="flex-start"
            justify="space-around"
            justifyContent="center"
          >
            <Card sx={{ maxWidth: 350 }} className="card">
              <CardHeader
                title={<div style={{ fontWeight: 'bold' }}>Basic Membership</div>}
                subheader={
                  <label className="text-white" style={{ fontWeight: 'bold', fontSize: '40px' }}>
                    FREE
                  </label>
                }
                className="background-color-header card-padding"
              ></CardHeader>
              <CardContent className="card-padding">
                <p>
                  <img src={checkBlackIcon}></img>
                </p>
                <p>Access to Forums and resources on NotaryCafe.com</p>
                <p>Profile visible to the NotaryCafe Network of Signing Services</p>
                <p>Includes your primary zip code as your Service Location for searches</p>
                <p>Access to Completion Report form at each Signing Service website</p>
              </CardContent>
              <CardActions className="background-color-header card-actions">
                <Button
                  target="_blank"
                  href={validateURL(RegisterfreePage) ? RegisterfreePage : ''}
                  variant="contained"
                  size="medium"
                  className="card-button-free"
                >
                  Subscribe for Free
                </Button>
              </CardActions>
            </Card>
            <Card
              style={{
                padding: '10px',
                backgroundColor: '#84B0C0',
                zIndex: 3,
              }}
            >
              <Card sx={{ maxWidth: 350 }} className="card">
                <CardHeader
                  style={{ padding: '30px' }}
                  title={
                    <div>
                      <label className="orange-label">Pro</label>
                      <label style={{ fontWeight: 'bold' }}> Membership</label>
                    </div>
                  }
                  subheader={
                    <div>
                      <label className="orange-label-dollar">$</label>
                      <label
                        className="orange-label"
                        style={{
                          fontSize: '30px',
                        }}
                      >
                        5
                      </label>
                      <label className="orange-label">/month</label>
                    </div>
                  }
                ></CardHeader>
                <CardContent style={{ padding: '0px' }}>
                  <div style={{ backgroundColor: '#276b7b' }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <img src={coffeeIcon}></img>
                          </TableCell>
                          <TableCell style={{ paddingLeft: '0px' }}>
                            <label className="title-color" style={{ fontWeight: 'bold' }}>
                              Cheaper than a cup of coffee
                            </label>
                            <br />
                            <label
                              className="title-color"
                              style={{
                                fontStyle: 'italic',
                                marginLeft: '5px',
                              }}
                            >
                              No long term commitment
                            </label>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <Card className="background-color-header card-year">
                    <label className="text-white">or </label>
                    <label className="text-white" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                      $36/yr
                    </label>
                  </Card>
                  <p>
                    <img src={checkOrangeIcon}></img>
                  </p>
                  <div className="card-padding-pro">
                    <p>Now with easier-than-ever centralized mobile completion reports</p>
                    <p>Include your professional profile in our public listing</p>
                    <p>Enhanced profile listings with additional zip codes for searches</p>
                    <p>Profile visbility control. You decide what information can be seen by the public</p>
                    <p>Include your professional and social media connections in your profile</p>
                  </div>
                </CardContent>
                <CardActions className="background-color-header card-actions">
                  <Button
                    target="_blank"
                    href={validateURL(RegisterPage) ? RegisterPage : ''}
                    size="medium"
                    variant="contained"
                    className="card-button-pro"
                  >
                    Become a Pro Member
                  </Button>
                </CardActions>
              </Card>
            </Card>
          </Grid>
        </Card>
        <br />
        <br />
        <Card className="cardTable">
          <TableContainer component={Paper}>
            <Table size="small" className="table" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <label
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#276b7b',
                      }}
                    >
                      Benefits
                    </label>
                  </TableCell>
                  <TableCell>
                    <label className="label-cell-bold">Basic </label>
                    <label className="label-cell">Member</label>
                  </TableCell>
                  <TableCell>
                    <label className="label-cell-bold">Pro </label>
                    <label className="label-cell">Member</label>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Access to Forums and resources on NotaryCafe.com
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkBlackIcon}></img>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Visibility to the Notary Cafe Network of of Signing Services
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkBlackIcon}></img>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Visibility in the Public Search on NotaryCafe.com
                  </TableCell>
                  <TableCell align="center">
                    <label style={{ fontWeight: 'bold' }}>-</label>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Includes your primary zip code as your Service Location for searches
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkBlackIcon}></img>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Includes 4 additional zip codes to appear in expanded search results
                  </TableCell>
                  <TableCell align="center">
                    <label style={{ fontWeight: 'bold' }}>-</label>{' '}
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Gain an edge over non members in our Signing Services network searches by appearing above them in
                    the search results
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    <label style={{ fontWeight: 'bold' }}>-</label>{' '}
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Connect your personal and professional social networks to your profile
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    <label style={{ fontWeight: 'bold' }}>-</label>{' '}
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Access to our Centralized Mobile Completion Report form on NotaryCafe.com for our network of Signing
                    Services
                  </TableCell>
                  <TableCell align="center">
                    <label style={{ fontWeight: 'bold' }}>-</label>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Profile visbility control. You decide what information can be seen in the public search
                  </TableCell>
                  <TableCell align="center">
                    <label style={{ fontWeight: 'bold' }}>-</label>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SEO Friendly profile URL's that can be shared or linked to Example: NotaryCafe.com/agent.name
                  </TableCell>
                  <TableCell align="center">
                    <label style={{ fontWeight: 'bold' }}>-</label>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Link your background screen and other needed docs to your profile for easy download or verification
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkBlackIcon}></img>
                  </TableCell>
                  <TableCell align="center">
                    <img src={checkOrangeIcon}></img>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="buttons">
            <Button
              target="_blank"
              href={validateURL(RegisterPage) ? RegisterPage : ''}
              size="medium"
              variant="contained"
              className="button"
            >
              Become a Pro Member
            </Button>
          </div>
        </Card>
      </AgentRegistrationStyle>
    </Fragment>
  );
};

export default AgentRegistration;
