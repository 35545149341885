import React, { useCallback } from 'react';
import { Button, LinearProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useBusinessSettings } from '../../context/business-settings.context';
import { StyledAccordion } from '../../Style';
import CommonInfo from '../../components/business-settings/BusinessSettingCommonInfo';
import RichTextEditor from '../common/others/RichTextEditor';

const ChangeRequest = ({ callBack = () => {} }) => {
  const panelKey = 'changeRequest';

  const {
    dataIsLoading,
    expanded,
    setExpanded,
    currentSetting,
    setCurrentSetting,
    updateBusSettings,
    hasDirtyChanges,
    progressType,
  } = useBusinessSettings();

  const handleNavigationCallback = (e) => {
    if (hasDirtyChanges) {
      callBack({ confirmation: false, accordion: panelKey });
      e.stopPropagation();
    } else {
      if (expanded !== panelKey) {
        callBack({ confirmation: true, accordion: panelKey });
      } else {
        setExpanded(false);
      }
    }
  };

  const handleCustomerEmailBody = useCallback(
    (html) => setCurrentSetting({ ...currentSetting, requestMsgChanges: html }),
    [currentSetting]
  );

  return (
    <>
      <StyledAccordion
        id={`${panelKey}-Accordion`}
        expanded={expanded === panelKey}
        onChange={(e) => handleNavigationCallback(e)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="changeRequestPanel-content"
          className={expanded ? 'activePanel' : ''}
        >
          <Typography className="heading">Change Request</Typography>
          <Typography className={`secondaryHeading ${expanded === panelKey ? 'activePanel' : ''}`}>
            Used when sending change request email
          </Typography>
        </AccordionSummary>
        {currentSetting && progressType == panelKey && (
          <>
            <AccordionDetails>
              <div className="comp-sub-title">Used when sending change request email</div>
              <div className="rows">
                <CommonInfo />
                <Box component="span">
                  <div className="comp-sub-title">Default Message - Customer 'Change Request'</div>
                  <div className="setting-label">Subject(200 characters)</div>
                  <TextField
                    fullWidth
                    multiline
                    value={currentSetting.requestSubChanges || ''}
                    onChange={(e) => {
                      setCurrentSetting({
                        ...currentSetting,
                        requestSubChanges: e.target.value,
                      });
                    }}
                  />
                  <div className="setting-label">Message (7700 characters)</div>
                  <RichTextEditor
                    readOnly={false}
                    editorHeight="auto"
                    characterMax={7700}
                    value={currentSetting.requestMsgChanges}
                    onChange={handleCustomerEmailBody}
                  />
                </Box>
              </div>
            </AccordionDetails>
            <div className="action-section">
              <Button
                disabled={dataIsLoading}
                variant="contained"
                onClick={() => updateBusSettings(panelKey, 'Change request ')}
              >
                Update
              </Button>
            </div>
          </>
        )}
      </StyledAccordion>
      {dataIsLoading && progressType == panelKey && <LinearProgress sx={{ top: '-10px' }} color="inherit" />}
    </>
  );
};

export default ChangeRequest;
