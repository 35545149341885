import { authHeader, handleResponse } from '../helpers';
import Fetch from '../helpers/Fetch';

const manageRequestService = {
  getGeneralRequests: async function (paramObj, signal) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(paramObj),
      signal: signal,
    };
    const params = {};
    const url = '/managerequest/GetGeneralRequests';
    return Fetch.post(url, params, options).then(handleResponse);
  },

  getRequestAppointmentData: async function (
    apptActionType,
    customerID,
    notaryID,
    requestID,
    billingVisible,
    altLocVisible
  ) {
    const options = { headers: await authHeader() };
    const params = {
      apptActionType: apptActionType,
      customerID: customerID,
      notaryID: notaryID,
      requestID: requestID,
      billingVisible: billingVisible,
      altLocVisible: altLocVisible,
    };
    const url = `/managerequest/getDataForAppointmentRequest`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  notifyRequestApptEmail: async function (requestID, actionType, formData, omitCustEmail, omitAgentEmail, customerID) {
    const options = { headers: await authHeader(true), body: formData };
    const params = {
      requestID: requestID,
      actionType: actionType,
      omitCustEmail: omitCustEmail,
      omitAgentEmail: omitAgentEmail,
      customerID: customerID,
    };
    const url = `/managerequest/sendAppointmentEmail`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  getRequestLogs: async function (requestID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID };
    const url = `/api/RequestLog/getRequestLogs`;
    return Fetch.get(url, params, options).then(handleResponse);
  },

  saveRequestLog: async function (log) {
    const options = { headers: await authHeader(), body: JSON.stringify(log) };
    const params = {};
    const url = `/api/RequestLog/addRequestLog`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  updateRequestLog: async function (log) {
    const options = { headers: await authHeader(), body: JSON.stringify(log) };
    const params = {};
    const url = `/api/RequestLog/updateRequestLog`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  deleteRequestLog: async function (requestLogID, isLegacy) {
    const options = { headers: await authHeader(), body: JSON.stringify() };
    const params = { requestLogID: requestLogID, isLegacy: isLegacy };
    const url = `/api/RequestLog/deleteRequestLog`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  assignStaffToRequest: async function (requestID, assignedStaffID) {
    const options = { headers: await authHeader() };
    const params = { requestID: requestID, assignedStaffID: assignedStaffID };
    const url = `/managerequest/assignStaffToRequest`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  getStaffForDDL: async function (type) {
    const options = { headers: await authHeader() };
    const params = { type: type };
    const url = `/managerequest/getStaffForDDL`;
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  getAgentsforDDL: async function (status) {
    const options = { headers: await authHeader() };
    const params = { status };
    const url = `/managerequest/getAgentsForDDL`;
    return Fetch.get(url, params, options).then((res) => res.json());
  },

  saveFileUploadNotes: async function (requestID, notes) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(notes),
    };
    const params = { requestID };
    const url = `/managerequest/UpdateNotes`;
    return Fetch.update(url, params, options).then(handleResponse);
  },

  sendAgentPageOpenNotice: async function (requestID) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(requestID),
    };
    const params = {};
    const url = `/managerequest/sendAgentPageOpenNotice`;
    return Fetch.post(url, params, options).then(handleResponse);
  },

  quickSigningUpdate: async function (requestID, data) {
    const options = {
      headers: await authHeader(),
      body: JSON.stringify(data),
    };
    const params = { requestID };
    const url = `/managerequest/quickSigningUpdate`;
    return Fetch.update(url, params, options).then(handleResponse);
  },
};

export { manageRequestService };
