import React, { useContext, useState, useEffect, useRef } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { LinkedIn, Facebook, Twitter } from '@mui/icons-material';
import SaveOutlinedIcon from '@mui/icons-material/Save';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import LoadingButton from '@mui/lab/LoadingButton';
import { AntSwitch } from '../../Style';
import { UserProfileContext } from '../../context/user-profile.context';
import { Favorite, FavoriteBorderOutlined } from '@mui/icons-material';
import { ServiceOwnerData } from './ServiceOwnerData';
import { store } from '../../store';
import AgentProfile from './AgentProfile';
import CustomerProfile from './CustomerProfile';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import AppConfirmationDialog from '../common/others/AppConfirmationDialog';
import { userService } from '../../services';
import Loading from '../common/others/Loading';
import { appColors } from '../../theme/AppThemes';
import UserProfileAdminUpdateDialog from './UserProfileAdminUpdateDialog';

const proMemberIcon = require('../../assets/images/ico_proStatus.png');

const StyledIndex = styled('div')(
  ({ theme, $priColor, $fixedIntro }) => css`
    .very-top {
      margin-top: 5px;
    }

    .very-top.fixed {
      top: 45px;
      position: sticky;
      z-index: 20;
      background-color: ${theme.palette.nsAppBG.fixedBG};
      padding-top: 10px;
      padding-bottom: 10px;
      margin: -2.5px;
      width: 100.5%;
    }

    .avatar-intro.fixed {
      display: flex;
    }

    .membership-admin {
      padding: 15px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .service-owner {
      width: 100%;
      height: 20%;
      margin-top: 10px;
    }

    .avatar-intro {
      padding: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      padding-left: 8px;
      width: 100%;
      height: 10%;
      position: relative;
      border-radius: 5px;
      border: 1px solid ${theme.palette.mode == 'dark' ? 'black' : theme.palette.primary.main};
      background-color: ${theme.palette.mode == 'dark'
        ? theme.palette.background.paper
        : appColors[$priColor].palette.border.main};

      ${theme.breakpoints.down('sm')} {
        display: block;
        justify-content: center;
      }

      .avatar-div {
        display: flex;
        flex-grow: 0;
        align-items: center;

        ${theme.breakpoints.down('sm')} {
          display: block;
          text-align: center;
          justify-content: center;
        }

        .MuiAvatar-root {
          width: 110px;
          height: 110px;
          border: 4px solid ${theme.palette.mode == 'dark' ? theme.palette.border.main : theme.palette.primary.main};
          color: ${theme.palette.primary.main};
          background-color: ${theme.palette.mode == 'dark'
            ? theme.palette.background.default
            : appColors[$priColor].palette.border.main};
        }

        .icon-row {
          inline-size: min-content;
          margin-left: 30px;
          line-height: 0px;
        }

        ${theme.breakpoints.down('sm')} {
          .MuiAvatar-root {
            width: 100px;
            height: 100px;
          }

          .icon-row {
            inline-size: auto;
            margin-left: 0px;
          }
        }
      }

      .contact-info {
        flex: 2;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 5px 5px 5px 12px;
        padding-left: ${$fixedIntro ? '20px' : 'inherit'};

        h5 {
          color: ${theme.palette.mode == 'dark' ? '#31889d' : '#276b7b'};
        }

        .row {
          display: flex;
          flex-direction: row;
        }

        ${theme.breakpoints.down('sm')} {
          align-items: ${$fixedIntro ? 'flex-start' : 'center'};
        }

        ${theme.breakpoints.up('sm')} {
          .zip-codes {
            display: none;
          }
        }
      }

      .other-info {
        align-self: normal;
        padding: 5px;
        text-align: right;

        ${theme.breakpoints.down('sm')} {
          .zip-codes {
            display: none;
          }

          .edit-buttons {
            top: ${$fixedIntro ? 'auto' : '10px'};
            bottom: ${$fixedIntro ? '10px' : 'auto'};
          }
        }
      }

      ${theme.breakpoints.down('sm')} {
        .other-info {
          padding: 0px;
        }

        .other-info > :not(.edit-buttons) {
          display: none;
        }
      }

      .favorite-rating {
        display: flex;
        flex-grow: 1;
        padding: 4px;
        justify-content: end;

        .favorite {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }

        .rating {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }

      .edit-buttons {
        gap: 8px;
        display: flex;
        justify-content: center;
        ${theme.breakpoints.up('sm')} {
          position: absolute;
        }
        bottom: 10px;
        right: 10px;
      }

      .bigIntroHideContent {
        overflow: hidden;
        line-height: 1.25em;
        height: 5em;

        .showMore {
          color: ${theme.palette.secondary.main};
        }
      }

      .bigIntroHideContent {
        overflow: hidden;
        line-height: 1.25em;
        height: 5em;

        .showMore {
          color: ${theme.palette.secondary.main};
        }
      }

      .showContent {
        line-height: 1em;
        height: auto;
      }
    }

    .main {
      position: relative;

      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 5px;

        > div:nth-child(1) {
          align-self: flex-end;
        }
      }
    }
  `
);

const SmallAvatar = styled(Avatar)(
  ({ theme }) => css`
    width: 50px !important;
    height: 22px !important;
    border-radius: 7px !important;
    box-shadow: 1px 1px 1px !important;
    border: 2px solid ${theme.palette.background.paper} !important;

    img {
      object-fit: cover;
      top: 0.5px;
    }
  `
);

const Profile = (props) => {
  const mountRef = useRef(true);
  const history = useHistory();
  const {
    fromManager,
    loading: gettingData,
    profileType,
    profileId,
    profileInfo,
    createProfile,
    updateProfile,
    deleteProfile,
    updatedProfile,
    ownerData,
    setOwnerData,
    getFullProfile,
    lookupList,
    serviceEditRights,
    toggleAgentInPersonalList,
    mapElementFreeText,
    checkIfStateHasChanges,
  } = useContext(UserProfileContext);

  const [showFixed, setShowFixed] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const [loading, setLoading] = useState({ cancel: false, save: false });
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [rating, setRating] = useState(0);
  const [confirmContent, setConfirmContent] = useState(false);
  const [bigIntroTruncated, setBigIntroTruncated] = useState(true);
  const [bigIntroState, setBigIntroState] = useState(() => {
    return profileInfo.Descriptions?.[0]?.text?.slice(0, 500);
  });
  const [openProfileAdminUpdate, setOpenProfileAdminUpdate] = useState(false);

  const profileImage = `https://notarycafe.com/Content/profile/${profileInfo.Entities?.[0].publicId}_1.png`;

  const { userRoles, detail } = store.getState().reducer.currentUser;
  const { customColor: appColor } = store.getState().reducer.themeAppearance;
  const viewport = store.getState().reducer.appViewport;

  useEffect(() => {
    if (profileId == 'new' && (userRoles.includes('SuperUser') || userRoles.includes('ServiceOwner'))) {
      setEdit(true);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (profileType === 'agent') {
      setRating(_.cloneDeep(ownerData.CustomizedData?.rating));
    }
    return () => {};
  }, [ownerData]);

  useEffect(() => {
    const onScroll = (e) => {
      const newShowFixed = window.scrollY > 10;
      showFixed !== newShowFixed && setShowFixed(newShowFixed);
    };

    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [showFixed]);

  const proMemberIconRender = () => {
    return profileInfo.PaidMemberships?.[0]?.isMember ? (
      <Tooltip title="To edit pro member items, visit your NotaryCafe profile.">
        <SmallAvatar alt="pro" src={proMemberIcon} />
      </Tooltip>
    ) : (
      ''
    );
  };

  const proMemberBadge = () => {
    if (proMemberIconRender()) {
      return (
        <Badge
          sx={{ '.MuiBadge-badge': { p: 0 } }}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={proMemberIconRender()}
        >
          <Avatar src={profileImage} />
        </Badge>
      );
    } else return <Avatar src={profileImage} />;
  };

  const handleLongIntroToggle = (event) => {
    if (bigIntroState?.length > 500) {
      setBigIntroTruncated(true);
      setBigIntroState(profileInfo.Descriptions?.[0]?.text?.slice(0, 500));
    } else {
      setBigIntroTruncated(false);
      setBigIntroState(profileInfo.Descriptions?.[0]?.text);
    }
  };

  const handleLinks = (linkName) => {
    let link = profileInfo.Links?.find((link) => link.lookupName.toLowerCase() == linkName)?.url;
    if (link) {
      return link.includes('http') ? link : `https://${link}`;
    }
    return '';
  };

  const twitter = handleLinks('twitter') || '';
  const facebook = handleLinks('facebook') || '';
  const linkedin = handleLinks('linkedin') || '';

  const handleDialogConfirmation = async (event) => {
    // not the best to handle with confirmContent.includes() but this's what I found.
    if (confirmContent.includes('rating')) {
      if (event) {
        let temp = _.cloneDeep(ownerData.CustomizedData);
        temp.rating = event ? rating : temp.rating;
        setOwnerData({ ...ownerData, CustomizedData: temp });
        await updateProfile({ ...updatedProfile, CustomizedData: temp });
        setConfirm(false);
      } else setConfirm(false);
    } else if (confirmContent.includes('personal')) {
      if (event) {
        let temp = _.cloneDeep(ownerData.ElementFreeTexts);
        let index = temp.map((object) => object.element).indexOf(100);
        temp[index].value = event ? !temp[index].value : temp[index].value;
        setOwnerData({ ...ownerData, ElementFreeTexts: temp });
        await toggleAgentInPersonalList(temp[index].value ? true : false);
        setConfirm(false);
      } else setConfirm(false);
    } else if (confirmContent.includes('delete')) {
      if (event) {
        await deleteProfile();
        setConfirm(false);
        history.push(`/contact-manager`);
      } else setConfirm(false);
    }
  };

  const workEmail = profileInfo.Emails ? profileInfo.Emails.find((email) => email.lookupName === 'Work')?.address : '';

  const workPhone = profileInfo.PhoneNumbers
    ? profileInfo.PhoneNumbers.find((phone) => phone.lookupName === 'Work')?.number
    : '';

  const workAddress = () => {
    if (profileInfo.Addresses) {
      let obj = profileInfo.Addresses.find((address) => address.name?.toLowerCase().includes('billing'));
      if (['xs'].includes(viewport)) {
        return (
          <>
            <p>{obj.street}</p>
            {obj.line2 && <p>{obj.line2}</p>}
            <p>
              {obj.city}, {obj.state} {obj.zip}
            </p>
          </>
        );
      } else return `${obj.street}${obj.line2 ? ', ' + obj.line2 : ''}, ${obj.city}, ${obj.state} ${obj.zip}`;
    } else return '';
  };

  const cancelHandler = async () => {
    if (profileId == 'new') {
      history.push(`/contact-manager`);
    } else {
      setLoading({ ...loading, cancel: true });
      await getFullProfile(lookupList);
      store.dispatch({ type: 'SET_PAGE_IN_EDIT_MODE', payload: false });
      setEdit(false);
    }
    setLoading({ ...loading, cancel: false });
  };

  const updateClickHandler = async (event) => {
    setLoading({ ...loading, save: true });
    setDisabled(false);

    const cleanEditMode = () => {
      store.dispatch({ type: 'SET_PAGE_IN_EDIT_MODE', payload: false });
      setEdit(false);
    };

    if (event.toLowerCase() == 'new') {
      await createProfile(updatedProfile).then((res) => {
        if (res) cleanEditMode();
      });
    } else
      await updateProfile(updatedProfile).then((res) => {
        if (res) cleanEditMode();
      });

    setLoading({ ...loading, save: false });
  };

  const canEditHandler = (userType) => {
    switch (userType) {
      case 'customer':
        let check1 = userRoles.includes('SuperUser') || userRoles.includes('Customer');
        let check2 = (serviceEditRights && userRoles.includes('ServiceOwner')) || userRoles.includes('Customer');
        let check3 = profileId == 'new' && (userRoles.includes('ServiceOwner') || userRoles.includes('Customer'));

        if ((edit || profileId == 'new') && (check1 || check2 || check3)) {
          return true;
        } else return false;

      case 'agent':
        // let agentCheck1 =
        //   userRoles.includes('SuperUser') || userRoles.includes('NotaryAgent');
        // let agentCheck2 =
        //   (serviceEditRights && userRoles.includes('ServiceOwner')) ||
        //   userRoles.includes('NotaryAgent');

        // if (edit && (agentCheck1 || agentCheck2)) {
        //   return true;
        // } else return false;
        return edit; // Temporarily
      default:
        break;
    }
  };

  return (
    <StyledIndex $priColor={appColor} $fixedIntro={showFixed}>
      {Object.keys(profileInfo).length > 0 || profileId == 'new' ? (
        <>
          <div className={`${showFixed ? 'very-top fixed' : 'very-top'}`}>
            {!showFixed || ['sm', 'md', 'lg', 'xl'].includes(viewport) ? (
              <div className="avatar-intro">
                <div className="avatar-div">
                  {profileType === 'agent' && (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>{proMemberBadge()}</div>
                      {profileInfo.PaidMemberships?.[0]?.isMember && (
                        <div className="icon-row">
                          {facebook && (
                            <a href={facebook} target="_blank">
                              <Facebook sx={{ color: '#1877f2' }} fontSize={'large'} />
                            </a>
                          )}
                          {twitter && (
                            <a href={twitter} target="_blank">
                              <Twitter sx={{ color: '#1D9BF9' }} fontSize={'large'} />
                            </a>
                          )}
                          {linkedin && (
                            <a href={linkedin} target="_blank">
                              <LinkedIn sx={{ color: '#0073b1' }} fontSize={'large'} />
                            </a>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {profileId == 'new' ? (
                  <div className="contact-info">
                    <Typography variant="h5" fontWeight={'bold'}>
                      {!(profileInfo.Entities?.[0].lastName + profileInfo.Entities?.[0].firstName)
                        ? 'New Customer'
                        : profileInfo.Entities?.[0].lastName + ', ' + profileInfo.Entities?.[0].firstName}
                    </Typography>
                  </div>
                ) : (
                  <div className="contact-info">
                    <Typography variant="h5" fontWeight={'bold'}>
                      {profileInfo.Entities?.[0].lastName + ', ' + profileInfo.Entities?.[0].firstName}
                    </Typography>
                    <div className="row">
                      <Typography variant="subtitle1" fontWeight={'bold'}>
                        {profileInfo.Entities?.[0].parentName}
                      </Typography>
                    </div>
                    <div className="row">
                      <Typography variant="body2" fontWeight={'bold'}>
                        {'Billing:'}
                      </Typography>
                      <Typography component="div">{workAddress()}</Typography>
                    </div>
                    <div className="row">
                      <Typography variant="body2" fontWeight={'bold'}>
                        {'Work:'}
                      </Typography>
                      <Typography variant="body2">{workPhone}</Typography>
                    </div>
                    <div className="row">
                      <Typography variant="body2">
                        <a href={`mailto:${workEmail}`}>{workEmail}</a>
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="other-info">
                  {profileType === 'agent' && (
                    <>
                      {profileInfo.PaidMemberships?.[0]?.isMember && (
                        <div className="zip-codes">
                          <Typography variant="subtitle2" fontWeight={'bold'}>
                            {'Additional Zipcodes:'}
                          </Typography>
                          {profileInfo.ServiceLocations?.map((obj, index) => (
                            <span key={index}>
                              {obj.zipCode}
                              {profileInfo.ServiceLocations.length - 1 != index ? ', ' : ''}
                            </span>
                          ))}
                        </div>
                      )}
                      {!userRoles.includes('NotaryAgent') && (
                        <div className="favorite-rating">
                          <div className="rating">
                            <Rating
                              size="large"
                              max={5}
                              value={ownerData.CustomizedData?.rating || 0}
                              onChange={(e) => {
                                setRating(parseInt(e.target.value));
                                setConfirm(true);
                                setConfirmContent(`Do you want to change this Agent's rating to ${e.target.value}`);
                              }}
                            />
                          </div>
                          <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: '10px' }} />
                          <div className="favorite">
                            {mapElementFreeText(100, true)?.value ? (
                              <Favorite
                                color="danger"
                                fontSize="large"
                                onClick={() => {
                                  setConfirm(true);
                                  setConfirmContent('Do you want to take this agent off your personal list?');
                                }}
                              />
                            ) : (
                              <FavoriteBorderOutlined
                                color="danger"
                                fontSize="large"
                                onClick={() => {
                                  setConfirm(true);
                                  setConfirmContent('Do you want to add this agent to your personal list?');
                                }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <br />
                  {!userRoles.includes('NotaryAgent') && (
                    <>
                      {edit ? (
                        <div className="edit-buttons">
                          <LoadingButton
                            loading={loading.cancel}
                            endIcon={<CloseOutlinedIcon />}
                            onClick={cancelHandler}
                          >
                            Cancel
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            loading={loading.save}
                            endIcon={<SaveOutlinedIcon />}
                            onClick={() => updateClickHandler(profileId)}
                          >
                            {profileId == 'new' ? 'Save' : 'Update'}
                          </LoadingButton>
                        </div>
                      ) : (
                        <div className="edit-buttons">
                          {userRoles.includes('ServiceOwner') && profileType === 'customer' && (
                            <Button
                              color="error"
                              sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
                              size="small"
                              variant="contained"
                              onClick={(e) => {
                                setConfirm(true);
                                setConfirmContent('Are you sure you want to delete this contact?');
                              }}
                            >
                              Delete Profile
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            disabled={loading.cancel}
                            onClick={() => {
                              userService.refreshToken();
                              setEdit(true);
                              setDisabled(true);
                              store.dispatch({
                                type: 'SET_PAGE_IN_EDIT_MODE',
                                payload: true,
                              });
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="avatar-intro fixed">
                <div className="avatar-div">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>{proMemberBadge()}</div>
                </div>
                <div className="contact-info">
                  <Typography variant="h5" fontWeight={'bold'}>
                    {profileInfo.Entities?.[0].lastName + ', ' + profileInfo.Entities?.[0].firstName}
                  </Typography>
                  <div className="row">
                    <Typography variant="subtitle1" fontWeight={'bold'}>
                      {profileInfo.Entities?.[0].parentName}
                    </Typography>
                  </div>
                  <div className="row">
                    <Typography variant="body2" fontWeight={'bold'}>
                      {'Work:'}
                    </Typography>
                    <Typography variant="body2">{workPhone}</Typography>
                  </div>
                  <div className="row">
                    <Typography variant="body2">
                      <a href={`mailto:${workEmail}`}>{workEmail}</a>
                    </Typography>
                  </div>
                </div>
                <div className="other-info">
                  {!userRoles.includes('NotaryAgent') && (
                    <>
                      {edit ? (
                        <div className="edit-buttons">
                          <LoadingButton
                            loading={loading.cancel}
                            endIcon={<CloseOutlinedIcon />}
                            onClick={cancelHandler}
                          >
                            Cancel
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            loading={loading.save}
                            endIcon={<SaveOutlinedIcon />}
                            onClick={() => updateClickHandler(profileId)}
                          >
                            {profileId == 'new' ? 'Save' : 'Update'}
                          </LoadingButton>
                        </div>
                      ) : (
                        <div className="edit-buttons">
                          {userRoles.includes('ServiceOwner') && profileType === 'customer' && (
                            <Button
                              color="error"
                              sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
                              size="small"
                              variant="contained"
                              onClick={(e) => {
                                setConfirm(true);
                                setConfirmContent('Are you sure you want to delete this contact?');
                              }}
                            >
                              Delete Profile
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            onClick={() => {
                              userService.refreshToken();
                              setEdit(true);
                              setDisabled(true);
                              store.dispatch({
                                type: 'SET_PAGE_IN_EDIT_MODE',
                                payload: true,
                              });
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {userRoles.includes('SuperUser') && profileType === 'agent' && (
            <Card className="membership-admin" sx={{ display: 'block !important' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ color: '#266b7b', fontWeight: 600 }}>Admin Options</Typography>
                <div>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={(e) =>
                      setOpenProfileAdminUpdate({
                        profileId: profileId || '',
                        memberShip: profileInfo.PaidMemberships?.[0],
                        account: ownerData.Entities?.[0],
                      })
                    }
                  >
                    Profile Admin Update
                  </Button>
                </div>
              </div>
              <p>
                <strong>Member Status: </strong>
                {profileInfo.PaidMemberships?.[0]?.isMember ? (
                  <span>{`Member Until ${profileInfo.PaidMemberships?.[0]?.expiration || 'Unknown'}. ${
                    profileInfo.PaidMemberships?.[0]?.daysLeft || 0
                  } days left.`}</span>
                ) : (
                  <span>Not a member</span>
                )}
              </p>
            </Card>
          )}
          <div className="service-owner">
            {(userRoles.includes('SuperUser') || userRoles.includes('ServiceOwner')) && (
              <ServiceOwnerData editMode={edit} canEdit={edit} />
            )}
          </div>
          {profileType === 'agent' && (
            <>
              {userRoles.includes('NotaryAgent') && (
                <Alert sx={{ marginTop: '10px' }} variant="filled" severity="warning">
                  {
                    <>
                      Please visit&nbsp;
                      <Link
                        sx={{ fontWeight: 600 }}
                        target="_blank"
                        href="https://notarycafe.com/Account/LogOn?ReturnUrl=%2Fprofile"
                        color="inherit"
                      >
                        NotaryCafe.com
                      </Link>
                      &nbsp;to update your profile.
                    </>
                  }
                </Alert>
              )}
              <Card sx={{ marginTop: '10px' }}>
                <CardHeader
                  sx={{ color: '#266b7b', fontWeight: 600 }}
                  disableTypography={true}
                  subheader="Professional Introduction & Experience"
                ></CardHeader>
                <CardContent sx={{ paddingTop: edit ? '5px' : '16px' }}>
                  <div className="bigIntroContent bigIntroHideContent">
                    {bigIntroState || profileInfo.Descriptions?.[0]?.text?.slice(0, 500)}
                    &nbsp;&nbsp;
                    <>
                      {profileInfo.Descriptions?.[0]?.text.length > 500 && (
                        <>
                          {bigIntroTruncated ? (
                            <Link
                              className="showMore"
                              component={'button'}
                              varient="text"
                              underline="none"
                              onClick={(e) => handleLongIntroToggle(e)}
                            >
                              ...show more
                            </Link>
                          ) : (
                            <Link
                              className="showMore"
                              varient="text"
                              component={'button'}
                              underline="none"
                              onClick={(e) => handleLongIntroToggle(e)}
                            >
                              show less
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  </div>
                </CardContent>
              </Card>
            </>
          )}
          <div className="main">
            <div>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption">Collapsed</Typography>
                <AntSwitch
                  checked={isAllExpanded}
                  onChange={() => setIsAllExpanded(!isAllExpanded)}
                  inputProps={{ 'aria-label': 'ant design' }}
                />
                <Typography variant="caption">Expanded</Typography>
              </Stack>
            </div>
            <Prompt
              when={checkIfStateHasChanges() ? true : false}
              message={JSON.stringify({
                open: true,
                msg: '',
              })}
            />
            {profileType === 'customer' && (
              <CustomerProfile
                expandAll={isAllExpanded}
                editMode={edit || profileId == 'new'}
                canEdit={canEditHandler('customer')}
                disabled={!canEditHandler('customer')}
              />
            )}
            {profileType === 'agent' && (
              <AgentProfile
                expandAll={isAllExpanded}
                // editMode={edit}
                // canEdit={canEditHandler('agent')}
                // disabled={!canEditHandler('agent')}
                editMode={edit}
                canEdit={false}
                disabled={true}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="avatar-intro">
            {profileType === 'agent' && (
              <div className="avatar-div">
                <div style={{ display: 'flex', justifyContent: 'center' }}>{proMemberBadge()}</div>
              </div>
            )}
            <div className="contact-info">
              <Typography variant="h5" fontWeight={'bold'}>
                {fromManager ? props.fullName : detail.lastName + ', ' + detail.firstName}
              </Typography>
              <div className="row">
                {fromManager ? (
                  <Typography variant="subtitle1" fontWeight={'bold'}>
                    {props.company?.replace('<br>', '')}
                  </Typography>
                ) : (
                  <Skeleton variant="h2" width={300} animation="wave" sx={{ fontSize: '1rem' }} />
                )}
              </div>
              <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" width={200} sx={{ fontSize: '1rem' }} />
            </div>
          </div>
          {gettingData && <Loading forList msg="Getting profile data..." />}
        </>
      )}
      {confirm && (
        <AppConfirmationDialog
          id="appConfirmationDialog"
          open={confirm}
          title={'Are you sure?'}
          content={confirmContent}
          buttonTrueText={'Yes'}
          buttonFalseText={'No'}
          onClose={(e) => handleDialogConfirmation(e)}
        />
      )}
      {openProfileAdminUpdate && (
        <UserProfileAdminUpdateDialog
          open={openProfileAdminUpdate ? true : false}
          state={openProfileAdminUpdate}
          buttonTrueText={'Update'}
          buttonFalseText={'Close'}
          onClose={(val) => {
            cancelHandler();
            setOpenProfileAdminUpdate(false);
          }}
        />
      )}
    </StyledIndex>
  );
};

export default Profile;
