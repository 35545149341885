import React, { Fragment, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { deepmerge } from '@mui/utils';

import { appThemes, appColors } from './theme/AppThemes';
import { userService } from './services';
import { GlobalStyle } from './GlobalStyles';
import AppContent from './AppContent';
import AppError from './AppError';
import AppTopBar from './components/layout/AppTopBar';
import AppConfirmationDialog from './components/common/others/AppConfirmationDialog';
import { getJsonOr } from './helpers';
import { ModalProvider } from './context/modal.store';
import { useDirtyChangesPrompt } from './helpers/hooks/useDirtyChangesPrompt';
import { useDirtyChangesStore } from './context/dirty-changes.store';

const App = ({ store: store, ...props }) => {
  const { accessToken, currentUser, isPageInEditMode, themeAppearance, promptState } = store;

  const history = useHistory();

  const [theme, setTheme] = useState(useTheme());
  const [onServerError, setOnServerError] = useState(false);
  const [confirm, setConfirm] = useState({
    open: false,
    title: 'Are you sure?',
    msg: 'You have unsaved changes, are you sure you want to leave?',
    trueEventText: 'Yes',
    falseEventText: 'No',
  });
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [editModeExpiration, setEditModeExpiration] = useState(false);

  const { promptDirtyChanges } = useDirtyChangesPrompt();
  const hasDirtyChanges = useDirtyChangesStore((s) => s.isDirty);

  useLayoutEffect(() => {
    setTheme(createTheme(deepmerge(appThemes[themeAppearance.customTheme], appColors[themeAppearance.customColor])));
  }, [themeAppearance]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'text/plain' },
    };

    fetch(`${SERVICE_URL}/portal`, requestOptions)
      .then((response) => {
        return response.text().then(function (text) {
          const data = text && JSON.parse(text);
          localStorage.setItem('NSPortalContext', JSON.stringify(data));
          localStorage.setItem('NSCurrentPortalID', data.portalID);

          props.dispatch({
            type: 'SET_GLOBALS',
            payload: {
              isAuthenticated: currentUser.detail ? true : false,
              portalID: data.portalID,
              superUserId: data.superUserId,
              logoFile: data.logoFile,
              portalName: data.portalName,
              email: data.email,
              portalAliasHashed: data.portalAliasHashed,
            },
          });

          props.dispatch({ type: 'SET_CURRENT_PORTAL_ID', payload: data.portalID });

          // refreshToken is being called to make sure the new token will be
          // attach in the API calls header. This only happens once, when the app is mounted
          if (currentUser.detail && !accessToken.onLogin) {
            userService.refreshToken().then((res) => {
              if (!res) history.push('/login');
            });
          }
        });
      })
      .catch((error) => {
        setOnServerError(true);
      });

    return () => {};
  }, [currentUser]);

  // ************ IMPORTANT **********
  // Code below will check for the token expiration
  // will logout the user when the token expire

  useEffect(() => {
    let editModeInterval;
    let noEditModeInterval;
    let isPageInEditModeCheck;

    if (isPageInEditMode) {
      editModeInterval = setInterval(() => {
        isPageInEditModeCheck = store.isPageInEditMode;
        if (isPageInEditModeCheck) {
          clearInterval(noEditModeInterval); // clear the non edit mode interval
        }
        let timeInSeconds = userService.tokenTimeRemaining();
        // Show alert 2 minutes before timing out
        if (parseInt(timeInSeconds) == 120) {
          setTimeout(() => {
            props.dispatch({ type: 'SET_SHOW_EDIT_ALERT_MODAL', payload: true });
          }, timeInSeconds);
        }
        if (timeInSeconds === 0) clearInterval(editModeInterval);
      }, 1000);
    }
    return () => {
      clearInterval(editModeInterval);
      clearInterval(noEditModeInterval);
    };
  }, [accessToken.token, isPageInEditMode]);

  let viewport = undefined;
  if (theme) {
    if (useMediaQuery(theme.breakpoints.only('xs'))) viewport = 'xs';
    if (useMediaQuery(theme.breakpoints.only('sm'))) viewport = 'sm';
    if (useMediaQuery(theme.breakpoints.only('md'))) viewport = 'md';
    if (useMediaQuery(theme.breakpoints.only('lg'))) viewport = 'lg';
    if (useMediaQuery(theme.breakpoints.only('xl'))) viewport = 'xl';

    if (useMediaQuery(theme.breakpoints.between('900', '1050'))) viewport = 'xsm';
  }

  useEffect(() => {
    if (currentUser.detail) {
      props.dispatch({ type: 'SET_APP_VIEWPORT', payload: viewport });
      switch (viewport) {
        case 'xs':
        case 'sm':
          props.dispatch({ type: 'SET_MENU_STATE', payload: 'hidden' });
          break;
        case 'xsm':
          props.dispatch({ type: 'SET_MENU_STATE', payload: 'icons' });
          break;
        default:
          props.dispatch({ type: 'SET_MENU_STATE', payload: 'expanded' });
          break;
      }
    }
    return () => {};
  }, [viewport, currentUser.detail]);

  const getConfirmation = (message, callback) => {
    let _confirm = getJsonOr(message) || confirm;

    promptDirtyChanges(
      {
        ...confirm,
        title: _confirm.title || confirm.title,
        msg: _confirm.msg || confirm.msg,
        switchBtn: _confirm.switchBtn || false,
        trueEventText: _confirm.trueEventText || confirm.trueEventText,
        falseEventText: _confirm.falseEventText || confirm.falseEventText,
      },
      callback,
      _confirm.open
    );
  };

  const timeRemainingForEdition = () => {
    return { remainingTime: userService.tokenTimeRemaining() };
  };

  const showEditAlertModal = useSelector((state) => state.reducer.showEditAlertModal || false);

  useEffect(() => {
    if (showEditAlertModal) {
      setEditModeExpiration(true);
    } else {
      setEditModeExpiration(false);
    }
    return () => {
      setEditModeExpiration(false);
    };
  }, [showEditAlertModal]);

  const keepUserSigned = (event) => {
    if (event === 'timerIsDone' || !event) {
      props.dispatch({
        type: 'SET_SHOW_EDIT_ALERT_MODAL',
        payload: false,
      });
      userService.logout().then((re) => {
        history.push('/login'); // This will redirect to login because of the route setting in the app
      });
    } else {
      props.dispatch({
        type: 'SET_SHOW_EDIT_ALERT_MODAL',
        payload: false,
      });
      userService.refreshToken();
    }
  };

  useEffect(() => {
    if (promptState === 'destroyed') {
      setConfirm({ ...confirm, open: false });
    }

    return () => {};
  }, [promptState]);

  return (
    <Fragment>
      {onServerError ? (
        <ThemeProvider theme={theme}>
          <AppError />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <BrowserRouter getUserConfirmation={getConfirmation}>
            <CssBaseline />
            <GlobalStyle theme={theme} />
            <AppTopBar />

            {/* MAIN APP CONTENT BEGIN */}
            <AppContent />
            <ModalProvider />
            {/* MAIN APP CONTENT END */}

            {editModeExpiration && (
              <AppConfirmationDialog
                id="appConfirmationDialog"
                open={editModeExpiration}
                title={'Need More Time?'}
                content={'Your session is about to expire. You will be automatically signed out in'}
                buttonTrueText={'Stay signed in'}
                buttonFalseText={'Sign out'}
                buttonTrueOnLeft={true}
                onClose={(e) => keepUserSigned(e)}
                addTokenTimer={true}
                value={timeRemainingForEdition()}
              />
            )}
          </BrowserRouter>
        </ThemeProvider>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({ store: state.reducer });
export default connect(mapStateToProps)(App);
