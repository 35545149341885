import React from 'react';

import { css } from 'styled-components';
import { styled } from '@mui/material/styles';

const LoaderStyle = styled('div')(
  ({ theme }) => css`
    .Loader {
      margin: 5rem auto;
      border: 8px solid #f2f2f2;
      border-top: 8px solid #f37928;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1.5s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
);

const Loader = () => {
  return <LoaderStyle></LoaderStyle>;
};

export default Loader;
