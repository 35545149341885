export const TRANSLATIONS_ES = {
  global: {
    ns__state: 'Estado',
  },
  signingRequest: {
    ns__borrowerFirstName: 'Nombre del Prestatario',
    ns__borrowerLastName: 'Apellido del Prestatario',
    ns__coBorrowerFirstName: 'Nombre del Co-Prestatario',
    ns__coBorrowerLastName: 'Apellido del Co-Prestatario',
    ns__signingAddress1: 'Dirección del Prestatario 1',
    ns__signingAddress2: 'Dirección del Prestatario 2',
    ns__signingCity: 'Ciudad',
    ns__signingState: 'Estado',
    ns__signingZipCode: 'Codigo Postal',
    ns__dayPhone: 'Telefono de Día',
    ns__eveningPhone: 'Telefono de Tarde',
    ns__otherPhone: 'Telefono Alternativo',
    ns__otherContactInfo: 'Informacion Adicional',
  },
};
