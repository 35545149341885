import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import { Card, Collapse, IconButton, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

import ShippingCompany from '../../common/ddls/ShippingCompany';
import { SigningRequestContext } from '../../../context/signing-request.context';
import { NSTimePicker } from '../../common/pickers/NSTimePicker';
import { NSDatePicker } from '../../common/pickers/NSDatePicker';
import { HtmlTooltip } from '../../../Style';
import { parseStringTime } from '../../../helpers';

const RootCard = styled(Card)(
  ({ theme }) => css`
    padding: 0px;
    background-color: transparent;

    ${theme.breakpoints.down('sm')} {
      padding: 10px !important;
    }

    .inputs-layout {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 700px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .desktop-tab {
        @media screen and (max-width: 550px) {
          display: none;
        }
      }

      .mobile-tab {
        @media screen and (min-width: 550px) {
          display: none;
        }
      }

      .package-2 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }
    }
  `
);

const CompletionInfoDesk = (props) => {
  const {
    _________________fake,
    pageMode,
    signingRequest,
    setSigningRequest,
    completionOptions,
    setCompletionOptions,
  } = useContext(SigningRequestContext);

  const [viewMode, setViewMode] = useState('view');
  const [selectedOption, setSelectedOption] = useState('');
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (signingRequest.signingStatusID == 4 && signingRequest.scanBackRequired) setViewMode('view');
    else setViewMode(pageMode);
    return () => {};
  }, [signingRequest.signingStatusID, pageMode]);

  useEffect(() => {
    const foundObject = completionOptions.find((item) => item.selected === true);
    setSelectedOption(typeof foundObject !== 'undefined' ? foundObject.text : '');
    return () => {};
  }, [completionOptions]);

  const handleCompletionResultChange = (event) => {
    let newVal = event.target.value;
    if (newVal === selectedOption) {
      setSelectedOption('');
    } else {
      setSelectedOption(newVal);
    }

    const newList = completionOptions.map((item) => {
      if (item.text === newVal) {
        item.selected = newVal === selectedOption ? false : true;
      } else {
        item.selected = false;
      }
      return item;
    });

    setCompletionOptions(newList);

    setTimeout(() => {
      setSigningRequest({
        ...signingRequest,
        completionOptions: newList,
      });
    }, 500);
  };

  const disableHandler = (isEnabled = true) => {
    if (!isEnabled) return true;
    return viewMode === 'view' ? true : false;
  };

  const handleExpandedPackage = () => {
    setExpanded(!expanded);
  };

  return (
    <RootCard variant={''}>
      <div className="section-title-with-info">
        <div>Completion Information</div>
        <div>
          <HtmlTooltip arrow title={`To be completed by the Notary Agent after the completion of thesigning.`}>
            <IconButton>
              <InfoOutlinedIcon color="info" fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        </div>
      </div>

      <div className="inputs-layout">
        <div className="left-section">
          <NSDatePicker
            label="Appointment Date"
            disabled={disableHandler()}
            required={true}
            error={!signingRequest.completionDate}
            date={signingRequest.completionDate}
            emitDate={(date) => {
              setSigningRequest({
                ...signingRequest,
                completionDate: date,
              });
            }}
          />
          <NSTimePicker
            getShortTime
            label={'Actual Signing Time'}
            disabled={disableHandler()}
            required={selectedOption.includes('Complete')}
            error={!parseStringTime(signingRequest.completionTime) && selectedOption.includes('Complete')}
            time={signingRequest.completionTime}
            emitTime={(time) => {
              setSigningRequest({
                ...signingRequest,
                completionTime: time,
              });
            }}
            helperText={
              signingRequest.completionTime
                ? parseStringTime(signingRequest.completionTime) !== null
                  ? ''
                  : 'Legacy notes: ' +
                    signingRequest.completionTime +
                    '. This will be overrode once a valid time is submitted.'
                : ''
            }
          />
          <NSDatePicker
            label="Date Doc's Returned"
            disabled={disableHandler()}
            required={selectedOption.includes('Complete')}
            error={selectedOption.includes('Complete') && !signingRequest.completionReturnedDate}
            date={signingRequest.completionReturnedDate}
            emitDate={(date) =>
              setSigningRequest({
                ...signingRequest,
                completionReturnedDate: date,
              })
            }
          />
          <p className="comp-input-notes">Package #1</p>
          <ShippingCompany
            disabled={disableHandler()}
            required={selectedOption.includes('Complete')}
            error={selectedOption.includes('Complete') && !signingRequest.completionReturnedVia}
            value={signingRequest.completionReturnedVia || ''}
            ddlOnChange={(value) =>
              setSigningRequest({
                ...signingRequest,
                completionReturnedVia: value,
              })
            }
          />
          <TextField
            id="ns__completionTrackingNumber"
            label="Tracking Number"
            disabled={disableHandler()}
            error={selectedOption.includes('Complete') && !signingRequest.completionTrackingNumber}
            required={selectedOption.includes('Complete')}
            value={signingRequest.completionTrackingNumber || ''}
            onChange={(e) =>
              setSigningRequest({
                ...signingRequest,
                completionTrackingNumber: e.target.value,
              })
            }
          />
          <div className="package-2">
            <p className="comp-input-notes">Package #2 (if applicable) </p>
            <AddCircleOutline onClick={handleExpandedPackage} />
          </div>
          <Collapse in={expanded}>
            <ShippingCompany
              disabled={disableHandler()}
              value={signingRequest.completionReturnedVia2 || ''}
              ddlOnChange={(value) =>
                setSigningRequest({
                  ...signingRequest,
                  completionReturnedVia2: value,
                })
              }
            />
            <TextField
              id="ns__completionTrackingNumber2"
              label="Tracking Number"
              disabled={disableHandler()}
              value={signingRequest.completionTrackingNumber2 || ''}
              onChange={(e) =>
                setSigningRequest({
                  ...signingRequest,
                  completionTrackingNumber2: e.target.value,
                })
              }
            />
          </Collapse>
          <div className="mobile-tab">
            <FormControl component="fieldset">
              <FormLabel component="legend">Completion Results</FormLabel>
              <RadioGroup
                sx={{ paddingLeft: '10px' }}
                name="completion-results"
                value={selectedOption}
                onChange={handleCompletionResultChange}
              >
                {completionOptions.map((item, index) => (
                  <FormControlLabel
                    sx={{ width: 'fit-content' }}
                    key={index}
                    value={item.text}
                    control={<Radio style={{ pointerEvents: 'auto' }} disabled={disableHandler()} size="small" />}
                    label={item.value === 'Completed, all docs signed!' ? item.value : item.value + ' (add comments)'}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div className="label-with-info">
              <div>Comments</div>
              <div>
                <HtmlTooltip
                  arrow
                  title={`If the above selected option specifies 'add comments', please explain situation in comments section below.`}
                >
                  <IconButton>
                    <InfoOutlinedIcon color="info" fontSize="small" />
                  </IconButton>
                </HtmlTooltip>
              </div>
            </div>
            <TextField
              id="ns__completionComments"
              label="1000 Character limit"
              multiline
              rows={4}
              disabled={disableHandler()}
              required={selectedOption !== 'chkComplete' && selectedOption !== ''}
              error={selectedOption !== 'chkComplete' && !signingRequest.completionComments}
              value={signingRequest.completionComments || ''}
              onChange={(e) =>
                setSigningRequest({
                  ...signingRequest,
                  completionComments: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="desktop-tab">
          <FormControl component="fieldset">
            <FormLabel component="legend">Completion Results</FormLabel>
            <RadioGroup
              sx={{ paddingLeft: '10px' }}
              name="completion-results"
              value={selectedOption}
              onChange={handleCompletionResultChange}
            >
              {completionOptions.map((item, index) => (
                <FormControlLabel
                  sx={{ width: 'fit-content' }}
                  key={index}
                  value={item.text}
                  control={<Radio style={{ pointerEvents: 'auto' }} disabled={disableHandler()} size="small" />}
                  label={item.value === 'Completed, all docs signed!' ? item.value : item.value + ' (add comments)'}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className="label-with-info">
            <div>Comments</div>
            <div>
              <HtmlTooltip
                arrow
                title={`If the above selected option specifies 'add comments', please explain situation in comments section below.`}
              >
                <IconButton>
                  <InfoOutlinedIcon color="info" fontSize="small" />
                </IconButton>
              </HtmlTooltip>
            </div>
          </div>
          <TextField
            id="ns__completionComments"
            label="1000 Character limit"
            multiline
            rows={4}
            disabled={disableHandler()}
            required={selectedOption !== 'chkComplete' && selectedOption !== ''}
            error={selectedOption !== 'chkComplete' && !signingRequest.completionComments}
            value={signingRequest.completionComments || ''}
            onChange={(e) =>
              setSigningRequest({
                ...signingRequest,
                completionComments: e.target.value,
              })
            }
          />
        </div>
      </div>
    </RootCard>
  );
};

export default CompletionInfoDesk;
