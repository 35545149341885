import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import BugReportIcon from '@mui/icons-material/BugReport';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import { NotificationCenterContext } from '../../../context/notification-center.context';
import { appReleaseNotes } from '../../..';
import { Alert } from '@mui/material';
import { Button } from '@mui/base';
import { sanitizeHTML } from '../../../helpers';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0, //theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const NotificationDrawer = ({ open, onClose }) => {
  const { onNewRelease, setOnNewRelease } = React.useContext(NotificationCenterContext);
  const [expanded, setExpanded] = React.useState();

  React.useEffect(() => {
    if (onNewRelease.release) {
      localStorage.setItem('NS_LastSeen_ReleaseVersion', onNewRelease.release.ReleaseVersion);
      setExpanded(onNewRelease.release.ReleaseVersion);
      setOnNewRelease((prev) => ({ ...prev, seen: true }));
    }
    return () => {};
  }, [open]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) onClose();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const releasesNotes = () => (
    <Box role="presentation">
      <Typography sx={{ m: 1 }} variant="subtitle1">
        Release Notes
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Alert sx={{ m: 1 }} severity="info">
        Some features or fixes require you to refresh your browser.{' '}
        <Button size="small" onClick={() => window.location.reload(false)}>
          Refresh now
        </Button>
      </Alert>
      {appReleaseNotes.map((release) => (
        <Accordion
          key={release.ReleaseVersion}
          defaultExpanded={release.ReleaseVersion == onNewRelease.release?.ReleaseVersion}
          expanded={expanded === release.ReleaseVersion}
          onChange={handleChange(release.ReleaseVersion)}
        >
          <AccordionSummary aria-controls={`${release.ReleaseVersion}-content`} id={`${release.ReleaseVersion}-header`}>
            <Typography sx={{ fontWeight: 'bold' }} variant="body2">
              Release-{release.ReleaseVersion}
            </Typography>
            <Typography sx={{ fontStyle: 'italic' }} variant="caption">
              {release.ReleaseDate}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {release.Info.NewFeatures?.length > 0 && (
                <>
                  <ListItem sx={{ alignItems: 'center', p: '0 8px', lineHeight: '100%' }} alignItems="flex-start">
                    <ListItemAvatar sx={{ minWidth: '30px', mt: 0 }}>
                      <NewReleasesIcon fontSize="medium" color="info" />
                    </ListItemAvatar>
                    <ListItemText primary={release.ReleaseVersion == '2.0.0' ? 'Initial Release' : 'New Features'} />
                  </ListItem>
                  {release.ReleaseVersion !== '2.0.0' && (
                    <ListItem sx={{ fontSize: '14px', display: 'block', p: '0px 16px' }} alignItems="flex-start">
                      {release.Info.NewFeatures.map((feature, index) => (
                        <React.Fragment key={index}>
                          {feature.Name ? (
                            <ListItemText
                              sx={{
                                '.MuiListItemText-primary': {
                                  fontSize: '.9em',
                                  display: 'flex',
                                  gap: '5px',
                                  alignItems: 'center',
                                },
                                '.MuiListItemText-secondary': {
                                  fontSize: '.8em',
                                  paddingLeft: '18px',
                                },
                              }}
                              primary={
                                <>
                                  <DoneIcon color="success" sx={{ fontSize: '1em' }} />
                                  {feature.Name}
                                </>
                              }
                              secondary={
                                <div
                                  style={{ whiteSpace: 'pre-wrap', rowGap: '5px', display: 'grid' }}
                                  dangerouslySetInnerHTML={sanitizeHTML(feature.Description)}
                                />
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    </ListItem>
                  )}
                  {release.Info.BugFixes?.length > 0 && (
                    <Divider variant="inset" component="li" sx={{ m: 0, mt: '10px', mb: '10px' }} />
                  )}
                </>
              )}
              {release.Info.BugFixes?.length > 0 && (
                <>
                  <ListItem sx={{ alignItems: 'center', p: '0 8px', lineHeight: '100%' }} alignItems="flex-start">
                    <ListItemAvatar sx={{ minWidth: '30px', mt: 0 }}>
                      <BugReportIcon fontSize="medium" color="warning" />
                    </ListItemAvatar>
                    <ListItemText primary={'Bug Fixes'} />
                  </ListItem>
                  <ListItem sx={{ fontSize: '14px', display: 'block', p: '0px 16px' }} alignItems="flex-start">
                    {release.Info.BugFixes.map((bug, index) => (
                      <React.Fragment key={index}>
                        {bug.Name ? (
                          <ListItemText
                            sx={{
                              '.MuiListItemText-primary': {
                                fontSize: '.9em',
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                              },
                              '.MuiListItemText-secondary': { fontSize: '.8em', paddingLeft: '18px' },
                            }}
                            primary={
                              <>
                                <DoneIcon color="success" sx={{ fontSize: '1em' }} />
                                {bug.Name}
                              </>
                            }
                            secondary={bug.Description}
                          />
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </ListItem>
                </>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  return (
    <Drawer
      sx={{ '.MuiDrawer-paper': { maxWidth: '350px' } }}
      anchor={'right'}
      open={open}
      onClose={toggleDrawer(false)}
    >
      {releasesNotes()}
    </Drawer>
  );
};

export default NotificationDrawer;
