import React, { Fragment, useState, useRef, useContext, useEffect } from 'react';
import { css } from 'styled-components';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  Button,
  IconButton,
  Menu,
  MenuList,
  Paper,
  Popper,
  MenuItem,
  Tooltip,
  useMediaQuery,
  ListItemIcon,
} from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SortIcon from '@mui/icons-material/Sort';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AddIcon from '@mui/icons-material/Add';

import { ContactManagerContext } from '../../../context/contact-manager.context';
import { ContactEmailDialog } from '../../../components/contact-manager/list/ContactEmailDialog';
import PaginationCompact from '../../../components/common/others/PaginationCompact';
import { signingRequestService as srService } from '../../../services';
import { dispatchAlert } from '../../../helpers';
import { store } from '../../../store';
import { HtmlTooltip } from '../../../Style';
import PaginationMobile from '../../common/others/PaginationMobile';
import zIndex from '@mui/material/styles/zIndex';
import { ViewEmailsDialog } from '../ViewEmailsDialog';
import { ContactExport } from '../ContactExport';
import Show from '../../common/others/Show';
import AgentProfileFormOpenBtn from '../../agent-profile-form/AgentProfileFormOpenBtn';

const sortOptions = [
  { id: 1, label: 'Miles', value: 'Miles' },
  { id: 2, label: 'Name', value: 'Name' },
  { id: 3, label: 'Company', value: 'Company' },
  { id: 4, label: 'City', value: 'City' },
  { id: 5, label: 'State', value: 'State' },
  { id: 6, label: 'Zip', value: 'Zip' },
];

const PaginationDiv = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    white-space: nowrap;

    @media screen and (max-width: 850px) {
      &.non-mobile {
        display: none;
      }
    }

    @media screen and (min-width: 851px) {
      &.mobile {
        display: none;
      }
    }
  `
);

const StyledListToolBar = styled('div')(
  ({ theme }) => css`
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    .options {
      align-items: center;
      display: flex;
      flex-grow: 1;
      flex: 1;

      .option-chips {
        display: contents;
        .MuiChip-root {
          border-radius: 5px;

          .MuiChip-label {
            font-size: 0.75rem;
          }
        }

        .MuiChip-root:nth-of-type(2) {
          margin-left: 5px;
        }
      }
    }

    .records-count {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 500;
    }

    @media screen and (max-width: 850px) {
      .records-count {
        display: none;
      }
    }

    .MuiButton-root {
      text-transform: none;
    }

    .emailCount {
      align-self: center;
    }
  `
);

var alphabetArray = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const Item = styled(Paper)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary2.main : '',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 0,
    border: `2px solid ${theme.palette.nsAppBG.main}`,
    padding: '0 4px',
  },
}));

const ListOptions = (props) => {
  const history = useHistory();

  const { contactMgrStorage } = useSelector((state) => state.reducer.portalStorage);

  const {
    loadingDataList,
    listMode,
    setListMode,
    dataList,
    renderListView,
    setRenderListView,
    totalPages,
    filterPayLoad,
    setFilterPayLoad,
    getContactList,
    handlePageNumber,
    requestID,
    assignNotaryState,
    selectedEmailCount: emailCount,
    selectedEmailCountLimit,
    setSelectedEmailCount,
    setAgentEmailRecipients,
    fromContactManager,
    pagination,
    controlSetting,
    emailTemplate,
    setEmailTemplate,
    currentPaginationPageSize,
  } = useContext(ContactManagerContext);

  const mountedRef = useRef(true);
  const { currentUser } = store.getState().reducer;

  const listSortAnchorRef = useRef(null);
  const minWidth500 = useMediaQuery('(min-width:500px)');
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [activeSortMenu, setActiveSortMenu] = useState(false);
  const [viewEmailsDialogOpen, setViewEmailsDialogOpen] = useState(false);
  const [exportContactsOpen, setExportContactsOpen] = useState(false);
  const [listStylerAnchorEl, setListStylerAnchorEl] = useState(null);
  const listStyleMenuOpen = Boolean(listStylerAnchorEl);

  useEffect(() => {
    return () => {
      mountedRef.current = dataList?.length > 0 ? false : true;
    };
  }, [loadingDataList]);

  const handleEmailActionPerform = async (response) => {
    if (response) {
      await setListMode('contacts');
      getContactList().then((res) => {
        setSelectedEmailCount(0);
        setAgentEmailRecipients([]);
      });
    }
    setEmailDialogOpen(false);
  };

  const handleListSortClose = (event) => {
    setActiveSortMenu(false);
    listSortAnchorRef.current = null;
  };

  const getTemplate = async () => {
    await srService.getAgentAvailRequestEmailTemplate(requestID).then(
      (res) => {
        setListMode('email');
        setEmailTemplate(res);
      },
      (error) => dispatchAlert(error, true)
    );
  };

  const handleListOption = (event) => {
    let buttonText = event.target.textContent || event.target.innerText;

    if (buttonText.toLowerCase() == 'view emails') {
      setViewEmailsDialogOpen(true);
    } else if (buttonText.toLowerCase() == 'availability email') {
      getTemplate();
    } else if (buttonText.toLowerCase() == 'export contacts') {
      setExportContactsOpen(true);
    } else {
      setListMode('contacts');
      setSelectedEmailCount(0);
      setAgentEmailRecipients([]);
    }
    setListStylerAnchorEl(null);
  };

  // Alphabet component. END
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  // Alphabet component. END

  const dispatch = useDispatch();

  const [listAppearanceAnchorEl, setListAppearanceAnchorEl] = useState(null);
  const listAppearanceOpen = Boolean(listAppearanceAnchorEl);
  const handleListAppearanceClick = (event) => setListAppearanceAnchorEl(event.currentTarget);
  const handleListAppearanceMenuItemClick = (val) => {
    if (val == 'alternate-color') {
      dispatch({
        type: 'SET_PORTAL_STORAGE_CONTACT_MGR',
        payload: { altCardColor: !contactMgrStorage.altCardColor },
      });
    } else {
      setRenderListView(val == 'on' ? 2 : 1);
      dispatch({ type: 'SET_PORTAL_STORAGE_CONTACT_MGR', payload: { compactViewOn: val } });
    }
    setListAppearanceAnchorEl(null);
  };

  useEffect(() => {
    if (contactMgrStorage.compactViewOn == 'on') setRenderListView(2);
    else setRenderListView(1);

    return () => {};
  }, [renderListView]);

  return (
    <Fragment>
      {listMode === 'email' && (
        <Box sx={{}}>
          <Collapse in={listMode === 'email'}>
            <Alert
              variant="filled"
              severity="info"
              action={
                <Fragment>
                  <Button color="inherit" variant="text" onClick={(e) => handleListOption(e)}>
                    Cancel
                  </Button>
                  {emailCount > 0 && (
                    <Button
                      color="inherit"
                      disabled={emailCount < 1}
                      endIcon={<SendOutlinedIcon fontSize="inherit" />}
                      onClick={() => setEmailDialogOpen(true)}
                    >
                      Send Email
                    </Button>
                  )}
                </Fragment>
              }
            >
              {`Select up to ${selectedEmailCountLimit} contacts from the list then press Send Email`}
            </Alert>
          </Collapse>
        </Box>
      )}
      <StyledListToolBar style={{}}>
        <div className="options">
          <Show when={filterPayLoad.listSelection == 'Customer'}>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <Button
                variant="contained"
                endIcon={<AddIcon />}
                onClick={(e) => {
                  history.push(`/contact-manager/profile/customer/new`);
                  e.stopPropagation();
                }}
              >
                New Customer
              </Button>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
            </Box>
          </Show>

          <Show when={filterPayLoad.listSelection != 'Customer'}>
            <AgentProfileFormOpenBtn />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
          </Show>

          <Button
            sx={{ minWidth: '100px' }}
            id="contact-list-style"
            color="text"
            variant="text"
            aria-haspopup="true"
            aria-expanded={listStyleMenuOpen ? 'true' : undefined}
            endIcon={<MoreVertOutlinedIcon />}
            onClick={(event) => setListStylerAnchorEl(event.currentTarget)}
          >
            List Options
          </Button>
          <Menu
            id="contact-menu-list-style"
            anchorEl={listStylerAnchorEl}
            open={listStyleMenuOpen}
            onClose={() => setListStylerAnchorEl(null)}
          >
            <MenuItem
              disabled={fromContactManager ? true : false}
              selected={listMode === 'contacts'}
              onClick={(e) => handleListOption(e)}
            >
              General List
            </MenuItem>
            {!filterPayLoad.showHidden && (
              <MenuItem
                selected={listMode === 'hidden'}
                onClick={(e) => {
                  getContactList({ ...filterPayLoad, showHidden: true });
                  setFilterPayLoad({ ...filterPayLoad, showHidden: true });
                  setListStylerAnchorEl(null);
                }}
              >
                Show Hidden
              </MenuItem>
            )}
            {!assignNotaryState?.publicId && !fromContactManager && (
              <MenuItem
                disabled={fromContactManager ? true : false}
                selected={listMode === 'email'}
                onClick={(e) => handleListOption(e)}
              >
                Availability Email
              </MenuItem>
            )}
            {(currentUser.userRoles.includes('SuperUser') || currentUser.userRoles.includes('ServiceOwner')) && (
              <div>
                {controlSetting?.showEmailListBtn && (
                  <MenuItem selected={listMode === 'view-emails'} onClick={(e) => handleListOption(e)}>
                    View Emails
                  </MenuItem>
                )}
                {controlSetting?.showExcelOption && (
                  <MenuItem selected={listMode === 'export'} onClick={(e) => handleListOption(e)}>
                    Export Contacts
                  </MenuItem>
                )}
              </div>
            )}
          </Menu>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
          {filterPayLoad.showHidden && (
            <>
              <Chip
                size="small"
                color="primary"
                variant="outlined"
                label={'Remove Hidden'}
                deleteIcon={<CloseIcon />}
                onDelete={() => {
                  getContactList({ ...filterPayLoad, showHidden: false });
                  setFilterPayLoad({ ...filterPayLoad, showHidden: false });
                }}
                clickable={true}
              />
              <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
            </>
          )}
          <div className="alpha-filter">
            <IconButton
              color="text"
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <StyledBadge badgeContent={filterPayLoad.alpha} color="secondary" size="small">
                <SortByAlphaIcon />
              </StyledBadge>
            </IconButton>
            <Popper
              sx={{ zIndex: 1 }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="auto-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'auto-start' ? 'left top' : 'left bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Grid
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        onKeyDown={handleListKeyDown}
                      >
                        <Item
                          square={true}
                          selected={filterPayLoad.alpha == 'All' ? true : false}
                          sx={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            getContactList({ ...filterPayLoad, alpha: 'All' });
                            setFilterPayLoad({ ...filterPayLoad, alpha: 'All' });
                            handleClose(e);
                          }}
                        >
                          All
                        </Item>
                        {alphabetArray.map((item) => (
                          <Item
                            square={true}
                            key={item}
                            selected={filterPayLoad.alpha == item ? true : false}
                            sx={{
                              // borderTopLeftRadius: item == 'A' ? '5px' : '0px',
                              // borderBottomLeftRadius: item == 'A' ? '5px' : '0px',
                              borderTopRightRadius: item == 'Z' ? '5px' : '0px',
                              borderBottomRightRadius: item == 'Z' ? '5px' : '0px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              getContactList({ ...filterPayLoad, alpha: e.target.textContent });
                              setFilterPayLoad({ ...filterPayLoad, alpha: e.target.textContent });
                              handleClose(e);
                            }}
                          >
                            {item}
                          </Item>
                        ))}
                      </Grid>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1, ml: 2.5 }} />
          <div className="page-settings">
            <Tooltip title="Page Settings">
              <IconButton
                onClick={handleListAppearanceClick}
                size="small"
                aria-controls={listAppearanceOpen ? 'list-appearance' : undefined}
                aria-haspopup="true"
                aria-expanded={listAppearanceOpen ? 'true' : undefined}
              >
                <Badge
                  color="secondary"
                  variant={'dot'}
                  invisible={!(contactMgrStorage.compactViewOn == 'on' || contactMgrStorage.altCardColor)}
                >
                  <SettingsOutlinedIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={listAppearanceAnchorEl}
              id="list-appearance"
              open={listAppearanceOpen}
              onClose={() => setListAppearanceAnchorEl(null)}
              onClick={() => setListAppearanceAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={() => handleListAppearanceMenuItemClick('off')} selected={renderListView == 1}>
                <ListItemIcon>
                  <AutoAwesomeMosaicOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Expanded View
              </MenuItem>
              <MenuItem onClick={() => handleListAppearanceMenuItemClick('on')} selected={renderListView == 2}>
                <ListItemIcon>
                  <TableChartOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Compact View
              </MenuItem>
              {/* <Divider />
              <MenuItem
                onClick={() =>
                  dispatch({
                    type: 'SET_PORTAL_STORAGE_CONTACT_MGR',
                    payload: { lockViewOnScroll: !contactMgrStorage.lockViewOnScroll },
                  })
                }
              >
                <ListItemIcon>
                  {contactMgrStorage.lockViewOnScroll ? (
                    <CheckBoxOutlinedIcon fontSize="small" color="success" />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                  )}
                </ListItemIcon>
                Lock View On Scroll
              </MenuItem>
              {renderListView == 1 && (
                <div>
                  <MenuItem onClick={() => handleListAppearanceMenuItemClick('alternate-color')}>
                    <ListItemIcon>
                      {contactMgrStorage.altCardColor ? (
                        <CheckBoxOutlinedIcon fontSize="small" color="success" />
                      ) : (
                        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    Alternate Card Color
                  </MenuItem>
                </div>
              )} */}
            </Menu>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 1 }} />
          {dataList?.length >= 1 && (
            <>
              <Tooltip disableFocusListener title={'Sorted by ' + filterPayLoad.sortField}>
                <Button
                  ref={listSortAnchorRef}
                  sx={{ minWidth: '50px' }}
                  color="text"
                  variant="text"
                  aria-haspopup="true"
                  aria-label="sort"
                  startIcon={<SortIcon fontSize="small" />}
                  onClick={(e) => {
                    setActiveSortMenu(true);
                    listSortAnchorRef.current = e.currentTarget;
                  }}
                >
                  {minWidth500 ? `Sorted by ${filterPayLoad.sortField}` : 'Sort'}
                </Button>
              </Tooltip>
              {activeSortMenu && (
                <Popper
                  sx={{ zIndex: 1 }}
                  id={'sortFilter'}
                  open={activeSortMenu ? true : false}
                  anchorEl={listSortAnchorRef.current}
                  placement={'right-start'}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleListSortClose}>
                          <MenuList dense>
                            {sortOptions.map((item) => (
                              <MenuItem
                                key={item.id}
                                onClick={(e) => {
                                  getContactList({
                                    ...filterPayLoad,
                                    sortField: item.value,
                                  });
                                  setFilterPayLoad({
                                    ...filterPayLoad,
                                    sortField: item.value,
                                  });
                                  handleListSortClose(e);
                                }}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              )}
            </>
          )}
          {listMode === 'email' && (
            <div className="emailCount">
              <Badge badgeContent={emailCount} color="info">
                <MailOutlinedIcon color="action" />
              </Badge>
            </div>
          )}
        </div>
        <div className="records-count">
          <span>{pagination?.totalRecords} Records Found</span>
          {parseInt(controlSetting?.pendingReg || 0) > 0 && (
            <HtmlTooltip arrow title={`${controlSetting.pendingReg} Pending Agent Registrations`}>
              <IconButton>
                <InfoOutlinedIcon color="info" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          )}
        </div>
        {dataList?.length > 0 && (
          <PaginationDiv className="non-mobile">
            {dataList?.length >= 1 && (
              <PaginationCompact
                pageNumber={filterPayLoad.currentPageNumber}
                totalPages={totalPages}
                handlePageNumber={handlePageNumber}
              />
            )}
          </PaginationDiv>
        )}
        {dataList?.length > 0 && pagination && (
          <PaginationDiv className="mobile">
            <PaginationMobile
              count={totalPages}
              totalRows={pagination?.totalRecords}
              pageNumber={filterPayLoad.currentPageNumber}
              rowsPerPage={currentPaginationPageSize()}
              onChange={handlePageNumber}
            />
          </PaginationDiv>
        )}
      </StyledListToolBar>
      {emailDialogOpen && (
        <ContactEmailDialog
          onOpen={emailDialogOpen}
          onClose={() => setEmailDialogOpen(false)}
          title={'Availability Email Request'}
          requestID={requestID}
          emailTemplate={emailTemplate}
          setEmailTemplate={setEmailTemplate}
          confirmCallback={(e) => handleEmailActionPerform(e)}
        />
      )}
      {viewEmailsDialogOpen && (
        <ViewEmailsDialog
          onOpen={viewEmailsDialogOpen}
          onClose={() => setViewEmailsDialogOpen(false)}
          title={'Email List for the Filtered Contacts'}
        />
      )}
      {exportContactsOpen && (
        <ContactExport
          onOpen={exportContactsOpen}
          onClose={() => setExportContactsOpen(false)}
          title={'View & Export'}
        />
      )}
    </Fragment>
  );
};

export default ListOptions;
