import { useState, useCallback, useTransition, useMemo, useLayoutEffect } from 'react';
import Fetch from '../Fetch.js';
import { authHeader } from '../auth-header';
import { handleResponse } from '../handle-response'; // Adjust the import path accordingly

const useFetch = (url, secure = true, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, startTransition] = useTransition();

  // Memoize options to prevent unnecessary re-creation
  const memoizedOptions = useMemo(() => {
    return { ...options }; // Spread operator to create a shallow copy
  }, [options]);

  const fetchData = useCallback(
    async (method = 'GET', query = {}, bodyParams = '') => {
      setLoading(true);
      setError(null);

      const hasBodyParams = method === 'POST' || method === 'PUT';

      const headers = {
        ...memoizedOptions,
        ...(hasBodyParams ? { body: JSON.stringify(bodyParams) } : ''),
        ...(secure ? { headers: await authHeader() } : {}),
      };

      try {
        let response;
        switch (method) {
          case 'POST':
            response = await Fetch.post(url, query, headers);
            break;
          case 'PUT':
            response = await Fetch.update(url, query, headers);
            break;
          case 'DELETE':
            response = await Fetch.remove(url, query, headers);
            break;
          case 'GET':
          default:
            response = await Fetch.get(url, query, headers);
            break;
        }

        const result = await handleResponse(response);

        // Use startTransition to mark the state update as non-urgent
        startTransition(() => {
          setData(result);
        });
      } catch (err) {
        setError(err); // Set error
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [url, memoizedOptions, startTransition]
  );

  return { data, loading, error, fetchData, isPending };
};

export default useFetch;
