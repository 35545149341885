function FilterOption(filter, index) {
  this.id = filter.id;
  this.label = filter.label;
  this.type = filter.type;
  this.multiple = false;
  this.permanent = filter.permanent;
  this.dataType = filter.dataType;
  this.backendValProp = filter.backendValProp;
  this.disableClearable = filter.disableClearable;

  // this below will help to SORT the applied filter by the moment it gets added
  if (filter.permanent) this['lastEnteredTimestamp'] = index;

  switch (filter.id) {
    case 'capabilities':
    case 'backgroundChecks':
    case 'languages':
      this.multiple = true;
      this.value = filter.value || [];
      this.options = [];
      this.sortBy = 'value';
      break;
    case 'sO_Rating':
      this.value = filter.value || 0;
      this.valueLabel = filter.valueLabel || '';
      this.options = [
        { index: '0', label: '1+', value: '1' },
        { index: '1', label: '2+', value: '2' },
        { index: '2', label: '3+', value: '3' },
        { index: '3', label: '4+', value: '4' },
        { index: '4', label: '5', value: '5' },
      ];
      break;
    case 'agentRegDocs':
      this.multiple = true;
      this.value = filter.useCustomForm ? filter.value : filter.value || {};
      this.options = [];
      this.otherOptions = filter.otherOptions;
      this.useCustomForm = filter.useCustomForm;
      break;
    default:
      let _or = filter.dataType == 'number' ? parseInt(filter.value || 0) : filter.value || '';
      this.value = filter.value || _or;
      break;
  }
}

export const GetInitialFilterOptions = (defaultList = []) => {
  return defaultList.map((item, index) => (item = new FilterOption(item, index)));
};
